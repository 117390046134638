import React from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { ModalText } from '../../components/cms/blockText.constants';
import { ModalBtnText } from '../../components/cms/buttonText.constants';
import Routes from '../routes/routes.constants';
import { clearAuthStateToken } from '../../utilities/authentication';
import { cancelOTPService } from '../security/security.service';

type Props = {
  visible: boolean;
  onExit: () => void;
  onConfirm: () => void;
  isNewAccountOpeningFlow: boolean;
};

const ConfirmLeaveOtpModal = (props: Props) => {
  const { visible, onExit, onConfirm, isNewAccountOpeningFlow } = props;
  const history = useHistory();

  const onModalConfirm = async () => {
    await cancelOTPService();
    clearAuthStateToken();
    if (isNewAccountOpeningFlow) {
      onConfirm();
    } else {
      history.push(Routes.SECURITY);
    }
  };

  return (
    <ConfirmationModal
      titleText={
        isNewAccountOpeningFlow
          ? ModalText.CANCEL_OTP_VERIFICATION_TITLE_NEW_ACCOUNT
          : ModalText.CANCEL_OTP_VERIFICATION_TITLE
      }
      contentText={
        isNewAccountOpeningFlow
          ? ModalText.CANCEL_OTP_VERIFICATION_BODY_NEW_ACCOUNT
          : ModalText.CANCEL_OTP_VERIFICATION_BODY
      }
      exitText={
        isNewAccountOpeningFlow ? ModalBtnText.CANCEL_CONTINUE_BTN : ModalBtnText.CANCEL_GENERIC
      }
      confirmText={
        isNewAccountOpeningFlow ? ModalBtnText.CANCEL_EXIT_BTN : ModalBtnText.YES_LEAVE_PAGE_BTN
      }
      visible={visible}
      onConfirm={() => {
        onModalConfirm();
      }}
      onExit={onExit}
    />
  );
};

export default ConfirmLeaveOtpModal;
