import React, { useState } from 'react';
import classnames from 'classnames';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { Theme } from '../../utilities/types';
import type { Card } from './cancelCard.service';
import { CardTypes } from './cancelCard.service';
import { useActivityTileStyles } from '../../components/activityTile/activityTile.styles';
import pxToRem from '../../utilities/pxToRem';
import { GhostButton } from '../../components/buttons/buttons';
import { formatAccountName } from '../../formatters/buildAccountName';
import { ScreenReaderText } from '../../components/typography/typography';
import { formatAccountNumberWithType } from '../../utilities/a11y';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { ModalText } from '../../components/cms/blockText.constants';
import { ModalBtnText } from '../../components/cms/buttonText.constants';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';

type CardProps = {
  card: Card;
  name?: string;
  onCancelClicked: (arg1: Card) => Promise<void>;
  isCancelDisabled: boolean;
};

const useCardTileStyles = makeStyles((theme: Theme) => ({
  rightColumn: {
    textAlign: 'right',
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      lineHeight: 1.75,
      padding: `${pxToRem(6)} ${pxToRem(8)}`,
    },
  },
  rowContainer: {
    alignItems: 'center',
  },
  lineSpace: {
    [theme.breakpoints.down('sm')]: {
      marginTop: pxToRem(15),
    },
  },
}));

const atmCardIcon = getImageSrc(ImagesFileNames.atmCardPng);
const atmCardIconLg = getImageSrc(ImagesFileNames.atmCard_2xPng);
const atmCardIconXl = getImageSrc(ImagesFileNames.atmCard_3xPng);

const debitCardIcon = getImageSrc(ImagesFileNames.debitcardPng);
const debitCardIconLg = getImageSrc(ImagesFileNames.debitcard_2xPng);
const debitCardIconXl = getImageSrc(ImagesFileNames.debitcard_3xPng);
const creditCardIcon = getImageSrc(ImagesFileNames.creditCardPng);
const creditCardIconLg = getImageSrc(ImagesFileNames.creditCard_2xPng);
const creditCardIconXl = getImageSrc(ImagesFileNames.creditCard_3xPng);

const CardImages = {
  [CardTypes.ATM]: (
    <img
      src={atmCardIcon}
      alt="Atm Card"
      srcSet={`${atmCardIcon} 1x, ${atmCardIconLg} 2x, ${atmCardIconXl} 3x`}
    />
  ),
  [CardTypes.Debit]: (
    <img
      src={debitCardIcon}
      alt="Debit Card"
      srcSet={`${debitCardIcon} 1x, ${debitCardIconLg} 2x, ${debitCardIconXl} 3x`}
    />
  ),
  [CardTypes.Credit]: (
    <img
      src={creditCardIcon}
      alt="Credit Card"
      srcSet={`${creditCardIcon} 1x, ${creditCardIconLg} 2x, ${creditCardIconXl} 3x`}
    />
  ),
} as const;

export const CardTile = ({ card, name, onCancelClicked, isCancelDisabled }: CardProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { cardDescription, cardId, cardLastFour, cardType, linkedAccounts } = card;
  const activityTileClasses = useActivityTileStyles();
  const cardTileClasses = useCardTileStyles();
  const cardName = `${cardDescription} ●●${cardLastFour}`;

  const onProceedCancel = async () => {
    setShowConfirmationModal(false);
    setIsLoading(true);
    await onCancelClicked(card);
    setIsLoading(false);
  };

  const onClick = () => {
    onProceedCancel();
  };

  const viewCancelButton = (
    <GhostButton
      loading={isLoading}
      disabled={isCancelDisabled}
      onClick={() => {
        setShowConfirmationModal(true);
      }}
      data-test="cancel-card-button"
    >
      Cancel Card
    </GhostButton>
  );

  return (
    <Grid
      key={cardId}
      container
      item
      component="tr"
      role="row"
      className={classnames(activityTileClasses.itemContainer, cardTileClasses.rowContainer)}
    >
      <Grid
        xs={6}
        sm={3}
        item
        component="td"
        role="cell"
        data-test="card-pic-container"
        className={activityTileClasses.item}
      >
        {CardImages[cardType]}
      </Grid>
      <Hidden smUp>
        <Grid
          xs={6}
          item
          component="td"
          role="cell"
          data-test="cancel-card-container"
          className={classnames(activityTileClasses.item, cardTileClasses.rightColumn)}
        >
          {viewCancelButton}
        </Grid>
      </Hidden>
      <Grid
        xs={12}
        sm={4}
        item
        component="td"
        role="cell"
        data-test="card-info"
        className={activityTileClasses.item}
      >
        <Grid xs={12} item>
          {name}
        </Grid>
        <Grid xs={12} item>
          <span aria-hidden>{cardName}</span>
          <ScreenReaderText>{formatAccountNumberWithType(cardName)}</ScreenReaderText>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={3}
        item
        component="td"
        role="cell"
        data-test="linked-accounts-info"
        className={classnames(activityTileClasses.item, cardTileClasses.lineSpace)}
      >
        {linkedAccounts.map((account) => {
          const accountName = formatAccountName(
            account.accountNickname,
            account.accountDisplayType,
            `●●${account.accountLastFour}`
          );
          return (
            <Grid xs={12} item key={account.accountId}>
              <span aria-hidden>{accountName}</span>
              <ScreenReaderText>{formatAccountNumberWithType(accountName)}</ScreenReaderText>
            </Grid>
          );
        })}
      </Grid>
      <Hidden xsDown>
        <Grid
          xs={6}
          sm={2}
          item
          component="td"
          role="cell"
          data-test="cancel-card-container"
          className={classnames(activityTileClasses.item, cardTileClasses.rightColumn)}
        >
          {viewCancelButton}
        </Grid>
      </Hidden>
      <ConfirmationModal
        exitText={ModalBtnText.CANCEL_CARD_NO_BTN}
        confirmText={ModalBtnText.CANCEL_CARD_YES_BTN}
        contentText={ModalText.CANCEL_CARD_MODAL_BODY}
        onConfirm={onClick}
        onExit={() => {
          setIsLoading(false);
          setShowConfirmationModal(false);
        }}
        titleText={ModalText.CANCEL_CARD_MODAL_TITLE}
        visible={showConfirmationModal}
      />
    </Grid>
  );
};

export default CardTile;
