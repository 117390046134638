import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import { ANALYTICS_CD_BUMP_UP_CONFIRMATION, ANALYTICS_CD_BUMP_UP_OVERVIEW } from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.activity,
    pagefunction: pageTrack.pagefunction.cd_bump,
  });

const viewOverview = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.landing_page }).record();
};

const viewConfirmation = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.confirmation_page }).record();
};

export const cdBumpUpEvents = {
  [ANALYTICS_CD_BUMP_UP_OVERVIEW]: viewOverview,
  [ANALYTICS_CD_BUMP_UP_CONFIRMATION]: viewConfirmation,
} as const;

export default cdBumpUpEvents;
