import React from 'react';
import type { SVGComponent } from '../../components/svg/svg';
import Colors from '../../components/colors/colors';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';

export const RewardServiceLevels = Object.freeze({
  DIAMOND: 'Diamond',
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
  SILVER: 'Silver',
  BASIC: 'None',
  '': '',
});

export type ServiceLevel = typeof RewardServiceLevels[keyof typeof RewardServiceLevels];

export const RewardLevelsMapping = Object.freeze({
  '': RewardServiceLevels.BASIC,
  AFFINITY: RewardServiceLevels.BASIC,
  'DIAM - AFF': RewardServiceLevels.DIAMOND,
  DIAMOND: RewardServiceLevels.DIAMOND,
  DIAMSYFAFF: RewardServiceLevels.DIAMOND,
  GOLD: RewardServiceLevels.GOLD,
  GOLDSYFAFF: RewardServiceLevels.GOLD,
  'GOLD - AFF': RewardServiceLevels.GOLD,
  'PLAT - AFF': RewardServiceLevels.PLATINUM,
  PLATINUM: RewardServiceLevels.PLATINUM,
  PLATSYFAFF: RewardServiceLevels.PLATINUM,
  'SILV - AFF': RewardServiceLevels.SILVER,
  SILVER: RewardServiceLevels.SILVER,
  SILVSYFAFF: RewardServiceLevels.SILVER,
  'SYF - AFF': RewardServiceLevels.BASIC,
});

export type BSLServiceLevel = keyof typeof RewardLevelsMapping;

export const RewardTierTitle = Object.freeze({
  DIAMOND: 'Diamond',
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
  SILVER: 'Silver',
  BASIC: 'Basic',
});

export const RewardTierDescription = Object.freeze({
  DIAMOND:
    'Total balance of at least $250,000 or you have been a Synchrony Bank customer for at least 5 years.',
  PLATINUM:
    'Total balance of $100,000 – $249,999.99 or you have been a Synchrony Bank customer for at least 3 years.',
  GOLD: 'Total balance of $50,000 – $99,999.99 or you have been a Synchrony Bank customer for at least 2 years.',
  SILVER:
    'Total balance of $10,000 – $49,999.99 or you have been a Synchrony Bank customer for at least 1 year.',
  BASIC:
    'Total balance of less than $10,000 and you have been a Synchrony Bank customer for less than 1 year.',
});

export const Perks = Object.freeze({
  ATM_REIMBURSEMENTS: 'ATM Fee Reimbursements (up to $5 USD per statement cycle)',
  ATM_UNLIMITED_REIMBURSEMENTS: 'Unlimited ATM Fee Reimbursements',
  MMA_CHECKS: 'Free First Order of Synchrony Money Market Checks',
  WIRE_TRANSFERS: 'Three Free Wire Transfers per statement cycle',
  PHONE_ACCESS: 'Dedicated Customer Service phone access',
  HOTEL: 'Hotel discounts',
  CAR_RENTALS: 'Discount on car rentals',
  AMENITIES: 'Exclusive value-added amenities: spa, golf, and restaurant offers',
  THEME_PARKS: 'Theme park discounts',
});

export const rewardsBasicPerks = [Perks.ATM_REIMBURSEMENTS, Perks.MMA_CHECKS];

export const rewardsSilverPerks = [Perks.ATM_REIMBURSEMENTS, Perks.MMA_CHECKS, Perks.HOTEL];

export const rewardsGoldPerks = [
  Perks.ATM_REIMBURSEMENTS,
  Perks.MMA_CHECKS,
  Perks.HOTEL,
  Perks.CAR_RENTALS,
];

export const rewardsPlatinumPerks = [
  Perks.ATM_REIMBURSEMENTS,
  Perks.MMA_CHECKS,
  Perks.HOTEL,
  Perks.CAR_RENTALS,
];

export const rewardsDiamondPerks = [
  Perks.ATM_UNLIMITED_REIMBURSEMENTS,
  Perks.MMA_CHECKS,
  Perks.WIRE_TRANSFERS,
  Perks.PHONE_ACCESS,
  Perks.HOTEL,
  Perks.CAR_RENTALS,
];

const PerksLabels = Object.freeze({
  LABEL_YES: 'Yes',
  LABEL_YES_EXCLAMATION: 'Yes!',
  LABEL_YEP: 'Yep',
  LABEL_YEP_EXCLAMATION: 'Yep!',
  LABEL_GOT_IT: 'You got it',
  LABEL_GOT_IT_EXCLAMATION: 'You got it!',
  LABEL_OF_COURSE: 'Of course',
  LABEL_OF_COURSE_EXCLAMATION: 'Of course!',
  LABEL_YEAH: 'Yeah',
  LABEL_YEAH_EXCLAMATION: 'Yeah!',
});

export const PerksTable = Object.freeze({
  ATM_REIMBURSEMENTS: {
    TITLE: 'ATM Fee Reimbursements (up to $5 USD per statement cycle)',
    BASIC: PerksLabels.LABEL_YES_EXCLAMATION,
    SILVER: PerksLabels.LABEL_GOT_IT_EXCLAMATION,
    GOLD: PerksLabels.LABEL_OF_COURSE_EXCLAMATION,
    PLATINUM: PerksLabels.LABEL_YEP,
  },
  ATM_UNLIMITED_REIMBURSEMENTS: {
    TITLE: 'Unlimited ATM Fee Reimbursements',
    DIAMOND: PerksLabels.LABEL_YEAH_EXCLAMATION,
  },
  MMA_CHECKS: {
    TITLE: 'Free First Order of Synchrony Money Market Checks',
    BASIC: PerksLabels.LABEL_OF_COURSE_EXCLAMATION,
    SILVER: PerksLabels.LABEL_YEAH,
    GOLD: PerksLabels.LABEL_YEP,
    PLATINUM: PerksLabels.LABEL_GOT_IT_EXCLAMATION,
    DIAMOND: PerksLabels.LABEL_YES,
  },
  WIRE_TRANSFERS: {
    TITLE: 'Three Free Wire Transfers per statement cycle',
    DIAMOND: PerksLabels.LABEL_OF_COURSE_EXCLAMATION,
  },
  PHONE_ACCESS: {
    TITLE: 'Dedicated Customer Service phone access',
    DIAMOND: PerksLabels.LABEL_YEP_EXCLAMATION,
  },
  HOTEL: {
    TITLE: 'Hotel discounts',
    SILVER: PerksLabels.LABEL_YEP,
    GOLD: PerksLabels.LABEL_YES_EXCLAMATION,
    PLATINUM: PerksLabels.LABEL_OF_COURSE_EXCLAMATION,
    DIAMOND: PerksLabels.LABEL_GOT_IT_EXCLAMATION,
  },
  CAR_RENTALS: {
    TITLE: 'Discount on car rentals',
    GOLD: PerksLabels.LABEL_YEAH_EXCLAMATION,
    PLATINUM: PerksLabels.LABEL_YES,
    DIAMOND: PerksLabels.LABEL_YEP_EXCLAMATION,
  },
});

export const RewardsLinks = Object.freeze({
  ALL_REWARDS: 'https://www.synchronybank.com/about/#perks',
  PERKS_TERMS_AND_CONDITIONS: 'https://www.synchronybank.com/sites/syfbank/documents/perkstc.pdf',
  TRAVEL_BENEFITS_SILVER:
    'http://www.employeetravelspecials.com/launch.aspx?siteid=55b711b9-c4e7-45f2-ae66-4a71a34081b2',
  TRAVEL_BENEFITS_GOLD:
    'http://www.employeetravelspecials.com/launch.aspx?siteid=562ef46c-50c3-427c-a0b0-3e78d13e5b26',
  TRAVEL_BENEFITS_PLATINUM:
    'http://www.employeetravelspecials.com/launch.aspx?siteid=8fd2aba5-779c-4099-b19e-bd16e0afaea2',
  TRAVEL_BENEFITS_DIAMOND:
    'http://www.employeetravelspecials.com/launch.aspx?siteid=2f259cce-ff65-493b-990a-91058a5a7690',
});

export type RewardsInfo = {
  balance: string;
  color: string;
  icon: React.ReactElement<React.ComponentProps<SVGComponent>>;
  title: string;
  description: string;
  minimumTenure: string;
  perks: string[];
  travelBenefitsLink?: string;
};

export const RewardsBasic: RewardsInfo = Object.freeze({
  balance: 'Less than $10,000',
  color: Colors.optimumBlue,
  icon: <SVGImage imageName={ImagesFileNames.iconRewardsBasicSvg} ariaHidden="true" />,
  title: RewardTierTitle.BASIC,
  description: RewardTierDescription.BASIC,
  minimumTenure: 'Less than 1 year',
  perks: rewardsBasicPerks,
  travelPerks: [],
});
export const RewardsSilver: RewardsInfo = Object.freeze({
  balance: '$10,000 - $49,999.99',
  color: Colors.steelGrey,
  icon: <SVGImage imageName={ImagesFileNames.iconRewardsSilverSvg} ariaHidden="true" />,
  title: RewardTierTitle.SILVER,
  description: RewardTierDescription.SILVER,
  minimumTenure: '1+ years',
  perks: rewardsSilverPerks,
  travelBenefitsLink: RewardsLinks.TRAVEL_BENEFITS_SILVER,
});
export const RewardsGold: RewardsInfo = Object.freeze({
  balance: '$50,000 - $99,999.99',
  color: Colors.rewardsGold,
  icon: <SVGImage imageName={ImagesFileNames.iconRewardsGoldSvg} ariaHidden="true" />,
  title: RewardTierTitle.GOLD,
  description: RewardTierDescription.GOLD,
  minimumTenure: '2+ years',
  perks: rewardsGoldPerks,
  travelBenefitsLink: RewardsLinks.TRAVEL_BENEFITS_GOLD,
});
export const RewardsPlatinum: RewardsInfo = Object.freeze({
  balance: '$100,000 - $249,999.99',
  color: Colors.rewardsPlatinum,
  icon: <SVGImage imageName={ImagesFileNames.iconRewardsPlatinumSvg} ariaHidden="true" />,
  title: RewardTierTitle.PLATINUM,
  description: RewardTierDescription.PLATINUM,
  minimumTenure: '3+ years',
  perks: rewardsPlatinumPerks,
  travelBenefitsLink: RewardsLinks.TRAVEL_BENEFITS_PLATINUM,
});
export const RewardsDiamond = Object.freeze({
  balance: '$250,000+',
  color: Colors.rewardsDiamond,
  icon: <SVGImage imageName={ImagesFileNames.iconRewardsDiamondSvg} ariaHidden="true" />,
  title: RewardTierTitle.DIAMOND,
  description: RewardTierDescription.DIAMOND,
  minimumTenure: '5+ years',
  perks: rewardsDiamondPerks,
  travelBenefitsLink: RewardsLinks.TRAVEL_BENEFITS_DIAMOND,
});

export const rewardsLevels = [
  RewardsBasic,
  RewardsSilver,
  RewardsGold,
  RewardsPlatinum,
  RewardsDiamond,
] as const;

export const RewardsText = Object.freeze({
  AVAILABLE_PERKS: 'Available Perks:',
  BALANCE: 'Balance',
  MINIMUM_TENURE: 'Minimum Tenure',
  VIEW_REWARD_TIER: 'View Reward Tier',
});
