import type { AccountDetailsType } from 'Domain/Account/AccountDetails';
import type { PendingApplication } from './types';

const routes = {
  accountsTransferable: 'accountsTransferable',
  applications: 'applications',
  beneficiaries: 'beneficiaries',
  customers: 'customers',
  documents: 'documents',
  downtime: 'servicefiles/downtime.json',
  external: 'externalAccounts',
  internal: 'depositAccounts',
  messages: 'messages',
  messageThreads: 'messageThreads',
  newAccountOpening: 'newAccountOpening',
  newAccountOpeningTransmit: 'new-account-opening',
  products: 'products',
  public_keys: 'fle/keys',
  relationships: 'relationships',
  terms: 'terms',
  transfers: 'transfers',
  utilities: 'utilities',
  preferences: 'preferences',
} as const;

type URLType = 'API_V1' | 'API_V2_BASE_URL' | 'DOWNTIME';

const constructUrl = (path: string, urlType?: URLType) => {
  let apiBaseUrl = '';
  const { API_BASE_URL, DOWNTIME_URL, API_V2_BASE_URL } = window.__config__;
  switch (urlType) {
    case 'DOWNTIME':
      apiBaseUrl = DOWNTIME_URL || '';
      break;
    case 'API_V2_BASE_URL':
      apiBaseUrl = API_V2_BASE_URL || '';
      break;
    case 'API_V1':
    default:
      apiBaseUrl = API_BASE_URL || '';
      break;
  }
  return `${apiBaseUrl}/${path}`;
};

// Internal
export const ACCOUNT_ALERTS_PREFERENCES_URL = () =>
  constructUrl(`${routes.preferences}/accountAlerts`);
export const ACCOUNTS_TRANSFERABLE_URL = () => constructUrl(routes.accountsTransferable);
export const ACTIVE_CARDS_URL = () => constructUrl('cards?cardStatus=Active');
export const ALL_ACCOUNTS_DASHBOARD_URL = () => constructUrl(routes.internal);
export const ALL_ACCOUNTS_URL = () => constructUrl(`${routes.transfers}/accounts`);
export const ALL_STATEMENTS_URL = () => constructUrl(`${routes.internal}/statements`);
export const APPLICATIONS_CITIZENSHIPS_URL = () =>
  constructUrl(`${routes.newAccountOpening}/lists/nations`);
export const APPLICATIONS_CUSTOMER_SEARCH_URL = () =>
  constructUrl(`${routes.newAccountOpening}/customer`);
export const APPLICATIONS_CUSTOMER_TAX_ID_URL = () =>
  constructUrl(`${routes.customers}/customer/taxId`);
export const APPLICATIONS_JOINT_OWNERS_URL = () =>
  constructUrl(`${routes.newAccountOpening}/jointOwners`);
export const APPLICATIONS_JOINT_OWNER_V2_URL = () =>
  constructUrl(`${routes.newAccountOpeningTransmit}/joint-owners`, 'API_V2_BASE_URL');
export const APPLICATIONS_OCCUPATIONS_URL = () =>
  constructUrl(`${routes.newAccountOpening}/lists/occupations`);
export const APPLICATIONS_SECURITY_QUESTIONS_URL = () =>
  constructUrl(`${routes.newAccountOpening}/lists/securityQuestions`);
export const APPLICATIONS_SOURCE_OF_FUNDS_URL = () =>
  constructUrl(`${routes.newAccountOpening}/lists/fundsSource`);
export const APPLICATIONS_STATES_URL = () =>
  constructUrl(`${routes.newAccountOpening}/lists/states`);
export const APPLICATIONS_TERMS_URL = () => constructUrl(`${routes.newAccountOpening}/terms`);
export const APPLICATIONS_TERMS_V2_URL = () =>
  constructUrl(`${routes.newAccountOpeningTransmit}/terms`, 'API_V2_BASE_URL');
export const APPLICATIONS_V2_URL = () =>
  constructUrl(`${routes.newAccountOpeningTransmit}/applications`, 'API_V2_BASE_URL');
export const APPLICATIONS_URL = () => constructUrl(`${routes.newAccountOpening}/applications`);
export const BENEFICIARIES_URL = () => constructUrl(routes.beneficiaries);
export const BILLPAY_URL_INFO_URL = () => constructUrl(`${routes.customers}/customer/billPay/url`);
export const BUMPUP_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${accountId}/rate`);
export const CANCEL_CARD_URL = (cardId: string) => constructUrl(`cards/${cardId}`);
export const CARD_STATUS_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${accountId}/cards`);
export const CHECK_IMAGES_URL = (
  accountId: string,
  accountType: AccountDetailsType,
  checkNumber: string
) => constructUrl(`${routes.internal}/${accountType}/${accountId}/checks/${checkNumber}`);
export const CHECKS_DEPOSIT_SLIPS_URL = () => constructUrl('checks');
export const CUSTOMER_INFO_URL = () => constructUrl(`${routes.customers}/customer`);
export const DOCUMENTS_INFO_URL = () => constructUrl(`${routes.documents}`);
export const DOWNTIME_BASE_URL = () => constructUrl(routes.downtime, 'DOWNTIME');
export const EDIT_USERNAME_URL = () => constructUrl('username');
export const EDIT_PASSWORD_URL = () => constructUrl('password');
export const ENCRYPTED_ACCOUNT_NUMBER_URL = (accountType: string, surrogateId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${surrogateId}/account-id`);
export const ETIN_URL = () => constructUrl('termsAgreements/etin');
export const ETIN_TRANSMIT_URL = (applicantId: string) =>
  constructUrl(
    `${routes.newAccountOpeningTransmit}/applicants/${applicantId}/etin-terms`,
    'API_V2_BASE_URL'
  );
export const EXTERNAL_ACCOUNT_URL = (accountId: string) =>
  constructUrl(`${routes.external}/${accountId}`);
export const EXTERNAL_ACCOUNTS_URL = () => constructUrl(`${routes.external}`);
export const FUNDING_EXTERNAL_URL = () => constructUrl(`${routes.transfers}/funding/external`);
export const FUNDING_INTERNAL_URL = () => constructUrl(`${routes.transfers}/funding/internal`);
export const LISTS_STATES_URL = () => constructUrl(`${routes.utilities}/lists/states`);
export const LISTS_OCCUPATIONS_URL = () => constructUrl(`${routes.utilities}/lists/occupations`);
export const LISTS_NATIONS_URL = () => constructUrl(`${routes.utilities}/lists/nations`);
export const INTERNAL_ACCOUNTS_ACTIVITY_URL = (type: string, accountId: string) =>
  constructUrl(`${routes.internal}/${type}/${accountId}`);
export const INTERNAL_ACCOUNTS_OVERDRAFT_URL = (type: string, accountId: string) =>
  constructUrl(`${routes.internal}/${type}/${accountId}/overdraft`);
export const INTERNAL_ACCOUNTS_TRANSACTIONS_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${accountId}/transactions`);
export const MESSAGES_URL = () => constructUrl(routes.messages);
export const MESSAGE_COUNTS_URL = () => constructUrl(`${routes.messages}/counts`);
export const MESSAGES_INFO_URL = () => constructUrl(routes.messageThreads);
export const MESSAGE_DETAILS_URL = (threadId: string) =>
  constructUrl(`${routes.messageThreads}/${threadId}`);
export const MESSAGE_ATTACHMENTS_URL = () => constructUrl(`${routes.messages}/attachments`);
export const MESSAGE_ATTACHMENT_DOWNLOAD_URL = (
  threadId: string,
  messageId: string,
  attachmentName: string
) => constructUrl(`${routes.messages}/${threadId}/${messageId}/attachments/${attachmentName}/link`);

export const PATCH_APPLICATIONS_URL = (patchApplicationData: PendingApplication) => {
  const { applicantId, applicationId } = patchApplicationData;
  return constructUrl(
    `${routes.newAccountOpening}/applications/${applicationId}/applicants/${applicantId}`
  );
};
export const PRODUCTS_URL = () => constructUrl(`${routes.newAccountOpening}/products`);
export const RATE_MATRICES_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${accountId}/rateMatrices`);
export const RENEWAL_INSTRUCTIONS_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${accountId}/renewalInstructions`);
export const RETRIEVE_PUBLIC_KEY_URL = () => constructUrl(`${routes.public_keys}`);
export const SECURITY_ELIGIBILITY_URL = () => constructUrl('eligibility');
export const MFA_CHALLENGE_URL = () => constructUrl('mfa/challenge');
export const MFA_VALIDATE_URL = () => constructUrl('mfa/validate');
export const MFA_CANCEL_URL = () => constructUrl('mfa/cancel');
export const DOCUMENTS_NONCE_URL = (documentId: string) =>
  constructUrl(`documents/${documentId}/link`);
export const STATEMENTS_DOCUMENT_URL = (documentNonceUrl: string) =>
  constructUrl(`documents/statements/${documentNonceUrl}`);
export const SUBMIT_NEW_MESSAGE_URL = () => constructUrl(routes.messageThreads);
export const SUBMIT_REPLY_MESSAGE_URL = (threadId: string) =>
  constructUrl(`${routes.messageThreads}/${threadId}`);
export const VIEW_DOCUMENT_URL = (documentNonceUrl: string) =>
  constructUrl(`documents/${documentNonceUrl}`);
export const VIEW_MESSAGE_ATTACHMENT_URL = (attachmentNonceUrl: string) =>
  constructUrl(`${attachmentNonceUrl}`);
export const SUBMIT_ODP_LINK_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.internal}/${accountType}/${accountId}/overdraft`);
export const TERMS_URL = (category: string) => constructUrl(`${routes.terms}/${category}`);
export const THIRD_PARTY_TOKEN_URL = () =>
  constructUrl(`${routes.customers}/customer/third-party-token`);
export const TRANSFER_ESTIMATED_DATE_URL = () =>
  constructUrl(`${routes.transfers}/estimatedDeliveryDate`);
export const TRANSFER_FREQUENCIES_URL = () => constructUrl(`${routes.transfers}/frequencies`);
export const TRANSFER_URL = (id: string) => constructUrl(`${routes.transfers}/${id}`);
export const EDIT_TRANSFER_URL = (id: string, transferCategory: string) =>
  constructUrl(`${routes.transfers}/${transferCategory}/${id}`);
export const TRANSFERS_URL = () => constructUrl(routes.transfers);
export const VALID_ODP_ACCOUNTS_URL = () => constructUrl(`${routes.internal}/overdraft-eligible`);
export const VALIDATE_APPLICATIONS_URL = () =>
  constructUrl(`${routes.newAccountOpening}/applications/validate`);
export const VALIDATE_EXTERNAL_ACCOUNT_URL = (routingNumber: string) =>
  constructUrl(`${routes.external}/banks/${routingNumber}`);
export const VERIFY_EXTERNAL_ACCOUNT_URL = (externalId: string) =>
  constructUrl(`${routes.external}/${externalId}`);
export const YODLEE_ACCOUNT_INFO = () => constructUrl(`${routes.external}/yodlee`);
export const YODLEE_AGGREGATORS_URL = () => constructUrl(`${routes.external}/aggregators/yodlee`);
export const ZELLE_URL = () => constructUrl(`${routes.customers}/customer/zelle/url`);
export const ZELLE_URL_INFO_URL = () => constructUrl(`${routes.customers}/customer/zelle/url`);
export const PREFERENCES_INFO_URL = () => constructUrl(`${routes.preferences}/statements`);
export const TAX_DOCUMENT_PREFERENCES_URL = () =>
  constructUrl(`${routes.preferences}/taxDocuments`);
export const MARKETING_PREFERENCES_INFO_URL = () => constructUrl(`${routes.preferences}/marketing`);
export const PATCH_BENEFICIARIES_URL = () => constructUrl(`${routes.relationships}/beneficiary`);
export const UPDATE_BENEFICIARY_INFO_URL = (beneficiaryId: string) =>
  constructUrl(`${routes.beneficiaries}/${beneficiaryId}`);
export const PATCH_ACCOUNT_BENEFICIARIES_URL = (accountType: string, accountId: string) =>
  constructUrl(`${routes.relationships}/${accountType}/${accountId}`);
export const MESSAGES_TOPICS_URL = () => constructUrl(`${routes.messages}/topics`);
