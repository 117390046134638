import { LOCATION_CHANGE } from 'connected-react-router';
import type { ReduxState } from '../../reducers';
import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';

const viewPage = (action, state: ReduxState) => {
  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: 'account_opening',
      pagesubfunction: state.authenticate.isLoggedIn
        ? pageTrack.pagesubfunction.EAO
        : pageTrack.pagesubfunction.NAO,
      pagename: 'start_server_error',
      attr: '',
    })
    .record();
};

export const applicationFailLoadPageEvents = {
  [LOCATION_CHANGE]: viewPage,
} as const;

export default applicationFailLoadPageEvents;
