export interface AccountList {
  editId?: string;
  removeId?: string;
  transferId?: string;
}

const generateEditRemoveId = (accountList: AccountList[], uniqueId: string) => {
  return accountList.map((item) => {
    return {
      ...item,
      editId: `edit-${item[uniqueId]}`,
      removeId: `remove-${item[uniqueId]}`,
    };
  });
};

export default generateEditRemoveId;
