import { makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles';
import { Alignment } from '../../styles/layout/alignment.styles';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import { Typography } from '../typography/typography.styles';
import Colors from '../colors/colors';
import { secondaryButtonStyles } from '../buttons/buttons';
import { Z_INDEX_FORM_IMAGE } from '../../styles/zIndex';
import { FormHeaderProps } from './typings';

export const FORM_WIDTH = '58%';
export const IMAGE_WIDTH = '42%';

export default makeStyles(
  (theme: Theme): StyleRules<FormHeaderProps> => ({
    backLink: {
      ...secondaryButtonStyles,
      ...Typography.buttonText,
      ...Typography.upperCase,
      textDecoration: 'none',
      padding: '11px 20px 9px',
    },
    backLinkContainer: {
      display: 'flex',
    },
    consistently: {
      display: 'inline-block',
      color: Colors.synchronyGold,
    },
    ctaContainer: {
      marginTop: pxToRem(0),
      marginBottom: pxToRem(20),
      [theme.breakpoints.down('sm')]: {
        marginTop: pxToRem(20),
      },
      outline: 'none !important',
    },
    ctaContainerWithProgressStepper: {
      marginTop: pxToRem(50),
    },
    ctaDescription: {
      marginTop: pxToRem(20),
      marginBottom: pxToRem(30),
    },
    errorContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      flexGrow: 1,
    },
    flashMessageContainer: {
      minHeight: pxToRem(45),
    },
    flashMessageContainerWithProgressStepper: {
      /* has a progress stepper above itself */
      marginTop: pxToRem(30),
    },
    formContainer: {
      ...Alignment.allPadding,
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    formFdicWrapper: {
      alignContent: 'center',
      paddingLeft: pxToRem(4),
    },
    formHeaderContainer: {
      maxWidth: (props) => (props.fullWidthFormContent ? '100%' : pxToRem(640)),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&>div:last-of-type': {
        marginTop: pxToRem(16),
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        /**
         * adding a gap forces a minimum amount of space between logo and FDIC on smaller devices
         * both before & after the image appears on the right side of the form
         */
        gap: pxToRem(16),
        '&>div:last-of-type': {
          marginTop: pxToRem(0),
          width: 'fit-content',
        },
      },
    },
    formContent: {
      maxWidth: (props) => (props.fullWidthFormContent ? '100%' : pxToRem(640)),
      /* addExternalAccount, Transfers */
      marginTop: pxToRem(48),
    },
    formContentWithCtaText: {
      /* AO Thank you pages / non progress stepper pages on AO */
      marginTop: pxToRem(65),
    },
    formContentWithFlashMessage: {
      /* overrites progress stepper or forms with just title */
      marginTop: pxToRem(20),
    },
    formContentWithProgressStepper: {
      marginTop: pxToRem(40),
    },
    leftContainer: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: FORM_WIDTH,
      },
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh', // IE requires min-height
      '&:focus': {
        outline: 'none',
      },
    },
    formTitle: {
      marginTop: pxToRem(0),
    },
    formTitleWithProgressStepper: {
      marginTop: pxToRem(20),
    },
    imageBackground: {
      backgroundColor: Colors.imageBackground,
      backgroundPosition: '80% 50%',
      position: 'fixed',
      top: 0,
      left: FORM_WIDTH,
      height: '100vh',
      width: IMAGE_WIDTH,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      zIndex: Z_INDEX_FORM_IMAGE,
      [theme.breakpoints.down('lg')]: {
        minWidth: '10vw',
      },
    },
    marketingMessage: {
      margin: '0 7%',
      paddingTop: '24%',
      fontSize: pxToRem(30),
      color: Colors.white,
    },
    subtitle: {
      marginTop: pxToRem(24),
      marginBottom: pxToRem(48),
    },
  })
);
