import type { AllAccount } from 'Domain/Account/AllAccount';
import type {
  ApplicationsState,
  CustomerInfo,
  ExistingJointOwner,
  ExistingJointOwners,
  GetTermsResponse,
  PendingApplication,
  SubmitApplicationClientResponse,
} from '../../utilities/types';

import { buildApplicationsState } from '../../utilities/tests/applicationState.factory';

import type { TransferResponse } from '../transfers/newTransfer/newTransfer.reducer';
import { convertAccountSummaryToAllAccount } from '../allAccounts/allAccounts.service';
import type { AccountFundingFlow } from '../../utilities/accountFundingFlowType';
import { filterOutDuplicateJointOwners } from './applicationForm.reducer';

export const ACTION_APPLICATION_INIT_SUCCESS = 'ACTION_APPLICATION_INIT_SUCCESS';
export const ACTION_APPLICATION_ERROR = 'ACTION_APPLICATION_ERROR';
export const ACTION_DISPLAY_TERMS_AND_CONDITIONS = 'ACTION_DISPLAY_TERMS_AND_CONDITIONS';
export const ACTION_TERMS_AND_CONDITIONS_ERROR = 'ACTION_TERMS_AND_CONDITIONS_ERROR';
export const ACTION_CREATE_EXISTING_USER_APPLICATION_SUCCESS =
  'ACTION_CREATE_EXISTING_USER_APPLICATION_SUCCESS';
export const ACTION_SET_LOADING_FALSE = 'ACTION_SET_LOADING_FALSE';
export const ACTION_SET_LOADING_TRUE = 'ACTION_SET_LOADING_TRUE';
export const ACTION_SET_EDITING_FALSE = 'ACTION_SET_EDITING_FALSE';
export const ACTION_SET_EDITING_TRUE = 'ACTION_SET_EDITING_TRUE';
export const ACTION_CREATE_APPLICATION_REQUEST = 'ACTION_CREATE_APPLICATION_REQUEST';
export const ACTION_STORE_PENDING_ACCOUNT_APPLICATION = 'ACTION_STORE_PENDING_ACCOUNT_APPLICATION';
export const ACTION_CLEAR_PENDING_ACCOUNT_APPLICATION = 'ACTION_CLEAR_PENDING_ACCOUNT_APPLICATION';
export const ACTION_MARK_OTP_COMPLETE = 'ACTION_MARK_OTP_COMPLETE';
export const ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS =
  'ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS';
export const ACTION_CREATE_NEW_USER_APPLICATION_FAILURE =
  'ACTION_CREATE_NEW_USER_APPLICATION_FAILURE';
export const ACTION_STORE_NEW_USER_APPLICATION = 'ACTION_STORE_NEW_USER_APPLICATION';
export const ACTION_STORE_NEW_ACCOUNT_INFO = 'ACTION_STORE_NEW_ACCOUNT_INFO';
export const ACTION_FUND_ACCOUNT_REQUEST = 'ACTION_FUND_ACCOUNT_REQUEST';
export const ACTION_FUND_ACCOUNT_SUCCESS = 'ACTION_FUND_ACCOUNT_SUCCESS';
export const ACTION_FUND_ACCOUNT_VIA_CHECK_SUCCESS = 'ACTION_FUND_ACCOUNT_VIA_CHECK_SUCCESS';
export const ACTION_FETCH_CUSTOMER_INFO_SUCCESS = 'ACTION_FETCH_CUSTOMER_INFO_SUCCESS';
export const ACTION_LOAD_JOINT_OWNERS = 'ACTION_LOAD_JOINT_OWNERS';
export const ACTION_ADD_EXISTING_JOINT_OWNER = 'ACTION_ADD_EXISTING_JOINT_OWNER';
export const ACTION_MADE_FETCH_ATTEMPT = 'ACTION_MADE_FETCH_ATTEMPT';
export const ACTION_DECRYPT_TAX_ID_REQUEST = 'ACTION_DECRYPT_TAX_ID_REQUEST';
export const ACTION_DECRYPT_TAX_ID_SUCCESS = 'ACTION_DECRYPT_TAX_ID_SUCCESS';
export const ACTION_CLEAR_DECRYPT_TAX_ID = 'ACTION_CLEAR_DECRYPT_TAX_ID';
export const ACTION_CONFIRM_NEW_PHONE_NUMBER = 'ACTION_CONFIRM_NEW_PHONE_NUMBER';
export const ACTION_ALLOW_UNLOAD = 'ACTION_ALLOW_UNLOAD';
export const ACTION_SET_TRACKING_ID = 'ACTION_SET_TRACKING_ID';

export const ACTION_ANALYTICS_FUNDING_FLOW = 'ACTION_ANALYTICS_FUNDING_FLOW';

export const ACTION_CLEAR_ANALYTICS_FUNDING_FLOW = 'ACTION_CLEAR_ANALYTICS_FUNDING_FLOW';

export const ACTION_SET_CONTACT_US_CODE = 'ACTION_SET_CONTACT_US_CODE';

const initialState = buildApplicationsState();

export type ApplicationsAction =
  | {
      type: 'ACTION_APPLICATION_INIT_SUCCESS';
    }
  | {
      type: 'ACTION_APPLICATION_ERROR';
    }
  | {
      type: 'ACTION_CREATE_EXISTING_USER_APPLICATION_SUCCESS';
      payload: SubmitApplicationClientResponse;
    }
  | {
      type: 'ACTION_INITIATE_TERMS_REQUEST';
    }
  | {
      type: typeof ACTION_SET_LOADING_FALSE;
    }
  | {
      type: typeof ACTION_SET_LOADING_TRUE;
    }
  | {
      type: 'ACTION_SET_EDITING_FALSE';
    }
  | {
      type: 'ACTION_SET_EDITING_TRUE';
      payload: {
        stepId: string;
      };
    }
  | {
      type: 'ACTION_CREATE_APPLICATION_REQUEST';
      payload: string;
    }
  | {
      type: 'ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS';
      payload: SubmitApplicationClientResponse;
    }
  | {
      type: 'ACTION_CREATE_NEW_USER_APPLICATION_FAILURE';
    }
  | {
      type: 'ACTION_FETCH_CUSTOMER_INFO_SUCCESS';
      payload: {
        customerInfo: CustomerInfo;
      };
    }
  | {
      type: typeof ACTION_STORE_PENDING_ACCOUNT_APPLICATION;
      payload: PendingApplication;
    }
  | {
      type: typeof ACTION_CLEAR_PENDING_ACCOUNT_APPLICATION;
    }
  | {
      type: typeof ACTION_MARK_OTP_COMPLETE;
    }
  | {
      type: 'ACTION_STORE_NEW_USER_APPLICATION';
      payload: {
        application: ApplicationsState;
      };
    }
  | {
      type: typeof ACTION_FUND_ACCOUNT_REQUEST;
    }
  | {
      type: typeof ACTION_FUND_ACCOUNT_SUCCESS;
      payload: TransferResponse;
    }
  | {
      type: 'ACTION_FUND_ACCOUNT_VIA_CHECK_SUCCESS';
    }
  | {
      type: typeof ACTION_DISPLAY_TERMS_AND_CONDITIONS;
      payload: {
        terms: GetTermsResponse;
      };
    }
  | {
      type: 'ACTION_DISPLAY_EXISTING_USER_TERMS_AND_CONDITIONS';
      payload: {
        terms: GetTermsResponse;
      };
    }
  | {
      type: 'ACTION_STORE_NEW_ACCOUNT_INFO';
      payload: {
        newAccount: AllAccount;
        newProductId: string;
        funded: boolean;
      };
    }
  | {
      type: 'ACTION_LOAD_JOINT_OWNERS';
      payload: ExistingJointOwners;
    }
  | {
      type: 'ACTION_ADD_EXISTING_JOINT_OWNER';
      payload: ExistingJointOwner;
    }
  | {
      type: typeof ACTION_MADE_FETCH_ATTEMPT;
    }
  | {
      type: 'ACTION_DECRYPT_TAX_ID_REQUEST';
    }
  | {
      type: 'ACTION_DECRYPT_TAX_ID_SUCCESS';
      payload: string;
    }
  | {
      type: 'ACTION_CLEAR_DECRYPT_TAX_ID';
    }
  | {
      type: 'ACTION_CONFIRM_NEW_PHONE_NUMBER';
    }
  | {
      type: typeof ACTION_ALLOW_UNLOAD;
    }
  | {
      type: typeof ACTION_SET_TRACKING_ID;
      payload: string;
    }
  | {
      type: typeof ACTION_ANALYTICS_FUNDING_FLOW;
      payload?: AccountFundingFlow;
    }
  | {
      type: typeof ACTION_CLEAR_ANALYTICS_FUNDING_FLOW;
    }
  | {
      type: 'ACTION_TERMS_AND_CONDITIONS_ERROR';
    }
  | {
      type: typeof ACTION_SET_CONTACT_US_CODE;
      payload: string;
    };

const addExistingJointOwner = (
  jointOwners: ExistingJointOwners,
  addedJointOwner: ExistingJointOwner
) => {
  const newJointOwners = jointOwners.slice();
  newJointOwners.push(addedJointOwner);
  return filterOutDuplicateJointOwners(newJointOwners);
};

export default (
  // @ts-ignore
  state: ApplicationsState | null | undefined = initialState,
  action: ApplicationsAction = undefined
): ApplicationsState => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case ACTION_LOAD_JOINT_OWNERS:
      return { ...state, loadedJointOwners: filterOutDuplicateJointOwners(action.payload) };
    case ACTION_ADD_EXISTING_JOINT_OWNER:
      return {
        ...state,
        loadedJointOwners: addExistingJointOwner(state.loadedJointOwners, action.payload),
      };
    case ACTION_SET_LOADING_FALSE:
      return { ...state, isLoading: false };
    case ACTION_SET_LOADING_TRUE:
      return { ...state, isLoading: true, termsResponseErr: undefined };
    case ACTION_SET_EDITING_FALSE:
      return { ...state, isEditing: false, latestStep: '' };
    case ACTION_SET_EDITING_TRUE:
      return { ...state, isEditing: true, latestStep: action.payload.stepId };
    case ACTION_DISPLAY_TERMS_AND_CONDITIONS:
      return {
        ...state,
        terms: action.payload.terms,
      };
    case ACTION_TERMS_AND_CONDITIONS_ERROR:
      return {
        ...state,
        termsResponseErr: true,
      };
    case ACTION_STORE_NEW_ACCOUNT_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ACTION_CREATE_APPLICATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        newProductId: action.payload,
      };
    }
    case ACTION_CREATE_EXISTING_USER_APPLICATION_SUCCESS:
      return {
        ...state,
        lastApplicationResponse: action.payload,
        newAccount: action.payload.accounts
          ? convertAccountSummaryToAllAccount(action.payload.accounts[0])
          : undefined,
      };
    case ACTION_STORE_PENDING_ACCOUNT_APPLICATION:
      return {
        ...state,
        pendingApplication: action.payload,
      };
    case ACTION_CLEAR_PENDING_ACCOUNT_APPLICATION:
      return {
        ...state,
        pendingApplication: null,
      };
    case ACTION_MARK_OTP_COMPLETE:
      return {
        ...state,
        hasCompletedOtp: true,
      };
    // TODO: Consider renaming because this includes if app is denied, pending, otp, etc.
    case ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS:
      return {
        ...state,
        lastApplicationResponse: action.payload,
      };
    case ACTION_CREATE_NEW_USER_APPLICATION_FAILURE:
      return { ...state, isLoading: false };
    case ACTION_FUND_ACCOUNT_REQUEST:
      return { ...state, isLoading: true };
    case ACTION_FUND_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAccountFunded: true,
        lastFundingResponse: action.payload,
      };
    case ACTION_FUND_ACCOUNT_VIA_CHECK_SUCCESS:
      return { ...state, isLoading: false, isAccountFunded: true };
    case ACTION_APPLICATION_INIT_SUCCESS:
      return { ...state, fetchAttempts: 0 };
    case ACTION_APPLICATION_ERROR:
      return { ...state, isLoading: false };
    case ACTION_FETCH_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        customerInfo: action.payload.customerInfo,
      };
    case ACTION_MADE_FETCH_ATTEMPT:
      return { ...state, fetchAttempts: state.fetchAttempts + 1 };
    case ACTION_DECRYPT_TAX_ID_REQUEST:
    case ACTION_CLEAR_DECRYPT_TAX_ID:
      return {
        ...state,
        customerInfo: { ...state.customerInfo, decryptedTaxId: null },
      };
    case ACTION_DECRYPT_TAX_ID_SUCCESS:
      return {
        ...state,
        customerInfo: { ...state.customerInfo, decryptedTaxId: action.payload },
      };
    case ACTION_CONFIRM_NEW_PHONE_NUMBER: {
      return {
        ...state,
        hasConfirmedNewPhoneNumber: true,
      };
    }
    case ACTION_ALLOW_UNLOAD: {
      return {
        ...state,
        allowUnload: true,
      };
    }
    case ACTION_SET_TRACKING_ID: {
      return {
        ...state,
        trackingId: action.payload,
      };
    }
    case ACTION_ANALYTICS_FUNDING_FLOW: {
      return {
        ...state,
        currentFundingFlow: action.payload,
      };
    }

    case ACTION_CLEAR_ANALYTICS_FUNDING_FLOW: {
      return {
        ...state,
        currentFundingFlow: undefined,
      };
    }
    case ACTION_SET_CONTACT_US_CODE: {
      return { ...state, contactUsCode: action.payload };
    }
    default:
      return state;
  }
};
