import type { RewardsInfo, ServiceLevel } from './rewards.constants';
import {
  RewardServiceLevels,
  RewardsDiamond,
  RewardsPlatinum,
  RewardsGold,
  RewardsSilver,
  RewardsBasic,
} from './rewards.constants';

export const getRewardTierInfo = (serviceLevel?: ServiceLevel | null): RewardsInfo => {
  switch (serviceLevel) {
    case RewardServiceLevels.DIAMOND:
      return RewardsDiamond;
    case RewardServiceLevels.PLATINUM:
      return RewardsPlatinum;
    case RewardServiceLevels.GOLD:
      return RewardsGold;
    case RewardServiceLevels.SILVER:
      return RewardsSilver;
    case RewardServiceLevels.BASIC:
    default:
      return RewardsBasic;
  }
};

export default getRewardTierInfo;
