import { isDirty } from 'redux-form';
import type { ReduxState } from '../reducers';
import Routes, {
  accountDetailHelpers,
  messageCenterHelpers,
  editTransferHelpers,
} from '../containers/routes/routes.constants';
import { NAO_FORM_ID, EAO_FORM_ID } from './accountOpeningFlowType';
import type { Store } from './types';
import { FUNDING_FORM_ID, ADD_EXTERNAL_ACCOUNT_FORM_ID } from '../form.constants';
import { isSessionExpired } from '../containers/activityTimer/activityTimer';

const formsWithUnloadWarning = [
  { pathname: Routes.NAO, formId: NAO_FORM_ID },
  { pathname: Routes.EAO, formId: EAO_FORM_ID },
  { pathname: Routes.NAO_NEW_ACCOUNT_FUNDING, formId: FUNDING_FORM_ID },
  { pathname: Routes.ADD_EXTERNAL_ACCOUNT_OPTIONS, formId: ADD_EXTERNAL_ACCOUNT_FORM_ID },
];

const isExistingAccountFunding = (path: string) => {
  return (
    path.startsWith(Routes.DIRECT_NEW_ACCOUNT_FUNDING_PREFIX) ||
    path.startsWith(Routes.EAO_NEW_ACCOUNT_FUNDING_PREFIX)
  );
};

const pathsWithFormUnloadWarning = {
  [Routes.NAO_ACCOUNT_CONFIRMATION]: true,
  [Routes.EAO_ACCOUNT_CONFIRMATION]: true,
  [Routes.VERIFY_TRANSFER]: true,
} as const;

const pathsWithOtpUnloadWarning = {
  [Routes.OTP_INITIATE]: true,
  [Routes.OTP_VALIDATE]: true,
} as const;

const finalFormPathsWithUnloadWarning = {
  [Routes.PREFERENCES]: true,
  [Routes.BENEFICIARIES]: true,
  [Routes.NEW_MESSAGE]: true,
  [Routes.NEW_TRANSFER]: true,
  [Routes.EDIT_TRANSFER_PREFIX]: true,
  [Routes.VERIFY_TRANSFER]: true,
} as const;

const isFinalFormPath = (pathname: string) =>
  finalFormPathsWithUnloadWarning[pathname] ||
  accountDetailHelpers.isAccountDetailsUrl(pathname) ||
  messageCenterHelpers.isReplyMessageUrl(pathname) ||
  editTransferHelpers.isEditTransfersUrl(pathname);

const ModalBodyText = {
  FORM: 'Changes you made may not be saved.',
  OTP: 'This action will cancel verification. Are you sure you want to cancel?',
} as const;

export const unloadListener = (store: Store) => (e: BeforeUnloadEvent) => {
  const state: ReduxState = store.getState();
  if (state.applications.allowUnload || isSessionExpired(state)) {
    return;
  }

  const pauseUnload = (modalBodyText: string) => {
    // In order to initiate the prompt, some browsers require preventDefault to be
    // called. Others (e.g. Chrome) require returnValue to be set to a string.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    e.preventDefault();
    e.returnValue = modalBodyText;
  };

  const { pathname } = state.router.location;

  // Must check state updated manually for paths with final-form forms.
  if (isFinalFormPath(pathname) && state.profile.isFormDirtyPreventUnload) {
    pauseUnload(ModalBodyText.FORM);
    return;
  }

  if (pathsWithFormUnloadWarning[pathname]) {
    pauseUnload(ModalBodyText.FORM);
    return;
  }

  if (pathsWithOtpUnloadWarning[pathname]) {
    pauseUnload(ModalBodyText.OTP);
    return;
  }

  const pathnameWithoutStep = pathname.replace(/\/\d$/, '');
  const currentForm = formsWithUnloadWarning.find((form) => pathnameWithoutStep === form.pathname);
  let formId = currentForm && currentForm.formId;
  // Existing account funding has a dynamic url depending on the accountId.
  if (!formId && isExistingAccountFunding(pathnameWithoutStep)) {
    formId = FUNDING_FORM_ID;
  }

  // To catch edge case when user has progressed within a form without changing any data.
  const pastFirstStep = state.router.location.pathname !== pathnameWithoutStep;

  if (formId && (isDirty(formId)(state) || pastFirstStep)) {
    pauseUnload(ModalBodyText.FORM);
  }
};

export const startUnloadListener = (store: Store) => {
  if (window.__config__.IS_CI === 'true') {
    return;
  }
  window.addEventListener('beforeunload', unloadListener(store));
};

export default startUnloadListener;
