import { setupWorker } from 'msw/browser';
import { getMswConfig } from './sessionStorage';
import { handlerMapping } from './handlers';

const mswConfig = getMswConfig();
const { HANDLER_CONFIG } = mswConfig;

const server = setupWorker();

HANDLER_CONFIG.forEach(({ name, enabled }) => {
  if (enabled) server.use(...handlerMapping[name]);
});

export const startMSW = () => server.start({ onUnhandledRequest: 'bypass' });

export const stopMSW = () => server.stop();
