import { makeStyles, StyleRules } from '@material-ui/core/styles';
import breakpoint from '../../styles/breakpoints';
import {
  defaultPadding,
  defaultPx,
  largePadding,
  largePx,
  mediumPadding,
  mediumPx,
  smallPadding,
  smallPx,
} from '../../styles/layout/alignment.styles';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import Colors from '../colors/colors';

export const styles: StyleRules = {
  '@media print': {
    textStyle: {
      fontStyle: 'normal !important',
    },
    description: {
      marginLeft: '10%',
      whiteSpace: 'nowrap',
    },
    subDescription: {
      marginLeft: '10%',
      whiteSpace: 'nowrap',
      color: Colors.steelGrey,
    },
    subAmount: {
      color: Colors.steelGrey,
    },
    itemContainer: {
      paddingTop: `${pxToRem(12)} !important `,
      paddingBottom: `${pxToRem(12)} !important `,
    },
  },
  itemContainer: {
    paddingRight: defaultPadding,
    paddingLeft: pxToRem(defaultPx - 5),
    [breakpoint.up('sm')]: {
      paddingRight: smallPadding,
      paddingLeft: pxToRem(smallPx - 5),
    },
    [breakpoint.up('md')]: {
      paddingRight: mediumPadding,
      paddingLeft: pxToRem(mediumPx - 5),
    },
    [breakpoint.up('lg')]: {
      paddingRight: largePadding,
      paddingLeft: pxToRem(largePx - 5),
    },
    paddingTop: pxToRem(15),
    paddingBottom: pxToRem(15),
    borderLeft: `${pxToRem(5)} solid transparent`,
  },
  item: {
    display: 'block', // IE fix
  },
  amountContainer: {
    textAlign: 'right',
  },
  interest: {
    borderColor: Colors.synchronyGold,
  },
  overdraft: {
    borderColor: Colors.green,
  },
  pending: {
    borderColor: Colors.steelGrey,
  },
};

export const useActivityTileStyles = makeStyles(styles);

export default (theme: Theme): StyleRules => ({
  ...styles,
  [theme.breakpoints.up('sm')]: {
    transactionOverview: {
      flexDirection: 'row',
    },
    transactionAmount: {
      alignSelf: 'flex-start',
    },
  },
});
