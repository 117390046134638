import type { AccountActivityAction } from '../../containers/accountActivity/accountActivity.reducer';
import {
  ACTION_FETCH_ACCOUNT_DETAILS_FAILURE,
  ACTION_FETCH_ACCOUNT_DETAILS_REQUEST,
  ACTION_FETCH_ACCOUNT_DETAILS_SUCCESS,
  ACTION_FETCH_ACCOUNT_TRANSACTIONS_FAILURE,
  getSelectedAccount,
} from '../../containers/accountActivity/accountActivity.reducer';
import type { ReduxState } from '../../reducers';
import pageAnalytics from '../pageAnalytics';
import type { ChecksDepositSlipsAction } from '../actions';
import {
  ANALYTICS_BENEFICIARIES_ACCOUNT_START_FAILURE,
  ANALYTICS_BENEFICIARIES_ACCOUNT_START_SUCCESS,
  ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_FAILURE,
  ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_SUCCESS,
  ANALYTICS_CHECKS_DEPOSIT_SLIPS,
} from '../actions';
import { clickTrackType, clickTrack } from '../clickTracking.constants';
import { recordClickEvent } from '../clickTracking';
import pageTrack from '../pageAnalytics.constants';

const viewInit = (action: AccountActivityAction, state: ReduxState) => {
  const selectedAccount = getSelectedAccount(state);
  if (!selectedAccount) return;
  pageAnalytics.newPageView();
  pageAnalytics.addInfo({
    account_type: state.accountActivity.accountDetails.product.productDisplayType,
    pagekind: 'activity',
    pagefunction: 'account_overview',
    attr: selectedAccount.accountStatus,
  });
};

const beneficiariesInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
    pagefunction: pageTrack.pagefunction.beneficiaries_account,
    pagesubfunction: pageTrack.pagesubfunction.EDIT,
  });

const viewTransactionsFailure = () => {
  // optionally happens before ACCOUNT_DETAILS_SUCCESS/FAILURE
  pageAnalytics.addInfo({
    pagename: 'landing_page_transactions_failed_to_load',
    pagefunction: pageTrack.pagefunction.cd_renewal,
  });
};

const viewSuccess = (action: AccountActivityAction, state: ReduxState) => {
  if (pageAnalytics.getPageInfo('pagename') === '')
    pageAnalytics.addInfo({
      pagename: 'landing_page',
    });
  pageAnalytics
    .addInfo({
      account_type: state.accountActivity.accountDetails.product.productDisplayType,
    })
    .record();
};

const viewFailure = () => {
  pageAnalytics.addInfo({ pagename: 'landing_page_failed_to_load' }).record();
};

const viewChecksDepositSlips = (action: ChecksDepositSlipsAction) => {
  // The page analytics need to be updated before the click tracking is recorded
  pageAnalytics.newPageView().addInfo({
    pagefunction: 'account_overview',
    pagename: action.payload.hasError ? 'landing_page_error' : 'landing_page',
  });
  recordClickEvent({
    title: action.payload.canOrderChecks
      ? clickTrack.accountActivity.order_checks
      : clickTrack.accountActivity.order_deposit_slips,
    type: clickTrackType.BUTTON,
  });
};

const beneficiariesStartSuccess = () => {
  beneficiariesInit().addInfo({ pagename: pageTrack.pagename.start }).record();
};

const beneficiariesStartFailure = () => {
  beneficiariesInit().addInfo({ pagename: pageTrack.pagename.start_server_error }).record();
};

const beneficiariesSubmitSuccess = () => {
  beneficiariesInit().addInfo({ pagename: pageTrack.pagename.complete }).record();
};

const beneficiariesSubmitFailure = () => {
  beneficiariesInit().addInfo({ pagename: pageTrack.pagename.submit_server_error }).record();
};

export const accountActivityPageEvents = {
  [ACTION_FETCH_ACCOUNT_DETAILS_REQUEST]: viewInit,
  [ACTION_FETCH_ACCOUNT_TRANSACTIONS_FAILURE]: viewTransactionsFailure,
  [ACTION_FETCH_ACCOUNT_DETAILS_SUCCESS]: viewSuccess,
  [ACTION_FETCH_ACCOUNT_DETAILS_FAILURE]: viewFailure,
  // This is an abnormal use of click tracking in the page analytics
  // This is necessary because the click makes an API call and the click
  // tracking data depends on the response
  [ANALYTICS_CHECKS_DEPOSIT_SLIPS]: viewChecksDepositSlips,
  [ANALYTICS_BENEFICIARIES_ACCOUNT_START_SUCCESS]: beneficiariesStartSuccess,
  [ANALYTICS_BENEFICIARIES_ACCOUNT_START_FAILURE]: beneficiariesStartFailure,
  [ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_SUCCESS]: beneficiariesSubmitSuccess,
  [ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_FAILURE]: beneficiariesSubmitFailure,
} as const;

export default accountActivityPageEvents;
