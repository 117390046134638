import type { ServiceError } from '../../utilities/types';
import type {
  OTPPhone,
  SecurityEligibilityFlow,
  SecurityEligibilityResponse,
} from '../security/security.service';
import type { OTPInitiateSendValue } from '../otp/otp.constants';

export const ACTION_FETCH_PROFILE = 'ACTION_FETCH_PROFILE';
export const ACTION_FETCH_PROFILE_FAILURE = 'ACTION_FETCH_PROFILE_FAILURE';
export const ACTION_FETCH_PROFILE_SUCCESS = 'ACTION_FETCH_PROFILE_SUCCESS';
export const ACTION_SET_PROFILE_LOADING_TRUE = 'ACTION_SET_PROFILE_LOADING_TRUE';
export const ACTION_SET_PROFILE_LOADING_FALSE = 'ACTION_SET_PROFILE_LOADING_FALSE';
export const ACTION_FETCH_SECURITY_ELIGIBILITY_SUCCESS =
  'ACTION_FETCH_SECURITY_ELIGIBILITY_SUCCESS';
export const ACTION_PROFILE_SAVE_CHANGES_REQUEST = 'ACTION_PROFILE_SAVE_CHANGES_REQUEST';
export const ACTION_PROFILE_SAVE_CHANGES_RETURNED = 'ACTION_PROFILE_SAVE_CHANGES_RETURNED';
export const ACTION_FORM_DIRTY_PREVENT_UNLOAD = 'ACTION_FORM_DIRTY_PREVENT_UNLOAD';
export const ACTION_OTP_INITIATE = 'ACTION_OTP_INITIATE';

export type ProfileState = {
  firstName: string;
  isLoading: boolean;
  isFormDirtyPreventUnload: boolean;
  isSaveChangesLoading: boolean;
  profileError: ServiceError | null | undefined;
  securityEligibilityFlow?: SecurityEligibilityFlow;
  otpPhones?: OTPPhone[];
  selectedOtpPhoneNumber?: string;
  selectedOtpSendMethod?: OTPInitiateSendValue;
  isSelectedNewPhoneNumber?: boolean;
};

export type Profile = {
  firstName: string;
};

function getInitialState(): ProfileState {
  return {
    firstName: '',
    isFormDirtyPreventUnload: false,
    isLoading: false,
    isSaveChangesLoading: false,
    profileError: null,
  };
}

export type ProfileFetchActionFailure = {
  type: 'ACTION_FETCH_PROFILE_FAILURE';
  payload: ServiceError;
};

export type ProfileFetchActionSuccess = {
  type: 'ACTION_FETCH_PROFILE_SUCCESS';
  payload: Profile;
};

export type ProfileSetLoadingTrue = {
  type: 'ACTION_SET_PROFILE_LOADING_TRUE';
};

export type ProfileSetLoadingFalse = {
  type: 'ACTION_SET_PROFILE_LOADING_FALSE';
};

export type ProfileFetchAction =
  | ProfileFetchActionFailure
  | ProfileFetchActionSuccess
  | ProfileSetLoadingTrue
  | ProfileSetLoadingFalse
  | {
      type: typeof ACTION_FETCH_SECURITY_ELIGIBILITY_SUCCESS;
      payload: SecurityEligibilityResponse;
    }
  | {
      type: typeof ACTION_PROFILE_SAVE_CHANGES_REQUEST;
    }
  | {
      type: typeof ACTION_PROFILE_SAVE_CHANGES_RETURNED;
    }
  | {
      type: typeof ACTION_FORM_DIRTY_PREVENT_UNLOAD;
      payload: boolean;
    }
  | {
      type: typeof ACTION_OTP_INITIATE;
      payload: {
        selectedOtpPhoneNumber: string;
        selectedOtpSendMethod: OTPInitiateSendValue;
        isSelectedNewPhoneNumber?: boolean;
      };
    };

export default (
  state: ProfileState | null | undefined = getInitialState(),
  action: ProfileFetchAction = undefined
): ProfileState => {
  switch (action.type) {
    case ACTION_FETCH_PROFILE_SUCCESS:
      return { ...state, ...action.payload };
    case ACTION_FETCH_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        profileError: action.payload,
      };
    case ACTION_SET_PROFILE_LOADING_TRUE:
      return {
        ...state,
        profileError: null,
        isLoading: true,
      };
    case ACTION_SET_PROFILE_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_FETCH_SECURITY_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        securityEligibilityFlow: action.payload.flow,
        otpPhones: action.payload.phone_numbers,
      };
    case ACTION_PROFILE_SAVE_CHANGES_REQUEST:
      return {
        ...state,
        isSaveChangesLoading: true,
      };
    case ACTION_PROFILE_SAVE_CHANGES_RETURNED:
      return {
        ...state,
        isSaveChangesLoading: false,
      };
    case ACTION_FORM_DIRTY_PREVENT_UNLOAD:
      return {
        ...state,
        isFormDirtyPreventUnload: action.payload,
      };
    case ACTION_OTP_INITIATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
