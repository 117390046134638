import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import { ANALYTICS_REWARDS_VIEW_SERVER_ERROR, ANALYTICS_REWARDS_VIEW_SUCCESS } from '../actions';
import {
  RewardLevelsMapping,
  RewardServiceLevels,
} from '../../containers/rewards/rewards.constants';
import type { ReduxState } from '../../reducers';
import type { ServiceLevel } from '../../containers/rewards/rewards.constants';
import getRewardTierInfo from '../../containers/rewards/rewards.utilities';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
    pagefunction: pageTrack.pagefunction.rewards,
  });

const viewSuccess = (action: ServiceLevel, state: ReduxState) => {
  const serviceLevel =
    state.applications.customerInfo &&
    (RewardLevelsMapping[state.applications.customerInfo.serviceLevel] ||
      RewardServiceLevels.BASIC);
  const serviceLevelAttr = serviceLevel && getRewardTierInfo(serviceLevel).title;
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.view,
      attr: serviceLevelAttr && serviceLevelAttr.toLowerCase(),
    })
    .record();
};

const viewServerError = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

export const rewardsPageEvents = {
  [ANALYTICS_REWARDS_VIEW_SUCCESS]: viewSuccess,
  [ANALYTICS_REWARDS_VIEW_SERVER_ERROR]: viewServerError,
} as const;

export default rewardsPageEvents;
