/* eslint-disable react/no-unused-prop-types */
/*
 * Disabled no unused prop types rule because eslint can't follow the use of
 * spread operator in arguments.
 */
import React, { ClipboardEventHandler, MouseEventHandler, Ref, useState } from 'react';
import { TextField as MuiTextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Cleave from 'cleave.js/react';
import { kebabCase } from 'lodash';
import type { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Colors from '../colors/colors';
import ErrorWrapper from '../../containers/newAccountOpening/errorWrapper/errorWrapper';
import pxToRem from '../../utilities/pxToRem';
import i18n from '../../strings/i18n';
import IconButton from '../buttons/iconButton';
import SVGImage from '../svgImage';
import { HideIconSVG, RevealIconSVG } from '../svg/svg';

// IBSS-2527: Fix for MUI input label and Cleave incompatibility.
// Cleave initially renders inputs with an empty value which causes
// MUI to show the label in an empty state. Cleave then immediately
// calls setState with the new value which MUI doesn't pick up on.
// Here we force the initial value to be set to what we pass down.
const cleaveGetInitialState = Cleave.prototype.getInitialState;
Cleave.prototype.getInitialState = function getInitialState() {
  const state = cleaveGetInitialState.call(this);
  return { ...state, value: this.props.value };
};

type FieldProps = {
  id?: string;
  labelId?: string;
  name?: string;
  placeholderText?: string;
  label?: ReactNode;
  value?: string;
  disabled?: boolean;
  onChange?: (arg1: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  onBlur?: (arg1?: any) => void;
  onFocus?: (arg1?: any) => void;
  onCopy?: ClipboardEventHandler;
  onCut?: ClipboardEventHandler;
  onPaste?: ClipboardEventHandler;
  onContextMenu?: MouseEventHandler;
  FormHelperTextProps?: any;
  ariaRequired?: boolean;
  ariaDescribed?: string;
  inputRef?: Ref<any>;
  className?: string;
  classes?: ClassNameMap;
  shrink?: boolean;
  helperText?: string;
  maxLength?: number;
  error?: boolean;
  required?: boolean;
  InputAdornmentProps?: ReactElement;
  // MaterialUI apparently differentiates between lower/uppercase here
  inputProps?: any;
  InputProps?: any;
  fieldinstruction?: string;
};

type BaseTextFieldProps = {
  type: string;
  maxLength?: number;
  ariaLabel?: string;
  ['data-test']?: string;
};

export type CheckProps = {
  ariaLabel?: string;
  ariaRequired?: boolean;
  checked?: boolean;
  classes: any;
  className: string;
  ['data-test']?: string;
  disabled?: boolean;
  id: string;
  inputClassName?: string;
  label: ReactNode;
  labelId: string;
  required?: boolean;
};

const inputStyles = {
  hiddenButton: {
    width: 0,
    height: 0,
    padding: 0,
    border: 0,
    overflow: 'hidden',
  },
  customIconButtonFocus: {
    '&.Mui-focusVisible': {
      padding: pxToRem(0),
      marginRight: pxToRem(8),
    },
  },
  // This is a bit confusing, but it's how material-ui restyles labels for inputs.
  // https://material-ui-next.com/demos/text-fields/
  inputLabel: {
    pointerEvents: 'none',
    '&$cssFocused': {
      color: Colors.text,
    },
  },
  cssFocused: {},
  helperText: {
    paddingLeft: pxToRem(15),
  },
  shrink: {
    transform: `translate(${pxToRem(15)}, ${pxToRem(-5)}) scale(0.75)`,
    transformOrigin: 'top left',
  },
  ssnInput: {
    fontFamily: 'SSN-Font',
  },
} as const;

const useHelperTextOverrideStyles = makeStyles({
  root: {
    fontWeight: 'normal',
  },
});

const useInputStyles = makeStyles(inputStyles);

const passwordOverrideInputStyle = makeStyles({
  root: {
    marginLeft: pxToRem(8),
    paddingTop: pxToRem(2),
    paddingBottom: pxToRem(4),
  },
  passwordInputPosition: {
    marginLeft: pxToRem(-8),
    paddingTop: 0,
    fontSize: pxToRem(16),
  },
  inputFieldLabel: {
    fontSize: pxToRem(14),
  },
});

const BaseTextField = ({
  onChange,
  value,
  error,
  placeholderText,
  id,
  labelId,
  ariaLabel,
  ariaRequired,
  ariaDescribed,
  fieldinstruction,
  label,
  'data-test': dataTest,
  disabled,
  type,
  classes,
  maxLength,
  FormHelperTextProps = {},
  InputProps = {},
  inputProps,
  className,
  shrink,
  helperText,
  ...rest
}: BaseTextFieldProps & FieldProps) => {
  const helperTextClasses = useHelperTextOverrideStyles();
  const inputFieldClasses = useInputStyles();
  // Need to avoid explicitly setting aria-describedby to undefined since MUI generates that for us by default

  let ariaDescribedProps = {};
  if (!error && fieldinstruction) {
    ariaDescribedProps = { 'aria-describedby': fieldinstruction };
  } else if (error) {
    ariaDescribedProps = {};
  } else if (ariaDescribed) {
    ariaDescribedProps = { 'aria-describedby': ariaDescribed };
  }
  const message = helperText || '';
  return (
    <MuiTextField
      fullWidth
      FormHelperTextProps={{
        classes: { root: helperTextClasses.root },
        label,
        error,
        message,
        ...FormHelperTextProps,
      }}
      error={error}
      id={id}
      variant="standard"
      type={type}
      placeholder={placeholderText}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      InputLabelProps={{
        classes: {
          shrink: inputFieldClasses.shrink,
          root: classes?.inputFieldLabel ? classes.inputFieldLabel : inputFieldClasses.inputLabel,
          focused: inputFieldClasses.cssFocused,
        },
        id: labelId,
        disabled,
        shrink,
        // Removes the asterisk added to the input label for a required field
        required: false,
      }}
      inputProps={{
        maxLength,
        'aria-labelledby': labelId,
        'aria-required': ariaRequired,
        'data-test': dataTest,
        className,
        ...inputProps,
        ...ariaDescribedProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...InputProps,
        classes,
      }}
      helperText={helperText}
      {...rest}
    />
  );
};

const TextField = (props: FieldProps) => <BaseTextField type="text" {...props} />;

const EmailField = (props: FieldProps) => <BaseTextField type="email" {...props} />;

type DateFieldProps = {
  onChange: (arg1: React.ChangeEvent<HTMLFormElement>) => void;
  defaultValue?: string;
  inputProps?: any;
};

const dobInputComponent = ({ inputRef, ...props }) => (
  <Cleave htmlRef={inputRef} options={{ date: true, datePattern: ['m', 'd', 'Y'] }} {...props} />
);

export const trialDepositsInputComponent = ({ inputRef, ...props }: FieldProps) => (
  <Cleave
    htmlRef={inputRef}
    options={{
      numericOnly: true,
      stripLeadingZeroes: false,
    }}
    {...props}
  />
);

export const otpInputComponent = ({ inputRef, ...props }: FieldProps) => (
  <Cleave
    htmlRef={inputRef}
    options={{
      numericOnly: true,
    }}
    {...props}
  />
);

const ssnInputComponent = ({ inputRef, ...props }) => (
  <Cleave
    htmlRef={inputRef}
    aria-label="Social Security Number: 9 digits, no dashes"
    options={{
      blocks: [3, 2, 4],
      numericOnly: true,
      delimiter: '-',
    }}
    {...props}
  />
);

const screenReaderMaskedSsnInputComponent = ({ inputRef, ...props }) => (
  <Cleave
    htmlRef={inputRef}
    aria-label="Social Security Number: 9 digits, no dashes"
    options={{
      blocks: [9],
      numericOnly: true,
    }}
    {...props}
  />
);

const currencyInputComponent = ({ inputRef, ...props }) => (
  <Cleave
    htmlRef={inputRef}
    options={{
      prefix: '$',
      noImmediatePrefix: true,
      numeral: true,
      numeralDecimalScale: 2,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      numericOnly: true,
      numeralIntegerScale: 10,
      delimiter: ',',
    }}
    {...props}
  />
);

const wholeCurrencyInputComponent = ({ inputRef, ...props }) => (
  <Cleave
    htmlRef={inputRef}
    options={{
      prefix: '$',
      noImmediatePrefix: true,
      numeral: true,
      numeralDecimalScale: 0,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      numericOnly: true,
      numeralIntegerScale: 10,
      delimiter: ',',
      stripLeadingZeroes: false,
    }}
    {...props}
  />
);

const zipcodeInputComponent = ({ inputRef, ...props }) => (
  <Cleave
    htmlRef={inputRef}
    options={{
      blocks: [5, 4],
      numericOnly: true,
      delimiter: '-',
      delimiterLazyShow: true,
    }}
    {...props}
  />
);

const phoneNumberInputComponent = ({ inputRef, ...props }) => (
  <Cleave
    htmlRef={inputRef}
    options={{
      blocks: [3, 3, 4],
      delimiter: '-',
      numericOnly: true,
      delimiterLazyShow: true,
    }}
    {...props}
  />
);

const BirthDateField = ({ error, ...props }: DateFieldProps & FieldProps) => (
  <BaseTextField
    placeholderText="MM/DD/YYYY"
    FormHelperTextProps={{ error, component: ErrorWrapper }}
    InputProps={{
      inputComponent: dobInputComponent,
    }}
    error={error}
    type="text"
    {...props}
  />
);

const PhoneNumberField = (props: FieldProps) => (
  <BaseTextField
    type="text"
    InputProps={{
      inputComponent: phoneNumberInputComponent,
    }}
    {...props}
  />
);

const PasswordField = (props: FieldProps) => {
  const inputFieldClasses = useInputStyles();
  const passwordInputStyle = passwordOverrideInputStyle();
  const [isMasked, setMasked] = useState(true);
  const [isScreenReaderMasked, setScreenReaderMasked] = useState(false);
  const { classes, disabled, error, id, ...rest } = props;
  return (
    <BaseTextField
      type={isMasked ? 'password' : 'text'}
      FormHelperTextProps={{
        error,
        id: id ? `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}` : '',
        component: ErrorWrapper,
      }}
      className={classNames(
        isMasked ? inputFieldClasses.ssnInput : undefined,
        passwordInputStyle.passwordInputPosition
      )}
      classes={passwordInputStyle}
      InputProps={{
        autoComplete: 'off',
        startAdornment: (
          <button
            type="button"
            disabled={disabled}
            onClick={() => {
              setScreenReaderMasked(!isScreenReaderMasked);
            }}
            className={inputFieldClasses.hiddenButton}
            tabIndex={-1}
            aria-label={`${isScreenReaderMasked ? 'Allow' : 'Prevent'} readout of Password`}
          />
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={inputFieldClasses.customIconButtonFocus}
              title={isMasked ? 'Reveal' : 'Hide'}
              disabled={disabled}
              disableRipple
              data-test={`${kebabCase(id)}-show-hide-icon`}
              aria-label={`${isMasked ? 'Reveal' : 'Hide'} Password (does not affect readout)`}
              onClick={() => {
                setMasked(!isMasked);
              }}
            >
              {isMasked ? (
                <SVGImage
                  iconComponent={<RevealIconSVG aria-hidden="true" focusable="false" role="img" />}
                />
              ) : (
                <SVGImage
                  iconComponent={<HideIconSVG aria-hidden="true" focusable="false" role="img" />}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      error={error}
      id={id}
      {...rest}
    />
  );
};

const SSNField = (props: FieldProps) => {
  const inputFieldClasses = useInputStyles();
  const [isMasked, setMasked] = useState(true);
  const [isScreenReaderMasked, setScreenReaderMasked] = useState(false);
  const { classes, disabled, error, id, ...rest } = props;

  return (
    <BaseTextField
      placeholderText="___-__-____"
      type={isScreenReaderMasked ? 'password' : 'tel'}
      FormHelperTextProps={{
        error,
        id: id ? `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}` : '',
        component: ErrorWrapper,
      }}
      className={isMasked ? inputFieldClasses.ssnInput : undefined}
      InputProps={{
        inputComponent: isScreenReaderMasked
          ? screenReaderMaskedSsnInputComponent
          : ssnInputComponent,
        autoComplete: 'off',
        startAdornment: (
          <button
            type="button"
            disabled={disabled}
            onClick={() => {
              setScreenReaderMasked(!isScreenReaderMasked);
            }}
            className={inputFieldClasses.hiddenButton}
            tabIndex={-1}
            aria-label={`${
              isScreenReaderMasked ? 'Allow' : 'Prevent'
            } readout of Social Security Number`}
          />
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={inputFieldClasses.customIconButtonFocus}
              title={isMasked ? 'Reveal' : 'Hide'}
              disabled={disabled}
              disableRipple
              aria-label={`${
                isMasked ? 'Reveal' : 'Hide'
              } Social Security Number (does not affect readout)`}
              onClick={() => {
                setMasked(!isMasked);
              }}
            >
              {isMasked ? (
                <SVGImage
                  iconComponent={<RevealIconSVG aria-hidden="true" focusable="false" role="img" />}
                />
              ) : (
                <SVGImage
                  iconComponent={<HideIconSVG aria-hidden="true" focusable="false" role="img" />}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      error={error}
      id={id}
      {...rest}
    />
  );
};

const CurrencyField = ({ InputAdornmentProps, ...rest }: FieldProps) => (
  <BaseTextField
    type="text"
    InputProps={{
      inputComponent: currencyInputComponent,
      endAdornment: InputAdornmentProps,
    }}
    {...rest}
  />
);

const useCustomInputLabelStyles = makeStyles({
  labelRoot: {
    // Setting shrink: true in the TextField changes the alignment of the label.
    // This sets it back to its usual value.
    transform: 'translate(0.9375rem, -0.3125rem) scale(0.75)',
  },

  input: {
    '&:not(:focus)::placeholder': {
      color: Colors.charcoalGrey,
      opacity: 1,
    },
  },
});

const WholeCurrencyField = ({ InputAdornmentProps, ...rest }: FieldProps) => {
  const classes = useCustomInputLabelStyles();
  return (
    <BaseTextField
      type="text"
      InputProps={{
        inputComponent: wholeCurrencyInputComponent,
        endAdornment: InputAdornmentProps,
      }}
      // Material UI differentiates based on lowercase input vs uppercase Input here
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ className: classes.input }}
      shrink
      {...rest}
    />
  );
};

const ZipcodeField = (props: FieldProps) => (
  <BaseTextField
    type="text"
    InputProps={{
      inputComponent: zipcodeInputComponent,
    }}
    {...props}
  />
);

const HiddenInput = (props: FieldProps) => (
  <input
    type="hidden"
    id={props.name}
    name={props.name}
    value={props.value}
    disabled={props.disabled}
  />
);

export {
  SSNField,
  CurrencyField,
  BirthDateField,
  EmailField,
  PhoneNumberField,
  PasswordField,
  ZipcodeField,
  HiddenInput,
  TextField,
  WholeCurrencyField,
};
export default TextField;
