import type { OWCSState, OWCSAction } from './owcs.type';

export const ACTION_ADD_OWCS_PAGE_LIST = 'ACTION_ADD_OWCS_PAGE_LIST';
export const ACTION_SET_OWCS_LOADING_STATE = 'ACTION_SET_OWCS_LOADING_STATE';

export const initialState = { pageList: [], loading: true };

export default (
  state: OWCSState | null | undefined = initialState,
  action: OWCSAction = undefined
): OWCSState => {
  switch (action.type) {
    case ACTION_ADD_OWCS_PAGE_LIST:
      return { pageList: action.payload, loading: false };

    case ACTION_SET_OWCS_LOADING_STATE:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};
