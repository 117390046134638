export const getSearchParam = (
  location: {
    search: string;
  },
  key: string
): string | null => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
};

export default getSearchParam;
