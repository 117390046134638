import React from 'react';
import Loadable from 'react-loadable';
import Page from './page';
import PageWithCard from './pageWithCard';

type Options = {
  loader(): Promise<
    | React.ComponentType<any>
    | {
        default: React.ComponentType<any>;
      }
  >;
};

// Displays placeholder component until promise is resolved.
const Loading = (component) => (opts: Options) => {
  return Loadable<any, any>({
    loader: opts.loader,
    loading: (props: any) => {
      if (props.error) {
        // bubble up to error boundary if module fails to load
        throw new Error(props.error);
      }
      return component;
    },
  });
};

export const PageLoading = Loading(<Page loading />);

export const PageWithCardLoading = Loading(<PageWithCard loading />);
