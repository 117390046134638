import React from 'react';
import type { BeneficiaryAddress } from '../../containers/beneficiaries/beneficiaries.constants';

type Props = {
  address: BeneficiaryAddress;
};

export function FormatBeneficiaryAddress(props: Props) {
  const { address } = props;
  return (
    <>
      {address.line1.toLowerCase()}
      {address.line2 && ` ${address.line2}`}
      <br />
      {address.city.toLowerCase()}, {address.state} {address.postalCode}
    </>
  );
}

export default FormatBeneficiaryAddress;
