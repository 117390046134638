import type { RateMatrix } from '../containers/products/products.reducer';
import axios from '../utilities/axios';
import { RATE_MATRICES_URL } from '../utilities/route-mappings';

export type RateMatricesResponse = {
  rateMatrices: Array<RateMatrix>;
};

export type RateMatricesRequestParams = {
  accountType: string;
  accountId: string;
};

export type RateMatricesService = (
  params: RateMatricesRequestParams
) => Promise<RateMatricesResponse>;

const fetchRateMatrices: RateMatricesService = async (params) => {
  const matrixResponse = await axios.get(RATE_MATRICES_URL(params.accountType, params.accountId), {
    params,
  });
  return matrixResponse.data;
};

export default fetchRateMatrices;
