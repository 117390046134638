import { makeStyles } from '@material-ui/core/styles';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import { mediumFontWeight } from '../typography/typography.styles';

export default makeStyles((theme: Theme) => ({
  apy: {
    fontWeight: 'bold',
  },
  apyNumber: {
    fontSize: pxToRem(64),
  },
  apyPercent: {
    fontSize: pxToRem(30),
    lineHeight: 'normal',
  },
  apyPercentContainer: {
    display: 'inline-block',
  },
  apyApy: {
    fontSize: pxToRem(12),
  },
  apyUpTo: {
    fontSize: pxToRem(18),
    marginRight: pxToRem(8),
    wordBreak: 'break-word',
  },
  greenCheck: {
    marginRight: pxToRem(8),
  },
  minBalanceText: {
    fontSize: pxToRem(14),
    fontWeight: 'bold',
  },
  perksList: {
    marginTop: pxToRem(24),
    '& > * + *': {
      marginTop: pxToRem(12),
    },
  },
  perksListItem: {
    display: 'flex',
  },
  productTileContent: {
    backgroundColor: '#fafafa',
    padding: `${pxToRem(40)} ${pxToRem(20)} ${pxToRem(32)} ${pxToRem(20)}`,
    boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: pxToRem(32),
      paddingRight: pxToRem(32),
    },
  },
  subTitle: {
    fontSize: pxToRem(16),
    fontWeight: mediumFontWeight,
    marginTop: pxToRem(8),
    marginBottom: pxToRem(8),
  },
}));
