import basicAxios from 'axios';
import type { FetchOWCSPageDetailsService, FetchOWCSPageService } from './owcs.type';

const { OWCS_URL, OWCS_CLIENT_ID, OWCS_CONTENT_ORIGIN_PARAM } = window.__config__;
const headers = { 'X-SYF-Client-Id': OWCS_CLIENT_ID } as const;

export const fetchOWCSPageListService: FetchOWCSPageService = async () => {
  const { data } = await basicAxios.get(OWCS_URL, {
    headers,
    params: { contentOrigin: OWCS_CONTENT_ORIGIN_PARAM },
  });

  return data;
};

export const fetchOWCSPageContentService: FetchOWCSPageDetailsService = async (pageId: number) => {
  const { data } = await basicAxios.get(`${OWCS_URL}${pageId}`, {
    headers,
    params: { contentOrigin: OWCS_CONTENT_ORIGIN_PARAM },
  });

  return data.Page;
};
