import { getFormSyncErrors } from 'redux-form';
import type { ReduxState } from '../reducers';

export const sectionHasErrors = (formId: string, sectionId: string, state: ReduxState): boolean =>
  !!getFormSyncErrors(formId)(state)[sectionId];

export const normalizeDateOfBirth = (value?: string | null) =>
  value && value.replace(/^(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

export const formatDateOfBirth = (value?: string | null) =>
  value ? value.replace(/^(\d+)-(\d+)-(\d+)/, '$2/$3/$1') : '';

export const normalizeDashes = (value?: string | null) => (value ? value.replace(/-/g, '') : '');
