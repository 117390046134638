import axios from '../../utilities/axios';
import type { MarketingAlertsInfo, MarketingAlertsUpdate } from './marketingAlerts.constants';
import { MARKETING_PREFERENCES_INFO_URL } from '../../utilities/route-mappings';

export type GetMarketingAlertsService = () => Promise<MarketingAlertsInfo>;

export type UpdateMarketingAlertsService = (
  arg1: MarketingAlertsUpdate
) => Promise<MarketingAlertsInfo>;

export const fetchMarketingAlerts: GetMarketingAlertsService = async () => {
  const response = await axios.get(MARKETING_PREFERENCES_INFO_URL());
  return response.data;
};

export const updateMarketingAlerts: UpdateMarketingAlertsService = async (request) => {
  const response = await axios.put(MARKETING_PREFERENCES_INFO_URL(), request);
  return response.data;
};
