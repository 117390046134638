import fetchJose from '../utilities/fetchJose.ts';
import Worker from './generateKey.worker';

class SingletonWorker {
  worker;

  keyPair;

  constructor() {
    // Storybook environment doesn't compile worker scripts
    if (!Worker || typeof Worker === 'undefined') {
      throw new Error('Invalid worker');
    }

    this.worker = new Worker();
    this.keyPair = new Promise((resolve, reject) => {
      if (!this.worker) throw new Error('Worker not found');
      this.worker.addEventListener(
        'message',
        (event) => {
          resolve(event.data);
          this.terminate();
        },
        false
      );
      this.worker.addEventListener(
        'error',
        (err) => {
          reject(err);
          this.terminate();
        },
        false
      );
      this.worker.addEventListener(
        'messageerror',
        () => {
          reject(new Error('Invalid message returned'));
          this.terminate();
        },
        false
      );
    });
  }

  generateKey() {
    if (!this.worker) throw new Error('Invalid worker found');
    return this.worker.postMessage({ intent: 'generateKey' });
  }

  async getKeyPair() {
    this.generateKey();
    return this.keyPair;
  }

  async getPublicKey() {
    if (!this.keyPair || !fetchJose) throw new Error('Invalid key pair or package found');
    this.generateKey();
    const jose = await fetchJose();
    const key = await jose.JWK.asKey(await this.keyPair);
    return key.toJSON();
  }

  terminate() {
    if (!this.worker) return;
    this.worker.terminate();
  }
}

const instance = new SingletonWorker();
export default Object.freeze(instance);
