import type { AllAccountTypeName } from 'Domain/Account/AllAccount';
import type { ProductDisplayType, AccountDetailsType } from 'Domain/Account/AccountDetails';
import basicAxios from 'axios';
import { SortByAccountOptions } from '../../components/cms/sortAccounts.constants';
import type { ProductTypesName, Promotion } from '../products/products.reducer';
import type { Account, CdInformation } from '../../utilities/types';
import axios from '../../utilities/axios';
import {
  ALL_ACCOUNTS_DASHBOARD_URL,
  DOWNTIME_BASE_URL,
  ALL_STATEMENTS_URL,
} from '../../utilities/route-mappings';
import { ProductTypes } from '../products/products.reducer';

export type DashboardDepositAccount = {
  accountApy: string;
  accountId: string;
  accountIdDisplay: string;
  accountStatus:
    | 'Active'
    | 'Inactive'
    | 'Closed'
    | 'Dormant'
    | 'Matured'
    | 'Restricted'
    | 'Unknown'
    | '';
  accountType: AccountDetailsType;
  availableBalance: string;
  cdInformation?: CdInformation;
  closedDate?: string;
  currentBalance: string;
  eDelivery: boolean;
  external: boolean;
  funded: boolean;
  nickname: string;
  openedDate?: string;
  owner: boolean;
  primaryOwner: boolean;
  product: {
    creatable?: boolean;
    detail?: {
      displayText: string;
      url: string;
    };
    interest?: {
      accrualMethod: string;
      paymentFrequency: string;
      paymentPeriod: string;
      yearBase: string;
    };
    interestRateId?: string;
    maxOpenAmount?: number | null;
    minOpenAmount?: number | null;
    productDefinitionType?: string;
    productDisplayType: ProductDisplayType;
    productId: string;
    productName: AllAccountTypeName;
    productType: AllAccountTypeName;
    productTypeId?: string;
    productTypeName: ProductTypesName;
    promotion?: Promotion;
    rateMatrices?: {
      apy: number;
      rate: number;
      maxBalance: number;
      minBalance: number;
      balanceRange: string;
    }[];
    term?: number;
    termUnits?: string;
    tiers?: {
      amount: string;
      rate: string;
    }[];
  };
  rate: string;
  relationshipType: string;
  transfersFromEnabled: boolean;
  transfersToEnabled: boolean;
  type?: string;
};

export type StatementsDepositAccount = {
  accountId: string;
  accountIdDisplay: string;
  accountType: string;
  date: string;
  documentId: string;
  nickname: string;
  productDisplayType: string;
};

export type DashboardDepositAccountsResponse = {
  accounts: DashboardDepositAccount[];
  customerTotalBalance: string;
  asOfDate: string;
  nextPageAvailable: boolean;
};

export type StatementsDepositAccountsResponse = Array<StatementsDepositAccount>;

export const parseDashboardDepositAccountResponse = (
  account: DashboardDepositAccount
): Account => ({
  accountApy: account.accountApy,
  accountId: account.accountId,
  accountIdDisplay: account.accountIdDisplay,
  accountStatus: account.accountStatus,
  accountType: account.accountType,
  accountTypeDisplayName: account.product.productType.includes(ProductTypes.CertificateOfDeposit)
    ? account.product.productName
    : account.product.productType,
  availableBalance: account.availableBalance,
  cdInformation: account.cdInformation,
  creatable: account.product.creatable,
  eDelivery: account.eDelivery,
  external: account.external,
  funded: account.funded,
  nickname: account.nickname,
  openedDate: account.openedDate,
  owner: account.owner,
  primaryOwner: account.primaryOwner,
  productDisplayType: account.product.productDisplayType,
  productId: account.product.productId,
  productType: account.product.productType,
  rate: account.rate,
  relationshipType: account.relationshipType,
  transfersFromEnabled: account.transfersFromEnabled,
  transfersToEnabled: account.transfersToEnabled,
  totalBalance: account.currentBalance,
  type: account.type,
});

export type FetchAccountsPayload = {
  accounts: Account[];
  customerTotalBalance: string;
  asOfDate?: string;
  nextPageAvailable: boolean;
  isRefetch?: boolean;
};

export type AccountsServiceParams =
  | {
      purpose: 'dashboard' | 'beneficiary' | 'statements';
      year?: number;
      pageNumber?: number;
      isRefetch?: boolean;
    }
  | {
      purpose: 'dashboard' | 'beneficiary' | 'statements';
      pageNumber: number;
      sortBy: SortByAccountOptions;
      isRefetch?: boolean;
    };

export type AccountsService = (params?: AccountsServiceParams) => Promise<FetchAccountsPayload>;

export type StatementsService = () => Promise<StatementsDepositAccountsResponse>;

export const fetchAccountsService: AccountsService = (
  params: AccountsServiceParams = { purpose: 'dashboard' }
) =>
  axios.get(ALL_ACCOUNTS_DASHBOARD_URL(), { params }).then((res) => {
    const dashboardDepositAccounts: DashboardDepositAccountsResponse = res.data.data;
    return {
      customerTotalBalance: dashboardDepositAccounts.customerTotalBalance,
      accounts: dashboardDepositAccounts.accounts.map(parseDashboardDepositAccountResponse),
      asOfDate: dashboardDepositAccounts.asOfDate,
      nextPageAvailable: dashboardDepositAccounts.nextPageAvailable,
    };
  });

export const fetchStatementAccountsService: StatementsService = () =>
  axios.get(ALL_STATEMENTS_URL()).then((res) => res.data.data as StatementsDepositAccountsResponse);

export type Downtime = {
  start_date_time: Date;
  stop_date_time: Date;
  notification_message: string;
  downtime_message: string;
};
export type FetchDowntimePayload = {
  status: {
    response_code: string;
    response_desc: string;
  };
  maintenance: {
    downtime: Downtime[];
  };
};

export type DowntimeService = () => Promise<FetchDowntimePayload>;

export const fetchDowntimeService: DowntimeService = () =>
  basicAxios.get(DOWNTIME_BASE_URL()).then((res) => res.data);
