import { withStyles, Link, Hidden, StyleRules } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect, ConnectedComponent } from 'react-redux';
import classnames from 'classnames';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { startSurfly } from '../../utilities/surfly';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';
import { BodyText } from '../typography/typography';
import type { StyleProps, Theme } from '../../utilities/types';
import { SynchronyInfo } from '../cms/blockText.constants';
import FooterInfo from './footer.constants';
import type { ReduxState } from '../../reducers';
import { clickTrack } from '../../analytics/clickTracking.constants';
import { Alignment } from '../../styles/layout/alignment.styles';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const anchorLinkBaseStyles: CSSProperties = {
  lineHeight: 1,
  fontWeight: 'bold',
  letterSpacing: pxToRem(0.3),
  '&:hover, &:visited': {
    textDecoration: 'none',
    borderBottom: `${pxToRem(1)} solid`,
    paddingBottom: `${pxToRem(2)}`,
  },
};

const styles = (theme: Theme): StyleRules => ({
  footer: {
    background: Colors.charcoalGrey,
    padding: `${pxToRem(20)} ${pxToRem(15)}`,
    [theme.breakpoints.up('sm')]: {
      padding: `${pxToRem(10)} ${pxToRem(30)}`,
    },
  },
  footerLight: {
    ...Alignment.footerPadding,
    background: Colors.backgroundGrey,
    padding: `${pxToRem(20)} ${pxToRem(15)}`,
    [theme.breakpoints.up('sm')]: {
      padding: `${pxToRem(10)} ${pxToRem(30)}`,
    },
  },
  footerLinks: {
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: `${pxToRem(14)}`,
  },
  linkContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
  },
  rightColumn: {
    marginTop: pxToRem(15),
    minWidth: pxToRem(70),
    marginBottom: pxToRem(15),
  },
  linkWrapper: {
    textTransform: 'uppercase',
    marginRight: pxToRem(40),
    marginBottom: pxToRem(10),
    marginTop: pxToRem(12),
    [theme.breakpoints.up('sm')]: {
      marginBottom: pxToRem(10),
    },
  },
  linkWrapperLightFooter: {
    textTransform: 'uppercase',
    marginRight: pxToRem(26),
    marginTop: pxToRem(12),
    marginBottom: pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      marginBottom: pxToRem(0),
    },
  },
  linkWrapperFill: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  anchorLink: {
    ...anchorLinkBaseStyles,
    color: Colors.white,
  },
  anchorLinkLightFooter: {
    ...anchorLinkBaseStyles,
    fontSize: (props: Props) => (props.isHalfScreen ? pxToRem(12) : pxToRem(14)),
    color: Colors.footerGray,
  },
  member: {
    fontSize: pxToRem(17),
    letterSpacing: pxToRem(-0.3),
    lineHeight: pxToRem(0.8),
  },
  fdic: {
    fontSize: pxToRem(28),
    fontWeight: 700,
  },
  copyRight: {
    alignSelf: 'flex-start',
  },
  flexLineBreak: {
    flexBasis: '100%',
    height: '0px',
  },
});

type FooterProps = {
  isLight?: boolean;
  isHalfScreen?: boolean;
};

type StateProps = {
  affinityCompanyCode?: string;
};

type Props = FooterProps & StyleProps & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  affinityCompanyCode:
    state.applications.customerInfo && state.applications.customerInfo.affinityCompanyCode,
});

type FooterLinkProps = {
  to?: string;
  ['data-test']: string;
  ['data-track-title']: string;
  title: string;
  href?: string;
  onClick?: MouseEventHandler;
  rel?: string;
  role?: string;
  fill?: boolean;
  isLight?: boolean;
  isHalfScreen?: boolean;
} & StyleProps &
  StateProps;

const FooterLink = withStyles(styles)(
  ({
    classes,
    to,
    children,
    affinityCompanyCode,
    isLight,
    fill,
    isHalfScreen,
    ...rest
  }: FooterLinkProps) => {
    const footerLinkClassName = isLight ? classes.anchorLinkLightFooter : classes.anchorLink;
    const footerLinkhref = affinityCompanyCode ? `${to}?UISCode=${affinityCompanyCode}` : to;
    return (
      <li
        className={classnames(isLight ? classes.linkWrapperLightFooter : classes.linkWrapper, {
          [classes.linkWrapperFill]: fill,
        })}
      >
        {to ? (
          <Link className={footerLinkClassName} href={footerLinkhref} target="_blank" {...rest}>
            {children}
          </Link>
        ) : (
          <Link className={footerLinkClassName} {...rest}>
            {children}
          </Link>
        )}
      </li>
    );
  }
);

export const Footer = ({ classes, affinityCompanyCode, isLight, isHalfScreen }: Props) => {
  const footerlinkProps = { isLight, isHalfScreen };
  return (
    <footer
      id="footer"
      className={isLight ? classes.footerLight : classes.footer}
      role="contentinfo"
    >
      <div className={classes.footerLinks}>
        <div className={classes.leftColumn}>
          <ul className={classes.linkContainer}>
            <FooterLink
              {...footerlinkProps}
              to={FooterInfo.TERMS_URL}
              data-test="footer-link-online-terms"
              data-track-title={clickTrack.footer.terms}
              affinityCompanyCode={affinityCompanyCode}
              title="Opens new window."
            >
              {FooterInfo.TERMS}
            </FooterLink>
            <FooterLink
              {...footerlinkProps}
              to={FooterInfo.ONLINE_PRIVACY_POLICY_URL}
              data-test="footer-link-privacy"
              data-track-title={clickTrack.footer.privacy}
              affinityCompanyCode={affinityCompanyCode}
              title="Opens new window."
            >
              {FooterInfo.ONLINE_PRIVACY_POLICY}
            </FooterLink>
            <FooterLink
              {...footerlinkProps}
              to={FooterInfo.DISCLOSURES_URL}
              data-test="footer-link-disclosures"
              data-track-title={clickTrack.footer.disclosures}
              affinityCompanyCode={affinityCompanyCode}
              title="Opens new window."
            >
              {FooterInfo.DISCLOSURES}
            </FooterLink>
            <FooterLink
              {...footerlinkProps}
              to={FooterInfo.ACCOUNT_PRIVACY_POLICY_URL}
              data-test="footer-link-account-privacy"
              fill={!isHalfScreen}
              data-track-title={clickTrack.footer.privacy_policy}
              affinityCompanyCode={affinityCompanyCode}
              title="Opens new window."
            >
              {FooterInfo.ACCOUNT_PRIVACY_POLICY}
            </FooterLink>
            {window.Surfly && window.__config__.SURFLY_ENABLED === 'true' ? (
              <FooterLink
                {...footerlinkProps}
                data-test="footer-link-co-browse"
                data-track-title={clickTrack.footer.coBrowse}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  startSurfly();
                }}
                rel="nofollow"
                role="button"
                title="Start Cobrowse session"
              >
                {FooterInfo.COBROWSE}
              </FooterLink>
            ) : null}
            <FooterLink
              {...footerlinkProps}
              data-test="footer-link-contact-us"
              to={FooterInfo.CONTACT_US_URL}
              data-track-title={clickTrack.footer.contact}
              affinityCompanyCode={affinityCompanyCode}
              title="Opens new window."
            >
              {FooterInfo.CONTACT_US}
            </FooterLink>
            <FooterLink
              {...footerlinkProps}
              to={FooterInfo.FAQ_URL}
              data-test="footer-link-faq"
              data-track-title={clickTrack.footer.faq}
              affinityCompanyCode={affinityCompanyCode}
              title="Opens new window."
            >
              {FooterInfo.FAQ}
            </FooterLink>
            <li aria-hidden="true" className={classes.flexLineBreak} />
            <Hidden xsDown>
              <li className={classes.copyRight}>
                <BodyText
                  textColor={isLight ? Colors.footerGray : Colors.white}
                  style={{ fontSize: pxToRem(12) }}
                >
                  &copy; {moment().year()} {SynchronyInfo.BANKNAME}. All Rights Reserved.
                </BodyText>
              </li>
            </Hidden>
          </ul>
        </div>
        <div className={classes.rightColumn}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              affinityCompanyCode
                ? `${FooterInfo.FDIC_URL}?UISCode=${affinityCompanyCode}`
                : FooterInfo.FDIC_URL
            }
            aria-label="Member FDIC"
            title="Opens new window."
            data-test="footer-link-fdic"
            data-track-title={clickTrack.footer.fdic}
          >
            <SVGImage
              imageName={ImagesFileNames.memberFdicSvg}
              ariaHidden="true"
              fill={isLight ? Colors.footerGray : Colors.white}
            />
          </a>
        </div>
      </div>
      <Hidden smUp>
        <BodyText
          textColor={isLight ? Colors.footerGray : Colors.white}
          style={{ fontSize: pxToRem(12) }}
        >
          &copy; {moment().year()} {SynchronyInfo.BANKNAME}. All Rights Reserved.
        </BodyText>
      </Hidden>
    </footer>
  );
};

export default compose<ConnectedComponent<any, Props>>(
  connect(mapStateToProps, null),
  withStyles(styles)
)(Footer);
