import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import ButtonLink from '../../../components/links/buttonLink';
import { StandardText } from '../../../components/typography/typography';
import pxToRem from '../../../utilities/pxToRem';
import type { StyleProps } from '../../../utilities/types';
import type { ReduxState } from '../../../reducers';
import FormWithImage from '../../../components/formWithImage/formWithImage';
import type { CtaLinkData, CtaText } from '../newAccountOpening.constants';
import {
  CancelApplicationCtaText,
  ContactUsCtaText,
  ExitApplicationLinkData,
  FailApplicationText,
  PendingApplicationText,
  RegistrationFailedText,
  TryAgainApplicationLinkData,
  TryAgainApplicationText,
} from '../newAccountOpening.constants';
import { OWCSPageNames, OWCSPageSectionsNames } from '../../owcs/owcs.constants';
import { useOWCSContent } from '../../owcs/useOWCSContent';
import getDisplayNameFromSections from '../../owcs/owcs.utils';
import getImageSrc from '../../../utilities/getImageSrc';
import ImagesFileNames from '../../../images';

const styles = {
  textSection: { marginBottom: pxToRem(20), whiteSpace: 'pre-wrap' },
  overrideCtaContainer: { marginTop: pxToRem(5) },
  buttonContainer: { display: 'flex', marginTop: pxToRem(50) },
} as const;

type Props = {
  ctaText: CtaText;
  ctaLinkData?: CtaLinkData;
  isLoading?: boolean;
};

type StateProps = {
  isLoggedIn: boolean;
};

type AllProps = Props & StateProps & StyleProps;

const mapStateToProps = (state: ReduxState) => ({
  isLoggedIn: state.authenticate.isLoggedIn,
  lastApplicationResponse: state.applications.lastApplicationResponse,
});

export const ExitApplicationContainer = (props: AllProps) => {
  const { classes, isLoggedIn, ctaText, ctaLinkData = ExitApplicationLinkData, isLoading } = props;
  const link = isLoggedIn ? ctaLinkData.EAO_LINK : ctaLinkData.NAO_LINK;

  // Constructing Images URLs
  const rightPanelImageUrl = getImageSrc(ImagesFileNames.accountOpeningJpg);
  const rightPanelImageUrlLg = getImageSrc(ImagesFileNames.accountOpening_2xJpg);
  const rightPanelImageUrlXl = getImageSrc(ImagesFileNames.accountOpening_3xJpg);

  return (
    <Grid container className={classes.parentContainer} spacing={0} alignItems="stretch">
      <FormWithImage
        imageUrl={rightPanelImageUrl}
        imageUrlLg={rightPanelImageUrlLg}
        imageUrlXl={rightPanelImageUrlXl}
        showMarketingMessage
        classes={{ ctaContainer: classes.overrideCtaContainer }}
        ctaText={ctaText.CTA_HEADER}
        useLoadingAnimation={false}
        loading={isLoading}
      >
        <Grid item className={classes.textSection}>
          <StandardText data-test="cta-copy">{ctaText.CTA_COPY}</StandardText>
        </Grid>
        {ctaText.CTA_SUBCOPY && (
          <Grid item>
            <StandardText data-test="cta-subcopy">{ctaText.CTA_SUBCOPY}</StandardText>
          </Grid>
        )}
        <Grid item className={classes.buttonContainer}>
          <ButtonLink
            external={!isLoggedIn && link.external}
            to={link.CTA_URL}
            data-test="return-button"
          >
            {link.CTA_TEXT}
          </ButtonLink>
        </Grid>
      </FormWithImage>
    </Grid>
  );
};

const connectedComponent = connect(mapStateToProps)(ExitApplicationContainer);
const ExitApplication = withStyles(styles)(connectedComponent);

export default ExitApplication;

export const CancelApplication = () => {
  const { loading, sections } = useOWCSContent(OWCSPageNames.SERVICING_SUPPORT);

  return (
    <ExitApplication
      ctaText={CancelApplicationCtaText(
        getDisplayNameFromSections(sections, OWCSPageSectionsNames.SERVICING_BANKERS_HOURS)
      )}
      isLoading={loading}
    />
  );
};

type ContactUsProps = {
  contactUsCode: string;
};

const mapStateToContactUsProps = (state: ReduxState) => ({
  contactUsCode: state.applications.contactUsCode,
});

export const ContactUsApplication = connect(mapStateToContactUsProps)((props: ContactUsProps) => {
  const { contactUsCode } = props;
  const { loading } = useOWCSContent(OWCSPageNames.SERVICING_SUPPORT);

  return <ExitApplication ctaText={ContactUsCtaText(contactUsCode)} isLoading={loading} />;
});

type InitFailConnectedProps = {
  fetchAttempts: number;
};

const mapStateToInitFailProps = (state: ReduxState) => ({
  fetchAttempts: state.applications.fetchAttempts,
});

export const InitFailApplication = connect(mapStateToInitFailProps)(
  ({ fetchAttempts }: InitFailConnectedProps) => {
    let ctaText = FailApplicationText;
    let ctaLinkData = ExitApplicationLinkData;
    if (fetchAttempts <= 3) {
      ctaText = TryAgainApplicationText;
      ctaLinkData = TryAgainApplicationLinkData;
    }
    return <ExitApplication ctaText={ctaText} ctaLinkData={ctaLinkData} />;
  }
);

export const PendingApplication = () => <ExitApplication ctaText={PendingApplicationText} />;

export const RegistrationFailed = () => <ExitApplication ctaText={RegistrationFailedText} />;
