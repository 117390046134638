import { SynchronyInfo } from './blockText.constants';

export const LoadMoreTransactionsBtn = Object.freeze({
  CALL_TO_ACTION: 'Load more activity',
  DISABLED: 'No more activity',
  LOADING: 'Loading more activity',
});

export const LoadMoreDocumentsBtn = Object.freeze({
  CALL_TO_ACTION: 'Load more documents',
  DISABLED: 'No more documents',
  LOADING: 'Loading more documents',
});

export const FormSubmissionBtn = Object.freeze({
  AO_SUBMIT_APPLICATION: 'Submit Application',
  TRY_AGAIN: 'Try Again',
});

export const WelcomePageBtn = Object.freeze({
  CONTINUE_TO_NAO: `Yes, open a ${SynchronyInfo.BANKNAME} account`,
  CONTINUE_TO_EAO: `I'm already a customer`,
  CREATE_USERNAME: 'Register for online access',
});

export const ModalBtnText = Object.freeze({
  ACTIVITY_MODAL_BTN: 'Stay Signed In',
  ADD_BENEFICIARY: 'Add Beneficiary',
  AO_ACTIVITY_MODAL_BTN: 'Continue',
  BEGIN_ORDER_BTN: 'Begin Order',
  CANCEL_CARD_NO_BTN: 'No, Keep Card',
  CANCEL_CARD_YES_BTN: 'Yes, Cancel Card',
  CANCEL_CD_RENEWAL: 'Yes, exit',
  CANCEL_CONTINUE_BTN: 'Continue application',
  CANCEL_EXIT_BTN: 'Yes, Exit',
  CANCEL_GENERIC: 'Cancel',
  CANCEL_LEAVE_PAGE_BTN: 'Leave Page',
  CANCEL_NO_CONTINUE_BTN: 'No, continue',
  CANCEL_NO_GO_BACK_BTN: 'No, Go Back',
  CANCEL_NO_KEEP_EDITING_BTN: 'No, Keep Editing',
  CANCEL_TRANSFER_CANCELLATION: 'No, keep',
  CLOSE_GENERIC: 'Close',
  CONTINUE_CD_RENEWAL: 'Continue adding instructions',
  CONTINUE_TRANSFER_CANCELLATION: 'Yes, cancel transfer',
  DONE_BTN: 'Done',
  EXISTING_CUSTOMER_LOGIN_BTN: 'Sign in',
  EXISTING_CUSTOMER_REGISTER_BTN: 'Register',
  GO_TO_ADD_EXTERNAL_ACCOUNT: 'Yes, go to add external account',
  MANAGE_BENEFICIARIES: 'Manage Beneficiaries',
  OK_BTN: 'OK',
  OKAY_BTN: 'Okay',
  REMOVE_BENEFICIARY_BTN: 'Yes, Remove',
  REVERT_CHANGES_BTN: 'Yes, Revert Changes',
  SAVE: 'Save',
  SAVE_CHANGES: 'Save Changes',
  TRY_AGAIN: 'Try again',
  YES_CONTINUE: 'Yes, Continue',
  YES_LEAVE_PAGE_BTN: 'Yes, Leave Page',
  REMOVE_INTEREST_PAYMENT_ACCOUNT_BTN: 'Yes, Stop Payments',
  BACK_TO_TRANSFERS_BTN: 'Back to Transfers',
  CONTINUE_FUNDING_BTN: 'Continue Funding',
});

export const BackNextAndCancelBtn = Object.freeze({
  ADD_ACCOUNT: 'Add account',
  AUTO_RENEW_MY_CD: 'Auto Renew My CD',
  BACK: 'Back',
  BACK_TO_INBOX: 'Back To Inbox',
  CANCEL_GENERIC: 'Cancel',
  CANCEL_ADDING_INSTRUCTIONS: 'Cancel Adding Instructions',
  CANCEL_INTEREST_PAYMENTS: 'Cancel Setting Up Monthly Interest Payments',
  CANCEL_APPLICATION: 'Cancel and exit application',
  CANCEL_EDITING: 'Cancel editing',
  CONFIRM: 'Confirm',
  CONFIRM_AND_SAVE_CHANGES: 'Confirm & Save Changes',
  CONFIRM_PAYMENT_ACCOUNT: 'Confirm Payment Account',
  CONTINUE: 'Continue',
  GO_BACK: 'Go back',
  INFO_NOT_CORRECT: 'This information is not correct',
  NEXT_STEP: 'Next step',
  DONE: 'Done',
  DONE_EDITING: 'Done editing',
  NEXT_VERIFY_TRIAL_DEPOSITS: 'Verify Account',
  NEXT_COMPLETE_TRANSFER: 'Complete Transfer',
  OPEN_NEW_ACCOUNT: 'Open a new HYS account',
  REMOVE_TRANSFER: 'Remove Transfer',
  RENEW_WITH_NO_CHANGES: 'Renew with no changes',
  REVIEW_CHANGES: 'Review Changes',
  RETURN_TO_DASHBOARD: 'Return to Dashboard',
  SEND_CODE: 'Send Code',
  SEND_MESSAGE: 'Send Message',
  STOP_PAYMENTS: 'Stop Payments',
  VERIFY_CODE: 'Verify Code',
  UPDATE_INSTRUCTIONS: 'Update Instructions',
  SAVE_CHANGES: 'Save Changes',
  REVERT_CHANGES: 'Revert Changes',
  BACK_TO_ACCOUNT_DETAILS: 'Back to Account Details',
  REMOVE_INSTRUCTIONS: 'Remove Instructions',
});

export const DashboardAlertsBtn = Object.freeze({
  HIDE_ALERTS: 'Hide Alerts',
  SHOW_MORE: (alertsToShow: number) => `Show ${alertsToShow} More`,
});

export const AccountActivityBtn = Object.freeze({
  ACCOUNT_ALERTS: 'Account Alerts',
  ACCOUNT_ALERTS_OFFERS: 'Offers & Account Alerts',
  ADD_ADDRESS: 'Add Address',
  ADD_EMAIL_ADDRESS: 'Add Email Address',
  EDIT_ACCOUNT_NICKNAME: 'Edit Account Nickname',
  EDIT_BENEFICIARIES: 'Edit Beneficiaries',
  E_PREFERENCES: 'E-PREFERENCES',
  EXPLORE_PRODUCTS: 'EXPLORE PRODUCTS',
  BANK_FORMS: 'BANK FORMS',
  DOCUMENT_CENTER: 'DOCUMENT CENTER',
  INTEREST_PAYMENTS: 'Interest Payments',
  MOVE_MONEY: 'MOVE MONEY',
  MAKE_A_TRANSFER: 'Make a transfer',
  MANAGE_BENEFICIARIES: 'Manage Beneficiaries',
  MANAGE_OVERDRAFT_PROTECTION: 'Manage Overdraft Protection',
  OFFERS: 'Offers',
  ORDER_CHECKS_AND_DEPOSIT_SLIPS: 'Order Checks & Deposit Slips',
  ORDER_DEPOSIT_SLIPS: 'Order Deposit Slips',
  STATEMENTS: 'Statements',
  STOP_CHECK_PAYMENT: 'Stop Check Payment',
  VIEW_ACCOUNT_ACTIONS: 'View Account Actions',
  VIEW_ACCOUNT_INFO: 'View Account Info',
  VIEW_ALL_REWARDS: 'View all rewards features and benefits',
  VIEW_PERKS_TERMS_AND_CONDITIONS: 'View perks terms and conditions',
  VIEW_TRAVEL_BENEFITS: 'View your travel benefits',
  VIEW_STATEMENTS: 'VIEW STATEMENTS',
  VIEW_ALL_RATES_AND_TERMS: 'VIEW ALL RATES AND TERMS',
});

export const ProfileBtn = Object.freeze({
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  REVERT_CHANGES: 'Revert Changes',
  SAVE_CHANGES: ModalBtnText.SAVE_CHANGES,
  CANCEL_CARD: 'Cancel Card',
});

export const CdRenewalBtn = Object.freeze({
  EDIT: 'Edit',
});

export const ExternalAccountListBtn = Object.freeze({
  REMOVE_ACCOUNT: 'REMOVE ACCOUNT',
});

export const EditNickNameBtn = Object.freeze({
  EDIT: 'Edit',
  SAVE: 'Save',
  CANCEL: 'Cancel',
});

export const AccountAlertsBtn = Object.freeze({
  EDIT_ALERTS: 'Edit Alerts',
  CANCEL: 'Cancel',
  SAVE_CHANGES: 'Save Changes',
});

export const BeneficiariesBtn = Object.freeze({
  ADD_NEW_BENEFICIARY: 'Add New Beneficiary',
  EDIT: 'Edit',
  REMOVE: 'Remove',
});

export const InterestsPaymentsBtn = Object.freeze({
  DONE_BUTTON_TEXT: 'Done',
  DONE_BUTTON_TITLE: 'Back to Account Details',
});

export const DocumentsBtn = Object.freeze({
  VIEW_DOCUMENT: 'View Document',
  TRY_AGAIN: 'Try Again',
});

export const MessageBtn = Object.freeze({
  ADD_ATTACHMENT: 'Add Attachment',
});

export const SelectCDInterestPageBtn = Object.freeze({
  OPEN_AN_ACCOUNT: 'Open An Account',
});

export const PromoProductBtn = Object.freeze({
  PROMO_DETAILS: 'Promo Details',
});

export const ExploreProductsBtn = Object.freeze({
  RATES_AND_TERMS: 'Important Disclosures and All Product Rates and Terms',
  EXPLORE_SAVINGS_PRODUCTS: 'Explore savings products',
});

export const EPreferencesBtn = Object.freeze({
  MANAGE_TAX_DOCUMENT: 'Manage Tax Document Preferences',
});

export const RateAndTermsBtn = Object.freeze({
  OPEN_BANK_ACCOUNT: 'Open a Bank Account',
});
