import React, { useState, useEffect, useRef } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import type { FormRenderProps } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'bank-component-library/ui/atoms/Button';
import type {
  AccountAlertsPreferencesInfo,
  Alert,
  AlertsEligibleAccount,
  UpdateAccountAlertsPreferencesResponse,
} from './accountAlerts.constants';
import { AccountAlertsFieldNames, AccountAlertsType } from '../../form.constants';
import { useAccountAlertsStyles } from './accountAlerts.styles';
import { buildAccountNameWithProduct } from '../../formatters/buildAccountName';
import { GhostButton } from '../../components/buttons/buttons';
import { AccountAlertsBtn, ModalBtnText } from '../../components/cms/buttonText.constants';
import { ModalText } from '../../components/cms/blockText.constants';
import { RenderCheck, renderWholeCurrencyField } from '../../components/finalForm/finalFormInputs';
import type { AccountAlertsForm, Dispatch } from '../../utilities/types';
import { Header3, StandardText, ScreenReaderText } from '../../components/typography/typography';
import { updateAccountAlertsPreferences } from './accountAlerts.service';
import {
  setFlashMessage,
  clearFlashMessage as clearFlashMessageAction,
} from '../../components/flashMessage/flashMessage.reducer';
import {
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import { normalizeDollarStringToNumber } from '../../formatters/currency';
import {
  ANALYTICS_ACCOUNT_ALERTS_SUBMIT_FAILURE,
  ANALYTICS_ACCOUNT_ALERTS_SUBMIT_SUCCESS,
} from '../../analytics/actions';
import { validateAmount } from '../../utilities/validators';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import useEffectOnce from '../../utilities/reactHooks';
import { formatAccountNameForScreenReader } from '../../utilities/a11y';
import i18n from '../../strings/i18n';

type DispatchProps = {
  clearFlashMessage: () => void;
  setErrorFlashMessage: (arg1?: string | null | undefined) => void;
  setSuccessFlashMessage: () => void;
  recordAnalyticsSubmitPageView: () => void;
  recordAnalyticsSubmitErrorPageView: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  clearFlashMessage: () => dispatch(clearFlashMessageAction()),
  setErrorFlashMessage: (message) =>
    dispatch(
      setFlashMessage({
        messageText: message || FlashMessageText.GENERIC_ERROR,
        messageType: FlashMessageVariant.ERROR,
      })
    ),
  setSuccessFlashMessage: () =>
    dispatch(
      setFlashMessage({
        messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
        messageType: FlashMessageVariant.SUCCESS,
      })
    ),
  recordAnalyticsSubmitPageView: () => dispatch({ type: ANALYTICS_ACCOUNT_ALERTS_SUBMIT_SUCCESS }),
  recordAnalyticsSubmitErrorPageView: () =>
    dispatch({ type: ANALYTICS_ACCOUNT_ALERTS_SUBMIT_FAILURE }),
});

type Props = {
  account: AlertsEligibleAccount;
  hideFocusAccountAlerts: boolean;
  onCompleteEditing: (arg1: UpdateAccountAlertsPreferencesResponse) => void;
  onCancelEditing: () => void;
};

type AllProps = DispatchProps & Props;

const EditAccountAlerts = (props: AllProps) => {
  const {
    account,
    hideFocusAccountAlerts,
    clearFlashMessage,
    onCompleteEditing,
    onCancelEditing,
    setErrorFlashMessage,
    setSuccessFlashMessage,
    recordAnalyticsSubmitPageView,
    recordAnalyticsSubmitErrorPageView,
  } = props;
  const classes = useAccountAlertsStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [alertList, setAlertList] = useState([]);
  const [firstColumnAlerts, setFirstColumnAlerts] = useState([]);
  const [secondColumnAlerts, setSecondColumnAlerts] = useState([]);
  const [lastEdited, setLastEdited] = useState([]);
  const formRef = useRef<HTMLFormElement | null | undefined>(null);

  useEffectOnce(() => {
    window.scrollTo(0, 0);
    // Remove any old flash messages from editing other accounts
    clearFlashMessage();
    setAlertList(account.alerts);
    if (formRef.current) {
      formRef.current.focus();
    }
  });

  const onSubmit = async (values: AccountAlertsForm) => {
    const enrollFieldNames = Object.keys(values).filter((key) =>
      key.startsWith(`${AccountAlertsFieldNames.ENROLL}_`)
    );
    const alerts = enrollFieldNames.map((enrollFieldName) => {
      const code = enrollFieldName.replace(`${AccountAlertsFieldNames.ENROLL}_`, '');
      const originalAlert = account.alerts.find((alert) => alert.code === code);

      const amount =
        normalizeDollarStringToNumber(
          values[`${AccountAlertsFieldNames.AMOUNT}_${code}`] as string
        ) || null;

      return {
        ...originalAlert,
        alertType: values[enrollFieldName] ? AccountAlertsType.EMAIL : AccountAlertsType.NONE,
        amount,
      };
    });

    const requestBody: AccountAlertsPreferencesInfo = {
      accountsAlertsPreferences: {
        eligibleAccounts: [{ ...account, alerts }],
      },
    };

    let response;
    try {
      response = await updateAccountAlertsPreferences(requestBody);
    } catch (err) {
      recordAnalyticsSubmitErrorPageView();
      setErrorFlashMessage(err && err.data && err.data.message);
      return;
    }

    onCompleteEditing(response);
    recordAnalyticsSubmitPageView();
    setSuccessFlashMessage();
  };

  // The design has 2 per column which *should* be fine, but allowing for more than 2 here in case
  // more alerts are added in the future since 3 columns wouldn't fit

  useEffect(() => {
    setAlertList(account.alerts);
  }, [account]);

  useEffect(() => {
    setFirstColumnAlerts(alertList.slice(0, Math.max(2, Math.ceil(alertList.length / 2))));
  }, [alertList]);

  useEffect(() => {
    setSecondColumnAlerts(alertList.slice(firstColumnAlerts?.length));
  }, [firstColumnAlerts]);

  const updateFieldStatus = (code: string, flag: boolean) => {
    const alertListItem = alertList;
    const originalAlert = alertListItem.find((alert) => alert.code === code);
    const indexToRemove = alertListItem.findIndex((pl) => pl.code === code);
    alertListItem[indexToRemove] = {
      ...originalAlert,
      alertType: flag ? AccountAlertsType.EMAIL : AccountAlertsType.NONE,
    };
    setAlertList(alertListItem);
    setFirstColumnAlerts(alertListItem.slice(0, Math.max(2, Math.ceil(alertListItem.length / 2))));
    setSecondColumnAlerts(alertListItem.slice(firstColumnAlerts?.length));
    const updatedList = lastEdited;
    updatedList.push(code);
    setLastEdited(updatedList);
  };

  const revertLastUpdate = (code: string) => {
    const alertListItem = alertList;
    const originalAlert = alertListItem.find((alert) => alert.code === code);
    const indexToRemove = alertListItem.findIndex((pl) => pl.code === code);
    alertListItem[indexToRemove] = {
      ...originalAlert,
      alertType:
        originalAlert.alertType === AccountAlertsType.NONE
          ? AccountAlertsType.EMAIL
          : AccountAlertsType.NONE,
    };
    setAlertList(alertListItem);
  };

  const revertAllUpdates = () => {
    let revertedAlerts;
    if (lastEdited.length) {
      revertedAlerts = lastEdited.map((alert) => {
        revertLastUpdate(alert);
        return null;
      });
    }
    return revertedAlerts;
  };

  const renderEditAlertsList = (alerts: Alert[], form) => {
    const updateInputValue = (code) => {
      form.change(`${AccountAlertsFieldNames.AMOUNT}_${code}`, '');
    };

    return (
      <Grid item xs={12} md={6} xl={5} className={classes.editAlertsListContainer}>
        {alerts.map((alert) => (
          <Grid
            container
            item
            xs={12}
            className={classes.editAlertsFieldGroup}
            key={alert.code}
            role="group"
            aria-labelledby={`${AccountAlertsFieldNames.AMOUNT}_${alert.code}_Label`}
          >
            <Grid container item xs={2}>
              <>
                <Field
                  id={`${AccountAlertsFieldNames.ENROLL}_${alert.code}`}
                  name={`${AccountAlertsFieldNames.ENROLL}_${alert.code}`}
                  type="checkbox"
                  data-test={`alerts-amount-check-${alert.code}-container`}
                  component={RenderCheck}
                  ariaLabel="Enroll in Alert"
                  autoComplete=""
                  checked={false}
                  className={classes.formCheckBox}
                  data-test-label=""
                  initialValue={alert.alertType !== AccountAlertsType.NONE}
                />
                <OnChange name={`${AccountAlertsFieldNames.ENROLL}_${alert.code}`}>
                  {(optValue) => {
                    updateFieldStatus(alert.code, optValue);
                    if (!optValue) {
                      updateInputValue(alert.code);
                    }
                  }}
                </OnChange>
              </>
            </Grid>
            <Grid item xs={10}>
              <Field
                name={`${AccountAlertsFieldNames.AMOUNT}_${alert.code}`}
                initialValue={alert.amount ? parseInt(alert.amount, 10).toString() : ''}
                id={`${AccountAlertsFieldNames.AMOUNT}_${alert.code}`}
                labelId={`${AccountAlertsFieldNames.AMOUNT}_${alert.code}_Label`}
                label={alert.description}
                placeholder="$0"
                data-test={`alerts-amount-${alert.code}-container`}
                component={renderWholeCurrencyField}
                parse={normalizeDollarStringToNumber}
                autoComplete="transaction-amount"
                disabled={alert.alertType === AccountAlertsType.NONE}
                validate={(amount: string | null | undefined, allValues: AccountAlertsForm) => {
                  if (allValues[`${AccountAlertsFieldNames.ENROLL}_${alert.code}`]) {
                    return validateAmount(i18n({ accountAlerts: 'enterValidDollarAmount' }))(
                      amount
                    );
                  }
                  return undefined;
                }}
                ariaRequired
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Form onSubmit={onSubmit} initialValues={{}}>
      {({
        handleSubmit,
        invalid,
        pristine,
        submitting,
        form,
      }: FormRenderProps<AccountAlertsForm>) => {
        const onCancelClick = () => {
          if (pristine) {
            onCancelEditing();
          } else {
            setIsModalVisible(true);
          }
        };

        return (
          <Grid
            container
            item
            xs={12}
            component="form"
            className={classNames(
              classes.editAlertsContainer,
              !hideFocusAccountAlerts && classes.editAlertsNoFocus
            )}
            ref={formRef}
            tabIndex={-1}
          >
            <Grid container item>
              <Grid item xs={12} sm={9} md={9} className={classes.accountName}>
                <Header3>
                  {i18n({ accountAlerts: 'alertsFor' })}
                  <span aria-hidden> {buildAccountNameWithProduct(account)}</span>
                  <ScreenReaderText>{formatAccountNameForScreenReader(account)}</ScreenReaderText>
                </Header3>
                <Grid item lg={9} className={classes.editAccountSubText}>
                  <p>{i18n({ accountAlerts: 'editHelperText' })}</p>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid item sm={3} className={classes.buttonContainer}>
                  <GhostButton data-test="cancel-edit-button" onClick={onCancelClick}>
                    {AccountAlertsBtn.CANCEL}
                  </GhostButton>
                </Grid>
              </Hidden>
              <Grid item xs={12} className={classes.balanceAlertsHeader}>
                <StandardText className={classes.formBalance}>Balance Alerts</StandardText>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {!!alertList.length && firstColumnAlerts.length && (
                <>
                  {renderEditAlertsList(firstColumnAlerts, form)}
                  {!!secondColumnAlerts.length && renderEditAlertsList(secondColumnAlerts, form)}
                </>
              )}
            </Grid>
            <Grid item xs={12} className={classes.formButtonContainer}>
              <Hidden mdUp>
                <GhostButton
                  data-test="cancel-edit-button"
                  className={classes.formMobileCancelButton}
                  onClick={onCancelClick}
                >
                  {AccountAlertsBtn.CANCEL}
                </GhostButton>
              </Hidden>
              <Button
                data-test="save-changes-button"
                className="m-0 float-right"
                disabled={pristine || submitting || invalid}
                type="submit"
                onClick={handleSubmit}
                isLoading={submitting}
              >
                {AccountAlertsBtn.SAVE_CHANGES}
              </Button>
            </Grid>
            <ConfirmationModal
              visible={isModalVisible}
              titleText={ModalText.CANCEL_ALERT_MODAL_TITLE}
              contentText={ModalText.CANCEL_ALERT_MODAL_CONTENT}
              confirmText={ModalBtnText.CANCEL_EXIT_BTN}
              exitText={ModalBtnText.CANCEL_NO_KEEP_EDITING_BTN}
              data-track-title={clickTrack.accountAlerts.cancel_changes}
              data-track-type={clickTrackType.BUTTON}
              data-track-page={clickTrack.accountAlerts.view}
              onConfirm={() => {
                clearFlashMessage();
                onCancelEditing();
                revertAllUpdates();
              }}
              onExit={() => setIsModalVisible(false)}
            />
            <Prompt when={!pristine} message={ModalText.CANCEL_ALERT_MODAL_CONTENT} />
          </Grid>
        );
      }}
    </Form>
  );
};

export default connect(null, mapDispatchToProps)(EditAccountAlerts);
