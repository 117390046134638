import { BackNextAndCancelBtn } from '../components/cms/buttonText.constants';
import { clickTrack, clickTrackType } from '../analytics/clickTracking.constants';

export const getBackGridButtonDataTrackTitle = (disableGoBackClickTrack, dataTrackBack) =>
  disableGoBackClickTrack ? undefined : dataTrackBack || clickTrack.general.back;

export const getEditStage = (isEditing, finalStep, onFinishEdit) =>
  isEditing && finalStep && onFinishEdit;
export const shouldCancelBtnDisable = (disableCancel, loading, loadingSecondary) =>
  disableCancel || loading || loadingSecondary;
export const cancelBtnDataTrackTitle = (disableCancelClickTrack, dataTrackCancel) =>
  disableCancelClickTrack ? undefined : dataTrackCancel || clickTrack.general.cancel;
export const cancelBtnDataTrackType = (dataTrackType) => dataTrackType || clickTrackType.LINK;
export const cancelBtnText = (cancelText) => cancelText || BackNextAndCancelBtn.CANCEL_APPLICATION;
export const shouldPreviousButtonRender = (onBack, isEditing, canGoBackWhileEditing) =>
  onBack && (!isEditing || canGoBackWhileEditing);
export const shouldPreviousBtnDisable = (loading, loadingSecondary) => loading || loadingSecondary;
export const previousBtnDataTest = (dataTestBack) => dataTestBack || 'prev-button';
export const previousButtonText = (backText) => backText || 'Go Back';
export const shouldPrimaryBtnDisable = (disablePrimary, loading, loadingSecondary) =>
  disablePrimary || loading || loadingSecondary;
export const primaryBtnDataTest = (isEditing, dataTestNext) =>
  isEditing ? 'doneEditing-button' : dataTestNext || 'next-button';
