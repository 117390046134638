import { withStyles } from '@material-ui/core';
import * as React from 'react';
import classnames from 'classnames';
import type { StyleProps } from '../../utilities/types';
import styles from './tileList.styles';

type TileListProps = {
  component?: string;
  ['data-test']?: string;
  list: Array<any>;
  listItemRenderProp: any;
  /** Whether to wrap list item in container element. */
  listItemComponent?: string | false | true;
  accountDetailsSection?: boolean;
  header?: React.ReactNode;
};

export const TileList = (props: TileListProps & StyleProps) => {
  const {
    classes = {},
    component = 'table',
    'data-test': dataTest,
    list = [],
    listItemRenderProp,
    listItemComponent,
    header,
    accountDetailsSection,
  } = props;

  const typeOfListItem = typeof listItemComponent === 'string' ? listItemComponent : 'tbody';
  const tileListItems = list.map((element, index) =>
    listItemComponent !== false
      ? React.createElement(
          typeOfListItem,
          {
            key: index, // eslint-disable-line react/no-array-index-key
            className: classnames({
              [classes.root]: accountDetailsSection,
              [classes.rootPrint]: !accountDetailsSection,
            }),
            'data-test': 'list-item',
          },
          listItemRenderProp(element, index, classes)
        )
      : listItemRenderProp(element, index, classes)
  );

  return React.createElement(
    component,
    {
      // role attribute required as CSS display property overrides semantics
      role: component === 'table' ? 'table' : undefined,
      className: classes.tileListContainer,
      'data-test': dataTest,
    },
    <>
      {header && <thead className={classes.tileListHeader}>{header}</thead>}
      {tileListItems}
    </>
  );
};

const styled = withStyles(styles)(TileList);
styled.displayName = 'TileList';
export default styled;
