import { Grid, Hidden, makeStyles } from '@material-ui/core';
import type { GridSize } from '@material-ui/core/Grid/Grid';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Alignment } from '../../styles/layout/alignment.styles';
import type { StyleProps } from '../../utilities/types';

type ColumnType = {
  left?: GridSize;
  middle?: GridSize;
  right?: GridSize;
};
type Props = {
  ['data-test']?: string;
  leftColumn?: string | ReactElement;
  middleColumn?: string;
  rightColumn?: string | ReactElement;
  columnAlign?: 'left' | 'right';
  titleType?: string;
  columnSizes?: ColumnType;
  hideLeftSmDown?: boolean;
  inboxClass?: string;
};

const useStyles = makeStyles({
  padding: {
    ...Alignment.headerPadding,
    paddingBottom: 7,
    paddingTop: '0 !important',
  },
  rightColumnContainer: {
    textAlign: (props: Partial<Props>) => props.columnAlign,
  },
  column: {
    display: 'block',
  },
});

const TileListSubtitles = (props: Props & StyleProps) => {
  const defaultColumnSize = { left: 3, middle: true, right: 4 } as const;
  const {
    columnSizes = defaultColumnSize,
    hideLeftSmDown = true,
    inboxClass,
    leftColumn,
    middleColumn,
    rightColumn,
    columnAlign = 'right',
  } = props;
  const classes = useStyles({ columnAlign });
  const { padding, column, rightColumnContainer } = classes;

  return (
    <Grid
      container
      item
      component="tr"
      className={inboxClass ?? padding}
      data-test={props['data-test']}
    >
      <Hidden smDown={hideLeftSmDown}>
        <Grid
          item
          xs={columnSizes.left}
          component="th"
          className={column}
          data-test="left-column-title"
        >
          {leftColumn}
        </Grid>
      </Hidden>

      <Grid
        xs={columnSizes.middle}
        item
        component="th"
        className={column}
        data-test="middle-column-title"
      >
        {middleColumn}
      </Grid>
      {rightColumn && (
        <Grid
          xs={columnSizes.right}
          md={columnSizes ? undefined : 2}
          item
          component="th"
          className={classNames(column, rightColumnContainer)}
          data-test="right-column-title"
        >
          {rightColumn}
        </Grid>
      )}
    </Grid>
  );
};

export default TileListSubtitles;
