import { Grid, withStyles, StyleRules } from '@material-ui/core';
import React, { Component } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import type { StyleProps, Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import Footer from '../footer/footer';
import Header from '../header/header';
import { headerHeight } from '../header/header.styles';
import { Z_INDEX_FOOTER } from '../../styles/zIndex';
import { SynchronyInfo } from '../cms/blockText.constants';
import AnimatedLoadingOverlay from '../loadingOverlay/animatedLoadingOverlay';

type Props = {
  title?: string;
  className?: string;
  contentClassName?: string;
  loading?: boolean;
  children?: ReactNode;
  hideHeader?: boolean;
  footerIsLight?: boolean;
  pageHeading?: string;
  disableLoadingOverlay?: boolean;
} & StyleProps;

const styles = (theme: Theme): StyleRules => ({
  root: {
    paddingTop: (props: Props) => (props.hideHeader ? 0 : headerHeight.expanded.md),
    minHeight: '100vh',
    [theme.breakpoints.up('lg')]: {
      paddingTop: (props: Props) => (props.hideHeader ? 0 : headerHeight.expanded.lg),
    },
  },
  footerContainer: {
    zIndex: Z_INDEX_FOOTER,
    marginTop: pxToRem(24),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  pageContentContainer: {
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    outline: 'none',
  },
});

export class Page extends Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      className,
      contentClassName,
      children,
      classes,
      loading,
      title,
      pageHeading,
      hideHeader,
      footerIsLight,
      disableLoadingOverlay,
    } = this.props;
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={0}
        className={classNames(classes.root, className)}
      >
        {typeof title !== 'undefined' && (
          <Helmet>
            <title data-test="page-title">
              {pageHeading ? `${pageHeading} - ` : ''}
              {title} - {SynchronyInfo.BANKNAME}
            </title>
          </Helmet>
        )}

        {!hideHeader && (
          <Grid item>
            <Header isDark title={title || ''} />
          </Grid>
        )}

        <Grid
          container
          item
          className={classNames(classes.pageContentContainer, contentClassName)}
          spacing={0}
          tabIndex={-1}
        >
          {children}
          {!disableLoadingOverlay && <AnimatedLoadingOverlay isLoading={loading} variant="page" />}
        </Grid>

        <Grid item className={classes.footerContainer}>
          <Footer isLight={footerIsLight} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Page);
