import axios from '../../utilities/axios';
import type {
  AccountAlertsPreferencesInfo,
  UpdateAccountAlertsPreferencesResponse,
} from './accountAlerts.constants';
import { ACCOUNT_ALERTS_PREFERENCES_URL } from '../../utilities/route-mappings';

export type GetAccountAlertsPreferencesService = () => Promise<AccountAlertsPreferencesInfo>;

export const fetchAccountAlertsPreferences: GetAccountAlertsPreferencesService = async () => {
  const res = await axios.get(ACCOUNT_ALERTS_PREFERENCES_URL());
  return res.data;
};

export type UpdateAccountAlertsPreferencesService = (
  arg1: AccountAlertsPreferencesInfo
) => Promise<UpdateAccountAlertsPreferencesResponse>;

export const updateAccountAlertsPreferences: UpdateAccountAlertsPreferencesService = async (
  requestBody
) => {
  const res = await axios.patch(ACCOUNT_ALERTS_PREFERENCES_URL(), requestBody);
  return res.data;
};
