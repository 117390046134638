const imageFolder = '/images/';
const bankFolder = '/images/banks/';

const ImagesFileNames = Object.freeze({
  // SVG images
  affinityAlertCircleSvg: `${imageFolder}affinity-alert-circle.svg`,
  alertSvg: `${imageFolder}alert.svg`,
  aosLockSvg: `${imageFolder}aos-lock.svg`,
  arrowSvg: `${imageFolder}arrow.svg`,
  goPaperlessSvg: `${imageFolder}go-paperless.svg`,
  unfilledCircleSvg: `${imageFolder}unfilled-circle.svg`,
  syfWhiteLogoSvg: `${imageFolder}syf-white-logo.svg`,
  syfBlackLogoSvg: `${imageFolder}syf-black-logo.svg`,
  profileSvg: `${imageFolder}profile.svg`,
  prevMonthSvg: `${imageFolder}prevMonth.svg`,
  odpSvg: `${imageFolder}odp.svg`,
  nextMonthSvg: `${imageFolder}nextMonth.svg`,
  memberFdicSvg: `${imageFolder}member-fdic.svg`,
  iconWarningSvg: `${imageFolder}icon-warning.svg`,
  iconTransferSvg: `${imageFolder}icon-transfer.svg`,
  iconTransferToSvg: `${imageFolder}icon-transfer-to.svg`,
  iconTransferSuccessSvg: `${imageFolder}icon-transfer-success.svg`,
  iconSleepingPigSvg: `${imageFolder}icon-sleeping-pig.svg`,
  iconRewardsSilverSvg: `${imageFolder}Rewards-Silver.svg`,
  iconRewardsPlatinumSvg: `${imageFolder}Rewards-Platinum.svg`,
  iconRewardsGoldSvg: `${imageFolder}Rewards-Gold.svg`,
  iconRewardsDiamondSvg: `${imageFolder}Rewards-Diamond.svg`,
  iconRewardsBasicSvg: `${imageFolder}Rewards-Basic.svg`,
  iconRemoveSvg: `${imageFolder}icon-remove.svg`,
  iconPrintSvg: `${imageFolder}icon-print.svg`,
  iconPlusCircleSvg: `${imageFolder}icon-plus-circle.svg`,
  iconPlusCircleLargeSvg: `${imageFolder}icon-plus-circle-large.svg`,
  iconPencilSvg: `${imageFolder}icon-pencil.svg`,
  iconMorePaperlessSvg: `${imageFolder}icon-more-paperless.svg`,
  iconMorePaperlessSvgSm: `${imageFolder}icon-more-paperless-sm.svg`,
  iconLockSvg: `${imageFolder}icon-lock.svg`,
  iconGreenOutlineCheckmarkSvg: `${imageFolder}icon-green-outline-checkmark.svg`,
  iconFaqSvg: `${imageFolder}icon-faq.svg`,
  iconExternalAccountGreenSvg: `${imageFolder}icon-external-account-green.svg`,
  iconExternalAccountBlueSvg: `${imageFolder}icon-external-account-blue.svg`,
  iconErrorSvg: `${imageFolder}icon-error.svg`,
  iconDownloadSvg: `${imageFolder}icon-download.svg`,
  iconCheckmarkCircleSvg: `${imageFolder}icon-checkmark-circle.svg`,
  iconCheckGreenSvg: `${imageFolder}icon-check-green.svg`,
  iconCalendarSvg: `${imageFolder}icon-calendar.svg`,
  iconAttachmentSvg: `${imageFolder}icon-attachment.svg`,
  iconArrowDownSvg: `${imageFolder}icon-arrow-down.svg`,
  iconAlertSvg: `${imageFolder}icon-alert.svg`,
  iconAlertDisabledSvg: `${imageFolder}icon-alert-disabled.svg`,
  iconFDICLogoSvg: `${imageFolder}FDIC-logo.svg`,
  filledCircleSvg: `${imageFolder}filled-circle.svg`,
  downArrowSvg: `${imageFolder}down-arrow.svg`,
  deleteCircleSvg: `${imageFolder}delete-circle.svg`,
  dateArrowSvg: `${imageFolder}date-arrow.svg`,
  beneficiariesSvg: `${imageFolder}beneficiaries.svg`,

  // SVG Banks images
  wellsfargoSvg: `${bankFolder}wellsfargo.svg`,
  usbankSvg: `${bankFolder}usbank.svg`,
  usaaSvg: `${bankFolder}usaa.svg`,
  tdSvg: `${bankFolder}td.svg`,
  suntrustSvg: `${bankFolder}suntrust.svg`,
  pncSvg: `${bankFolder}pnc.svg`,
  citiSvg: `${bankFolder}citi.svg`,
  chasebankSvg: `${bankFolder}chasebank.svg`,
  capitaloneSvg: `${bankFolder}capitalone.svg`,
  bbandtSvg: `${bankFolder}bbandt.svg`,
  bankofamericaSvg: `${bankFolder}bankofamerica.svg`,
  allySvg: `${bankFolder}ally.svg`,

  // PNG images
  atmCardPng: '/images/atmcard.png',
  atmCard_2xPng: '/images/atmcard_2x.png',
  atmCard_3xPng: '/images/atmcard_3x.png',
  iconSyfApp512Png: '/images/icon-syf-app-512.png',
  iconSyfApp192Png: '/images/icon-syf-app-192.png',
  logoDark_3xPng: '/images/logo-dark_3x.png',
  logo_3xPng: '/images/logo_3x.png',
  mountainsIMGTablet_1xPng: '/images/MountainsIMGTablet_1x.png',
  mountainsIMGMobile_2xPng: '/images/MountainsIMGMobile_2x.png',
  mountainsIMGMobile_1xPng: '/images/MountainsIMGMobile_1x.png',
  mountainsIMGDesktop_1xPng: '/images/MountainsIMGDesktop_1x.png',
  goPaperlessPng: '/images/go-paperless.png',
  goPaperless_2xPng: '/images/go-paperless_2x.png',
  goPaperless_3xPng: '/images/go-paperless_3x.png',
  debitcardPng: '/images/debitcard.png',
  debitcard_2xPng: '/images/debitcard_2x.png',
  debitcard_3xPng: '/images/debitcard_3x.png',
  creditCardPng: '/images/creditcard.png',
  creditCard_2xPng: '/images/creditcard_2x.png',
  creditCard_3xPng: '/images/creditCard_3x.png',
  check400PxPng: '/images/check_400px.png',
  resourceImage1Png: '/images/Resource-image1.png',
  // JPG images
  accountDashboardSummerJpg: '/images/account-dashboard-summer.jpg',
  accountDashboardSummer_2xJpg: '/images/account-dashboard-summer_2x.jpg',
  accountDashboardSummer_3xJpg: '/images/account-dashboard-summer_3x.jpg',
  accountOpeningJpg: '/images/account-opening.jpg',
  accountOpening_2xJpg: '/images/account-opening_2x.jpg',
  accountOpening_3xJpg: '/images/account-opening_3x.jpg',
  addExternalAccountsJpg: '/images/add-external-accounts.jpg',
  addExternalAccounts_2xJpg: '/images/add-external-accounts_2x.jpg',
  addExternalAccounts_3xJpg: '/images/add-external-accounts_3x.jpg',
  backseatTrunkViewJpg: '/images/backseat-trunk-view.jpg',
  backseatTrunkView_2xJpg: '/images/backseat-trunk-view_2x.jpg',
  backseatTrunkView_3xJpg: '/images/backseat-trunk-view_3x.jpg',
  bgMountainJpg: '/images/bg-mountain.jpg',
  cdRenewalAndInterestPaymentsJpg: '/images/cd-renewal-and-interest-payments.jpg',
  cdRenewalAndInterestPayments_2xJpg: '/images/cd-renewal-and-interest-payments_2x.jpg',
  cdRenewalAndInterestPayments_3xJpg: '/images/cd-renewal-and-interest-payments_3x.jpg',
  messageCenterReplyJpg: '/images/message-center-reply.jpg',
  messageCenterReply_2xJpg: '/images/message-center-reply_2x.jpg',
  messageCenterReply_3xJpg: '/images/message-center-reply_3x.jpg',
  messageCenterComposeJpg: '/images/message-center-compose.jpg',
  messageCenterCompose_2xJpg: '/images/message-center-compose_2x.jpg',
  messageCenterCompose_3xJpg: '/images/message-center-compose_3x.jpg',
  mountainsIMGDesktop_2xJpg: '/images/MountainsIMGDesktop_2x.jpg',
});

export default ImagesFileNames;
