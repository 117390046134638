import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import {
  ANALYTICS_EPREFERENCES_START_SUCCESS,
  ANALYTICS_EPREFERENCES_START_FAILURE,
  ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS,
  ANALYTICS_EPREFERENCES_SUBMIT_FAILURE,
} from '../actions';
import type { EPreferencesAnalyticsAction } from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
    pagefunction: pageTrack.pagefunction.epreferences,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start_server_error }).record();
};

const submitSuccess = (action: EPreferencesAnalyticsAction) => {
  viewInit().addInfo({ pagename: pageTrack.pagename.complete, attr: action.payload }).record();
};

const submitFailure = (action: EPreferencesAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.submit_server_error, attr: action.payload })
    .record();
};

export const ePreferencesPageEvents = {
  [ANALYTICS_EPREFERENCES_START_SUCCESS]: viewSuccess,
  [ANALYTICS_EPREFERENCES_START_FAILURE]: viewFailure,
  [ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS]: submitSuccess,
  [ANALYTICS_EPREFERENCES_SUBMIT_FAILURE]: submitFailure,
} as const;

export default ePreferencesPageEvents;
