import axios from '../../utilities/axios';
import type { ViewDocumentsResponse, ETaxDocumentPreferences } from './documents.constants';
import {
  DOCUMENTS_INFO_URL,
  DOCUMENTS_NONCE_URL,
  TAX_DOCUMENT_PREFERENCES_URL,
} from '../../utilities/route-mappings';
import type { DocumentsNonceResponse } from '../statements/statements.reducer';

export type GetDocumentsService = (
  documentType: string,
  startDate: string,
  endDate: string
) => Promise<ViewDocumentsResponse>;

export const fetchDocuments: GetDocumentsService = async (documentType, startDate, endDate) => {
  const response = await axios.get(DOCUMENTS_INFO_URL(), {
    params: { documentType, startDate, endDate },
  });
  return response.data;
};

export type ViewDocumentService = (
  documentId: string
) => Promise<DocumentsNonceResponse | null | undefined>;

export const viewDocument: ViewDocumentService = async (documentId) => {
  const { data } = await axios.get(DOCUMENTS_NONCE_URL(documentId));
  return data;
};

export type GetTaxDocumentPreferencesService = () => Promise<ETaxDocumentPreferences>;

export const fetchTaxDocumentPreferences: GetTaxDocumentPreferencesService = async () => {
  const res = await axios.get(TAX_DOCUMENT_PREFERENCES_URL());
  return res.data.taxDocumentsPreferences;
};

export default fetchDocuments;
