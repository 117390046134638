// @ts-nocheck
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import type { ReactNode } from 'react';
import React from 'react';
import { compose } from 'redux';
import type { StyleProps } from '../../utilities/types';
import colors from '../colors/colors';
import styles, { Typography as TypographyStyles } from './typography.styles';
import pxToRem from '../../utilities/pxToRem';

type FontWeight = 'bold' | 'medium' | 'normal' | 'light';
type FontStyle = 'italic' | 'normal' | 'initial';
type TextTransform = 'lowerCase' | 'upperCase' | 'capitalize';

// Eslint appears to have issues with parsing destructured types
// https://github.com/yannickcr/eslint-plugin-react/issues/1364
/* eslint-disable react/no-unused-prop-types */
export type TypographyProps = {
  // Typography style props
  fontStyle?: FontStyle;
  fontWeight?: FontWeight;
  letterSpacing?: string;
  paddingBottom?: string;
  textColor?: string;
  textTransform?: TextTransform;
  // Props to pass to underlying component
  ['aria-hidden']?: string | boolean;
  ['data-test']?: string;
  children?: ReactNode;
  id?: string;
  className?: string;
  component?: React.ComponentType<any> | string;
  role?: string;
  href?: string;
  translate?: string;
  typeof?: string;
  rel?: string;
  target?: string;
  tabIndex?: number;
  title?: string;
  classes?: ClassNameMap;
  isActive?: boolean;
  style?: Record<string, unknown>;
};

type SublinkTypographyProps = TypographyProps & {
  isActive?: boolean;
  classes: any;
};

/* eslint-enable react/no-unused-prop-types */

const getOverridableClasses = (classes): string =>
  classnames(
    classes.overrideColor,
    classes.overrideFontWeight,
    classes.overrideFontStyle,
    classes.overridePaddingBottom,
    classes.overrideTextTransform,
    classes.overrideLetterSpacing
  );

const createOverrideStyles = ({
  textColor = '',
  fontWeight = '',
  fontStyle = '',
  paddingBottom = '',
  textTransform = '',
  letterSpacing = '',
}: TypographyProps) => ({
  overrideColor: textColor ? { color: textColor } : {},
  overrideFontWeight: { ...(TypographyStyles[fontWeight] || {}) },
  overrideFontStyle: { ...(TypographyStyles[fontStyle] || {}) },
  overridePaddingBottom: paddingBottom ? { paddingBottom } : {},
  overrideTextTransform: { ...(TypographyStyles[textTransform] || {}) },
  overrideLetterSpacing: letterSpacing ? { letterSpacing } : {},
});

const createStyledTypography = (
  {
    // pull out non-DOM props
    fontWeight,

    textColor,
    fontStyle,
    textTransform,
    paddingBottom,
    letterSpacing,
    component,
    ...domProps
  }: TypographyProps,
  renderProp
) =>
  compose(
    // Override default Typography styles
    withStyles(
      createOverrideStyles({
        fontWeight,
        textColor,
        fontStyle,
        textTransform,
        paddingBottom,
        letterSpacing,
      })
    ),
    // Apply default Typography styles
    withStyles(styles)
  )(({ classes }) => <>{renderProp(classes, domProps)}</>);

/*
 * -- Set autofocus to the form header for keyboard navigation --
 */

const FormHeader1 = React.forwardRef<TypographyProps, HTMLElement>(
  (props: TypographyProps, ref?: any) => {
    const Styled = createStyledTypography(props, (classes, domProps) =>
      React.createElement(
        props.component || 'h1',
        {
          ref,
          ...domProps,
          className: classnames(
            classes.header1,
            classes.root,
            getOverridableClasses(classes),
            domProps.className
          ),
        },
        domProps.children
      )
    );

    return <Styled />;
  }
);

const LargestFormHeader = React.forwardRef<TypographyProps, any>(
  (props: TypographyProps, ref?: any) => {
    const { children } = props;
    return (
      <FormHeader1 tabIndex="-1" fontWeight="bold" ref={ref} {...props}>
        {children}
      </FormHeader1>
    );
  }
);

/*
 * -- Header 1 --
 */

const Header1 = withStyles(TypographyStyles)(
  ({ component, children, className, classes, ...props }) =>
    React.createElement(
      component || 'h1',
      {
        ...props,
        className: classnames(classes.header1, classes.root, className),
      },
      children
    )
);

const LargestHeader = (props: TypographyProps) => {
  const { children } = props;
  return (
    <Header1 fontWeight="bold" {...props}>
      {children}
    </Header1>
  );
};

/*
 * -- Large Header with Line breaks and font size adjustment based on device viewport --
 */

const HeaderBanner = withStyles(TypographyStyles)(
  ({ component, children, className, classes, ...props }) =>
    React.createElement(
      component || 'h1',
      {
        ...props,
        className: classnames(classes.header1Banner, classes.root, className),
      },
      children
    )
);

const LargeHeader = (props: TypographyProps) => {
  const { children } = props;
  return (
    <HeaderBanner fontWeight="bold" {...props}>
      {children}
    </HeaderBanner>
  );
};

/*
 * -- Header 2 --
 */

const Header2 = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'h2',
      {
        ...domProps,
        className: classnames(classes.header2, getOverridableClasses(classes), domProps.className),
      },
      domProps.children
    )
  );

  return <Styled />;
};

const HalfPageTitle = (props: TypographyProps) => {
  const { children } = props;
  return <Header2 {...props}>{children}</Header2>;
};

/*
 * -- Header 3 --
 */

const Header3 = React.forwardRef<TypographyProps, any>((props: TypographyProps, ref?: any) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'h3',
      {
        ref,
        ...domProps,
        className: classnames(classes.header3, getOverridableClasses(classes), domProps.className),
      },
      domProps.children
    )
  );

  return <Styled />;
});

/*
 * -- Header 4 --
 */

const Header4 = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'h4',
      {
        ...domProps,
        className: classnames(classes.header4, getOverridableClasses(classes), domProps.className),
      },
      domProps.children
    )
  );

  return <Styled />;
};

const CallToActionHeader = (props: TypographyProps) => {
  const { children } = props;
  return (
    <Header2 {...props} fontWeight="bold">
      {children}
    </Header2>
  );
};
const CallToActionHeaderWhite = (props: TypographyProps) => {
  const { children } = props;
  return <CallToActionHeader {...props}>{children}</CallToActionHeader>;
};

const InformationalSectionHeader = React.forwardRef<TypographyProps, any>(
  (props: TypographyProps, ref?: any) => {
    const { children } = props;
    return (
      <Header3 tabIndex="-1" ref={ref} {...props}>
        {children}
      </Header3>
    );
  }
);

const InformationalSectionHeaderBold = (props: TypographyProps) => {
  const { children } = props;
  return (
    <Header3 {...props} fontWeight="bold">
      {children}
    </Header3>
  );
};

const InformationalSectionHeaderThin = (props: TypographyProps) => {
  const { children } = props;
  return <Header3 {...props}>{children}</Header3>;
};

const InformationalSectionHeaderWhite = (props: TypographyProps) => {
  const { children } = props;
  return (
    <InformationalSectionHeader {...props} textColor={props.textColor || colors.white}>
      {children}
    </InformationalSectionHeader>
  );
};

/*
 * -- Link Text --
 */
// TODO: rework and simplify LinkText and other typography elements
const LinkText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(classes.linkText, getOverridableClasses(classes), domProps.className),
      },
      domProps.children
    )
  );
  return <Styled />;
};

const InlineLinkText = withStyles(styles)(
  ({ children, classes, className, ...rest }: TypographyProps & StyleProps) => (
    <a
      {...rest}
      className={classnames(classes.inlineLinkText, getOverridableClasses(classes), className)}
    >
      {children}
    </a>
  )
);

const SubLinkText = withStyles(styles)((props: SublinkTypographyProps) => {
  const { children, isActive = false, classes } = props;
  return (
    <span className={classnames(isActive ? classes.subLinkTextActive : classes.subLinkText)}>
      {children}
    </span>
  );
});

const NewAccountHeaderLinkText = withStyles(styles)(
  (
    props: TypographyProps & {
      classes: any;
    }
  ) => {
    const { children, classes } = props;
    return <span className={classes.newAccountHeaderLinkText}>{children}</span>;
  }
);

const LinkTextOpenAccount = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(
          classes.linkTextOpenAccount,
          getOverridableClasses(classes),
          domProps.className
        ),
      },
      domProps.children
    )
  );
  return <Styled />;
};

const ExploreProductsSubHeader = withStyles(styles)(
  (
    props: TypographyProps & {
      classes: any;
    }
  ) => {
    const { children, classes } = props;
    return <span className={classes.exploreProductsSubHeader}>{children}</span>;
  }
);

const StandaloneSidebarLinkText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(
          classes.standaloneSidebarLinkText,
          getOverridableClasses(classes),
          domProps.className
        ),
      },
      domProps.children
    )
  );
  return <Styled />;
};

/*
 * -- Body Text --
 */

const BodyText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(classes.body, getOverridableClasses(classes), domProps.className),
      },
      domProps.children
    )
  );
  return <Styled />;
};

const BodyTextSmall = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(
          classes.bodySmall,
          getOverridableClasses(classes),
          domProps.className
        ),
      },
      domProps.children
    )
  );
  return <Styled />;
};

const BodyTextSmallEmphasis = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyTextSmall {...props} fontWeight="medium">
      {children}
    </BodyTextSmall>
  );
};

const BodyTextMedium = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(
          classes.bodyMedium,
          getOverridableClasses(classes),
          domProps.className
        ),
      },
      domProps.children
    )
  );
  return <Styled />;
};
const BodyTextPendingMedium = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyTextMedium {...props} fontStyle="italic">
      {children}
    </BodyTextMedium>
  );
};

const InfoText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(
          classes.informationalText,
          getOverridableClasses(classes),
          domProps.className
        ),
      },
      domProps.children
    )
  );
  return <Styled />;
};

const StandardText = (props: TypographyProps) => {
  const { children } = props;
  return <BodyText {...props}>{children}</BodyText>;
};
const StandardTextEmphasis = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyText {...props} fontWeight="bold">
      {children}
    </BodyText>
  );
};

const StandardTextPreferences = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyText {...props} fontWeight="medium">
      {children}
    </BodyText>
  );
};

const StandardTextPending = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyText {...props} fontStyle="italic">
      {children}
    </BodyText>
  );
};
const StandardTextPendingEmphasis = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyText {...props} fontStyle="italic" fontWeight="bold">
      {children}
    </BodyText>
  );
};

const StandardTextEmphasisSpaced = (props: TypographyProps) => {
  const { children } = props;
  return (
    <BodyText {...props} fontWeight="bold" letterSpacing={pxToRem(1.2)}>
      {children}
    </BodyText>
  );
};

const InformationalText = (props: TypographyProps) => {
  const { children } = props;
  return (
    <InfoText {...props} fontWeight="normal">
      {children}
    </InfoText>
  );
};

const InformationalTextMedium = (props: TypographyProps) => {
  const { children } = props;
  return (
    <InfoText {...props} fontWeight="medium">
      {children}
    </InfoText>
  );
};

const InformationalTextBold = (props: TypographyProps) => {
  const { children } = props;
  return (
    <InfoText {...props} fontWeight="bold">
      {children}
    </InfoText>
  );
};

const ProgressStepperText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) => (
    <span
      {...domProps}
      className={classnames(
        classes.progressText,
        getOverridableClasses(classes),
        domProps.className
      )}
    >
      {domProps.children}
    </span>
  ));
  return <Styled />;
};

/**
 * -- Form Text --
 */

const FormText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) => (
    <span
      {...domProps}
      className={classnames(
        classes.formTextDefault,
        getOverridableClasses(classes),
        domProps.className
      )}
    >
      {domProps.children}
    </span>
  ));
  return <Styled />;
};

const InputText = (props: TypographyProps) => {
  const { children } = props;
  return <FormText {...props}>{children}</FormText>;
};
const InputTextActive = (props: TypographyProps) => {
  const { children } = props;
  return (
    <FormText {...props} fontWeight="normal">
      {children}
    </FormText>
  );
};

const InputTextFilledOut = InputTextActive;

/*
 * -- Subtitle Text --
 */

const SubtitleText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, domProps) =>
    React.createElement(
      props.component || 'span',
      {
        ...domProps,
        className: classnames(classes.subtitle, getOverridableClasses(classes), domProps.className),
      },
      domProps.children
    )
  );
  return <Styled />;
};
const SubtitleEmphasis = (props: TypographyProps) => {
  const { children } = props;
  return (
    <SubtitleText fontWeight="medium" {...props}>
      {children}
    </SubtitleText>
  );
};

const SectionHeader = (props: TypographyProps) => {
  const { children } = props;
  return <SubtitleText {...props}>{children}</SubtitleText>;
};

const SectionHeaderBold = ({ children, ...rest }: TypographyProps) => (
  <SubtitleText fontWeight="bold" {...rest}>
    {children}
  </SubtitleText>
);

const ErrorSectionHeader = ({ children, textColor, ...rest }: TypographyProps) => (
  <SubtitleText fontWeight="bold" textColor={textColor || colors.steelGrey} {...rest}>
    {children}
  </SubtitleText>
);

const PendingSectionHeader = ({ children, ...rest }: TypographyProps) => (
  <SubtitleText fontStyle="italic" {...rest}>
    {children}
  </SubtitleText>
);

const TransactionSubtitle = (props: TypographyProps) => {
  const { children } = props;
  return (
    <SubtitleText {...props} fontWeight="light">
      {children}
    </SubtitleText>
  );
};

const PendingTransactionSubtitle = (props: TypographyProps) => {
  const { children } = props;
  return (
    <SubtitleText {...props} fontWeight="light" fontStyle="italic">
      {children}
    </SubtitleText>
  );
};

const ScreenReaderText = (props: TypographyProps) => {
  const Styled = createStyledTypography(props, (classes, { children, ...domProps }) =>
    React.createElement(props.component || 'span', {
      ...domProps,
      // Placing the children inside a pseudo element to prevent copying https://danoc.me/blog/css-prevent-copy/
      'data-hidden-text': React.Children.toArray(children).join(' '),
      className: classnames(
        classes.screenReaderText,
        getOverridableClasses(classes),
        domProps.className
      ),
    })
  );
  return <Styled />;
};

export {
  Header1,
  Header2,
  Header3,
  Header4,
  ScreenReaderText,
  LinkText,
  LinkTextOpenAccount,
  InlineLinkText,
  SubLinkText,
  NewAccountHeaderLinkText,
  StandaloneSidebarLinkText,
  BodyText,
  BodyTextSmall,
  BodyTextSmallEmphasis,
  BodyTextMedium,
  BodyTextPendingMedium,
  FormText,
  SubtitleText,
  CallToActionHeader,
  CallToActionHeaderWhite,
  HalfPageTitle,
  InformationalSectionHeader,
  InformationalSectionHeaderThin,
  InformationalSectionHeaderWhite,
  InformationalSectionHeaderBold,
  InputText,
  InputTextActive,
  InputTextFilledOut,
  InformationalText,
  InformationalTextMedium,
  InformationalTextBold,
  LargestHeader,
  LargestFormHeader,
  LargeHeader,
  SectionHeader,
  SectionHeaderBold,
  ErrorSectionHeader,
  PendingSectionHeader,
  ProgressStepperText,
  StandardText,
  StandardTextEmphasis,
  StandardTextPreferences,
  StandardTextPending,
  StandardTextPendingEmphasis,
  StandardTextEmphasisSpaced,
  SubtitleEmphasis,
  TransactionSubtitle,
  PendingTransactionSubtitle,
  ExploreProductsSubHeader,
};
