import { makeStyles } from '@material-ui/core/styles';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';

export const useRewardsTierTileStyles = makeStyles({
  divider: {
    width: '100%',
    backgroundColor: Colors.lightBlue,
    margin: `${pxToRem(24)} 0`,
  },
  rewardLevel: {
    position: 'absolute',
    left: '50%',
    // Shift up by half of SVG height plus container padding
    transform: `translate(-50%, ${pxToRem(-21.5 - 16)})`,
  },
  select: {
    width: '100%',
  },
  tierRequirements: {
    marginTop: pxToRem(62),
  },
  tierTitle: {
    display: 'block',
    marginTop: pxToRem(6),
  },
  tile: {
    textAlign: 'center',
    padding: pxToRem(16),
    marginTop: pxToRem(48),
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: Colors.white,
  },
  tinyText: {
    fontSize: pxToRem(10),
    fontWeight: 'lighter',
    textTransform: 'uppercase',
  },
  alignLeft: {
    textAlign: 'left',
    marginTop: pxToRem(10),
  },
});

export default useRewardsTierTileStyles;
