import React from 'react';
import Tile from './tile';
import { TransactionSubtitle } from '../typography/typography';

const emptyTile = (text, classes) => (
  <Tile>
    <td colSpan={3}>
      <TransactionSubtitle className={classes.noActivityMessageText}>{text}</TransactionSubtitle>
    </td>
  </Tile>
);

export default emptyTile;
