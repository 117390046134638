import { withStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import LoadingIndicator from '../loadingIndicator/loadingIndicator';
import styles from './loadingOverlay.styles';

type Props = {
  isLoading?: boolean;
  classes?: ClassNameMap;
  children?: any;
  variant?: 'page' | 'formWithImage';
};

export const LoadingOverlay = ({
  isLoading = false,
  classes = {},
  children = null,
  variant,
}: Props) => {
  const overlayRef = useRef(null);

  useEffect(() => {
    // Unsupported in very old browsers, will fallback to 'top: 50%'
    if (!('requestAnimationFrame' in window)) return;

    let rAFId;

    const animate = () => {
      const overlay = overlayRef.current;
      const header = document.getElementById('header');
      const footer = document.getElementById('footer');

      if (overlay && header && footer) {
        const headerBBox = header.getBoundingClientRect();
        const footerBBox = footer.getBoundingClientRect();

        const viewportHeight = window.innerHeight;
        const currentY = window.scrollY;

        const minY = currentY + headerBBox.height;
        const maxY = Math.min(currentY + footerBBox.top, currentY + viewportHeight);
        const centerY = headerBBox.height + (maxY - minY) / 2;

        overlay.style.transform = `translateY(${centerY}px)`;
        overlay.style.top = '0%';
      }

      rAFId = requestAnimationFrame(animate);
    };

    if (isLoading) {
      rAFId = requestAnimationFrame(animate);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (rAFId) {
        cancelAnimationFrame(rAFId);
      }
    };
  }, [overlayRef, isLoading]);

  return (
    <>
      {children}
      {isLoading && (
        <div
          className={classnames(classes.overlay, {
            [classes.formWithImageOverlay]: variant === 'formWithImage',
          })}
        >
          <div ref={overlayRef} className={classes.loadingIndicatorContainer}>
            <LoadingIndicator className={classes.loadingIndicator} />
          </div>
        </div>
      )}
    </>
  );
};

LoadingOverlay.displayName = 'LoadingOverlay';
export default withStyles(styles)(LoadingOverlay);
