import React, { useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';
import type { FormApi } from 'final-form';
import keyCodeConstants from '../../utilities/keyCodeConstants';
import type { MessageForm } from '../../utilities/types';
import { IconGhostButton } from '../buttons/buttons';
import { MessageBtn } from '../cms/buttonText.constants';
import useStyles from './addAttachment.styles';
import { ATTACHMENT_LIMIT } from '../cms/blockText.constants';
import AttachmentTile from '../attachmentTile/attachmentTile';
import { RenderFileField } from '../finalForm/finalFormInputs';
import { MessageFieldNames } from '../../containers/messages/messages.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import i18n from '../../strings/i18n';

type Props = {
  form: FormApi<MessageForm>;
  values?: MessageForm;
};

const AddAttachment = ({ form, values }: Props) => {
  const classes = useStyles();
  const attachmentLimit = parseInt(window.__config__.MESSAGE_ATTACHMENT_LIMIT, 10);
  const attachmentFiles = Array.from(values?.attachmentFiles ?? []);
  const disableButton = attachmentFiles.length >= attachmentLimit;
  const inputFileRef = useRef(null);
  const addAttachmentButtonRef = useRef(null);
  const attachmentTileRefs = useRef([]);

  useEffect(() => {
    attachmentTileRefs.current = attachmentTileRefs.current.slice(0, attachmentFiles.length);
  }, [attachmentFiles]);

  // Taking the keydown approach to trigger keyboard accessibility.
  // MUI has an open issue where the file input won't be part of the accessiblity tree.
  const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const inputFile = inputFileRef;
    const isSpaceOrEnter =
      event.code === keyCodeConstants.SPACE || event.code === keyCodeConstants.ENTER;
    if (isSpaceOrEnter && inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid container component="ul" className={classes.addAttachmentList} item xs={12} spacing={3}>
        {attachmentFiles.map((file, index) => {
          /**
           * No other remove buttons, go to add attachment. Otherwise, go to closest
           * remove button, going left first. For the first remove button, go to the right
           */
          const handleRemoveAttachmentFocus = () => {
            let refCurrent = addAttachmentButtonRef.current;
            if (attachmentFiles.length >= 2) {
              let refIndex = 1; // for the scenario of first remove button
              if (index !== 0) {
                refIndex = index - 1; // one element to the left
              }
              refCurrent = attachmentTileRefs.current[refIndex];
            }
            refCurrent?.focus();
          };
          return (
            <AttachmentTile
              ref={(element) => {
                attachmentTileRefs.current[index] = element;
              }}
              handleRemoveFocus={handleRemoveAttachmentFocus}
              file={file}
              key={`${file.name}${file.lastModified}`}
              form={form}
            />
          );
        })}
      </Grid>
      <Grid item xs={12}>
        {/* The htmlFor here connects the button to the below Field, allowing for hiding the native <input> */}
        <IconGhostButton
          icon={<SVGImage imageName={ImagesFileNames.iconAttachmentSvg} ariaHidden="true" />}
          className={classes.addAttachmentButton}
          // Button stays focused after selecting files, but disabled font color needs to take precedence over :focus,
          // and the :disabled selector doesn't work on a <label>
          classes={disableButton ? { root: classes.disabledButton } : undefined}
          component="label"
          htmlFor={MessageFieldNames.ATTACHMENT_FILES}
          disabled={disableButton}
          data-track-title={clickTrack.replyMessage.add_attachment}
          data-track-type={clickTrackType.LINK}
          ref={addAttachmentButtonRef}
          onKeyDown={onKeyDown}
          aria-describedby="add-attachments-limit-message"
        >
          {MessageBtn.ADD_ATTACHMENT}
        </IconGhostButton>
        <Field
          name={MessageFieldNames.ATTACHMENT_FILES}
          id={MessageFieldNames.ATTACHMENT_FILES}
          hidden
          multiple
          component={RenderFileField}
          helperText={ATTACHMENT_LIMIT}
          // $FlowFixMe Something is incorrect in the MUI type definitions for refs, ignoring for now
          ref={inputFileRef}
          // Using onChange prop instead of react-final-form-listeners because having this override the default
          // onChange function is actually desired here (in order to combine the Sets of Files)
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let newFiles: File[];
            try {
              // By instantiating a new File, we set file.lastModified to be equal to Date.now(),
              // which lets it be used as a unique key for React when rendering the array of files
              newFiles = Array.from(event.target.files).map((file) => new File([file], file.name));
            } catch {
              // File() constructor is not availalbe in IE, will throw an error
              newFiles = Array.from(event.target.files).map((file) => {
                const blob = new Blob([file], { type: file.type });
                return {
                  ...blob,
                  name: file.name,
                  // Need this for generating a unique key (like above) and to make newFile match the File prototype
                  lastModified: Date.now(),
                  // webkitRelativePath is not available in IE
                  webkitRelativePath: null,
                };
              });
            }
            // This shouldn't be necessary but without it, file upload doesn't work in IE 🤷‍♂️
            if (!newFiles.length) return;
            const allFiles = new Set([...attachmentFiles, ...newFiles]);
            form.change(MessageFieldNames.ATTACHMENT_FILES, allFiles);

            // Need to clear the value after change to allow attaching the same file again
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
          }}
          validate={(value?: Set<File> | null) => {
            if (value && value.size > attachmentLimit) {
              return i18n({ MESSAGES: 'attachmentLimitError' });
            }
            return undefined;
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddAttachment;
