import Colors from '../../components/colors/colors.ts';

export default {
  displayName: 'Consumer Bank',
  appId: 'cbruleenginewebapp',
  formSession: false,
  crypto: false,
  serverUrl: false,
  secureaccess: false,
  isLoggingEnabled: true,
  OAuth2: false,
  internal: false,
  isVoiceEnabled: true,
  formatResponse: true,
  authenticatorListing: {
    primary: ['iovationcheck'],
  },
  analytics: false,
  analyticsObj: {},
  defaultUI: 'false',
  brandingObj: {
    bodyText: Colors.transmitBodyText,
    bodylink: Colors.transmitBodylink,
    fieldFocus: Colors.optimumBlue,
    primaryBtnText: Colors.transmitPrimaryBtnText,
    primaryBtnHover: Colors.optimumBlue,
    primaryBtnBackground: Colors.optimumBlue,
    secondaryBtnText: Colors.transmitBodylink,
    secondaryBtnHover: Colors.transmitPrimaryBtnText,
    secondayBtnBackground: Colors.transmitPrimaryBtnText,
    footerBackground: Colors.transmitPrimaryBtnText,
    footerText: Colors.transmitFooterText,
  },
  customStyles: {
    aasFieldHighlight: 'primaryBtnBackground',
    aasInfoText: 'bodylink',
  },
  loader: {
    display: 'true',
    topCoord: '50%',
    leftCoord: '50%',
    type: 'rect',
  },
  queueService: [
    {
      type: 'iovationcheck',
      policyId: 'cbruleenginewebapp_iovationdevicecheck',
      custom: {
        error: [],
        nextStep: {},
        params: [],
      },
    },
    {
      type: 'otp',
      policyId: 'cbruleenginewebapp_lexisnexis_otp',
      custom: {
        error: [],
        path: [],
        nextStep: {},
        params: [],
        analyticsTrackerList: ['start', 'enter code sent', 'complete'],
        reubenOptimization: true,
      },
    },
    {
      type: 'phoneconfirm',
      policyId: 'cbruleenginewebapp_phoneconfirm',
      custom: {
        error: [],
        nextStep: {},
        params: [],
      },
    },
  ],
};
