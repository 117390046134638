import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import updateContext, { handleCDApiAttribute } from './bioCatch.utils';
import getSearchParam from '../../utilities/url';
import { QueryParams } from '../../containers/routes/routes.constants';
import useIsPageLoaded from '../../utilities/hooks/useIsPageLoaded';

const BioCatch = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const codeParam = getSearchParam(window.location, QueryParams.code);
  const stateParam = getSearchParam(window.location, QueryParams.state);

  const isCustomerLoaded = codeParam !== null && stateParam !== null;

  const { location } = useSelector((state: RootStateOrAny) => state.router);
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.authenticate);

  const isPageLoaded = useIsPageLoaded();

  const insertBioCatchScript = () => {
    const script = document.createElement('script');
    script.src =
      window.__config__.ENVIRONMENT === 'production'
        ? `/js/biocatchprod.min.js?v=${window.__config__.ENV_HASH}`
        : `/js/biocatchstaging.min.js?v=${window.__config__.ENV_HASH}`;
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
      handleCDApiAttribute();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (isPageLoaded) {
      if (isCustomerLoaded) {
        if (isLoggedIn) {
          insertBioCatchScript();
        }
      } else {
        insertBioCatchScript();
      }
    }
  }, [isPageLoaded, isLoggedIn]);

  useEffect(() => {
    if (scriptLoaded) {
      updateContext(location.pathname);
    }
  }, [scriptLoaded, location]);
  return null;
};

export default BioCatch;
