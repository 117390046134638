import type { Name } from './types';

const formatCustomerFullName = (name: Name): string => {
  // first constructs full name, then
  // removes leading and trailing white spaces, then
  // removes extra middle spaces
  return `${name.prefix} ${name.firstName} ${name.middleName} ${name.lastName} ${name.suffix}`
    .replace(/^\s+|\s+$/g, '')
    .replace(/\s+/g, ' ');
};

export default formatCustomerFullName;
