import translations from './lang';
import { AnyObject, LanguageObject, StringObject, StringsDictionary } from './lang/typings';

/**
 * This finds a string from <language>.json, then replaces its slots with values, returning a fully formatted string for the UI.
 * @param {Object} lang Object that has key of the string from <language>.json
 * @param {Object} data Values of slots to replace.  Key is slot, value is replacement.
 */
export default (lang: StringObject, data?: AnyObject): string => {
  const language = 'en';
  const strings = translations[language] as StringsDictionary;
  const messageKey = Object.keys(lang)[0] as keyof LanguageObject;
  const message = strings[messageKey][lang[messageKey] as string];

  if (data) {
    let result = message;
    const args = Object.keys(data);
    args.forEach((arg: string) => {
      result = result.replace(`%${arg}%`, data[arg]);
    });
    return result;
  }

  return message;
};
