import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from 'src/reducers';
import ArtisaiEvent from '../../components/pixelWhitelisting/artisai/artisaiEvent';

type StateProps = {
  currentLocation: string;
};

const mapStateToProps = (state: ReduxState) => ({
  currentLocation: state.router.location.pathname,
});
const PixelWhitelisting = ({ currentLocation }: StateProps) => {
  return <ArtisaiEvent currentLocation={currentLocation} />;
};

export default connect(mapStateToProps)(PixelWhitelisting);
