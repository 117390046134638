import React from 'react';
import AccountManagementPage from '../../components/accountManagementPage/accountManagementPage';

const CombinedStatements = () => {
  return (
    <AccountManagementPage pageHeading="Combined Statements">
      <></>
    </AccountManagementPage>
  );
};

export default CombinedStatements;
