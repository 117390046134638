/* eslint-disable no-underscore-dangle */

import { v4 as uuidv4 } from 'uuid';
import sessionVariables from './sessionIdConstants';

export const setSessionID = (sessionID: string = uuidv4()): string => {
  global.sessionStorage.setItem(sessionVariables.SESSION_ID_STORAGE_KEY, sessionID);

  const globalWindow = window || global;
  if (globalWindow._SFDDL && globalWindow._SFDDL.base)
    globalWindow._SFDDL.base.session_id = sessionID;

  return sessionID;
};

export const setSessionParam = (key: string, sessionValue: string): string => {
  global.sessionStorage.setItem(key, sessionValue);
  return null;
};

export const getSessionParam = (key): string => global.sessionStorage.getItem(key);

export const getSessionID = (): string =>
  global.sessionStorage.getItem(sessionVariables.SESSION_ID_STORAGE_KEY);

export const clearSessionID = (): void => {
  global.sessionStorage.removeItem(sessionVariables.SESSION_ID_STORAGE_KEY);
  global.sessionStorage.removeItem(sessionVariables.SESSION_ID_CUSTOMER);
};

export const isSessionIDValid = (): boolean => {
  const sessionID = getSessionID();
  return sessionID != null;
};

export const isSessionParamValid = (key: string): boolean => {
  const sessionValue = getSessionParam(key);
  return sessionValue != null;
};
