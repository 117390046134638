export const AFFlow = Object.freeze({
  NAO: 'NAO',
  EAO: 'EAO',
  DIRECT: 'DIRECT',
});

export type AccountFundingFlow = typeof AFFlow[keyof typeof AFFlow];

export const isNAO = (afFlow: AccountFundingFlow) => {
  return afFlow === AFFlow.NAO;
};

export const isEAO = (afFlow: AccountFundingFlow) => {
  return afFlow === AFFlow.EAO;
};

export const isAccountOpeningFlow = (afFlow: AccountFundingFlow) => {
  return isNAO(afFlow) || isEAO(afFlow);
};

export const isDirect = (afFlow: AccountFundingFlow) => {
  return afFlow === AFFlow.DIRECT;
};

export const isYodleeEnabled = () =>
  window.__config__.YODLEE_ENABLED === 'true' || global.__config__.YODLEE_ENABLED;

export const FundingMethod = Object.freeze({
  CHECK: 'check',
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  ADD_EXTERNAL_ACCOUNT: 'new_external',
});
export type FundingMethodType = typeof FundingMethod[keyof typeof FundingMethod];

export const NewExternalFundingMethod = Object.freeze({
  ACCT_NUMBER: 'acct_number',
  YODLEE: 'yodlee',
});
export type NewExternalFundingMethodType =
  typeof NewExternalFundingMethod[keyof typeof NewExternalFundingMethod];

export default { AFFlow, isYodleeEnabled };
