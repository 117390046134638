import React, { useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import { useActivityTileStyles } from '../../../components/activityTile/activityTile.styles';
import { GhostButton } from '../../../components/buttons/buttons';
import type { ViewDocument } from '../documents.constants';
import { DocumentsBtn } from '../../../components/cms/buttonText.constants';
import i18n from '../../../strings/i18n';
import {
  BodyTextSmall,
  ScreenReaderText,
  StandardText,
} from '../../../components/typography/typography';
import { useDocumentsContainerStyles } from '../documents.styles';
import { clickTrack } from '../../../analytics/clickTracking.constants';

type Props = {
  document: ViewDocument;
  onClick: () => Promise<void | null | undefined>;
  errorMessage?: string;
};

const DocumentsTileListItem = (props: Props) => {
  const { document, onClick, errorMessage } = props;
  const activityTileClasses = useActivityTileStyles();
  const classes = useDocumentsContainerStyles();
  const [isLoading, setIsLoading] = useState(false);

  const onButtonClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  const viewDocumentLink = (
    <GhostButton
      data-test="view-document-button"
      onClick={onButtonClick}
      loading={isLoading}
      data-track-title={clickTrack.documents.view_document}
      data-track-page={clickTrack.documents.view}
      className={classes.viewDocumentLink}
    >
      {!errorMessage ? DocumentsBtn.VIEW_DOCUMENT : DocumentsBtn.TRY_AGAIN}
    </GhostButton>
  );

  return (
    <Grid
      key={document.documentId}
      container
      item
      component="tr"
      role="row"
      className={classnames(activityTileClasses.itemContainer, classes.documentRowContainer)}
    >
      <Grid
        xs={6}
        item
        component="td"
        role="cell"
        data-test="document-name-container"
        className={activityTileClasses.item}
      >
        <>
          <StandardText>
            {document.type}
            {document.accountLastFour ? (
              <>
                <span aria-hidden="true">●●</span>
                <ScreenReaderText>{i18n({ accounts: 'accountEndingIn' })}</ScreenReaderText>
                {document.accountLastFour}
              </>
            ) : null}
          </StandardText>
          <Hidden mdUp>
            <p>
              <BodyTextSmall fontWeight="light">{moment(document.date).format('ll')}</BodyTextSmall>
            </p>
          </Hidden>
        </>
      </Grid>
      <Hidden mdUp>
        <Grid
          xs={6}
          item
          component="td"
          role="cell"
          data-test="view-document-container"
          className={classes.rightColumn}
        >
          {viewDocumentLink}
          {errorMessage && <div className={classes.errorMessageFont}>{errorMessage}</div>}
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid
          xs={12}
          md={2}
          item
          component="td"
          role="cell"
          data-test="document-date-container"
          className={activityTileClasses.item}
        >
          <p>
            <StandardText>{moment(document.date).format('ll')}</StandardText>
          </p>
        </Grid>
        <Grid
          xs={12}
          md={4}
          item
          component="td"
          role="cell"
          data-test="view-document-container"
          className={classes.rightColumn}
        >
          {viewDocumentLink}
          {errorMessage && <div className={classes.errorMessageFont}>{errorMessage}</div>}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default DocumentsTileListItem;
