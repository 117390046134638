import { mockAffinityCompanyCode } from 'TestUtils/mock-applications';
import { accountDetails } from './mockDataConstants.ts';

const moneyMarketAccount = 'Money Market Account';
const balanceRangeValues = Object.freeze({
  RANGE_0_TO_4999: '$0.01 - $4,999.99',
  RANGE_0_TO_14999: '$0.00 - $14,999.99',
  RANGE_2000_TO_24999: '$2,000.00 - $24,999.99',
  RANGE_15000_TO_24999: '$15,000.00 - $24,999.00',
  RANGE_24999_TO_99999: '$24,999.01 - $99,999.00',
  RANGE_25000_TO_99999: '$25,000.00 - $99,999.99',
  RANGE_99999_ABOVE: '$99,999.01 + ',
  RANGE_100000_ABOVE: '$100,000.00 +',
});

export const cdTerm = {
  id: '14',
  description: '60 MONTH CD',
  productId: '360',
  term: 60,
  termUnits: 'Months',
  rateMatrices: [
    {
      balanceRange: accountDetails.FIRST_BALANCE_RANGE,
      minBalance: 2000.0,
      maxBalance: 24999.0,
      apy: '2.60%',
      rate: '2.56%',
    },
    {
      balanceRange: accountDetails.SECOND_BALANCE_RANGE,
      minBalance: 24999.01,
      maxBalance: 99999.0,
      apy: '2.60%',
      rate: '2.56%',
    },
    {
      balanceRange: accountDetails.THIRD_BALANCE_RANGE,
      minBalance: 99999.01,
      maxBalance: 99999999999,
      apy: '2.60%',
      rate: '2.56%',
    },
  ],
};

export const termList = [
  {
    id: '1',
    description: 'Money Market',
    productId: '130',
    rateMatrices: [
      {
        balanceRange: '$0.01 - $4,999.00',
        minBalance: 0.01,
        maxBalance: 4999.0,
        apy: '1.25%',
        rate: '1.24%',
      },
      {
        balanceRange: accountDetails.FOURTH_BALANCE_RANGE,
        minBalance: 4999.01,
        maxBalance: 24999.0,
        apy: '1.25%',
        rate: '1.24%',
      },
      {
        balanceRange: accountDetails.FIFTH_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999999999,
        apy: '1.25%',
        rate: '1.24%',
      },
    ],
  },
  {
    id: '2',
    description: 'Interest Checking',
    productId: '110',
    rateMatrices: [
      {
        balanceRange: '$0.00 - $4,999.00',
        minBalance: 0.0,
        maxBalance: 4999.0,
        apy: '0.30%',
        rate: '0.29%',
      },
      {
        balanceRange: accountDetails.FOURTH_BALANCE_RANGE,
        minBalance: 4999.01,
        maxBalance: 24999.0,
        apy: '0.50%',
        rate: '0.49%',
      },
      {
        balanceRange: accountDetails.FIFTH_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999999999,
        apy: '0.70%',
        rate: '0.69%',
      },
    ],
  },
  {
    id: '3',
    description: 'High Yield Savings',
    productId: '100',
    rateMatrices: [
      {
        balanceRange: '$0.01 - $4,999.00',
        minBalance: 0.01,
        maxBalance: 4999.0,
        apy: '2.05%',
        rate: '2.03%',
      },
      {
        balanceRange: accountDetails.FOURTH_BALANCE_RANGE,
        minBalance: 4999.01,
        maxBalance: 24999.0,
        apy: '2.05%',
        rate: '2.03%',
      },
      {
        balanceRange: accountDetails.FIFTH_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999999999,
        apy: '2.05%',
        rate: '2.03%',
      },
    ],
  },
  {
    id: '4',
    description: '3 MONTH CD',
    productId: '303',
    term: 3,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '0.80%',
        rate: '0.79%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '0.80%',
        rate: '0.79%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '0.80%',
        rate: '0.79%',
      },
    ],
  },
  {
    id: '5',
    description: '6 MONTH CD',
    productId: '306',
    term: 6,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '1.05%',
        rate: '1.04%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '1.05%',
        rate: '1.04%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '1.05%',
        rate: '1.04%',
      },
    ],
  },
  {
    id: '6',
    description: '9 MONTH CD',
    productId: '309',
    term: 9,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '1.30%',
        rate: '1.29%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '1.30%',
        rate: '1.29%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '1.30%',
        rate: '1.29%',
      },
    ],
  },
  {
    id: '7',
    description: '12 MONTH CD',
    term: 12,
    termUnits: 'Months',
    productId: '312',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '2.40%',
        rate: '2.37%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '2.40%',
        rate: '2.37%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '2.40%',
        rate: '2.37%',
      },
    ],
  },
  {
    id: '8',
    description: '13 MONTH CD',
    productId: '313',
    term: 13,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '2.45%',
        rate: '2.42%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '2.45%',
        rate: '2.42%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '2.45%',
        rate: '2.42%',
      },
    ],
  },
  {
    id: '9',
    description: '18 MONTH CD',
    productId: '318',
    term: 18,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '2.45%',
        rate: '2.42%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '2.45%',
        rate: '2.42%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '2.45%',
        rate: '2.42%',
      },
    ],
  },
  {
    id: '10',
    description: '24 MONTH CD',
    productId: '324',
    term: 24,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '2.50%',
        rate: '2.47%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '2.50%',
        rate: '2.47%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '2.50%',
        rate: '2.47%',
      },
    ],
  },
  {
    id: '11',
    description: '24 MONTH BUMP UP CD',
    productId: '624',
    term: 24,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '0.05%',
        rate: '0.05%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '0.05%',
        rate: '0.05%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '0.05%',
        rate: '0.05%',
      },
    ],
  },
  {
    id: '12',
    description: '36 MONTH CD',
    productId: '336',
    term: 36,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '2.50%',
        rate: '2.47%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '2.50%',
        rate: '2.47%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '2.50%',
        rate: '2.47%',
      },
    ],
  },
  {
    id: '13',
    description: '48 MONTH CD',
    productId: '348',
    term: 48,
    termUnits: 'Months',
    rateMatrices: [
      {
        balanceRange: accountDetails.FIRST_BALANCE_RANGE,
        minBalance: 2000.0,
        maxBalance: 24999.0,
        apy: '2.55%',
        rate: '2.51%',
      },
      {
        balanceRange: accountDetails.SECOND_BALANCE_RANGE,
        minBalance: 24999.01,
        maxBalance: 99999.0,
        apy: '2.55%',
        rate: '2.51%',
      },
      {
        balanceRange: accountDetails.THIRD_BALANCE_RANGE,
        minBalance: 99999.01,
        maxBalance: 99999999999,
        apy: '2.55%',
        rate: '2.51%',
      },
    ],
  },
  cdTerm,
  {
    id: '15',
    description: 'Empty Rate Matrix',
    productId: '390',
    rateMatrices: [],
  },
];

export const mockMMAProduct = {
  displayName: 'Money Market',
  displayCode: 'MMA',
  hasChecks: true,
  hasAtmCards: true,
  terms: [1],
  promotion: null,
  maxAPY: '0.15%',
  maxRate: '0.10%',
};

export const mockHYSProduct = {
  displayName: 'High Yield Savings',
  displayCode: 'HYS',
  hasChecks: false,
  hasAtmCards: true,
  terms: [2],
  promotion: null,
  maxAPY: '0.45%',
  maxRate: '0.40%',
};

export const mockCDProduct = {
  displayName: 'Certificate of Deposit',
  displayCode: 'CD',
  terms: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  promotion: null,
  maxAPY: '0.65%',
  maxRate: '0.60%',
};
export const mockProductList = [mockMMAProduct, mockHYSProduct, mockCDProduct];

export const mockPromoRateMatrices = [
  {
    balanceRange: '$0.00 - $14,999.99',
    minBalance: 0,
    maxBalance: 14999.99,
    apy: '2.53%',
    rate: '2.50%',
  },
  {
    balanceRange: '$15,000.00 - $24,999.00',
    minBalance: 15000,
    maxBalance: 24999,
    apy: '1.51%',
    rate: '1.50%',
  },
  {
    balanceRange: accountDetails.SECOND_BALANCE_RANGE,
    minBalance: 24999.01,
    maxBalance: 99999,
    apy: '1.51%',
    rate: '1.50%',
  },
  {
    balanceRange: accountDetails.THIRD_BALANCE_RANGE,
    minBalance: 99999.01,
    maxBalance: 99999999999,
    apy: '1.51%',
    rate: '1.50%',
  },
];

export const fetchProductsSuccessResponse = {
  affinity: false,
  affinityCompanyCode: mockAffinityCompanyCode,
  productTypes: {
    products: [
      {
        displayCode: 'MMA',
        displayName: moneyMarketAccount,
        hasChecks: true,
        hasAtmCards: true,
        terms: [1],
        promotion: null,
        maxAPY: '0.15%',
        maxRate: '0.10%',
      },
      {
        displayCode: 'HYS',
        displayName: 'High Yield Savings',
        hasChecks: false,
        hasAtmCards: true,
        terms: [2],
        promotion: null,
        maxAPY: '0.45%',
        maxRate: '0.40%',
      },
      {
        displayCode: 'CD',
        displayName: 'Certificate of Deposit',
        terms: [3, 4, 5, 6, 7, 8, 9, 10, 11],
        promotion: null,
        maxAPY: '0.65%',
        maxRate: '0.60%',
      },
    ],
    terms: [
      {
        id: '1',
        description: moneyMarketAccount,
        productId: '130',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_0_TO_4999,
            minBalance: 0.01,
            maxBalance: 4999.99,
            apy: '0.85%',
            rate: '0.84%',
          },
          {
            balanceRange: '$5,000.00 - $24,999.99',
            minBalance: 5000,
            maxBalance: 24999.99,
            apy: '0.86%',
            rate: '0.85%',
          },
          {
            balanceRange: '$25,000.00 +',
            minBalance: 25000,
            maxBalance: 99999999999,
            apy: '0.87%',
            rate: '0.86%',
          },
        ],
      },
      {
        id: '2',
        description: 'High Yield Savings',
        productId: '100',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_0_TO_4999,
            minBalance: 0.01,
            maxBalance: 4999.99,
            apy: '1.05%',
            rate: '1.04%',
          },
          {
            balanceRange: '$5,000.00 - $24,999.99',
            minBalance: 5000,
            maxBalance: 24999.99,
            apy: '1.05%',
            rate: '1.04%',
          },
          {
            balanceRange: '$25,000.00 +',
            minBalance: 25000,
            maxBalance: 99999999999,
            apy: '1.05%',
            rate: '1.04%',
          },
        ],
      },
      {
        id: '3',
        term: '3',
        description: '3 Month CD',
        productId: '303',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '0.40%',
            rate: '0.40%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '0.26%',
            rate: '0.26%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '0.27%',
            rate: '0.27%',
          },
        ],
      },
      {
        id: '4',
        term: '6',
        description: '6 Month CD',
        productId: '306',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '0.50%',
            rate: '0.50%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '0.33%',
            rate: '0.33%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '0.34%',
            rate: '0.34%',
          },
        ],
      },
      {
        id: '5',
        term: '9',
        description: '9 Month CD',
        productId: '309',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '0.35%',
            rate: '0.35%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '0.36%',
            rate: '0.36%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '0.37%',
            rate: '0.37%',
          },
        ],
      },
      {
        id: '6',
        term: '12',
        description: '12 Month CD',
        productId: '312',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.25%',
            rate: '1.24%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '1.25%',
            rate: '1.24%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '1.25%',
            rate: '1.24%',
          },
        ],
      },
      {
        id: '7',
        term: '18',
        description: '18 Month CD',
        productId: '318',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.25%',
            rate: '1.24%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '1.26%',
            rate: '1.25%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '1.27%',
            rate: '1.26%',
          },
        ],
      },
      {
        id: '8',
        term: '24',
        description: '24 Month CD',
        productId: '324',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.45%',
            rate: '1.44%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '1.45%',
            rate: '1.44%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '1.45%',
            rate: '1.44%',
          },
        ],
      },
      {
        id: '9',
        term: '24',
        description: '24 MONTH BUMP UP CD',
        productId: '624',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.45%',
            rate: '1.44%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '1.45%',
            rate: '1.44%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '1.45%',
            rate: '1.44%',
          },
        ],
      },
      {
        id: '10',
        term: '36',
        description: '36 Month CD',
        productId: '336',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.60%',
            rate: '1.58%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '1.60%',
            rate: '1.58%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '1.70%',
            rate: '1.68%',
          },
        ],
      },
      {
        id: '11',
        term: '48',
        description: '48 Month CD',
        productId: '348',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.70%',
            rate: '1.68%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '1.75%',
            rate: '1.73%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '1.75%',
            rate: '1.73%',
          },
        ],
      },
      {
        id: '12',
        term: '60',
        description: '60 Month CD',
        productId: '360',
        rateMatrices: [
          {
            balanceRange: balanceRangeValues.RANGE_2000_TO_24999,
            minBalance: 2000,
            maxBalance: 24999.99,
            apy: '1.95%',
            rate: '1.98%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_25000_TO_99999,
            minBalance: 25000,
            maxBalance: 99999.99,
            apy: '2.00%',
            rate: '1.98%',
          },
          {
            balanceRange: balanceRangeValues.RANGE_100000_ABOVE,
            minBalance: 100000,
            maxBalance: 99999999999,
            apy: '2.00%',
            rate: '1.98%',
          },
        ],
      },
    ],
  },
  validAffinityCompanyCode: false,
  shouldShowInvalidAffinityCodeModal: false,
  affinityVerbiage: '',
  rateMatrices: [
    {
      balanceRange: balanceRangeValues.RANGE_0_TO_14999,
      minBalance: 0,
      maxBalance: 14999.99,
      apy: '2.53%',
      rate: '2.50%',
    },
    {
      balanceRange: balanceRangeValues.RANGE_15000_TO_24999,
      minBalance: 15000,
      maxBalance: 24999,
      apy: '1.51%',
      rate: '1.50%',
    },
    {
      balanceRange: balanceRangeValues.RANGE_24999_TO_99999,
      minBalance: 24999.01,
      maxBalance: 99999,
      apy: '1.51%',
      rate: '1.50%',
    },
    {
      balanceRange: balanceRangeValues.RANGE_99999_ABOVE,
      minBalance: 99999.01,
      maxBalance: 99999999999,
      apy: '1.51%',
      rate: '1.50%',
    },
  ],
};

export default {
  affinity: false,
  productTypes: {
    products: mockProductList,
    terms: termList,
  },
  validAffinityCompanyCode: false,
  rateMatrices: mockPromoRateMatrices,
};
