import { EffectCallback, useEffect as reactUseEffect } from 'react';

/**
 * Hook to run useEffect only on the initial component mount.
 * @see https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
 */
export const useEffectOnce = (cb: EffectCallback) => {
  // HACK: eslint autofix breaks deps when they explicitly should be empty
  // https://github.com/facebook/react/issues/15204
  const useEffect = reactUseEffect; // eslint-disable-line no-underscore-dangle

  useEffect(cb, [
    /* no deps to only trigger once */
  ]);
};

export default useEffectOnce;
