import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Button from 'bank-component-library/ui/atoms/Button';
import IgniteModal, {
  IgniteModalContent,
  IgniteModalActions,
} from '../../components/modal/igniteModal';
import { clearSessionID } from '../../utilities/sessionID';
import type { StyleProps } from '../../utilities/types';
import type { ReduxState } from '../../reducers';
import { stopTimer } from '../authenticate/authenticate.actions';
import Routes, { TimerIgnorePaths } from '../routes/routes.constants';
import { ModalText } from '../../components/cms/blockText.constants';
import { ModalBtnText } from '../../components/cms/buttonText.constants';
import { ACTION_REFRESH_ACTIVITY_TIMER } from '../authenticate/authenticate.reducer';
import { NAO_FORM_ID } from '../../utilities/accountOpeningFlowType';
import InactivityTime from './activityTimer.constant';

type DispatchProps = {
  cancelNAOApplication: () => void;
  refreshActivityTimer: () => void;
  logout: () => void;
  stopTokenTimer: () => void;
};
type StateProps = {
  shouldRefreshTimer: boolean;
  showActivityWarning: boolean;
  sessionIsExpired: boolean;
  isNAO: boolean;
  isAccountOpening: boolean;
};

type Props = StateProps & DispatchProps & StyleProps;
const aoPaths = [Routes.NAO, Routes.EAO];
const aoTransmitPaths = [Routes.OTP_INITIATE_TRANSMIT, Routes.OTP_INITIATE_TRANSMIT_EAO];

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>) => ({
  cancelNAOApplication: () => {
    // force a refresh to ensure user doesn't auto logout before the push
    dispatch(reset(NAO_FORM_ID));
    clearSessionID();
    dispatch(push(Routes.CANCEL_APPLICATION));
  },
  refreshActivityTimer: () => {
    dispatch({ type: ACTION_REFRESH_ACTIVITY_TIMER });
  },
  logout: () => {
    dispatch(push(`${Routes.LOGOUT}?sessionTimeout=true&reason=session-timeout`));
  },
  stopTokenTimer: () => {
    dispatch(stopTimer);
  },
});

export const isSessionExpired = (state: ReduxState, sessionOutTime?: number) => {
  const sessionOutExpiredTime =
    sessionOutTime !== undefined ? sessionOutTime : InactivityTime.default;
  return state.authenticate.activityTicks > sessionOutExpiredTime;
};

const mapStateToProps = (state: ReduxState) => {
  const { isLoggedIn } = state.authenticate;
  const onIgnorePath = TimerIgnorePaths.some((path) =>
    state.router.location.pathname.includes(path)
  );
  const pageHasTimer = isLoggedIn || !onIgnorePath;
  const isAccountOpeningTransmit = aoTransmitPaths.some((path) =>
    state.router.location.pathname.includes(path)
  );
  const sessionOutInactivityTime = isAccountOpeningTransmit
    ? InactivityTime.transmit
    : InactivityTime.default;

  return {
    shouldRefreshTimer:
      !pageHasTimer && state.authenticate.activityTicks && state.authenticate.activityTicks >= 0,
    showActivityWarning: state.authenticate.activityTicks === sessionOutInactivityTime,
    sessionIsExpired: isSessionExpired(state, sessionOutInactivityTime),
    isNAO: state.router.location.pathname.includes(Routes.NAO),
    isAccountOpening: aoPaths.some((path) => state.router.location.pathname.includes(path)),
    pageHasTimer,
  };
};

export class ActivityTimer extends React.Component<Props> {
  componentDidMount() {
    document.addEventListener('click', this.props.refreshActivityTimer);
    document.addEventListener('keydown', this.props.refreshActivityTimer);
  }

  componentDidUpdate() {
    const {
      shouldRefreshTimer,
      sessionIsExpired,
      isNAO,
      refreshActivityTimer,
      stopTokenTimer,
      cancelNAOApplication,
      logout,
    } = this.props;
    if (shouldRefreshTimer) {
      refreshActivityTimer();
      return;
    }

    if (sessionIsExpired) {
      stopTokenTimer();
      if (isNAO) {
        cancelNAOApplication();
      } else {
        logout();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.props.refreshActivityTimer);
    document.removeEventListener('keydown', this.props.refreshActivityTimer);
  }

  render() {
    const { isAccountOpening, showActivityWarning } = this.props;
    return (
      showActivityWarning && (
        <IgniteModal
          visible
          data-test="activity-warning-modal"
          title={ModalText.ACTIVITY_MODAL_TITLE}
        >
          <IgniteModalContent variant="text">
            {isAccountOpening ? ModalText.AO_ACTIVITY_MODAL_BODY : ModalText.ACTIVITY_MODAL_BODY}
          </IgniteModalContent>
          <IgniteModalActions>
            <Button data-test="stay-active-button">
              {isAccountOpening
                ? ModalBtnText.AO_ACTIVITY_MODAL_BTN
                : ModalBtnText.ACTIVITY_MODAL_BTN}
            </Button>
          </IgniteModalActions>
        </IgniteModal>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTimer);
