import type { ReduxState } from '../../reducers/index';
import type { YodleeAccountsLoadedAction } from '../actions';
import {
  ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_LOADED,
  ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_FAILED,
} from '../actions';
import pageTrack from '../pageAnalytics.constants';
import pageAnalytics from '../pageAnalytics';

const initData = {
  account_type: 'generic',
  pagekind: 'transfers',
  pagefunction: 'add_external_bank',
  pagesubfunction: 'servicing',
  pagename: '',
  attr: '',
} as const;

const viewSuccess = (action: YodleeAccountsLoadedAction, state: ReduxState) => {
  const { isLoading, yodleeAccountIds } = state.addExternalAccount;
  const numberOfAccountsAttemptingToBeLinked = (yodleeAccountIds && yodleeAccountIds.length) || 0;
  pageAnalytics.newPageView(initData);
  pageAnalytics.addInfo({ attr: `yodlee_${numberOfAccountsAttemptingToBeLinked}` });

  // only process the state of the page when loading is toggled complete
  if (!isLoading) {
    pageAnalytics.addInfo({ pagename: action.payload }).record();
  }
};

const viewYodleeError = (action: YodleeAccountsLoadedAction, state: ReduxState) => {
  const { isLoading, yodleeAccountIds } = state.addExternalAccount;
  pageAnalytics.newPageView(initData);
  pageAnalytics.addInfo({ pagename: pageTrack.pagename.yodlee_connection_failed });
};

export const yodleeResponsePageEvents = {
  [ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_LOADED]: viewSuccess,
  [ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_FAILED]: viewYodleeError,
} as const;

export default yodleeResponsePageEvents;
