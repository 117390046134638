import { makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import Colors from '../colors/colors';
import { FormHeaderProps } from '../formWithImage/typings';

export default makeStyles(
  (theme: Theme): StyleRules<FormHeaderProps> => ({
    headerContainer: {
      marginTop: pxToRem(15),
      marginLeft: pxToRem(15),
    },
    headerTitle: {
      fontSize: pxToRem(30),
      fontWeight: 'bold',
      color: Colors.charcoalGrey,
    },
    headerDescription: {
      fontSize: pxToRem(16),
      fontWeight: 'normal',
      color: Colors.charcoalGrey,
      marginTop: pxToRem(24),
      marginBottom: pxToRem(16),
    },
    otpInputNumber: {
      marginTop: pxToRem(32),
    },
    headerSendOtp: {
      fontSize: pxToRem(16),
      fontWeight: 'normal',
      color: Colors.charcoalGrey,
      marginTop: pxToRem(16),
      marginBottom: pxToRem(16),
    },
    radio: {
      '&.Mui-checked': {
        border: `solid ${pxToRem(4)} ${Colors.optimumBlue} !important`,
        fontWeight: 'bold',
      },
    },
    termsMessage: {
      marginTop: pxToRem(24),
      fontSize: pxToRem(12),
    },
    buttonHolder: {
      margin: `${pxToRem(5)} ${pxToRem(32)} ${pxToRem(32)} ${pxToRem(32)}`,
    },
    buttonHolderCL: {
      margin: `${pxToRem(48)} 0 ${pxToRem(32)} ${pxToRem(-14)}`,
      justifyContent: 'flex-end',
    },
    authenticateBtn: {
      width: pxToRem(170),
      height: pxToRem(38),
    },
    trustScoreContainer: {
      marginBottom: `pxToRem(100) !important`,
    },
    errorContainer: {
      padding: pxToRem(10),
    },
    errorContainerText: {
      color: 'red !important',
    },
    subTitle: {
      fontSize: pxToRem(16),
    },
    inputError: {
      color: 'red',
    },
    otpLinksHolder: {
      marginLeft: pxToRem(3),
    },
    otpLinks: {
      color: Colors.optimumBlue,
      fontSize: pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'uppercase',
      lineHeight: '1.29',
      letterSpacing: pxToRem(1.17),
      cursor: 'pointer',
      textDecoration: 'underline !important',
    },
    sendOtpContainer: {
      padding: pxToRem(2),
    },
    listHeader: {
      fontSize: pxToRem(16),
      fontWeigh: 'bold',
      marginTop: pxToRem(10),
      marginBottom: pxToRem(10),
    },
    sendCodeAsLabel: {
      padding: pxToRem(16),
    },
    radioButton: {
      position: 'relative',
      marginTop: pxToRem(20),
      [theme.breakpoints.down('xs')]: {
        marginTop: pxToRem(45),
      },
    },
    inputRadio: {
      float: 'left',
      marginRight: pxToRem(10),
    },
    otpLabel: {
      margin: pxToRem(0),
      float: 'left',
    },
    otpSubLabel: {
      fontSize: pxToRem(12),
      marginLeft: pxToRem(24),
    },
    notNumberLink: {
      marginTop: pxToRem(16),
    },
    mobileNumberLabel: {
      fontSize: pxToRem(14),
      color: Colors.charcoalGrey,
    },
    phoneNumber: {
      marginTop: `0 !important`,
    },
    hideConfirmation: {
      visibility: 'hidden',
      height: '0',
    },
    warningIcon: {
      visibility: 'hidden',
    },
    warningIconError: {
      visibility: 'visible',
    },
    headerSubDescription: {
      fontSize: pxToRem(16),
      color: Colors.charcoalGrey,
      margin: `${pxToRem(16)} ${pxToRem(1)} ${pxToRem(12)} 0`,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
    },
  })
);
