import pxToRem from '../../utilities/pxToRem';
import TileListItemStyles from './tileList.styles';
import { Alignment } from '../../styles/layout/alignment.styles';

export default {
  tile: {
    ...TileListItemStyles,
    ...Alignment.horizontalPadding,
    minHeight: pxToRem(70),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  noActivity: {
    fontSize: pxToRem(14),
  },
} as const;
