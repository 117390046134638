import regexp from '../../utilities/regexConsts';
import i18n from '../../strings/i18n';

const checkPhoneTextField = (fieldName: string) => (value?: string | null) =>
  !regexp.formattedPhoneNumber.test(value) ? `Please enter a valid ${fieldName}` : undefined;

const checkOTPTextField = (fieldName: string) => (value?: string | null) =>
  !regexp.otp.test(value) ? `Please enter a valid ${fieldName}` : undefined;

const checkEmptyTextField = (fieldName: string) => (value?: string | null) =>
  !value ? `Enter ${fieldName}` : undefined;

export const checkEmptyPhoneNumber = checkEmptyTextField(
  i18n({ TransmitFormLabels: 'ENTER_PHONE_NUMBER' })
);

export const checkPhoneValidation = checkPhoneTextField(
  i18n({ TransmitFormLabels: 'ENTER_PHONE_NUMBER' })
);

export const checkEmptyOTP = checkEmptyTextField(
  i18n({ TransmitFormLabels: 'ENTER_OTP_INPUT_FIELD_NAME' })
);

export const checkValidOTP = checkOTPTextField(
  i18n({ TransmitFormLabels: 'ENTER_OTP_INPUT_FIELD_NAME' })
);
