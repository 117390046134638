import { makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import { FormHeaderProps } from '../../components/formWithImage/typings';

export default makeStyles(
  (theme: Theme): StyleRules<FormHeaderProps> => ({
    headerContainer: {
      marginTop: pxToRem(15),
      marginLeft: pxToRem(15),
    },
    radioButton: {
      position: 'relative',
      marginTop: pxToRem(20),
      [theme.breakpoints.down('xs')]: {
        marginTop: pxToRem(45),
      },
    },
    contentHolder: {
      minHeight: pxToRem(250),
    },
  })
);
