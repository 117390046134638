import regexp from '../../utilities/regexConsts';

export const removeExtraCharacters = (value) => {
  return value.replace(/[^0-9\s]+/g, '');
};

export const formatPhoneNumber = (value: string) => {
  const digits = removeExtraCharacters(value);
  let formattedValue = digits;
  if (digits.length > 3 && digits.length <= 6)
    formattedValue = `(${digits.slice(0, 3)})-${digits.slice(3)}`;
  else if (digits.length > 6)
    formattedValue = `(${digits.slice(0, 3)})-${digits.slice(3, 6)}-${digits.slice(6)}`;
  return formattedValue;
};

export const phoneValidation = (digits: string) => {
  return regexp.formattedPhoneNumber.test(digits);
};

export const otpValidation = (digits: string) => {
  return regexp.otp.test(digits);
};
