import { ExistingJointOwner, ExistingJointOwners } from 'src/utilities/types';
import type { NewJointOwner, NewJointOwners } from './jointOwners/typings';
import type {
  ExistingCustomerApplicationForm,
  NewCustomerApplicationForm,
} from './applications.actions';

export type ApplicationFormState = {
  newJointOwners: NewJointOwners;
  selectedExistingJointOwners: ExistingJointOwners;
  pendingNewJointOwnerName: string | null | undefined;
  formData: ExistingCustomerApplicationForm | null;
  customerId: string;
  trackingId: string;
  formNAOData: NewCustomerApplicationForm | null;
};

export const initialState: ApplicationFormState = {
  newJointOwners: [],
  selectedExistingJointOwners: [],
  pendingNewJointOwnerName: null,
  formData: null,
  customerId: '',
  trackingId: '',
  formNAOData: null,
};

export const ACTION_ADD_NEW_JOINT_OWNER = 'ACTION_ADD_NEW_JOINT_OWNER';
export const ACTION_EDIT_NEW_JOINT_OWNER = 'ACTION_EDIT_NEW_JOINT_OWNER';
export const ACTION_REMOVE_NEW_JOINT_OWNER = 'ACTION_REMOVE_NEW_JOINT_OWNER';
export const ACTION_ADD_SELECTED_EXISTING_JOINT_OWNER = 'ACTION_ADD_SELECTED_EXISTING_JOINT_OWNER';
export const ACTION_REMOVE_EXISTING_JOINT_OWNER = 'ACTION_REMOVE_EXISTING_JOINT_OWNER';
export const ACTION_PENDING_NEW_JOINT_OWNER_NAME = 'ACTION_PENDING_NEW_JOINT_OWNER_NAME';
export const ACTION_SET_APPLICATION_VALUE = 'ACTION_SET_APPLICATION_VALUE';
export const ACTION_SET_NAO_APPLICATION_VALUE = 'ACTION_SET_NAO_APPLICATION_VALUE';

type EditNewJointOwnerPayload = {
  index: number;
  newJointOwner: NewJointOwner;
};

type RemoveJointOwnerPayload = {
  index: number;
};

export const filterOutDuplicateJointOwners = (
  jointOwners: ExistingJointOwners
): ExistingJointOwners => {
  const customerIds = new Set();
  return jointOwners.filter((jointOwner) => {
    if (customerIds.has(jointOwner.customerId)) {
      return false;
    }
    customerIds.add(jointOwner.customerId);
    return true;
  });
};

function addJointOwner<T>(jointOwners: T[], addedJointOwner: T): T[] {
  const newJointOwners = jointOwners.slice();
  newJointOwners.push(addedJointOwner);
  return newJointOwners;
}

const addNewJointOwner = (
  jointOwners: NewJointOwners,
  addedJointOwner: NewJointOwner
): NewJointOwners => addJointOwner(jointOwners, addedJointOwner);

const addExistingJointOwner = (
  jointOwners: ExistingJointOwners,
  addedJointOwner: ExistingJointOwner
): ExistingJointOwners =>
  filterOutDuplicateJointOwners(addJointOwner(jointOwners, addedJointOwner));

const editNewJointOwner = (
  jointOwners: NewJointOwners,
  editedNewJointOwner: EditNewJointOwnerPayload
): NewJointOwners => {
  const newJointOwners = jointOwners.slice();
  newJointOwners.splice(editedNewJointOwner.index, 1, editedNewJointOwner.newJointOwner);
  return newJointOwners;
};

function removeJointOwner<T>(
  jointOwners: T[],
  removeJointOwnerPayload: RemoveJointOwnerPayload
): T[] {
  const newJointOwners = jointOwners.slice();
  newJointOwners.splice(removeJointOwnerPayload.index, 1);
  return newJointOwners;
}

export type ApplicationFormAction =
  | {
      type: typeof ACTION_ADD_NEW_JOINT_OWNER;
      payload: NewJointOwner;
    }
  | {
      type: typeof ACTION_EDIT_NEW_JOINT_OWNER;
      payload: EditNewJointOwnerPayload;
    }
  | {
      type: typeof ACTION_REMOVE_NEW_JOINT_OWNER;
      payload: RemoveJointOwnerPayload;
    }
  | {
      type: typeof ACTION_ADD_SELECTED_EXISTING_JOINT_OWNER;
      payload: ExistingJointOwner;
    }
  | {
      type: typeof ACTION_SET_NAO_APPLICATION_VALUE;
      payload: NewCustomerApplicationForm;
    }
  | {
      type: typeof ACTION_REMOVE_EXISTING_JOINT_OWNER;
      payload: RemoveJointOwnerPayload;
    }
  | {
      type: typeof ACTION_PENDING_NEW_JOINT_OWNER_NAME;
      payload: string | null | undefined;
    }
  | {
      type: typeof ACTION_SET_APPLICATION_VALUE;
      customerId: string;
      formData: ExistingCustomerApplicationForm;
      trackingId: string;
    };

export default (
  state: ApplicationFormState | null | undefined = initialState,
  action: ApplicationFormAction = undefined
): ApplicationFormState => {
  switch (action.type) {
    case ACTION_ADD_NEW_JOINT_OWNER:
      return { ...state, newJointOwners: addNewJointOwner(state.newJointOwners, action.payload) };
    case ACTION_EDIT_NEW_JOINT_OWNER:
      return { ...state, newJointOwners: editNewJointOwner(state.newJointOwners, action.payload) };
    case ACTION_REMOVE_NEW_JOINT_OWNER:
      return { ...state, newJointOwners: removeJointOwner(state.newJointOwners, action.payload) };
    case ACTION_ADD_SELECTED_EXISTING_JOINT_OWNER:
      return {
        ...state,
        selectedExistingJointOwners: addExistingJointOwner(
          state.selectedExistingJointOwners,
          action.payload
        ),
      };
    case ACTION_REMOVE_EXISTING_JOINT_OWNER:
      return {
        ...state,
        selectedExistingJointOwners: removeJointOwner(
          state.selectedExistingJointOwners,
          action.payload
        ),
      };
    case ACTION_SET_NAO_APPLICATION_VALUE: {
      return {
        ...state,
        formNAOData: action.payload,
      };
    }
    case ACTION_PENDING_NEW_JOINT_OWNER_NAME:
      return {
        ...state,
        pendingNewJointOwnerName: action.payload,
      };
    case ACTION_SET_APPLICATION_VALUE: {
      return {
        ...state,
        formData: action.formData,
      };
    }
    default:
      return { ...state };
  }
};
