import axios, { InternalAxiosRequestConfig } from 'axios';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  getAccessToken,
  isTokenValid,
  AUTH_STATE_TOKEN_STORAGE_KEY,
  MODIFY_PROFILE_TOKEN_STORAGE_KEY,
} from './authentication';
import {
  MFA_CANCEL_URL,
  MFA_CHALLENGE_URL,
  MFA_VALIDATE_URL,
  EDIT_USERNAME_URL,
  EDIT_PASSWORD_URL,
  APPLICATIONS_TERMS_URL,
  APPLICATIONS_URL,
} from './route-mappings';

const skipVersionHeaderRoutes = {
  [MFA_CANCEL_URL()]: true,
  [MFA_CHALLENGE_URL()]: true,
  [MFA_VALIDATE_URL()]: true,
} as const;

const useAuthStateTokenRoutes = skipVersionHeaderRoutes;

const useProfileModifyScopeTokenRoutes = {
  [EDIT_USERNAME_URL()]: true,
  [EDIT_PASSWORD_URL()]: true,
  [APPLICATIONS_TERMS_URL()]: true,
  [APPLICATIONS_URL()]: true,
} as const;

const IgniteAxios = () => {
  const instance = axios.create({
    baseURL: `/api`,
  });

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      const { url, headers, method = '', ...rest } = config;
      const updatedConfig = { url, headers, method, ...rest } as const;
      if (window.__config__.PASS_VERSION_HEADER === 'true' && !skipVersionHeaderRoutes[url]) {
        if (url.startsWith(window.__config__.API_V2_BASE_URL)) {
          updatedConfig.headers['X-SYF-Version'] = 'develop';
        } else {
          updatedConfig.headers.version = 'develop';
        }
      }
      if (useAuthStateTokenRoutes[url]) {
        updatedConfig.headers.Authorization = `Bearer ${getAccessToken(
          AUTH_STATE_TOKEN_STORAGE_KEY
        )}`;
      } else if (
        useProfileModifyScopeTokenRoutes[url] &&
        isTokenValid(MODIFY_PROFILE_TOKEN_STORAGE_KEY)
      ) {
        updatedConfig.headers.Authorization = `Bearer ${getAccessToken(
          MODIFY_PROFILE_TOKEN_STORAGE_KEY
        )}`;
      } else if (isTokenValid(ACCESS_TOKEN_STORAGE_KEY)) {
        updatedConfig.headers.Authorization = `Bearer ${getAccessToken(ACCESS_TOKEN_STORAGE_KEY)}`;
      } else if (updatedConfig.headers.Authorization) {
        delete updatedConfig.headers.Authorization;
      }

      return updatedConfig as InternalAxiosRequestConfig;
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(axios.isCancel(error) ? error : error.response)
  );

  return instance;
};

export default IgniteAxios();

export const { CancelToken } = axios;
