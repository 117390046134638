import { makeStyles } from '@material-ui/styles';
import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../../components/colors/colors';
import type { Theme } from '../../utilities/types';
import { Alignment } from '../../styles/layout/alignment.styles';

export const usePreferencesContainerStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    container: {
      ...Alignment.allPadding,
      maxWidth: pxToRem(1280),
    },
    containerNewDesign: {
      padding: `${pxToRem(39)} ${pxToRem(343)} ${pxToRem(32)} ${pxToRem(48)}`,
      maxWidth: pxToRem(1280),
      [theme.breakpoints.down('md')]: {
        padding: `${pxToRem(39)} ${pxToRem(48)} ${pxToRem(32)} ${pxToRem(48)}`,
      },
    },
    root: {
      padding: `${pxToRem(16)} ${pxToRem(48)} ${pxToRem(16)} ${pxToRem(48)}`,
    },
    tileItem: {
      backgroundColor: Colors.white,
      wordWrap: 'break-word',
      boxShadow: `0 ${pxToRem(10)} ${pxToRem(40)} 0 rgba(0, 0, 0, 0.1)`,
      padding: pxToRem(24),
    },
    ePreferenceNotification: {
      display: 'flex',
      paddingTop: `${pxToRem(7)} !important`,
      alignItems: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
      },
    },
    notificationIcon: {
      marginRight: pxToRem(8),
      paddingTop: pxToRem(5),
      boxSizing: 'content-box',
      [theme.breakpoints.up('sm')]: {
        paddingTop: pxToRem(0),
      },
    },
    electronicDeliveryCheckboxContainer: {
      marginTop: pxToRem(30),
    },
    ePreferencesSubTitle: {
      paddingTop: `${pxToRem(0)} !important`,
      [theme.breakpoints.up('md')]: {
        paddingTop: `${pxToRem(9)} !important`,
        paddingRight: `${pxToRem(2)} !important`,
      },
    },
    ePreferencesSubTitleText: {
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: `${pxToRem(30)} !important`,
      },
    },
    ePreferencesTaxDocuments: {
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: `${pxToRem(34)} !important`,
      },
    },
    notificationTier: {
      marginTop: pxToRem(35),
    },
    navigationControls: {
      marginTop: pxToRem(32),
    },
    accountHeaderCell: {
      width: '55%',
    },
    emailPreferencesDetail: {
      paddingTop: `${pxToRem(7)} !important`,
    },
    preferencesMainLable: {
      marginTop: pxToRem(6),
    },
    mailAndOnlineHeader: {
      whiteSpace: 'nowrap',
    },
    accountTable: {
      boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fafafa',

      // Center everything except the Account column
      '& td:not(:first-child),th:not(:first-child)': {
        textAlign: 'center',
      },
    },
    accountTableBody: {
      '& tr:nth-child(odd)': {
        backgroundColor: Colors.white,
      },
    },
    tableHeader: {
      paddingBottom: pxToRem(8),
    },
    tableRowStyle: {
      display: 'flex',
      flexDirection: 'column',
      margin: `0 0 0 ${pxToRem(-27)}`,
    },
    tableCellStyle: {
      paddingBottom: 0,
      borderBottom: 'none',
    },
    tableCellStyleOnlyRadio: {
      borderBottom: 'none',
      paddingTop: pxToRem(8),
      paddingBottom: pxToRem(5),
    },
  })
);

export default usePreferencesContainerStyles;
