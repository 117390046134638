/* Copyright(c) 2016, iovation, inc. All rights reserved. */

// Some Documentation: https://developer.bazaarvoice.com/conversations-api/tutorials/submission/device-fingerprinting/iovation-web#hidden-form-field
window.io_global_object_name = 'IGLOO';
window.IGLOO = window.IGLOO || {
  bbout_element_id: 'blackbox',
  enable_atrip: false, // forwarded from ignite-auth, fixes some inline issues
  enable_flash: false,
  loader: {
    version: 'general5', // forwarded from ignite-auth, fixes some inline issues
  },
};

require('./iovation.js');
