export const SelectField = Object.freeze({
  ACCOUNT_FOR_TRANSFER: 'Select Account',
  CD_TERM: 'Select Term',
  CITIZENSHIP: 'Select Country',
  EXISTING_JOINT_OWNER: 'Select Owner',
  FUNDS_SOURCE: 'Select Source',
  GENERIC: 'Select One',
  OCCUPATION: 'Select Occupation',
  OWNER_TYPE: 'Select Type',
  PRODUCT_TYPE: 'Select Product',
  SECURITY_QUESTION: 'Select Security Question',
  SORT_ACCOUNTS: 'SORT BY',
  STATE: 'Select State',
});

export default {
  SelectField,
};
