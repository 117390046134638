/* eslint-disable */
(function () {
  var e = document.getElementsByTagName('script')[0];
  var d = document.createElement('script');
  d.src = '//insiderdata360online.com/service/platform.js?ran=' + Math.random();
  d.type = 'text/javascript';
  d.async = true;
  d.defer = true;
  e.parentNode.insertBefore(d, e);
})();
