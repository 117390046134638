import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import type { FormApi } from 'final-form';
import type { AccountDetailsType, ProductDisplayType } from 'Domain/Account/AccountDetails';
import type { AllAccountTypeName } from 'Domain/Account/AllAccount';
import type { EpreferencesForm } from '../../utilities/types';
import isFeatureFlagEnabled from '../../utilities/isFeatureFlagEnabled';

export const PreferencesTitle = 'Statement and Notice Delivery';
export const PreferencesOldTitle = 'Statement and Notice Delivery Preferences';
export const ManageTaxDocumentButtonLabel = 'MANAGE TAX DOCUMENT PREFERENCES';
export const TableHeader = 'Delivery Preferences';
export const TaxDocumentPreferenceTitle = 'Tax Document Delivery';

export const UpdatedPreferencesNotificationStatement =
  'You can only manage paperless settings for accounts where you are the primary owner. Electronic delivery is not available for Trust accounts.';

export const PreferencesOptions = Object.freeze({
  MAIL_AND_ONLINE: 'mailAndOnline',
  ONLINE_ONLY: 'onlineOnly',
});

export const ETaxDocumentPreferencesConstant = Object.freeze({
  MODAL_TITLE: '1099-INT Tax Document Preferences',
  MODAL_SUB_TITLE: `Receive email notifications when your 1099-INT document is available online.
  Your election for 1099-INT documents will be effective for all existing accounts and all future
  accounts opened until you change your election. Please return here to change your election.`,
  FIELD_LABEL: '1099-INT Document',
  PAGE_SUB_TITLE: `Receive email notifications when your statements, disclosures and notices for your accounts are available online and in the app. 
  For any accounts you wish to continue receiving paper statements, please allow 3-5 weeks for arrival. IRA customers will receive statements online but also be mailed one quarterly.`,
  TAX_PREFERENCE_TITLE: `Looking to update your tax document preferences? `,
  TAX_PREFERENCE_SUB_TITLE: `Sign up to receive email notifications when your 1099-INT document is available online.`,
});

export type PreferencesOption = typeof PreferencesOptions[keyof typeof PreferencesOptions];

export const PreferencesFieldNames = Object.freeze({
  ALL_ACCOUNTS: 'allAccounts',
  ELECTRONIC_DELIVERY_TERMS_ACCEPTED: 'areTermsAccepted',
  SELECT_PREFERENCE: 'preferenceId',
});

export const getSelectPreferenceFieldName = (accountId: string) => {
  return `${accountId}${PreferencesFieldNames.SELECT_PREFERENCE}`;
};

export const PreferencesTableHeaders = Object.freeze({
  ACCOUNT: 'Account',
  MAIL_AND_ONLINE: 'Mail and Online',
  PRIOR_ONLINE_ONLY: 'Online-Only',
  ONLINE_ONLY: 'Online Only',
});

export const PreferencesFormLabels = Object.freeze({
  ALL_ACCOUNTS: 'All Accounts',
});

export const EDeliveryStatus = Object.freeze({
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  ENROLLED: 'ENROLLED',
  NOT_ENROLLED: 'NOT_ENROLLED',
  PARTIALLY_ENROLLED: 'PARTIALLY_ENROLLED',
});

export const ePreferencesFeatureFlag = isFeatureFlagEnabled('EPREFERENCES_NEW_DESIGN_ENABLED');

export enum ETaxDocumentPreferencesFieldNames {
  ELECTRONIC_DELIVERY_TERMS_ACCEPTED = 'areETaxTermsAccepted',
  E_DELIVERY_STATUS = 'edeliveryStatus',
}

export type EligibleAccount = {
  accountId: string;
  accountType: AccountDetailsType;
  product: {
    productType: string;
    productDisplayType: ProductDisplayType;
    productId: string;
    productName: AllAccountTypeName;
  };
  nickname: string;
  accountIdDisplay: string;
  edelivery: boolean;
};

export type StatementPreferences = {
  eligibleAccounts: EligibleAccount[];
  combinedStatements?: boolean;
};

export type EPreferencesInfo = {
  statementPreferences: StatementPreferences;
};

export type PatchEPreferences = {
  statementPreferences:
    | {
        eligibleAccounts: {
          accountId: string;
          accountType: AccountDetailsType;
          edelivery: boolean;
        }[];
        areTermsAccepted?: boolean;
      }
    | {
        combinedStatements: boolean;
      };
};

export type ETaxDocumentPreferences = {
  areTermsAccepted: boolean;
  edeliveryStatus: typeof EDeliveryStatus[keyof typeof EDeliveryStatus];
};

export type ETaxDocumentPreferencesResponse = {
  taxDocumentsPreferences: ETaxDocumentPreferences;
};

export type ETaxDocumentPreferencesFormData = {
  areETaxTermsAccepted: boolean;
  edeliveryStatus: typeof EDeliveryStatus[keyof typeof EDeliveryStatus];
};

export type AccountIdPreferenceProps = {
  [key: string]: string;
};

export type AllAccountFiledProps = { ariaLabel: string; value: string };

export type EPreferenceProps = {
  eligibleAccounts: EligibleAccount[];
  classes: ClassNameMap;
  updateFormDirtyStatus: (arg1: boolean) => void;
  showConfirmationModal: boolean;
  hasSessionExpired: boolean;
  clearFlashMessage: () => void;
  setSuccessFlashMessage: () => void;
  recordAnalyticsPageView: (arg1: string, arg2: string) => void;
  ePreferences: EPreferencesInfo;
  setEPreferences: (arg1: EPreferencesInfo) => void;
  setShowConfirmationModal: (arg1: boolean) => void;
  setErrorFlashMessage: (arg1: string) => void;
};

export type TableBodyProps = {
  classes: ClassNameMap;
  eligibleAccounts: EligibleAccount[];
  allAccountsFieldOptions: AllAccountFiledProps[];
  values: AccountIdPreferenceProps;
  form: FormApi<EpreferencesForm>;
  handleSingleAccountSelection?: (arg1: string, arg2: Record<string, string>) => void;
  handleAllAccountSelection?: (arg1: string) => void;
};

export type TableHeadProps = {
  classes: ClassNameMap;
};
