export const accountTypeName = Object.freeze({
  ACCOUNT_TYPE_CD: 'Certificate of Deposit',
  ACCOUNT_TYPE_HYS: 'High Yield Savings',
  ACCOUNT_TYPE_MMA: 'Money Market Account',
});

export const accountDate = Object.freeze({
  OPENED_DATE_02_11: '2020-02-11',
  OPENED_DATE_01_17: '2018-01-17',
  OPENED_DATE_05_17: '2015-05-17',
  CUSTOMER_INFO_05_13: '2019-05-13',
});

export const accountDetails = Object.freeze({
  APY_RATE: '0.000000000000000001',
  ACCOUNT_RELATIONSHIP_TYPE: 'Sole Owner',
  USER_NICKNAME: 'Vacation Fund',
});

export const externalAccountDetails = Object.freeze({
  BANK_NAME_UNITED_AIRLINES: 'United Airlines Employees Credit Union',
  BANK_NAME_SYF: 'Synchrony Bank',
});

export const productDetails = Object.freeze({
  FIRST_BALANCE_RANGE: '$2,000.00 - $24,999.00',
  SECOND_BALANCE_RANGE: '$4,999.01 - $24,999.00',
  THIRD_BALANCE_RANGE: '$24,999.01 - $99,999.00',
  FOURTH_BALANCE_RANGE: '$24,999.01 + ',
  FIFTH_BALANCE_RANGE: '$99,999.01 + ',
});

export const promotionalProducts = Object.freeze({
  PROMOTION_DESCRIPTION_HYS: 'High Yield Savings rate variance',
  PROMOTION_DESCRIPTION_CD: '12 Month CD 1.5%',
  PROMOTION_DISCLOSURE: 'This is a Promotion product',
  PROMOTION_DATE_FORMAT: 'YYYY-MM-DD',
  PROMOTION_START_DATE: '2019-11-06',
  PRODUCT_DISPLAY_NAME: 'Money Market',
});
