import React, { ReactElement } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import getImageSrc from '../../utilities/getImageSrc';
import useSvgImage from './index.style';
import ImagesFileNames from '../../images';

type ImageProps = {
  imageName?: string;
  fill?: string;
  alt?: string;
  focusable?: string;
  role?: string;
  tabIndex?: number;
  ariaLabel?: string;
  ariaDescribedby?: string;
  ariaHidden?: string;
  dataTest?: string;
  className?: string;
  wrapperContainerClass?: string;
  iconComponent?: ReactElement;
  handleAfterInjection?: () => void;
};

const SVGImage = (props: ImageProps): ReactElement => {
  const {
    imageName,
    fill,
    alt: title,
    focusable,
    role,
    tabIndex,
    ariaLabel,
    ariaDescribedby,
    ariaHidden,
    dataTest,
    className,
    wrapperContainerClass,
    iconComponent,
    handleAfterInjection,
  } = props;

  const classes = useSvgImage();
  const url = getImageSrc(imageName);

  const handleBeforeInjection = (svg: SVGElement) => {
    const svgImageName = imageName.split('/').pop();
    if (!title) {
      svg.setAttribute('aria-hidden', 'true');
      svg.setAttribute('focusable', 'false');
    }
    if (ariaHidden) {
      svg.setAttribute('aria-hidden', ariaHidden);
    }
    if (className) {
      svg.classList.add(className);
    }
    if (fill && fill.length > 0) {
      svg.setAttribute('fill', fill);
    }
    if (focusable) {
      svg.setAttribute('focusable', focusable);
    }
    if (ariaDescribedby) {
      svg.setAttribute('aria-describedby', ariaDescribedby);
    }
    svg.setAttribute('aria-label', ariaLabel || svgImageName);
    svg.setAttribute('role', role || 'img');
  };

  const afterInjection = (svg: SVGElement) => {
    if (svg?.dataset?.src.includes(ImagesFileNames.iconFDICLogoSvg)) {
      handleAfterInjection();
    }
  };

  const renderReactSVGImg = () => {
    return process.env.NODE_ENV === 'test' ? (
      <ReactSVG src={url} />
    ) : (
      <ReactSVG
        src={url}
        data-test={dataTest}
        className={classnames({
          [classes.outerDivContainer]: !wrapperContainerClass,
          [wrapperContainerClass]: wrapperContainerClass,
        })}
        beforeInjection={handleBeforeInjection}
        afterInjection={afterInjection}
        title={title}
        tabIndex={tabIndex}
      />
    );
  };

  return iconComponent || renderReactSVGImg();
};

export default SVGImage;
