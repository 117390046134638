import { Grid } from '@material-ui/core';
import React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import { Field as ReduxFormField } from 'redux-form';
import type { FieldValidator } from 'final-form';
import { formatDateOfBirth, normalizeDateOfBirth } from '../../utilities/reduxForm';
import { ReduxFormComponents } from '../reduxForms/inputs';
import {
  checkEmptyDOB,
  checkValidDOB,
  checkIfTooYoung,
  checkIfOutOfRange,
} from '../../containers/newAccountOpening/newUser/openAccount.validators';
import { FinalFormComponents } from '../finalForm/finalFormInputs';
import { composeValidators } from '../../utilities/validators';
import { IdentifyingInformationFieldNames } from '../../form.constants';
import Aria from '../cms/aria.constants';

type Props = {
  disabled?: boolean;
  useFinalForm?: boolean;
  helperText?: string;
  dataTest?: string;
  validate?: FieldValidator<string>;
};

const dobValidator = composeValidators(
  checkEmptyDOB,
  checkValidDOB,
  checkIfTooYoung,
  checkIfOutOfRange
);

export const DobInformation = (props: Props) => {
  const { disabled, useFinalForm, helperText, dataTest, validate } = props;
  const { RenderBirthDateField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    id: 'date-of-birth',
    name: IdentifyingInformationFieldNames.DOB,
    label: 'Date of Birth',
    labelId: 'dateofbirthLabelId',
    'data-test': dataTest,
    validate: validate || dobValidator,
    helperText,
    parse: useFinalForm ? normalizeDateOfBirth : undefined,
    normalize: useFinalForm ? undefined : normalizeDateOfBirth,
    format: formatDateOfBirth,
    component: RenderBirthDateField,
    disabled,
    autoComplete: 'bday',
    fieldinstruction: Aria.ADA_DOB,
  };

  return (
    <Grid container item spacing={2}>
      <Grid item sm={12} xs={12}>
        {useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />}
      </Grid>
    </Grid>
  );
};

export default DobInformation;
