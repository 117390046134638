import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { SecurityAnalyticsSubmitAction } from '../actions';
import {
  ANALYTICS_SECURITY_START_SUCCESS,
  ANALYTICS_SECURITY_SUBMIT_SUCCESS,
  ANALYTICS_SECURITY_SUBMIT_FAILURE,
  ANALYTICS_SECURITY_START_NO_OTP_SUCCESS,
  ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR,
} from '../actions';
import type { ReduxState } from '../../reducers';
import { SecurityEligibilityFlows } from '../../containers/security/security.service';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
  });

const viewSuccess = () => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.start, pagefunction: pageTrack.pagefunction.security })
    .record();
};

const submitSuccess = (action: SecurityAnalyticsSubmitAction) => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.complete,
      pagefunction: action.payload,
    })
    .record();
};

const submitFailure = (action: SecurityAnalyticsSubmitAction) => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.submit_server_error,
      pagefunction: action.payload,
    })
    .record();
};

const viewNoOtpSuccess = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  const { securityEligibilityFlow } = state.profile;
  const pageType =
    securityEligibilityFlow && securityEligibilityFlow === SecurityEligibilityFlows.USERNAME_NO_OTP
      ? pageTrack.pagefunction.change_username
      : pageTrack.pagefunction.change_password;
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.start_no_otp,
      pagefunction: pageType,
    })
    .record();
};

const eligibilityServerError = (action: SecurityAnalyticsSubmitAction) => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.eligibility_server_error,
      pagefunction: action.payload,
    })
    .record();
};

export const securityPageEvents = {
  [ANALYTICS_SECURITY_START_SUCCESS]: viewSuccess,
  [ANALYTICS_SECURITY_SUBMIT_SUCCESS]: submitSuccess,
  [ANALYTICS_SECURITY_SUBMIT_FAILURE]: submitFailure,
  [ANALYTICS_SECURITY_START_NO_OTP_SUCCESS]: viewNoOtpSuccess,
  [ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR]: eligibilityServerError,
} as const;

export default securityPageEvents;
