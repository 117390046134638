import React from 'react';
import { ModalText } from '../cms/blockText.constants';
import { ModalBtnText } from '../cms/buttonText.constants';
import ConfirmationModal from './ConfirmationModal';

type Props = {
  retryUrl?: string;
  onClose: () => void;
};

const PopupBlockedModal = ({ retryUrl, onClose }: Props) => (
  <ConfirmationModal
    titleText={ModalText.POPUP_BLOCKED_MODAL_TITLE}
    contentText={ModalText.POPUP_BLOCKED_MODAL_BODY}
    visible={!!retryUrl}
    onConfirm={() => {
      window.open(retryUrl, '');
      onClose();
    }}
    onExit={onClose}
    confirmText={ModalBtnText.TRY_AGAIN}
  />
);

export default PopupBlockedModal;
