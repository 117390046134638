import { Grid, withStyles, StyleRules } from '@material-ui/core';
import type { ReactNode } from 'react';
import React from 'react';
import type { StyleProps, Theme } from '../../utilities/types';

const styles = (theme: Theme): StyleRules => ({
  dashboardContent: {
    flexWrap: 'nowrap',
    overflowX: 'hidden',
  },
  [theme.breakpoints.up('md')]: {
    dashboardContent: {
      position: 'relative',
    },
  },
});

type Props = {
  children: ReactNode;
} & StyleProps;

const ContentGridContainerAndItemWrapper = (props: Props) => (
  <Grid
    id="main"
    component="main"
    container
    item
    direction="column"
    spacing={0}
    xs={12}
    md={8}
    className={props.classes.dashboardContent}
    aria-labelledby="page-title"
  >
    {props.children}
  </Grid>
);

const contentGridContainerAndItem = withStyles(styles)(ContentGridContainerAndItemWrapper);
contentGridContainerAndItem.displayName = 'DetailLayout';
export default contentGridContainerAndItem;
