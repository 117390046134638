import { makeStyles } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import { Theme } from '../../utilities/types';

export default makeStyles((theme: Theme) => ({
  fdicLogoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fdicSvgLogoWrapper: {
    display: 'grid',
  },
  fdicLogoContent: {
    fontFamily: '"Source Sans 3"',
    fontSize: `${pxToRem(12.8)} !important`,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: `${pxToRem(16.09)} !important`,
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(70),
    [theme.breakpoints.up('sm')]: {
      paddingRight: pxToRem(2),
    },
  },
  fdicLogoIcon: {
    width: pxToRem(37.36),
    height: pxToRem(14.79),
  },
}));
