import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Hidden } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import type { ReduxState } from '../../reducers/index';
import type { Account, Theme } from '../../utilities/types';
import Page from '../page/page';
import FullHeightLayout from '../pageLayout/FullHeightLayout';
import type { FlashMessageProperties } from '../flashMessage/flashMessage.reducer';
import { getNavLinks } from './link.constants';
import NavDesktop from './navDesktop';
import NavMobile from './navMobile';
import LoadingIndicatorStyled from '../loadingIndicator/loadingIndicator';
import type { CombinedStatementsPrefenceState } from '../../containers/combinedStatements/combinedStatements.reducer';
import AlertFlashMessage from '../flashMessage/AlertFlashMessage';

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  container: {
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      flex: '1',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  contentWithFlash: {
    paddingTop: 0,
  },
  flashMessage: {
    marginBottom: 0,
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    margin: 'auto',
  },
}));

type StateProps = {
  accounts: Account[];
  flashMessage: FlashMessageProperties;
  combinedStatementPreference: CombinedStatementsPrefenceState;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  accounts: state.accounts.data,
  flashMessage: state.flashMessage.pageFlashMessage,
  combinedStatementPreference: state.combinedStatementPreference,
});

type Props = {
  children: React.ReactNode;
  fullHeightLayoutClassName?: string;
  loading?: boolean;
  pageHeading: string;
  focusFlashMessage?: boolean;
};

type AllProps = StateProps & Props;

const AccountManagementPage = ({
  accounts,
  children,
  flashMessage,
  fullHeightLayoutClassName,
  loading,
  pageHeading,
  focusFlashMessage,
  combinedStatementPreference,
}: AllProps) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  let showCombinedStatements;

  if (window.sessionStorage.getItem('showCombinedStatements')) {
    showCombinedStatements = window.sessionStorage.getItem('showCombinedStatements') === 'true';
  } else {
    showCombinedStatements =
      combinedStatementPreference.combinedStatements &&
      combinedStatementPreference.eligibleAccounts.length > 1;
    window.sessionStorage.setItem('showCombinedStatements', showCombinedStatements);
  }

  let currentRoutePath;
  try {
    currentRoutePath = window.location.pathname;
  } catch {
    // ignore
  }

  const navLinks = getNavLinks(accounts, showCombinedStatements);
  const selected = navLinks.find((link) => link.to === currentRoutePath) || navLinks[0];
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Page
      pageHeading={pageHeading}
      title="Profile Settings"
      loading={loading}
      contentClassName={classes.pageContent}
      disableLoadingOverlay={isDesktopView}
    >
      <Hidden mdDown>
        <NavDesktop links={navLinks} selected={selected} />
      </Hidden>
      <Hidden lgUp>
        <NavMobile links={navLinks} selected={selected} />
      </Hidden>
      <div className={classes.container}>
        <AlertFlashMessage
          role="alert"
          flashMessage={flashMessage}
          resetScroll
          className={classes.flashMessage}
          focusMessage={focusFlashMessage}
        />
        {loading && isDesktopView && (
          <div className={classNames(classes.loadingIndicator)}>
            <LoadingIndicatorStyled />
          </div>
        )}
        {!loading && (
          <FullHeightLayout
            className={classNames(
              classes.content,
              !!flashMessage.messageText && classes.contentWithFlash,
              fullHeightLayoutClassName
            )}
          >
            {children}
          </FullHeightLayout>
        )}
      </div>
    </Page>
  );
};

export default connect(mapStateToProps)(AccountManagementPage);
