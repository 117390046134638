import pageAnalytics from '../../pageAnalytics';
import type { AccountFundingFlow } from '../../../utilities/accountFundingFlowType';
import { AFFlow } from '../../../utilities/accountFundingFlowType';
import { getPageSubfunction } from '../fundNewAccount.analytics';
import pageTrack from '../../pageAnalytics.constants';

const initPageData = (fundingFlow: AccountFundingFlow) => ({
  account_type: pageTrack.account_type.generic,
  pagekind: pageTrack.pagekind.account_opening,
  pagefunction: pageTrack.pagefunction.funding,
  pagesubfunction: getPageSubfunction(fundingFlow),
});

export const virtualViewPostRegistration = (fundingFlow: AccountFundingFlow) => () => {
  if (fundingFlow === AFFlow.NAO) {
    pageAnalytics
      .newPageView()
      .addInfo({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.registration,
        pagesubfunction: pageTrack.pagesubfunction.NAO,
        pagename: pageTrack.pagename.complete,
      })
      .record();
  }
};

export const viewSuccess = (fundingFlow: AccountFundingFlow) => () => {
  pageAnalytics
    .newPageView(initPageData(fundingFlow))
    .addInfo({ pagename: pageTrack.pagename.start })
    .record();
};

export const viewFailure = (fundingFlow: AccountFundingFlow) => () => {
  pageAnalytics
    .newPageView(initPageData(fundingFlow))
    .addInfo({ pagename: pageTrack.pagename.start_server_error })
    .record();
};
