import type { ReactNode } from 'react';
import React from 'react';
import { SynchronyInfo } from '../../components/cms/blockText.constants';
import { TransferType } from './cdRenewal.service';
import currency from '../../formatters/currency';
import { ScreenReaderText } from '../../components/typography/typography';
import { formatAccountNumberWithType } from '../../utilities/a11y';

//-----------------------------------------------------------------------------
// FORM FIELDS
//-----------------------------------------------------------------------------
export enum TermsAdjustmentFieldNames {
  PRODUCT_ID = 'productId',
}

export enum FundsAdjustmentFieldNames {
  ADJUSTMENT_TYPE = 'adjustmentType',
  AMOUNT = 'amount',
  TRANSFER_ACCOUNT = 'transferAccount',
  TRANSFER_TYPE = 'transferType',
}

// Union of all field name objects
export type CDRenewalFieldNamesType =
  | typeof FundsAdjustmentFieldNames[keyof typeof FundsAdjustmentFieldNames]
  | typeof TermsAdjustmentFieldNames[keyof typeof TermsAdjustmentFieldNames];

//-----------------------------------------------------------------------------
// PAGE CONSTANTS
//-----------------------------------------------------------------------------

export const CdRenewalLabels = Object.freeze({
  ADD_MONEY: 'Add money',
  ADD_OR_WITHDRAW_MONEY: 'Add or Withdraw Money',
  AMOUNT_TO_ADD: 'Amount to Add',
  AMOUNT_TO_WITHDRAW: 'Amount to Withdraw',
  CURRENT_TERM: 'Current Term',
  AVAILABLE_BALANCE: (availableBalance?: string) =>
    availableBalance ? `Available Balance: ${currency(availableBalance)}` : 'Available Balance',
  AVAILABLE_TERMS: 'Available Terms',
  FROM_ACCOUNT: 'From Account',
  RENEWAL_TERM: 'Renewal Term',
  TENANTIVE_MATURITY_DATE: 'Tentative Maturity Date',
  TO_ACCOUNT: 'To Account',
  TRANSFER_ACCOUNT_LABEL: (transferType: string) =>
    transferType === TransferType.INTERNAL ? 'Synchrony Accounts' : 'External Accounts',
  TRANSFER_FROM_EXTERNAL: 'Transfer from External Account',
  TRANSFER_FROM_INTERNAL: 'Transfer from Synchrony account',
  TRANSFER_TO_EXTERNAL: 'Transfer to External Account',
  TRANSFER_TO_INTERNAL: 'Transfer to Synchrony account',
  UNCHANGED_SUBTITLE: 'Balance is subject to change prior to the maturity date.',
  UNCHANGED: 'No addition or withdrawal of money',
  WITHDRAW_MONEY: 'Withdraw money',
  WITHDRAW_MONEY_LABEL: 'Please contact a Banker if you would like to withdraw funds.',
});

export const CDRenewalErrors = Object.freeze({
  ADD_AMOUNT: 'Enter amount to add',
  MINIMUM_BALANCE: (minBalance: number) =>
    `Your remaining balance must be at least ${currency(
      minBalance,
      false
    )} to renew your CD online. If you are trying to close your account or bring your CD balance below ${currency(
      minBalance,
      false
    )}, please contact a Banker at ${SynchronyInfo.CD_PHONE_TEXT}.`,
  WITHDRAW_AMOUNT: 'Enter amount to withdraw',
});

export const CommonPageText = Object.freeze({
  RENEWAL_TERM_TITLE: 'Renewal Term Selection',
  FUNDS_SELECTION_TITLE: 'Funds Selection',
});

export const OverviewPageText = Object.freeze({
  CTA_TEXT: (accountDisplayName: string) => (
    <>
      What should we do when your <span aria-hidden="true">{accountDisplayName}</span>{' '}
      <ScreenReaderText>{formatAccountNumberWithType(accountDisplayName)}</ScreenReaderText>
      matures?
    </>
  ),
  CTA_GRACE_PERIOD_TEXT: (accountDisplayName: string) => (
    <>
      Your <span aria-hidden="true">{accountDisplayName}</span>{' '}
      <ScreenReaderText>{formatAccountNumberWithType(accountDisplayName)}</ScreenReaderText>
      has matured and is now in the grace period.
    </>
  ),
  CTA_DESCRIPTION: (maturityDate: string) => (
    <>
      <p>Let us know what we should do when your CD reaches maturity on {maturityDate}.</p>
      <br />
      <p>
        If you need additional assistance with your account, or would like to close your account,
        please call us at {SynchronyInfo.CD_PHONE_TEXT}
        <span aria-hidden="true">.</span>
      </p>
    </>
  ),
  CTA_GRACE_PERIOD_DESCRIPTION: () => (
    <>
      <p>You don’t need to take any action unless you want to make changes to your CD.</p>
      <br />
      <p>
        If you need additional assistance with your account, or would like to close your account,
        please call us at {SynchronyInfo.CD_PHONE_TEXT}
        <span aria-hidden="true">.</span>
      </p>
    </>
  ),
  MODAL_LINK_TEXT: 'Certificate of Deposit Rates & Terms',
});

export const TermsAdjustmentText = Object.freeze({
  CTA_TEXT: 'Choose your renewal term',
  CTA_DESCRIPTION: 'Your rate will be set on the date of maturity.',
  CURRENT_RATES: 'Current Rates',
  DEPOSIT_AMOUNT: 'Deposit Amount',
  COMPARE_TERM_TEXT: 'COMPARE ALL RATES & TERMS',
});

export const FundsAdjustmentText = Object.freeze({
  CTA_TEXT: 'Would you like to add or withdraw money?',
  YODLEE: 'yodlee',
  REQUIRED_FIELD_NOTE: 'Note:',
  REQUIRED_FIELD_TEXT: 'A selection is required.',
  FUNDS_ADJUSTMENTS_NOTE: (
    <>
      If you would like to close your account, please contact a Banker at {SynchronyInfo.CD_PHONE}
      <span aria-hidden>.</span>
    </>
  ),
});

export const RenewViaYodleeText = Object.freeze({
  CTA_TEXT: 'Add new external bank account',
});

export const VerifyPageText = Object.freeze({
  CTA_TEXT: 'Verify renewal selections',
  CTA_DESCRIPTION: (accountDisplayName: string, maturityDate: string) => (
    <>
      When you click Confirm & Save Changes, these renewal instructions for{' '}
      <span aria-hidden="true">{accountDisplayName}</span>{' '}
      <ScreenReaderText>{formatAccountNumberWithType(accountDisplayName)}</ScreenReaderText>
      will go into effect on {maturityDate} at 12:00am ET.{' '}
    </>
  ),
  CTA_DESCRIPTION_GRACEPERIOD: (accountDisplayName: string) => (
    <>
      When you click Confirm & Save Changes, your renewal instructions for{' '}
      <span aria-hidden="true">{accountDisplayName}</span>{' '}
      <ScreenReaderText>{formatAccountNumberWithType(accountDisplayName)}</ScreenReaderText>
      will go into effect today.{' '}
    </>
  ),
  HELPER_TEXT_ADD_INTERNAL:
    'Transfers are processed on the date of maturity. If the transfer amount is greater than your available balance on that date, it will not be processed.',
  HELPER_TEXT_WITHDRAW_INTERNAL: 'Transfers are processed on the date of maturity.',
  HELPER_TEXT_ADD_EXTERNAL:
    'Transfers from registered external accounts will be initiated on the date of maturity and generally take up to 3 business days to post to the receiving account. If the transfer amount is greater than your available balance on that date, it will not be processed.',
  HELPER_TEXT_WITHDRAW_EXTERNAL:
    'Transfers to registered external accounts will be initiated on the date of maturity and generally take up to 3 business days to post to the receiving account.',
  HELPER_TEXT_CONTACT_US: (
    <>
      If you have any questions or want to make any changes to these instructions after
      confirmation, please contact us at {SynchronyInfo.CD_PHONE}
      <span aria-hidden>.</span>
    </>
  ),
  RENEWAL_TERM_TITLE: 'Renewal Term Selection',
  FUNDS_SELECTION_TITLE: 'Funds Selection',
});

export type CDRenewalTextObjectType = {
  CTA_TEXT: string;
  CTA_DESCRIPTION?: string;
  TRANSFER_DISCLAIMER_TEXT?: string;
};

export const AddFundsText = Object.freeze({
  CTA_TEXT: 'Which account would you like the money to come from?',
  CTA_DESCRIPTION: 'Select the account you want the money to come from.',
});

export const WithdrawFundsText = Object.freeze({
  CTA_TEXT: 'Where do you want your money to go?',
  CTA_DESCRIPTION: 'Select the account you want the money to go to.',
});

export const AddAmountText = Object.freeze({
  CTA_TEXT: 'How much money would you like to add to your CD?',
  TRANSFER_DISCLAIMER_TEXT:
    'If the amount you are adding exceeds the available balance of the "From Account" at the time of maturity, the transfer will not be processed.',
});

export const WithdrawAmountText = Object.freeze({
  CTA_TEXT: 'How much money would you like to withdraw from your CD?',
});

export type CDRenewalConfirmationText = {
  CTA_DESCRIPTION: string | ReactNode;
  CTA_TEXT: string;
  VIEW_ACCOUNT_DETAILS: string;
};

export const ConfirmationText: CDRenewalConfirmationText = Object.freeze({
  CTA_DESCRIPTION: 'Your CD renewal instructions have been submitted successfully as shown below.',
  CTA_TEXT: 'Renewal instructions confirmation',
  VIEW_ACCOUNT_DETAILS: 'View Account Details',
});

const ctaDescriptionInstructions = (
  <>
    Your CD renewal instructions have been received as shown below. If you would like to renew your
    CD with no changes, you can click on &quot;Remove Instructions&quot; to set your CD to
    auto-renew at the rate set at maturity.
  </>
);

export const RenewalInstructionsText: CDRenewalConfirmationText = Object.freeze({
  CTA_DESCRIPTION: ctaDescriptionInstructions,
  CTA_DESCRIPTION_UNCHANGED: ctaDescriptionInstructions,
  CTA_TEXT: 'Your renewal instructions',
  VIEW_ACCOUNT_DETAILS: 'View Account Details',
});

//-----------------------------------------------------------------------------
// ERROR CONSTANTS
//-----------------------------------------------------------------------------

export const ErrorText = Object.freeze({
  CTA_HEADER: 'Unfortunately, an error occurred',
  OPTIONS_CTA_HEADER: 'Unfortunately an error occurred when trying to add your account. You can:',
  OPTION_EXISTING_ACCOUNT_TITLE: 'Use an existing account',
  OPTION_EXISTING_ACCOUNT_DESCRIPTION_ADD:
    'Use a different Synchrony or external account you have already linked to add money from.',
  OPTION_EXISTING_ACCOUNT_DESCRIPTION_WITHDRAW:
    'Use a different Synchrony or external account you have already linked to withdraw money to.',
  OPTION_EXISTING_ACCOUNT_CTA_TEXT: 'Try another account',
  OPTION_ADD_ACCOUNT_TITLE: 'Add via routing and account number',
  OPTION_ADD_ACCOUNT_DESCRIPTION:
    'Add this account with your routing and account number. You can return to submit your CD renewal instructions once your account is verified.',
  OPTION_ADD_ACCOUNT_CTA_TEXT: 'Enter bank information',
  OPTION_LINK_ACCOUNT_TITLE: 'Go back and link a different account',
  OPTION_LINK_ACCOUNT_DESCRIPTION_ADD_FUNDS:
    'Instantly sign in and verify a different external account to add money from.',
  OPTION_LINK_ACCOUNT_DESCRIPTION_WITHDRAW_FUNDS:
    'Instantly sign in and verify a different external account to add money to.',
  OPTION_LINK_ACCOUNT_CTA_TEXT: 'Link a different account',
});

//-----------------------------------------------------------------------------
// ANALYTICS CONSTANTS
//-----------------------------------------------------------------------------

export const AnalyticsPageInfo = Object.freeze({
  ACTIVITY: 'activity',
  ADD_FUNDS: 'add_funds',
  CONFIRMATION: 'confirmation',
  CD_RENEWAL: 'cd_renewal',
  DEFAULT_ACCOUNT_TYPE: 'generic',
  DEFAULT_PAGE_FUNCTION: 'cdrenewal',
  DEFAULT_PAGE_KIND: 'cdrenewal',
  ENTER_AMOUNT_EXTERNAL: 'enter_amount_et_exist',
  ENTER_AMOUNT_SUBMIT_ERROR: 'enter_amount_submit_error',
  ENTER_AMOUNT_YODLEE: 'enter_amount_et_yodlee',
  ENTER_AMOUNT_INTERNAL: 'enter_amount_it',
  FUNDS_OPTIONS: 'funds_options',
  NEW_TERM: 'new_term',
  RENEWAL_INFORMATION: 'view_submitted',
  SAME_TERM: 'same_term',
  SELECT_ACCOUNT: 'select_account',
  START_ERROR: 'start_error',
  START: 'start',
  TERMS_OPTIONS: 'terms_options',
  VERIFY: 'verify',
  WITHDRAW_FUNDS: 'withdraw_funds',
  YODLEE: 'yodlee',
  YODLEE_ERROR_OPTIONS: 'yodlee_error_options',
});

export const ContractsConstants = Object.freeze({
  ADJUSTMENT_TYPE_VALUE: 'No Changes to Funds',
  ADD_FUNDS_OR_WITHDRAW_FUNDS: 'Add Funds|Withdraw Funds',
  EXTERNAL_OR_INTERNAL: 'External|Internal',
  ACCOUNT_ID: '29fj9jf-9489cn-29389dnbb-9382',
  WITHDRAW_FUNDS: 'Withdraw Funds',
});
