import * as React from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import './timezone';
import './hash-link-fix';
import App from './app';
import configureStore from './store/configureStore';
import { setStore } from './store';
import bootstrap from './bootstrap.actions';
import initMoxie from './vendor/moxie/config';
import initDynamicYield from './vendor/dynamicYield/config';

const history = createBrowserHistory({
  basename: '/',
});
const store = configureStore(history);
setStore(store);

if (process.env.NODE_ENV === 'development') {
  // Assign global 'app' object for local debugging
  window.app = Object.assign(Object.create(null), { history, store });
}

const root = document.getElementById('root');

if (!root) {
  throw new Error('no root element');
}

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable global-require */
  if (window === window.top) {
    require('../public/css/site.css');
    require('./index.css');
    require('./vendor/iovation/config');
    if (window.__config__.QUALTRICS_ID) {
      require('./vendor/qualtrics/config');
    }
    require('../public/js/bing.min');
    require('../public/js/google.min');
    require('../public/js/mittera.min');
    require('../public/js/reddit.min');
    require('../public/js/nextdoor.min');
    require('../public/js/tealium');
    require('../public/js/dy');
    require('../public/js/cdp');
    if (window.__config__.SURFLY_ENABLED === 'true') {
      require('../public/js/surfly');
    }
    // move bootstrap to right before the app loads, to help with initial loading times
    store.dispatch(bootstrap());
  }
  ReactDOM.render(<App history={history} store={store} />, root, () => {
    if (window === window.top) {
      if (window.__config__.IVA_CHAT_ENABLED === 'false') initMoxie(history, store);
      initDynamicYield(history);
    }
    /* eslint-enable global-require */
  });
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept('./app', async () => {
    // eslint-disable-next-line global-require
    const { default: NextApp } = await import('./app');
    ReactDOM.render(<NextApp history={history} store={store} />, root);
  });
}
