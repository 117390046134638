import { reducer as form } from 'redux-form';
import type { AccountActivityState } from '../containers/accountActivity/accountActivity.reducer';
import accountActivity from '../containers/accountActivity/accountActivity.reducer';
import type { AccountDashboardState } from '../containers/accountDashboard/accountDashboard.reducer';
import accounts from '../containers/accountDashboard/accountDashboard.reducer';
import type { CDRenewalState } from '../containers/cdRenewal/cdRenewal.reducer';
import cdRenewal from '../containers/cdRenewal/cdRenewal.reducer';
import type { ProfileState } from '../containers/accountDashboard/profile.reducer';
import profile from '../containers/accountDashboard/profile.reducer';
import type { AddExternalAccountState } from '../containers/addExternalAccount/addExternalAccount.reducers';
import addExternalAccount from '../containers/addExternalAccount/addExternalAccount.reducers';
import type { AllAccountsState } from '../containers/allAccounts/allAccounts.reducer';
import allAccounts from '../containers/allAccounts/allAccounts.reducer';
import type { AuthenticateState } from '../containers/authenticate/authenticate.reducer';
import authenticate from '../containers/authenticate/authenticate.reducer';
import type { ExternalAccountState } from '../containers/externalAccountDashboard/externalAccountDashboard.reducer';
import externalAccounts from '../containers/externalAccountDashboard/externalAccountDashboard.reducer';
import type { FlashMessageState } from '../components/flashMessage/flashMessage.reducer';
import flashMessage from '../components/flashMessage/flashMessage.reducer';
import type { StatementsState } from '../containers/statements/statements.reducer';
import applications from '../containers/newAccountOpening/applications.reducer';
import transmit from '../containers/newAccountOpening/applicationsTransmit.reducer';
import type { ApplicationFormState } from '../containers/newAccountOpening/applicationForm.reducer';
import applicationForm from '../containers/newAccountOpening/applicationForm.reducer';
import type { OpenAccountState } from '../containers/newAccountOpening/newUser/openAccount.reducer';
import openAccount from '../containers/newAccountOpening/newUser/openAccount.reducer';
import type { ProductState } from '../containers/products/products.reducer';
import products from '../containers/products/products.reducer';
import type { TransfersState } from '../containers/transfers/transfers.reducer';
import transfers from '../containers/transfers/transfers.reducer';
import type { VerifyTrialDepositsState } from '../containers/verifyTrialDeposits/verifyTrialDeposits.reducer';
import verifyTrialDeposits from '../containers/verifyTrialDeposits/verifyTrialDeposits.reducer';
import type { ApplicationsState, TransmitState, RouterState } from '../utilities/types';
import type { OverdraftState } from '../containers/overdraftProtection/overdraftProtection.reducer';
import overdraft from '../containers/overdraftProtection/overdraftProtection.reducer';
import type { NewTransferState } from '../containers/transfers/newTransfer/newTransfer.reducer';
import type { EditTransferState } from '../containers/transfers/editTransfer/editTransfer.reducer';
import newTransfer from '../containers/transfers/newTransfer/newTransfer.reducer';
import editTransfer from '../containers/transfers/editTransfer/editTransfer.reducer';
import type { ZelleState } from '../containers/zelle/zelle.reducer';
import type { ListsState } from './lists.reducer';
import lists from './lists.reducer';
import type { EtinState } from '../containers/etin/etin.reducer';
import etin from '../containers/etin/etin.reducer';
import owcs from '../containers/owcs/owcs.reducer';
import type { OWCSState } from '../containers/owcs/owcs.type';
import combinedStatementPreference from '../containers/combinedStatements/combinedStatements.reducer';
import type { CombinedStatementsPrefenceState } from '../containers/combinedStatements/combinedStatements.reducer';

const reducers = {
  form,
  accounts,
  accountActivity,
  addExternalAccount,
  allAccounts,
  applications,
  transmit,
  applicationForm,
  authenticate,
  cdRenewal,
  combinedStatementPreference,
  etin,
  externalAccounts,
  flashMessage,
  lists,
  openAccount,
  overdraft,
  products,
  profile,
  transfers,
  verifyTrialDeposits,
  newTransfer,
  editTransfer,
  owcs,
} as const;

export type ReduxState = {
  accountActivity: AccountActivityState;
  accounts: AccountDashboardState;
  addExternalAccount: AddExternalAccountState;
  allAccounts: AllAccountsState;
  applicationForm: ApplicationFormState;
  applications: ApplicationsState;
  transmit: TransmitState;
  authenticate: AuthenticateState;
  cdRenewal: CDRenewalState;
  combinedStatementPreference: CombinedStatementsPrefenceState;
  etin: EtinState;
  externalAccounts: ExternalAccountState;
  flashMessage: FlashMessageState;
  lists: ListsState;
  newTransfer: NewTransferState;
  editTransfer: EditTransferState;
  openAccount: OpenAccountState;
  overdraft: OverdraftState;
  products: ProductState;
  profile: ProfileState;
  router: RouterState;
  statements: StatementsState;
  transfers: TransfersState;
  verifyTrialDeposits: VerifyTrialDepositsState;
  zelle: ZelleState;
  owcs: OWCSState;
};

export default reducers;
