import React from 'react';

type Props = {
  callback: () => void;
};

type State = {
  error?: Error;
};

/**
 * Component to perform async navigation actions and bubble up any errors that
 * occur to the ErrorBoundary component.
 */
class AsyncNavigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    try {
      this.props.callback();
    } catch (err) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(err);
    }
  }

  render() {
    if (this.state.error) {
      // bubble up to react boundary
      throw this.state.error;
    }

    return null;
  }
}

export default AsyncNavigation;
