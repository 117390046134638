import React from 'react';
import TextLink from 'bank-component-library/ui/atoms/TextLink';
import { PanelNavigationProps } from './ControlPanel.typings';

const PanelNavigation = ({ tabs, activePanel, setActivePanel }: PanelNavigationProps) => {
  return (
    <div className="ml-2">
      {tabs.map((tab, index, array) => {
        const isLastTab = index === array.length - 1;
        const isActivePanel = activePanel === tab.panel;
        return (
          <>
            <TextLink
              key={tab.displayName}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActivePanel(tab.panel);
              }}
              disabled={isActivePanel}
              className={isActivePanel && 'text-surface-inverse'}
            >
              {isActivePanel ? <mark>{tab.displayName}</mark> : tab.displayName}
            </TextLink>
            {!isLastTab && <span> | </span>}
          </>
        );
      })}
    </div>
  );
};

export default PanelNavigation;
