import React from 'react';
import type { AccountDetailsType } from 'Domain/Account/AccountDetails';
import type { Account } from '../../utilities/types';
import { StandardTextEmphasis } from '../../components/typography/typography';
import { buildAccountNameForAccount } from '../../formatters/buildAccountName';

export type BeneficiaryAddress = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  type: string;
};
export type BeneficiaryName = {
  first: string;
  middle: string;
  last: string;
};

export type BeneficiaryAccount = {
  accountId: string;
  accountType: AccountDetailsType;
  accountLastFour: string;
  accountDisplayType: string;
  accountNickname: string;
  relationshipType: string;
};

export type Beneficiary = {
  name: BeneficiaryName;
  beneficiaryId: string;
  dateOfBirth: string;
  addresses: BeneficiaryAddress[];
  accounts: BeneficiaryAccount[];
  editId?: string;
  removeId?: string;
};

export const BeneficiaryModalDOBHelperText = 'MM/DD/YYYY';

export const BeneficiariesPageTexts = Object.freeze({
  ACCOUNTS_COLUMN_HEADER: 'Account(s)',
  ACCOUNT_BENEFICIARY_UPDATE_ERROR:
    'An error occurred while updating your beneficiaries. Please try again.',
  ADD_TO_ACCOUNTS: 'Add to Accounts',
  BENEFICIARY_COLUMN_HEADER: 'Beneficiary',
  BENEFICIARY_INFO_ERROR: 'An error occurred while editing this beneficiary. Please try again.',
  CREATE_BENEFICIARY_ERROR: 'An error occurred while adding this beneficiary. Please try again.',
  UPDATE_BENEICIARY_ACCOUNTS_ERROR:
    'An error occurred while adding some accounts to this beneficiary. Please try again.',
  BENEFICIARY_ACCOUNTS_ERROR:
    'An error occurred while editing the accounts for this beneficiary. Please try again.',
  CUSTOM_BENEFICIARY_ERROR: (beneficiary: Beneficiary) => {
    const { first, middle, last } = beneficiary.name;
    const beneficiaryFullName = `${first} ${middle} ${last}`.replace(/\s+/g, ' ').toLowerCase();
    return (
      <>
        An error occurred while removing the beneficiary{' '}
        <StandardTextEmphasis textTransform="capitalize">
          {beneficiaryFullName}
        </StandardTextEmphasis>{' '}
        from one or more accounts. Please try again.
      </>
    );
  },
  MISSING_BENEFICIARY_ERROR: (accounts: Account[]) =>
    `You have not added beneficiaries to the following accounts: ${accounts
      .map((account) => buildAccountNameForAccount(account))
      .join(', ')}`,
  MORE_THAN_FOUR_BENEFICIARIES_ERROR:
    'You cannot add more than 4 beneficiaries to this account. Please unselect one or more beneficiaries.',
  DESCRIPTION:
    'Add information about beneficiaries and choose the accounts you wish to associate with them.',
  NO_BENEFICIARIES: 'You have not added any beneficiaries yet.',
  PLEASE_SELECT_ACCOUNT: 'Please select at least one account for this beneficiary.',
  SELECT_ACCOUNTS: 'Select the accounts you would like to be associated with this beneficiary:',
  TITLE: 'Manage Beneficiaries',
  IRA_DESCRIPTION:
    'If you’d like to add a beneficiary to an IRA account, you will need to fill out the IRA Beneficiary Change form. You can also call a Banker with any questions at 1-866-226-5638.',
  NO_ACTIVE_ACCOUNTS: 'You do not have any accounts that are eligible to add beneficiaries online.',
  MANAGE_BENEFICIARY: 'Manage Beneficiary',
  IRA_BENEFICIARY_CHANGE_FORM: 'Ira Beneficiary Change Form',
  SELECT_BENEFICIARIES_HELPER_TEXT:
    'Select the beneficiaries you would like to be associated with this account. You can select up to 4 beneficiaries to associate with this account.',
  REMOVE_ALL_BENEFICIARIES_WARNING:
    'By leaving the boxes below unchecked, you will remove all beneficiaries from this account.',
  REMOVE_THIS_BENEFICIARY_WARNING:
    'By leaving this box unchecked, you will remove this beneficiary from your account, and from your list of available beneficiaries.',
  NO_BENEFICIARIES_ADDED:
    'You have not added any beneficiaries yet. To add or manage your beneficiaries, you can go to your Account Settings > Beneficiaries.',
});

export const BeneficiaryFormLabels = Object.freeze({
  ALL_ACCOUNTS: 'All Accounts',
  UNIT_NUMBER: 'Unit # (Optional)',
  SAME_AS_PRIMARY_ADDRESS: 'Same Address as Primary Owner',
});

export const IRA_FORM_LINK = 'https://www.synchronybank.com/documents/wres006010.pdf';

export type PatchBeneficiary = {
  relationship: {
    beneficiaryId: string;
    accounts: {
      accountId: string;
      accountType: AccountDetailsType;
      action: string;
      status?: string;
    }[];
  };
};

export type CreateBeneficiary = {
  beneficiaryId: string;
};

export type UpdateBeneficiaryAddressRequest = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  type: string;
};

export type UpdateBeneficiaryRequest = {
  name: BeneficiaryName;
  dateOfBirth: string;
  addresses: UpdateBeneficiaryAddressRequest[];
};

export type PatchAccountBeneficiary = {
  relationships: {
    customerId: string;
    relationshipType: string;
    action: string;
    status?: string;
  }[];
};

export default BeneficiariesPageTexts;
