import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import type { ButtonHTMLAttributes, ReactNode } from 'react';
import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Colors from '../colors/colors';
import { LinkText } from '../typography/typography';

interface LinkButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  classes?: ClassNameMap;
  children?: ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  inline?: boolean;
  dataTrackTitle?: string;
}

const IconLinkButtonStyles = {
  buttonContainer: {
    background: 'none',
    border: 'none',
    color: Colors.optimumBlue,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      color: Colors.hoverState,
    },
    '&:hover $text': {
      textDecoration: 'underline',
      color: 'inherit',
    },
    '& > *:first-child': {
      flexShrink: 0,
    },
  },
  buttonContainerInline: {
    display: 'inline',
    '& > $text': {
      textTransform: 'lowercase',
    },
  },
  text: {
    // generate class for hover styles
  },
} as const;

const IconLinkButtonWrapper = ({
  classes = {},
  children,
  className,
  disabled,
  inline,
  icon,
  dataTrackTitle,
  ...rest
}: LinkButtonProps) => {
  const inlineContainerClass = inline ? classes.buttonContainerInline : '';
  const inlineTextClass = inline ? classes.inlineText : '';
  return (
    <button
      className={classnames(classes.buttonContainer, inlineContainerClass, className)}
      data-track-title={dataTrackTitle}
      type="button"
      {...rest}
    >
      {icon || null}
      <LinkText className={classnames(classes.text, inlineTextClass)}>{children}</LinkText>
    </button>
  );
};

const iconLinkButton = withStyles(IconLinkButtonStyles)(IconLinkButtonWrapper);
iconLinkButton.displayName = 'IconLinkButton';

export default iconLinkButton;
