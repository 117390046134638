export const Z_INDEX_CD_TOOLBAR = 9;
export const Z_INDEX_LEFT_COLUMN = 1;
export const Z_INDEX_INPUT_LABEL = 5;
export const Z_INDEX_LOADING_OVERLAY = 10;
export const Z_INDEX_FOOTER = 15;
export const Z_INDEX_HEADER_BASE = 20;
export const Z_INDEX_HEADER_BG_IMAGE = 50;
export const Z_INDEX_HEADER_TITLE = 80;
export const Z_INDEX_HEADER_SUBNAV = 81;
export const Z_INDEX_HEADER_COLLAPSE = 90;
export const Z_INDEX_HEADER_INTERACTABLE = 100;
export const Z_INDEX_FORM_IMAGE = 200;
export const Z_INDEX_SKIP_LINK = 1101; // +1 from MUI header
