import { makeStyles } from '@material-ui/styles';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../../components/colors/colors';

export const useMessageContainerStyles = makeStyles(() => ({
  openAttachmentButton: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  responseMessageItemContainer: {
    width: '100%',
    height: pxToRem(297),
    overflowY: 'auto',
    border: `solid ${pxToRem(1)} ${Colors.charcoalGrey}`,
    margin: `${pxToRem(24)} ${pxToRem(8)} ${pxToRem(24)} ${pxToRem(10)}`,
  },
  responseMessageTextItem: {
    paddingLeft: pxToRem(24),
    paddingRight: pxToRem(48),
    paddingTop: pxToRem(24),
  },

  responseAttachmentItemContainer: {
    '& :nth-child(odd)': {
      marginLeft: -1,
    },
  },

  responseAttachmentItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'start',
  },

  responseViewAttachmentError: {
    fontSize: pxToRem(12),
    color: Colors.inlineError,
    marginLeft: pxToRem(32),
    marginTop: -8,
  },
}));

export default useMessageContainerStyles;
