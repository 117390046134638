import React from 'react';
import Loadable from 'react-loadable';
import type { OptionsWithoutRender } from 'react-loadable';

import FormWithImage from './formWithImage';

type Options = OptionsWithoutRender<any> & {
  imageUrl: string;
  showMarketingMessage?: boolean;
};

// Create new FormWithImage loading component.
// Displays placeholder component until promise is resolved.
const FormWithImageLoading = (opts: Options) => {
  return Loadable<any, any>({
    loader: opts.loader,
    loading: (props: any) => {
      if (props && props.error) {
        // bubble up to error boundary if module fails to load
        throw new Error(props.error);
      }
      return (
        <FormWithImage
          loading
          imageUrl={opts.imageUrl}
          showMarketingMessage={opts.showMarketingMessage}
        />
      );
    },
  });
};

export default FormWithImageLoading;
