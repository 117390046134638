import React, { Ref, forwardRef } from 'react';
import { type IconButtonProps, IconButton as MuiIconButton } from '@material-ui/core';

const IconButton = forwardRef((props: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
  const { children, ...otherProps } = props;
  return (
    <MuiIconButton {...otherProps} disableRipple ref={ref}>
      {children}
    </MuiIconButton>
  );
});

export default IconButton;
