import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, withStyles } from '@material-ui/core';
import { kebabCase } from 'lodash';
import type { AllAccountTypeName } from 'Domain/Account/AllAccount';
import { formatAccountNumberLastFour } from '../../formatters/buildAccountName';
import { clickTrack } from '../../analytics/clickTracking.constants';
import type { ReduxState } from '../../reducers/index';
import ButtonLink from '../links/buttonLink';
import {
  StandardText,
  InformationalSectionHeaderBold,
  StandardTextEmphasis,
} from '../typography/typography';
import pxToRem from '../../utilities/pxToRem';
import type { Dispatch, StyleProps } from '../../utilities/types';
import FormWithImage from '../formWithImage/formWithImage';
import { DepositInstructionsText } from '../../containers/newAccountOpening/newAccountOpening.constants';
import Routes from '../../containers/routes/routes.constants';
import { ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS } from '../../analytics/actions';
import Colors from '../colors/colors';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';

const styles = {
  textSection: { marginBottom: pxToRem(30) },
  overrideCtaContainer: { marginTop: 0, marginBottom: pxToRem(10) },
  buttonContainer: { display: 'flex' },
  horizontalLine: {
    marginTop: pxToRem(40),
    marginBottom: pxToRem(40),
    height: '0.5px',
    opacity: 0.3,
    border: `solid 0.5px ${Colors.lineBreak}`,
    width: '100%',
  },
};

type OwnProps = {
  isFunding?: boolean;
};

type StateProps = {
  accountType?: string;
  bankName?: string;
  accountNumber?: string;
  newAccountType?: AllAccountTypeName;
};

type DispatchProps = {
  loadAnalyticsTrialDepositInstructions: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & StyleProps;

const mapStateToProps = (state: ReduxState) => ({
  accountType: state.addExternalAccount.accountType,
  accountNumber: state.addExternalAccount.accountNumber,
  bankName: state.addExternalAccount.bankName,
  newAccountType: state.applications.newAccount.accountType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadAnalyticsTrialDepositInstructions: () =>
    dispatch({ type: ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS }),
});

export const TrialDepositInstructions = (props: Props) => {
  const {
    classes,
    bankName = '',
    newAccountType = '',
    accountType = '',
    accountNumber = '',
    isFunding,
    loadAnalyticsTrialDepositInstructions,
  } = props;
  useEffect(() => {
    loadAnalyticsTrialDepositInstructions();
  });

  // Fetching Images from Control Center's S3 Bucket
  const fundingImageUrl = getImageSrc(ImagesFileNames.accountOpeningJpg);
  const fundingImageUrlLg = getImageSrc(ImagesFileNames.accountOpening_2xJpg);
  const fundingImageUrlXl = getImageSrc(ImagesFileNames.accountOpening_3xJpg);
  const servicingImageUrl = getImageSrc(ImagesFileNames.addExternalAccountsJpg);
  const servicingImageUrlLg = getImageSrc(ImagesFileNames.addExternalAccounts_2xJpg);
  const servicingImageUrlXl = getImageSrc(ImagesFileNames.addExternalAccounts_3xJpg);

  // don't allow direct hits to this page w/o an account number
  if (!accountNumber || !accountType) {
    return <Redirect to={Routes.DASHBOARD} />;
  }
  const CTA_TO = isFunding ? DepositInstructionsText.AFF_CTA_URL : DepositInstructionsText.CTA_URL;
  const CTA_TEXT = isFunding
    ? DepositInstructionsText.AFF_CTA_BTN_TEXT
    : DepositInstructionsText.CTA_BTN_TEXT;
  const IMG_URL = isFunding ? fundingImageUrl : servicingImageUrl;
  const IMG_URL_LG = isFunding ? fundingImageUrlLg : servicingImageUrlLg;
  const IMG_URL_XL = isFunding ? fundingImageUrlXl : servicingImageUrlXl;
  const DATA_TRACK_TITLE = isFunding
    ? clickTrack.depositInstructions.view_dashboard
    : clickTrack.transfers.manage_external_accounts;
  const renderHeaderCopy = () => {
    if (isFunding) {
      return (
        <>
          <StandardText>
            You will be able to fund your {newAccountType} account via transfer once you verify
            trial deposits with your{' '}
          </StandardText>
          <StandardTextEmphasis>
            {bankName} {accountType} {formatAccountNumberLastFour(accountNumber)}.
          </StandardTextEmphasis>
        </>
      );
    }
    return (
      <StandardText>
        You will be able to use your{' '}
        <StandardTextEmphasis>
          {bankName} {accountType} {formatAccountNumberLastFour(accountNumber)}
        </StandardTextEmphasis>{' '}
        account once you verify trial deposits.
      </StandardText>
    );
  };

  return (
    <Grid container className={classes.parentContainer} spacing={0} alignItems="stretch">
      <FormWithImage
        imageUrl={IMG_URL}
        imageUrlLg={IMG_URL_LG}
        imageUrlXl={IMG_URL_XL}
        classes={{ ctaContainer: classes.overrideCtaContainer }}
        ctaText={DepositInstructionsText.CTA_HEADER}
        showMarketingMessage={isFunding}
        ctaDescription={renderHeaderCopy()}
        useLoadingAnimation={false}
      >
        <Grid item className={classes.textSection}>
          <InformationalSectionHeaderBold data-test="connection-initiated">
            {DepositInstructionsText.CTA_COPY}
          </InformationalSectionHeaderBold>
        </Grid>
        {DepositInstructionsText.CTA_COPY_LIST.map((text, index) => (
          <Grid key={text} item className={classes.textSection}>
            <StandardText data-test={`connection-initiated-step-${index}`}>{text}</StandardText>
          </Grid>
        ))}
        <hr className={classes.horizontalLine} aria-hidden="true" />

        <Grid item className={classes.buttonContainer}>
          <ButtonLink
            to={CTA_TO}
            data-test={`${kebabCase(CTA_TEXT)}-btn`}
            data-track-title={DATA_TRACK_TITLE}
          >
            {CTA_TEXT}
          </ButtonLink>
        </Grid>
      </FormWithImage>
    </Grid>
  );
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(TrialDepositInstructions);
const styledComponent = withStyles(styles)(connectedComponent);
export default styledComponent;
