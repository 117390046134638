import type { ReduxState } from '../../../reducers';
import type { AccountFundingFlow } from '../../../utilities/accountFundingFlowType';
import type { AccountActivityAction } from '../../../containers/accountActivity/accountActivity.reducer';
import pageAnalytics from '../../pageAnalytics';
import { getExternalFundingType, getPageSubfunction } from '../fundNewAccount.analytics';
import pageTrack from '../../pageAnalytics.constants';

export const viewFundAmount =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    if (getExternalFundingType(state) === pageTrack.attr.yodlee) {
      pageAnalytics
        .newPageView({
          account_type: pageTrack.account_type.generic,
          pagekind: pageTrack.pagekind.account_opening,
          pagefunction: pageTrack.pagefunction.add_external_bank,
          pagesubfunction: getPageSubfunction(fundingFlow),
          pagename: pageTrack.pagename.complete,
          attr: getExternalFundingType(state),
        })
        .record();
    }
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.funding,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.enter_amount,
        attr: getExternalFundingType(state),
      })
      .record();
  };

export default viewFundAmount;
