/* eslint-disable import/prefer-default-export */

import { FORM_ERROR } from 'final-form';
import type { MessageForm } from '../../utilities/types';

export const attachmentFilesValidation = (values: MessageForm) => {
  if (values && values.attachmentFiles) {
    if (
      values.attachmentFiles.size === 0 ||
      (values.attachments && values.attachmentFiles.size === values.attachments.length)
    ) {
      return undefined;
    }
    return { [FORM_ERROR]: '' };
  }
  return undefined;
};
