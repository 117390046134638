import pageAnalytics from '../pageAnalytics';
import { ANALYTICS_WELCOME_PAGE, ANALYTICS_WELCOME_PAGE_MAINTENANCE } from '../actions';

const viewPage = () => {
  pageAnalytics
    .addInfo({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: 'welcome',
      pagesubfunction: '',
      pagename: 'landing_page',
      attr: '',
    })
    .record();
};

const viewMaintenancePage = () => {
  // Need a full new page view here since viewPage() will have already recorded
  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: 'welcome',
      pagesubfunction: '',
      pagename: 'landing_page_system_maintenance',
      attr: '',
    })
    .record();
};

export const welcomePageEvents = {
  [ANALYTICS_WELCOME_PAGE]: viewPage,
  [ANALYTICS_WELCOME_PAGE_MAINTENANCE]: viewMaintenancePage,
} as const;

export default welcomePageEvents;
