import type { List } from '../../../reducers/lists.reducer';

export const ACTION_CITIZENSHIP_LIST_SUCCESS = 'ACTION_CITIZENSHIP_LIST_SUCCESS';
// Deprecated. Remove in favor of ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS in reducers/lists.reducer.js
// after NAO can handle the new data
export const ACTION_OCCUPATION_LIST_SUCCESS = 'ACTION_OCCUPATION_LIST_SUCCESS';
export const ACTION_SOURCE_OF_FUNDS_LIST_SUCCESS = 'ACTION_SOURCE_OF_FUNDS_LIST_SUCCESS';
// Deprecated. Remove in favor of ACTION_UTILITIES_STATE_LIST_SUCCESS in reducers/lists.reducer.js
// after NAO can handle the new data
export const ACTION_STATE_LIST_SUCCESS = 'ACTION_STATE_LIST_SUCCESS';
export const ACTION_SECURITY_QUESTIONS_LIST_SUCCESS = 'ACTION_SECURITY_QUESTIONS_LIST_SUCCESS';
export const ACTION_CUSTOMER_EXISTS = 'ACTION_CUSTOMER_EXISTS';
export const ACTION_CUSTOMER_ERROR = 'ACTION_CUSTOMER_ERROR';

export type OpenAccountState = {
  occupations: List;
  sourceOfFunds: List;
  countries: List;
  states: List;
  securityQuestions: List;
};

function getInitialState(): OpenAccountState {
  return {
    countries: [],
    occupations: [],
    sourceOfFunds: [],
    states: [],
    securityQuestions: [],
  };
}

export type OpenAccountAction =
  | {
      type: typeof ACTION_CITIZENSHIP_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_OCCUPATION_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_SOURCE_OF_FUNDS_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_STATE_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_SECURITY_QUESTIONS_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_CUSTOMER_EXISTS;
      payload?: number;
    }
  | {
      type: typeof ACTION_CUSTOMER_ERROR;
      payload?: number;
    };

export default (
  state: OpenAccountState | null | undefined = getInitialState(),
  action: OpenAccountAction = undefined
): OpenAccountState => {
  switch (action.type) {
    case ACTION_CITIZENSHIP_LIST_SUCCESS:
      return { ...state, countries: action.payload };
    case ACTION_OCCUPATION_LIST_SUCCESS:
      return { ...state, occupations: action.payload };
    case ACTION_SOURCE_OF_FUNDS_LIST_SUCCESS:
      return { ...state, sourceOfFunds: action.payload };
    case ACTION_STATE_LIST_SUCCESS:
      return { ...state, states: action.payload };
    case ACTION_SECURITY_QUESTIONS_LIST_SUCCESS:
      return { ...state, securityQuestions: action.payload };
    // These actions don't do anything, we just listen to them for page analytics
    case ACTION_CUSTOMER_EXISTS:
    case ACTION_CUSTOMER_ERROR:
    default:
      return state;
  }
};
