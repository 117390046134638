import { withStyles, Hidden } from '@material-ui/core';
import React from 'react';
import type { StyleProps } from '../../utilities/types';
import styles from '../../containers/verifyTrialDeposits/verifyTrialDeposits.container.styles';
import { SubtitleText, SubtitleEmphasis } from '../typography/typography';
import { AND_MORE_TEXT } from '../openNewAccountFormFlow/chooseFunding.constants';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const BankList = ({ classes }: StyleProps) => {
  return (
    <>
      <div className={classes.bankIconsContainer} data-test="yodlee-bank-list">
        <SVGImage
          imageName={ImagesFileNames.chasebankSvg}
          className={classes.bankIcon}
          role="img"
        />
        <SVGImage
          imageName={ImagesFileNames.wellsfargoSvg}
          className={classes.bankIcon}
          role="img"
        />
        <SVGImage
          imageName={ImagesFileNames.bankofamericaSvg}
          className={classes.bankIcon}
          role="img"
        />
        <SVGImage
          imageName={ImagesFileNames.capitaloneSvg}
          className={classes.bankIcon}
          role="img"
        />
        <Hidden xsDown>
          <SVGImage imageName={ImagesFileNames.pncSvg} className={classes.bankIcon} role="img" />
          <SVGImage
            imageName={ImagesFileNames.usbankSvg}
            className={classes.usBankIcon}
            role="img"
          />
          <Hidden smDown>
            <SVGImage imageName={ImagesFileNames.tdSvg} className={classes.bankIcon} role="img" />
            <Hidden mdDown>
              <SVGImage
                imageName={ImagesFileNames.usaaSvg}
                className={classes.bankIcon}
                role="img"
              />
              <SVGImage
                imageName={ImagesFileNames.citiSvg}
                className={classes.bankIcon}
                role="img"
              />
              <SVGImage
                imageName={ImagesFileNames.allySvg}
                className={classes.bankIcon}
                role="img"
              />
            </Hidden>
          </Hidden>
        </Hidden>
        <p className={classes.moreText}>
          <SubtitleText>{AND_MORE_TEXT}</SubtitleText>
        </p>
      </div>
      <SubtitleText component="div">
        Use our <SubtitleEmphasis> safe and secure </SubtitleEmphasis>
        method of connecting your external account with Synchrony. Search for your bank and sign in
        to your account to instantly verify your accounts.
        <SubtitleEmphasis> Your username and password are never stored </SubtitleEmphasis> and your
        information is 100% safe.
      </SubtitleText>
    </>
  );
};

export default withStyles(styles)(BankList);
