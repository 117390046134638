import moment from 'moment-timezone/moment-timezone';

// Copied from https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
import timezoneData from './timezone-data.json';

moment.tz.load(timezoneData);
moment.tz.setDefault('America/New_York');

if (process.env.NODE_ENV === 'development') {
  // Warn about timezone data expiring. Need to always have timezone data
  // ranging from [now, now + 1 year] for transfer scheduling.

  // expiration defined by JSON data
  const expirationYear = 2027;

  // warn in advance of data expiration
  const YEAR_MS = 1000 * 60 * 60 * 24 * 365;
  const NOTICE_ADVANCE = YEAR_MS * 1.5;
  const warningDateBeforeExpiring = new Date(
    new Date(`${expirationYear}-01-01`).getTime() - NOTICE_ADVANCE
  );
  const expiringSoon = new Date() > warningDateBeforeExpiring;

  if (expiringSoon) {
    const url = 'https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json';
    // eslint-disable-next-line no-alert
    alert(
      `timezone.js: moment-timezone data is close to expiring. Update to latest data from ${url}`
    );
  }
}
