import { sendChooseRequest } from '../../services/dynamicYield.service.ts';

const initDynamicYield = (history) => {
  sendChooseRequest(false);
  history.listen(() => {
    sendChooseRequest(false);
  });
};

export default initDynamicYield;
