const MoreAboutYouFormLabels = Object.freeze({
  CITIZENSHIP: 'Country of Citizenship',
  OCCUPATION: 'Occupation',
  EMPLOYER_NAME: 'Employer Name',
  SECURITY_QUESTION: 'Security Question',
  SECURITY_ANSWER: 'Security Answer',
});

export const MoreAboutYouInlineErrors = Object.freeze({
  EMPTY_OCCUPATION: `Please select ${MoreAboutYouFormLabels.OCCUPATION}.`,
  EMPTY_EMPLOYER_NAME: `Please enter ${MoreAboutYouFormLabels.EMPLOYER_NAME}.`,
  EMPTY_SECURITY_QUESTION: `Please select ${MoreAboutYouFormLabels.SECURITY_QUESTION}.`,
  EMPTY_SECURITY_ANSWER: `Please enter ${MoreAboutYouFormLabels.SECURITY_ANSWER}.`,
  TOO_SHORT_SECURITY_ANSWER: 'Please enter an Answer using at least 3 characters.',
  TOO_SHORT_EMPLOYER_NAME: `Please enter ${MoreAboutYouFormLabels.EMPLOYER_NAME} using at least 2 characters.`,
});

export const MoreAboutYouHelperText = Object.freeze({
  CITIZENSHIP:
    'Applicant must be a U.S. citizen or U.S. permanent resident alien (green card holder).',
  SECURITY_QUESTION:
    'For your protection, this information will only be used to verify your identity if you need to contact us via phone.',
});

export default MoreAboutYouFormLabels;
