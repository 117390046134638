import {
  buildOauthTokenUrl,
  buildCallbackUrl,
  setUpdatedAccessToken,
  getAccessToken,
  AUTH_STATE_TOKEN_STORAGE_KEY,
  setUpdatedModifyProfileToken,
} from '../../utilities/authentication';
import { getSessionID } from '../../utilities/sessionID';
import axios from '../../utilities/axios';
import { getBlackBox } from './blackBox.service';

export type TokenResponse = {
  access_token: string;
  refreshToken: string;
  analytics_id?: string;
};

type LimitedTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
  score: string;
  jti: string;
};

export const exchangeAuthCodeService = (authCode: string): Promise<TokenResponse> => {
  const data = new FormData();
  data.append('client_id', window.__config__.CLIENT_ID);
  data.append('grant_type', 'authorization_code');
  data.append('redirect_uri', buildCallbackUrl());
  data.append('code', authCode);

  let telemetryHeaders;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    telemetryHeaders = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }

  return axios
    .post(buildOauthTokenUrl(), data, {
      headers: { 'Content-Type': 'multipart/form-data', ...telemetryHeaders },
    })
    .then((res) => res.data);
};

let retryAttempt = 0;
export const fetchLimitedScopeToken = async (shouldRetryFetch = false): Promise<void> => {
  const blackBox = getBlackBox();
  const data = new FormData();

  data.append('client_id', window.__config__.CLIENT_ID);
  data.append('grant_type', 'urn://synchronybank.com/device-check');
  data.append('blackbox', blackBox);
  data.append('uuid', getSessionID());

  let telemetryHeaders;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    telemetryHeaders = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }
  const fetchToken = async () => {
    try {
      const response: {
        data: LimitedTokenResponse;
      } = await axios.post(buildOauthTokenUrl(), data, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...telemetryHeaders },
      });
      if (response && response.data && response.data.access_token) {
        setUpdatedAccessToken(response.data.access_token);
        retryAttempt = 0;
      }
    } catch (e) {
      if (shouldRetryFetch && retryAttempt < 1) {
        retryAttempt += 1;
        await fetchToken();
      }
    }
  };
  await fetchToken();
};

const fetchTokenWithAuthState = async (): Promise<LimitedTokenResponse> => {
  const blackBox = getBlackBox();
  const data = new FormData();

  data.append('client_id', window.__config__.CLIENT_ID);
  data.append('grant_type', 'urn://synchronybank.com/state-token');
  data.append('blackbox', blackBox);
  data.append('state_token', getAccessToken(AUTH_STATE_TOKEN_STORAGE_KEY));

  let telemetryHeaders;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    telemetryHeaders = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }

  const response: {
    data: LimitedTokenResponse;
  } = await axios.post(buildOauthTokenUrl(), data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...telemetryHeaders },
  });
  return response.data;
};

export const fetchNewAuthTokenService = async (): Promise<void> => {
  const response = await fetchTokenWithAuthState();
  if (response && response.access_token) {
    setUpdatedAccessToken(response.access_token);
  }
};

export const fetchModifyProfileTokenService = async (): Promise<void> => {
  const response = await fetchTokenWithAuthState();
  if (response && response.access_token) {
    setUpdatedModifyProfileToken(response.access_token);
  }
};
