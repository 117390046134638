import { makeStyles } from '@material-ui/core/styles';
import pxToRem from '../../utilities/pxToRem';
import breakpoint from '../breakpoints';

export const largePx = 48;
export const mediumPx = 32;
export const smallPx = 30;
export const defaultPx = 20;
export const largePadding = pxToRem(largePx);
export const mediumPadding = pxToRem(mediumPx);
export const smallPadding = pxToRem(smallPx);
export const defaultPadding = pxToRem(defaultPx);

export const Alignment = {
  horizontalPadding: {
    paddingLeft: defaultPadding,
    paddingRight: defaultPadding,
    [breakpoint.up('sm')]: {
      paddingLeft: smallPadding,
      paddingRight: smallPadding,
    },
    [breakpoint.up('md')]: {
      paddingLeft: mediumPadding,
      paddingRight: mediumPadding,
    },
    [breakpoint.up('lg')]: {
      paddingLeft: largePadding,
      paddingRight: largePadding,
    },
  },
  verticalPadding: {
    paddingTop: defaultPadding,
    paddingBottom: defaultPadding,
    [breakpoint.up('sm')]: {
      paddingTop: smallPadding,
      paddingBottom: smallPadding,
    },
    [breakpoint.up('md')]: {
      paddingTop: mediumPadding,
      paddingBottom: mediumPadding,
    },
    [breakpoint.up('lg')]: {
      paddingTop: largePadding,
      paddingBottom: largePadding,
    },
  },
  allPadding: {
    padding: defaultPadding,
    [breakpoint.up('sm')]: {
      padding: smallPadding,
    },
    [breakpoint.up('md')]: {
      padding: mediumPadding,
    },
    [breakpoint.up('lg')]: {
      padding: largePadding,
    },
  },
  dashboardHeaderPadding: {
    paddingTop: pxToRem(24),
    paddingBottom: pxToRem(16),
    paddingLeft: pxToRem(48),
    paddingRight: pxToRem(25),
  },
  headerPadding: {
    paddingLeft: defaultPadding,
    paddingRight: defaultPadding,
    paddingTop: defaultPadding,
    [breakpoint.up('sm')]: {
      paddingLeft: smallPadding,
      paddingRight: smallPadding,
      paddingTop: smallPadding,
    },
    [breakpoint.up('md')]: {
      paddingLeft: mediumPadding,
      paddingRight: mediumPadding,
      paddingTop: mediumPadding,
    },
    [breakpoint.up('lg')]: {
      paddingLeft: largePadding,
      paddingRight: largePadding,
      paddingTop: largePadding,
    },
  },
  footerPadding: {
    paddingLeft: defaultPadding,
    paddingRight: defaultPadding,
    paddingBottom: defaultPadding,
    [breakpoint.up('sm')]: {
      paddingLeft: smallPadding,
      paddingRight: smallPadding,
      paddingBottom: smallPadding,
    },
    [breakpoint.up('md')]: {
      paddingLeft: mediumPadding,
      paddingRight: mediumPadding,
      paddingBottom: mediumPadding,
    },
    [breakpoint.up('lg')]: {
      paddingLeft: largePadding,
      paddingRight: largePadding,
      paddingBottom: largePadding,
    },
  },
  pageContentPadding: {
    [breakpoint.down('md')]: {
      paddingTop: pxToRem(30),
    },
  },
} as const;

export const useAlignmentStyles = makeStyles(Alignment);

export default () => Alignment;
