import { Grid, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import type { RateMatrix, Term } from '../../containers/products/products.reducer';
import breakpoints from '../../styles/breakpoints';
import pxToRem from '../../utilities/pxToRem';
import { StandardText } from '../typography/typography';
import percentage from '../../formatters/percentage';
import currency from '../../formatters/currency';

type Props = {
  headerTitle?: string;
  term: Term;
  classes: any;
  highlightColor?: string;
  apyAsterisks: number;
  dataTest?: string;
  apyAsterisksDescribedBy?: string;
  isAmountWrap?: boolean;
  isStyleChanged?: boolean;
};

const styles = {
  termContainer: {
    paddingLeft: pxToRem(16.5),
    wordBreak: 'break-word',
    '& td, th': {
      display: 'block',
    },
  },
  termContainerNoPadding: {
    wordBreak: 'break-word',
    '& td, th': {
      display: 'block',
    },
  },
  termContainerWordWrap: {
    paddingLeft: pxToRem(16.5),
    wordBreak: 'break-word',
    '& td, th': {
      display: 'block',
    },
  },
  headerCell: {
    paddingRight: pxToRem(8),
    lineHeight: '1rem',
  },
  headerLine: {
    marginBottom: pxToRem(8),
  },
  amountRange: {
    display: 'flex',
    flexDirection: 'column',
  },
  amount: {
    minWidth: '100%',
  },
  headerCellTitle: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(16),
    },
  },
  tableHeader: {
    width: '100%',
    display: 'flex',
  },
} as const;

export class RateTable extends Component<Props> {
  renderDepositAmount = (rateMatrix: RateMatrix, biggestMin: number) => {
    if (rateMatrix.minBalance === biggestMin) {
      return (
        <Grid item xs={4} className={this.props.classes.amount}>
          <StandardText>{currency(rateMatrix.minBalance, true)} +</StandardText>
        </Grid>
      );
    }
    return (
      <Grid item xs={this.props.isAmountWrap ? 12 : 8}>
        <StandardText>
          {`${currency(rateMatrix.minBalance, true)} - ${currency(rateMatrix.maxBalance, true)}`}
        </StandardText>
      </Grid>
    );
  };

  renderTerm = (term: Term) => {
    const biggestMinDeposit = term.rateMatrices
      .map((matrix) => matrix.minBalance)
      .sort((a, b) => a - b)
      .pop();
    return (
      <>
        {term.rateMatrices.map((rateMatrix) => (
          <Grid item container key={rateMatrix.minBalance} component="tr" role="row">
            <Grid
              item
              xs={this.props.isAmountWrap ? 6 : 5}
              className={this.props.classes.amountRange}
              component="td"
              role="cell"
            >
              {this.renderDepositAmount(rateMatrix, biggestMinDeposit)}
            </Grid>
            <Grid item xs={3} component="td" role="cell">
              <StandardText>{percentage(rateMatrix.apy)}</StandardText>
            </Grid>
            <Grid item xs={this.props.isAmountWrap ? 3 : 4} component="td" role="cell">
              <StandardText>{percentage(rateMatrix.rate)}</StandardText>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  render() {
    const {
      headerTitle = 'Balance',
      term,
      highlightColor,
      classes,
      apyAsterisks,
      dataTest,
      apyAsterisksDescribedBy,
      isAmountWrap,
      isStyleChanged,
    } = this.props;

    return (
      <Grid
        container
        className={isStyleChanged ? classes.termContainerNoPadding : classes.termContainer}
        style={{
          borderLeft: highlightColor ? `solid ${pxToRem(4)}` : 'none',
          borderColor: highlightColor,
        }}
        component="table"
        role="table"
        data-test={dataTest}
      >
        <thead className={classes.tableHeader}>
          <Grid item container className={classes.headerLine} component="tr" role="row">
            <Grid
              item
              xs={isAmountWrap ? 6 : 5}
              className={classes.headerCell}
              component="th"
              role="columnheader"
            >
              <span className={classes.headerCellTitle}>{headerTitle}</span>
            </Grid>
            <Grid item xs={3} className={classes.headerCell} component="th" role="columnheader">
              <span
                className={classes.headerCellTitle}
                aria-describedby={apyAsterisksDescribedBy}
                aria-label="APY"
              >
                APY{'*'.repeat(apyAsterisks)}
              </span>
            </Grid>
            <Grid
              item
              xs={isAmountWrap ? 3 : 4}
              className={classes.headerCell}
              component="th"
              role="columnheader"
            >
              <span className={classes.headerCellTitle}>Interest Rate</span>
            </Grid>
          </Grid>
        </thead>
        <Grid item container spacing={2} component="tbody">
          {term && this.renderTerm(term)}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RateTable);
