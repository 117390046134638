export const pageTrack = Object.freeze({
  account_type: {
    generic: 'generic',
  },
  pagekind: {
    account_opening: 'account_opening',
    account_settings: 'account_settings',
    activity: 'activity',
    explore_products: 'explore_products',
    rate_and_terms: 'rates_terms',
    login_registration: 'login_registration',
    transfers: 'transfers',
    cd_interest_payments: 'cd_interest_payments',
    message_center: 'message_center',
  },
  pagefunction: {
    about_you: 'about_you',
    add_external_bank: 'add_external_bank',
    add_joint_owner: 'add_joint_owner',
    ao_otp: 'ao_otp',
    beneficiaries: 'beneficiaries',
    beneficiaries_account: 'beneficiaries_account',
    cancel_card: 'cancel_card',
    cd_bump: 'cd_bump',
    cd_renewal: 'cd_renewal',
    change_password: 'change_password',
    change_username: 'change_username',
    choose_product: 'choose_product',
    combined_statements: 'combined_statements',
    compose: 'compose',
    documents: 'documents',
    edit_transfer: 'edit_transfer',
    epreferences: 'ePreferences',
    funding: 'funding',
    inbox: 'inbox',
    joint_owner: 'joint_owner',
    joint_owner_new: 'joint_owner_new',
    marketing_alerts: 'marketing_alerts',
    opt_in_alerts: 'opt_in_alerts',
    registration: 'registration',
    review: 'review',
    rewards: 'rewards',
    savings_accounts: 'savings_accounts',
    select_account: 'select_account',
    security: 'security',
    statements: 'statements',
    stop_payments: 'stop_payments',
    transmit: 'transmit',
  },
  pagesubfunction: {
    EAO: 'eao',
    NAO: 'nao',
    OTP: 'otp',
    servicing: 'servicing',
    instant_verify: 'instant_verify',
    trial_deposits: 'trial_deposits',
    ADD: 'add',
    EDIT: 'edit',
    REMOVE: 'remove',
    new: 'new', // New Message
    reply: 'reply', // Reply Message
    INWARD: 'inward',
    OUTWARD: 'outward',
    INTERNAL: 'internal',
  },
  pagename: {
    account_info: 'account_info',
    amount_violation: 'amount_violation',
    application_failed_server_error: 'application_failed_server_error',
    attachment_server_error: 'attachment_server_error',
    complete: 'complete',
    complete_no_otp: 'complete_no_otp',
    confirm: 'confirm',
    confirmation_page: 'confirmation_page',
    connection_initiated: 'connection_initiated',
    denied: 'denied',
    document_server_error: 'document_server_error',
    eligibility_server_error: 'eligibility_server_error',
    enter_amount: 'enter_amount',
    error_cant_add: 'error_cant_add',
    review_amount: 'review_amount',
    error: 'error',
    interstitial: 'interstitial',
    landing_page: 'landing_page',
    more: 'more',
    more_server_error: 'more_server_error',
    otp_failed: 'otp_failed',
    otp_start: 'otp_start',
    otp_step2: 'otp_step2',
    otp_text_or_call: 'otp_text_or_call',
    pending: 'pending',
    ePreferences_saved: 'ePreferences_saved',
    ePreferences_not_saved: 'ePreferences_not_saved',
    server_error: 'server_error',
    start: 'start',
    start_error: 'start_error',
    start_existing: 'start_existing',
    start_contactus: 'start_contactus',
    start_no_phone: 'start_no_phone',
    start_no_otp: 'start_no_otp',
    start_intent: 'start_intent',
    start_server_error: 'start_server_error',
    start_submit_error: 'start_submit_error',
    step2: 'step2',
    step2_error: 'step2_error',
    step2_code_failed: 'step2_code_failed',
    step2_blocked: 'step2_blocked',
    step2_server_error: 'step2_server_error',
    step2_personal_details: 'step2_personal_details',
    step2_personal_details_server_error: 'step2_personal_details_server_error',
    step2_verify: 'step2_verify',
    step2_verify_submit_error: 'step2_verify_submit_error',
    submit_error: 'submit_error',
    submit_server_error: 'submit_server_error',
    view: 'view',
    view_error: 'view_error',
    view_opt_out: 'view_opt_out',
    view_already_opt_out: 'view_already_opt_out',
    view_server_error: 'view_server_error',
    personal_info: 'personal_info',
    unknown: 'unknown',
    start_unconfirmed_phone: 'start_unconfirmed_phone',
    start_max_unconfirmed_attempts: 'start_max_unconfirmed_attempts',
    terms: 'terms',
    ews_connection_failed: 'ews_connection_failed',
    yodlee_connection_failed: 'yodlee_connection_failed',
    mail_a_check: 'mail_a_check',
  },
  attr: {
    both: 'both', // marketing alerts
    check: 'check',
    edit: 'edit', // cd_interest_payments
    email: 'email', // marketing alerts
    etin: 'etin',
    ews: 'ews', // instant verify with account/routing
    exist: 'exist', // existing external
    internal: 'internal',
    mail: 'mail', // marketing alerts
    md: 'md', // micro deposits
    none: 'none', // marketing alerts
    no_etin: 'no_etin',
    basic: 'basic', // rewards
    blank: 'blank',
    setup: 'setup', // cd_interest_payments
    silver: 'silver',
    gold: 'gold',
    diamond: 'diamond',
    platinum: 'platinum',
    yodlee: 'yodlee',
    mixed: 'mixed',
    online_only: 'online_only',
    mail_only: 'mail_only',
    new_phone_number: 'new_phone_number',
    existing_phone_number: 'existing_phone_number',
  },
});

export default pageTrack;
