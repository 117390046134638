import type { CDRenewalStepsType } from '../containers/cdRenewal/cdRenewal.types';
import pageTrack from './pageAnalytics.constants';
import type { TransferActivity } from '../containers/transfers/transfers.reducer';
import type { SubmitApplicationClientResponse } from '../utilities/types';

// cd bump up
export const ANALYTICS_CD_BUMP_UP_OVERVIEW = 'ANALYTICS_CD_BUMP_UP_OVERVIEW';
export const ANALYTICS_CD_BUMP_UP_CONFIRMATION = 'ANALYTICS_CD_BUMP_UP_CONFIRMATION';
export type CDBumpUpAnalyticsAction = {
  type: typeof ANALYTICS_CD_BUMP_UP_OVERVIEW | typeof ANALYTICS_CD_BUMP_UP_CONFIRMATION;
};

// cd renewal
export const ANALYTICS_CD_RENEWAL_NEXT_STEP = 'ANALYTICS_CD_RENEWAL_NEXT_STEP';
export const ANALYTICS_CD_RENEWAL_START_SUCCESS = 'ANALYTICS_CD_RENEWAL_START_SUCCESS';
export const ANALYTICS_CD_RENEWAL_START_FAILURE = 'ANALYTICS_CD_RENEWAL_START_FAILURE';
export const ANALYTICS_CD_RENEWAL_CONFIRMATION = 'ANALYTICS_CD_RENEWAL_CONFIRMATION';
export const ANALYTICS_CD_RENEWAL_INFORMATION = 'ANALYTICS_CD_RENEWAL_INFORMATION';

// welcome
export const ANALYTICS_WELCOME_PAGE = 'ANALYTICS_WELCOME_PAGE';
export const ANALYTICS_WELCOME_PAGE_MAINTENANCE = 'ANALYTICS_WELCOME_PAGE_MAINTENANCE';

// funding
export const ANALYTICS_CHOOSE_FUNDING = 'ANALYTICS_CHOOSE_FUNDING';
export const ANALYTICS_ADD_EXTERNAL_BANK_START = 'ANALYTICS_ADD_EXTERNAL_BANK_START';
export const ANALYTICS_FUND_AMOUNT = 'ANALYTICS_FUND_AMOUNT';
export const ANALYTICS_FUND_REVIEW = 'ANALYTICS_FUND_REVIEW';
export const ANALYTICS_FUND_CONFIRMATION = 'ANALYTICS_FUND_CONFIRMATION';
export const ANALYTICS_FUND_VIA_CHECK_CONFIRMATION = 'ANALYTICS_FUND_VIA_CHECK_CONFIRMATION';
export const ANALYTICS_ACCOUNT_VERIFIED = 'ANALYTICS_ACCOUNT_VERIFIED';
export const ANALYTICS_ROUTING_NUMBER_FAILED = 'ANALYTICS_ROUTING_NUMBER_FAILED';
export const ANALYTICS_YODLEE_FAILED = 'ANALYTICS_YODLEE_FAILED';

// open account
export const ANALYTICS_PERSONAL_INFORMATION = 'ANALYTICS_PERSONAL_INFORMATION';
export const ANALYTICS_MORE_ABOUT_YOU = 'ANALYTICS_MORE_ABOUT_YOU';
export const ANALYTICS_SELECT_PRODUCT = 'ANALYTICS_SELECT_PRODUCT';
export const ANALYTICS_JOINT_OWNERS = 'ANALYTICS_JOINT_OWNERS';
export const ANALYTICS_REVIEW_SELECTION = 'ANALYTICS_REVIEW_SELECTION';
export const ANALYTICS_REVIEW_TERMS = 'ANALYTICS_REVIEW_TERMS';
export const ANALYTICS_REVIEW_TERMS_TRANSMIT = 'ANALYTICS_REVIEW_TERMS_TRANSMIT';
export const ANALYTICS_REVIEW_ETIN_TRANSMIT = 'ANALYTICS_REVIEW_ETIN_TRANSMIT';
export const ANALYTICS_SELECTED_JOINT_OWNER = 'ANALYTICS_SELECTED_JOINT_OWNER';
export const ANALYTICS_ADD_JOINT_OWNER_MODAL = 'ANALYTICS_ADD_JOINT_OWNER_MODAL';
export const ANALYTICS_REVIEW_JOINT_OWNER_MODAL = 'ANALYTICS_REVIEW_JOINT_OWNER_MODAL';
export const ANALYTICS_START_INTENT = 'ANALYTICS_START_INTENT';
export const ANALYTICS_ELIGIBILITY_ERROR_AO = 'ANALYTICS_ELIGIBILITY_ERROR_AO';

// Submit Application
export const ANALYTICS_SUBMIT_APPLICATION_ERROR = 'ANALYTICS_SUBMIT_APPLICATION_ERROR';

export type SubmitApplicationAnalyticsAction = {
  type: typeof ANALYTICS_SUBMIT_APPLICATION_ERROR;
  payload: string;
};

// Processing Application
export const ANALYTICS_PROCESSING_APPLICATION = 'ANALYTICS_PROCESSING_APPLICATION';

// Processing Application Transmit
export const ANALYTICS_PROCESSING_APPLICATION_TRANSMIT =
  'ANALYTICS_PROCESSING_APPLICATION_TRANSMIT';

export type ProcessingApplicationAnalyticsAction = {
  type: typeof ANALYTICS_PROCESSING_APPLICATION;
  payload?: SubmitApplicationClientResponse;
  isNewAccountOpening: boolean;
};

export type ProcessingApplicationAnalyticsTransmitAction = {
  type: typeof ANALYTICS_PROCESSING_APPLICATION;
  payload?: SubmitApplicationClientResponse;
  isNewAccountOpening: boolean;
  ctaStatus: string;
};

// Overdraft
export const ANALYTICS_ADD_OVERDRAFT_START = 'ANALYTICS_ADD_OVERDRAFT_START';
export const ANALYTICS_ADD_OVERDRAFT_NO_ELIGIBLE_ACCOUNTS =
  'ANALYTICS_ADD_OVERDRAFT_NO_ELIGIBLE_ACCOUNTS';
export const ANALYTICS_REMOVE_OVERDRAFT_START = 'ANALYTICS_REMOVE_OVERDRAFT_START';
export const ANALYTICS_OVERDRAFT_ERROR = 'ANALYTICS_OVERDRAFT_ERROR';
export const ANALYTICS_OVERDRAFT_MOUNTED = 'ANALYTICS_OVERDRAFT_MOUNTED';
export const ANALYTICS_ADD_OVERDRAFT_CONFIRMED = 'ANALYTICS_ADD_OVERDRAFT_CONFIRMED';
export const ANALYTICS_REMOVE_OVERDRAFT_CONFIRMED = 'ANALYTICS_REMOVE_OVERDRAFT_CONFIRMED';

// Statements
export const ANALYTICS_ACTION_STATEMENTS_SUCCESS = 'ANALYTICS_ACTION_STATEMENTS_SUCCESS';
export const ANALYTICS_ACTION_STATEMENTS_FAILURE = 'ANALYTICS_ACTION_STATEMENTS_FAILURE';
export const ANALYTICS_ACTION_STATEMENTS_SUBMIT_SUCCESS =
  'ANALYTICS_ACTION_STATEMENTS_SUBMIT_SUCCESS';
export const ANALYTICS_ACTION_STATEMENTS_SUBMIT_FAILURE =
  'ANALYTICS_ACTION_STATEMENTS_SUBMIT_FAILURE';
export const ANALYTICS_ACTION_STATEMENTS_OPT_OUT_SUCCESS =
  'ANALYTICS_ACTION_STATEMENTS_OPT_OUT_SUCCESS';
export const ANALYTICS_ACTION_STATEMENTS_ALREADY_OPT_OUT_SUCCESS =
  'ANALYTICS_ACTION_STATEMENTS_ALREADY_OPT_OUT_SUCCESS';
export const ANALYTICS_ACTION_STATEMENTS_OPT_OUT_FAILURE =
  'ANALYTICS_ACTION_STATEMENTS_OPT_OUT_FAILURE';

export type StatementsAnalyticsAction = {
  type:
    | typeof ANALYTICS_ACTION_STATEMENTS_SUCCESS
    | typeof ANALYTICS_ACTION_STATEMENTS_FAILURE
    | typeof ANALYTICS_ACTION_STATEMENTS_SUBMIT_SUCCESS
    | typeof ANALYTICS_ACTION_STATEMENTS_SUBMIT_FAILURE
    | typeof ANALYTICS_ACTION_STATEMENTS_OPT_OUT_SUCCESS
    | typeof ANALYTICS_ACTION_STATEMENTS_ALREADY_OPT_OUT_SUCCESS
    | typeof ANALYTICS_ACTION_STATEMENTS_OPT_OUT_FAILURE;
};

// Combined Statements
export const ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS = 'ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS';
export const ACTION_FETCH_COMBINED_STATEMENTS_REQUEST = 'ACTION_FETCH_COMBINED_STATEMENTS_REQUEST';
export const ACTION_FETCH_COMBINED_STATEMENTS_FAILURE = 'ACTION_FETCH_COMBINED_STATEMENTS_FAILURE';

// External Accounts
export const ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_LOADED =
  'ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_LOADED';
export const ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_FAILED =
  'ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_FAILED';

export const ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS =
  'ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS';
export const ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED =
  'ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED';
export const ANALYTICS_LOAD_MAIL_A_CHECK = 'ANALYTICS_LOAD_MAIL_A_CHECK';

export const ANALYTICS_VERIFY_DEPOSITS = 'ANALYTICS_VERIFY_DEPOSITS';
export const ANALYTICS_YODLEE_START_SUCCESS = 'ANALYTICS_YODLEE_START_SUCCESS';
export const ANALYTICS_YODLEE_START_FAILURE = 'ANALYTICS_YODLEE_START_FAILURE';

// ePreferences
export const ANALYTICS_EPREFERENCES_START_SUCCESS = 'ANALYTICS_EPREFERENCES_START_SUCCESS';
export const ANALYTICS_EPREFERENCES_START_FAILURE = 'ANALYTICS_EPREFERENCES_START_FAILURE';
export const ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS = 'ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS';
export const ANALYTICS_EPREFERENCES_SUBMIT_FAILURE = 'ANALYTICS_EPREFERENCES_SUBMIT_FAILURE';

export type EPreferencesAnalyticsAction = {
  type:
    | typeof ANALYTICS_EPREFERENCES_START_SUCCESS
    | typeof ANALYTICS_EPREFERENCES_START_FAILURE
    | typeof ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS
    | typeof ANALYTICS_EPREFERENCES_SUBMIT_FAILURE;
  payload?: string;
};

// Explore Products
export const ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS = 'ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS';
export const ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE = 'ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE';

export type ExploreProductsAnalyticsAction = {
  type:
    | typeof ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS
    | typeof ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE;
};

// Rate and Terms
export const ANALYTICS_RATE_AND_TERMS_VIEW_SUCCESS = 'ANALYTICS_RATE_AND_TERMS_VIEW_SUCCESS';
export const ANALYTICS_RATE_AND_TERMS_VIEW_FAILURE = 'ANALYTICS_RATE_AND_TERMS_VIEW_FAILURE';

export type RateAndTermsAnalyticsAction = {
  type: typeof ANALYTICS_RATE_AND_TERMS_VIEW_SUCCESS | typeof ANALYTICS_RATE_AND_TERMS_VIEW_FAILURE;
};

// Marketing Alerts
export const ANALYTICS_MARKETING_ALERTS_START_SUCCESS = 'ANALYTICS_MARKETING_ALERTS_START_SUCCESS';
export const ANALYTICS_MARKETING_ALERTS_START_FAILURE = 'ANALYTICS_MARKETING_ALERTS_START_FAILURE';
export const ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS =
  'ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS';
export const ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE =
  'ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE';

export type MarketingAlertsAnalyticsSubmitAttr =
  | typeof pageTrack.attr.email
  | typeof pageTrack.attr.mail
  | typeof pageTrack.attr.both;

export type MarketingAlertsAnalyticsAction = {
  type:
    | typeof ANALYTICS_MARKETING_ALERTS_START_SUCCESS
    | typeof ANALYTICS_MARKETING_ALERTS_START_FAILURE
    | typeof ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS
    | typeof ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE;
  payload?: MarketingAlertsAnalyticsSubmitAttr;
};

// Security
export const ANALYTICS_SECURITY_START_SUCCESS = 'ANALYTICS_SECURITY_START_SUCCESS';
export const ANALYTICS_SECURITY_SUBMIT_SUCCESS = 'ANALYTICS_SECURITY_SUBMIT_SUCCESS';
export const ANALYTICS_SECURITY_SUBMIT_FAILURE = 'ANALYTICS_SECURITY_SUBMIT_FAILURE';

export type SecurityAnalyticsSubmitPageFunction =
  | typeof pageTrack.pagefunction.change_password
  | typeof pageTrack.pagefunction.change_username;

export type SecurityAnalyticsSubmitAction = {
  type: typeof ANALYTICS_SECURITY_SUBMIT_SUCCESS | typeof ANALYTICS_SECURITY_SUBMIT_FAILURE;
  payload: SecurityAnalyticsSubmitPageFunction;
};

// OTP
export const ANALYTICS_INITIATE_OTP_START_SUCCESS = 'ANALYTICS_INITIATE_OTP_START_SUCCESS';
export const ANALYTICS_SECURITY_START_NO_OTP_SUCCESS = 'ANALYTICS_SECURITY_START_NO_OTP_SUCCESS';
export const ANALYTICS_INITIATE_OTP_NO_PHONE = 'ANALYTICS_INITIATE_OTP_NO_PHONE';
export const ANALYTICS_VERIFY_OTP_VIEW_SUCCESS = 'ANALYTICS_VERIFY_OTP_VIEW_SUCCESS';
export const ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS = 'ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS';
export const ANALYTICS_INVALID_OTP_ERROR = 'ANALYTICS_INVALID_OTP_ERROR';
export const ANALYTICS_VERIFY_OTP_BLOCKED_ERROR = 'ANALYTICS_VERIFY_OTP_BLOCKED_ERROR';
export const ANALYTICS_MAX_OTP_ATTEMPTS_ERROR = 'ANALYTICS_MAX_OTP_ATTEMPTS_ERROR';
export const ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR =
  'ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR';
export const ANALYTICS_INITIATE_OTP_SERVER_ERROR = 'ANALYTICS_INITIATE_OTP_SERVER_ERROR';
export const ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR =
  'ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR';
export const ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR =
  'ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR';
export const ANALYTICS_VERIFY_OTP_SERVER_ERROR = 'ANALYTICS_VERIFY_OTP_SERVER_ERROR';

export const ANALYTICS_INITIATE_OTP_TRANSMIT = 'ANALYTICS_INITIATE_OTP_TRANSMIT';
export const ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE = 'ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE';
export const ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR =
  'ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR';

export type SubmitApplicationTransmitAnalyticsAction = {
  type: typeof ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR;
  payload: string;
};

// Cancel Card
export const ANALYTICS_CANCEL_CARD_START_SUCCESS = 'ANALYTICS_CANCEL_CARD_START_SUCCESS';
export const ANALYTICS_CANCEL_CARD_START_FAILURE = 'ANALYTICS_CANCEL_CARD_START_FAILURE';
export const ANALYTICS_CANCEL_CARD_SUBMIT_SUCCESS = 'ANALYTICS_CANCEL_CARD_SUBMIT_SUCCESS';
export const ANALYTICS_CANCEL_CARD_SUBMIT_FAILURE = 'ANALYTICS_CANCEL_CARD_SUBMIT_FAILURE';

export type CancelCardAnalyticsAction = {
  type:
    | typeof ANALYTICS_CANCEL_CARD_START_SUCCESS
    | typeof ANALYTICS_CANCEL_CARD_START_FAILURE
    | typeof ANALYTICS_CANCEL_CARD_SUBMIT_SUCCESS
    | typeof ANALYTICS_CANCEL_CARD_SUBMIT_FAILURE;
  payload?: string;
};

// CD Interest Payments
export const ANALYTICS_CD_INTEREST_PAYMENTS_START_SUCCESS =
  'ANALYTICS_CD_INTEREST_PAYMENTS_START_SUCCESS';
export const ANALYTICS_CD_INTEREST_PAYMENTS_START_FAILURE =
  'ANALYTICS_CD_INTEREST_PAYMENTS_START_FAILURE';
export const ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_SUCCESS =
  'ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_SUCCESS';
export const ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_FAILURE =
  'ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_FAILURE';
export const ANALYTICS_CD_INTEREST_PAYMENTS_STOP_SUCCESS =
  'ANALYTICS_CD_INTEREST_PAYMENTS_STOP_SUCCESS';
export const ANALYTICS_CD_INTEREST_PAYMENTS_STOP_FAILURE =
  'ANALYTICS_CD_INTEREST_PAYMENTS_STOP_FAILURE';

export type CDInterestPaymentsAnalyticsAction = {
  type:
    | typeof ANALYTICS_CD_INTEREST_PAYMENTS_START_SUCCESS
    | typeof ANALYTICS_CD_INTEREST_PAYMENTS_START_FAILURE
    | typeof ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_SUCCESS
    | typeof ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_FAILURE
    | typeof ANALYTICS_CD_INTEREST_PAYMENTS_STOP_SUCCESS
    | typeof ANALYTICS_CD_INTEREST_PAYMENTS_STOP_FAILURE;
  payload?: string;
};

// Account Alerts
export const ANALYTICS_ACCOUNT_ALERTS_START_SUCCESS = 'ANALYTICS_ACCOUNT_ALERTS_START_SUCCESS';
export const ANALYTICS_ACCOUNT_ALERTS_START_FAILURE = 'ANALYTICS_ACCOUNT_ALERTS_START_FAILURE';
export const ANALYTICS_ACCOUNT_ALERTS_SUBMIT_SUCCESS = 'ANALYTICS_ACCOUNT_ALERTS_SUBMIT_SUCCESS';
export const ANALYTICS_ACCOUNT_ALERTS_SUBMIT_FAILURE = 'ANALYTICS_ACCOUNT_ALERTS_SUBMIT_FAILURE';

// beneficiaries
export const ANALYTICS_BENEFICIARIES_VIEW_SUCCESS = 'ANALYTICS_BENEFICIARIES_VIEW_SUCCESS';
export const ANALYTICS_BENEFICIARIES_VIEW_FAILURE = 'ANALYTICS_BENEFICIARIES_VIEW_FAILURE';
export const ANALYTICS_BENEFICIARIES_START_SUCCESS = 'ANALYTICS_BENEFICIARIES_START_SUCCESS';
export const ANALYTICS_BENEFICIARIES_START_FAILURE = 'ANALYTICS_BENEFICIARIES_START_FAILURE';
export const ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS =
  'ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS';
export const ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE =
  'ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE';
export const ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS = 'ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS';
export const ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE = 'ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE';
export const ANALYTICS_BENEFICIARIES_ACCOUNT_START_SUCCESS =
  'ANALYTICS_BENEFICIARIES_ACCOUNT_START_SUCCESS';
export const ANALYTICS_BENEFICIARIES_ACCOUNT_START_FAILURE =
  'ANALYTICS_BENEFICIARIES_ACCOUNT_START_FAILURE';
export const ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_SUCCESS =
  'ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_SUCCESS';
export const ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_FAILURE =
  'ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_FAILURE';

export type BeneficiariesAnalyticsAction = {
  type:
    | typeof ANALYTICS_BENEFICIARIES_VIEW_SUCCESS
    | typeof ANALYTICS_BENEFICIARIES_VIEW_FAILURE
    | typeof ANALYTICS_BENEFICIARIES_START_SUCCESS
    | typeof ANALYTICS_BENEFICIARIES_START_FAILURE
    | typeof ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS
    | typeof ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE
    | typeof ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS
    | typeof ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE
    | typeof ANALYTICS_BENEFICIARIES_ACCOUNT_START_SUCCESS
    | typeof ANALYTICS_BENEFICIARIES_ACCOUNT_START_FAILURE
    | typeof ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_SUCCESS
    | typeof ANALYTICS_BENEFICIARIES_ACCOUNT_SUBMIT_FAILURE;
  payload?: string;
};

// Documents
export const ANALYTICS_VIEW_DOCUMENTS_SUCCESS = 'ANALYTICS_VIEW_DOCUMENTS_SUCCESS';
export const ANALYTICS_VIEW_DOCUMENTS_FAILURE = 'ANALYTICS_VIEW_DOCUMENTS_FAILURE';
export const ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS = 'ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS';
export const ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE = 'ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE';
export const ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE = 'ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE';
export const ANALYTICS_EPREFERENCES_SAVED = 'ANALYTICS_EPREFERENCES_SAVED';
export const ANALYTICS_EPREFERENCES_NOT_SAVED = 'ANALYTICS_EPREFERENCES_NOT_SAVED';

export type DocumentsAnalyticsAction = {
  type:
    | typeof ANALYTICS_VIEW_DOCUMENTS_SUCCESS
    | typeof ANALYTICS_VIEW_DOCUMENTS_FAILURE
    | typeof ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS
    | typeof ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE
    | typeof ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE
    | typeof ANALYTICS_EPREFERENCES_SAVED
    | typeof ANALYTICS_EPREFERENCES_NOT_SAVED;
};

// Rewards
export const ANALYTICS_REWARDS_VIEW_SUCCESS = 'ANALYTICS_REWARDS_VIEW_SUCCESS';
export const ANALYTICS_REWARDS_VIEW_SERVER_ERROR = 'ANALYTICS_REWARDS_VIEW_SERVER_ERROR';
// Transfers
export const ANALYTICS_NEW_TRANSFER_LOADED = 'ANALYTICS_NEW_TRANSFER_LOADED';

// Checks & Deposit Slips
export const ANALYTICS_CHECKS_DEPOSIT_SLIPS = 'ANALYTICS_CHECKS_DEPOSIT_SLIPS';

export type ChecksDepositSlipsPayload = {
  canOrderChecks: boolean;
  hasError: boolean;
};
export type ChecksDepositSlipsAction = {
  type: typeof ANALYTICS_CHECKS_DEPOSIT_SLIPS;
  payload: ChecksDepositSlipsPayload;
};

export type CDRenewalAnalyticsData = {
  pagename?: string;
  pagesubfunction?: string;
  attr?: string;
};

export type CDRenewalStartSuccessAnalyticsAction = {
  type: typeof ANALYTICS_CD_RENEWAL_START_SUCCESS;
  payload: {
    isGracePeriod: boolean;
  };
};

export type CDRenewalStartFailureAnalyticsAction = {
  type: typeof ANALYTICS_CD_RENEWAL_START_FAILURE;
  payload: {
    isGracePeriod: boolean;
  };
};

export type CDRenewalAnalyticsActionPayload = {
  step: CDRenewalStepsType;
  pagename?: string;
  pagesubfunction?: string;
  attr?: string;
};

export type CDRenewalNextStepAnalyticsAction = {
  type: typeof ANALYTICS_CD_RENEWAL_NEXT_STEP;
  payload: CDRenewalAnalyticsActionPayload;
};

export type YodleeAccountsLoadedAction = {
  type: typeof ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_LOADED;
  payload: string;
};

// Inbox
export const ANALYTICS_VIEW_INBOX_SUCCESS = 'ANALYTICS_VIEW_INBOX_SUCCESS';
export const ANALYTICS_VIEW_INBOX_FAILURE = 'ANALYTICS_VIEW_INBOX_FAILURE';

export type InboxAnalyticsAction = {
  type: typeof ANALYTICS_VIEW_INBOX_SUCCESS | typeof ANALYTICS_VIEW_INBOX_FAILURE;
};

// New Message
export const ANALYTICS_VIEW_NEW_MESSAGE_SUCCESS = 'ANALYTICS_VIEW_NEW_MESSAGE_SUCCESS';
export const ANALYTICS_VIEW_NEW_MESSAGE_FAILURE = 'ANALYTICS_VIEW_NEW_MESSAGE_FAILURE';
export const ANALYTICS_SUBMIT_NEW_MESSAGE_FAILURE = 'ANALYTICS_SUBMIT_NEW_MESSAGE_FAILURE';

export type NewMessageAnalyticsAction = {
  type:
    | typeof ANALYTICS_VIEW_NEW_MESSAGE_SUCCESS
    | typeof ANALYTICS_VIEW_NEW_MESSAGE_FAILURE
    | typeof ANALYTICS_SUBMIT_NEW_MESSAGE_FAILURE;
};

// Reply Message
export const ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS = 'ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS';
export const ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE = 'ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE';
export const ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE = 'ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE';
export const ANALYTICS_VIEW_ATTACHMENT_FAILURE = 'ANALYTICS_VIEW_ATTACHMENT_FAILURE';

export type ReplyMessageAnalyticsAction = {
  type:
    | typeof ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS
    | typeof ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE
    | typeof ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE
    | typeof ANALYTICS_VIEW_ATTACHMENT_FAILURE;
};

// Edit Transfer
export const ANALYTICS_START_EDIT_TRANSFER_SUCCESS = 'ANALYTICS_START_EDIT_TRANSFER_SUCCESS';
export const ANALYTICS_START_EDIT_TRANSFER_FAILURE = 'ANALYTICS_START_EDIT_TRANSFER_FAILURE';
export const ANALYTICS_CONFIRM_EDIT_TRANSFER_SUCCESS = 'ANALYTICS_CONFIRM_EDIT_TRANSFER_SUCCESS';
export const ANALYTICS_EDIT_TRANSFER_SUBMIT_SUCCESS = 'ANALYTICS_EDIT_TRANSFER_SUBMIT_SUCCESS';
export const ANALYTICS_EDIT_TRANSFER_SUBMIT_FAILURE = 'ANALYTICS_EDIT_TRANSFER_SUBMIT_FAILURE';

export type EditTransferAnalyticsAction = {
  type:
    | typeof ANALYTICS_START_EDIT_TRANSFER_SUCCESS
    | typeof ANALYTICS_START_EDIT_TRANSFER_FAILURE
    | typeof ANALYTICS_CONFIRM_EDIT_TRANSFER_SUCCESS
    | typeof ANALYTICS_EDIT_TRANSFER_SUBMIT_SUCCESS
    | typeof ANALYTICS_EDIT_TRANSFER_SUBMIT_FAILURE;
  payload?: TransferActivity;
  errorMessage?: string;
};

export type AnalyticsAction =
  | CDRenewalNextStepAnalyticsAction
  | YodleeAccountsLoadedAction
  | CDRenewalStartSuccessAnalyticsAction
  | CDRenewalStartFailureAnalyticsAction
  | {
      type: typeof ANALYTICS_CD_RENEWAL_CONFIRMATION;
      payload: CDRenewalAnalyticsData;
    }
  | {
      type: typeof ANALYTICS_CD_RENEWAL_INFORMATION;
    }
  | {
      type: typeof ANALYTICS_WELCOME_PAGE;
    }
  | {
      type: typeof ANALYTICS_WELCOME_PAGE_MAINTENANCE;
    }
  | {
      type: typeof ANALYTICS_CHOOSE_FUNDING;
    }
  | {
      type: typeof ANALYTICS_FUND_AMOUNT;
    }
  | {
      type: typeof ANALYTICS_FUND_REVIEW;
    }
  | {
      type: typeof ANALYTICS_FUND_CONFIRMATION;
    }
  | {
      type: typeof ANALYTICS_FUND_VIA_CHECK_CONFIRMATION;
    }
  | {
      type: typeof ANALYTICS_ACCOUNT_VERIFIED;
    }
  | {
      type: typeof ANALYTICS_ROUTING_NUMBER_FAILED;
    }
  | {
      type: typeof ANALYTICS_YODLEE_FAILED;
    }
  | {
      type: typeof ANALYTICS_PERSONAL_INFORMATION;
    }
  | {
      type: typeof ANALYTICS_MORE_ABOUT_YOU;
    }
  | {
      type: typeof ANALYTICS_SELECT_PRODUCT;
    }
  | {
      type: typeof ANALYTICS_JOINT_OWNERS;
    }
  | {
      type: typeof ANALYTICS_REVIEW_SELECTION;
    }
  | {
      type: typeof ANALYTICS_ELIGIBILITY_ERROR_AO;
    }
  | {
      type: typeof ANALYTICS_REVIEW_TERMS;
    }
  | {
      type: typeof ANALYTICS_REVIEW_ETIN_TRANSMIT;
    }
  | {
      type: typeof ANALYTICS_REVIEW_TERMS_TRANSMIT;
    }
  | {
      type: typeof ANALYTICS_SELECTED_JOINT_OWNER;
      payload: number;
    }
  | {
      type: typeof ANALYTICS_ADD_JOINT_OWNER_MODAL;
    }
  | {
      type: typeof ANALYTICS_REVIEW_JOINT_OWNER_MODAL;
    }
  | {
      type: typeof ANALYTICS_START_INTENT;
    }
  | {
      type: typeof ANALYTICS_ADD_OVERDRAFT_START;
    }
  | {
      type: typeof ANALYTICS_ADD_OVERDRAFT_NO_ELIGIBLE_ACCOUNTS;
    }
  | {
      type: typeof ANALYTICS_REMOVE_OVERDRAFT_START;
    }
  | {
      type: typeof ANALYTICS_OVERDRAFT_ERROR;
    }
  | {
      type: typeof ANALYTICS_OVERDRAFT_MOUNTED;
    }
  | {
      type: typeof ANALYTICS_ADD_OVERDRAFT_CONFIRMED;
    }
  | {
      type: typeof ANALYTICS_REMOVE_OVERDRAFT_CONFIRMED;
    }
  | {
      type: typeof ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS;
    }
  | {
      type: typeof ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED;
    }
  | {
      type: typeof ANALYTICS_LOAD_MAIL_A_CHECK;
    }
  | {
      type: typeof ANALYTICS_VERIFY_DEPOSITS;
      payload: boolean;
    }
  | {
      type: typeof ANALYTICS_ADD_EXTERNAL_BANK_START;
    }
  | {
      type: typeof ANALYTICS_YODLEE_START_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_YODLEE_START_FAILURE;
    }
  | {
      type: typeof ANALYTICS_NEW_TRANSFER_LOADED;
    }
  | EPreferencesAnalyticsAction
  | MarketingAlertsAnalyticsAction
  | ChecksDepositSlipsAction
  | {
      type: typeof ANALYTICS_SECURITY_START_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_SECURITY_START_NO_OTP_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_START_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_NO_PHONE;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_TRANSMIT;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE;
    }
  | {
      type: typeof ANALYTICS_VERIFY_OTP_VIEW_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_INVALID_OTP_ERROR;
    }
  | {
      type: typeof ANALYTICS_VERIFY_OTP_BLOCKED_ERROR;
    }
  | {
      type: typeof ANALYTICS_MAX_OTP_ATTEMPTS_ERROR;
    }
  | {
      type: typeof ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR;
      payload: string;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_SERVER_ERROR;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR;
    }
  | {
      type: typeof ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR;
    }
  | {
      type: typeof ANALYTICS_VERIFY_OTP_SERVER_ERROR;
    }
  | {
      type: typeof ANALYTICS_REWARDS_VIEW_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_REWARDS_VIEW_SERVER_ERROR;
    }
  | {
      type: typeof ANALYTICS_ACCOUNT_ALERTS_START_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_ACCOUNT_ALERTS_START_FAILURE;
    }
  | {
      type: typeof ANALYTICS_ACCOUNT_ALERTS_SUBMIT_SUCCESS;
    }
  | {
      type: typeof ANALYTICS_ACTION_ADD_YODLEE_ACCOUNTS_FAILED;
    }
  | {
      type: typeof ANALYTICS_ACCOUNT_ALERTS_SUBMIT_FAILURE;
    }
  | SecurityAnalyticsSubmitAction
  | BeneficiariesAnalyticsAction
  | CDInterestPaymentsAnalyticsAction
  | CancelCardAnalyticsAction
  | DocumentsAnalyticsAction
  | StatementsAnalyticsAction
  | SubmitApplicationAnalyticsAction
  | InboxAnalyticsAction
  | NewMessageAnalyticsAction
  | ReplyMessageAnalyticsAction
  | EditTransferAnalyticsAction
  | ProcessingApplicationAnalyticsAction
  | ExploreProductsAnalyticsAction
  | RateAndTermsAnalyticsAction
  | CDBumpUpAnalyticsAction;
