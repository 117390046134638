import { LOCATION_CHANGE } from 'connected-react-router';
import type { ReduxState } from '../../reducers';
import type { Action } from '../../utilities/types';
import pageAnalytics from '../pageAnalytics';
import { getNewTransferAttr } from '../utilities';

export const viewPage = (action: Action, state: ReduxState) => {
  let pagesubfunction = 'invalid';
  const frequency = getNewTransferAttr(state);
  if (
    state.newTransfer.transfer &&
    state.newTransfer.transfer.fromAccount &&
    state.newTransfer.transfer.toAccount
  ) {
    const fromType = state.newTransfer.transfer.fromAccount.external ? 'Ex' : 'In';
    const toType = state.newTransfer.transfer.toAccount.external ? 'toEx' : 'toIn';

    switch (fromType + toType) {
      case 'ExtoIn':
        pagesubfunction = 'inward';
        break;

      case 'IntoIn':
        pagesubfunction = 'internal';
        break;

      case 'IntoEx':
        pagesubfunction = 'outward';
        break;

      case 'ExtoEx':
      default:
        break;
    }
  }

  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'transfers',
      pagefunction: 'make_transfer',
      pagesubfunction,
      pagename: 'confirm',
      attr: frequency,
    })
    .record();
};

export const verifyTransferPageEvents = {
  [LOCATION_CHANGE]: viewPage,
} as const;

export default verifyTransferPageEvents;
