import { StyleRules } from '@material-ui/core';
import Colors from '../colors/colors';
import { Z_INDEX_LOADING_OVERLAY } from '../../styles/zIndex';
import type { Theme } from '../../utilities/types';
import { FORM_WIDTH } from '../formWithImage/formWithImage.styles';

export default (theme: Theme): StyleRules => ({
  overlay: {
    zIndex: Z_INDEX_LOADING_OVERLAY,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: Colors.white,
    position: 'fixed',
  },
  formWithImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      width: FORM_WIDTH,
    },
  },
  loadingIndicatorContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  loadingIndicator: {
    transform: 'translate(-50%, -50%)',
  },
});
