(() => {
  const divTag = document.createElement('div');
  divTag.setAttribute('id', window.__config__.QUALTRICS_ID);

  if (document.body) {
    document.body.appendChild(divTag);
  }
  // eslint-disable-next-line global-require
  require('./qualtrics');
})();
