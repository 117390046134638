import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';
import { normalFontWeight } from '../typography/typography.styles';

export const useNoPhoneCheckStyles = makeStyles({
  checkboxFormControlOverride: {
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: pxToRem(16),
  },
  checkboxOverride: {
    alignItems: 'flex-start',
    height: 'auto',
    paddingTop: 0,
    paddingRight: pxToRem(10),
    paddingBottom: 0,
  },
  phoneCheckField: {},
});

export const useCheckboxStyles = makeStyles({
  checkedIconSVG: {
    width: pxToRem(24),
    height: pxToRem(24),
    padding: pxToRem(3),
  },
});

export const Checkbox = withStyles({
  root: {
    padding: `${pxToRem(9)} ${pxToRem(9)}`,
    '&.Mui-focusVisible': {
      borderRadius: pxToRem(4),
      padding: pxToRem(0),
      margin: `${pxToRem(9)} ${pxToRem(9)}`,
      outline: `solid ${pxToRem(2)} ${Colors.optimumBlue}`,
    },
  },
})(MUICheckbox);

export const useCDRadioStyles = makeStyles({
  circleIcon: {
    color: Colors.optimumBlue,
  },
  notChecked: {
    height: pxToRem(16),
  },
  radioButtonRoot: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formLabelRoot: {
    fontSize: pxToRem(12),
    marginBottom: pxToRem(12),
  },
  formControlLabelRoot: {
    background: Colors.white,
    border: `1px solid ${Colors.lightGray} `,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    height: pxToRem(100),
    justifyContent: 'center',
    margin: '0',
    textAlign: 'center',
    '&:focus-within': {
      // Visible in full-color space
      boxShadow: '0 6px 12px 0 rgba(0, 104, 153, 0.3)',

      // Visible in Windows high-contrast themes (which disable box-shadow and override outline-color)
      outlineColor: 'transparent',
      outlineWidth: 2,
      outlineStyle: 'dotted',
    },
  },
  formControlLabelRootSelected: {
    border: `2px solid ${Colors.optimumBlue}`,
  },
  secondaryLabel: {
    height: pxToRem(16),
    fontSize: pxToRem(10),
  },
  radioGroupRoot: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const useRadioStyles = makeStyles({
  checkedLabel: {
    fontWeight: 'bold',
  },
  formLabelRoot: {
    fontSize: pxToRem(12),
    marginBottom: pxToRem(12),
  },
  secondaryLabel: {
    fontSize: pxToRem(12),
  },
  radio: {
    '&.Mui-focusVisible': {
      border: `solid ${pxToRem(4)} ${Colors.optimumBlue}`,
      margin: `${pxToRem(8)} ${pxToRem(8)}`,
      width: pxToRem(16),
      height: pxToRem(16),
      color: Colors.optimumBlue,
    },
  },
});

export const useCheckboxLabelStyles = makeStyles({
  inputCheckbox: {
    paddingTop: pxToRem(2),
    paddingBottom: pxToRem(2),
    alignSelf: 'flex-start',
  },
});

export const selectFieldDefaultFont = makeStyles({
  selectFieldDisplayStyle: {
    fontWeight: normalFontWeight,
  },
});

export const currencyFieldStyles = makeStyles({
  errorIconFocusStyle: {
    marginRight: pxToRem(8),
  },
  passwordEmptyErrorStyle: {
    margin: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(16)} ${pxToRem(8)}`,
  },
});
