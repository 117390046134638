import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, withStyles } from '@material-ui/core';
import { kebabCase } from 'lodash';
import Button from 'bank-component-library/ui/atoms/Button';
import { clickTrack } from '../../analytics/clickTracking.constants';
import type { ReduxState } from '../../reducers/index';
import { StandardText } from '../typography/typography';
import pxToRem from '../../utilities/pxToRem';
import type { Dispatch, StyleProps } from '../../utilities/types';
import FormWithImage from '../formWithImage/formWithImage';
import {
  AccountVerificationFailedText,
  MailACheckText,
} from '../../containers/newAccountOpening/newAccountOpening.constants';
import Routes from '../../containers/routes/routes.constants';
import SynchronyAddress from '../synchronyAddress/synchronyAddress';
import { OWCSPageNamesPOBox } from '../../containers/owcs/owcs.constants';
import { ANALYTICS_LOAD_MAIL_A_CHECK } from '../../analytics/actions';
import Colors from '../colors/colors';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';

const styles = {
  textSection: { marginBottom: pxToRem(30) },
  overrideCtaContainer: { marginTop: 0, marginBottom: pxToRem(10) },
  buttonContainer: { display: 'flex' },
  horizontalLine: {
    marginTop: pxToRem(40),
    marginBottom: pxToRem(40),
    height: '0.5px',
    opacity: 0.3,
    border: `solid 0.5px ${Colors.lineBreak}`,
    width: '100%',
  },
};

type OwnProps = {
  isFunding?: boolean;
};

type StateProps = {
  accountType?: string;
  accountNumber?: string;
};

type DispatchProps = {
  loadAnalyticsAccountVerificationFailed: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & StyleProps;

const mapStateToProps = (state: ReduxState) => {
  return {
    accountType: state.addExternalAccount.accountType,
    accountNumber: state.addExternalAccount.accountNumber,
    bankName: state.addExternalAccount.bankName,
    newAccountType: state.applications.newAccount.accountType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadAnalyticsAccountVerificationFailed: () => dispatch({ type: ANALYTICS_LOAD_MAIL_A_CHECK }),
});

export const MailCheck = (props: Props) => {
  const {
    classes,
    accountType = '',
    accountNumber = '',
    isFunding,
    loadAnalyticsAccountVerificationFailed,
  } = props;
  useEffect(() => {
    loadAnalyticsAccountVerificationFailed();
  });

  const history = useHistory();

  // Fetching Images from Control Center's S3 Bucket
  const fundingImageUrl = getImageSrc(ImagesFileNames.accountOpeningJpg);
  const fundingImageUrlLg = getImageSrc(ImagesFileNames.accountOpening_2xJpg);
  const fundingImageUrlXl = getImageSrc(ImagesFileNames.accountOpening_3xJpg);
  const servicingImageUrl = getImageSrc(ImagesFileNames.addExternalAccountsJpg);
  const servicingImageUrlLg = getImageSrc(ImagesFileNames.addExternalAccounts_2xJpg);
  const servicingImageUrlXl = getImageSrc(ImagesFileNames.addExternalAccounts_3xJpg);

  const IMG_URL = isFunding ? fundingImageUrl : servicingImageUrl;
  const IMG_URL_LG = isFunding ? fundingImageUrlLg : servicingImageUrlLg;
  const IMG_URL_XL = isFunding ? fundingImageUrlXl : servicingImageUrlXl;
  const DATA_TRACK_TITLE = isFunding
    ? clickTrack.depositInstructions.view_dashboard
    : clickTrack.transfers.manage_external_accounts;

  const fundWithCheckSteps = [
    'From your Account Dashboard go to your Inbox to see your Welcome Kit.',
    'Print deposit slip and fill out',
    'Endorse the back of the check and write your account number on your check ',
    'Please mail within 60 days to address listed below:',
  ];

  return (
    <Grid container className={classes.parentContainer} spacing={0} alignItems="stretch">
      <FormWithImage
        imageUrl={IMG_URL}
        imageUrlLg={IMG_URL_LG}
        imageUrlXl={IMG_URL_XL}
        classes={{ ctaContainer: classes.overrideCtaContainer }}
        ctaText={MailACheckText.CTA_HEADER}
        showMarketingMessage={isFunding}
        useLoadingAnimation={false}
      >
        <div className="mb-6">
          {accountNumber && (
            <>
              <StandardText component="div">{accountType}</StandardText>
              <StandardText component="div" data-test="account-number">
                {AccountVerificationFailedText.ACCOUNT_NUMBER}: {accountNumber}
              </StandardText>
            </>
          )}
        </div>
        <ol className="mb-6" data-test="fund-with-check-message">
          {fundWithCheckSteps.map((description, index) => (
            <StandardText key={description} component="li" className="mt-2">
              <b>{index + 1}.&nbsp;</b>
              {description}
            </StandardText>
          ))}
        </ol>
        <SynchronyAddress template={OWCSPageNamesPOBox.PAGE_CONFRIMATION} />

        <Grid item className="mb-6 text-end">
          <Button
            variant="primary"
            data-test={`${kebabCase(AccountVerificationFailedText.GO_TO_DASHBOARD)}-btn`}
            data-track-title={DATA_TRACK_TITLE}
            onClick={() => history.push(Routes.DASHBOARD)}
          >
            {MailACheckText.ACCOUNT_DASHBOARD}
          </Button>
        </Grid>
      </FormWithImage>
    </Grid>
  );
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MailCheck);
const styledComponent = withStyles(styles)(connectedComponent);
export default styledComponent;
