// Place all constants for accessing sections of Redux forms

// This will prevent dependency chains from including full components and allow
// for better codesplitting performance.

export const NAO_FORM_ID = 'newUserAccountOpeningForm';
export const EAO_FORM_ID = 'existingUserAccountOpeningForm';

export const PERSONAL_INFORMATION_SECTION_ID = 'personalInformation';

export const JOINT_OWNERS_SECTION_ID = 'jointOwnersSection';
export const JOINT_OWNERS_LISTS_ID = 'jointOwnersLists';
export const NEW_JOINT_OWNERS_ID = 'newJointOwners';
export const SELECTED_EXISTING_JOINT_OWNERS_ID = 'selectedExistingJointOwners';

export const REQUIRED_FIELD_NOTE = 'Note:';
export const REQUIRED_FIELD_TEXT = 'All fields are required.';
export const REQUIRED_FIELD_SELECTION = 'A selection is required.';

export const PRODUCT_SECTION_ID = 'product';

export const FUNDING_FORM_ID = 'newAccountFundingForm';

export const ADD_EXTERNAL_ACCOUNT_FORM_ID = 'addExternalAccountForm';

export const EXTERNAL_ACCOUNT_OPTION_FORM = 'chooseExternalAccountOption';

export const EditNicknameFieldNames = Object.freeze({
  NICKNAME: 'nickname',
});

export type EditNicknameFieldName =
  typeof EditNicknameFieldNames[keyof typeof EditNicknameFieldNames];

export const TrialDepositFieldNames = Object.freeze({
  FIRST: 'trialDeposit1',
  SECOND: 'trialDeposit2',
});

export type TrialDepositFieldName =
  typeof TrialDepositFieldNames[keyof typeof TrialDepositFieldNames];

export const TransferFieldNames = Object.freeze({
  AMOUNT: 'amount',
  END_DATE: 'endDate',
  ENDING_TYPE: 'endingType',
  FREQUENCY: 'frequency',
  FROM_ACCOUNT: 'fromAccountId',
  REQUEST_DATE: 'requestDate',
  TO_ACCOUNT: 'toAccountId',
});

export type TransferFieldName = typeof TransferFieldNames[keyof typeof TransferFieldNames];

export const ProtectorAccountsFieldNames = Object.freeze({
  PROTECTOR_ACCOUNTS: 'protectorAccounts',
});

export type ProtectorAccountsFieldName =
  typeof ProtectorAccountsFieldNames[keyof typeof ProtectorAccountsFieldNames];

export const AddExternalAccountFieldNames = Object.freeze({
  ACCOUNT_NUMBER: 'accountNumber',
  ACCOUNT_TYPE: 'accountType',
  CONFIRMED_ACH: 'confirmedACH',
  ROUTING_NUMBER: 'routingNumber',
});

export type AddExternalAccountFieldName =
  typeof AddExternalAccountFieldNames[keyof typeof AddExternalAccountFieldNames];

export const AddressFieldNames = Object.freeze({
  SAME_ADDRESS: 'sameAsPrimary',
  EMAIL: 'email',
  EMAIL_TYPE: 'emailType',
  STREET_ADDRESS_1: 'streetAddress1',
  STREET_ADDRESS_2: 'streetAddress2',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
  PHONE_TYPE: 'phoneType',
  PHONE_NUMBER: 'phoneNumber',
  SECONDARY_PHONE_TYPE: 'secondaryPhoneType',
  SECONDARY_PHONE_NUMBER: 'secondaryPhoneNumber',
  NO_PHONE_NUMBER: 'noPhoneNumber',
});

export type AddressFieldName = typeof AddressFieldNames[keyof typeof AddressFieldNames];

export const IdentifyingInformationFieldNames = Object.freeze({
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  SSN: 'socialSecurityNumber',
  DOB: 'dateOfBirth',
});

export type IdentifyingInformationFieldName =
  typeof IdentifyingInformationFieldNames[keyof typeof IdentifyingInformationFieldNames];

export const OccupationFieldNames = Object.freeze({
  OCCUPATION: 'occupation',
  EMPLOYER_NAME: 'employerName',
});

export type OccupationFieldName = typeof OccupationFieldNames[keyof typeof OccupationFieldNames];

export const MoreAboutYouFieldNames = Object.freeze({
  ...OccupationFieldNames,
  CITIZENSHIP: 'citizenship',
  SECURITY_QUESTION: 'securityQuestion',
  SECURITY_ANSWER: 'securityAnswer',
});

export const NewJointOwnerFieldNames = Object.freeze({
  ...IdentifyingInformationFieldNames,
  ...MoreAboutYouFieldNames,
});

export type NewJointOwnerFieldName =
  typeof NewJointOwnerFieldNames[keyof typeof NewJointOwnerFieldNames];

export const StatementsFieldNames = Object.freeze({
  ACCOUNT_ID: 'accountId',
  YEAR: 'year',
});

export type StatementsFieldName = typeof StatementsFieldNames[keyof typeof StatementsFieldNames];

export const CombinedStatementsFieldNames = Object.freeze({
  COMBINED_STATEMENTS: 'combinedStatements',
});

export type CombinedStatementsFieldName =
  typeof CombinedStatementsFieldNames[keyof typeof CombinedStatementsFieldNames];

export const UsernameFieldNames = Object.freeze({
  USERNAME: 'Username',
});

export type UsernameFieldName = typeof UsernameFieldNames[keyof typeof UsernameFieldNames];

export const PasswordFieldNames = Object.freeze({
  PASSWORD: 'new_password',
  CONFIRM_PASSWORD: 'confirm_new_password',
});

export type PasswordFieldName = typeof PasswordFieldNames[keyof typeof PasswordFieldNames];

export const AccountAlertsFieldNames = Object.freeze({
  AMOUNT: 'amount',
  ENROLL: 'enroll',
});

export const AccountAlertsType = Object.freeze({
  NONE: 'none',
  EMAIL: 'email',
});

export type AccountAlertsFieldName =
  typeof AccountAlertsFieldNames[keyof typeof AccountAlertsFieldNames];

// This is the only field name specific to the Beneficiary Modal. The rest are built in
// to our various address field components or are generated from account IDs.
export const BeneficiaryFieldNames = Object.freeze({
  ALL_ACCOUNTS: 'allAccounts',
});

export const CdInterestPaymentsFieldNames = Object.freeze({
  INTEREST_PAYMENTS_ACCOUNT: 'interestPaymentsAccount',
});

export type CdInterestPaymentsFieldName =
  typeof CdInterestPaymentsFieldNames[keyof typeof CdInterestPaymentsFieldNames];

export const DocumentsFieldNames = Object.freeze({
  DOCUMENT_TYPE: 'type',
  YEAR: 'year',
});
