import type { AccountDetailsType } from 'Domain/Account/AccountDetails';
import axios from '../../utilities/axios';
import { ACTIVE_CARDS_URL, CANCEL_CARD_URL } from '../../utilities/route-mappings';

export const CardTypes = Object.freeze({
  ATM: 'ATM',
  Debit: 'Debit',
  Credit: 'Credit',
});

type CardType = keyof typeof CardTypes;

type CardStatus = 'Active' | 'Suspended' | 'Closed';

type CardLinkedAccount = {
  accountDisplayType: string;
  accountId: string;
  accountLastFour: string;
  accountNickname: string;
  accountType: AccountDetailsType;
};

export type Card = {
  cardDescription: string;
  cardId: string;
  cardLastFour: string;
  cardStatus: CardStatus;
  cardType: CardType;
  linkedAccounts: CardLinkedAccount[];
};

export const fetchActiveCards = async (): Promise<Card[]> => {
  const res = await axios.get(ACTIVE_CARDS_URL());
  return res.data.cards;
};

export type CancelCardService = (arg1: string) => Promise<void>;

export const cancelCardService: CancelCardService = async (cardId) => {
  await axios.patch(CANCEL_CARD_URL(cardId), { cardStatus: 'Closed' });
};
export default fetchActiveCards;
