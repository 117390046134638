import type { OWCSPageSection, Contents, ContentAttributes, Content } from './owcs.type';

export const getDisplayNameFromSections = (
  sections: OWCSPageSection[],
  sectionName: string
): string => {
  const section: OWCSPageSection | null | undefined = sections.find(
    (instance) => instance.name === sectionName
  );
  return (section && section.attributes && section.attributes.displaytext) || '';
};

function getContentFromContentBlocks(contents: Content[]) {
  return contents.reduce<Record<string, ContentAttributes | string>>(
    (structuredContent, content: Content) => {
      const attributes = content && content.attributes;
      if (attributes) {
        return {
          ...structuredContent,
          [attributes.type]: attributes.imageUrl || attributes.displaytext,
        };
      }

      return structuredContent;
    },
    {}
  );
}

/**
 * The structure of the page must be:
 * Page > Section > ContentBlock > ContentDetail
 *
 * ContentBlock in OWCS must have { type, displayText, ?imageUrl }
 */
export function getContentDetailsFromOWCS<T = any>(section?: OWCSPageSection | null): T[] {
  if (!section) return [];

  const dashboardTileListContent: Array<Contents> | null | undefined = section.contentBlocks;
  if (dashboardTileListContent && dashboardTileListContent.length) {
    return dashboardTileListContent.reduce<Array<T>>((cmsContentList, contentDataList) => {
      const contents: Content[] = contentDataList && contentDataList.contents;
      if (contents && contents.length) {
        const content: any = getContentFromContentBlocks(contents);
        return [...cmsContentList, content];
      }

      return cmsContentList;
    }, []);
  }

  return [];
}

export const getSectionsFromPage = (
  pageName: string,
  sections: OWCSPageSection[]
): OWCSPageSection | null | undefined => {
  return sections.find((item) => item.name === pageName);
};

export default getDisplayNameFromSections;
