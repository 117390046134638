import type { Dispatch } from '../../utilities/types';
import {
  ACTION_FETCH_ACCOUNTS_FAILURE,
  ACTION_FETCH_ACCOUNTS_REQUEST,
  ACTION_FETCH_BENEFICIARY_ACCOUNTS_REQUEST,
  ACTION_FETCH_ACCOUNTS_SUCCESS,
  ACTION_FETCH_BENEFICIARY_ACCOUNTS_SUCCESS,
  ACTION_FETCH_BENEFICIARY_ACCOUNTS_FAILURE,
  ACTION_FETCH_DOWNTIME_SUCCESS,
} from './accountDashboard.reducer';
import type {
  AccountsService,
  AccountsServiceParams,
  DowntimeService,
  FetchDowntimePayload,
} from './accountDashboard.service';
import { fetchAccountsService, fetchDowntimeService } from './accountDashboard.service';
import type { ReduxState } from '../../reducers';

export const fetchAccounts =
  (params: AccountsServiceParams, service: AccountsService = fetchAccountsService) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    const { accounts } = getState();
    const { isRefetch, ...apiPayload } = params;
    if (accounts.isLoading) {
      return undefined;
    }

    dispatch({ type: ACTION_FETCH_ACCOUNTS_REQUEST });

    let payload;

    try {
      const fetchAccountsData = await service(apiPayload);
      payload = { ...fetchAccountsData, isRefetch };
    } catch (e) {
      dispatch({
        type: ACTION_FETCH_ACCOUNTS_FAILURE,
        payload: {},
      });
      throw e;
    }

    if (payload) {
      dispatch({
        type: ACTION_FETCH_ACCOUNTS_SUCCESS,
        payload,
      });
    }
    return payload;
  };

export const fetchBeneficiaryAccounts =
  (params: AccountsServiceParams, service: AccountsService = fetchAccountsService) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    const { accounts } = getState();
    if (accounts.isBeneficiaryAccountsLoading) {
      return undefined;
    }

    dispatch({ type: ACTION_FETCH_BENEFICIARY_ACCOUNTS_REQUEST });

    let payload;

    try {
      payload = await service(params);
    } catch (e) {
      dispatch({
        type: ACTION_FETCH_BENEFICIARY_ACCOUNTS_FAILURE,
        payload: {},
      });
      throw e;
    }

    if (payload) {
      dispatch({
        type: ACTION_FETCH_BENEFICIARY_ACCOUNTS_SUCCESS,
        payload,
      });
      return payload;
    }

    return payload;
  };
export const fetchDowntime =
  (service: DowntimeService = fetchDowntimeService) =>
  async (dispatch: Dispatch) => {
    const data: FetchDowntimePayload = await service();
    if (
      !data ||
      !data.maintenance ||
      !data.maintenance.downtime ||
      !data.maintenance.downtime.length
    )
      return;

    dispatch({
      type: ACTION_FETCH_DOWNTIME_SUCCESS,
      payload: data.maintenance.downtime[0],
    });
  };
