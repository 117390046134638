// This way if we have to change the way in which our SVGs are loaded, we can isolate changes
// to this file.
import * as React from 'react';

import { ReactComponent as CheckboxCheckedIcon } from '../../../public/images/icon-checkbox-checked.svg';
import { ReactComponent as CloseIcon } from '../../../public/images/close.svg';
import { ReactComponent as NavMenuIcon } from '../../../public/images/nav-menu.svg';
import { ReactComponent as HideIcon } from '../../../public/images/hide-text.svg';
import { ReactComponent as RevealIcon } from '../../../public/images/reveal-text.svg';

export const CheckboxCheckedIconSVG: SVGComponent = CheckboxCheckedIcon;
export const CloseIconSVG: SVGComponent = CloseIcon;
export const HideIconSVG: SVGComponent = HideIcon;
export const NavMenuIconSVG: SVGComponent = NavMenuIcon;
export const RevealIconSVG: SVGComponent = RevealIcon;

export type SVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
