import pageAnalytics from '../pageAnalytics';
import type { ReduxState } from '../../reducers';
import type { AccountFundingFlow } from '../../utilities/accountFundingFlowType';
import pageTrack from '../pageAnalytics.constants';
import type { AccountActivityAction } from '../../containers/accountActivity/accountActivity.reducer';
import { getExternalFundingType, getPageSubfunction } from './fundNewAccount.analytics';

export const yodleeStartSuccess =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.add_external_bank,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.start,
        attr: getExternalFundingType(state),
      })
      .record();
  };

export const yodleeFailure =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.add_external_bank,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.error,
        attr: getExternalFundingType(state),
      })
      .record();
  };
