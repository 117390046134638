import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';
import { Z_INDEX_HEADER_SUBNAV } from '../../styles/zIndex';
import HeaderPortal from '../header/headerPortal';
import { headerBottomBoxShadow } from '../header/header.styles';
import SidebarLink from '../links/sidebarLink';
import { StandaloneSidebarLinkText } from '../typography/typography';
import { Alignment } from '../../styles/layout/alignment.styles';
import type { NavProps } from './nav.types';
import { theme } from '../../styles/createTheme';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const MOBILE_NAV_HEIGHT = pxToRem(65);

const useStyles = makeStyles({
  button: {
    padding: '1rem 0',
  },
  pageSpacing: {
    marginBottom: MOBILE_NAV_HEIGHT,
  },
});

const StyledExpansionPanel = withStyles({
  root: {
    zIndex: Z_INDEX_HEADER_SUBNAV,
    boxShadow: headerBottomBoxShadow,

    // Hack to get box shadow not to appear above the tabs.
    // Won't work in IE, but 🤷
    clipPath: 'inset(0 0 -1rem 0)',
  },
})(Accordion);

const StyledExpansionPanelSummary = withStyles({
  root: {
    height: MOBILE_NAV_HEIGHT,
    ...Alignment.horizontalPadding,
  },
  expandIcon: {
    color: Colors.optimumBlue,
    transform: 'rotate(90deg)',
    '&.Mui-expanded': {
      transform: 'rotate(-90deg)',
    },
  },
})(AccordionSummary);

const StyledExpansionPanelDetails = withStyles({
  root: {
    display: 'grid',
    marginTop: '-1rem',
    paddingBottom: '0.5rem',
    [theme.breakpoints.between('sm', 'lg')]: {
      paddingLeft: pxToRem(30),
      paddingRight: pxToRem(30),
    },
  },
})(AccordionDetails);

const NavMobile = ({ links, selected }: NavProps) => {
  const styles = useStyles();
  const [navHeaderExpanded, setNavHeaderExpanded] = useState(false);
  const checkLastNavLick = links?.slice(-1).shift();
  const lastNavLink =
    selected?.label?.toLowerCase() === checkLastNavLick?.label?.toLowerCase()
      ? links?.slice(-2).shift()
      : checkLastNavLick;

  const renderLink = (link) => {
    return (
      <SidebarLink
        to={link.to}
        className={styles.button}
        data-test={link.dataTest}
        data-track-type={link.dataTrackType}
        data-track-title={link.dataTrackTitle}
        key={link.to}
      >
        {link.label}
      </SidebarLink>
    );
  };

  const linkNodes = links.map((link) => {
    if (link === selected) return null;
    return renderLink(link);
  });

  const handleExpand = () => {
    setNavHeaderExpanded(!navHeaderExpanded);
  };

  const handleExpandOnBlur = (event) => {
    if (event?.target?.innerHTML.toLowerCase() === lastNavLink?.label?.toLowerCase())
      setNavHeaderExpanded(false);
  };

  const children = (
    <StyledExpansionPanel
      square
      expanded={navHeaderExpanded}
      onChange={handleExpand}
      onBlur={handleExpandOnBlur}
    >
      <StyledExpansionPanelSummary expandIcon={<SVGImage imageName={ImagesFileNames.arrowSvg} />}>
        <StandaloneSidebarLinkText className="active">{selected.label}</StandaloneSidebarLinkText>
      </StyledExpansionPanelSummary>
      <StyledExpansionPanelDetails aria-hidden={!navHeaderExpanded}>
        {linkNodes}
      </StyledExpansionPanelDetails>
    </StyledExpansionPanel>
  );

  return (
    <div className={styles.pageSpacing}>
      <HeaderPortal>{children}</HeaderPortal>
    </div>
  );
};

export default NavMobile;
