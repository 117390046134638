const accountDetailsData = (accountId: string) => ({
  data: {
    openDate: '2024-06-13',
    interestEarnedYTD: '0.01',
    accountOwners: ['GURU C E'],
    accountBeneficiaries: [],
    holdBalance: '0.00',
    routingNumber: '021213591',
    overdraftAvailable: false,
    interestEarnedLastYear: '0.00',
    nextInterestPaymentDate: '2024-10-13',
    interestDistribution: {
      method: 'External transfer',
    },
    accountId,
    accountType: 'Certificate of Deposit',
    funded: true,
    product: {
      productType: 'Certificate of Deposit',
      productDisplayType: 'CD',
      productId: '318',
      productName: '6 MONTH CD',
      minOpenAmount: 0,
      maxOpenAmount: 9999999,
      creatable: true,
      fundable: false,
      marketRate: '3.900',
      marketApy: '3.98',
    },
    currentBalance: '1000.00',
    availableBalance: '1000.00',
    transfersToEnabled: false,
    transfersFromEnabled: false,
    accountApy: '3.98',
    nickname: '',
    accountStatus: 'Active',
    cdInformation: {
      maturityDate: '2024-12-13',
      lastRenewalDate: '2023-05-22',
      inGracePeriod: false,
      isMaturing: false,
      isRenewable: false,
      term: {
        value: '6',
        units: 'Months',
      },
      renewalInstructionsApplied: false,
      maturityType: 'AUTOMATIC',
      cdBumpUpDate: null,
      isRateEditable: false,
      isBumpUpCD: false,
    },
    rate: '3.900',
    owner: true,
    primaryOwner: true,
    accountIdDisplay: '●●1234',
    accountLastFour: '1234',
  },
});

export default accountDetailsData;

const something = {
  data: {
    openDate: '2023-04-28',
    interestEarnedYTD: '31.36',
    accountOwners: ['GURU C E'],
    accountBeneficiaries: [],
    holdBalance: '0.00',
    routingNumber: '021213591',
    overdraftAvailable: false,
    interestEarnedLastYear: '29.64',
    nextInterestPaymentDate: '2024-09-22',
    interestDistribution: {
      method: 'Capitalize',
      isEditable: false,
      isEditableReasonCode: 'UPCOMING_INTEREST',
    },
    accountId: '7fa61b64-eb79-4241-af1c-567cd385b1c1',
    accountType: 'Certificate of Deposit',
    funded: true,
    product: {
      productType: 'Certificate of Deposit',
      productDisplayType: 'CD',
      productId: '318',
      productName: '18 MONTH CD',
      minOpenAmount: 0,
      maxOpenAmount: 8000,
      creatable: true,
      fundable: false,
      marketRate: '4.500',
      marketApy: '4.60',
    },
    currentBalance: '1061.00',
    availableBalance: '1061.00',
    transfersToEnabled: false,
    transfersFromEnabled: false,
    accountApy: '4.60',
    nickname: '',
    accountStatus: 'Active',
    cdInformation: {
      maturityDate: '2024-11-22',
      lastRenewalDate: '2023-05-22',
      inGracePeriod: false,
      isMaturing: false,
      isRenewable: false,
      term: {
        value: '18',
        units: 'Months',
      },
      renewalInstructionsApplied: false,
      maturityType: 'AUTOMATIC',
      cdBumpUpDate: null,
      isRateEditable: false,
      isBumpUpCD: false,
    },
    rate: '4.500',
    owner: true,
    primaryOwner: true,
    accountIdDisplay: '●●5071',
    accountLastFour: '5071',
  },
};
