import { makeStyles } from '@material-ui/styles';
import { Alignment } from '../../styles/layout/alignment.styles';
import pxToRem from '../../utilities/pxToRem';

export default makeStyles({
  root: {
    minHeight: '100vh',
  },
  header: {
    ...Alignment.horizontalPadding,
    height: pxToRem(100),
    display: 'flex',
    alignItems: 'center',
  },
  sad: {
    fontSize: '3.125rem',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1,
    padding: pxToRem(12),
    paddingBottom: pxToRem(64),
    textAlign: 'center',
  },
});
