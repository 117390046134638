import { fetchEncryptionKeyService } from '../containers/externalAccounts/externalAccounts.service';
import worker from '../workers/singletonWorker';
import fetchJose from './fetchJose';

export const ACCOUNT_ENCRYPTION_PUBLIC_KEY = 'account_key';
export const ACCOUNT_DECRYPTION_KEY_PAIR = 'account_decryption_key';
const ALG_VALUE = 'RSA-OAEP-256';
export type PublicJWK = {
  kty: 'RSA';
  d: string;
  e: 'AQAB';
  use: 'enc';
  kid: string;
  alg: 'RS256' | 'RSA-OAEP-256';
  n: string;
};

export type PublicPrivateJWK = PublicJWK & {
  dp: string;
  dq: string;
  p: string;
  q: string;
  qi: string;
};

export const mockKeyPair = {
  kty: 'RSA',
  d: 'string',
  e: 'AQAB',
  use: 'enc',
  kid: 'string',
  alg: ALG_VALUE,
  n: 'string',
  dp: 'string',
  dq: 'string',
  p: 'string',
  q: 'string',
  qi: 'string',
} as const;

export const mockPublicKey = {
  kty: 'RSA',
  d: 'fence',
  e: 'AQAB',
  use: 'enc',
  kid: 'lol',
  alg: 'RS256',
  n: 'crypt',
} as const;
export type FetchEncryptionKeyResponse = {
  keys: Array<PublicJWK>;
};

const setEncryptionKey = (accessToken: string): void => {
  global.sessionStorage.setItem(ACCOUNT_ENCRYPTION_PUBLIC_KEY, JSON.stringify(accessToken));
};

const getEncryptionKey = (): PublicJWK => {
  return JSON.parse(global.sessionStorage.getItem(ACCOUNT_ENCRYPTION_PUBLIC_KEY));
};

export const fetchEncryptionKey = (): Promise<PublicJWK> => {
  const encryptionKey = getEncryptionKey();
  if (encryptionKey) {
    return Promise.resolve(encryptionKey);
  }
  return fetchEncryptionKeyService().then((keyPayload) => {
    // RS256 is given so we have to change enc header to RSA-OAEP-256
    const modKey = { ...keyPayload.keys[0], alg: ALG_VALUE } as const;
    keyPayload.keys.push(modKey);
    return fetchJose().then((jose) =>
      jose.JWK.asKeyStore(keyPayload).then((result) => {
        // {result} is a jose.JWK.KeyStore
        const newEncryptionKey = result.get(modKey.kid, { kty: 'RSA', alg: ALG_VALUE });
        setEncryptionKey(newEncryptionKey);
        return Promise.resolve(newEncryptionKey);
      })
    );
  });
};

export type EncryptFunc = (content: string) => Promise<string>;

export const encrypt: EncryptFunc = (content) =>
  fetchJose().then(async (jose) => {
    const key = await fetchEncryptionKey();
    return jose.JWK.asKey(key).then((keyObj) =>
      jose.JWE.createEncrypt({ format: 'compact', contentAlg: 'A256CBC-HS512' }, keyObj)
        .update(content)
        .final()
        .then((result: string) => result)
    );
  });

type DecryptFunc = (message: string) => Promise<{
  payload: string;
}>;

export const decrypt: DecryptFunc = async (message) => {
  const jose = await fetchJose();
  const { keystore } = await jose.JWK.asKey(await worker.getKeyPair());
  return jose.JWE.createDecrypt(keystore).decrypt(message);
};
