import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import ButtonLink from '../../../../components/links/buttonLink';
import { StandardText } from '../../../../components/typography/typography';
import pxToRem from '../../../../utilities/pxToRem';
import type { StyleProps } from '../../../../utilities/types';
import FormWithImage from '../../../../components/formWithImage/formWithImage';
import { DepositInstructionsText } from '../../newAccountOpening.constants';
import { SynchronyInfo } from '../../../../components/cms/blockText.constants';
import getImageSrc from '../../../../utilities/getImageSrc';
import ImagesFileNames from '../../../../images';

const styles = {
  textSection: { marginBottom: pxToRem(30) },
  overrideCtaContainer: { marginTop: 0, marginBottom: pxToRem(10) },
  buttonContainer: { display: 'flex' },
} as const;

type Props = StyleProps;
export const ContactSupport = (props: Props) => {
  const { classes } = props;

  // Constructing Images URLs
  const rightPanelImageUrl = getImageSrc(ImagesFileNames.accountOpeningJpg);
  const rightPanelImageUrlLg = getImageSrc(ImagesFileNames.accountOpening_2xJpg);
  const rightPanelImageUrlXl = getImageSrc(ImagesFileNames.accountOpening_3xJpg);

  return (
    <Grid container className={classes.parentContainer} spacing={0} alignItems="stretch">
      <FormWithImage
        imageUrl={rightPanelImageUrl}
        imageUrlLg={rightPanelImageUrlLg}
        imageUrlXl={rightPanelImageUrlXl}
        classes={{ ctaContainer: classes.overrideCtaContainer }}
        ctaText="Unfortunately an error occurred"
        useLoadingAnimation={false}
      >
        <Grid item className={classes.textSection}>
          <StandardText>
            We are unable to process your request at this time. To continue, try again later or
            contact us at {SynchronyInfo.PHONE}
          </StandardText>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <ButtonLink to={DepositInstructionsText.AFF_CTA_URL}>
            Return to Accounts Dashboard
          </ButtonLink>
        </Grid>
      </FormWithImage>
    </Grid>
  );
};

const styledComponent = withStyles(styles)(ContactSupport);
export default styledComponent;
