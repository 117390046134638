import type { OverdraftAccount } from '../../containers/overdraftProtection/overdraftProtection.service';
import type { CdInformation, Product } from '../../utilities/types';

export type ProductDisplayType = 'HYS' | 'MMA' | 'CD' | 'IRA CD' | 'IRA MMA' | 'NONE';

export const AccountTypes = Object.freeze({
  CHECKING: 'Checking',
  SAVINGS: 'Savings',
  CD: 'Certificate of Deposit',
  NONE: 'NONE',
  '': '',
});

export type AccountDetailsType = typeof AccountTypes[keyof typeof AccountTypes];

export type Interest = {
  apy: string;
  paidYTD: string;
  rate: string;
};

export type InterestDistributionMethod =
  | 'Capitalize'
  | 'Internal transfer'
  | 'External transfer'
  | 'Pay by check';

export type InterestDistribution = {
  method: InterestDistributionMethod;
  isEditable?: boolean;
  transferAccount?: {
    accountDisplayType: ProductDisplayType;
    accountId?: string;
    accountLastFour: string;
    accountNickname: string | null | undefined;
    accountType: AccountDetailsType;
    externalId: string;
    routingNumber: string;
    bankName?: string;
  } | null;
};

export type Withdrawal = {
  allowed: string;
  count: string;
};

export type AccountDetails = {
  accountBeneficiaries: Array<string>;
  accountId: string;
  accountIdDisplay: string;
  accountOwners: Array<string>;
  cdInformation?: CdInformation;
  currentBalance: string;
  holdBalance: string;
  inGracePeriod: boolean;
  interest: Interest;
  interestDistribution: InterestDistribution;
  iraPlanType: string;
  isMaturing?: boolean;
  isRenewable?: boolean;
  maturityDate?: string;
  nextInterestPaymentDate?: string;
  nickname: string;
  openDate: Date;
  overdraft?: OverdraftAccount;
  routingNumber: string;
  term: string;
  withdrawal: Withdrawal;
  primaryOwner: boolean;
  renewalInstructionsApplied?: boolean;
  product: Product;
  type: AccountDetailsType;
  cdBumpUpDate?: string;
  isRateEditable?: boolean;
};

export type CDAccountDetails = AccountDetails & {
  type: 'Certificate of Deposit';
  cdInformation: CdInformation;
};

// Because TransactionType would somehow not be a specific enough name here...
export type CreditOrDebit = 'Credit' | 'Debit';

export type Transaction = {
  amount: string;
  checkNumber?: string;
  currentBalance?: string;
  date: string;
  description: Array<string>;
  estimatedDate?: string;
  interestPayment: boolean;
  pending: boolean;
  transactionType?: 'Overdraft';
  type: CreditOrDebit;
};

export default AccountTypes;
