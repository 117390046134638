import React from 'react';
import { withStyles, FormControl, StyleRules, InputLabel } from '@material-ui/core';
import Input from '@mui/material/Input';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import pxToRem from '../../utilities/pxToRem';
import type { StyleProps } from '../../utilities/types';
import colors from '../colors/colors';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const styles = (): StyleRules => ({
  root: {
    marginTop: '0 !important', // Use important to override material-ui styles
  },
  select: {
    paddingRight: `${pxToRem(20)} !important`,
    paddingLeft: pxToRem(16),
    marginTop: pxToRem(10),
    marginRight: pxToRem(16),
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  disabledSelectField: {
    paddingRight: `${pxToRem(10)} !important`,
  },
  label: {
    fontSize: pxToRem(17),
    lineHeight: 1.29,
    color: colors.charcoalGrey,
  },
  arrowSvgClass: {
    width: pxToRem(10),
    height: pxToRem(10),
    transform: 'rotate(90deg)',
    position: 'relative',
  },
});

const InputField = withStyles(() => ({
  root: {
    '&label.Mui-focused': {
      color: colors.charcoalGrey,
    },
    '&.MuiInput-underline': {
      borderBottomColor: colors.optimumBlue,
    },
    '&.MuiInput-underline:after': {
      borderBottomColor: colors.optimumBlue,
    },
  },
}))(Input);

type ResizableSelectProps = {
  id: string;
  children?: ReactNode;
  ['data-test']?: string;
  fullWidth?: boolean;
  inputProps?: { [key: string]: any };
  menuItemPaperClass?: string;
  arrowIconWrapperClass?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (arg1: SelectChangeEvent) => void;
  displayEmpty?: boolean;
  inputLabelText?: string;
};

export const ResizableSelect = (props: ResizableSelectProps & StyleProps) => {
  const {
    id,
    children,
    classes = {},
    'data-test': dataTest,
    fullWidth = true,
    inputProps,
    menuItemPaperClass,
    arrowIconWrapperClass,
    value,
    displayEmpty,
    inputLabelText,
    disabled,
    ...rest
  } = props;

  return (
    <FormControl fullWidth={fullWidth} disabled={disabled}>
      <InputLabel id="input-label" className={classes.label}>
        {inputLabelText}
      </InputLabel>
      <Select
        {...rest}
        disabled={disabled}
        input={<InputField id={id} />}
        displayEmpty={displayEmpty}
        className={classes.root}
        inputProps={inputProps}
        classes={{
          select: classnames(classes.select, { [classes.disabledSelectField]: disabled }),
        }}
        IconComponent={() =>
          disabled ? (
            <SVGImage className={classes.arrowSvgClass} imageName={ImagesFileNames.dateArrowSvg} />
          ) : (
            <SVGImage
              wrapperContainerClass={arrowIconWrapperClass}
              className={classes.arrowSvgClass}
              imageName={ImagesFileNames.arrowSvg}
            />
          )
        }
        data-test={dataTest}
        labelId="input-label"
        value={value}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          classes: { paper: menuItemPaperClass },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const styled = withStyles(styles)(ResizableSelect);
styled.displayName = 'ResizableSelect';
export default styled;
