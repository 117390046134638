import { replace } from 'connected-react-router';
import { formValueSelector } from 'redux-form';
import type { Dispatch, GetState, InitiateOTPForm, ValidateOTPForm } from '../../utilities/types';
import type {
  SecurityEligibilityService,
  SecurityEligibilityType,
  OTPChallengeService,
  OTPValidateService,
} from './security.service';
import {
  securityEligibilityService,
  challengeOTPService,
  validateOTPService,
  SecurityEligibilityTypes,
} from './security.service';
import { ACTION_FETCH_SECURITY_ELIGIBILITY_SUCCESS } from '../accountDashboard/profile.reducer';
import {
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import {
  clearFlashMessage,
  setFlashMessage,
} from '../../components/flashMessage/flashMessage.reducer';
import { setUpdatedAuthStateToken } from '../../utilities/authentication';
import Routes from '../routes/routes.constants';
import { getBlackBox } from '../authenticate/blackBox.service';
import {
  fetchModifyProfileTokenService,
  fetchNewAuthTokenService,
} from '../authenticate/authenticate.service';
import { ACTION_SET_NEEDS_SESSION_REFRESH } from '../authenticate/authenticate.reducer';
import {
  ANALYTICS_ELIGIBILITY_ERROR_AO,
  ANALYTICS_INITIATE_OTP_SERVER_ERROR,
  ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR,
  ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR,
  ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR,
} from '../../analytics/actions';
import pageTrack from '../../analytics/pageAnalytics.constants';
import { NAO_FORM_ID } from '../../utilities/accountOpeningFlowType';
import { PERSONAL_INFORMATION_SECTION_ID } from '../../form.constants';
import trimSuperfluousSpaces from '../../formatters/trimSuperfluousSpaces';
import type {
  AboutYouFields,
  AddressFields,
  EmailField,
} from '../newAccountOpening/applications.actions';
import { encrypt } from '../../utilities/crypt';

export const checkSecurityEligibilityAction =
  (
    securityEligibilityType: SecurityEligibilityType,
    service: SecurityEligibilityService = securityEligibilityService
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(clearFlashMessage());
    let eligibilityResponse;

    let pagefunction;
    if (securityEligibilityType === SecurityEligibilityTypes.CHANGE_USERNAME) {
      pagefunction = pageTrack.pagefunction.change_username;
    } else if (securityEligibilityType === SecurityEligibilityTypes.CHANGE_PASSWORD) {
      pagefunction = pageTrack.pagefunction.change_password;
    }

    let request = {
      type: securityEligibilityType,
      name: {},
      ssn: '',
      primaryAddress: {},
      emailAddress: '',
    };

    if (securityEligibilityType === SecurityEligibilityTypes.NEW_ACCOUNT_OPENING) {
      const reduxState = getState();
      const selector = formValueSelector(NAO_FORM_ID);

      const {
        firstName,
        middleName,
        lastName,
        socialSecurityNumber,
        streetAddress1,
        city,
        state,
        zipCode,
        email,
      }: AboutYouFields & AddressFields & EmailField = selector(
        reduxState,
        PERSONAL_INFORMATION_SECTION_ID
      );
      request = {
        ...request,
        name: {
          first: trimSuperfluousSpaces(firstName),
          middle: trimSuperfluousSpaces(middleName),
          last: trimSuperfluousSpaces(lastName),
        },
        ssn: await encrypt(socialSecurityNumber),
        primaryAddress: {
          line1: trimSuperfluousSpaces(streetAddress1),
          city: trimSuperfluousSpaces(city),
          state,
          zipCode,
        },
        emailAddress: email,
      };
    }

    try {
      eligibilityResponse = await service(request);
    } catch (err) {
      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText: (err && err.data && err.data.message) || FlashMessageText.GENERIC_ERROR,
        })
      );
      if (securityEligibilityType === SecurityEligibilityTypes.NEW_ACCOUNT_OPENING) {
        dispatch({ type: ANALYTICS_ELIGIBILITY_ERROR_AO });
      }
      if (pagefunction) {
        dispatch({ type: ANALYTICS_SECURITY_ELIGIBILITY_SERVER_ERROR, payload: pagefunction });
      }
    }
    if (eligibilityResponse) {
      dispatch({ type: ACTION_FETCH_SECURITY_ELIGIBILITY_SUCCESS, payload: eligibilityResponse });
      setUpdatedAuthStateToken(eligibilityResponse.state_token);
    }
    return eligibilityResponse && eligibilityResponse.flow;
  };

export const initiateOTP =
  (initiateOTPForm: InitiateOTPForm, service: OTPChallengeService = challengeOTPService) =>
  async (dispatch: Dispatch) => {
    let challengeResponse;
    try {
      challengeResponse = await service(initiateOTPForm);
    } catch (err) {
      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText: (err && err.data && err.data.message) || FlashMessageText.GENERIC_ERROR,
        })
      );

      if (
        err &&
        err.data &&
        err.data.error_uri === 'urn://synchronybank.com/unconfirmed_phone_number'
      ) {
        dispatch({ type: ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR });
        return {};
      }
      if (
        err &&
        err.data &&
        err.data.error_uri === 'urn://synchronybank.com/confirmation_attempts_exceeded'
      ) {
        dispatch({ type: ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR });
        return {};
      }
      dispatch({ type: ANALYTICS_INITIATE_OTP_SERVER_ERROR });
      return {};
    }
    dispatch(replace(Routes.OTP_VALIDATE));
    return challengeResponse;
  };

export const validateOTP =
  (validateOTPForm: ValidateOTPForm, service: OTPValidateService = validateOTPService) =>
  async () => {
    const request = {
      ...validateOTPForm,
      blackbox: getBlackBox(),
    };
    return service(request);
  };

export const fetchModifyProfileTokenAction =
  (service: () => Promise<void> = fetchModifyProfileTokenService) =>
  async (dispatch: Dispatch) => {
    try {
      await service();
    } catch (err) {
      const { data = {} } = err || {};
      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText: data.message || FlashMessageText.GENERIC_ERROR,
        })
      );
      // Rethrow to be caught in submit function
      throw err;
    }
  };

export const fetchNewAuthTokenAction =
  (hasSubmittedUsername?: boolean) => async (dispatch: Dispatch) => {
    try {
      await fetchNewAuthTokenService();
    } catch (err) {
      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText: FlashMessageText.GENERIC_ERROR,
        })
      );
      // Rethrow to allow container to handle its behavior based on whether call succeeds or fails
      throw err;
    }
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.SUCCESS,
        messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
      })
    );
    if (hasSubmittedUsername) {
      dispatch({ type: ACTION_SET_NEEDS_SESSION_REFRESH, payload: true });
    }
  };
