import type {
  ExternalAccount,
  ValidationStatusMessageKey,
  VerificationStatusMessageKey,
} from 'Domain/Account/ExternalAccount';
import type { ServiceError } from '../../utilities/types';

export const ACTION_FETCH_EXTERNAL_ACCOUNTS_SUCCESS = 'ACTION_FETCH_EXTERNAL_ACCOUNTS_SUCCESS';
export const ACTION_FETCH_EXTERNAL_ACCOUNTS_REQUEST = 'ACTION_FETCH_EXTERNAL_ACCOUNTS_REQUEST';
export const ACTION_FETCH_EXTERNAL_ACCOUNTS_FAILURE = 'ACTION_FETCH_EXTERNAL_ACCOUNTS_FAILURE';
export const ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS = 'ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS';
export const ACTION_DELETE_EXTERNAL_ACCOUNT_FAILURE = 'ACTION_DELETE_EXTERNAL_ACCOUNT_FAILURE';
export const ACTION_DELETE_EXTERNAL_ACCOUNT_REQUEST = 'ACTION_DELETE_EXTERNAL_ACCOUNT_REQUEST';
export const ACTION_SET_FETCH_ATTEMPT = 'ACTION_SET_FETCH_ATTEMPT';
export const ACTION_CLEAR_EXTERNAL_ACCOUNT_NICKNAME_ERRORS =
  'ACTION_CLEAR_EXTERNAL_ACCOUNT_NICKNAME_ERRORS';
export const ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_SUCCESS =
  'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_SUCCESS';
export const ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_REQUEST =
  'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_REQUEST';
export const ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_FAILURE =
  'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_FAILURE';
export const ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_LOADING_FALSE =
  'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_LOADING_FALSE';

export type VerificationStatusMessages = Partial<Record<VerificationStatusMessageKey, string>>;

export type ValidationStatusMessages = Partial<Record<ValidationStatusMessageKey, string>>;

export type ExternalAccountState = {
  accounts: Array<ExternalAccount>;
  isLoading: boolean;
  nickNameIsLoading: boolean;
  isRemoving: boolean;
  error?: ServiceError;
};

const getInitialState = (): ExternalAccountState => ({
  accounts: [],
  nickNameIsLoading: false,
  isLoading: false,
  isRemoving: false,
});

export type FetchSuccessType = {
  type: 'ACTION_FETCH_EXTERNAL_ACCOUNTS_SUCCESS';
  payload: ExternalAccount[];
};

export type FetchFailedType = {
  errText: string | null | undefined;
};

type ReinitiateTrialDepositsPayload = {
  success?: boolean;
  message: string;
  attemptsRemaining?: number;
  externalId: string;
};

export type ExternalAccountAction =
  | FetchSuccessType
  | {
      type: 'ACTION_FETCH_EXTERNAL_ACCOUNTS_REQUEST';
    }
  | {
      type: 'ACTION_FETCH_EXTERNAL_ACCOUNTS_FAILURE';
      payload: FetchFailedType;
    }
  | {
      type: 'ACTION_DELETE_EXTERNAL_ACCOUNT_REQUEST';
    }
  | {
      type: 'ACTION_DELETE_EXTERNAL_ACCOUNT_FAILURE';
      payload: string | null | undefined;
    }
  | {
      type: 'ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS';
      payload: {
        accountId: string;
      };
    }
  | {
      type: 'ACTION_SET_FETCH_ATTEMPT';
      payload: number;
    }
  | {
      type: 'ACTION_REINITIATE_TRIAL_DEPOSITS_SUCCESS';
      payload: ReinitiateTrialDepositsPayload;
    }
  | {
      type: 'ACTION_REINITIATE_TRIAL_DEPOSITS_REQUEST';
    }
  | {
      type: 'ACTION_REINITIATE_TRIAL_DEPOSITS_FAILURE';
    }
  | {
      type: 'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_FAILURE';
      payload: {
        accountId: string;
      };
    }
  | {
      type: typeof ACTION_CLEAR_EXTERNAL_ACCOUNT_NICKNAME_ERRORS;
    }
  | {
      type: 'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_REQUEST';
    }
  | {
      type: 'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_LOADING_FALSE';
    }
  | {
      type: 'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_SUCCESS';
      payload: {
        nickname: string;
        accountId: string;
      };
    };

const clearEditNicknameErrors = (accounts: Array<ExternalAccount>) =>
  accounts.map((account) => ({ ...account, editNicknameError: false }));

export default (
  state: ExternalAccountState | null | undefined = getInitialState(),
  action: ExternalAccountAction = undefined
): ExternalAccountState => {
  switch (action.type) {
    case 'ACTION_FETCH_EXTERNAL_ACCOUNTS_REQUEST':
      return {
        ...getInitialState(),
        isLoading: true,
      };
    case 'ACTION_FETCH_EXTERNAL_ACCOUNTS_SUCCESS':
      return {
        ...state,
        accounts: action.payload || [],
        isLoading: false,
      };
    case 'ACTION_FETCH_EXTERNAL_ACCOUNTS_FAILURE':
      return {
        ...state,
        accounts: [],
        isLoading: false,
        error: { message: action.payload.errText || '' },
      };
    case 'ACTION_DELETE_EXTERNAL_ACCOUNT_REQUEST':
      return {
        ...state,
        isRemoving: true,
      };
    case 'ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS': {
      const { accountId } = action.payload;
      return {
        ...state,
        isRemoving: false,
        accounts: state.accounts.filter((account) => accountId !== account.externalId),
      };
    }
    case 'ACTION_DELETE_EXTERNAL_ACCOUNT_FAILURE':
      return {
        ...state,
        isRemoving: false,
      };

    case 'ACTION_REINITIATE_TRIAL_DEPOSITS_REQUEST': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'ACTION_REINITIATE_TRIAL_DEPOSITS_FAILURE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'ACTION_REINITIATE_TRIAL_DEPOSITS_SUCCESS': {
      let accounts = JSON.parse(JSON.stringify(state.accounts));
      accounts = accounts.map((account) => {
        if (action.payload && action.payload.externalId === account.externalId) {
          return {
            ...account,
            verificationStatus: 'TRIAL_DEPOSIT_PENDING',
            verificationStatusMessage: null,
          };
        }
        return account;
      });
      return {
        ...state,
        isLoading: false,
        accounts,
      };
    }
    case ACTION_CLEAR_EXTERNAL_ACCOUNT_NICKNAME_ERRORS:
      return {
        ...state,
        accounts: clearEditNicknameErrors(state.accounts),
      };
    case ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_REQUEST:
      return {
        ...state,
        nickNameIsLoading: true,
        accounts: clearEditNicknameErrors(state.accounts),
      };
    case 'ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_LOADING_FALSE':
      return {
        ...state,
        nickNameIsLoading: false,
      };
    case ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_SUCCESS:
      return {
        ...state,
        nickNameIsLoading: false,
        accounts: state.accounts.map((account) => {
          if (action.payload.accountId === account.externalId) {
            return {
              ...account,
              nickName: action.payload.nickname,
            };
          }
          return account;
        }),
      };
    case ACTION_MODIFY_EXTERNAL_ACCOUNT_NICKNAME_FAILURE:
      return {
        ...state,
        nickNameIsLoading: false,
        accounts: state.accounts.map((account) => {
          if (action.payload.accountId === account.externalId) {
            return {
              ...account,
              editNicknameError: true,
            };
          }
          return account;
        }),
      };
    default:
      return state;
  }
};
