import type { ProductTerm, RateMatrix } from '../containers/products/products.reducer';
import axios from '../utilities/axios';
import { PRODUCTS_URL } from '../utilities/route-mappings';
import fetchRateMatrices from './rateMatrices';
import type { RateMatricesRequestParams } from './rateMatrices';

export type ProductTypesResponse = {
  affinity: boolean;
  productTypes: ProductTerm;
  validAffinityCompanyCode: boolean;
  validPromotionCode?: boolean;
  rateMatrices?: Array<RateMatrix>;
};

export type ProductTypesRequestParams = {
  affinityCompanyCode?: string;
  includePromotionCode?: string;
  displayCode?: string;
  accountType?: string;
  accountId?: string;
};
export type ProductTypesService = (
  params: ProductTypesRequestParams
) => Promise<ProductTypesResponse>;

// eslint-disable-next-line import/prefer-default-export
export const fetchProductTypes: ProductTypesService = async (params) => {
  const productResponse = await axios.get(PRODUCTS_URL(), {
    params: {
      affinityCompanyCode: params.affinityCompanyCode,
      displayCode: params.displayCode,
      includePromotionCode: params.includePromotionCode,
    },
  });
  let rateMatrices;
  if (params.accountId && params.accountType) {
    const matricesParams: RateMatricesRequestParams = {
      accountId: params.accountId || '',
      accountType: params.accountType || '',
    };
    const rateMatricesResponse = await fetchRateMatrices(matricesParams);
    rateMatrices = rateMatricesResponse.rateMatrices;
  }
  return {
    ...productResponse.data,
    rateMatrices,
  };
};
