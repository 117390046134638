import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import classNames from 'classnames';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import type { ReduxState } from '../../reducers';
import {
  setFlashMessage,
  clearFlashMessage as clearFlashMessageAction,
} from '../../components/flashMessage/flashMessage.reducer';
import { usePreferencesContainerStyles } from './ePreferences.styles';
import {
  Header2,
  StandardTextEmphasis,
  SubtitleText,
} from '../../components/typography/typography';
import pxToRem from '../../utilities/pxToRem';
import AccountManagementPage from '../../components/accountManagementPage/accountManagementPage';
import ArrowLink from '../../components/links/arrowLink';
import Routes from '../routes/routes.constants';
import {
  PreferencesTitle,
  PreferencesOldTitle,
  ETaxDocumentPreferencesConstant,
  TaxDocumentPreferenceTitle,
  UpdatedPreferencesNotificationStatement,
  ePreferencesFeatureFlag,
} from './ePreferences.constants';
import type { EPreferencesInfo } from './ePreferences.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import { EPreferencesBtn } from '../../components/cms/buttonText.constants';
import type { CustomerInfo } from '../../utilities/types';
import { fetchEPreferences } from './ePreferences.service';
import { updateFormStatus } from '../profile/profile.actions';
import { useEffectOnce } from '../../utilities/reactHooks';
import Error from '../../components/error/error';
import { isSessionExpired } from '../activityTimer/activityTimer';
import { fetchCustomerInfo as fetchCustomerInfoAction } from '../newAccountOpening/applications.actions';
import {
  FlashMessageVariant,
  FlashMessageText,
} from '../../components/flashMessage/flashMessage.constants';
import {
  ANALYTICS_EPREFERENCES_START_SUCCESS,
  ANALYTICS_EPREFERENCES_START_FAILURE,
  ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS,
  ANALYTICS_EPREFERENCES_SUBMIT_FAILURE,
} from '../../analytics/actions';
import RenderEmailOrNoEmail from '../../components/email/renderEmailOrNoEmail';
import MarketingPreference from '../marketingAlerts/marketingAlerts.container';
import EPreferencesTable from './ePreferencesTable';
import TaxDocumentPreference from '../taxDocumentPreference/taxDocumentPreference.container';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';

type StateProps = {
  customerInfo?: CustomerInfo;
  hasSessionExpired: boolean;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  customerInfo: state.applications.customerInfo,
  hasSessionExpired: isSessionExpired(state),
});

const pageViewActions = Object.freeze({
  startSuccess: ANALYTICS_EPREFERENCES_START_SUCCESS,
  startFailure: ANALYTICS_EPREFERENCES_START_FAILURE,
  submitSuccess: ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS,
  submitFailure: ANALYTICS_EPREFERENCES_SUBMIT_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type DispatchProps = {
  fetchCustomerInfo: () => Promise<void>;
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) => void;
  setErrorFlashMessage: (message?: string) => void;
  setSuccessFlashMessage: () => void;
  updateFormDirtyStatus: (arg1?: boolean | null | undefined) => void;
  clearFlashMessage: () => void;
};

type EPreferenceHeaderSectionProps = {
  classes: ClassNameMap;
  preferencesEmail: string;
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<null, null, AnyAction>
): DispatchProps => ({
  fetchCustomerInfo: async () => {
    dispatch(fetchCustomerInfoAction());
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) =>
    dispatch({ type: pageViewAction, payload }),
  setErrorFlashMessage: (message) =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText: message || FlashMessageText.GENERIC_ERROR,
      })
    ),
  setSuccessFlashMessage: () =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.SUCCESS,
        messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
      })
    ),
  updateFormDirtyStatus: (isDirty) => dispatch(updateFormStatus(!!isDirty)),
  clearFlashMessage: () => dispatch(clearFlashMessageAction()),
});
const checkLoading = (isLoading: boolean, isCustomerLoading: boolean) => {
  return isLoading || isCustomerLoading;
};

const checkError = (hasError: boolean, hasCustomerError: boolean) => {
  return hasError || hasCustomerError;
};

const getPreferencesEmail = (customerInfo: CustomerInfo) => {
  return (
    customerInfo &&
    customerInfo.emailAddresses &&
    customerInfo.emailAddresses[0] &&
    customerInfo.emailAddresses[0].email
  );
};

const getEligibleAccounts = (ePreferences: EPreferencesInfo) => {
  return (
    ePreferences &&
    ePreferences.statementPreferences &&
    ePreferences.statementPreferences.eligibleAccounts
  );
};

const getPreferencesEmailData = (preferencesEmail?: string) => {
  return preferencesEmail ? 'email-ePref' : 'no-email-ePref';
};

const ePreferenceNotificationTooltip = (classes: ClassNameMap) => {
  return (
    <Grid item xs={12} className={classes.ePreferenceNotification}>
      <SVGImage
        imageName={ImagesFileNames.iconAlertDisabledSvg}
        ariaHidden="true"
        className={classes.notificationIcon}
      />
      <Grid
        item
        xs={12}
        sm={!ePreferencesFeatureFlag ? 10 : 12}
        md={!ePreferencesFeatureFlag ? 9 : 12}
      >
        <SubtitleText data-test="ePreferences-notification-statement">
          {UpdatedPreferencesNotificationStatement}
        </SubtitleText>
      </Grid>
    </Grid>
  );
};

const EPreferenceOldHeaderSection = ({
  classes,
  preferencesEmail,
}: EPreferenceHeaderSectionProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Header2 paddingBottom={pxToRem(8)} data-test="ePreferences-header">
          {PreferencesOldTitle}
        </Header2>
      </Grid>
      <Grid item xs={12} md={11} className={classes.ePreferencesSubTitle}>
        <p data-test="ePreferences-description" className={classes.ePreferencesSubTitleText}>
          {ETaxDocumentPreferencesConstant.PAGE_SUB_TITLE}
        </p>
      </Grid>
      <Grid item xs={12}>
        <RenderEmailOrNoEmail
          dataTest={getPreferencesEmailData(preferencesEmail)}
          emailAddress={preferencesEmail}
        />
      </Grid>
      {ePreferenceNotificationTooltip(classes)}
      <Grid item xs={12} md={11}>
        <p data-test="ePreferences-tax-documents" className={classes.ePreferencesTaxDocuments}>
          <StandardTextEmphasis>
            {ETaxDocumentPreferencesConstant.TAX_PREFERENCE_TITLE}
          </StandardTextEmphasis>
          {ETaxDocumentPreferencesConstant.TAX_PREFERENCE_SUB_TITLE}
        </p>
      </Grid>
      <Grid item xs={12}>
        <ArrowLink
          to={Routes.DOCUMENTS}
          data-test="quick-links-tax-documents"
          className={classes.quickLinksActionLink}
          role="link"
          data-track-page={clickTrack.ePreferences.tax_documents}
          data-track-title={clickTrack.ePreferences.tax_documents}
          data-track-type={clickTrackType.LINK}
        >
          {EPreferencesBtn.MANAGE_TAX_DOCUMENT}
        </ArrowLink>
      </Grid>
    </>
  );
};

const ePreferencesHeaderSection = (classes: ClassNameMap, preferencesEmail: string) => {
  return !ePreferencesFeatureFlag ? (
    <EPreferenceOldHeaderSection classes={classes} preferencesEmail={preferencesEmail} />
  ) : (
    <>
      <Grid item xs={12}>
        <Header2 paddingBottom={pxToRem(8)} data-test="ePreferences-header">
          {PreferencesTitle}
        </Header2>
      </Grid>
      <Grid item xs={12} className={classes.ePreferencesSubTitle}>
        <p data-test="ePreferences-description">{ETaxDocumentPreferencesConstant.PAGE_SUB_TITLE}</p>
      </Grid>
      {ePreferenceNotificationTooltip(classes)}
      <Grid item xs={12} className={classes.emailPreferencesDetail}>
        <RenderEmailOrNoEmail
          dataTest={getPreferencesEmailData(preferencesEmail)}
          emailAddress={preferencesEmail}
        />
      </Grid>
    </>
  );
};

type AllProps = StateProps & DispatchProps;

export const Preferences = ({
  customerInfo,
  fetchCustomerInfo,
  hasSessionExpired,
  recordAnalyticsPageView,
  setErrorFlashMessage,
  setSuccessFlashMessage,
  updateFormDirtyStatus,
  clearFlashMessage,
}: AllProps) => {
  const [ePreferences, setEPreferences] = useState<EPreferencesInfo | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasCustomerError, setHasCustomerError] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const getEPreferences = async () => {
    if (ePreferences) {
      return true;
    }
    let ePreferencesData;
    setIsLoading(true);
    setHasError(false);

    try {
      ePreferencesData = await fetchEPreferences();
    } catch {
      setHasError(true);

      return false;
    } finally {
      setIsLoading(false);
    }
    setEPreferences(ePreferencesData);

    return true;
  };

  const refetchCustomerInfo = async () => {
    if (customerInfo) {
      return true;
    }
    setIsCustomerLoading(true);
    setHasCustomerError(false);
    try {
      await fetchCustomerInfo();
    } catch (err) {
      setHasCustomerError(true);
      return false;
    } finally {
      setIsCustomerLoading(false);
    }
    return true;
  };

  const getInitialData = async () => {
    const [successfulRequest, successfulCustomerRequest] = await Promise.all([
      getEPreferences(),
      refetchCustomerInfo(),
    ]);

    recordAnalyticsPageView(
      successfulRequest && successfulCustomerRequest
        ? pageViewActions.startSuccess
        : pageViewActions.startFailure
    );
  };

  useEffectOnce(() => {
    // Necessary because react doesn't like useEffect returning a Promise
    getInitialData();
  });

  const preferencesEmail = getPreferencesEmail(customerInfo);
  const classes = usePreferencesContainerStyles();
  const eligibleAccounts = getEligibleAccounts(ePreferences);

  return (
    <AccountManagementPage
      pageHeading={PreferencesTitle}
      loading={checkLoading(isLoading, isCustomerLoading)}
      focusFlashMessage
    >
      {checkError(hasError, hasCustomerError) ? (
        <Error onClick={getInitialData} center />
      ) : (
        <Grid container direction="column" alignItems="center">
          <Grid
            className={classNames({
              [classes.containerNewDesign]: ePreferencesFeatureFlag,
              [classes.container]: !ePreferencesFeatureFlag,
            })}
          >
            <Grid
              container
              className={classNames({
                [classes.tileItem]: ePreferencesFeatureFlag,
              })}
              spacing={3}
            >
              {ePreferencesHeaderSection(classes, preferencesEmail)}
              {preferencesEmail && (
                <EPreferencesTable
                  eligibleAccounts={eligibleAccounts}
                  classes={classes}
                  updateFormDirtyStatus={updateFormDirtyStatus}
                  showConfirmationModal={showConfirmationModal}
                  hasSessionExpired={hasSessionExpired}
                  clearFlashMessage={clearFlashMessage}
                  setSuccessFlashMessage={setSuccessFlashMessage}
                  recordAnalyticsPageView={recordAnalyticsPageView}
                  ePreferences={ePreferences}
                  setEPreferences={setEPreferences}
                  setShowConfirmationModal={setShowConfirmationModal}
                  setErrorFlashMessage={setErrorFlashMessage}
                />
              )}
            </Grid>
          </Grid>
          {ePreferencesFeatureFlag && (
            <TaxDocumentPreference
              classes={classes}
              ETaxDocumentPreferencesConstant={ETaxDocumentPreferencesConstant}
              TaxDocumentPreferenceTitle={TaxDocumentPreferenceTitle}
              EPreferencesBtn={EPreferencesBtn}
            />
          )}
          {window.__config__.IGNITE_OFFERS_ENABLED === 'true' && (
            <MarketingPreference ePreferenceStyle={classes} />
          )}
        </Grid>
      )}
    </AccountManagementPage>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
