import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import Button from 'bank-component-library/ui/atoms/Button';
import { FeatureFlag, FeatureFlags } from '../../utilities/isFeatureFlagEnabled';
import useEffectOnce from '../../utilities/reactHooks';
import useStyles from './ControlPanel.styles';
import i18n from '../../strings/i18n';

type FeatureFlagValue = 'true' | 'false';

const getFeatureFlags = (): FeatureFlags => {
  let flags: Partial<FeatureFlags> = {};

  Object.entries(window.__config__).forEach(([key, value]: [FeatureFlag, FeatureFlagValue]) => {
    // only get the boolean feature flags
    if (value === 'true' || value === 'false') {
      if (sessionStorage.getItem(key) === null) {
        flags = { ...flags, [key]: value };
      } else {
        flags = { ...flags, [key]: sessionStorage.getItem(key) };
      }
    }
  });
  return flags as FeatureFlags;
};

const FeatureFlagPanel = () => {
  const [flags, setFlags] = useState<FeatureFlags>(getFeatureFlags());
  const classes = useStyles();

  const enabledFlags: FeatureFlag[] = ['EPREFERENCES_NEW_DESIGN_ENABLED', 'TRANSMIT_ENABLED'];

  const initializeSessionFlags = () => {
    for (const [key, value] of Object.entries(flags)) {
      if (sessionStorage.getItem(key) === null) {
        sessionStorage.setItem(key, value);
      }
    }
  };
  useEffectOnce(() => {
    initializeSessionFlags();
  });

  const updateFeatureFlag = (flagName: string, value: string) => {
    const newValue = value === 'true' ? 'false' : 'true';
    sessionStorage.setItem(flagName, newValue);
    setFlags({ ...flags, [flagName]: newValue });
  };

  const resetFeatureFlags = () => {
    for (const [key] of Object.entries(flags)) {
      sessionStorage.removeItem(key);
    }

    initializeSessionFlags();
    setFlags(getFeatureFlags());
  };
  return (
    <>
      {Object.entries(flags).map(([key]: [FeatureFlag, FeatureFlagValue]) => {
        return (
          <FormControlLabel
            className={classes.flagLabel}
            key={key}
            control={
              <Switch
                checked={flags[key] === 'true'}
                onChange={() => updateFeatureFlag(key, flags[key])}
                name={key}
              />
            }
            label={key}
            disabled={!enabledFlags.includes(key)}
          />
        );
      })}
      <div className={classes.resetFlagButton}>
        <Button size="large" type="button" onClick={resetFeatureFlags}>
          {i18n({ actions: 'resetAllFeatureFlags' })}
        </Button>
      </div>
    </>
  );
};

export default FeatureFlagPanel;
