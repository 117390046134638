import { withStyles } from '@material-ui/core';
import type { ReactNode } from 'react';
import React from 'react';
import type { StyleProps } from '../../utilities/types';
import styles from './tile.styles';

type TileProps = {
  children: ReactNode;
};

const TileUnstyled = ({ classes = {}, children }: TileProps & StyleProps) => (
  <tr className={classes.tile} role="row">
    {children}
  </tr>
);

export const Tile = withStyles(styles)(TileUnstyled);

export default Tile;
