import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import { useActivityTileStyles } from '../../components/activityTile/activityTile.styles';
import { BodyTextMedium } from '../../components/typography/typography';
import type { MessageThread } from './viewInbox.constants';
import colors from '../../components/colors/colors';
import { messageCenterHelpers } from '../routes/routes.constants';
import { useViewInboxContainerStyles } from './viewInbox.styles';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';

type Props = {
  messageThread: MessageThread;
};

const MessagesTileListItem = (props: Props) => {
  const { messageThread } = props;
  const activityTileClasses = useActivityTileStyles();
  const inboxContainerStyles = useViewInboxContainerStyles();

  const isUnreadMessage = messageThread.latestMessage.status === 'Unread';

  return (
    <Grid
      key={messageThread.threadId}
      component={Link}
      to={messageCenterHelpers.buildMessageCenterReplyUrl(messageThread.threadId)}
      container
      className={classnames(
        inboxContainerStyles.itemContainer,
        inboxContainerStyles.inboxRowContainer,
        isUnreadMessage ? inboxContainerStyles.inboxUnreadRowContainer : undefined
      )}
      data-track-title={clickTrack.viewInbox.compose_reply}
      data-track-type={clickTrackType.LINK}
    >
      <Grid
        xs={4}
        sm={3}
        item
        data-test="message-date-container"
        className={activityTileClasses.item}
      >
        <BodyTextMedium
          className={inboxContainerStyles.inboxCellLinkContainer}
          fontWeight={isUnreadMessage ? 'bold' : 'normal'}
          fontStyle={isUnreadMessage ? 'initial' : 'italic'}
        >
          {isUnreadMessage ? (
            <SVGImage
              imageName={ImagesFileNames.filledCircleSvg}
              fill={colors.synchronyGold}
              className={inboxContainerStyles.inboxUnreadIcon}
              data-test="unread-icon"
            />
          ) : undefined}
          {moment(messageThread.latestMessage.date).format('ll')}
        </BodyTextMedium>
      </Grid>

      <Hidden xsDown>
        <Grid xs={9} item data-test="message-topic-container" className={activityTileClasses.item}>
          <BodyTextMedium
            className={inboxContainerStyles.inboxCellLinkContainer}
            fontWeight={isUnreadMessage ? 'bold' : 'normal'}
            fontStyle={isUnreadMessage ? 'initial' : 'italic'}
          >
            {messageThread.topic}
          </BodyTextMedium>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12} data-test="message-topic-container" className={activityTileClasses.item}>
          <BodyTextMedium
            className={inboxContainerStyles.inboxCellLinkContainer}
            fontWeight={isUnreadMessage ? 'bold' : 'normal'}
            fontStyle={isUnreadMessage ? 'initial' : 'italic'}
          >
            {messageThread.topic}
          </BodyTextMedium>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default MessagesTileListItem;
