import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import Colors from '../colors/colors';

export default (theme: Theme): StyleRules => ({
  openAccountHeaderLink: {
    background: 'none',
    border: 'none',
    textDecoration: 'none',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: `${pxToRem(10)} ${pxToRem(15)}`,
    color: Colors.optimumBlue,
    '& :first-child': {
      paddingRight: pxToRem(6),
      boxSizing: 'content-box',
      marginRight: pxToRem(2),
    },
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
    '&:hover > *': {
      color: Colors.hoverState,
      fontWeight: 700,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${pxToRem(0)} ${pxToRem(17)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${pxToRem(0)} ${pxToRem(24)}`,
    },
  },
});
