import { v4 as uuidv4 } from 'uuid';
import { HeaderRequest } from '../containers/newAccountOpening/newAccountOpening.constants';

export const v2HeaderData = () => ({
  headers: {
    'X-SYF-Api-Key': window.__config__.API_CLIENT_ID,
    'X-SYF-Request-CorrelationId': uuidv4(),
  },
});

export const v2HeaderDataApplication = () => ({
  headers: {
    'X-SYF-Api-Key': window.__config__.API_CLIENT_ID,
    'X-SYF-Request-CorrelationId': uuidv4(),
    'X-SYF-Request-TrackingId': uuidv4(),
  },
});

export const v2HeaderExternalAccount = () => ({
  headers: {
    'X-API-VERSION': 'v2.0',
  },
});

export default () => {
  return {
    headers: {
      'X-SYF-Request-TrackingId': uuidv4(),
      'X-SYF-Request-CorrelationId': uuidv4(),
      'X-SYF-Request-ChannelId': HeaderRequest.channelId,
    },
  };
};

export const headerDataWithFleKey = (fleKeyHeader) => {
  return {
    headers: {
      'X-SYF-Request-TrackingId': uuidv4(),
      'X-SYF-Request-CorrelationId': uuidv4(),
      'X-SYF-Request-ChannelId': HeaderRequest.channelId,
      ...fleKeyHeader,
    },
  };
};
