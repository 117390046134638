import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/atoms/logo.styles';
import { SynchronyInfo } from '../cms/blockText.constants';
import type { StyleProps } from '../../utilities/types';
import Routes from '../../containers/routes/routes.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';

type Props = {
  isDark?: boolean;
  onMouseEnter?: any;
  className?: string;
  height?: string;
  isWithFdicLogo?: boolean;
};

export const Logo = (props: Props & StyleProps) => {
  const { isDark = true, classes = {}, onMouseEnter, className, height, isWithFdicLogo } = props;

  const logoDark = getImageSrc(ImagesFileNames.syfBlackLogoSvg);
  const logo = getImageSrc(ImagesFileNames.syfWhiteLogoSvg);

  const logoSrc = isDark ? logoDark : logo;
  const displayFit = isWithFdicLogo ? { width: 'fit-content' } : '';
  return (
    <div itemScope itemType="http://schema.org/Corporation" style={{ ...displayFit }}>
      <Link
        to={Routes.DASHBOARD}
        className={classNames(classes.logoLink, className)}
        data-test="synchrony-logo"
        data-track-title={clickTrack.header.icon}
        data-track-type={clickTrackType.ICON}
        title={SynchronyInfo.BANKNAME}
        onMouseEnter={onMouseEnter}
        itemProp="url"
      >
        <img
          src={logoSrc}
          alt="Synchrony Bank Logo"
          className={classNames(classes.logo)}
          height={height || '38px'}
          itemProp="logo"
        />
      </Link>
    </div>
  );
};

const styled = withStyles(styles)(Logo);
styled.displayName = 'LogoStyled';
export default styled;
