// TODO: consolidate duplicated colors and minimize colors throughout the site
export default {
  white: '#FFF',
  bannerTextWhite: '#fbfbfb',
  loadingOverlayColor: 'rgba(255,255,255, 0.94)',
  blackTransparent5: 'rgba(0, 0, 0, 0.05)',
  backgroundGrey: '#F6F6F6',
  sidePanelBackgroundGrey: '#F6F6F6',
  lightGray: '#E9EAE8',
  divider: '#E9EAE8',
  dividerLine: '#d8d8d8',
  loadingBorderColor: 'rgba(233,234,232, 0.4)',
  steelGrey: '#868A8E',
  disabledState: '#868A8E',
  buttonDisabledState: '#dcdce0',
  overlayBackground: 'rgba(148,150,154, 0.5)',
  imageBackground: '#DDDDDD',
  footerGray: '#4f5059',
  charcoalGrey: '#3B3C43',
  lineBreakGrey: '#DCDCE2',
  text: '#3B3C43',
  buttonTextDisabled: '#aeb0b7',
  vulcan: '#3B3C43',
  textFlash: '#333740',
  normalHelperText: '#333740',
  labelText: '#333740',
  placeholderText: '#333740',
  red: '#FF0000', // equivalent to 'red'
  lightRed: '#EAC0BC',
  errorFlash: '#EAC0BC',
  darkYellow: '#c79d00',
  lightOrange: '#F3D4B5',
  pinkLady: '#F3D3B3',
  cdActionFlash: '#F3D3B3',
  lightGreen: '#D8E7D4',
  successFlash: '#D8E7D4',
  lightTurquoise: '#C3D5D7',
  nonDashboardLinkFlash: '#C3D5D7',
  lightBlue: '#B2D1E0',
  dashboardFlash: '#B2D1E0',
  dashboardFlashBlue: '#e8f0f4',
  dashboardFlashOrange: '#FAEDE1',
  brick: '#C63527',
  inlineError: '#C63527',
  autumn: '#D76B00',
  cd: '#D76B00',
  synchronyGold: '#FBC600',
  synchronyCachedWarning: '#fef5d7',
  lightSkyBlue: '#f3f8fa',
  green: '#3E8529',
  turquoise: '#39757A',
  barneyPurple: '#5A397A',
  optimumBlue: '#006899',
  dimOptimumBlue: '#bdd7e4',
  clickable: '#006899',
  marineBlue: '#01426a',
  darkBlue: '#00415f',
  hoverState: '#01426A',
  darkNavyBlue: '#003256',
  lineBreak: '#979797',
  rewardsGold: '#AC8222',
  rewardsPlatinum: '#535F65',
  rewardsDiamond: '#58A7AF',
  lightBlack: '#333641',
  expansionPanelGey: '#f4f4f4',
  lightGrey: '#94969a',
  transmitBodyText: '#333333',
  transmitBodylink: '#34657F',
  transmitPrimaryBtnText: '#FFFFFF',
  transmitFooterText: '#000',

  /*
   * New colors & format
   * 100 - 500 are for black/dark backgrounds
   * 600 - 900 are for white/light backgrounds
   */
  blue100: '#D6EFFA',
  blue200: '#BCE4F8',
  blue400: '#74C6EC',
  blue700: '#006899', // Optimum Blue
  blue800: '#004869',
};
