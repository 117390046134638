import React from 'react';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { rewardsLevels } from '../../containers/rewards/rewards.constants';
import { SubtitleEmphasis } from '../typography/typography';
import useStyles from './rewardsTierChart.styles';
import RequirementChart from './requirementsChart';
import PerksRewardChart from './perksRewardChart';

export const RewardsTierChart = () => {
  const classes = useStyles();
  return (
    <Table className={classes.tableIcons} data-test="rewards-requirements-perks-table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.columnAwards} />
          {rewardsLevels.map((rewardsLevel) => (
            <TableCell
              align="center"
              className={classes.columnAwards}
              key={rewardsLevel.title}
              data-test={`rewards-level-${rewardsLevel.title.toLowerCase()}`}
            >
              {rewardsLevel.icon}
              <SubtitleEmphasis
                textTransform="upperCase"
                fontWeight="bold"
                className={classes.rewardsTitle}
              >
                {rewardsLevel.title}
              </SubtitleEmphasis>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        <RequirementChart />
        <PerksRewardChart />
      </TableBody>
    </Table>
  );
};

export default RewardsTierChart;
