import type { AllAccount, AllAccountTypeName } from 'Domain/Account/AllAccount';
import type { AccountDetails } from 'Domain/Account/AccountDetails';
import i18n from '../strings/i18n';
import type { TransferAccount } from '../containers/transfers/transfers.types';
import type { ProtectorAccount } from '../containers/overdraftProtection/overdraftProtection.service';
import type { Account } from './types';
import { formatAccountName } from '../formatters/buildAccountName';
import { EligibleAccount } from '../containers/ePreferences/ePreferences.constants';

function isMaskedPhoneNumber(maskedPhone: string) {
  return !!maskedPhone && maskedPhone.includes('●●●');
}

/** (●●●) ●●●-9090 */
export function formatMaskedPhoneNumber(maskedPhone: string) {
  if (!isMaskedPhoneNumber(maskedPhone)) {
    return maskedPhone;
  }
  const speakDigits = maskedPhone.slice(-4).split('').join('-');
  return `Phone number ending in ${speakDigits}`;
}

/** e.g. US Bank Savings ●●2266 or HYS ●●2266 */
export function hasMaskedAccountNumber(accountName: string) {
  return !!accountName && accountName.includes('●●');
}

/** e.g. ●●0046. */
export function formatAccountNumber(accountNumber: string) {
  const speakDigits = accountNumber.replace(/●/g, '').split('').join('-');
  return `ending in ${speakDigits}`;
}

/** e.g. US Bank Savings ●●2266 or HYS ●●2266 */
export function formatAccountNumberWithType(accountNumber: string) {
  // Prevent error if we do not receive an account in the format
  // we expect, e.g. if the account has an account nickname.
  if (!hasMaskedAccountNumber(accountNumber)) {
    return accountNumber;
  }
  const blackCircleAccount = accountNumber.split('●●');
  const speakType = blackCircleAccount[0].trim();
  const speakDigits = formatAccountNumber(blackCircleAccount[1]);
  return `${speakType} ${speakDigits}`;
}

export const formatAccountNameForScreenReader = (
  account: ProtectorAccount | TransferAccount | Account
) => {
  const { nickname, accountType, accountIdDisplay } = account;
  const formattedIdDisplay = formatAccountNumber(accountIdDisplay);
  return formatAccountName(nickname, accountType, formattedIdDisplay);
};

export const formatAccountNameYearForScreenReader = (account: EligibleAccount) => {
  const { nickname, product, accountIdDisplay } = account;
  const formattedId = accountIdDisplay.replace(/\D/g, '').slice(-4);
  const formattedIdDisplay = `${i18n({ accounts: 'accountEndingIn' })} ${formattedId}`;
  const productType = product?.productDisplayType;
  return formatAccountName(nickname, productType, formattedIdDisplay);
};

export const formatAccountDetailsNameForScreenReader = (account: AccountDetails) => {
  const { nickname, type, accountIdDisplay } = account;
  const formattedIdDisplay = formatAccountNumber(accountIdDisplay);
  return formatAccountName(nickname, type, formattedIdDisplay);
};

export const formatProductDisplayTypeForScreenReader = (account: Account | AllAccount) => {
  const { nickname, productDisplayType = account.accountType, accountIdDisplay } = account;
  const formattedIdDisplay = formatAccountNumber(accountIdDisplay);
  return formatAccountName(nickname, productDisplayType, formattedIdDisplay);
};

export function createFundingAccountInternalAriaLabel(account: AllAccount) {
  const { accountType, accountIdDisplay, productDisplayType } = account;
  const lastFour = accountIdDisplay.slice(-4);
  const speakDigits = lastFour.split('').join('-');
  const availableBalance = account.availableBalance
    ? ` has a balance of $${account.availableBalance} `
    : ' ';

  return `${
    account.nickname || productDisplayType || accountType
  } account ending in ${speakDigits}${availableBalance}`;
}

export function createFundingAccountExternalAriaLabel(account: AllAccount) {
  const { bankName, accountType, accountIdDisplay } = account;
  const lastFour = accountIdDisplay.slice(-4);
  const speakDigits = lastFour.split('').join('-');
  const displayedBankName = bankName;

  return `${displayedBankName} ${accountType} account,
  ending in ${speakDigits}`;
}

export function createAccountNameAriaLabel(account: Account) {
  const { accountTypeDisplayName, nickname, accountIdDisplay } = account;
  const lastFour = accountIdDisplay.slice(-4);
  const speakDigits = lastFour.split('').join('-');

  return `${nickname || accountTypeDisplayName} account
  ending in ${speakDigits}.`;
}

export function createAccountOpeningDetailsAriaLabel(
  productName: AllAccountTypeName,
  accountIdDisplay: string,
  availableBalance: string
) {
  const lastFour = accountIdDisplay.slice(-4);
  const speakDigits = lastFour.split('').join('-');

  return `${productName} account
  ending in ${speakDigits}
  with an opening balance of $${availableBalance}`;
}

export function createZipCodeValueAriaLabel(zipCode: string) {
  return zipCode.split('').join('-');
}
