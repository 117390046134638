import { formValueSelector } from 'redux-form';
import { NAO_FORM_ID, EAO_FORM_ID, PRODUCT_SECTION_ID } from '../../form.constants.ts';
import Paths from '../../containers/routes/routes.constants.ts';
import loadScript from '../../utilities/scriptLoader.ts';
import { getCustomerCIF } from '../../utilities/authentication.ts';

const initMoxie = (history, store) => {
  const src = `https://asset.gomoxie.solutions/concierge/${window.__config__.MOXIE_TENANT_NAME}/client/concierge-client.js`;
  loadScript(src, {
    'data-concierge': '1',
    'data-concierge-host': 'gomoxie.solutions',
    defer: 'true',
  });

  // https://gomoxie-portal.kb.net/portal/articles/6716
  const eventName = 'GoMoxie:conciergeReady';

  const conciergeReady = () => {
    window.removeEventListener(eventName, conciergeReady);

    const getChannelsData = () => {
      const state = store.getState();
      const formId = state.router.location.pathname.includes(Paths.NAO) ? NAO_FORM_ID : EAO_FORM_ID;
      const selector = formValueSelector(formId);
      const { term: productId, productType: productName } =
        selector(state, PRODUCT_SECTION_ID) || {};

      return {
        customerCIF: getCustomerCIF(),
        sendingPage: window.location.href,
        productId,
        productName,
      };
    };

    const updateChannelsData = () => {
      const channelsData = getChannelsData();
      window.GoMoxie.conciergeV2.channelsData(channelsData);
    };

    const onRouteChange = () => {
      // Wait for react-helmet to apply document.title changes
      setTimeout(function onDocumentUpdate() {
        updateChannelsData();
        window.GoMoxie.conciergeV2.updatePage(window.location.href, document.title);
      }, 0);
    };

    updateChannelsData();
    history.listen(onRouteChange);
  };
  window.addEventListener(eventName, conciergeReady);
};

export default initMoxie;
