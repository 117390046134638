import AccountDetailsTypes from 'Domain/Account/AccountDetails.ts';

export const mockInterest = {
  paidYTD: 'the-paidYTD',
  rate: 'the-rate',
  apy: 'the-apy',
};

export const mockInterestWithValues = {
  paidYTD: 'the-paidYTD',
  rate: '.59',
  apy: '.60',
};

export const mockWithdrawal = {
  count: 'count',
  allowed: 'allowed',
};

export const mockProduct = {
  detail: {
    displayText: '',
    url: '',
  },
  interest: {
    accrualMethod: '',
    paymentFrequency: '',
    paymentPeriod: '',
    yearBase: '',
  },
  interestRateId: '',
  maxOpenAmount: 0,
  minOpenAmount: 0,
  productDefinitionType: '',
  productDisplayType: 'NONE',
  productId: '',
  productName: '',
  productType: '',
  productTypeId: '',
  productTypeName: 'NONE',
  promotion: {
    code: '',
    description: '',
    maximumAmount: 0,
    minimumAmount: 0,
    name: '',
    newMoney: false,
  },
  rateMatrices: [],
  term: 0,
  termUnits: '',
  tiers: [{ amount: '', rate: '' }],
};

export const mockAccountSummary = {
  accountApy: '2.5',
  accountId: '456',
  accountIdDisplay: '--6',
  accountIdFull: '456456',
  accountStatus: 'Active',
  accountType: 'HYS',
  accountTypeDisplayName: 'string',
  availableBalance: 'string',
  cdInformation: {
    inGracePeriod: false,
    isMaturing: false,
    isRenewable: false,
    term: {
      value: 6,
      units: 'Months',
    },
    renewalInstructionsApplied: false,

    isRateEditable: false,
  },
  closedDate: 'string',
  currentBalance: '44.00',
  nickname: '',
  owner: false,
  primaryOwner: true,
  product: mockProduct,
  rate: 'string',
  relationshipType: 'string',
  transfersFromEnabled: true,
  transfersToEnabled: true,
};

export const mockCDAccount = {
  accountBeneficiaries: ['Mario', 'Luigi'],
  accountOwners: ['Tim Lincecum'],
  accountId: 'the-account-id',
  accountIdDisplay: '**1234',
  currentBalance: '2000.00',
  cdInformation: {
    maturityDate: '2020-05-05',
    inGracePeriod: false,
    isMaturing: false,
    isRenewable: false,
    term: {
      value: '12',
      units: 'Months',
    },

    isRateEditable: false,
    cdBumpUpDate: null,
  },
  holdBalance: '123.45',
  inGracePeriod: false,
  interest: mockInterest,
  interestDistribution: { method: 'Capitalize' },
  iraPlanType: '',
  isMaturing: false,
  isRenewable: false,
  maturityDate: new Date().toISOString(),
  openDate: new Date(1),
  routingNumber: 'the-routing-number',
  term: 'the-term',
  withdrawal: mockWithdrawal,
  nickname: '',
  product: { ...mockProduct, productDisplayType: 'CD' },
  primaryOwner: true,
  type: AccountDetailsTypes.CD,
};

export default {
  accountBeneficiaries: ['Mario', 'Luigi'],
  accountOwners: ['Tim Lincecum'],
  accountId: 'the-account-id',
  accountIdDisplay: '**1234',
  currentBalance: '100.00',
  holdBalance: '123.45',
  inGracePeriod: false,
  interest: mockInterest,
  interestDistribution: { method: 'Capitalize' },
  iraPlanType: '',
  isMaturing: false,
  isRenewable: false,
  maturityDate: new Date().toISOString(),
  openDate: new Date(1),
  routingNumber: 'the-routing-number',
  term: 'the-term',
  withdrawal: mockWithdrawal,
  nickname: '',
  product: mockProduct,
  primaryOwner: true,
  type: AccountDetailsTypes.CHECKING,
};
