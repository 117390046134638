/* eslint-disable no-underscore-dangle */

import type { ReduxState } from '../reducers';
import { SecurityEligibilityFlows } from '../containers/security/security.service';
import pageTrack from './pageAnalytics.constants';

export const getNewTransferAttr = (state: ReduxState) => {
  if (state.newTransfer.transfer && state.newTransfer.transfer.frequency) {
    return state.newTransfer.transfer.frequency === 'ONCE' ? 'onetime' : 'recurring';
  }
  return '';
};

export const isNAOOTPFlow = (state: ReduxState) => {
  const { securityEligibilityFlow } = state.profile;
  return (
    securityEligibilityFlow &&
    securityEligibilityFlow === SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP
  );
};

export const isNAOTransmitFlow = (state: ReduxState) => {
  return state.authenticate.isLoggedIn
    ? pageTrack.pagesubfunction.EAO
    : pageTrack.pagesubfunction.NAO;
};

export const getPageFunctionTypeForOTP = (state: ReduxState) => {
  const { securityEligibilityFlow } = state.profile;
  switch (securityEligibilityFlow) {
    case SecurityEligibilityFlows.USERNAME_OTP_REQUIRED:
      return pageTrack.pagefunction.change_username;

    case SecurityEligibilityFlows.PASSWORD_OTP_REQUIRED:
      return pageTrack.pagefunction.change_password;

    case SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP:
    default:
      return pageTrack.pagefunction.ao_otp;
  }
};

export default getNewTransferAttr;
