import { UsernameFieldNames } from '../../form.constants';
import { checkEmptyTextField, charsAndNumbers } from '../../utilities/validators';

export const isUsernameLengthValid = (username?: string) =>
  !!username && username.length >= 4 && username.length < 21;

export const checkEmptyUsername = checkEmptyTextField(UsernameFieldNames.USERNAME);

export const checkValidCharsUsername = charsAndNumbers(UsernameFieldNames.USERNAME);

export const checkValidOTPCode = (code?: string | null) => !code || code.length !== 4;
