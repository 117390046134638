import type { ServiceError } from '../../utilities/types';
import type { EligibleAccount } from '../ePreferences/ePreferences.constants';

export const ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS = 'ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS';
export const ACTION_FETCH_COMBINED_STATEMENTS_REQUEST = 'ACTION_FETCH_COMBINED_STATEMENTS_REQUEST';
export const ACTION_FETCH_COMBINED_STATEMENTS_FAILURE = 'ACTION_FETCH_COMBINED_STATEMENTS_FAILURE';

export type CombinedStatementsPrefenceState = {
  combinedStatements: boolean;
  eligibleAccounts: EligibleAccount[];
  error?: ServiceError;
};

const getInitialState = (): CombinedStatementsPrefenceState => ({
  combinedStatements: false,
  eligibleAccounts: [],
});

export type CombinedStatementsAction =
  | {
      type: typeof ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS;
      payload: CombinedStatementsPrefenceState;
    }
  | {
      type: typeof ACTION_FETCH_COMBINED_STATEMENTS_FAILURE;
      payload: ServiceError;
    }
  | {
      type: typeof ACTION_FETCH_COMBINED_STATEMENTS_REQUEST;
    };

export default (
  state: CombinedStatementsPrefenceState | null | undefined = getInitialState(),
  action: CombinedStatementsAction = undefined
): CombinedStatementsPrefenceState => {
  switch (action.type) {
    case ACTION_FETCH_COMBINED_STATEMENTS_REQUEST:
      return {
        ...getInitialState(),
      };
    case ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS:
      return {
        ...state,
        combinedStatements: action.payload.combinedStatements,
        eligibleAccounts: action.payload.eligibleAccounts,
      };
    case ACTION_FETCH_COMBINED_STATEMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
