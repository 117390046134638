export const ACTION_UTILITIES_STATE_LIST_SUCCESS = 'ACTION_UTILITIES_STATE_LIST_SUCCESS';
export const ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS = 'ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS';
export const ACTION_UTILITIES_NATIONS_LIST_SUCCESS = 'ACTION_UTILITIES_NATIONS_LIST_SUCCESS';

export type ListItem = {
  name: string;
  value: string;
  additionalDetails?: {
    employerRequired?: boolean;
  };
};

export type List = Array<ListItem>;

export type ListsState = {
  nations: List;
  occupations: List;
  states: List;
};

function getInitialState(): ListsState {
  return {
    nations: [],
    occupations: [],
    states: [],
  };
}

export type ListsAction =
  | {
      type: typeof ACTION_UTILITIES_STATE_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS;
      payload: List;
    }
  | {
      type: typeof ACTION_UTILITIES_NATIONS_LIST_SUCCESS;
      payload: List;
    };
export default (
  state: ListsState | null | undefined = getInitialState(),
  action: ListsAction = undefined
): ListsState => {
  switch (action.type) {
    case ACTION_UTILITIES_STATE_LIST_SUCCESS:
      return { ...state, states: action.payload };
    case ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS:
      return { ...state, occupations: action.payload };
    case ACTION_UTILITIES_NATIONS_LIST_SUCCESS:
      return { ...state, nations: action.payload };
    default:
      return state;
  }
};
