import { makeStyles } from '@material-ui/core/styles';
import pxToRem from '../../utilities/pxToRem';
import useBeneficiariesStyles from '../../containers/beneficiaries/beneficiaries.styles';
import Colors from '../colors/colors';

export const useBeneficiaryModalStyles = makeStyles({
  beneficiaryAccountsError: {
    color: Colors.inlineError,
    fontSize: pxToRem(14),

    // Add some spacing between multiple error messages
    '& + &': {
      marginTop: pxToRem(8),
    },
  },
  beneficiaryInfoError: {
    color: Colors.inlineError,
    fontSize: pxToRem(14),
    marginTop: pxToRem(16),
  },
  errorIcon: {
    width: pxToRem(15),
    height: pxToRem(13),
    marginLeft: pxToRem(6),
  },
  infoLabel: {
    marginBottom: pxToRem(8),
  },
  paper: {
    maxWidth: pxToRem(890),
    width: pxToRem(890),
  },
  selectAccountsDescription: {
    marginTop: pxToRem(8),
    marginBottom: pxToRem(16),
  },
  sameAddressCheckboxAddMode: {
    marginTop: pxToRem(40),
  },
  sameAddressCheckboxEditMode: {
    marginTop: pxToRem(27),
  },
  dobFieldInstruction: {
    fontSize: pxToRem(13.5),
    paddingLeft: pxToRem(15),
  },
});

export default useBeneficiariesStyles;
