import { StyleRules } from '@material-ui/core';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';

export default (theme: Theme): StyleRules => ({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    maxHeight: '100vh',
  },
  closeContainer: {
    position: 'absolute',
    right: pxToRem(5),
    top: pxToRem(5),
  },
  closeIcon: {
    width: pxToRem(14),
    height: pxToRem(14),
  },
  paper: {
    boxShadow: theme.shadows[5],
    maxWidth: pxToRem(500),
    overflow: 'auto',
    position: 'relative',
    maxHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      opacity: 1,
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
  },
  paperBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  wide: {
    maxWidth: pxToRem(700),
  },
  themedOutline: {
    outlineColor: theme.palette.primary.main,
  },
  modalContentWrapper: {
    display: 'flex',
    height: '100%',
  },
  modalTitle: {
    paddingBottom: pxToRem(10),
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    padding: theme.spacing(4),
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      padding: theme.spacing(3),
    },
  },
});
