import type { AgreementTypes } from '../../utilities/types';

export const ACTION_ETIN_REQUEST = 'ACTION_ETIN_REQUEST';
export const ACTION_ETIN_SUCCESS = 'ACTION_ETIN_SUCCESS';
export const ACTION_ETIN_FAILURE = 'ACTION_ETIN_FAILURE';
export const ACTION_ETIN_CUSTOMER_REQUEST = 'ACTION_ETIN_CUSTOMER_REQUEST';
export const ACTION_ETIN_CUSTOMER_SUCCESS = 'ACTION_ETIN_CUSTOMER_SUCCESS';
export const ACTION_ETIN_CUSTOMER_FAILURE = 'ACTION_ETIN_CUSTOMER_FAILURE';
export const ACTION_ETIN_DECRYPTION_REQUEST = 'ACTION_ETIN_DECRYPTION_REQUEST';
export const ACTION_ETIN_DECRYPTION_SUCCESS = 'ACTION_ETIN_DECRYPTION_SUCCESS';
export const ACTION_ETIN_DECRYPTION_FAILURE = 'ACTION_ETIN_DECRYPTION_FAILURE';
export const ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_REQUEST =
  'ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_REQUEST';
export const ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_RESPONSE =
  'ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_RESPONSE';

export type EtinState = {
  agreements?: AgreementTypes[];
  hasCustomerError: boolean;
  hasDecryptionError: boolean;
  hasEtinTextError: boolean;
  isCustomerLoading: boolean;
  isDecryptionLoading: boolean;
  isEtinTextLoading: boolean;
  isUpdateTaxIdCertificationLoading: boolean;
};

export const getInitialState = (): EtinState => ({
  hasCustomerError: false,
  hasDecryptionError: false,
  hasEtinTextError: false,
  isCustomerLoading: false,
  isDecryptionLoading: false,
  isEtinTextLoading: false,
  isUpdateTaxIdCertificationLoading: false,
});

export type EtinAction =
  | {
      type: typeof ACTION_ETIN_REQUEST;
    }
  | {
      type: typeof ACTION_ETIN_SUCCESS;
      payload: AgreementTypes[];
    }
  | {
      type: typeof ACTION_ETIN_FAILURE;
    }
  | {
      type: typeof ACTION_ETIN_CUSTOMER_REQUEST;
    }
  | {
      type: typeof ACTION_ETIN_CUSTOMER_SUCCESS;
    }
  | {
      type: typeof ACTION_ETIN_CUSTOMER_FAILURE;
    }
  | {
      type: typeof ACTION_ETIN_DECRYPTION_REQUEST;
    }
  | {
      type: typeof ACTION_ETIN_DECRYPTION_SUCCESS;
    }
  | {
      type: typeof ACTION_ETIN_DECRYPTION_FAILURE;
    }
  | {
      type: typeof ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_REQUEST;
    }
  | {
      type: typeof ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_RESPONSE;
    };

export default (
  state: EtinState | null | undefined = getInitialState(),
  action: EtinAction = undefined
): EtinState => {
  switch (action.type) {
    case ACTION_ETIN_REQUEST:
      return { ...state, isEtinTextLoading: true, hasEtinTextError: false };
    case ACTION_ETIN_SUCCESS:
      return {
        ...state,
        isEtinTextLoading: false,
        hasEtinTextError: false,
        agreements: action.payload,
      };
    case ACTION_ETIN_FAILURE:
      return { ...state, isEtinTextLoading: false, hasEtinTextError: true };
    case ACTION_ETIN_CUSTOMER_REQUEST:
      return { ...state, isCustomerLoading: true, hasCustomerError: false };
    case ACTION_ETIN_CUSTOMER_SUCCESS:
      return { ...state, isCustomerLoading: false, hasCustomerError: false };
    case ACTION_ETIN_CUSTOMER_FAILURE:
      return { ...state, isCustomerLoading: false, hasCustomerError: true };
    case ACTION_ETIN_DECRYPTION_REQUEST:
      return { ...state, isDecryptionLoading: true, hasDecryptionError: false };
    case ACTION_ETIN_DECRYPTION_SUCCESS:
      return { ...state, isDecryptionLoading: false, hasDecryptionError: false };
    case ACTION_ETIN_DECRYPTION_FAILURE:
      return {
        ...state,
        isDecryptionLoading: false,
        hasDecryptionError: true,
      };
    case ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_REQUEST:
      return {
        ...state,
        isUpdateTaxIdCertificationLoading: true,
      };
    case ACTION_ETIN_UPDATE_TAXID_CERTIFICATION_RESPONSE:
      return {
        ...state,
        isUpdateTaxIdCertificationLoading: false,
      };
    default:
      return state;
  }
};
