import { Grid } from '@material-ui/core';
import type { ReactNode } from 'react';
import React from 'react';

type Props = {
  className?: string;
  children: ReactNode | ReactNode[];
};

const FullHeightLayout = ({ className = '', children }: Props) => (
  <Grid container item spacing={0} className={className}>
    {children}
  </Grid>
);

export default FullHeightLayout;
