import { makeStyles } from '@material-ui/core';
import type { Theme } from '../../utilities/types';
import colors from '../colors/colors';
import pxToRem from '../../utilities/pxToRem';

export default makeStyles((theme: Theme) => ({
  root: {
    marginBottom: pxToRem(26),
    padding: pxToRem(20),
    color: colors.textFlash,
    lineHeight: '1rem',
    fontSize: '1rem',
    display: 'flex',
    '&:focus': {
      outline: 'none',
    },
  },
  action: {
    background: colors.cdActionFlash,
  },
  dashboard: {
    background: colors.dashboardFlash,
    paddingTop: pxToRem(10),
    paddingBottom: pxToRem(10),
  },
  dashboardBrand: {
    background: colors.dashboardFlashBlue,
    paddingTop: pxToRem(10),
    paddingBottom: pxToRem(10),
  },
  dashboardAlert: {
    background: colors.synchronyGold,
    paddingTop: pxToRem(10),
    paddingBottom: pxToRem(10),
  },
  dashboardAction: {
    background: colors.cdActionFlash,
    paddingTop: pxToRem(10),
    paddingBottom: pxToRem(10),
  },
  dashboardActionBrand: {
    background: colors.dashboardFlashOrange,
    paddingTop: pxToRem(10),
    paddingBottom: pxToRem(10),
  },
  default: {
    background: colors.nonDashboardLinkFlash,
  },
  error: {
    background: colors.errorFlash,
  },
  icon: {
    padding: `0 ${pxToRem(12)}`,
    height: '100%',
  },
  iconAccountAlert: {
    padding: `0 ${pxToRem(8)}`,
    height: '100%',
    margin: `${pxToRem(4)} 0 0  ${pxToRem(15)}`,
    [theme.breakpoints.down('sm')]: {
      margin: `${pxToRem(4)} 0 0  ${pxToRem(2)}`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: `${pxToRem(4)} 0 0  ${pxToRem(5)}`,
    },
  },
  info: {
    background: colors.dashboardFlash,
  },
  messageBody: {
    fontSize: pxToRem(14),
    lineHeight: 1.43,
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(16),
      lineHeight: 1.25,
    },
  },
  success: {
    background: colors.successFlash,
  },
  warning: {
    background: colors.synchronyGold,
    paddingLeft: 0,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  warningCached: {
    background: colors.synchronyCachedWarning,
    paddingLeft: 0,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  warningAccountDashboard: {
    background: colors.dashboardFlashBlue,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginBottom: `0 !important`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 0 ${pxToRem(5)} 0 !important`,
    },
  },
  fullWidth: {
    width: '100%',
    marginRight: pxToRem(12),
  },
}));
