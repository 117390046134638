import type { AccountDashboardAction } from '../../containers/accountDashboard/accountDashboard.reducer';
import {
  ACTION_FETCH_ACCOUNTS_FAILURE,
  ACTION_FETCH_ACCOUNTS_SUCCESS,
  showEPreferencesDashboardAlert,
} from '../../containers/accountDashboard/accountDashboard.reducer';
// eslint-disable-next-line import/no-cycle
import pageAnalytics from '../pageAnalytics';
import type { ReduxState } from '../../reducers';

// ---------- PAGE ANALYTICS ----------
const initView = (state: ReduxState) => {
  pageAnalytics.newPageView();
  let customerInfo;
  let accounts;
  let pagesubfunction = '';

  if (state.applications) customerInfo = state.applications.customerInfo;
  if (state.accounts) accounts = state.accounts.data;
  if (customerInfo)
    if (customerInfo.emailAddresses.length === 0) pagesubfunction = 'no_email';
    else if (accounts && showEPreferencesDashboardAlert(accounts)) pagesubfunction = 'go_paperless';

  pageAnalytics.addInfo({
    account_type: 'generic',
    pagekind: 'dashboard',
    pagefunction: 'dashboard',
    pagesubfunction,
  });
};

const viewFailure = (action: AccountDashboardAction, state: ReduxState) => {
  initView(state);
  pageAnalytics.addInfo({ pagename: 'landing_page_server_error' });
  pageAnalytics.record();
};
const viewSuccess = (action: AccountDashboardAction, state: ReduxState) => {
  initView(state);

  if (
    // lots of typeguards.
    // in summary: "if no accounts"
    'payload' in action &&
    typeof action.payload !== 'string' &&
    'accounts' in action.payload &&
    action.payload.accounts?.length
  ) {
    pageAnalytics.addInfo({ pagename: 'landing_page' });
    if (
      // summary: 'if payload has asOfDate'
      action?.payload?.asOfDate !== null
    ) {
      pageAnalytics.addInfo({ pagesubfunction: 'cached' });
    } else {
      pageAnalytics.addInfo({ pagesubfunction: '' });
    }
  } else pageAnalytics.addInfo({ pagename: 'landing_page_no_account' });

  pageAnalytics.record();
};

export const accountDashboardPageEvents = {
  [ACTION_FETCH_ACCOUNTS_FAILURE]: viewFailure,
  [ACTION_FETCH_ACCOUNTS_SUCCESS]: viewSuccess,
};

export default accountDashboardPageEvents;
