import React from 'react';

import { SynchronyInfo } from '../cms/blockText.constants';
import { StandardTextEmphasis } from '../typography/typography';

export enum FlashMessageVariant {
  ACTION = 'ACTION',
  DASHBOARD = 'DASHBOARD',
  DASHBOARDBRAND = 'DASHBOARDBRAND',
  DASHBOARD_ALERT = 'DASHBOARD_ALERT',
  DASHBOARD_ACTION = 'DASHBOARD_ACTION',
  DASHBOARD_ACTION_BRAND = 'DASHBOARD_ACTION_BRAND',
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  WARNINGCACHED = 'WARNINGCACHED',
  WARNINGACCOUNT = 'WARNINGACCOUNT',
}

export enum FlashMessageAccountType {
  UNFUNDED = 'Unfunded',
  GRACEPERIOD = 'CDGracePeriod',
  MATURITY = 'CDMaturity',
}

export type FlashMessageVariantType = keyof typeof FlashMessageVariant;

export const FlashMessageTextTemplate = {
  cdGracePeriodMessage: (accountName: string) =>
    `Your ${accountName} is in its 10 day grace period.`,
  cdMaturingMessage: (accountName: string) => `Your ${accountName} is approaching maturity.`,
} as const;

export const FlashMessageCD = {
  cdMaturingMessage: (accountName: string) => `Your ${accountName} is approaching maturity.`,
} as const;

export const FlashMessageText = {
  ACCOUNT_CLOSED: (
    <>
      <StandardTextEmphasis>Your account has been closed.</StandardTextEmphasis> Please contact a
      Banker at {SynchronyInfo.PHONE} to learn more.
    </>
  ),
  ACCOUNT_DORMANT: (
    <>
      <StandardTextEmphasis>Your account is dormant.</StandardTextEmphasis> There has been no
      activity on your account for an extended period of time. Take action to prevent the funds in
      your account from being transferred to the state due to state unclaimed property laws. Please
      contact a Banker at {SynchronyInfo.PHONE} to change your account status.
    </>
  ),
  ACCOUNT_NOT_FUNDED:
    'If you have not already started the funding process, please add money to your account. If your account is not funded within 60 days of opening the account, it will be closed.',
  ACCOUNT_NOT_FUNDED_CTA: 'Add money',
  ACCOUNT_NOT_FUNDED_DASHBOARD: (accountName: string) =>
    `If you have not already started the funding process, please add money to the following account: ${accountName}.`,
  ACCOUNT_NOT_FUNDED_ACCOUNT_ALERT: (accountName: string) =>
    `If you have not already started the funding process, please add money to the account: ${accountName}`,
  ACCOUNT_RESTRICTED: (
    <>
      <StandardTextEmphasis>Your account is restricted.</StandardTextEmphasis> You are not able to
      access this account online. Please contact a Banker at {SynchronyInfo.PHONE} to change your
      account status.
    </>
  ),
  ACCOUNTS_NOT_FOUND: 'There was an error finding your accounts.',
  CD_CALL_US: (
    <span>
      If you would like to make further changes to the renewal instructions for your CD, please call
      a Banker at {SynchronyInfo.CD_PHONE_DARK}.
    </span>
  ),
  CD_IRA_MATURING: (
    <>
      You don’t need to take any action unless you want to make changes to your IRA CD. If you’d
      like to make changes during this period, please call a Banker at {SynchronyInfo.CD_PHONE_DARK}
      . Please note that the IRA team is not available on weekends.
    </>
  ),
  CD_GRACE_MATURING: (
    <>
      You don’t need to take any action unless you want to make changes to your CD. If you’d like to
      make changes during this period, click here to
    </>
  ),
  CD_MATURING:
    'Your CD is approaching maturity and will automatically renew at the same term. You don’t need to take any action unless you want to make changes to your CD. If you’d like to make changes, click here to',
  CD_IRA_GRACE_PERIOD: (
    <>
      You don’t need to take any action unless you want to make changes to your IRA CD. If you’d
      like to make changes during this period, please call a Banker at {SynchronyInfo.CD_PHONE_DARK}
      . Please note that the IRA team is not available on weekends.
    </>
  ),
  CD_MATURING_DASHBOARD_LINK_TEXT: 'Find out more',
  CD_MATURING_RENEWAL_INSTRUCTIONS:
    'Your CD is approaching maturity. We have your renewal instructions on file.',
  CD_VIEW_RENEWAL_INSTRUCTIONS: 'View Renewal Instructions',
  CD_UPDATE_RENEWAL_OPTIONS: 'Update Renewal Options',
  CD_RENEWAL_ERROR: `Our system is unable to process your CD Renewal instructions at this time. To continue renewing your CD, please try again later or contact us at ${SynchronyInfo.CD_PHONE_TEXT}.`,
  CREATE_APPLICATION_ERROR: (
    <span>
      Thank you for your interest in opening an account with Synchrony Bank. We are having trouble
      processing your request at this time. Please try again or call us at {SynchronyInfo.PHONE}.
    </span>
  ),
  DATA_FETCH_ERROR: 'An error has occurred. Please try again.',
  EDIT_PROFILE_ERROR: `Changes cannot be saved at this time. Please try again or contact a Banker at ${SynchronyInfo.PHONE_TEXT}.`,
  EDIT_SUCCESS_GENERIC: 'Your changes have been saved successfully.',
  GENERIC_ERROR: 'Unfortunately an error has occurred. Please try again.',
  EDIT_NICKNAME_ERROR: (
    <span>
      Unfortunately an error occurred. We are unable to process your request at this time. To
      continue, try again later or contact us at {SynchronyInfo.PHONE}.
    </span>
  ),
  INSUFFICIENT_BALANCE:
    'You’ll need to select an amount less than your current available balance, or a future date.',
  INVALID_OTP_CODE: 'Sorry, that code is incorrect. Please try again.',
  MAX_OTP_ATTEMPTS_REACHED:
    'Sorry, you reached the maximum attempts to enter that code. Please re-send a code to try again.',
  MISSING_EMAIL_DASHBOARD_ALERT:
    'It looks like we don’t have an email address on file for your account. Once you add an email, you’ll be able to receive notifications when statements are available and set up account alerts online.',
  MISSING_EMAIL_LINK_TEXT: 'Add Email Address',
  NO_MESSAGE: '',
  SUCCESS_MESSAGE: 'Successfully cancelled your transfer.',
  CANCEL_CARD_SUCCESS_MESSAGE: 'Successfully cancelled your card',
  REINITIATE_TRIAL_DEPOSITS_SUCCESS: 'You have successfully reinitiated your trial deposits',
  REINITIATE_TRIAL_DEPOSITS_ERROR:
    'Unable to reinitiate trial deposits at this time. Please try again later.',
  REMOVE_EXTERNAL_ACCOUNTS_SUCCESS:
    'Your Registered External Account has been deleted. You will no longer be able to make transfers to or from this account and any scheduled transfers will be cancelled.',
  SUCCESS: 'Success',
  TRANSFER_ERROR_TOO_FEW_ACCOUNTS:
    'You need at least two eligible accounts in order to make a transfer. Please open an account or connect an external bank account before initiating a transfer.',
  TRANSFER_ERROR_TOO_FEW_ACCOUNTS_WITH_RESTRICTED_EXTERNAL: `You need at least two eligible accounts in order to make a transfer. Your external accounts
      are unavailable and you can’t make transfers to or from external accounts at this time. If you
      have any questions, please call us at ${SynchronyInfo.PHONE_TEXT}.`,
  TRY_AGAIN: 'Try again',
  TRY_AGAIN_LINK_TEXT: 'try again',
  UNCREATABLE_CALL_US_MESSAGE: (
    <>
      Your account is unfunded. Please contact a Banker at {SynchronyInfo.PHONE} to add funds to
      your account.
    </>
  ),
  VERIFY_TRIAL_DEPOSITS_FLASH_TEXT: 'Your external account is ready to be verified.',
  VERIFY_TRIAL_DEPOSITS_SUCCESS: 'You have successfully verified your external bank account.',
  VERIFY_TRIAL_DEPOSITS_LINK_TEXT: 'VERIFY TRIAL DEPOSITS',
  PROMO_CODE_NOT_VALID: 'Sorry, this promo code is not valid.',
  TAX_DOCUMENT_E_PREFERENCE_ERROR:
    'An error occurred while saving your preferences. Please try again.',
};
