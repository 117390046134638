import { makeStyles } from '@material-ui/styles';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import { Alignment } from '../../styles/layout/alignment.styles';

export const useRewardsContainerStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: pxToRem(1280),
    ...Alignment.allPadding,
  },
  currentRewardTierContainer: {
    marginTop: pxToRem(16),
    marginBottom: pxToRem(48),
    display: 'flex',
  },
  currentRewardTierIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginRight: pxToRem(11),
  },
  currentRewardTierIcon: {
    flex: 'auto',
    marginBottom: pxToRem(14),
    maxHeight: pxToRem(43),
  },
  currentRewardTierDescription: {
    flex: 3,
    borderLeft: '0.5px solid rgba(59, 60, 67, 0.56)',
    paddingTop: pxToRem(17),
    paddingBottom: pxToRem(16),
    paddingLeft: pxToRem(11),
  },
  rewardTierList: {
    display: 'flex',
    paddingTop: pxToRem(16),
    paddingRight: pxToRem(48),
    paddingBottom: pxToRem(32),
    paddingLeft: pxToRem(32),
    '& li': {
      listStyleType: 'disc',
      paddingLeft: pxToRem(16),
    },
  },
  rewardLink: {
    marginBottom: pxToRem(16),
    display: 'block',
  },
  divider: {
    margin: `${pxToRem(32)} 0 ${pxToRem(16)} 0`,
    [theme.breakpoints.up('sm')]: {
      margin: `${pxToRem(64)} 0 ${pxToRem(27)} 0`,
    },
  },
}));

export default useRewardsContainerStyles;
