import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const EPreferencePriorTitle = 'Marketing Preferences';

const EPreferenceTitle = 'Marketing Communication Delivery';

const EPreferenceSelectStatement =
  'Select how you would like to receive marketing and promotional offers from us.';

const EPreferenceNoCustomersMailOnFile =
  'It looks like we don’t have an address on file. To manage these settings, you’ll need to edit your profile.';

const EPreferenceElectronicAgreementText = 'ELECTRONIC COMMUNICATIONS AGREEMENT';

export const EPreferenceTexts = Object.freeze({
  PREFERENCE_TITLE: EPreferencePriorTitle,
  TITLE: EPreferenceTitle,
  SELECT_STATEMENT: EPreferenceSelectStatement,
  ELECTRONIC_AGREEMENT: EPreferenceElectronicAgreementText,
  NO_MAIL_ON_FILE: EPreferenceNoCustomersMailOnFile,
});

export const OffersPageFieldNames = Object.freeze({
  EMAIL: 'email',
  MAIL: 'mail',
});

export type EmailEnrolled = {
  enrolled: boolean;
  emailAddress: string;
};
export type FormValues = {
  email: boolean;
  mail: boolean;
};
export type MailingAddress = {
  line1: string;
  line2: string;
  line3: string;
  zipCode: string;
};

export type MailingAddressEnrolled = {
  enrolled: boolean;
  mailingAddress: MailingAddress;
};

export type MarketingAlerts = {
  email: EmailEnrolled;
  mail: MailingAddressEnrolled;
};

export type MarketingAlertsInfo = {
  marketingPreferences: MarketingAlerts;
};

export type MarketingAlertsUpdate = {
  marketingPreferences: {
    email: {
      enrolled: boolean;
    };
    mail: {
      enrolled: boolean;
    };
  };
};

export type MarketingPreferenceContentProps = {
  classes: ClassNameMap;
  isLoading: boolean;
  marketingAlerts: MarketingAlertsInfo;
  onSubmit: (formValues: FormValues) => Promise<void>;
};
