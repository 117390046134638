import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import type { ReduxState } from '../../reducers';
import PageWithCard from '../../components/page/pageWithCard';
import type { OTPInitiateSendValue } from './otp.constants';
import {
  InitiateOTPFieldNames,
  OTPValidateFormLabels,
  OTPValidateText,
  ValidateOTPFieldNames,
} from './otp.constants';
import { SectionHeaderBold, SubtitleText } from '../../components/typography/typography';
import BackAndNext from '../../components/buttons/backAndNext';
import { BackNextAndCancelBtn } from '../../components/cms/buttonText.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import { GhostButton } from '../../components/buttons/buttons';
import RenderCheckbox from '../../components/openNewAccountFormFlow/selectedAProductCheckbox';
import type { InitiateOTPForm, ValidateOTPForm } from '../../utilities/types';
import type {
  OTPChallengeResponse,
  OTPPhone,
  SecurityEligibilityFlow,
} from '../security/security.service';
import {
  initiateOTP,
  validateOTP,
  fetchModifyProfileTokenAction,
} from '../security/security.actions';
import { renderOtpField } from '../../components/finalForm/finalFormInputs';
import { checkValidOTPCode } from '../security/security.validators';
import Routes from '../routes/routes.constants';
import { setFlashMessage } from '../../components/flashMessage/flashMessage.reducer';
import {
  FlashMessageVariant,
  FlashMessageText,
} from '../../components/flashMessage/flashMessage.constants';
import ConfirmLeaveOtpModal from './ConfirmLeaveOtpModal';
import useEffectOnce from '../../utilities/reactHooks';
import {
  ANALYTICS_INVALID_OTP_ERROR,
  ANALYTICS_MAX_OTP_ATTEMPTS_ERROR,
  ANALYTICS_VERIFY_OTP_VIEW_SUCCESS,
  ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS,
  ANALYTICS_VERIFY_OTP_SERVER_ERROR,
  ANALYTICS_VERIFY_OTP_BLOCKED_ERROR,
} from '../../analytics/actions';
import { SecurityEligibilityFlows } from '../security/security.service';

type StateProps = {
  otpPhones?: OTPPhone[];
  securityEligibilityFlow?: SecurityEligibilityFlow;
  selectedOtpPhoneNumber?: string;
  selectedOtpSendMethod?: OTPInitiateSendValue;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  otpPhones: state.profile.otpPhones,
  securityEligibilityFlow: state.profile.securityEligibilityFlow,
  selectedOtpPhoneNumber: state.profile.selectedOtpPhoneNumber,
  selectedOtpSendMethod: state.profile.selectedOtpSendMethod,
});

type DispatchProps = {
  fetchModifyProfileToken: () => Promise<void>;
  resendCode: (arg1: any) => Promise<OTPChallengeResponse>;
  setErrorFlashMessage: (arg1: string) => void;
  submitValidateOTP: (arg1: ValidateOTPForm) => Promise<Record<any, any> | null | undefined>;
  recordAnalyticsPageView: () => void;
  recordAnalyticsOtpCompletedPageView: () => void;
  recordAnalyticsInvalidOtpPageView: () => void;
  recordAnalyticsBlockedPageView: () => void;
  recordAnalyticsMaxOtpAttemptsPageView: () => void;
  recordAnalyticsOtpServerErrorPageView: () => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchModifyProfileToken: () => dispatch(fetchModifyProfileTokenAction()),
  resendCode: (sendOTPForm: InitiateOTPForm) => dispatch(initiateOTP(sendOTPForm)),
  setErrorFlashMessage: (messageText: string) =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText,
      })
    ),
  submitValidateOTP: (validateOTPForm) => dispatch(validateOTP(validateOTPForm)),
  recordAnalyticsPageView: () => dispatch({ type: ANALYTICS_VERIFY_OTP_VIEW_SUCCESS }),
  recordAnalyticsOtpCompletedPageView: () =>
    dispatch({ type: ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS }),
  recordAnalyticsInvalidOtpPageView: () => dispatch({ type: ANALYTICS_INVALID_OTP_ERROR }),
  recordAnalyticsBlockedPageView: () => dispatch({ type: ANALYTICS_VERIFY_OTP_BLOCKED_ERROR }),
  recordAnalyticsMaxOtpAttemptsPageView: () => dispatch({ type: ANALYTICS_MAX_OTP_ATTEMPTS_ERROR }),
  recordAnalyticsOtpServerErrorPageView: () =>
    dispatch({ type: ANALYTICS_VERIFY_OTP_SERVER_ERROR }),
});

type AllProps = StateProps & DispatchProps;

export const ValidateOtp = (props: AllProps) => {
  const {
    otpPhones = [],
    fetchModifyProfileToken,
    resendCode,
    securityEligibilityFlow,
    selectedOtpPhoneNumber,
    selectedOtpSendMethod,
    setErrorFlashMessage,
    submitValidateOTP,
    recordAnalyticsPageView,
    recordAnalyticsOtpCompletedPageView,
    recordAnalyticsInvalidOtpPageView,
    recordAnalyticsBlockedPageView,
    recordAnalyticsMaxOtpAttemptsPageView,
    recordAnalyticsOtpServerErrorPageView,
  } = props;
  const history = useHistory();
  // If the user does not have the securityEligibilityFlow (e.g. in case of refresh or
  // direct navigation to this page), then redirect them to the security page
  useEffectOnce(() => {
    if (!securityEligibilityFlow) {
      history.replace(Routes.SECURITY);
    }
    recordAnalyticsPageView();
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);
  const [isChallengeMaxAttemptsReached, setIsChallengeMaxAttemptsReached] = useState(false);
  const [hasValidatedOTP, setHasValidatedOTP] = useState(false);

  const selectedPhone =
    selectedOtpPhoneNumber && otpPhones.find((otpPhone) => otpPhone.id === selectedOtpPhoneNumber);
  const selectedPhoneDisplay = selectedPhone ? selectedPhone.display.slice(-4) : '';
  const isNAO = securityEligibilityFlow === SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP;

  const handleValidOTP = () => {
    recordAnalyticsOtpCompletedPageView();
    setHasValidatedOTP(true);
  };

  const handleOTPMaxAttemptsReached = () => {
    if (isNAO) {
      history.replace(`${Routes.NAO}/5`);
    }
  };

  const handleUnauthorizedStateToken = () => {
    recordAnalyticsBlockedPageView();
    history.replace(`${Routes.LOGOUT}?reason=otp-maxout`);
  };

  const redirectToNextPage = () => {
    if (isNAO) {
      history.replace(`${Routes.NAO}/5`);
    } else {
      history.replace(Routes.SECURITY);
    }
  };

  const handleOTPError = (err) => {
    if (err && err.data) {
      switch (err.data.error_uri) {
        case 'urn://synchronybank.com/invalid-otp-code':
          setErrorFlashMessage(FlashMessageText.INVALID_OTP_CODE);
          recordAnalyticsInvalidOtpPageView();
          return { [ValidateOTPFieldNames.OTP]: true };

        case 'urn://synchronybank.com/multifactor-authentication-required':
          recordAnalyticsMaxOtpAttemptsPageView();
          history.replace(Routes.OTP_INITIATE);
          setErrorFlashMessage(FlashMessageText.MAX_OTP_ATTEMPTS_REACHED);
          return {};

        case 'urn://synchronybank.com/otp-max-attempts-reached':
          handleOTPMaxAttemptsReached();
          return {};

        case 'urn://synchronybank.com/unauthorized-state-token':
          handleUnauthorizedStateToken();
          return {};

        default:
          break;
      }
    }

    recordAnalyticsOtpServerErrorPageView();
    setErrorFlashMessage(FlashMessageText.GENERIC_ERROR);
    return {};
  };

  const onSubmit = async (values) => {
    if (!hasValidatedOTP) {
      try {
        await submitValidateOTP(values);
      } catch (err) {
        handleOTPError(err);
        return {};
      }
      handleValidOTP();
    }

    try {
      await fetchModifyProfileToken();
    } catch (err) {
      return {};
    }

    return redirectToNextPage();
  };

  const resendNewCode = async () => {
    if (selectedOtpPhoneNumber && selectedOtpSendMethod) {
      setIsResendCodeLoading(true);
      const response = await resendCode({
        [InitiateOTPFieldNames.PHONE_NUMBER]: selectedOtpPhoneNumber,
        [InitiateOTPFieldNames.HOW_TO_SEND_CODE]: selectedOtpSendMethod,
      });
      setIsChallengeMaxAttemptsReached(response.max_initiations_reached);
      setIsResendCodeLoading(false);
    }
  };

  return (
    <PageWithCard
      headerText={OTPValidateText.OTP_VALIDATE_HEADER}
      description={OTPValidateText.OTP_VALIDATE_DESCRIPTION_INFO(selectedPhoneDisplay)}
    >
      <Form onSubmit={onSubmit}>
        {({
          dirtySinceLastSubmit,
          handleSubmit,
          hasSubmitErrors,
          hasValidationErrors,
          submitting,
          form,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {!isChallengeMaxAttemptsReached && !hasValidatedOTP && (
                <Grid item xs={12}>
                  <SubtitleText fontWeight="bold" textTransform="upperCase">
                    {OTPValidateText.NEED_ANOTHER_CODE_TEXT}
                  </SubtitleText>
                  <GhostButton
                    loading={isResendCodeLoading}
                    data-test="quick-links-resend-now"
                    data-track-title={clickTrack.otp.resend_now}
                    data-track-type={clickTrackType.LINK}
                    onClick={() => {
                      resendNewCode();
                      form.change(ValidateOTPFieldNames.OTP, undefined);
                    }}
                  >
                    <SectionHeaderBold>{OTPValidateText.RESEND_NOW_TEXT}</SectionHeaderBold>
                  </GhostButton>
                </Grid>
              )}
              {/*  There is an issue, if click away from this field the focus isn't properly given
              to the new click event. To solve this issue added the e.preventDefault() with onBlur */}
              <Field
                label={OTPValidateFormLabels.ENTER_CODE}
                labelId="otpField"
                name={ValidateOTPFieldNames.OTP}
                component={renderOtpField}
                autoComplete="off"
                validate={checkValidOTPCode}
                data-test="enter-otp-code"
                onBlur={(e) => {
                  e.preventDefault();
                }}
              />
              <Field
                id={ValidateOTPFieldNames.REMEMBER_DEVICE}
                name={ValidateOTPFieldNames.REMEMBER_DEVICE}
                type="checkbox"
                data-test="rememberThisDevice"
                label={OTPValidateFormLabels.REMEMBER_THIS_DEVICE}
                labelId="rememberDeviceLabelId"
                component={RenderCheckbox}
                format={(value) => (value ? 'true' : 'false')}
                autoComplete=""
                data-test-label="remember-this-device-checkbox-label"
                initialValue={false}
              />
              <BackAndNext
                primaryOnLeft
                disableNext={hasValidationErrors || (hasSubmitErrors && !dirtySinceLastSubmit)}
                onNext={handleSubmit}
                loading={submitting}
                nextText={BackNextAndCancelBtn.VERIFY_CODE}
                onBack={() => setShowConfirmationModal(true)}
                backText={BackNextAndCancelBtn.CANCEL_GENERIC}
                nextType="submit"
              />
            </form>
          );
        }}
      </Form>
      <ConfirmLeaveOtpModal
        visible={showConfirmationModal}
        isNewAccountOpeningFlow={
          securityEligibilityFlow === SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP
        }
        onConfirm={() => history.replace(`${Routes.NAO}/5`)}
        onExit={() => setShowConfirmationModal(false)}
      />
    </PageWithCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateOtp);
