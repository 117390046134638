import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';

const logoDark = getImageSrc(ImagesFileNames.syfBlackLogoSvg);

export default (theme: Theme): StyleRules => ({
  logoLink: {
    border: '0',
    display: 'block',
    width: 'fit-content',
    lineHeight: '0',
  },
  logoDark: {
    backgroundImage: `url(${logoDark.toString()})`,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: pxToRem(30),
    },
  },
  adaHidden: {
    overflow: 'hidden',
    width: 0,
    height: 0,
    opacity: 0,
    display: 'inline-block',
    position: 'absolute',
  },
});
