import { createStyles } from '@material-ui/core';
import breakpoints from '../../styles/breakpoints';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import Colors from '../colors/colors';

export const lightFontWeight = 100;
export const normalFontWeight = 400;
export const mediumFontWeight = 500;
export const boldFontWeight = 700;

export const Typography = createStyles({
  root: {},
  header1: {
    fontSize: pxToRem(30),
    fontWeight: boldFontWeight,
    [breakpoints.up('md')]: {
      fontSize: pxToRem(50),
    },
    '&:focus': {
      outline: 'solid',
    },
  },
  header2: {
    fontSize: pxToRem(30),
    fontWeight: 'bold',
    lineHeight: 1,
  },
  header3: {
    fontSize: pxToRem(24),
    fontWeight: 500,
    lineHeight: 1.17,
  },
  header3MediumToSmall: {
    fontSize: pxToRem(20),
  },
  header4: {
    fontSize: pxToRem(18),
    fontWeight: 'bold',
    lineHeight: 1,
  },
  header1Banner: {
    fontSize: pxToRem(30),
    fontWeight: boldFontWeight,
    [breakpoints.up('md')]: {
      fontSize: pxToRem(32),
    },
    [breakpoints.up('lg')]: {
      fontSize: pxToRem(40),
    },
    [breakpoints.up('xl')]: {
      fontSize: pxToRem(50),
    },
  },
  informationalText: {
    fontSize: pxToRem(24),
    lineHeight: 1.17,
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(18),
    },
  },
  mainNav: {
    fontSize: pxToRem(20),
    lineHeight: 1,
    letterSpacing: pxToRem(0.5),
  },
  bodySmall: {
    fontSize: pxToRem(12),
    lineHeight: 1.6,
  },
  bodyMedium: {
    fontSize: pxToRem(16),
    lineHeight: 1,
    fontWeight: mediumFontWeight,
  },
  body: {
    fontSize: pxToRem(14),
    lineHeight: 1.43,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(16),
      lineHeight: 1.25,
    },
  },
  progressText: {
    fontSize: pxToRem(9),
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(12),
    },
  },
  formTextDefault: {
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 100,
  },
  formTextComplete: {
    extend: 'formTextDefault',
    fontWeight: 'normal',
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: pxToRem(14),
    lineHeight: 1.43,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: boldFontWeight,
    lineHeight: 1,
    letterSpacing: pxToRem(0.4),
  },
  linkText: {
    fontSize: pxToRem(14),
    fontWeight: boldFontWeight,
    lineHeight: 1.29,
    letterSpacing: pxToRem(1.2),
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: Colors.optimumBlue,
    '&:hover': {
      color: Colors.hoverState,
    },
  },
  linkTextOpenAccount: {
    fontSize: pxToRem(14),
    fontWeight: boldFontWeight,
    lineHeight: 1.29,
    letterSpacing: pxToRem(1.2),
    textTransform: 'capitalize',
    textDecoration: 'none',
    color: Colors.optimumBlue,
    '&:hover': {
      color: Colors.hoverState,
    },
  },
  inlineLinkText: {
    fontWeight: mediumFontWeight,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textDecoration: 'none',
    color: Colors.clickable,
    '&:hover': {
      color: Colors.hoverState,
      textDecoration: 'underline',
    },
  },
  subLinkText: {
    fontSize: pxToRem(14),
    fontWeight: normalFontWeight,
    lineHeight: 1.8,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: Colors.charcoalGrey,
    pointerEvents: 'none',
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(16),
      lineHeight: 1.25,
    },
  },
  subLinkTextActive: {
    fontSize: pxToRem(14),
    textTransformation: 'none',
    fontWeight: mediumFontWeight,
    lineHeight: 1.8,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: Colors.optimumBlue,
    pointerEvents: 'none',
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(16),
      lineHeight: 1.25,
    },
  },
  standaloneSidebarLinkText: {
    fontSize: pxToRem(14),
    lineHeight: 1.29,
    letterSpacing: pxToRem(1.2),
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: Colors.charcoalGrey,
    '&:hover, &.active': {
      fontWeight: boldFontWeight,
      color: Colors.optimumBlue,
    },
  },
  newAccountHeaderLinkText: {
    fontSize: pxToRem(14),
    fontWeight: 'bold',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: Colors.optimumBlue,
    pointerEvents: 'none',
    textTransform: 'uppercase',
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(16),
      lineHeight: 1.25,
    },
  },
  exploreProductsSubHeader: {
    marginBottom: pxToRem(30),
    marginTop: pxToRem(25),
    color: Colors.white,
    maxWidth: pxToRem(850),
    fontWeight: normalFontWeight,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontSize: pxToRem(16),
    lineHeight: 1.25,
  },
  screenReaderText: {
    position: 'absolute',
    height: '1px',
    width: '1px',
    clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
    overflow: 'hidden !important',
    '&:before': {
      content: 'attr(data-hidden-text)',
    },
  },
  bold: {
    fontWeight: boldFontWeight,
  },
  medium: {
    fontWeight: mediumFontWeight,
  },
  normal: {
    fontWeight: normalFontWeight,
  },
  light: {
    fontWeight: lightFontWeight,
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  lowerCase: {
    textTransform: 'lowercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  italic: {
    fontStyle: 'italic',
  },
  noActivity: {
    fontSize: pxToRem(14),
  },
  // Overridable properties
  overrideColor: {},
  overrideFontWeight: {},
  overrideFontStyle: {},
  overridePaddingBottom: {},
  overrideTextTransform: {},
  overrideLetterSpacing: {},
});

export default (theme: Theme) => ({
  ...Typography,
  [theme.breakpoints.up('sm')]: {
    body: {
      fontSize: pxToRem(16),
      lineHeight: 1.25,
    },
  },
});
