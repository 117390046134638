export const DocumentTypes = Object.freeze({
  NOTICES: 'notices',
});

export const DocumentsPageText = Object.freeze({
  LEFT_PANEL_TITLE: 'Be Prepared',
  LEFT_PANEL_DESCRIPTION:
    'View or print tax information, account notices, or other documents from Synchrony Bank in one place.',
});

export const EDeliveryStatus = Object.freeze({
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
});

export type ETaxDocumentPreferences = {
  areTermsAccepted: boolean;
  edeliveryStatus: typeof EDeliveryStatus[keyof typeof EDeliveryStatus];
};

export type ETaxDocumentPreferencesResponse = {
  taxDocumentsPreferences: ETaxDocumentPreferences;
};

export const DocumentURL = Object.freeze({
  BANK_FORMS_URL: 'https://www.synchronybank.com/banking-resources/forms/',
});

export type ViewDocument = {
  documentId: string;
  name: string;
  date: string;
  type: string;
  accountLastFour: string;
};

export type ViewDocumentsResponse = {
  more: boolean;
  documents: ViewDocument[];
};
