import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import AccountManagementPage from '../../components/accountManagementPage/accountManagementPage';
import { Header2, SubtitleText } from '../../components/typography/typography';
import type { Name } from '../../utilities/types';
import type { ReduxState } from '../../reducers/index';
import { CancelCardTitle, CancelCardDescription, NoActiveCards } from './cancelCard.constants';
import type { Card } from './cancelCard.service';
import { fetchActiveCards, cancelCardService, CardTypes } from './cancelCard.service';
import useEffectOnce from '../../utilities/reactHooks';
import { useCancelCardStyles } from './cancelCard.styles';
import Error from '../../components/error/error';
import TileList from '../../components/tileList/tileList';
import { CardTile } from './CardTile';
import formatCustomerFullName from '../../utilities/formatCustomerFullName';
import { fetchCustomerInfo as fetchCustomerInfoAction } from '../newAccountOpening/applications.actions';
import {
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import { setFlashMessage } from '../../components/flashMessage/flashMessage.reducer';
import {
  ANALYTICS_CANCEL_CARD_START_FAILURE,
  ANALYTICS_CANCEL_CARD_START_SUCCESS,
  ANALYTICS_CANCEL_CARD_SUBMIT_FAILURE,
  ANALYTICS_CANCEL_CARD_SUBMIT_SUCCESS,
} from '../../analytics/actions';
import { useAlignmentStyles } from '../../styles/layout/alignment.styles';

type StateProps = {
  name?: Name;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  name: state.applications.customerInfo && state.applications.customerInfo.name,
});

const pageViewActions = Object.freeze({
  startSuccess: ANALYTICS_CANCEL_CARD_START_SUCCESS,
  startFailure: ANALYTICS_CANCEL_CARD_START_FAILURE,
  submitSuccess: ANALYTICS_CANCEL_CARD_SUBMIT_SUCCESS,
  submitFailure: ANALYTICS_CANCEL_CARD_SUBMIT_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type DispatchProps = {
  fetchCustomerInfo: () => Promise<void>;
  setErrorFlashMessage: (message?: string) => void;
  setSuccessFlashMessage: () => void;
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchCustomerInfo: async () => {
    dispatch(fetchCustomerInfoAction());
  },
  setErrorFlashMessage: (message) => {
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText: message || FlashMessageText.GENERIC_ERROR,
      })
    );
  },
  setSuccessFlashMessage: () => {
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.SUCCESS,
        messageText: FlashMessageText.CANCEL_CARD_SUCCESS_MESSAGE,
      })
    );
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) => {
    dispatch({ type: pageViewAction, payload });
  },
});

type AllProps = StateProps & DispatchProps;

export const CancelCard = ({
  fetchCustomerInfo,
  name,
  setSuccessFlashMessage,
  setErrorFlashMessage,
  recordAnalyticsPageView,
}: AllProps) => {
  const [cards, setCards] = useState<Card[] | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [isEditMode, setIsEditMode] = useState(false);

  const onCancel = async (cardToRemove): Promise<void> => {
    try {
      setIsEditMode(true);
      await cancelCardService(cardToRemove.cardId);
      recordAnalyticsPageView(
        pageViewActions.submitSuccess,
        cardToRemove.cardType !== CardTypes.ATM
          ? cardToRemove.cardType.toLowerCase()
          : cardToRemove.cardType
      );
      const updatedCards = cards && cards.filter((card) => card.cardId !== cardToRemove.cardId);
      setCards(updatedCards);
      setSuccessFlashMessage();
    } catch (err) {
      setErrorFlashMessage(err && err.data && err.data.message);
      recordAnalyticsPageView(pageViewActions.submitFailure);
    } finally {
      setIsEditMode(false);
    }
  };

  const fullName = name && formatCustomerFullName(name);
  const renderCard = (card) => (
    <CardTile
      card={card}
      name={fullName}
      onCancelClicked={onCancel}
      isCancelDisabled={isEditMode}
    />
  );
  const getCustomerInfo = async () => {
    if (!name) {
      await fetchCustomerInfo();
    }
  };

  const getCards = async () => {
    let response;
    setIsLoading(true);
    setErrorMessage('');
    try {
      const [activeCardsResponse] = await Promise.all([fetchActiveCards(), getCustomerInfo()]);
      response = activeCardsResponse;
    } catch (err) {
      setErrorMessage((err && err.data && err.data.message) || FlashMessageText.GENERIC_ERROR);
      recordAnalyticsPageView(pageViewActions.startFailure);
      return;
    } finally {
      setIsLoading(false);
    }
    setCards(response);
    recordAnalyticsPageView(pageViewActions.startSuccess);
  };

  useEffectOnce(() => {
    getCards();
  });

  const classes = useCancelCardStyles();
  const alignmentClasses = useAlignmentStyles();

  const gridItems =
    cards && cards.length ? (
      <TileList list={cards} listItemRenderProp={renderCard} />
    ) : (
      <SubtitleText component="div" className={alignmentClasses.horizontalPadding}>
        {NoActiveCards}
      </SubtitleText>
    );

  return (
    <AccountManagementPage
      pageHeading="ATM Cards"
      loading={isLoading}
      fullHeightLayoutClassName={classes.fullHeightLayout}
    >
      {errorMessage ? (
        <Error onClick={getCards} message={errorMessage} center />
      ) : (
        <>
          <Grid container spacing={3} className={alignmentClasses.allPadding}>
            <Grid item xs={12}>
              <Header2 data-test="cancel-card-header">{CancelCardTitle}</Header2>
            </Grid>
            <Grid item xs={12}>
              <p data-test="cancel-card-description">{CancelCardDescription}</p>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {gridItems}
          </Grid>
        </>
      )}
    </AccountManagementPage>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelCard);
