import React, { useEffect, useState } from 'react';
import { IVAChat } from 'cb-iva-react-web-ui';
import { IVAChatProps } from 'cb-iva-react-web-ui/IVAChat';
import { RootStateOrAny, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { getSessionID, getSessionParam, isSessionParamValid } from '../../utilities/sessionID';
import sessionVariables from '../../utilities/sessionIdConstants';
import getSearchParam from '../../utilities/url';
import { QueryParams } from '../routes/routes.constants';
import useIsPageLoaded from '../../utilities/hooks/useIsPageLoaded';

const useStyles = makeStyles(() => ({
  chatHolder: {
    'z-index': 1101,
  },
}));

const SydneyIVAChat = () => {
  const [showIVA, setShowIVA] = useState(false);
  const classes = useStyles();

  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.authenticate);
  const { customerInfo } = useSelector((state: RootStateOrAny) => state.applications);

  const codeParam = getSearchParam(window.location, QueryParams.code);
  const stateParam = getSearchParam(window.location, QueryParams.state);

  const isCustomerLoggedIn = (codeParam !== null && stateParam !== null) || isLoggedIn;
  const isCustomerIdSet =
    customerInfo?.customerId !== undefined ||
    isSessionParamValid(sessionVariables.SESSION_ID_CUSTOMER);

  const isPageLoaded = useIsPageLoaded();

  useEffect(() => {
    if (isPageLoaded) {
      if (isCustomerLoggedIn) {
        if (isCustomerIdSet) {
          setShowIVA(true);
        } else {
          setShowIVA(false);
        }
      } else {
        setShowIVA(true);
      }
    }
  }, [isPageLoaded, isCustomerLoggedIn, isCustomerIdSet]);

  const sydneyProps: IVAChatProps = {
    apiHostName: window.__config__.IVA_HOST_URL,
    clientSessionId: getSessionID(),
    isLoggedIn: isCustomerIdSet,
    showIvaChatIcon: true,
    platform: 'BANK',
    userMetadata: { cifId: getSessionParam(sessionVariables.SESSION_ID_CUSTOMER) },
  };

  return (
    showIVA && (
      <div className={classes.chatHolder} data-test="sydney-iva-container">
        <IVAChat {...sydneyProps} />
      </div>
    )
  );
};

export default SydneyIVAChat;
