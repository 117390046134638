const jointOwnerPrefixes = ['First', 'Second', 'Third'];

export default Object.freeze({
  ADDRESS: 'Address',
  ALERTS_AND_OFFERS: 'Account Alerts & Offers',
  ALERTS: 'Account Alerts',
  BENEFICIARIES: 'Beneficiaries',
  CANCEL_CARD_SIDEBAR: 'Atm/Debit Cards',
  COMBINED_STATEMENTS: 'Combined Statements',
  MANAGE_CARDS: 'Manage Cards',
  CITIZENSHIP: 'Country of Citizenship',
  DOB: 'Date of Birth',
  DOCUMENTS: 'Documents',
  E_PREFERENCES: 'ePreferences',
  E_PREFERENCES_ACCOUNTS: 'E-PREFERENCES',
  EMAIL: 'Email',
  EMPLOYER: 'Employer',
  EXISTING_JOINT_OWNER: (index: number) => `${jointOwnerPrefixes[index]} Joint Owner`,
  FIRST_NAME: 'First Name',
  FULL_NAME: 'Full Name',
  LAST_NAME: 'Last Name',
  MIDDLE_NAME: 'Middle Name',
  NEW_JOINT_OWNER: (index: number) => `${jointOwnerPrefixes[index]} Joint Owner Information`,
  NEW_TRANSFER: 'New Transfer',
  OCCUPATION: 'Occupation',
  OFFERS: 'Offers',
  PASSWORD: 'Password',
  PHONE_NUMBER: 'Phone Number',
  PHONE_TYPE: 'Phone Type',
  PREFIX: 'Prefix',
  PRIMARY_OWNER: 'Primary Account Owner Information',
  PROFILE: 'My Profile',
  QUICK_LINKS: 'Quick Links',
  REWARDS: 'Rewards',
  RATES_AND_TERMS: 'Rates and Terms',
  SAVINGS_ACCOUNTS: 'Savings Accounts',
  SECONDARY_PHONE_NUMBER: 'Secondary Phone Number',
  SECONDARY_PHONE_TYPE: 'Secondary Phone Type',
  SECURITY: 'Security',
  SEND_MESSAGE: 'Send Message',
  SOURCE_OF_FUNDS: 'Source of Funds',
  SPECIAL_OFFERS: 'Special Offers',
  SSN: 'SSN',
  SUFFIX: 'Suffix',
  USERNAME: 'Username',
  VIEW_INBOX: 'View Inbox',
  ATM_LOCATOR: 'ATM Locator',
  SIGN_OUT: 'Sign Out',
  RESOURCES: 'Resources',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATION_CENTER: 'Notification Center',
  ACCOUNTS: 'My Accounts',
});
