import createTheme from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { primaryButtonStyles, secondaryButtonStyles } from '../components/buttons/buttons';
import Colors from '../components/colors/colors';
import { Z_INDEX_INPUT_LABEL } from './zIndex';
import pxToRem from '../utilities/pxToRem';
import { lightFontWeight } from '../components/typography/typography.styles';

const placeholderVisible: CSSProperties = {
  color: Colors.placeholderText,
};

const focusVisibleState: CSSProperties = {
  outline: `solid ${pxToRem(2)} ${Colors.blue800}`,
};

const overrides: Overrides = {
  MuiAccordionSummary: {
    root: {
      '&$focusVisible': {
        backgroundColor: 'unset',
        ...focusVisibleState,
      },
    },
  },
  MuiButton: {
    root: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 'auto',
      padding: '0.5rem 1.25rem',
      borderRadius: '0 !important',
      boxShadow: 'none !important',
      fontWeight: 'bold',
      '&:active': {
        boxShadow: 'none',
      },
      '&$focusVisible': {
        boxShadow: 'none',
        outlineOffset: '4px',
        ...focusVisibleState,
      },
      '&:focus': {
        boxShadow: 'none',
      },
      '&:hover': {
        boxShadow: 'none',
      },
      '&$disabled': {
        color: Colors.buttonDisabledState,
      },
    },
    // white button
    contained: {
      margin: `${pxToRem(22)} ${pxToRem(16)} 0 0`,
      backgroundColor: Colors.white,
      // !important used to override MUI button style precedence in production
      // possibly caused by https://github.com/mui-org/material-ui/issues/16609
      color: `${Colors.blue700} !important`,
      border: 0,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: Colors.blue100,
      },
      '&:active': {
        backgroundColor: Colors.blue400,
      },
      '&:disabled': {
        backgroundColor: Colors.disabledState,
        border: `solid 2px ${Colors.disabledState}`,
        color: Colors.white,
        '&:hover, &:focus': {
          backgroundColor: Colors.disabledState,
        },
      },
      '&$focusVisible': {
        backgroundColor: Colors.white,
        outlineColor: `${Colors.blue200}`,
      },
    },
    // blue button
    containedPrimary: {
      ...primaryButtonStyles,
    },
    // blue outlined button
    outlinedPrimary: {
      ...secondaryButtonStyles,
    },
  },
  MuiIconButton: {
    root: {
      // '&$focusVisible' does not work here for some reason, so we're mentioning the compiled class explicitly
      '&.Mui-focusVisible': {
        boxShadow: 'none',
        ...focusVisibleState,
      },
    },
  },
  MuiExpansionPanel: {
    root: {
      // Overwrite Paper elevation box shadow
      boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.06)',
      // Prevents accordion animation between adjacent ExpansionPanels
      '&$expanded': {
        margin: undefined,
      },
      // Removes border between adjacent ExpansionPanels
      '&::before': {
        display: 'none',
      },
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      display: 'block',
    },
  },
  MuiExpansionPanelSummary: {
    root: {
      '&$expanded': {
        // Keep min-height the same as when not expanded
        minHeight: undefined,
      },
      '&$focused': {
        backgroundColor: 'unset',
        ...focusVisibleState,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expandIcon: {
      '&$expanded': {
        transform: 'rotate(90deg)',
      },
    },
  },
  MuiFormControl: {
    root: {
      display: 'flex',
      marginTop: '1.0rem',
    },
  },
  MuiInputBase: {
    root: {
      fontWeight: 500,
      '&$error': {
        color: Colors.inlineError,
      },
    },
    input: {
      '&::-webkit-input-placeholder': placeholderVisible,
      '&::-moz-placeholder': placeholderVisible, // Firefox 19+
      '&:-ms-input-placeholder': placeholderVisible, // IE 11
      '&::-ms-input-placeholder': placeholderVisible, // Edge
    },
  },
  MuiInput: {
    root: {
      backgroundColor: '#fff',
      borderBottomColor: Colors.optimumBlue,
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: '1rem',
      '&$disabled': {
        backgroundColor: '#dfdfdf',
      },
    },
    // When combined with the above root styles, these add up to the equivalent of padding: '1rem'
    inputMultiline: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingRight: '1rem',
    },
  },
  MuiInputLabel: {
    root: {
      zIndex: Z_INDEX_INPUT_LABEL, // raise label above the inputs it animates over
    },
    formControl: {
      transform: 'translate(1.0rem, 2.0rem) scale(1)',
    },
  },
  MuiFormLabel: {
    root: {
      color: 'inherit',
      '&$focused': {
        color: 'inherit',
      },
      '&$error': {
        color: Colors.labelText,
      },
    },
  },
  MuiFormControlLabel: {
    label: {
      fontWeight: 400,
      color: Colors.labelText,
    },
  },
  MuiFormHelperText: {
    root: {
      fontWeight: lightFontWeight,
      fontSize: pxToRem(14),
      paddingLeft: pxToRem(15),
      color: Colors.normalHelperText,
      '&$error': {
        color: Colors.inlineError,
      },
    },
  },
  MuiMenuItem: {
    root: {
      // '&$focusVisible' does not work here for some reason, so we're mentioning the compiled class explicitly
      '&.Mui-focusVisible:not(.Mui-selected)': {
        ...focusVisibleState,
        backgroundColor: 'transparent',
        outlineOffset: '-4px',
      },
    },
  },
};

export const fontFamily = '"Synchrony Sans","Avenir","Helvetica Neue",sans-serif';

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.optimumBlue,
      light: Colors.optimumBlue,
      dark: Colors.optimumBlue,
    },
    secondary: {
      main: Colors.optimumBlue,
      light: Colors.optimumBlue,
      dark: Colors.optimumBlue,
    },
    error: {
      main: Colors.brick,
      light: Colors.brick,
      dark: Colors.brick,
    },
  },
  typography: {
    fontFamily,
  },
  overrides,
});

export default () => theme;
