import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import type { FormRenderProps } from 'react-final-form';
import { Field, Form } from 'react-final-form';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import type { ErrorParams } from '../../../components/formWithImage/formWithImage';
import FormWithImage from '../../../components/formWithImage/formWithImage';
import { RenderSelectField, RenderTextField } from '../../../components/finalForm/finalFormInputs';
import type { GetTopics } from '../messages.constants';
import { MessageFieldNames } from '../messages.constants';
import { defaults } from '../../../components/error/error';
import useEffectOnce from '../../../utilities/reactHooks';
import { BackNextAndCancelBtn, ModalBtnText } from '../../../components/cms/buttonText.constants';
import BackNextAndCancel from '../../../components/buttons/backNextAndCancel';
import Routes from '../../routes/routes.constants';
import { fetchMessageTopics, submitNewMessage } from '../messages.service';
import { attachmentFilesValidation } from '../messages.validators';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { ModalText } from '../../../components/cms/blockText.constants';
import { clickTrack, clickTrackType } from '../../../analytics/clickTracking.constants';
import type { MessageForm } from '../../../utilities/types';
import AddAttachment from '../../../components/addAttachment/addAttachment';
import type { FlashMessageVariantType } from '../../../components/flashMessage/flashMessage.constants';
import { FlashMessageVariant } from '../../../components/flashMessage/flashMessage.constants';
import IgniteFlashMessage from '../../../components/flashMessage/IgniteFlashMessage';
import { requiredField } from '../../../utilities/validators';
import { ViewInboxFlashMessageTexts } from '../../viewInbox/viewInbox.constants';
import {
  ANALYTICS_SUBMIT_NEW_MESSAGE_FAILURE,
  ANALYTICS_VIEW_NEW_MESSAGE_FAILURE,
  ANALYTICS_VIEW_NEW_MESSAGE_SUCCESS,
} from '../../../analytics/actions';
import { updateFormStatus } from '../../profile/profile.actions';
import getImageSrc from '../../../utilities/getImageSrc';
import ImagesFileNames from '../../../images';
import { StandardTextPending } from '../../../components/typography/typography';
import i18n from '../../../strings/i18n';

const pageViewActions = Object.freeze({
  viewNewMessageSuccess: ANALYTICS_VIEW_NEW_MESSAGE_SUCCESS,
  viewNewMessageFailure: ANALYTICS_VIEW_NEW_MESSAGE_FAILURE,
  submitNewMessageFailure: ANALYTICS_SUBMIT_NEW_MESSAGE_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type DispatchProps = {
  goToInboxView: () => void;
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => void;
  updateFormDirtyStatus: (arg1: boolean) => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  goToInboxView: () => {
    dispatch(push(Routes.VIEW_INBOX));
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => dispatch({ type: pageViewAction }),
  updateFormDirtyStatus: (isDirty: boolean) => dispatch(updateFormStatus(isDirty)),
});

export const NewMessage = (props: DispatchProps) => {
  const history = useHistory();
  const { goToInboxView, recordAnalyticsPageView, updateFormDirtyStatus } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [messageTopics, setMessageTopics] = useState<GetTopics | null | undefined>();
  const [errorParams, setErrorParams] = useState<ErrorParams | null | undefined>(undefined);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const [flashMessage, setFlashMessage] = useState<
    | {
        messageType: FlashMessageVariantType;
        messageText: React.ReactNode;
      }
    | null
    | undefined
  >();

  const composeMessageImageUrl = getImageSrc(ImagesFileNames.messageCenterComposeJpg);
  const composeMessageImageUrlLg = getImageSrc(ImagesFileNames.messageCenterCompose_2xJpg);
  const composeMessageImageUrlXl = getImageSrc(ImagesFileNames.messageCenterCompose_3xJpg);

  const getTopics = async () => {
    let topics;
    setIsLoading(true);
    setErrorParams(undefined);

    try {
      topics = await fetchMessageTopics();
      recordAnalyticsPageView(pageViewActions.viewNewMessageSuccess);
    } catch (err) {
      setErrorParams({
        error: {
          title: defaults.title,
          message: (err && err.data && err.data.message) || defaults.message,
        },
        onClick: getTopics,
      });
      recordAnalyticsPageView(pageViewActions.viewNewMessageFailure);
    } finally {
      setIsLoading(false);
    }
    setMessageTopics(topics);
  };

  useEffectOnce(() => {
    getTopics();
  });

  const onSubmit = async (
    values: MessageForm & {
      topic: string;
    }
  ) => {
    const { topic, message, attachments } = values;

    let response;
    try {
      response = await submitNewMessage(topic, message, attachments);
    } catch (err) {
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText: ViewInboxFlashMessageTexts.ERROR,
      });
      recordAnalyticsPageView(pageViewActions.submitNewMessageFailure);
    }

    if (response) {
      const inboxLocation = {
        pathname: Routes.VIEW_INBOX,
        state: { successfullySent: true },
      };

      history.push(inboxLocation);
    }
  };

  const renderSubmitErrorFlashMessage = () => {
    if (flashMessage) {
      return (
        <IgniteFlashMessage variant={flashMessage.messageType} resetScroll>
          {flashMessage.messageText}
        </IgniteFlashMessage>
      );
    }

    return null;
  };

  return (
    <FormWithImage
      imageUrl={composeMessageImageUrl}
      imageUrlLg={composeMessageImageUrlLg}
      imageUrlXl={composeMessageImageUrlXl}
      formTitle={i18n({ MESSAGES: 'newMessageTitle' })}
      formDescription={i18n({ MESSAGES: 'newMessageTopic' })}
      loading={isLoading}
      errorParams={errorParams || undefined}
      flashMessageRenderProp={renderSubmitErrorFlashMessage}
    >
      <Form onSubmit={onSubmit} validate={attachmentFilesValidation}>
        {({
          dirty = false,
          form,
          handleSubmit,
          invalid,
          pristine,
          submitting,
          values,
        }: FormRenderProps<MessageForm>) => {
          updateFormDirtyStatus(dirty);
          return (
            <Grid container spacing={2} component="form">
              <Grid item xs={12}>
                <StandardTextPending>
                  {i18n({ MESSAGES: 'requiredFieldHelper' })}
                </StandardTextPending>
              </Grid>
              {messageTopics && (
                <Grid item xs={12} sm={6}>
                  <Field
                    name={MessageFieldNames.TOPIC}
                    label="*Topic"
                    id={MessageFieldNames.TOPIC}
                    options={messageTopics.topics}
                    component={RenderSelectField}
                    validate={requiredField(i18n({ MESSAGES: 'topicRequiredError' }))}
                    placeholder="Choose Topic"
                    data-test="select-topics"
                    ariaRequired
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  name={MessageFieldNames.MESSAGE}
                  label="*Message"
                  id={MessageFieldNames.MESSAGE}
                  component={RenderTextField}
                  placeholder=" "
                  multiline
                  rows="10"
                  shrinkLabel
                  validate={requiredField(i18n({ MESSAGES: 'messageRequiredError' }))}
                  data-test="message-text-area"
                  ariaRequired
                />
              </Grid>
              <AddAttachment form={form} values={values} />
              <Grid item xs={12}>
                <BackNextAndCancel
                  disablePrimary={pristine || invalid}
                  loading={submitting}
                  onNext={handleSubmit}
                  nextText={BackNextAndCancelBtn.SEND_MESSAGE}
                  onCancel={() => {
                    if (!pristine) {
                      setIsCancelModalVisible(true);
                    } else {
                      goToInboxView();
                    }
                  }}
                  cancelText={BackNextAndCancelBtn.BACK_TO_INBOX}
                  disableCancelClickTrack={!pristine}
                  data-test-next="send-message"
                  data-test-cancel="back-to-inbox"
                  data-track-type={clickTrackType.BUTTON}
                  data-track-cancel={clickTrack.newMessage.cancel_new_message}
                  data-track-next={clickTrack.newMessage.submit_new_message}
                />
              </Grid>
              <Prompt
                when={dirty && !submitting && !isCancelModalVisible}
                message={ModalText.EXIT_MODAL_CONTENT}
              />
              <ConfirmationModal
                visible={isCancelModalVisible}
                titleText={ModalText.CANCEL_MESSAGE_MODAL_TITLE}
                contentText={ModalText.CANCEL_MESSAGE_MODAL_CONTENT}
                confirmText={ModalBtnText.CANCEL_EXIT_BTN}
                exitText={ModalBtnText.CANCEL_NO_GO_BACK_BTN}
                data-track-title={clickTrack.newMessage.cancel_new_message}
                data-track-type={clickTrackType.BUTTON}
                onConfirm={() => {
                  goToInboxView();
                }}
                onExit={() => {
                  setIsCancelModalVisible(false);
                }}
              />
            </Grid>
          );
        }}
      </Form>
    </FormWithImage>
  );
};

export default connect(null, mapDispatchToProps)(NewMessage);
