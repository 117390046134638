import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import { Alignment } from '../../styles/layout/alignment.styles';

export const styles: StyleRules = {
  container: {
    ...Alignment.allPadding,
    textAlign: 'center',
  },
  title: {},
  message: {
    margin: `${pxToRem(10)} auto 0`,
    maxWidth: pxToRem(400),
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      fontSize: '1rem',
    },
  },
  icon: {
    marginBottom: pxToRem(30),
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1,
  },
};

export default () => styles;
