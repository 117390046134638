import { makeStyles } from '@material-ui/styles';

export const useCancelCardStyles = makeStyles({
  fullHeightLayout: {
    padding: 0,
    overflow: 'hidden',
  },
});

export default useCancelCardStyles;
