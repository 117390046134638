import axios from '../../utilities/axios';
import type {
  Beneficiary,
  CreateBeneficiary,
  PatchAccountBeneficiary,
  PatchBeneficiary,
  UpdateBeneficiaryRequest,
} from './beneficiaries.constants';
import {
  BENEFICIARIES_URL,
  PATCH_ACCOUNT_BENEFICIARIES_URL,
  PATCH_BENEFICIARIES_URL,
  UPDATE_BENEFICIARY_INFO_URL,
} from '../../utilities/route-mappings';

export type BeneficiariesResponse = {
  beneficiaries: Beneficiary[];
};

export type GetBeneficiariesService = () => Promise<BeneficiariesResponse>;

export const fetchBeneficiaries: GetBeneficiariesService = async () => {
  const res = await axios.get(BENEFICIARIES_URL());
  return res.data;
};

export type AddOrRemoveBeneficiaryService = (arg1: PatchBeneficiary) => Promise<PatchBeneficiary>;

export const addOrRemoveBeneficiary: AddOrRemoveBeneficiaryService = async (beneficiary) => {
  const response = await axios.patch(PATCH_BENEFICIARIES_URL(), beneficiary);
  return response.data;
};

export type UpdateBeneficiaryInfoService = (
  arg1: string,
  arg2: UpdateBeneficiaryRequest
) => Promise<string>;

export const updateBeneficiaryInfo: UpdateBeneficiaryInfoService = async (
  beneficiaryId,
  beneficiaryInfo
) => {
  const response = await axios.put(UPDATE_BENEFICIARY_INFO_URL(beneficiaryId), beneficiaryInfo);
  return response.data;
};

export type CreateBeneficiaryInfoService = (
  arg1: UpdateBeneficiaryRequest
) => Promise<CreateBeneficiary>;

export const createBeneficiaryInfo: CreateBeneficiaryInfoService = async (beneficiaryInfo) => {
  const response = await axios.post(BENEFICIARIES_URL(), beneficiaryInfo);
  return response.data;
};

export type AddOrRemoveAccountBeneficiaryService = (
  arg1: string,
  arg2: string,
  arg3: PatchAccountBeneficiary
) => Promise<PatchAccountBeneficiary>;

export const addOrRemoveAccountBeneficiary: AddOrRemoveAccountBeneficiaryService = async (
  accountType,
  accountId,
  accountBeneficiaries
) => {
  const response = await axios.patch(
    PATCH_ACCOUNT_BENEFICIARIES_URL(accountType, accountId),
    accountBeneficiaries
  );
  return response.data;
};

export default fetchBeneficiaries;
