export const OWCSPageNames = Object.freeze({
  SERVICING_SUPPORT: 'Servicing Support',
  DASHBOARD_PAGE: 'Dashboard Tile',
  EXPLORE_PRODUCTS: 'Explore Products',
  AO_WELCOME_PAGE: 'AOWelcome',
  DASHBOARD_SPECIAL_OFFERS_PAGE: 'Dashboard Special Offers',
  DASHBOARD_POBOX: 'POBox',
});

export const OWCSPageNamesPOBox = Object.freeze({
  PAGE_CONFRIMATION: 'Confirmation',
  PAGE_PRINT: 'Print',
});

export const OWCSPageSectionsNames = Object.freeze({
  SERVICING_BANKERS_HOURS: 'Servicing Bankers Hours',

  DASHBOARD_TILE_SECTION: 'Dashboard Tiles',
  DASHBOARD_FLASH_MESSAGE: 'Dashboard Flash Message',

  EXPLORE_PRODUCTS_INTRO: 'Explore Products - Intro Section',
  EXPLORE_PRODUCTS_CARDS: 'Explore Products - Card Section',

  AO_WELCOME_DISC_SECTION: 'AOWelcomeDiscSection',

  DASHBOARD_SPECIAL_OFFERS_SECTION: 'Dashboard Special Offers - Section',
  POBOX_SECTION: 'POBoxSection',
});
