import {
  ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE,
  ANALYTICS_VIEW_ATTACHMENT_FAILURE,
  ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE,
  ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS,
} from '../actions';
import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.message_center,
    pagefunction: pageTrack.pagefunction.compose,
    pagesubfunction: pageTrack.pagesubfunction.reply,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

const submitFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.submit_server_error }).record();
};

const viewAttachmentFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.attachment_server_error }).record();
};

export const replyMessagePageEvents = {
  [ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS]: viewSuccess,
  [ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE]: viewFailure,
  [ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE]: submitFailure,
  [ANALYTICS_VIEW_ATTACHMENT_FAILURE]: viewAttachmentFailure,
} as const;

export default replyMessagePageEvents;
