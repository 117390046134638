import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { rewardsLevels, RewardsText } from '../../containers/rewards/rewards.constants';
import {
  StandardText,
  BodyTextSmall,
  SubtitleText,
  ScreenReaderText,
} from '../typography/typography';
import useStyles from './rewardsTierChart.styles';
import i18n from '../../strings/i18n';

const RequirementsChart = () => {
  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell colSpan={6} className={classes.column} data-test="rewards-level-heading">
          <StandardText className={classes.tableHeader}>
            {i18n({ REWARDS_TABLE: 'HEADER1' })}
          </StandardText>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <StandardText aria-hidden fontWeight="medium" data-test="rewards-balance">
            {RewardsText.BALANCE}
          </StandardText>
          <ScreenReaderText>{i18n({ REWARDS_TABLE: 'HOW_TO_QUALIFY' })}</ScreenReaderText>
        </TableCell>
        {rewardsLevels.map((rewardsLevel) => (
          <TableCell align="center" key={rewardsLevel.title}>
            <SubtitleText aria-hidden>{rewardsLevel.balance}</SubtitleText>
            <ScreenReaderText>{rewardsLevel.description.replace('–', 'to')}</ScreenReaderText>
          </TableCell>
        ))}
      </TableRow>
      <TableRow aria-hidden>
        <TableCell size="small" className={classes.andOrCell} />
        <TableCell align="center" size="small" className={classes.andOrCell}>
          <BodyTextSmall>AND</BodyTextSmall>
        </TableCell>
        <TableCell align="center" size="small" className={classes.andOrCell}>
          <BodyTextSmall>OR</BodyTextSmall>
        </TableCell>
        <TableCell align="center" size="small" className={classes.andOrCell}>
          <BodyTextSmall>OR</BodyTextSmall>
        </TableCell>
        <TableCell align="center" size="small" className={classes.andOrCell}>
          <BodyTextSmall>OR</BodyTextSmall>
        </TableCell>
        <TableCell align="center" size="small" className={classes.andOrCell}>
          <BodyTextSmall>OR</BodyTextSmall>
        </TableCell>
      </TableRow>
      <TableRow aria-hidden>
        <TableCell>
          <StandardText fontWeight="medium" data-test="rewards-minimum-tenure">
            {RewardsText.MINIMUM_TENURE}
          </StandardText>
        </TableCell>
        {rewardsLevels.map((rewardsLevel) => (
          <TableCell align="center" key={rewardsLevel.title}>
            {rewardsLevel.minimumTenure}
          </TableCell>
        ))}
      </TableRow>
      <TableRow aria-hidden>
        <TableCell colSpan={6} className={classes.spacerLine} />
      </TableRow>
    </>
  );
};

export default RequirementsChart;
