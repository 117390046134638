import axios from '../../utilities/axios';
import {
  TERMS_URL,
  APPLICATIONS_TERMS_URL,
  APPLICATIONS_TERMS_V2_URL,
} from '../../utilities/route-mappings';
import type { GetTermsResponse } from '../../utilities/types';
import { v2HeaderData } from '../../utilities/apiRequestHeader';

export const fetchVerifyExternalAccountTerms = () =>
  axios.get(TERMS_URL('verify-external-accounts-trial-deposits')).then((res) => res.data.data[0]);

export const getAOTermsAndConditions = (): Promise<GetTermsResponse> =>
  axios
    .get(APPLICATIONS_TERMS_URL(), {
      params: {
        withholdingType: 'radio',
      },
    })
    .then((res) => res.data);

export const getAOTermsAndConditionsTransmit = (): Promise<GetTermsResponse> => {
  const config = v2HeaderData();
  return axios.get(APPLICATIONS_TERMS_V2_URL(), config).then((res) => res.data);
};
