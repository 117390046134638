import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import {
  ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS,
  ACTION_FETCH_COMBINED_STATEMENTS_FAILURE,
} from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
    pagefunction: pageTrack.pagefunction.combined_statements,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

export const combinedStatementsPageEvents = {
  [ACTION_FETCH_COMBINED_STATEMENTS_SUCCESS]: viewSuccess,
  [ACTION_FETCH_COMBINED_STATEMENTS_FAILURE]: viewFailure,
} as const;

export default combinedStatementsPageEvents;
