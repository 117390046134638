import React, { Ref, forwardRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import type { FormApi } from 'final-form';
import useEffectOnce from '../../utilities/reactHooks';
import type { MessageForm } from '../../utilities/types';
import { uploadAttachmentService } from '../../containers/messages/messages.service';
import useStyles from './attachmentTile.styles';
import i18n from '../../strings/i18n';
import {
  handleCurrentAttachment,
  handleAttachedFiles,
  RenderErrorMessage,
  RenderAttachmentIcon,
} from './helpers';

type Props = {
  file: File;
  form: FormApi<MessageForm>;
  handleRemoveFocus: () => void;
};

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        // Need to use split() to remove the metadata at the beginning of the string
        return resolve(reader.result.split(',')[1]);
      }
      return resolve('');
    };
    reader.onerror = (error) => reject(error);
  });

const AttachmentTile = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  const { file, form, handleRemoveFocus } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [attachmentId, setAttachmentId] = useState<string | null | undefined>();

  const handleUploadAttachment = async () => {
    const dotSeparatorIndex = file.name.lastIndexOf('.');
    const fileName = file.name.slice(0, dotSeparatorIndex);
    const fileType = file.name.slice(dotSeparatorIndex + 1);
    const content = await toBase64(file);
    let response;
    setIsLoading(true);
    setErrorMessage(null);
    try {
      response = await uploadAttachmentService(fileName, fileType, content);
    } catch (err) {
      if (err?.status < 500) {
        // 413 means Apigee errored out due to the request payload being too large
        if (err?.status === 413) {
          setErrorMessage(i18n({ MESSAGES: 'attachmentSizeError' }));
        } else {
          setErrorMessage(err?.data?.message || i18n({ MESSAGES: 'genericAttachmentError' }));
        }
      } else {
        setErrorMessage(
          <span>
            {i18n({ MESSAGES: 'genericAttachmentError' })}{' '}
            <button
              type="button"
              data-test="retry-attachment-button"
              className={classes.tryAgainButton}
              onClick={handleUploadAttachment}
            >
              Click here
            </button>{' '}
            to try again.
          </span>
        );
      }
    }
    setIsLoading(false);
    if (response) {
      setAttachmentId(response.attachmentId);
      handleCurrentAttachment({ form, response });
    }
  };

  const handleRemove = () => {
    const {
      values = {
        message: '',
      },
    } = form.getState();
    handleAttachedFiles({ attachmentId, values, handleRemoveFocus, form, file });
  };

  useEffectOnce(() => {
    handleUploadAttachment();
  });

  return (
    <Grid component="li" item xs={12} sm={6}>
      <div className={errorMessage ? classes.errorContainer : classes.container}>
        <div className={classes.fileNameContainer}>
          <RenderAttachmentIcon
            isLoading={isLoading}
            handleRemove={handleRemove}
            file={file}
            classes={classes}
            ref={ref}
          />
          <span className={classes.fileName}>{file.name}</span>
        </div>

        <RenderErrorMessage errorMessage={errorMessage} classes={classes} />
      </div>
    </Grid>
  );
});

export default AttachmentTile;
