export default (value: string | number | null | undefined): string => {
  if (!value) {
    return '';
  }

  const stringValue = value.toString();

  // if it's already masked (from BSL), just return it
  if (stringValue.match(/^\d{3}-\d{2}-\d{4}/)) return stringValue;

  // normalize string and remove all unnecessary characters
  const sanitizedSsn = stringValue.replace(/[^\d]/g, '');

  if (sanitizedSsn.length === 9) {
    // reformat and return SSN
    return sanitizedSsn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }

  return sanitizedSsn;
};
