import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from '../../utilities/types';
import type { ReduxState } from '../../reducers';
import { buildRefreshSessionUrl } from '../../utilities/authentication';
import { ACTION_SET_NEEDS_SESSION_REFRESH } from '../authenticate/authenticate.reducer';

type StateProps = {
  needsSessionRefresh: boolean;
};

const mapStateToProps = (state: ReduxState) => ({
  needsSessionRefresh: state.authenticate.needsSessionRefresh,
});

type DispatchProps = {
  resetNeedsSessionRefresh: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetNeedsSessionRefresh: () => {
    dispatch({ type: ACTION_SET_NEEDS_SESSION_REFRESH, payload: false });
  },
});

type AllProps = StateProps & DispatchProps;

// Refresh Session is used when auth requires the user to refresh their session.
// For example: after a user successfully changes their username.
const RefreshSession = (props: AllProps) => {
  const { needsSessionRefresh, resetNeedsSessionRefresh } = props;

  if (needsSessionRefresh) {
    return (
      <iframe
        onLoad={resetNeedsSessionRefresh}
        title="refreshSession"
        width="0"
        height="0"
        // The refreshSessionUrl will redirect to /callback with the new code.
        // Authenticate container sends a message with the code back to us.
        src={buildRefreshSessionUrl()}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          border: 0,
          display: 'none',
        }}
        // The iframe needs to allow same origin to have access to the session cookie in order to
        // get the new session cookie
        sandbox="allow-same-origin allow-scripts"
      />
    );
  }
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshSession);
