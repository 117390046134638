import { http, HttpResponse } from 'msw';
import { INTERNAL_ACCOUNTS_ACTIVITY_URL } from '../../utilities/route-mappings';
import accountDetailsData from '../data/accountDetails';

const getAccountDetails = http.get(
  INTERNAL_ACCOUNTS_ACTIVITY_URL('Certificate%20of%20Deposit', ':accountId'),
  (req) => {
    const { accountId } = req.params;
    return HttpResponse.json(accountDetailsData(accountId as string));
  }
);

export default [getAccountDetails];
