import pageAnalytics from '../pageAnalytics';
import {
  ANALYTICS_ADD_OVERDRAFT_NO_ELIGIBLE_ACCOUNTS,
  ANALYTICS_ADD_OVERDRAFT_START,
  ANALYTICS_REMOVE_OVERDRAFT_START,
  ANALYTICS_OVERDRAFT_ERROR,
  ANALYTICS_OVERDRAFT_MOUNTED,
  ANALYTICS_ADD_OVERDRAFT_CONFIRMED,
  ANALYTICS_REMOVE_OVERDRAFT_CONFIRMED,
} from '../actions';

const setPageInfo = (pagename: string) => () => {
  pageAnalytics.addInfo({
    account_type: 'generic',
    pagekind: 'activity',
    pagefunction: 'odp',
    pagename,
  });
};

const emitPageview = (pagename: string) => () => {
  setPageInfo(pagename)();
  pageAnalytics.record();
};

export const overdraftProtectionPageEvents = {
  [ANALYTICS_ADD_OVERDRAFT_START]: setPageInfo('add_start'),
  [ANALYTICS_ADD_OVERDRAFT_NO_ELIGIBLE_ACCOUNTS]: setPageInfo('no_eligible_accounts'),
  [ANALYTICS_REMOVE_OVERDRAFT_START]: setPageInfo('remove_start'),
  [ANALYTICS_OVERDRAFT_ERROR]: emitPageview('error'),
  [ANALYTICS_OVERDRAFT_MOUNTED]: () => pageAnalytics.record(),
  [ANALYTICS_ADD_OVERDRAFT_CONFIRMED]: emitPageview('add_confirmation'),
  [ANALYTICS_REMOVE_OVERDRAFT_CONFIRMED]: emitPageview('remove_confirmation'),
} as const;

export default overdraftProtectionPageEvents;
