import React from 'react';
import { SynchronyInfo } from '../../components/cms/blockText.constants';

export const ExploreProductsPageText = Object.freeze({
  APY: 'APY*',
  HEADER: 'Find the Synchrony Bank account that’s right for you.',
  NO_MINIMUM_BALANCE: 'No Minimum Balance',
  PAGE_TITLE: `Explore Savings Accounts - ${SynchronyInfo.BANKNAME}`,
  UP_TO: 'up to',
  SUB_HEADER: (
    <>
      With every Synchrony Bank account enjoy no minimum balance and no monthly fees. Manage your
      account from almost anywhere online or in the Synchrony Bank mobile app.
    </>
  ),
});

export const ProductPerks = Object.freeze({
  HYS: {
    SUB_TITLE: 'Earn more with the flexibility to withdraw at any time.',
    INFO: 'Access your money whenever you need it online, by phone or via ATM.',
  },
  CD: {
    SUB_TITLE: 'Lock in a great rate by securing your funds for the duration of the term.',
    INFO: 'Fund within 15 calendar days, and if the rate is higher on the day you fund, we’ll give you the best rate.',
  },
  MMA: {
    SUB_TITLE: 'Save with your cash close at hand—and the ability to write checks.',
    INFO: 'Write checks and withdraw your money online, by phone or via ATM.',
  },
});
