export default function currencyFormatter(value: number | null | undefined | string, cents = true) {
  if (value === undefined || value === null) {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatterNoCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (cents ? formatter : formatterNoCents).format(parseFloat(String(value)));
}

export const normalizeDollarStringToNumber = (value?: string | null) =>
  value && value.replace(/[^.0-9]/g, '');
