import * as React from 'react';
import { withStyles } from '@material-ui/core';
import TileList from './tileList';
import styles from './tileList.styles';
import emptyTile from './emptyTile';

export const NoActivityMessage = {
  NO_PENDING_ACTIVITY: 'No pending transactions',
  NO_RECENT_ACTIVITY: 'No posted transactions',
  NO_RECURRING_TRANSFERS: 'No recurring transfers',
  NO_SCHEDULED_TRANSFERS: 'No scheduled one-time transfers',
  NO_STATEMENTS: 'No statements for this period',
  NO_DOCUMENTS: 'No documents for this period',
  NO_MESSAGES: 'No messages',
} as const;

type NoActivityMessageValues = typeof NoActivityMessage[keyof typeof NoActivityMessage];

type NoActivityTileListProps = {
  header?: React.ReactNode;
  message: NoActivityMessageValues | string;
};

const NoActivityTileListUnstyled = (props: NoActivityTileListProps) => (
  <TileList
    header={props.header}
    list={[props.message]}
    listItemRenderProp={emptyTile}
    data-test={`no-activity-list-${props.message.split(' ')[1]}`}
    {...props}
  />
);

export const NoActivityTileList = withStyles(styles)(NoActivityTileListUnstyled);
