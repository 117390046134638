import { Hidden } from '@material-ui/core';
import React, { useEffect, useRef, ReactElement, ReactNode, MouseEventHandler } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from './formWithImage.styles';
import type { ServiceError, Theme } from '../../utilities/types';
import {
  LargestFormHeader,
  LargeHeader,
  InformationalSectionHeader,
  StandardText,
} from '../typography/typography';
import LoadingOverlay from '../loadingOverlay/loadingOverlay';
import Logo from '../logo/logo';
import Error from '../error/error';
import ProgressStepper from '../progressStepper/progressStepper';
import { SynchronyInfo } from '../cms/blockText.constants';
import Footer from '../footer/footer';
import AnimatedLoadingOverlay from '../loadingOverlay/animatedLoadingOverlay';
import { FormHeaderProps } from './typings';
import FDICLogo from '../fdicLogo/fdicLogo';
import shouldFdicLogoRender from '../../utilities/shouldFdicLogoRender';

export const FormHeader = ({
  id,
  className,
  component,
  children,
  path,
  'data-test': dataTest = 'header-page-title',
  ...rest
}: FormHeaderProps) => {
  const classes = useStyles(rest);
  const headerRef = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (headerRef && headerRef.current && headerRef.current.focus) {
        headerRef.current.focus();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [path]);

  return (
    <LargestFormHeader
      id={id}
      component={component}
      className={classNames(classes.ctaContainer, className)}
      data-test={dataTest}
      ref={headerRef}
      {...rest}
    >
      {children}
    </LargestFormHeader>
  );
};

export const FormCTADescription = (props: FormHeaderProps) => {
  const {
    children,
    className,
    'aria-hidden': ariaHidden,
    'data-test': dataTest = 'cta-description',
    id,
  } = props;
  const classes = useStyles(props);
  return (
    <StandardText
      className={classNames(classes.ctaDescription, className)}
      component="div"
      data-test={dataTest}
      aria-hidden={ariaHidden}
      id={id}
    >
      {children}
    </StandardText>
  );
};

export type ErrorParams = {
  error: ServiceError;
  goBackPath?: string;
  goBackText?: string;
  onClick?: MouseEventHandler;
};

type Props = {
  children?: ReactNode | ReactNode[];
  ctaDescription?: ReactNode;
  ctaText?: string;
  errorParams?: ErrorParams;
  flashMessageRenderProp?: () => ReactNode;
  formDescription?: string;
  formTitle?: string;
  imageUrl: string;
  imageUrlLg?: string;
  imageUrlXl?: string;
  loading?: boolean;
  marketingFooter?: ReactNode;
  progressStepper?: ReactElement<typeof ProgressStepper>;
  showMarketingMessage?: boolean;
  shouldFocusTitle?: boolean;
  useLoadingAnimation?: boolean;
  fullWidthFormContent?: boolean;
  ['data-test-title']?: string;
  ['data-test-description']?: string;
  ['data-test-cta-text']?: string;
  classes?: ClassNameMap;
};

const backgroundImageStyles = (imageUrl1x: string, imageUrl2x?: string, imageUrl3x?: string) =>
  makeStyles((theme: Theme) => ({
    backgroundImageDisplay: {
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${imageUrl1x})`,
      },
      [theme.breakpoints.up('lg')]: {
        backgroundImage: `url(${imageUrl2x || imageUrl3x || imageUrl1x})`,
      },
      [theme.breakpoints.up('xl')]: {
        backgroundImage: `url(${imageUrl3x || imageUrl2x || imageUrl1x})`,
      },
    },
  }));

type AnimationProps = {
  useLoadingAnimation: boolean;
  loading?: boolean;
};

const LoadingAnimation = (props: AnimationProps) => {
  const { useLoadingAnimation, loading } = props;
  return (
    <>
      {useLoadingAnimation ? (
        <AnimatedLoadingOverlay isLoading={loading} variant="formWithImage" />
      ) : (
        <LoadingOverlay isLoading={loading} variant="formWithImage" />
      )}
    </>
  );
};

type MarketingMessageProps = {
  showMarketingMessage?: boolean;
  classes: ClassNameMap;
};

const MarketingMessage = (props: MarketingMessageProps) => {
  const { showMarketingMessage, classes } = props;
  return (
    <>
      {showMarketingMessage && (
        <div className={classes.marketingMessage}>
          <LargeHeader component="div">
            No minimum balance. <br />
            No monthly fees. <br />
            <span className={classes.consistently}>More for you.</span>
          </LargeHeader>
        </div>
      )}
    </>
  );
};

type ShowFormDescriptionProps = {
  dataDescription?: string;
  classes: ClassNameMap;
  formDescription?: string;
};

const ShowFormDescription = (props: ShowFormDescriptionProps) => {
  const { formDescription, classes, dataDescription } = props;
  return (
    <>
      {formDescription && (
        <div className={classes.subtitle} data-test={dataDescription}>
          <StandardText>{formDescription}</StandardText>
        </div>
      )}
    </>
  );
};

type ShowCtaDescriptionProps = {
  dataDescription?: string;
  ctaDescription?: ReactNode;
};

const ShowCtaDescription = (props: ShowCtaDescriptionProps) => {
  const { ctaDescription, dataDescription } = props;
  return (
    <>
      {ctaDescription && (
        <FormCTADescription data-test={dataDescription}>{ctaDescription}</FormCTADescription>
      )}
    </>
  );
};

type ViewHeaderProps = {
  ctaText?: string;
  id?: string;
  frmHeaderClassName?: string;
  formTitle?: string;
  ctaTextComponent?: string;
  dataCtaText?: string;
  path?: string;
  dataTestTitle?: string;
  informationHeaderClassName?: string;
};

const ViewHeader = (props: ViewHeaderProps) => {
  const {
    ctaText,
    id,
    frmHeaderClassName,
    formTitle,
    ctaTextComponent,
    dataCtaText,
    path,
    dataTestTitle,
    informationHeaderClassName,
  } = props;

  const headerInfoRef = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (headerInfoRef && headerInfoRef.current && headerInfoRef.current.focus) {
        headerInfoRef.current.focus();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [path]);

  return (
    <>
      {ctaText ? (
        <FormHeader
          className={frmHeaderClassName}
          id={id}
          component={ctaTextComponent}
          data-test={dataCtaText}
          path={path}
        >
          {ctaText}
        </FormHeader>
      ) : (
        formTitle && (
          <InformationalSectionHeader
            id="page-title"
            component="h1"
            data-test={dataTestTitle}
            className={informationHeaderClassName}
            ref={headerInfoRef}
          >
            {formTitle}
          </InformationalSectionHeader>
        )
      )}
    </>
  );
};

const checkMainPageTitle = (formTitle?: string) => {
  return formTitle ? 'page-title' : undefined;
};

const checkFormHeaderTitle = (formTitle?: string) => {
  return formTitle ? undefined : 'page-title';
};

const checkFormTitle = (bankName: string, pageSubtitle?: string) => {
  return pageSubtitle ? `${pageSubtitle} - ${bankName}` : bankName;
};

const checkCtaTextComponent = (formTitle?: string) => {
  return formTitle ? 'h2' : 'h1';
};

const FormWithImage = (props: Props) => {
  const mainRef = useRef(null);
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.shouldFocusTitle && mainRef && mainRef.current && mainRef.current.focus) {
      mainRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    children,
    ctaDescription,
    ctaText,
    errorParams,
    flashMessageRenderProp,
    formDescription,
    formTitle,
    imageUrl,
    imageUrlLg,
    imageUrlXl,
    loading,
    marketingFooter,
    progressStepper,
    showMarketingMessage,
    useLoadingAnimation = true,
  } = props;
  // If the formWithImage has a formTitle, then the ctaText should be a subordinate
  // h2. Otherwise, ctaText should be h1.
  const ctaTextComponent = checkCtaTextComponent(formTitle);
  const pageSubtitle = formTitle || ctaText;
  const pageTitle = checkFormTitle(SynchronyInfo.BANKNAME, pageSubtitle);
  const backgroundImageClasses = backgroundImageStyles(imageUrl, imageUrlLg, imageUrlXl)();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title data-test="page-title">{pageTitle}</title>
      </Helmet>
      <LoadingAnimation useLoadingAnimation={useLoadingAnimation} loading={loading} />

      <main
        tabIndex={-1}
        ref={mainRef}
        className={classes.leftContainer}
        aria-labelledby={checkMainPageTitle(formTitle)}
      >
        <div className={classes.formContainer}>
          <div className={classes.formHeaderContainer}>
            <Logo isDark isWithFdicLogo />
            {shouldFdicLogoRender() && (
              <div className={classes.formFdicWrapper}>
                <FDICLogo />
              </div>
            )}
          </div>
          {errorParams ? (
            <>
              <div className={classes.errorContainer}>
                <Error
                  title={errorParams.error.title}
                  message={errorParams.error.message}
                  onClick={errorParams.onClick}
                />
              </div>
              {errorParams.goBackPath && errorParams.goBackText && (
                <div className={classes.backLinkContainer}>
                  <Link className={classes.backLink} to={errorParams.goBackPath}>
                    {errorParams.goBackText}
                  </Link>
                </div>
              )}
            </>
          ) : (
            <div
              className={classNames(
                classes.formContent,
                ctaText && classes.formContentWithCtaText,
                progressStepper && classes.formContentWithProgressStepper,
                flashMessageRenderProp && classes.formContentWithFlashMessage
              )}
            >
              {progressStepper}
              {flashMessageRenderProp && (
                <div
                  className={classNames(
                    classes.flashMessageContainer,
                    progressStepper && classes.flashMessageContainerWithProgressStepper
                  )}
                  data-test="flash-message-container"
                >
                  {flashMessageRenderProp()}
                </div>
              )}
              <ViewHeader
                ctaText={ctaText}
                frmHeaderClassName={progressStepper && classes.ctaContainerWithProgressStepper}
                id={checkFormHeaderTitle(formTitle)}
                formTitle={formTitle}
                ctaTextComponent={ctaTextComponent}
                dataCtaText={props['data-test-cta-text']}
                path={location.pathname}
                dataTestTitle={props['data-test-title']}
                informationHeaderClassName={classNames(
                  classes.formTitle,
                  progressStepper && classes.formTitleWithProgressStepper
                )}
              />

              <ShowFormDescription
                classes={classes}
                dataDescription={props['data-test-description']}
                formDescription={formDescription}
              />

              <ShowCtaDescription
                dataDescription={props['data-test-description']}
                ctaDescription={ctaDescription}
              />

              {children}
            </div>
          )}
        </div>
        <Footer isLight isHalfScreen />
      </main>

      <Hidden smDown>
        <div
          className={classNames(
            classes.imageBackground,
            backgroundImageClasses.backgroundImageDisplay
          )}
        >
          <MarketingMessage showMarketingMessage={showMarketingMessage} classes={classes} />

          <div className={classes.marketingFooter}>{marketingFooter}</div>
        </div>
      </Hidden>
    </>
  );
};

export default FormWithImage;
