export const saveInteraction = () => {
  if (!window.newrelic || !window.newrelic.interaction || !window.newrelic.save) return;
  window.newrelic.interaction().save();
};

export const setCustomAttribute = (title, value = '') => {
  if (!window.newrelic || !window.newrelic.setCustomAttribute || !title) return;
  window.newrelic.setCustomAttribute(title, value);
};

export const setFinished = () => {
  if (!window.newrelic || !window.newrelic.finished) return;
  window.newrelic.finished();
};

export const setErrorNotice = (error) => {
  if (!window.newrelic || !window.newrelic.noticeError || !error) return;
  window.newrelic.noticeError(error);
};

export const addPageAction = (name, attributes = {}) => {
  if (!window.newrelic || !window.newrelic.addPageAction || !name) return;
  window.newrelic.addPageAction(name, attributes);
};

const initNewRelic = (history) => {
  const onRouteChange = () => {
    // Wait for react-helmet to apply document.title changes
    setTimeout(function onDocumentUpdate() {
      saveInteraction();
    }, 0);
  };

  if (typeof window.newrelic === 'object') {
    setFinished();
    if (history && history.listen) {
      history.listen(onRouteChange);
    }
  }
};

export default initNewRelic;
