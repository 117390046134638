import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import type { FormRenderProps } from 'react-final-form';
import { Field, Form } from 'react-final-form';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { Prompt, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import type { ErrorParams } from '../../../components/formWithImage/formWithImage';
import FormWithImage from '../../../components/formWithImage/formWithImage';
import { RenderTextField } from '../../../components/finalForm/finalFormInputs';
import { defaults } from '../../../components/error/error';
import useEffectOnce from '../../../utilities/reactHooks';
import { BackNextAndCancelBtn, ModalBtnText } from '../../../components/cms/buttonText.constants';
import BackNextAndCancel from '../../../components/buttons/backNextAndCancel';
import type { MessageDetailsRouterProps } from '../../routes/routes.constants';
import Routes from '../../routes/routes.constants';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { ModalText } from '../../../components/cms/blockText.constants';
import { clickTrack, clickTrackType } from '../../../analytics/clickTracking.constants';
import type { Message, MessageDetails } from '../messages.constants';
import { MessageFieldNames } from '../messages.constants';
import type { MessageForm } from '../../../utilities/types';
import { useMessageContainerStyles } from '../messages.styles';
import AddAttachment from '../../../components/addAttachment/addAttachment';
import {
  fetchViewAttachmentLink,
  fetchMessageDetails,
  submitReplyMessage,
} from '../messages.service';
import { attachmentFilesValidation } from '../messages.validators';
import { StandardText, StandardTextPending } from '../../../components/typography/typography';
import type { FlashMessageVariantType } from '../../../components/flashMessage/flashMessage.constants';
import { FlashMessageVariant } from '../../../components/flashMessage/flashMessage.constants';
import { ViewInboxFlashMessageTexts } from '../../viewInbox/viewInbox.constants';
import IgniteFlashMessage from '../../../components/flashMessage/IgniteFlashMessage';
import { IconGhostButton } from '../../../components/buttons/buttons';
import { VIEW_MESSAGE_ATTACHMENT_URL } from '../../../utilities/route-mappings';
import {
  ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE,
  ANALYTICS_VIEW_ATTACHMENT_FAILURE,
  ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE,
  ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS,
} from '../../../analytics/actions';
import { requiredField } from '../../../utilities/validators';
import { updateFormStatus } from '../../profile/profile.actions';
import PopupBlockedModal from '../../../components/modal/PopupBlockedModal';
import getImageSrc from '../../../utilities/getImageSrc';
import ImagesFileNames from '../../../images';
import SVGImage from '../../../components/svgImage';
import i18n from '../../../strings/i18n';

const pageViewActions = Object.freeze({
  submitReplyMessageFailure: ANALYTICS_SUBMIT_REPLY_MESSAGE_FAILURE,
  viewAttachmentFailure: ANALYTICS_VIEW_ATTACHMENT_FAILURE,
  viewReplyMessageFailure: ANALYTICS_VIEW_REPLY_MESSAGE_FAILURE,
  viewReplyMessageSuccess: ANALYTICS_VIEW_REPLY_MESSAGE_SUCCESS,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];
type DispatchProps = {
  goToInboxView: () => void;
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => void;
  updateFormDirtyStatus: (arg1: boolean) => void;
};

type AllProps = DispatchProps & MessageDetailsRouterProps;

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  goToInboxView: () => {
    dispatch(push(Routes.VIEW_INBOX));
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => dispatch({ type: pageViewAction }),
  updateFormDirtyStatus: (isDirty: boolean) => dispatch(updateFormStatus(isDirty)),
});

export const ReplyMessage = ({
  goToInboxView,
  match,
  recordAnalyticsPageView,
  updateFormDirtyStatus,
}: AllProps) => {
  const history = useHistory();
  const [messageDetails, setMessageDetails] = useState<MessageDetails | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorParams, setErrorParams] = useState<ErrorParams | null | undefined>(undefined);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [popupBlockedModalUrl, setPopupBlockedModalUrl] = useState<string | undefined>();
  const [flashMessage, setFlashMessage] = useState<
    | {
        messageType: FlashMessageVariantType;
        messageText: React.ReactNode;
      }
    | null
    | undefined
  >();

  // Attachments
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentToView, setAttachmentToView] = useState<string | null | undefined>();
  const [isViewAttachmentError, setViewAttachmentError] = useState(false);

  // Get Images URLs from S3 bucket
  const replyMessageImageUrl = getImageSrc(ImagesFileNames.messageCenterReplyJpg);
  const replyMessageImageUrlLg = getImageSrc(ImagesFileNames.messageCenterReply_2xJpg);
  const replyMessageImageUrlXl = getImageSrc(ImagesFileNames.messageCenterReply_3xJpg);

  const classes = useMessageContainerStyles();

  const getMessageDetails = async () => {
    let messageDetailsResponse;
    setIsLoading(true);
    setErrorParams(undefined);
    try {
      messageDetailsResponse = await fetchMessageDetails(match.params.threadId);
      setMessageDetails(messageDetailsResponse);
      recordAnalyticsPageView(pageViewActions.viewReplyMessageSuccess);
    } catch (err) {
      setErrorParams({
        error: {
          title: defaults.title,
          message: (err && err.data && err.data.message) || defaults.message,
        },
        onClick: getMessageDetails,
      });
      recordAnalyticsPageView(pageViewActions.viewReplyMessageFailure);
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    getMessageDetails();
  });

  const onSubmit = async (values: MessageForm) => {
    const { message, attachments } = values;

    let response;
    try {
      response = await submitReplyMessage(match.params.threadId, message, attachments);
    } catch (err) {
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText: ViewInboxFlashMessageTexts.ERROR,
      });
      recordAnalyticsPageView(pageViewActions.submitReplyMessageFailure);
    }

    if (response) {
      const inboxLocation = {
        pathname: Routes.VIEW_INBOX,
        state: { successfullySent: true },
      };

      history.push(inboxLocation);
    }
  };

  const renderSubmitErrorFlashMessage = () => {
    if (flashMessage) {
      return (
        <IgniteFlashMessage variant={flashMessage.messageType} resetScroll>
          {flashMessage.messageText}
        </IgniteFlashMessage>
      );
    }
    return null;
  };

  const viewAttachment = async (messageId: string, attachmentName: string) => {
    setAttachmentToView(`${messageId}_${attachmentName}`);
    setIsAttachmentLoading(true);
    setViewAttachmentError(false);
    let response;
    try {
      response = await fetchViewAttachmentLink(match.params.threadId, messageId, attachmentName);
    } catch {
      setViewAttachmentError(true);
      recordAnalyticsPageView(pageViewActions.viewAttachmentFailure);
    } finally {
      setIsAttachmentLoading(false);
    }
    if (response) {
      setAttachmentToView(undefined);
      const url = VIEW_MESSAGE_ATTACHMENT_URL(response.attachmentLink);
      const open = window.open(url, '');

      if (!open) {
        // Could not open PDF, most likely because of a popup
        setPopupBlockedModalUrl(url);
      }
    }
  };

  const renderAttachments = (message: Message) =>
    message.attachments.map((attachment) => {
      const key = `${message.messageId}_${attachment.attachmentName}`;
      return (
        <Grid item xs={12} sm={6} key={key} className={classes.responseAttachmentItemContainer}>
          <IconGhostButton
            icon={<SVGImage imageName={ImagesFileNames.iconAttachmentSvg} />}
            onClick={() => viewAttachment(message.messageId, attachment.attachmentName)}
            loading={isAttachmentLoading && attachmentToView === key}
            data-test="view-response-attachment-button"
            data-track-title={clickTrack.replyMessage.view_attachment}
            data-track-type={clickTrackType.LINK}
            className={classes.openAttachmentButton}
          >
            <span className={classes.responseAttachmentItem}>{attachment.attachmentName}</span>
          </IconGhostButton>
          {attachmentToView === key && isViewAttachmentError && (
            <div
              className={classes.responseViewAttachmentError}
              data-test="view-attachment-error-container"
            >
              {i18n({ MESSAGES: 'viewAttachmentError' })}
            </div>
          )}
        </Grid>
      );
    });

  return (
    <FormWithImage
      imageUrl={replyMessageImageUrl}
      imageUrlLg={replyMessageImageUrlLg}
      imageUrlXl={replyMessageImageUrlXl}
      formTitle={messageDetails ? messageDetails.subject : ''}
      loading={isLoading}
      errorParams={errorParams || undefined}
      flashMessageRenderProp={renderSubmitErrorFlashMessage}
    >
      <Form onSubmit={onSubmit} validate={attachmentFilesValidation}>
        {({
          dirty = false,
          form,
          handleSubmit,
          invalid,
          pristine,
          submitting,
          values,
        }: FormRenderProps<MessageForm>) => {
          updateFormDirtyStatus(dirty);
          return (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                className={classes.responseMessageItemContainer}
                data-test="response-message-container"
              >
                {messageDetails &&
                  messageDetails.messages.map((message) => {
                    return (
                      <Grid
                        item
                        key={message.messageId}
                        className={classes.responseMessageTextItem}
                      >
                        <StandardText>From: {message.from}</StandardText>
                        <br />
                        <StandardText>To: {message.to}</StandardText>
                        <br />
                        <StandardText>Date: {moment(message.date).format('ll')}</StandardText>
                        <br />
                        <br />
                        <StandardText>{message.text}</StandardText>
                      </Grid>
                    );
                  })}
              </Grid>
              {messageDetails &&
                messageDetails.messages.map((message) => {
                  return message.attachments && renderAttachments(message);
                })}
              <Grid item xs={12}>
                <StandardTextPending>
                  {i18n({ MESSAGES: 'requiredFieldHelper' })}
                </StandardTextPending>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={MessageFieldNames.MESSAGE}
                  label="*Reply"
                  id={MessageFieldNames.MESSAGE}
                  component={RenderTextField}
                  validate={requiredField(i18n({ MESSAGES: 'messageRequiredError' }))}
                  placeholder=" "
                  multiline
                  rows="10"
                  shrinkLabel
                  data-test="reply-message-text-area"
                  ariaRequired
                />
              </Grid>
              <AddAttachment form={form} values={values} />
              <Grid item xs={12}>
                <BackNextAndCancel
                  disablePrimary={pristine || invalid}
                  loading={submitting}
                  onNext={handleSubmit}
                  nextText={BackNextAndCancelBtn.SEND_MESSAGE}
                  onCancel={() => {
                    if (!pristine) {
                      setIsCancelModalVisible(true);
                    } else {
                      goToInboxView();
                    }
                  }}
                  cancelText={BackNextAndCancelBtn.BACK_TO_INBOX}
                  disableCancelClickTrack={!pristine}
                  data-test-next="send-message-reply"
                  data-test-cancel="back-to-inbox-reply"
                  data-track-type={clickTrackType.BUTTON}
                  data-track-cancel={clickTrack.replyMessage.cancel_reply}
                  data-track-next={clickTrack.replyMessage.submit_reply}
                />
              </Grid>
              <Prompt
                when={dirty && !submitting && !isCancelModalVisible}
                message={ModalText.EXIT_MODAL_CONTENT}
              />
              <ConfirmationModal
                visible={isCancelModalVisible}
                titleText={ModalText.CANCEL_MESSAGE_MODAL_TITLE}
                contentText={ModalText.CANCEL_MESSAGE_MODAL_CONTENT}
                confirmText={ModalBtnText.YES_LEAVE_PAGE_BTN}
                exitText={ModalBtnText.CANCEL_NO_GO_BACK_BTN}
                data-track-title={clickTrack.replyMessage.cancel_reply}
                data-track-type={clickTrackType.BUTTON}
                onConfirm={() => {
                  goToInboxView();
                }}
                onExit={() => {
                  setIsCancelModalVisible(false);
                }}
              />
              <PopupBlockedModal
                retryUrl={popupBlockedModalUrl}
                onClose={() => {
                  setPopupBlockedModalUrl(undefined);
                }}
              />
            </Grid>
          );
        }}
      </Form>
    </FormWithImage>
  );
};

export default connect(null, mapDispatchToProps)(ReplyMessage);
