import type { AllAccount } from 'Domain/Account/AllAccount';
import type { RecurringInterval } from '../transfers.types';
import type { ServiceError } from '../../../utilities/types';

export type Transfer = {
  fromAccount: AllAccount;
  toAccount: AllAccount;
  amount: string;
  requestDate: Date;
  endDate: Date | null | undefined;
  frequency: string;
};

type TransferInfo = {
  successMessage: string;
};

export type NewTransferState = {
  transferInfo: TransferInfo;
  isLoading: boolean;
  isEstimatedDateLoading?: boolean;
  isPending: boolean;
  transfer?: Transfer;
  pendingTransfer?: Transfer;
  estimatedPostDate?: Date;
  frequencies: RecurringInterval[];
  isValidAmount: boolean;
  newTransferError?: ServiceError;
};

export const getInitialState = (): NewTransferState => ({
  transferInfo: { successMessage: '' },
  isLoading: true,
  isEstimatedDateLoading: false,
  frequencies: [],
  isValidAmount: false,
  isPending: false,
});

export const ACTION_REDIRECT_CONFIRM_TRANSFER = 'ACTION_REDIRECT_CONFIRM_TRANSFER';
export const ACTION_CREATE_TRANSFER_REQUEST = 'ACTION_CREATE_TRANSFER_REQUEST';
export const ACTION_CREATE_TRANSFER_SUCCESS = 'ACTION_CREATE_TRANSFER_SUCCESS';
export const ACTION_CLEAR_PENDING_TRANSFER = 'ACTION_CLEAR_PENDING_TRANSFER';
export const ACTION_UPDATE_ESTIMATED_POST_DATE = 'ACTION_UPDATE_ESTIMATED_POST_DATE';
export const ACTION_FETCH_TRANSFER_FREQUENCY_SUCCESS = 'ACTION_FETCH_TRANSFER_FREQUENCY_SUCCESS';
export const ACTION_UPDATE_AMOUNT_VALIDATION = 'ACTION_UPDATE_AMOUNT_VALIDATION';
export const ACTION_TOGGLE_LOADING = 'ACTION_TOGGLE_LOADING';
export const ACTION_NEW_TRANSFER_FAILURE = 'ACTION_NEW_TRANSFER_FAILURE';
export const ACTION_CREATE_TRANSFER_FAILURE = 'ACTION_CREATE_TRANSFER_FAILURE';
export const ACTION_CREATE_TRANSFER_FAILURE_MESSAGE = 'ACTION_CREATE_TRANSFER_FAILURE_MESSAGE';
export const ACTION_CLEAR_NEW_TRANSFER_ERROR = 'ACTION_CLEAR_NEW_TRANSFER_ERROR';
export const ACTION_TOGGLE_TRANSFER_DATE_LOADING = 'ACTION_TOGGLE_TRANSFER_DATE_LOADING';

export type TransferResponse = {
  successMessage: string;
  confirmationNumber?: string;
  estimatedPostDate?: Date;
};
export type EstimatedDeliveryDateResponse = {
  estimatedPostDate: Date;
};

export type TransferFrequencyResponse = {
  data: {
    frequencies: RecurringInterval[];
  };
};

export type NewTransferAction =
  | {
      type: 'ACTION_CREATE_TRANSFER_SUCCESS';
      payload: TransferResponse;
    }
  | {
      type: 'ACTION_CREATE_TRANSFER_FAILURE';
    }
  | {
      type: 'ACTION_CREATE_TRANSFER_REQUEST';
    }
  | {
      type: 'ACTION_TOGGLE_LOADING';
      payload: boolean;
    }
  | {
      type: 'ACTION_REDIRECT_CONFIRM_TRANSFER';
      payload: Transfer;
    }
  | {
      type: typeof ACTION_CLEAR_PENDING_TRANSFER;
    }
  | {
      type: 'ACTION_UPDATE_ESTIMATED_POST_DATE';
      payload?: Date;
    }
  | {
      type: 'ACTION_FETCH_TRANSFER_FREQUENCY_SUCCESS';
      payload: RecurringInterval[];
    }
  | {
      type: 'ACTION_UPDATE_AMOUNT_VALIDATION';
      payload: boolean;
    }
  | {
      type: 'ACTION_NEW_TRANSFER_FAILURE';
      payload: ServiceError;
    }
  | {
      type: 'ACTION_CLEAR_NEW_TRANSFER_ERROR';
    }
  | {
      type: 'ACTION_TOGGLE_TRANSFER_DATE_LOADING';
      payload: boolean;
    };

export default (
  state: NewTransferState | null | undefined = getInitialState(),
  action: NewTransferAction = undefined
): NewTransferState => {
  switch (action.type) {
    case ACTION_CREATE_TRANSFER_SUCCESS:
      return {
        ...state,
        pendingTransfer: undefined,
        transferInfo: action.payload,
        isPending: false,
      };
    case ACTION_CREATE_TRANSFER_REQUEST:
      return { ...state, isPending: true };
    case ACTION_CREATE_TRANSFER_FAILURE:
      return { ...state, isPending: false };
    case ACTION_REDIRECT_CONFIRM_TRANSFER:
      return {
        ...state,
        transfer: action.payload,
        pendingTransfer: action.payload,
        isLoading: false,
      };
    case ACTION_CLEAR_PENDING_TRANSFER:
      return {
        ...state,
        pendingTransfer: undefined,
      };
    case ACTION_UPDATE_ESTIMATED_POST_DATE:
      return { ...state, estimatedPostDate: action.payload };
    case ACTION_FETCH_TRANSFER_FREQUENCY_SUCCESS:
      return { ...state, frequencies: action.payload };
    case ACTION_UPDATE_AMOUNT_VALIDATION:
      return { ...state, isValidAmount: action.payload };
    case ACTION_TOGGLE_LOADING:
      return { ...state, isLoading: action.payload };
    case ACTION_TOGGLE_TRANSFER_DATE_LOADING:
      return { ...state, isEstimatedDateLoading: action.payload };
    case ACTION_NEW_TRANSFER_FAILURE:
      return { ...state, newTransferError: action.payload };
    case ACTION_CLEAR_NEW_TRANSFER_ERROR:
      return { ...state, newTransferError: undefined };
    default:
      return { ...state };
  }
};
