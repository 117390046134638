import Routes from '../../containers/routes/routes.constants';
import i18n from '../../strings/i18n';

const globalWindow = window || global;

export const handleCDApiAttribute = () => {
  if (globalWindow.cdApi) {
    globalWindow.cdApi.startNewSession();
    globalWindow.cdApi.setCustomerSessionId(sessionStorage.getItem('session_id'));
    globalWindow.cdApi.setCustomerBrand(i18n({ bioCatchBrandName: 'brandName' }));
  }
};

const changeContextValue = (currentPath: string) => {
  switch (currentPath) {
    case Routes.DASHBOARD:
      return 'Dashboard';

    case Routes.SECURITY:
      return 'Edit_Security';

    case Routes.OTP_INITIATE:
      return 'Ignite_Initiate_OTP';

    case Routes.OTP_VALIDATE:
      return 'Ignite_Validate_OTP';

    case Routes.PROFILE:
      return 'Edit_User_Profile';

    case Routes.PREFERENCES:
      return 'ePreferences_Setting';

    case Routes.NEW_TRANSFER:
      return 'new_Transfer';

    case Routes.ADD_EXTERNAL_ACCOUNT_OPTIONS:
      return 'Add_External_Account';

    case Routes.ADD_EXTERNAL_ACCOUNT_NUM:
      return 'Add_External_Account';

    default:
      return 'Other';
  }
};

export default (currentLocation: string) => {
  if (globalWindow.cdApi && globalWindow.cdApi.changeContext) {
    globalWindow.cdApi.changeContext(changeContextValue(currentLocation));
  }
};
