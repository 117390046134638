import axios from '../utilities/axios';
import {
  LISTS_STATES_URL,
  LISTS_OCCUPATIONS_URL,
  LISTS_NATIONS_URL,
} from '../utilities/route-mappings';

export const fetchStateListService = () => axios.get(LISTS_STATES_URL()).then((res) => res.data);

export const fetchOccupationListService = () =>
  axios.get(LISTS_OCCUPATIONS_URL()).then((res) => res.data);

export const fetchNationsListService = () => axios.get(LISTS_NATIONS_URL()).then((res) => res.data);

export default fetchStateListService;
