import { applyMiddleware, createStore, combineReducers } from 'redux';
import type { Reducer } from 'redux';
import { History } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import timerMiddleware from 'redux-timer-middleware';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import type { Store } from '../utilities/types';
import { pageAnalyticsMiddleware } from '../analytics/pageAnalytics';
import rootReducers from '../reducers';

type ReducerObject = {
  [key: string]: any;
};

type DynamicStore = Store & {
  asyncReducers: ReducerObject;
  injectReducer: (key: string, asyncReducer: Reducer<any, any>) => void;
};

function createReducer(staticReducers: ReducerObject, asyncReducers: ReducerObject) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

export default (history: History) => {
  const composeEnhancers = composeWithDevTools({ trace: true });
  const middleware = [thunk, routerMiddleware(history), timerMiddleware, pageAnalyticsMiddleware];

  const staticReducers = {
    ...rootReducers,
    router: connectRouter(history),
  };

  const asyncReducers = {};

  // Typescript doesn't like injectReducer, so cast to any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store: any = createStore(
    createReducer(staticReducers, asyncReducers),
    composeEnhancers(applyMiddleware(...middleware))
  );

  // Allow dynamically injecting reducers for codesplitting
  // https://redux.js.org/recipes/code-splitting/#defining-an-injectreducer-function

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = asyncReducers;

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key: string, asyncReducer: Reducer<any, any>) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(staticReducers, asyncReducers));
  };
  return store;
};
