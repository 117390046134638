import type { TransferCategory, TransferType } from './transfers.services';
import type { TransferAccount, BasicAccount, RecurringInterval } from './transfers.types';
import type { ServiceError } from '../../utilities/types';
import generateEditRemoveId from '../../utilities/generateEditRemoveId';

type TransferStatus = 'EXPIRED' | 'NEW' | 'ACTIVE' | 'INACTIVE';
type TransferInfo = {
  successMessage: string;
};

export type TransferActivity = {
  amount: string;
  endDate: string;
  estimatedPostOn: string;
  fromAccount: TransferAccount;
  internal: boolean;
  isCancelable: boolean;
  isEditable: boolean;
  recurrenceType: RecurringInterval;
  toAccount: TransferAccount;
  transferId: string;
  transferCategory: TransferCategory;
  transferType?: TransferType;
  status?: TransferStatus;
  sendDate: string;
  editableFrequencies?: RecurringInterval[];
  length?: number;
  editId?: string;
  removeId?: string;
  findIndex?: (arg1: Record<string, string>) => number;
};

export type TransfersState = {
  isLoading: boolean;
  isRemoving: boolean;
  transferActivity?: TransferActivity[];
  error?: ServiceError;
  transferInfo?: TransferInfo;
  frequencies?: [];
  isValidAmount?: boolean;
};

const getInitialState = (): TransfersState => ({
  isLoading: false,
  isRemoving: false,
});

export const ACTION_GET_TRANSFER_ACTIVITY_SUCCESS = 'ACTION_GET_TRANSFER_ACTIVITY_SUCCESS';
export const ACTION_GET_TRANSFER_ACTIVITY_REQUEST = 'ACTION_GET_TRANSFER_ACTIVITY_REQUEST';
export const ACTION_GET_TRANSFER_ACTIVITY_FAILURE = 'ACTION_GET_TRANSFER_ACTIVITY_FAILURE';
export const ACTION_DELETE_TRANSFER_REQUEST = 'ACTION_DELETE_TRANSFER_REQUEST';
export const ACTION_DELETE_TRANSFER_FAILURE = 'ACTION_DELETE_TRANSFER_FAILURE';
export const ACTION_DELETE_TRANSFER_SUCCESS = 'ACTION_DELETE_TRANSFER_SUCCESS';

export type TransferListResponse = {
  data: {
    transfers: [
      {
        fromAccount: BasicAccount;
        toAccount: BasicAccount;
        recurrenceType: {
          value: string;
          name: string;
        };
        amount: string;
        estimatedPostOn: string;
      }
    ];
  };
};

export type TransferAction =
  | {
      type: 'ACTION_GET_TRANSFER_ACTIVITY_SUCCESS';
      payload: TransferActivity[];
    }
  | {
      type: 'ACTION_GET_TRANSFER_ACTIVITY_REQUEST';
    }
  | {
      type: 'ACTION_GET_TRANSFER_ACTIVITY_FAILURE';
      payload: ServiceError;
    }
  | {
      type: 'ACTION_DELETE_TRANSFER_REQUEST';
    }
  | {
      type: 'ACTION_DELETE_TRANSFER_FAILURE';
    }
  | {
      type: 'ACTION_DELETE_TRANSFER_SUCCESS';
      payload: {
        scheduledOrRecurringTransferId: string;
        transferType: TransferType;
        transferCategory: TransferCategory;
      };
    };

const filterDeleted = (
  transferActivityList: TransferActivity[],
  transferId: string
): TransferActivity[] => {
  const filterTransfers = (currentTransfer) => currentTransfer.transferId !== transferId;
  return transferActivityList.filter(filterTransfers);
};
export default (
  state: TransfersState | null | undefined = getInitialState(),
  action: TransferAction = undefined
) => {
  switch (action.type) {
    case ACTION_GET_TRANSFER_ACTIVITY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case ACTION_GET_TRANSFER_ACTIVITY_SUCCESS:
      return {
        ...state,
        transferActivity: generateEditRemoveId(action.payload, 'transferId'),
        isLoading: false,
      };
    case ACTION_GET_TRANSFER_ACTIVITY_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case ACTION_DELETE_TRANSFER_REQUEST:
      return { ...state, isRemoving: true };
    case ACTION_DELETE_TRANSFER_FAILURE:
      return { ...state, isRemoving: false };
    case ACTION_DELETE_TRANSFER_SUCCESS:
      return {
        ...state,
        isRemoving: false,
        transferActivity: filterDeleted(
          state.transferActivity ? state.transferActivity : [],
          action.payload.scheduledOrRecurringTransferId
        ),
      };
    default:
      return { ...state };
  }
};
