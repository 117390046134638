import type { AllAccountTypeName } from 'Domain/Account/AllAccount';
import type { YodleeAccount } from 'Domain/Account/ExternalAccount';
import type { YodleeAggregatorsResponse } from '../newAccountOpening/funding/fundNewAccount.service';

export const YodleeErrors = {
  SUBMIT: 'submitYodlee',
  CHUNK_ERROR: 'chunkError',
  TOKEN: 'token',
} as const;

export type YodleeError = typeof YodleeErrors[keyof typeof YodleeErrors];

export type AddExternalAccountState = {
  isLoading: boolean;
  accountErrorMessage?: string;
  yodleeErrorMessage?: string;
  yodleeErrorRetryAttempts?: number;
  yodleeErrorRetryHandler?: () => Promise<void>;
  yodleeRetriableError?: YodleeError;
  yodleeAccounts?: YodleeAggregatorsResponse;
  yodleeAccountIds?: string[];
  bankName?: string;
  accountType?: AllAccountTypeName;
  accountNumber?: string;
  externalId?: string;
  yodleeAccount?: YodleeAccount;
  isInstantlyVerified?: boolean;
  startedFlow?: ExternalAccountState;
};

export type ExternalAccountState = {
  page?: string;
  accountId?: string;
};

export const ACTION_ADD_EXTERNAL_ACCOUNT_REQUEST = 'ACTION_ADD_EXTERNAL_ACCOUNT_REQUEST';
export const ACTION_ADD_EXTERNAL_ACCOUNT_SUCCESS = 'ACTION_ADD_EXTERNAL_ACCOUNT_SUCCESS';
export const ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED = 'ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED';
export const ACTION_VALIDATE_ROUTING_NUMBER_REJECTED = 'ACTION_VALIDATE_ROUTING_NUMBER_REJECTED';
export const ACTION_CLEAR_ADD_EXTERNAL_ACCOUNT_ERROR = 'ACTION_CLEAR_ADD_EXTERNAL_ACCOUNT_ERROR';
export const ACTION_CHOOSE_EXTERNAL_ACCOUNT_OPTION_LOADED =
  'ACTION_CHOOSE_EXTERNAL_ACCOUNT_OPTION_LOADED';
export const ACTION_VALIDATE_EXTERNAL_ACCOUNT_REQUEST = 'ACTION_VALIDATE_EXTERNAL_ACCOUNT_REQUEST';
export const ACTION_ADD_YODLEE_ACCOUNT = 'ACTION_ADD_YODLEE_ACCOUNT';
export const ACTION_SET_IS_INSTANTLY_VERIFIED = 'ACTION_SET_IS_INSTANTLY_VERIFIED';
export const ACTION_CLEAR_YODLEE_ACCOUNT = 'ACTION_CLEAR_YODLEE_ACCOUNT';
export const ACTION_YODLEE_RETRIABLE_ERROR = 'ACTION_YODLEE_RETRIABLE_ERROR';
export const ACTION_YODLEE_FAILURE = 'ACTION_YODLEE_FAILURE';
export const ACTION_YODLEE_RESET_ERRORS = 'ACTION_YODLEE_RESET_ERRORS';
export const ACTION_YODLEE_FLOW_STARTS = 'ACTION_YODLEE_FLOW_STARTS';
export const ACTION_SET_BANK_NAME = 'ACTION_SET_BANK_NAME';
export const ACTION_ADD_YODLEE_ACCOUNTS_REQUEST = 'ACTION_ADD_YODLEE_ACCOUNTS_REQUEST';
export const ACTION_ADD_YODLEE_ACCOUNTS_SUCCESS = 'ACTION_ADD_YODLEE_ACCOUNTS_SUCCESS';
export const ACTION_ADD_YODLEE_ACCOUNTS_FAILURE = 'ACTION_ADD_YODLEE_ACCOUNTS_FAILURE';
type RetriableYodleeError = {
  errorType: YodleeError;
  errorRetryHandler: () => Promise<void>;
  errorMessage?: string;
};
export type AddExternalAccountAction =
  | {
      type: 'ACTION_ADD_EXTERNAL_ACCOUNT_REQUEST';
      payload: {
        accountType: AllAccountTypeName;
        accountNumber: string;
      };
    }
  | {
      type: typeof ACTION_ADD_EXTERNAL_ACCOUNT_SUCCESS;
      payload: boolean;
    }
  | {
      type: 'ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED';
      payload: string;
    }
  | {
      type: typeof ACTION_VALIDATE_ROUTING_NUMBER_REJECTED;
    }
  | {
      type: 'ACTION_CLEAR_ADD_EXTERNAL_ACCOUNT_ERROR';
    }
  | {
      type: 'ACTION_SET_BANK_NAME';
      payload: string;
    }
  | {
      type: 'ACTION_ADD_YODLEE_ACCOUNT';
      payload: YodleeAccount;
    }
  | {
      type: 'ACTION_SET_IS_INSTANTLY_VERIFIED';
      payload: string;
    }
  | {
      type: typeof ACTION_CHOOSE_EXTERNAL_ACCOUNT_OPTION_LOADED;
    }
  | {
      type: typeof ACTION_CLEAR_YODLEE_ACCOUNT;
    }
  | {
      type: typeof ACTION_VALIDATE_EXTERNAL_ACCOUNT_REQUEST;
    }
  | {
      type: typeof ACTION_YODLEE_RETRIABLE_ERROR;
      payload: RetriableYodleeError;
    }
  | {
      type: typeof ACTION_YODLEE_FAILURE;
      payload: string;
    }
  | {
      type: typeof ACTION_YODLEE_FLOW_STARTS;
      payload: ExternalAccountState;
    }
  | {
      type: typeof ACTION_YODLEE_RESET_ERRORS;
    }
  | {
      type: 'ACTION_ADD_YODLEE_ACCOUNTS_FAILURE';
    }
  | {
      type: 'ACTION_ADD_YODLEE_ACCOUNTS_SUCCESS';
      payload: YodleeAggregatorsResponse;
    }
  | {
      type: 'ACTION_ADD_YODLEE_ACCOUNTS_REQUEST';
      payload: string[];
    };

function getInitialState(): AddExternalAccountState {
  return {
    isLoading: false,
  };
}

const handleRetriableError = (state: AddExternalAccountState, error: RetriableYodleeError) => {
  const { errorType, errorRetryHandler, errorMessage } = error;
  let attempts = state.yodleeErrorRetryAttempts || 0;
  if (errorType === state.yodleeRetriableError) {
    attempts += 1;
  } else {
    attempts = 0;
  }
  return {
    ...state,
    yodleeErrorRetryAttempts: attempts,
    yodleeErrorRetryHandler: errorRetryHandler,
    yodleeRetriableError: errorType,
    yodleeErrorMessage: errorMessage,
    isLoading: false,
  };
};

const resetYodleeErrors = () => ({
  yodleeErrorMessage: undefined,
  yodleeRetriableError: undefined,
  yodleeErrorRetryAttempts: 0,
  yodleeErrorRetryHandler: undefined,
});

export default (
  state: AddExternalAccountState | null | undefined = getInitialState(),
  action: AddExternalAccountAction = undefined
): AddExternalAccountState => {
  switch (action.type) {
    case ACTION_VALIDATE_EXTERNAL_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_ADD_EXTERNAL_ACCOUNT_REQUEST:
      return {
        ...state,
        accountType: action.payload.accountType,
        accountNumber: action.payload.accountNumber,
        accountErrorMessage: undefined,
        isLoading: true,
        isInstantlyVerified: false,
        externalId: undefined,
        yodleeAccount: undefined,
      };
    case ACTION_ADD_EXTERNAL_ACCOUNT_SUCCESS:
    case ACTION_VALIDATE_ROUTING_NUMBER_REJECTED:
    case ACTION_ADD_YODLEE_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED:
      return {
        ...state,
        isLoading: false,
        accountErrorMessage: action.payload,
      };
    case ACTION_CLEAR_ADD_EXTERNAL_ACCOUNT_ERROR:
      return {
        ...state,
        accountErrorMessage: undefined,
      };
    case ACTION_SET_BANK_NAME:
      return {
        ...state,
        bankName: action.payload,
      };
    case ACTION_SET_IS_INSTANTLY_VERIFIED:
      return {
        ...state,
        isInstantlyVerified: true,
        externalId: action.payload,
      };
    case ACTION_ADD_YODLEE_ACCOUNTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        yodleeAccountIds: action.payload,
      };
    }
    case ACTION_ADD_YODLEE_ACCOUNT:
      return {
        ...state,
        ...resetYodleeErrors(),
        isLoading: false,
        yodleeAccount: {
          ...action.payload,
          isYodlee: true,
        },
        isInstantlyVerified: true,
      };
    case ACTION_ADD_YODLEE_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        ...resetYodleeErrors(),
        isLoading: false,
        yodleeAccounts: action.payload,
      };
    }
    case ACTION_CLEAR_YODLEE_ACCOUNT:
      return {
        ...state,
        ...resetYodleeErrors(),
        yodleeAccount: undefined,
        yodleeAccounts: undefined,
        yodleeAccountIds: undefined,
      };
    case ACTION_YODLEE_RETRIABLE_ERROR:
      return handleRetriableError(state, action.payload);
    case ACTION_YODLEE_FAILURE:
      return {
        ...state,
        // Upon receiving a non-retriable (4XX) error, any previous retry logic is no longer relevant
        ...resetYodleeErrors(),
        isLoading: false,
        yodleeErrorMessage: action.payload,
      };
    case ACTION_YODLEE_FLOW_STARTS:
      return {
        ...state,
        startedFlow: action.payload,
      };
    case ACTION_YODLEE_RESET_ERRORS:
      return { ...state, ...resetYodleeErrors() };
    default:
      return state;
  }
};
