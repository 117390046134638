import type { ProductDisplayType } from 'Domain/Account/AccountDetails';

export const isCD = (productDisplayType?: ProductDisplayType) =>
  productDisplayType && productDisplayType.includes('CD');

export const isIRA = (productDisplayType?: ProductDisplayType) =>
  productDisplayType && productDisplayType.includes('IRA');

export const isMMA = (productDisplayType?: ProductDisplayType) =>
  productDisplayType && productDisplayType.includes('MMA');

export const isCHK = (productDisplayType?: ProductDisplayType) =>
  productDisplayType && productDisplayType.includes('CHK');

export const isHYS = (productDisplayType?: ProductDisplayType) =>
  productDisplayType && productDisplayType.includes('HYS');

export const isCDPromo = (promoName?: string) => promoName && promoName.includes('CD');
