import React, { ReactElement } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ModalText } from '../../components/cms/blockText.constants';
import TileList from '../../components/tileList/tileList';
import { NoActivityTileList } from '../../components/tileList/noActivityTileList';
import { ModalBtnText } from '../../components/cms/buttonText.constants';
import TileListSubtitles from '../../components/tileList/tileListSubtitles';
import { Beneficiary, BeneficiariesPageTexts } from './beneficiaries.constants';
import { Account } from '../../utilities/types';
import ConfirmationModal from '../../components/modal/ConfirmationModal';

type ModelType = {
  beneficiaryAccounts: Account[];
  hasIraAccounts: boolean;
  classes: ClassNameMap;
  beneficiaries: Beneficiary[];
  renderTileListHeader: () => React.ReactNode;
  renderBeneficiary: (args: Beneficiary) => ReactElement;
  onClickRemove: () => void;
  showConfirmationModal: boolean;
  handleOnExit: () => void;
};

export const BeneficiariesModel: React.FC<ModelType> = (props) => {
  const {
    beneficiaryAccounts,
    hasIraAccounts,
    classes,
    beneficiaries,
    renderTileListHeader,
    renderBeneficiary,
    onClickRemove,
    showConfirmationModal,
    handleOnExit,
  } = props;

  const renderBeneficiaryList = () => {
    return beneficiaries?.length ? (
      <TileList
        header={renderTileListHeader()}
        data-test="beneficiaries-list"
        list={beneficiaries}
        listItemRenderProp={renderBeneficiary}
      />
    ) : (
      <NoActivityTileList
        message={BeneficiariesPageTexts.NO_BENEFICIARIES}
        header={renderTileListHeader()}
      />
    );
  };
  return beneficiaryAccounts && !!beneficiaryAccounts.length ? (
    <Grid
      item
      xs={12}
      data-test="beneficiaries-item-container"
      className={!hasIraAccounts && classes.beneficiaryItemContainer}
    >
      {renderBeneficiaryList()}
      <ConfirmationModal
        confirmText={ModalBtnText.REMOVE_BENEFICIARY_BTN}
        contentText={ModalText.REMOVE_BENEFICIARY_MODAL_BODY}
        onConfirm={onClickRemove}
        onExit={handleOnExit}
        titleText={ModalText.REMOVE_BENEFICIARY_MODAL_TITLE}
        visible={showConfirmationModal}
      />
    </Grid>
  ) : null;
};

type TileListType = {
  beneficiaries?: Beneficiary[];
  classes: ClassNameMap;
};

export const BeneficiariesTileList: React.FC<TileListType> = (props) => {
  const { beneficiaries, classes } = props;
  return (
    <>
      <Hidden smUp>
        <TileListSubtitles
          middleColumn={BeneficiariesPageTexts.BENEFICIARY_COLUMN_HEADER}
          rightColumn={beneficiaries?.length !== 0 ? BeneficiariesPageTexts.MANAGE_BENEFICIARY : ''}
          columnSizes={{
            middle: 4,
            right: 8,
          }}
          classes={{
            column: classes.column,
          }}
          data-test="beneficiaries-list-subtitles"
        />
      </Hidden>
      <Hidden xsDown>
        <TileListSubtitles
          leftColumn={BeneficiariesPageTexts.BENEFICIARY_COLUMN_HEADER}
          middleColumn={BeneficiariesPageTexts.ACCOUNTS_COLUMN_HEADER}
          rightColumn={beneficiaries?.length !== 0 ? BeneficiariesPageTexts.MANAGE_BENEFICIARY : ''}
          columnAlign="left"
          columnSizes={{
            left: 4,
            middle: 5,
            right: 3,
          }}
          hideLeftSmDown={false}
          classes={{
            column: classes.column,
          }}
          data-test="beneficiaries-list-subtitles"
        />
      </Hidden>
    </>
  );
};
