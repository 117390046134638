import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ManageTaxDocumentButtonLabel } from '../../containers/ePreferences/ePreferences.constants';
import ManageTaxDocumentModal from '../modal/ManageTaxDocumentModal';
import { setFlashMessage } from '../flashMessage/flashMessage.reducer';
import { FlashMessageText, FlashMessageVariant } from '../flashMessage/flashMessage.constants';
import { updateTaxDocumentPreferencesService } from '../../containers/ePreferences/ePreferences.service';
import type { Dispatch } from '../../utilities/types';
import type {
  ETaxDocumentPreferences,
  ETaxDocumentPreferencesFormData,
  ETaxDocumentPreferencesResponse,
} from '../../containers/ePreferences/ePreferences.constants';
import pxToRem from '../../utilities/pxToRem';
import { GreenButton } from '../buttons/buttons';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import {
  ANALYTICS_EPREFERENCES_SAVED,
  ANALYTICS_EPREFERENCES_NOT_SAVED,
} from '../../analytics/actions';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const pageViewActions = Object.freeze({
  savedSuccess: ANALYTICS_EPREFERENCES_SAVED,
  savedFailure: ANALYTICS_EPREFERENCES_NOT_SAVED,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type StateProps = {
  taxDocumentPreferences: ETaxDocumentPreferences;
};

type DispatchProps = {
  setSuccessFlashMessage: () => void;
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => void;
};

type Props = StateProps & DispatchProps;

const useStyle = makeStyles({
  buttonIcon: {
    paddingLeft: pxToRem(10),
    width: pxToRem(24),
  },
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setSuccessFlashMessage: () =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.SUCCESS,
        messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
      })
    ),
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => dispatch({ type: pageViewAction }),
});

export const ManageTaxDocument = ({
  taxDocumentPreferences,
  setSuccessFlashMessage,
  recordAnalyticsPageView,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [taxDocumentPreferenceInput, setTaxDocumentPreferenceInput] =
    useState<ETaxDocumentPreferences>(taxDocumentPreferences);

  const styles = useStyle();
  const onSubmit = async ({
    areETaxTermsAccepted,
    edeliveryStatus,
  }: ETaxDocumentPreferencesFormData) => {
    try {
      const requestPayload: ETaxDocumentPreferencesResponse = {
        taxDocumentsPreferences: {
          edeliveryStatus,
          areTermsAccepted: areETaxTermsAccepted,
        },
      };
      await updateTaxDocumentPreferencesService(requestPayload);
      recordAnalyticsPageView(pageViewActions.savedSuccess);
      setShowModal(false);

      // When user will reopen the modal we need to show previous input without api call.
      setTaxDocumentPreferenceInput(requestPayload.taxDocumentsPreferences);

      setTimeout(() => setSuccessFlashMessage(), 100);
    } catch {
      recordAnalyticsPageView(pageViewActions.savedFailure);
      setError(FlashMessageText.TAX_DOCUMENT_E_PREFERENCE_ERROR);
    }
  };
  return (
    <>
      <ManageTaxDocumentModal
        taxDocumentPreferences={taxDocumentPreferenceInput}
        visible={showModal}
        closeModal={() => setShowModal(false)}
        onSubmit={onSubmit}
        error={error}
      />
      <GreenButton
        data-test="manage-tax-preference-button"
        onClick={() => {
          setShowModal(true);
        }}
        startIcon={
          <span className={styles.buttonIcon}>
            <SVGImage imageName={ImagesFileNames.iconMorePaperlessSvgSm} ariaHidden="true" />
          </span>
        }
        data-track-page={clickTrack.ePreferences.view}
        data-track-title={clickTrack.ePreferences.tax_documents}
        data-track-type={clickTrackType.BUTTON}
      >
        {ManageTaxDocumentButtonLabel}
      </GreenButton>
    </>
  );
};

export default connect(null, mapDispatchToProps)(ManageTaxDocument);
