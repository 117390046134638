import moment from 'moment';
import { CONTACT_US_CODE_DEFAULT } from 'Containers/newAccountOpening/applications.constants.ts';
import { mockProduct } from './mock-account-details';
import { getInitialState } from '../../components/flashMessage/flashMessage.reducer.ts';
import { getInitialState as getInitialEtinState } from '../../containers/etin/etin.reducer.ts';
import AccountDetailsTypes from '../../domain/Account/AccountDetails.ts';

const buildTransactions = () => ({
  currentBalance: '',
  description: [],
  amount: '',
  date: '',
  pending: false,
  interestPayment: false,
  type: 'Credit',
});

export const buildAccountActivityState = (options = {}) => ({
  accountDetails: {
    accountId: options.accountId || '',
    accountIdDisplay: '',
    accountBeneficiaries: [],
    accountOwners: [],
    currentBalance: '',
    holdBalance: '',
    inGracePeriod: false,
    interestDistribution: { method: 'Capitalize' },
    interest: { apy: '', paidYTD: '', rate: '' },
    iraPlanType: '',
    maturityDate: '',
    openDate: new Date(),
    routingNumber: '',
    term: '',
    type: options.type || AccountDetailsTypes.CHECKING,
    withdrawal: { allowed: '', count: '' },
    product: mockProduct,
    primaryOwner: false,
    nickname: '',
  },
  editNicknamePending: false,
  hasLoadMoreTransactionsError: false,
  transactions: [buildTransactions(), buildTransactions()],
  isAccountLoading: false,
  isAccountIdLoading: options.isAccountIdLoading || false,
  isAccountIdError: false,
  isFirstTransactionLoading: false,
  isMoreTransactionLoading: false,
  isNickNameModalLoading: false,
  lastPage: false,
  cursor: '000000030',
  decryptedAccountId: null,
  isNicknameModalOpen: false,
});

export const buildEmptyCustomerInfoState = (options = {}) => ({
  addresses: [],
  asOfDate: '',
  createdAt: '',
  customerId: options.customerId ? options.customerId : '',
  customerStatus: '',
  customerType: '',
  dateOfBirth: '',
  emailAddresses: [
    {
      email: '',
      type: '',
    },
  ],
  employments: [
    {
      employerName: '',
      occupation: {
        name: '',
        value: '',
      },
    },
  ],
  lastContactedAt: '',
  lastUpdatedAt: '',
  links: [
    {
      href: '',
      rel: '',
      templated: false,
    },
  ],
  name: {
    firstName: '',
    lastName: '',
    middleName: '',
    prefix: '',
    suffix: '',
  },
  nationality: {
    name: '',
  },
  noPhoneADA: false,
  phones: [
    {
      number: '',
      type: '',
      displayType: '',
    },
  ],
  serviceLevel: null,
  taxId: '',
  udf: [
    {
      id: '',
      name: '',
      value: '',
    },
  ],
  decryptedTaxId: null,
});

export const buildApplicationsState = (options = {}) => ({
  pendingApplication: null,
  lastFundingResponse: {
    successMessage: '',
  },
  hasCompletedOtp: false,
  isLoading: false,
  isEditing: false,
  isAccountFunded: false,
  latestStep: '',
  terms: {},
  agreementStatuses: {},
  newAccount: {
    id: options.id || '',
    accountIdDisplay: '',
    accountType: options.accountType || AccountDetailsTypes.CHECKING,
    externalId: '',
    name: '',
    external: false,
    typeName: '',
    transfersFromEnabled: false,
    transfersToEnabled: false,
  },
  loadedJointOwners: [],
  fetchAttempts: 0,
  hasConfirmedNewPhoneNumber: false,
  funded: false,
  allowUnload: options.allowUnload || false,
  trackingId: '',
  contactUsCode: CONTACT_US_CODE_DEFAULT,
});

export const buildProfileState = () => ({
  firstName: '',
  isFormDirtyPreventUnload: false,
  isLoading: false,
  isLoadingSubmit: false,
  isSaveChangesLoading: false,
  profileError: {
    title: '',
    message: '',
  },
});

export const buildAppState = (options = {}) => ({
  accountActivity: options.accountActivity || buildAccountActivityState(),
  accounts: {
    data: [],
    isLoading: false,
    isBeneficiaryAccountsLoading: false,
    totalBalance: '0',
    asOfDate: '',
    nextPageAvailable: false,
    beneficiaryAccounts: [],
    cmsContent: { flashMessages: [], tileMessages: [], imageMessages: [] },
  },
  applications: options.applications || buildApplicationsState(),
  applicationForm: {
    newJointOwners: [],
    pendingNewJointOwnerName: null,
    selectedExistingJointOwners: [],
  },
  authenticate: {
    isLoggedIn: false,
    isLoading: false,
    activityTicks: 0,
    tokenTicks: 0,
    needsSessionRefresh: false,
  },
  addExternalAccount: { isLoading: false },
  etin: getInitialEtinState(),
  externalAccounts: {
    accounts: [],
    nickNameIsLoading: false,
    isLoading: false,
    isRemoving: false,
  },
  cdRenewal: { hasError: false, isLoading: false },
  allAccounts: {
    internalAccounts: [],
    externalAccounts: [],
    allAccountsLoading: false,
    error: false,
  },
  lists: {
    nations: [],
    occupations: [],
    states: [],
  },
  verifyTrialDeposits: {
    isLoading: false,
    isVerifySuccessful: false,
  },
  openAccount: {
    countries: [],
    occupations: [],
    sourceOfFunds: [],
    states: [],
    securityQuestions: [],
  },
  products: {
    activePromotion: null,
    affinityCompanyCode: '',
    affinity: false,
    affinityVerbiage: '',
    error: false,
    isLoading: false,
    productTypes: {
      products: [],
      terms: [],
    },
    validAffinityCompanyCode: false,
    shouldShowInvalidAffinityCodeModal: false,
    validPromotionCode: true,
    rateMatrices: [],
  },
  overdraft: {
    protectorAccountList: [],
    hasOverdraft: false,
    isOverdraftError: false,
    isLoading: false,
    isOverdraftActionLoading: false,
    protectedAccount: {
      accountId: '',
      accountIdDisplay: '',
      accountType: AccountDetailsTypes.CHECKING,
      nickname: '',
      productDisplayType: '',
    },
    protectorAccount: {
      accountId: '',
      accountIdDisplay: '',
      accountType: AccountDetailsTypes.SAVINGS,
      nickname: '',
      productDisplayType: '',
    },
  },
  form: null,
  transfers: {
    transferInfo: { successMessage: '' },
    isLoading: false,
    isRemoving: false,
    transferActivity: [],
    frequencies: [],
    isValidAmount: false,
  },
  newTransfer: {
    transferInfo: {
      successMessage: '',
    },
    isLoading: false,
    isPending: false,
    frequencies: [
      {
        value: 'ONCE',
        name: '',
      },
    ],
    isValidAmount: false,
  },
  editTransfer: {
    editTransferInfo: {
      successMessage: '',
    },
    isLoading: false,
    isPending: false,
    frequencies: [
      {
        value: 'ONCE',
        name: '',
      },
    ],
  },
  profile: buildProfileState(),
  statements: {
    documents: [],
    more: false,
    isLoading: false,
    isAccountLoading: false,
    isStatementsLoading: false,
    accounts: [],
    customerTotalBalance: '',
    accountsFetchFailed: false,
    statementsFetchFailed: false,
    selectedAccountId: '',
    selectedYear: moment().year(),
  },
  flashMessage: getInitialState(),
  router: {
    location: {
      pathname: options.pathname || '',
      search: '',
      hash: '',
    },
    action: 'PUSH',
  },
  zelle: {
    error: false,
    isLoading: false,
  },
  owcs: {
    loading: false,
    pageList: [],
  },
});
