import React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import { Field as ReduxFormField } from 'redux-form';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FinalFormComponents } from '../finalForm/finalFormInputs';
import { ReduxFormComponents } from '../reduxForms/inputs';
import { IdentifyingInformationFieldNames } from '../../form.constants';
import { composeValidators } from '../../utilities/validators';
import {
  checkEmptyFirstName,
  checkValidFirstName,
  checkValidMiddleName,
  checkValidLastName,
  checkEmptyLastName,
} from '../../containers/newAccountOpening/newUser/openAccount.validators';

type PersonalInformationFieldProps = {
  classes?: ClassNameMap;
  dataTest?: string;
  disabled?: boolean;
  useFinalForm?: boolean;
};

const firstNameValidator = composeValidators(checkEmptyFirstName, checkValidFirstName);
const lastNameValidator = composeValidators(checkEmptyLastName, checkValidLastName);

export const FirstName = (props: PersonalInformationFieldProps) => {
  const { classes = {}, disabled, dataTest, useFinalForm } = props;
  const { RenderTextField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: IdentifyingInformationFieldNames.FIRST_NAME,
    id: IdentifyingInformationFieldNames.FIRST_NAME,
    type: 'text',
    label: 'First Name',
    labelId: 'firstnameLabelId',
    'data-test': dataTest,
    validate: firstNameValidator,
    component: RenderTextField,
    autoComplete: 'given-name',
    maxLength: 20,
    disabled,
    ariaRequired: true,
    classes,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

export const MiddleName = (props: PersonalInformationFieldProps) => {
  const { classes = {}, disabled, dataTest, useFinalForm } = props;
  const { RenderTextField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: IdentifyingInformationFieldNames.MIDDLE_NAME,
    type: 'text',
    label: 'Middle Name (Optional)',
    labelId: 'midnameLabelId',
    'data-test': dataTest,
    validate: checkValidMiddleName,
    component: RenderTextField,
    autoComplete: 'additional-name',
    maxLength: 20,
    disabled,
    classes,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

export const LastName = (props: PersonalInformationFieldProps) => {
  const { classes = {}, disabled, dataTest, useFinalForm } = props;
  const { RenderTextField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: IdentifyingInformationFieldNames.LAST_NAME,
    id: IdentifyingInformationFieldNames.LAST_NAME,
    type: 'text',
    label: 'Last Name',
    labelId: 'lastnameLabelId',
    'data-test': dataTest,
    validate: lastNameValidator,
    autoComplete: 'family-name',
    component: RenderTextField,
    maxLength: 20,
    disabled,
    ariaRequired: true,
    classes,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};
