export type AuthenticateState = {
  isLoggedIn: boolean;
  isLoading: boolean;
  activityTicks: number;
  tokenTicks: number;
  needsSessionRefresh: boolean;
};

const getInitialState = (): AuthenticateState => ({
  isLoggedIn: false,
  isLoading: false,
  activityTicks: 0,
  tokenTicks: 0,
  needsSessionRefresh: false,
});

export const ACTION_SET_USER_LOGGED_IN = 'ACTION_SET_USER_LOGGED_IN';
export const ACTION_SET_USER_LOGGED_OUT = 'ACTION_SET_USER_LOGGED_OUT';
export const ACTION_SET_LOADING_FALSE = 'ACTION_SET_LOADING_FALSE';
export const ACTION_REFRESH_ACCESS_TOKEN = 'ACTION_REFRESH_ACCESS_TOKEN';
export const ACTION_SET_TICK_STATUS = 'ACTION_SET_TICK_STATUS';
export const ACTION_SET_TICK_STATUS_END = 'ACTION_SET_TICK_STATUS_END';
export const ACTION_REFRESH_ACTIVITY_TIMER = 'ACTION_REFRESH_ACTIVITY_TIMER';
export const ACTION_SET_NEEDS_SESSION_REFRESH = 'ACTION_SET_NEEDS_SESSION_REFRESH';

export type AuthenticateAction =
  | {
      type: 'ACTION_SET_USER_LOGGED_IN';
    }
  | {
      type: 'ACTION_SET_LOADING_FALSE';
    }
  | {
      type: 'ACTION_SET_USER_LOGGED_OUT';
    }
  | {
      type: 'ACTION_REFRESH_ACCESS_TOKEN';
    }
  | {
      type: 'ACTION_REFRESH_ACTIVITY_TIMER';
    }
  | {
      type: 'ACTION_SET_TICK_STATUS';
    }
  | {
      type: 'ACTION_SET_TICK_STATUS_END';
    }
  | {
      type: typeof ACTION_SET_NEEDS_SESSION_REFRESH;
      payload: boolean;
    };

export const LOGIN_FAILURE_MESSAGE =
  'Sorry, it looks like the Username and/or Password you provided does not match our records';

export default (
  state: AuthenticateState | null | undefined = getInitialState(),
  action: AuthenticateAction = undefined
) => {
  switch (action.type) {
    case ACTION_SET_USER_LOGGED_IN:
      return { ...state, isLoggedIn: true, isLoading: false };
    case ACTION_SET_USER_LOGGED_OUT:
      return { ...state, isLoggedIn: false, isLoading: false };
    case ACTION_SET_LOADING_FALSE:
      return { ...state, isLoading: false };
    case ACTION_REFRESH_ACCESS_TOKEN:
      return { ...state, tokenTicks: 0 };
    case ACTION_REFRESH_ACTIVITY_TIMER:
      return { ...state, activityTicks: 0 };
    case ACTION_SET_TICK_STATUS:
      return { ...state, activityTicks: state.activityTicks + 1, tokenTicks: state.tokenTicks + 1 };
    case ACTION_SET_NEEDS_SESSION_REFRESH:
      return { ...state, needsSessionRefresh: action.payload };
    case ACTION_SET_TICK_STATUS_END:
    default:
      return state;
  }
};
