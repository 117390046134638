import type { ReduxState } from '../../../reducers';
import type { AccountFundingFlow } from '../../../utilities/accountFundingFlowType';
import type { AccountActivityAction } from '../../../containers/accountActivity/accountActivity.reducer';
import pageAnalytics from '../../pageAnalytics';
import {
  getExternalFundingType,
  getPageSubfunction,
  isOnFormStep,
} from '../fundNewAccount.analytics';
import { ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS } from '../../../containers/verifyTrialDeposits/verifyTrialDeposits.reducer';
import {
  ANALYTICS_VERIFY_DEPOSITS,
  ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS,
  ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED,
  ANALYTICS_LOAD_MAIL_A_CHECK,
} from '../../actions';
import pageTrack from '../../pageAnalytics.constants';
import Routes, { FundingSteps } from '../../../containers/routes/routes.constants';

export const addExternal =
  (attr = '') =>
  () => {
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.transfers,
        pagefunction: pageTrack.pagefunction.add_external_bank,
        pagesubfunction: pageTrack.pagesubfunction.servicing,
        pagename: pageTrack.pagename.complete,
        attr,
      })
      .record();
  };

export const fundingSuccess =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.funding,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.complete,
        attr: getExternalFundingType(state),
      })
      .record();
  };

export const fundingAccountVerified =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    if (!isOnFormStep(state, FundingSteps.VERIFIED)) return;

    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.add_external_bank,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.complete,
        attr: pageTrack.attr.ews,
      })
      .record();
  };

export const fundingAccountEWSFailed =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.add_external_bank,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.ews_connection_failed,
      })
      .record();
  };

export const fundingAccountYodleeFailed =
  (fundingFlow: AccountFundingFlow) => (action: AccountActivityAction, state: ReduxState) => {
    pageAnalytics
      .newPageView({
        account_type: pageTrack.account_type.generic,
        pagekind: pageTrack.pagekind.account_opening,
        pagefunction: pageTrack.pagefunction.add_external_bank,
        pagesubfunction: getPageSubfunction(fundingFlow),
        pagename: pageTrack.pagename.yodlee_connection_failed,
      })
      .record();
  };

export const fundingAccountNotVerified = (action: AccountActivityAction, state: ReduxState) => {
  const { pathname } = state.router.location;
  const { applications } = state;
  const { currentFundingFlow } = applications;

  pageAnalytics
    .newPageView({
      account_type: pageTrack.account_type.generic,
      pagekind:
        pathname === Routes.TRIAL_DEPOSIT_INSTRUCTIONS
          ? pageTrack.pagekind.account_opening
          : pageTrack.pagekind.transfers,
      pagefunction: pageTrack.pagefunction.add_external_bank,
      pagesubfunction: getPageSubfunction(currentFundingFlow),
      pagename: pageTrack.pagename.connection_initiated,
      attr: pageTrack.attr.ews,
    })
    .record();
};

export const loadFundingAccountNotVerified = (action: AccountActivityAction, state: ReduxState) => {
  const { pathname } = state.router.location;
  const { applications } = state;
  const { currentFundingFlow } = applications;

  pageAnalytics
    .newPageView({
      account_type: pageTrack.account_type.generic,
      pagekind: pageTrack.pagekind.transfers,
      pagefunction: pageTrack.pagefunction.add_external_bank,
      pagesubfunction: getPageSubfunction(currentFundingFlow),
      pagename: pageTrack.pagename.ews_connection_failed,
    })
    .record();
};

export const loadMailACheck = (action: AccountActivityAction, state: ReduxState) => {
  const { applications } = state;
  const { currentFundingFlow } = applications;

  pageAnalytics
    .newPageView({
      account_type: pageTrack.account_type.generic,
      pagekind: pageTrack.pagekind.account_opening,
      pagefunction: pageTrack.pagefunction.add_external_bank,
      pagesubfunction: getPageSubfunction(currentFundingFlow),
      pagename: pageTrack.pagename.mail_a_check,
    })
    .record();
};

export const fundingVerificationEvents = {
  [ANALYTICS_LOAD_TRIAL_DEPOSIT_INSTRUCTIONS]: fundingAccountNotVerified,
  [ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED]: loadFundingAccountNotVerified,
  [ANALYTICS_LOAD_MAIL_A_CHECK]: loadMailACheck,
  [ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS]: addExternal(pageTrack.attr.md),
  [ANALYTICS_VERIFY_DEPOSITS]: addExternal(pageTrack.attr.ews),
} as const;
