import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import colors from '../colors/colors';
import { fontFamily } from '../../styles/createTheme';

const { red } = colors;

const datePickerStyles = (): StyleRules => ({
  errorLabel: {
    color: red,
    fontWeight: 'normal',
  },
  datePickerContainer: {
    paddingTop: pxToRem(20),
  },
  inputLabelError: { color: red },
  datepickerTextfield: {
    '& .MuiInputBase-input': { fontFamily },
    '& .MuiFormHelperText-root': { fontFamily },
  },
  datepickerTextfieldError: {
    '& .MuiInputBase-input': { color: red },
    '& .MuiFormLabel-root': { color: red },
  },
  datepickerLayout: {
    '& .MuiPickersDay-root': {
      border: '1px solid lightgray',
      borderRadius: '1px',
    },
    '& .MuiPickersYear-yearButton': { fontFamily },
    '& .MuiPickersCalendarHeader-label': { fontFamily },
    '& .MuiDateCalendar-root': { fontFamily },
    '& .MuiPickersCalendarHeader-root': {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    },
  },
  datepickerLayoutError: {
    '& .MuiPickersYear-yearButton': { color: red },
    '& .MuiPickersCalendarHeader-label': { color: red },
    '& .MuiDateCalendar-root': { color: red },
  },
});

export default datePickerStyles;
