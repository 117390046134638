import moment from 'moment';
import regexp from '../../../utilities/regexConsts';
import {
  PersonalInformationFormLabels,
  PersonalInformationInlineErrors,
} from '../../../components/cms/personalInformation.constants';
import MoreAboutYouFormLabels, {
  MoreAboutYouInlineErrors,
} from '../../../components/cms/moreAboutYou.constants';
import { checkEmptyTextField } from '../../../utilities/validators';
import i18n from '../../../strings/i18n';

// Empty Field Errors
const checkEmptySelect = (fieldName: string) => (value?: string | null) =>
  !value ? `Please select ${fieldName}.` : undefined;

export const checkEmptyFirstName = checkEmptyTextField(PersonalInformationFormLabels.FIRST_NAME);
export const checkEmptyLastName = checkEmptyTextField(PersonalInformationFormLabels.LAST_NAME);
export const checkEmptyStreetAddress = checkEmptyTextField(
  PersonalInformationFormLabels.STREET_ADDRESS_1
);
export const checkEmptyCity = checkEmptyTextField(PersonalInformationFormLabels.CITY);
export const checkEmptyState = checkEmptySelect(PersonalInformationFormLabels.STATE);
export const checkEmptyZip = checkEmptyTextField(PersonalInformationFormLabels.ZIP_CODE);
export const checkEmptyPhone = checkEmptyTextField(PersonalInformationFormLabels.PHONE_NUMBER);
export const checkEmptyEmail = checkEmptyTextField(PersonalInformationFormLabels.EMAIL);
export const checkEmptySSN = checkEmptyTextField(
  PersonalInformationFormLabels.SOCIAL_SECURITY_NUMBER
);
export const checkEmptyDOB = checkEmptyTextField(PersonalInformationFormLabels.DATE_OF_BIRTH);
export const checkEmptyCitizenship = checkEmptySelect(MoreAboutYouFormLabels.CITIZENSHIP);
export const checkEmptyOccupation = checkEmptySelect(MoreAboutYouFormLabels.OCCUPATION);
export const checkEmptyEmployerName = checkEmptyTextField(MoreAboutYouFormLabels.EMPLOYER_NAME);
export const checkEmptySecurityQuestion = checkEmptySelect(
  MoreAboutYouFormLabels.SECURITY_QUESTION
);
export const checkEmptySecurityAnswer = checkEmptyTextField(MoreAboutYouFormLabels.SECURITY_ANSWER);

// Invalid Character Errors
const charsAndSpaces = (fieldName: string) => (value?: string | null) =>
  !regexp.charsAndSpaces.test(value)
    ? i18n(
        { personalInformationInlineErrorsMessage: 'INVALID_LETTER_SPACE_ERROR_CHAR' },
        { fieldName }
      )
    : undefined;

const charsNumbersAndSpaces = (fieldName: string) => (value?: string | null) =>
  !regexp.charsNumbersAndSpaces.test(value)
    ? i18n(
        { personalInformationInlineErrorsMessage: 'INVALID_NUMBER_SPACE_ERROR_CHAR' },
        { fieldName }
      )
    : undefined;

const charsNumbersAndAtLeastOneSpace = (fieldName: string) => (value?: string | null) =>
  !regexp.charsNumbersAndAtLeastOneSpace.test(value)
    ? i18n(
        { personalInformationInlineErrorsMessage: 'INVALID_AT_LEAST_ONE_SPACE_ERROR_CHAR' },
        { fieldName }
      )
    : undefined;

export const checkValidFirstName = charsAndSpaces(PersonalInformationFormLabels.FIRST_NAME);
export const checkValidMiddleName = charsAndSpaces(PersonalInformationFormLabels.MIDDLE_NAME);
export const checkValidLastName = charsAndSpaces(PersonalInformationFormLabels.LAST_NAME);
export const checkValidStreetAddress = charsNumbersAndAtLeastOneSpace(
  PersonalInformationFormLabels.STREET_ADDRESS_1
);
export const checkValidUnitNumber = charsNumbersAndSpaces(
  PersonalInformationFormLabels.STREET_ADDRESS_2
);
export const checkValidCity = charsAndSpaces(PersonalInformationFormLabels.CITY);
export const checkValidEmployerName = charsNumbersAndSpaces(MoreAboutYouFormLabels.EMPLOYER_NAME);
export const checkValidSecurityAnswer = charsNumbersAndSpaces(
  MoreAboutYouFormLabels.SECURITY_ANSWER
);

// Minimum Character Errors
const minCharsEmployerName = () => (value?: string | null) =>
  value && !regexp.atLeastTwoChars.test(value.trim())
    ? MoreAboutYouInlineErrors.TOO_SHORT_EMPLOYER_NAME
    : undefined;

const atLeastThreeChars =
  (fieldName: string, errorMsg = `Sorry, ${fieldName} should contain a minimum of 3 characters.`) =>
  (value?: string | null) =>
    value && !regexp.atLeastThreeChars.test(value.trim()) ? errorMsg : undefined;

export const checkMinCharsStreetAddress = atLeastThreeChars(
  PersonalInformationFormLabels.STREET_ADDRESS_1
);
export const checkMinCharsCity = atLeastThreeChars(PersonalInformationFormLabels.CITY);
export const checkMinCharsEmployerName = minCharsEmployerName();
export const checkMinCharsSecurityAnswer = atLeastThreeChars(
  MoreAboutYouFormLabels.SECURITY_ANSWER,
  MoreAboutYouInlineErrors.TOO_SHORT_SECURITY_ANSWER
);

export const checkValidZipCode = (value?: string | null) =>
  !regexp.zipCode.test(value) ? PersonalInformationInlineErrors.INVALID_FORMAT_ZIP : undefined;

export const checkValidPhoneNumber = (value?: string | null) =>
  !regexp.phoneNumber.test(value)
    ? PersonalInformationInlineErrors.INVALID_FORMAT_PHONE
    : undefined;

export const checkValidEmailAddress = (value?: string | null) =>
  !regexp.email.test(value) ? PersonalInformationInlineErrors.INVALID_FORMAT_EMAIL : undefined;

export const checkValidSSN = (value?: string | null) =>
  !regexp.ssn.test(value) ? PersonalInformationInlineErrors.INVALID_FORMAT_SSN : undefined;

export const checkValidDOB = (value?: string | null) =>
  !regexp.dateOfBirth.test(value) ? PersonalInformationInlineErrors.INVALID_FORMAT_DOB : undefined;

// Special Case Errors - Date of Birth
const applicantIsTooYoung = (chosenDob?: string | null) => {
  const oldEnough = moment().subtract(18, 'years');
  return moment(chosenDob).isAfter(oldEnough);
};

const applicantIsTooOld = (chosenDob?: string | null) => {
  const tooOld = moment().subtract(120, 'years');
  return moment(chosenDob).isBefore(tooOld);
};

const applicantDobIsFutureDate = (chosenDob?: string | null) => {
  const oldEnough = moment().subtract(0, 'years');
  return moment(chosenDob).isAfter(oldEnough);
};

export const checkIfDobIsFutureDate = (value?: string | null) =>
  value && applicantDobIsFutureDate(value)
    ? PersonalInformationInlineErrors.INVALID_FORMAT_DOB
    : undefined;

export const checkIfTooYoung = (value?: string | null) =>
  value && applicantIsTooYoung(value) ? PersonalInformationInlineErrors.DOB_TOO_YOUNG : undefined;

export const checkIfOutOfRange = (value?: string | null) =>
  value && applicantIsTooOld(value)
    ? PersonalInformationInlineErrors.DOB_OUT_OF_DATE_RANGE
    : undefined;
