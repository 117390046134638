import React, { useState } from 'react';
import { connect } from 'react-redux';
import { range } from 'lodash';
import { Hidden, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import type { FormRenderProps } from 'react-final-form';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import type { Account, CustomerInfo, DocumentsForm } from '../../utilities/types';
import type { ReduxState } from '../../reducers';
import { fetchCustomerInfo as fetchCustomerInfoAction } from '../newAccountOpening/applications.actions';
import { fetchDocuments, viewDocument, fetchTaxDocumentPreferences } from './documents.service';
import type { ViewDocument, ETaxDocumentPreferences } from './documents.constants';
import {
  DocumentsPageText,
  DocumentTypes,
  EDeliveryStatus,
  DocumentURL,
} from './documents.constants';
import useEffectOnce from '../../utilities/reactHooks';
import Page from '../../components/page/page';
import Error from '../../components/error/error';
import FullHeightLayout from '../../components/pageLayout/FullHeightLayout';
import LeftPanelGridItem, { LeftPanelHeader } from '../../components/pageLayout/LeftPanelGridItem';
import ContentGridContainerAndItem from '../../components/pageLayout/ContentGridContainerAndItem';
import useDocumentsContainerStyles from './documents.styles';
import { DocumentsFieldNames } from '../../form.constants';
import { RenderMenuItemSelectField } from '../../components/finalForm/finalFormInputs';
import TileListSubtitles from '../../components/tileList/tileListSubtitles';
import TileList from '../../components/tileList/tileList';
import DocumentsTileListItem from './documentsTileListItem/documentsTileListItem';
import { StandardText } from '../../components/typography/typography';
import ArrowLink from '../../components/links/arrowLink';
import Routes, { showEpreferencesLink } from '../routes/routes.constants';
import type { FlashMessageProperties } from '../../components/flashMessage/flashMessage.reducer';
import {
  AccountActivityBtn,
  LoadMoreDocumentsBtn,
} from '../../components/cms/buttonText.constants';
import labelConstants from '../../components/cms/labels.constants';
import {
  NoActivityMessage,
  NoActivityTileList,
} from '../../components/tileList/noActivityTileList';
import LoadMoreButton from '../../components/buttons/loadMoreButton';
import LoadingIndicator from '../../components/loadingIndicator/loadingIndicator';
import { FlashMessageText } from '../../components/flashMessage/flashMessage.constants';
import { VIEW_DOCUMENT_URL } from '../../utilities/route-mappings';
import {
  ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE,
  ANALYTICS_VIEW_DOCUMENTS_FAILURE,
  ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE,
  ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS,
  ANALYTICS_VIEW_DOCUMENTS_SUCCESS,
} from '../../analytics/actions';
import { clickTrack } from '../../analytics/clickTracking.constants';
import {
  isEligibleForEPreferences,
  isEligibleForStatements,
} from '../accountDashboard/accountDashboard.reducer';
import PopupBlockedModal from '../../components/modal/PopupBlockedModal';
import ManageTaxDocument from '../../components/ePreferences/manageTaxDocument';
import AlertFlashMessage from '../../components/flashMessage/AlertFlashMessage';

type StateProps = {
  customerInfo?: CustomerInfo;
  accounts: Account[];
  flashMessage: FlashMessageProperties;
};

type YearOption = {
  name: number;
  value: string;
};

const pageViewActions = Object.freeze({
  viewDocumentsSuccess: ANALYTICS_VIEW_DOCUMENTS_SUCCESS,
  viewDocumentsFailure: ANALYTICS_VIEW_DOCUMENTS_FAILURE,
  viewMoreDocumentsSuccess: ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS,
  viewMoreDocumentsFailure: ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE,
  viewDocumentNonceFailure: ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

const mapStateToProps = (state: ReduxState): StateProps => ({
  customerInfo: state.applications.customerInfo,
  accounts: state.accounts.data,
  flashMessage: state.flashMessage.pageFlashMessage,
});

type DispatchProps = {
  fetchCustomerInfo: () => Promise<void>;
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchCustomerInfo: async () => {
    dispatch(fetchCustomerInfoAction());
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => {
    dispatch({ type: pageViewAction });
  },
});

type AllProps = StateProps & DispatchProps;

interface RenderDocumentsProps {
  documentErrorMessages: Record<string, string>;
  handleListItemClick: (document: ViewDocument) => Promise<void>;
}

const renderDocuments =
  ({ documentErrorMessages, handleListItemClick }: RenderDocumentsProps) =>
  (document: ViewDocument) =>
    (
      <DocumentsTileListItem
        document={document}
        errorMessage={documentErrorMessages[document.documentId]}
        onClick={() => handleListItemClick(document)}
      />
    );

export const Documents = ({
  customerInfo,
  fetchCustomerInfo,
  accounts,
  recordAnalyticsPageView,
  flashMessage,
}: AllProps) => {
  const [documents, setDocuments] = useState<ViewDocument[]>([]);
  const [hasMoreDocuments, setHasMoreDocuments] = useState(false);
  const [isFullPageLoading, setIsFullPageLoading] = useState(false);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [hasDocumentsError, setHasDocumentsError] = useState(false);
  const [hasTaxDocumentsError, setHasTaxDocumentsError] = useState(false);
  const [hasMoreDocumentsError, setHasMoreDocumentsError] = useState(false);
  const [documentErrorMessages, setDocumentErrorMessages] = useState<{ [key: string]: string }>({});
  const [popupBlockedModalUrl, setPopupBlockedModalUrl] = useState<string | undefined>();
  const [taxDocumentPreferences, setTaxDocumentPreferences] = useState<
    ETaxDocumentPreferences | null | undefined
  >();
  const [documentYearValue, setDocumentYearValue] = useState<number>(moment().year());

  const classes = useDocumentsContainerStyles();

  const getDocuments = async (selectedYear: number) => {
    setDocumentYearValue(selectedYear);
    setHasDocumentsError(false);
    setIsDocumentsLoading(true);
    let documentsResponse;
    const startDate = `${selectedYear}-01-01`;
    const endDate = `${selectedYear}-12-31`;

    try {
      documentsResponse = await fetchDocuments(DocumentTypes.NOTICES, startDate, endDate);
    } catch {
      setHasDocumentsError(true);
      recordAnalyticsPageView(pageViewActions.viewDocumentsFailure);
    } finally {
      setIsDocumentsLoading(false);
      if (documentsResponse) {
        setDocuments(documentsResponse.documents);
        setHasMoreDocuments(documentsResponse.more);
        recordAnalyticsPageView(pageViewActions.viewDocumentsSuccess);
      }
    }
  };

  const getMoreDocuments = async (selectedYear: number) => {
    // Should be impossible if the load more button is enabled, but just in case
    if (!documents.length) return;

    setHasMoreDocumentsError(false);
    setIsMoreLoading(true);
    let documentsResponse;
    const startDate = `${selectedYear}-01-01`;
    const endDate = documents[documents.length - 1].date;

    try {
      documentsResponse = await fetchDocuments(DocumentTypes.NOTICES, startDate, endDate);
    } catch {
      setHasMoreDocumentsError(true);
      recordAnalyticsPageView(pageViewActions.viewMoreDocumentsFailure);
    } finally {
      setIsMoreLoading(false);
      if (documentsResponse) {
        const currentDocumentIds = new Set(documents.map(({ documentId }) => documentId));
        const newDocuments = documentsResponse.documents.filter(
          ({ documentId }) => !currentDocumentIds.has(documentId)
        );
        const combinedDocuments = [...documents, ...newDocuments];
        setDocuments(combinedDocuments);
        setHasMoreDocuments(documentsResponse.more);
        recordAnalyticsPageView(pageViewActions.viewMoreDocumentsSuccess);
      }
    }
  };

  const reFetchCustomerInfo = async () => {
    if (customerInfo) return;
    setError(false);
    try {
      await fetchCustomerInfo();
    } catch (err) {
      setError(true);
    }
  };

  const getTaxDocumentPreferences = async () => {
    if (taxDocumentPreferences) {
      return true;
    }
    setHasTaxDocumentsError(false);
    let taxDocumentPreferencesData;
    try {
      taxDocumentPreferencesData = await fetchTaxDocumentPreferences();
    } catch {
      setHasTaxDocumentsError(true);
      return false;
    }
    setTaxDocumentPreferences(taxDocumentPreferencesData);
    return true;
  };

  const getInitialData = async () => {
    setIsFullPageLoading(true);
    await Promise.all([
      reFetchCustomerInfo(),
      getDocuments(documentYearValue),
      getTaxDocumentPreferences(),
    ]);
    setIsFullPageLoading(false);
  };

  useEffectOnce(() => {
    getInitialData();
  });

  const onErrorClick = (selectedYear: number) => async () => {
    setIsFullPageLoading(true);
    await Promise.all([
      hasError && reFetchCustomerInfo(),
      hasDocumentsError && getDocuments(selectedYear),
      hasTaxDocumentsError && getTaxDocumentPreferences(),
    ]);
    setIsFullPageLoading(false);
  };

  const showEPaymentButton =
    taxDocumentPreferences &&
    taxDocumentPreferences?.edeliveryStatus !== EDeliveryStatus.NOT_ELIGIBLE;

  const showFlashMessage = !!flashMessage.messageText;
  const renderDateOptions = (): YearOption[] => {
    if (!customerInfo) return [];

    const { createdAt } = customerInfo;
    const sortedYears = range(moment().year(), moment(createdAt).year() - 1, -1);
    return sortedYears.map((year: number) => ({ value: year.toString(), name: year }));
  };

  const handleListItemClick = async (document: ViewDocument): Promise<void> => {
    // Reset error state
    setDocumentErrorMessages({});
    let response;
    try {
      response = await viewDocument(document.documentId);
    } catch (err) {
      const errorMessage = (err && err.data && err.data.message) || FlashMessageText.GENERIC_ERROR;
      setDocumentErrorMessages({ [document.documentId]: errorMessage });
      recordAnalyticsPageView(pageViewActions.viewDocumentNonceFailure);
    }
    if (response) {
      const url = VIEW_DOCUMENT_URL(response.documentLink);
      const open = window.open(url, '');

      if (!open) {
        // Could not open PDF, most likely because of a popup blocker
        setPopupBlockedModalUrl(url);
      }
    }
  };

  const renderListItems = () => {
    const renderTileListHeader = () => (
      <>
        <Hidden mdUp>
          <TileListSubtitles
            middleColumn="Documents"
            rightColumn="View"
            columnSizes={{ left: 4, middle: 4, right: 4 }}
            titleType="documents"
            data-test="documents-list-subtitles"
          />
        </Hidden>
        <Hidden smDown>
          <TileListSubtitles
            leftColumn="Document"
            middleColumn="Date"
            rightColumn="View"
            columnSizes={{ left: 6, middle: 2, right: 4 }}
            titleType="documents"
            data-test="documents-list-subtitles"
          />
        </Hidden>
      </>
    );

    return documents.length ? (
      <TileList
        header={renderTileListHeader()}
        data-test="documents-list"
        list={documents}
        listItemRenderProp={renderDocuments({ handleListItemClick, documentErrorMessages })}
        listItemComponent
      />
    ) : (
      <NoActivityTileList
        header={renderTileListHeader()}
        message={NoActivityMessage.NO_DOCUMENTS}
      />
    );
  };

  const renderQuickLinks = () => {
    return (
      <>
        {isEligibleForStatements(accounts) && (
          <Grid item xs={12}>
            <ArrowLink
              to={Routes.STATEMENTS}
              data-test="quick-links-documents-statements"
              className={classes.quickLinksActionLink}
              role="link"
            >
              {AccountActivityBtn.STATEMENTS}
            </ArrowLink>
          </Grid>
        )}

        {showEpreferencesLink(accounts) && (
          <Grid item xs={12}>
            <ArrowLink
              to={Routes.PREFERENCES}
              data-test="quick-links-documents-go-paperless"
              role="link"
            >
              {AccountActivityBtn.E_PREFERENCES}
            </ArrowLink>
          </Grid>
        )}

        <Grid item xs={12}>
          <ArrowLink
            to={DocumentURL.BANK_FORMS_URL}
            data-test="quick-links-documents-bank-forms"
            role="link"
          >
            {AccountActivityBtn.BANK_FORMS}
          </ArrowLink>
        </Grid>
      </>
    );
  };

  // const TypedSelectField = Field as new () => GenericField<SelectFieldProps & ReduxFieldProps>;
  const ContentGridContainerClassName = showFlashMessage
    ? classes.menuContainersWithFlashMssg
    : classes.menuContainers;

  const checkIsDocumentsLoading = (values) => {
    return isDocumentsLoading ? (
      <Grid item xs={12} aria-busy={isDocumentsLoading}>
        <LoadingIndicator aria-live="assertive" />
      </Grid>
    ) : (
      <>
        <Grid item xs={12}>
          {renderListItems()}
        </Grid>
        <Grid item xs={12} className={classes.loadMoreButtonContainer}>
          <LoadMoreButton
            data-test="load-more-documents"
            disabledText={LoadMoreDocumentsBtn.DISABLED}
            hasError={hasMoreDocumentsError}
            hasMore={hasMoreDocuments}
            isLoading={isMoreLoading}
            loadingText={LoadMoreDocumentsBtn.LOADING}
            loadMoreText={LoadMoreDocumentsBtn.CALL_TO_ACTION}
            data-track-title={clickTrack.documents.load_more_documents}
            onClick={() => {
              getMoreDocuments(values.year);
            }}
          />
        </Grid>
      </>
    );
  };

  return (
    <Page title="Documents" loading={isFullPageLoading}>
      <FullHeightLayout>
        <LeftPanelGridItem>
          <LeftPanelHeader
            title={DocumentsPageText.LEFT_PANEL_TITLE}
            text={DocumentsPageText.LEFT_PANEL_DESCRIPTION}
          />
          {showEPaymentButton && (
            <Grid item xs={12} className={classes.taxDocButton}>
              <ManageTaxDocument taxDocumentPreferences={taxDocumentPreferences} />
            </Grid>
          )}
          {(isEligibleForStatements(accounts) || isEligibleForEPreferences(accounts)) && (
            <div className={classes.quickLinksContainer}>
              <Hidden smDown>
                <StandardText
                  fontWeight="normal"
                  className={classes.quickLinksHeader}
                  component="h4"
                >
                  {labelConstants.QUICK_LINKS}
                </StandardText>
              </Hidden>
              {renderQuickLinks()}
            </div>
          )}
        </LeftPanelGridItem>

        <ContentGridContainerAndItem>
          <Grid item xs={12}>
            <AlertFlashMessage
              role="alert"
              flashMessage={flashMessage}
              showFlashMessage={showFlashMessage}
              resetScroll
              focusMessage
            />
            <Form
              onSubmit={() => {
                /* This is intentional to avoid eslint issue i.e sonarqube critcal issue */
              }}
            >
              {({ values }: FormRenderProps<DocumentsForm>) => {
                return hasDocumentsError || hasError || hasTaxDocumentsError ? (
                  <Error center onClick={onErrorClick(values.year)} />
                ) : (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={ContentGridContainerClassName}
                    >
                      <Grid item xs={12} sm={6} md={5} className={classes.documentYearSelect}>
                        <InputLabel
                          id={DocumentsFieldNames.YEAR}
                          className={classes.documentSelectLabel}
                        >
                          Year
                        </InputLabel>
                        <Field
                          name={DocumentsFieldNames.YEAR}
                          id={DocumentsFieldNames.YEAR}
                          placeholder="Select Year"
                          disablePlaceholder
                          data-test="document-year-dropdown"
                          data-test-label="document-year-dropdown-label"
                          options={renderDateOptions()}
                          classes={classes.documentSelectIcon}
                          ariaLabel={
                            isDocumentsLoading ? 'Document is loading' : 'Select Year Field'
                          }
                          component={RenderMenuItemSelectField}
                          ariaRequired
                          readOnly={isDocumentsLoading}
                          ariaDisabled={isDocumentsLoading}
                          defaultVal={documentYearValue}
                          initialValue={moment().year().toString()}
                          menuItemPaperClass={classes.documentMenuItem}
                          arrowIconWrapperClass={classes.arrowIconWrapperClass}
                        />
                        <OnChange name={DocumentsFieldNames.YEAR}>
                          {(year: number) => {
                            if (customerInfo) {
                              getDocuments(year);
                            }
                          }}
                        </OnChange>
                      </Grid>
                    </Grid>
                    {checkIsDocumentsLoading(values)}
                  </>
                );
              }}
            </Form>
          </Grid>
        </ContentGridContainerAndItem>
      </FullHeightLayout>
      <PopupBlockedModal
        retryUrl={popupBlockedModalUrl}
        onClose={() => {
          setPopupBlockedModalUrl(undefined);
        }}
      />
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
