import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../components/colors/colors';
import { Alignment } from '../../styles/layout/alignment.styles';
import pxToRem from '../../utilities/pxToRem';
import { lightFontWeight } from '../../components/typography/typography.styles';

export default makeStyles({
  affinityContainer: {
    marginBottom: pxToRem(16),
  },
  affinityIconWrapper: {
    marginRight: pxToRem(8),
  },
  apyDisclosure: {
    marginTop: pxToRem(24),
    marginBottom: pxToRem(32),
  },
  header: {
    color: Colors.white,
  },
  subHeader: {
    marginBottom: pxToRem(30),
    marginTop: pxToRem(25),
    color: Colors.white,
    maxWidth: pxToRem(850),
    fontWeight: lightFontWeight,
  },
  mainContent: {
    flexGrow: 1,
    marginTop: pxToRem(48),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...Alignment.horizontalPadding,
  },
  ratesAndTerms: {
    marginTop: pxToRem(28),
  },
  cancelButton: {
    marginBottom: pxToRem(28),
  },
  root: {
    minHeight: '100vh',
  },
  splitBackground: {
    background: `linear-gradient(180deg, #00415F ${pxToRem(480)}, ${
      Colors.backgroundGrey
    } ${pxToRem(480)})`,
  },
});
