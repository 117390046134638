import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import LoadingIndicatorStyled from '../../components/loadingIndicator/loadingIndicator';
import useEffectOnce from '../../utilities/reactHooks';
import getOWCSPageList from './owcs.actions';
import type { ReduxState } from '../../reducers';

type DispatchProps = {
  fetchOWCSPageList: () => void;
};

type StateProps = {
  isLoading: boolean;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchOWCSPageList: () => dispatch(getOWCSPageList()),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
  isLoading: state.owcs.loading,
});

type ComponentProps = {
  children: ReactElement;
};

type Props = DispatchProps & StateProps & ComponentProps;

export const OWCSWrapper = ({ children, isLoading, fetchOWCSPageList }: Props) => {
  useEffectOnce(() => {
    fetchOWCSPageList();
  });

  if (isLoading) {
    return (
      <div
        data-test="owcs-loading"
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
        }}
      >
        <LoadingIndicatorStyled />
      </div>
    );
  }

  return children;
};

export default connect(mapStateToProps, mapDispatchToProps)(OWCSWrapper);
