import * as React from 'react';
import Button from 'bank-component-library/ui/atoms/Button';
import { ButtonEvent } from '../buttons/buttons';
import IgniteModal, { IgniteModalActions, IgniteModalContent } from './igniteModal';
import { ModalBtnText } from '../cms/buttonText.constants';

export type ConfirmationModalProps = {
  confirmText: string;
  contentText: string;
  disableActions?: boolean;
  exitText?: string;
  onConfirm: ButtonEvent;
  onExit: () => void;
  titleText: string;
  visible: boolean;
  isWide?: boolean;
  ['data-track-page']?: string;
  ['data-track-title']?: string;
};

const ConfirmationModal = ({
  visible,
  titleText,
  contentText,
  confirmText,
  exitText,
  disableActions = false,
  onConfirm,
  onExit,
  isWide,
  'data-track-page': dataTrackPage,
  'data-track-title': dataTrackTitle,
}: ConfirmationModalProps) => (
  <IgniteModal
    visible={visible}
    data-test="confirmation-modal"
    title={titleText}
    onEscapeKeyDown={onExit}
    isWide={isWide}
  >
    <IgniteModalContent variant="text">{contentText}</IgniteModalContent>
    <IgniteModalActions isCL>
      <Button
        variant="primary-outlined"
        data-test="confirmation-modal-cancel-button"
        disabled={disableActions}
        onClick={onExit}
      >
        {exitText || ModalBtnText.CANCEL_GENERIC}
      </Button>
      <Button
        data-test="confirmation-modal-primary-button"
        data-track-title={dataTrackTitle}
        data-track-page={dataTrackPage}
        isLoading={disableActions}
        disabled={disableActions}
        onClick={onConfirm}
      >
        {confirmText}
      </Button>
    </IgniteModalActions>
  </IgniteModal>
);

export default ConfirmationModal;
