import React from 'react';
import IgniteFlashMessage from './IgniteFlashMessage';
import { FlashMessageProperties } from './flashMessage.reducer';

type AlertFlashMessageProps = {
  /** Role of div, either alert or status. Alert tells screen readers
   * to urgently read a message. Status is less urgent and may not
   * be read immediately.
   */
  role: 'alert' | 'status';
  /** Flash message properties, e.g. text, type */
  flashMessage: FlashMessageProperties;
  /** Controls rendering of the flash message. If not passed, will default to
   * rendering if the flashMessage has messageText and messageType props
   */
  showFlashMessage?: boolean;
  /** Scroll to top when message appears */
  resetScroll?: boolean;
  /** Whether to send screen focus to the message */
  focusMessage?: boolean;
  /** Class name */
  className?: string;
};

/**
 * Display a single flash message, wrapped in a live region for
 * screen reader announcements. This component must be rendered
 * unconditionally, or the live region may not work. The rendering
 * of the flash message is controlled by prop 'showFlashMessage'
 */
const AlertFlashMessage = (props: AlertFlashMessageProps) => {
  const {
    role,
    flashMessage,
    showFlashMessage = !!(flashMessage.messageText && flashMessage.messageType),
    resetScroll,
    focusMessage,
    className,
  } = props;
  const { messageText, messageType } = flashMessage;

  return (
    <div role={role}>
      {showFlashMessage && (
        <IgniteFlashMessage
          variant={messageType}
          focusMessage={focusMessage}
          resetScroll={resetScroll}
          className={className}
          aria-live={null} // we do not want the flash message component to control the live region
        >
          {messageText}
        </IgniteFlashMessage>
      )}
    </div>
  );
};

export default AlertFlashMessage;
