import React from 'react';
import Button from 'bank-component-library/ui/atoms/Button';
import IgniteModal, { IgniteModalContent, IgniteModalActions } from './igniteModal';
import { ModalBtnText } from '../cms/buttonText.constants';

type InfoModalProps = {
  title: string;
  description: React.ReactNode;
  onConfirm: () => void;
  visible: boolean;
  confirmText?: string;
};

export const InfoModal = (props: InfoModalProps) => {
  return (
    <IgniteModal
      visible={props.visible}
      title={props.title}
      data-test="info-modal"
      onEscapeKeyDown={props.onConfirm}
    >
      <IgniteModalContent variant="text">{props.description}</IgniteModalContent>
      <IgniteModalActions isCL>
        <Button onClick={props.onConfirm}>{props.confirmText || ModalBtnText.OKAY_BTN}</Button>
      </IgniteModalActions>
    </IgniteModal>
  );
};

export default InfoModal;
