import { StyleRules } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../../components/colors/colors';

export const useViewInboxContainerStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    contentContainer: {
      paddingTop: pxToRem(60),
    },
    marginTopContainer: {
      marginTop: pxToRem(30),
    },
    messageContainer: {
      paddingTop: pxToRem(48),
    },
    inboxRowContainer: {
      '&:hover': {
        cursor: 'pointer',
      },
      textDecoration: 'none',
      color: Colors.charcoalGrey,
    },
    inboxUnreadRowContainer: {
      backgroundColor: Colors.lightSkyBlue,
    },
    inboxUnreadIcon: {
      width: pxToRem(8),
      position: 'relative',
      right: pxToRem(16),
      marginRight: pxToRem(-8),
      height: pxToRem(10),
      paddingTop: pxToRem(3),
      boxSizing: 'content-box',
    },
    inboxCellLinkContainer: {
      textDecoration: 'none',
      color: Colors.charcoalGrey,
      outline: 'none',
      width: '100%',
      display: 'flex',
    },
    flashMessage: {
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
    },
    itemContainer: {
      padding: `${pxToRem(16)} ${pxToRem(12)} ${pxToRem(16)} ${pxToRem(24)}`,
      [theme.breakpoints.up('sm')]: {
        padding: `${pxToRem(16)} ${pxToRem(12)} ${pxToRem(16)} ${pxToRem(32)}`,
      },
      [theme.breakpoints.up('md')]: {
        padding: `${pxToRem(16)} ${pxToRem(12)} ${pxToRem(16)} ${pxToRem(48)}`,
      },
    },
    inboxHeaderPadding: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: pxToRem(48),
      },
    },
  })
);

export default useViewInboxContainerStyles;
