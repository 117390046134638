import { useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import useEffectOnce from '../../utilities/reactHooks';
import { fetchOWCSPageContentService } from './owcs.service';
import type { OWCSState, OWCSPage, OWCSPageContent } from './owcs.type';

export const useOWCSContent = (pageName: string) => {
  const { pageList = [] }: OWCSState = useSelector((state: RootStateOrAny) => state.owcs);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [owcsPageDetails, setOWCSPageDetails] = useState({ name: '', sections: [] });

  const loadOWCSPageDetails = async (pageId: number) => {
    setIsLoading(true);
    setError(false);
    try {
      const pageDetails: OWCSPageContent | null | undefined = await fetchOWCSPageContentService(
        pageId
      );
      setOWCSPageDetails(pageDetails);
    } catch (e) {
      setError(true);
      // do nothing
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    const pageAttributes: OWCSPage | null | undefined = pageList.find(
      (page) => page.name === pageName
    );
    if (pageAttributes) loadOWCSPageDetails(pageAttributes.pageId);
  });

  return { loading, error, loadOWCSPageDetails, ...owcsPageDetails };
};

export default useOWCSContent;
