export const ViewInboxPageTexts = Object.freeze({
  TITLE: 'Inbox',
  HEADER_TITLE: "We're here to help",
  DESCRIPTION: 'Send a secure message and a Banker will respond within 24 hours.',
  SEND_MSG_BTN: 'Send a message',
  FAQ_DESC: 'Skip the wait and get answers to our most commonly asked questions in our FAQs.',
  FAQ_LINK: 'Frequently Asked Questions',
  LEARN_MORE_DESC: 'Deposit checks no matter where you are with the Synchrony Bank app.',
  LEARN_MORE_LINK: 'Learn more',
  CHAT_WITH_US: 'Chat with us: ',
  AVAILABLE_LINK: 'Available',
  NOT_AVAILABLE: 'Not Available',
});

export const ViewInboxFlashMessageTexts = Object.freeze({
  SUCCESS: 'Your message has been sent.',
  ERROR:
    'Unfortunately an error has occurred and your message was not sent. Please try sending this message again.',
});

export type LatestMessageInfo = {
  date: string;
  status: string;
};

export type MessageThread = {
  threadId: string;
  topic: string;
  latestMessage: LatestMessageInfo;
};

export type MessagesInfo = {
  messageThreads: MessageThread[];
};

export default ViewInboxPageTexts;
