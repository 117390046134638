import { FormHelperText, withStyles } from '@material-ui/core';
import React from 'react';
import { normalFontWeight } from '../../../components/typography/typography.styles';

const styles = {
  root: {
    fontWeight: normalFontWeight,
  },
} as const;

const ErrorWrapper = ({
  classes = {},
  id,
  className = 'errors',
  children,
  ariaLive,
  error,
  ...rest
}) => (
  <FormHelperText
    role="status"
    classes={classes}
    id={id}
    className={className}
    aria-live="polite"
    aria-atomic="true"
    data-test="form-error"
    error={error}
    {...rest}
  >
    {children}
  </FormHelperText>
);

export default withStyles(styles)(ErrorWrapper);
