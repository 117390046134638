import React, { forwardRef } from 'react';
import type { ReactNode, Ref } from 'react';
import {
  withStyles,
  FormControl,
  Input,
  InputLabel,
  NativeSelect as MuiSelect,
  StyleRules,
} from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import type { StyleProps } from '../../utilities/types';
import ErrorWrapper from '../../containers/newAccountOpening/errorWrapper/errorWrapper';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const styles = (): StyleRules => ({
  root: {
    marginTop: '0 !important', // Use important to override material-ui styles
    paddingRight: pxToRem(10),
    '&:focus-within': {
      outline: `${pxToRem(2)} solid black`,
      'border-radius': pxToRem(5),
    },
  },
  inputLabel: {
    position: 'relative',
    pointerEvents: 'none',
  },
  shrink: {
    marginLeft: pxToRem(15),
    top: pxToRem(-5),
  },
  select: {
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  icon: {
    marginBottom: pxToRem(2),
    width: pxToRem(10),
    height: pxToRem(10),
    position: 'relative',
    transform: 'rotate(90deg)',
  },
  iconWrapperClass: {
    position: 'absolute',
    pointerEvents: 'none',
    right: pxToRem(8),
  },
});

type SelectProps = {
  id?: string;
  children?: ReactNode;
  error?: boolean;
  ['data-test']?: string;
  ['data-test-label']?: string;
  FormHelperTextProps?: Record<string, unknown>;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  labelId?: string;
  inputProps?: { [key: string]: any };
  value?: string;
  disabled?: boolean;
  onChange?: (arg1: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const Select = forwardRef((props: SelectProps & StyleProps, ref: Ref<HTMLInputElement>) => {
  const {
    id,
    children,
    classes = {},
    'data-test': dataTest,
    'data-test-label': dataTestLabel,
    FormHelperTextProps = {},
    fullWidth = true,
    helperText = null,
    label = '',
    labelId,
    error,
    inputProps,
    ...rest
  } = props;

  return (
    <FormControl fullWidth={fullWidth} className={classes.formControl} error={error}>
      <InputLabel
        id={labelId}
        shrink
        classes={{ root: classes.inputLabel, shrink: classes.shrink }}
        htmlFor={id}
        data-test={dataTestLabel}
      >
        {label}
      </InputLabel>
      <MuiSelect
        {...rest}
        input={<Input id={id} />}
        className={classes.root}
        inputProps={inputProps}
        classes={{
          select: classes.select,
        }}
        IconComponent={() => (
          <SVGImage
            wrapperContainerClass={classes.iconWrapperClass}
            className={classes.icon}
            imageName={ImagesFileNames.dateArrowSvg}
          />
        )}
        data-test={dataTest}
        inputRef={ref}
      >
        {children}
      </MuiSelect>
      {helperText && (
        <ErrorWrapper label={label} error={error} {...FormHelperTextProps}>
          {helperText}
        </ErrorWrapper>
      )}
    </FormControl>
  );
});

const styled = withStyles(styles)(Select);
styled.displayName = 'Select';
export default styled;
