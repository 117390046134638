import type { ReduxState } from '../../reducers';
import type { Action } from '../../utilities/types';
import pageAnalytics from '../pageAnalytics';
import {
  ACTION_FAIL_VERIFY_TRIAL_DEPOSITS,
  ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS,
} from '../../containers/verifyTrialDeposits/verifyTrialDeposits.reducer';
import { ANALYTICS_VERIFY_DEPOSITS } from '../actions';
import type { AccountFundingFlow } from '../../utilities/accountFundingFlowType';
import { AFFlow } from '../../utilities/accountFundingFlowType';
import pageTrack from '../pageAnalytics.constants';
import { getPageSubfunction } from './fundNewAccount.analytics';

const defaultData = {
  account_type: pageTrack.account_type.generic,
  pagekind: pageTrack.pagekind.transfers,
  pagefunction: pageTrack.pagefunction.add_external_bank,
} as const;

const getRouteType = (fundingFlow: AccountFundingFlow) => {
  switch (fundingFlow) {
    case AFFlow.EAO:
      return pageTrack.pagesubfunction.EAO;

    case AFFlow.NAO:
      return pageTrack.pagesubfunction.NAO;

    case AFFlow.DIRECT:
    default:
      return pageTrack.pagesubfunction.servicing;
  }
};

const viewVerifyDeposits =
  (fundingFlow: AccountFundingFlow, attr = '') =>
  (action: Action, state: ReduxState) => {
    // should only be used for non funding flow page view
    const { verifyTrialDeposits } = state;
    const { isVerifySuccessful } = verifyTrialDeposits;

    pageAnalytics
      .newPageView(defaultData)
      .addInfo(
        isVerifySuccessful
          ? {
              pagesubfunction: getPageSubfunction(fundingFlow),
              pagename: pageTrack.pagename.complete,
              attr,
            }
          : {
              pagesubfunction: pageTrack.pagesubfunction.trial_deposits,
              pagename: pageTrack.pagename.step2_verify,
              attr,
            }
      )
      .record();
  };

const verifySuccess = (fundingFlow: AccountFundingFlow) => () => {
  // can occur on any flow with instant or micro deposit
  const inFundingFlow = fundingFlow !== AFFlow.DIRECT;

  pageAnalytics
    .newPageView(defaultData)
    .addInfo({
      pagesubfunction: inFundingFlow ? getRouteType(fundingFlow) : 'trial_deposits',
      pagename: inFundingFlow ? pageTrack.pagename.complete : pageTrack.pagename.step2_verify,
    })
    .record();
};

const verifyFailure = () => {
  // can only occur on a micro deposit verification non funding flow
  pageAnalytics
    .newPageView(defaultData)
    .addInfo({
      pagesubfunction: pageTrack.pagesubfunction.trial_deposits,
      pagename: pageTrack.pagename.step2_verify_submit_error,
      attr: pageTrack.attr.md,
    })
    .record();
};

export const verifyTrialDepositsPageEvents = (fundingFlow: AccountFundingFlow) => ({
  // IBSS-1634: user view
  [ANALYTICS_VERIFY_DEPOSITS]: viewVerifyDeposits(fundingFlow, pageTrack.attr.ews),

  // IBSS-1633: Verify Trial Deposits
  [ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS]: verifySuccess(fundingFlow),
  [ACTION_FAIL_VERIFY_TRIAL_DEPOSITS]: verifyFailure,
});

export default verifyTrialDepositsPageEvents;
