import { Grid, withStyles } from '@material-ui/core';
import type { ReactNode } from 'react';
import React from 'react';
import Colors from '../colors/colors';
import { Z_INDEX_LEFT_COLUMN } from '../../styles/zIndex';
import pxToRem from '../../utilities/pxToRem';
import { LEFT_PANEL_BOX_SHADOW } from './LeftPanelGridItem';
import type { StyleProps } from '../../utilities/types';

const styles = {
  leftPanelContainer: {
    zIndex: Z_INDEX_LEFT_COLUMN,
    width: pxToRem(295),
    flex: '0 1 auto',
    backgroundColor: Colors.white,
    boxShadow: LEFT_PANEL_BOX_SHADOW,
    paddingTop: '1rem',
  },
} as const;
type Props = {
  children: ReactNode;
} & StyleProps;

const NarrowLeftPanelGridItemWrapper = (props: Props) => (
  <Grid item component="aside" className={props.classes.leftPanelContainer}>
    {props.children}
  </Grid>
);

const Styles = withStyles(styles);
const narrowLeftPanelGridItem = Styles(NarrowLeftPanelGridItemWrapper);
narrowLeftPanelGridItem.displayName = 'NarrowLeftPanelGridItem';
export default narrowLeftPanelGridItem;
