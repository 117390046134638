import type { Account, ServiceError } from '../../utilities/types';
import type { Downtime, FetchAccountsPayload } from './accountDashboard.service';
import { CustomerRelationshipTypes } from './accountDashboard.constants';

export type AccountDashboardState = {
  data: Array<Account>;
  beneficiaryAccounts: Array<Account>;
  isLoading: boolean;
  isBeneficiaryAccountsLoading: boolean;
  totalBalance: string;
  downtime?: Downtime;
  error?: ServiceError;
  cmsIsLoading?: boolean;
  loginRedirectPath?: string;
  asOfDate: string;
  nextPageAvailable: boolean;
};

function getInitialState(): AccountDashboardState {
  return {
    beneficiaryAccounts: [],
    cmsIsLoading: false,
    isLoading: false,
    isBeneficiaryAccountsLoading: false,
    data: [],
    totalBalance: '0',
    asOfDate: '',
    nextPageAvailable: false,
  };
}

export const ACTION_FETCH_ACCOUNTS_REQUEST = 'ACTION_FETCH_ACCOUNTS_REQUEST';
export const ACTION_FETCH_BENEFICIARY_ACCOUNTS_REQUEST =
  'ACTION_FETCH_BENEFICIARY_ACCOUNTS_REQUEST';
export const ACTION_FETCH_ACCOUNTS_SUCCESS = 'ACTION_FETCH_ACCOUNTS_SUCCESS';
export const ACTION_FETCH_BENEFICIARY_ACCOUNTS_SUCCESS =
  'ACTION_FETCH_BENEFICIARY_ACCOUNTS_SUCCESS';
export const ACTION_FETCH_ACCOUNTS_FAILURE = 'ACTION_FETCH_ACCOUNTS_FAILURE';
export const ACTION_FETCH_BENEFICIARY_ACCOUNTS_FAILURE =
  'ACTION_FETCH_BENEFICIARY_ACCOUNTS_FAILURE';
export const ACTION_FETCH_DOWNTIME_SUCCESS = 'ACTION_FETCH_DOWNTIME_SUCCESS';
export const ACTION_SAVE_ACCOUNT_NICKNAME_SUCCESS = 'ACTION_SAVE_ACCOUNT_NICKNAME_SUCCESS';
export const ACTION_UPDATE_ACCOUNTS = 'ACTION_UPDATE_ACCOUNTS';
export const ACTION_FETCH_CMS_CONTENT_REQUEST = 'ACTION_FETCH_CMS_CONTENT_REQUEST';
export const ACTION_FETCH_CMS_CONTENT_FAILURE = 'ACTION_FETCH_CMS_CONTENT_FAILURE';
export const ACTION_UPDATE_LOGIN_REDIRECT_PATH = 'ACTION_UPDATE_LOGIN_REDIRECT_PATH';

type EditNicknameRequest = {
  accountId: string;
  nickname: string;
};

const editNickname = (accounts: Array<Account>, payload: EditNicknameRequest): Array<Account> =>
  accounts.map((account) => {
    if (account.accountId === payload.accountId) {
      return { ...account, nickname: payload.nickname };
    }
    return account;
  });

export const isPrimaryOwnerOfAnAccount = (accounts: Account[]) => {
  return !!(accounts && accounts.some((account) => account.primaryOwner));
};

export const isEligibleForStatements = (accounts: Account[]) => {
  return !!(
    accounts &&
    accounts.some((account) => account.relationshipType !== CustomerRelationshipTypes.GUARDIAN) &&
    accounts.some((account) => account.accountStatus !== 'Restricted')
  );
};

const isAccountEligibleForEPreferences = (account: Account) => {
  return (
    account.accountStatus === 'Active' &&
    (account.relationshipType === CustomerRelationshipTypes.CUSTODIAN ||
      account.relationshipType === CustomerRelationshipTypes.SOLE_OWNER ||
      account.relationshipType === CustomerRelationshipTypes.JOINT_PRIMARY_OWNER ||
      account.relationshipType === CustomerRelationshipTypes.GRANTOR_TRUSTEE)
  );
};

export const isEligibleForEPreferences = (accounts: Account[]) => {
  return !!(accounts && accounts.some((account) => isAccountEligibleForEPreferences(account)));
};

export const showEPreferencesDashboardAlert = (accounts: Account[]) => {
  return !!(
    accounts &&
    accounts.some((account) => isAccountEligibleForEPreferences(account) && !account.eDelivery)
  );
};

const updateAccountsData = (accounts: Array<Account>, payload: Partial<Account>): Array<Account> =>
  accounts.map((account) =>
    account.accountId === payload.accountId ? { ...account, ...payload } : account
  );

export type AccountDashboardAction =
  | {
      type: 'ACTION_FETCH_ACCOUNTS_REQUEST';
    }
  | {
      type: 'ACTION_FETCH_BENEFICIARY_ACCOUNTS_REQUEST';
    }
  | {
      type: 'ACTION_FETCH_ACCOUNTS_SUCCESS';
      payload: FetchAccountsPayload;
    }
  | {
      type: 'ACTION_FETCH_BENEFICIARY_ACCOUNTS_SUCCESS';
      payload: FetchAccountsPayload;
    }
  | {
      type: 'ACTION_FETCH_ACCOUNTS_FAILURE';
      payload: ServiceError;
    }
  | {
      type: 'ACTION_FETCH_BENEFICIARY_ACCOUNTS_FAILURE';
      payload: ServiceError;
    }
  | {
      type: 'ACTION_FETCH_DOWNTIME_SUCCESS';
      payload: Downtime;
    }
  | {
      type: typeof ACTION_SAVE_ACCOUNT_NICKNAME_SUCCESS;
      payload: EditNicknameRequest;
    }
  | {
      type: typeof ACTION_UPDATE_ACCOUNTS;
      payload: Partial<Account>;
    }
  | {
      type: 'ACTION_FETCH_CMS_CONTENT_REQUEST';
    }
  | {
      type: 'ACTION_FETCH_CMS_CONTENT_FAILURE';
      payload: ServiceError;
    }
  | {
      type: typeof ACTION_UPDATE_LOGIN_REDIRECT_PATH;
      payload: string;
    };

export default (
  state: AccountDashboardState | null | undefined = getInitialState(),
  action: AccountDashboardAction = undefined
) => {
  const { error, ...rest } = state;
  switch (action.type) {
    case ACTION_FETCH_ACCOUNTS_REQUEST:
      return {
        ...rest,
        isLoading: true,
      };
    case ACTION_FETCH_BENEFICIARY_ACCOUNTS_REQUEST:
      return {
        ...rest,
        isBeneficiaryAccountsLoading: true,
      };
    case ACTION_FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        data:
          state.data.length === 0 || action.payload.isRefetch
            ? action.payload.accounts
            : [...state.data, ...action.payload.accounts],
        isLoading: false,
        totalBalance: action.payload.customerTotalBalance,
        asOfDate: action.payload.asOfDate,
        nextPageAvailable: action.payload.nextPageAvailable,
      };
    case ACTION_FETCH_BENEFICIARY_ACCOUNTS_SUCCESS:
      return {
        ...state,
        beneficiaryAccounts: action.payload.accounts,
        isBeneficiaryAccountsLoading: false,
        totalBalance: action.payload.customerTotalBalance,
      };
    case ACTION_FETCH_BENEFICIARY_ACCOUNTS_FAILURE:
      return {
        ...state,
        isBeneficiaryAccountsLoading: false,
      };
    case ACTION_FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ACTION_FETCH_DOWNTIME_SUCCESS:
      return {
        ...state,
        downtime: action.payload,
      };
    case ACTION_SAVE_ACCOUNT_NICKNAME_SUCCESS:
      return {
        ...state,
        data: editNickname(state.data, action.payload),
      };
    case ACTION_UPDATE_ACCOUNTS:
      return {
        ...state,
        data: updateAccountsData(state.data, action.payload),
      };
    case ACTION_FETCH_CMS_CONTENT_FAILURE:
      return {
        ...state,
        cmsIsLoading: false,
      };
    case ACTION_FETCH_CMS_CONTENT_REQUEST:
      return {
        ...state,
        cmsIsLoading: true,
      };
    case ACTION_UPDATE_LOGIN_REDIRECT_PATH:
      return {
        ...state,
        loginRedirectPath: action.payload,
      };
    default:
      return state;
  }
};
