import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import {
  ANALYTICS_CD_INTEREST_PAYMENTS_START_FAILURE,
  ANALYTICS_CD_INTEREST_PAYMENTS_START_SUCCESS,
  ANALYTICS_CD_INTEREST_PAYMENTS_STOP_FAILURE,
  ANALYTICS_CD_INTEREST_PAYMENTS_STOP_SUCCESS,
  ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_FAILURE,
  ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_SUCCESS,
} from '../actions';
import type { CDInterestPaymentsAnalyticsAction } from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.cd_interest_payments,
    pagefunction: pageTrack.pagefunction.select_account,
  });

const viewSuccess = (action: CDInterestPaymentsAnalyticsAction) => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start, attr: action.payload }).record();
};

const viewFailure = (action: CDInterestPaymentsAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.start_server_error, attr: action.payload })
    .record();
};

const submitSuccess = (action: CDInterestPaymentsAnalyticsAction) => {
  viewInit().addInfo({ pagename: pageTrack.pagename.complete, attr: action.payload }).record();
};

const submitFailure = (action: CDInterestPaymentsAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.submit_server_error, attr: action.payload })
    .record();
};

const stopSuccess = () => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.complete,
      pagefunction: pageTrack.pagefunction.stop_payments,
    })
    .record();
};

const stopFailure = () => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.submit_server_error,
      pagefunction: pageTrack.pagefunction.stop_payments,
    })
    .record();
};

export const cdInterestPaymentsPageEvents = {
  [ANALYTICS_CD_INTEREST_PAYMENTS_START_SUCCESS]: viewSuccess,
  [ANALYTICS_CD_INTEREST_PAYMENTS_START_FAILURE]: viewFailure,
  [ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_SUCCESS]: submitSuccess,
  [ANALYTICS_CD_INTEREST_PAYMENTS_SUBMIT_FAILURE]: submitFailure,
  [ANALYTICS_CD_INTEREST_PAYMENTS_STOP_SUCCESS]: stopSuccess,
  [ANALYTICS_CD_INTEREST_PAYMENTS_STOP_FAILURE]: stopFailure,
} as const;

export default cdInterestPaymentsPageEvents;
