/* eslint-disable sonarjs/no-duplicate-string */
import { accountDetails } from './mockDataConstants.ts';
import { ExternalAccountTypes } from '../../containers/addExternalAccount/addExternalAccount.actions.ts';
import AccountDetailsTypes from '../../domain/Account/AccountDetails.ts';
import AllAccountsConstants from '../../containers/allAccounts/allAccounts.constants';

const mockProduct = {
  productDisplayType: 'HYS',
  productId: '123',
  productName: 'High Yield Savings',
  productType: 'High Yield Savings',
  productTypeName: 'Savings',
};

const mockAccounts = [
  {
    accountApy: '1.00',
    accountId: '8675-309',
    accountIdDisplay: '●●5309',
    accountStatus: 'Active',
    accountType: 'Savings',
    accountTypeDisplayName: 'High Yield Savings',
    availableBalance: '54000.43',
    creatable: true,
    eDelivery: false,
    funded: true,
    nickname: 'MyAccount',
    openedDate: '2018-01-17',
    owner: true,
    primaryOwner: true,
    productId: '130',
    product: mockProduct,
    productDisplayType: 'HYS',
    rate: accountDetails.APY_RATE,
    relationshipType: accountDetails.ACCOUNT_RELATIONSHIP_TYPE,
    totalBalance: '56080.43',
    transfersFromEnabled: true,
    transfersToEnabled: true,
  },
  {
    accountApy: '1.05',
    accountId: '1234-567',
    accountIdDisplay: '●●4567',
    accountStatus: 'Active',
    accountType: 'Checking',
    accountTypeDisplayName: 'Money Market Account',
    availableBalance: '56.43',
    creatable: true,
    eDelivery: false,
    funded: true,
    nickname: 'Scuba',
    openedDate: '2015-05-17',
    owner: true,
    primaryOwner: true,
    productId: '140',
    product: mockProduct,
    productDisplayType: 'MMA',
    rate: accountDetails.APY_RATE,
    relationshipType: accountDetails.ACCOUNT_RELATIONSHIP_TYPE,
    totalBalance: '56.43',
    transfersFromEnabled: true,
    transfersToEnabled: true,
  },
  {
    accountApy: '1.50',
    accountId: '9098-765',
    accountIdDisplay: '●●8765',
    accountStatus: 'Active',
    accountType: 'Certificate of Deposit',
    accountTypeDisplayName: 'Certificate of Deposit',
    availableBalance: '123.22',
    creatable: true,
    eDelivery: false,
    funded: true,
    nickname: '',
    openedDate: '2020-02-11',
    owner: true,
    primaryOwner: true,
    productId: '150',
    product: mockProduct,
    productDisplayType: 'CD',
    rate: accountDetails.APY_RATE,
    relationshipType: accountDetails.ACCOUNT_RELATIONSHIP_TYPE,
    totalBalance: '123.22',
    transfersFromEnabled: false,
    transfersToEnabled: false,
  },
];

export const fetchAllAccountsSuccessBody = {
  data: [
    {
      accountDisplayId: '●●4859',
      accountId: null,
      accountType: ExternalAccountTypes.SAVINGS,
      availableBalance: '125.00',
      bankName: 'Zeal Credit Union',
      external: true,
      externalId: '13445031',
      name: 'Zeal Credit Union, Savings, ****4859',
      nickname: null,
      product: null,
      transferFromEnabled: true,
      transferToEnabled: true,
    },
    {
      accountDisplayId: '●●123',
      accountId: null,
      accountType: AccountDetailsTypes.CHECKING,
      availableBalance: '2000.00',
      bankName: 'My Financial 4',
      external: true,
      externalId: '13475053',
      name: 'My Financial 4, Checking, 123',
      nickname: null,
      product: null,
      transferFromEnabled: true,
      transferToEnabled: true,
    },
    {
      accountDisplayId: '●●7114',
      accountId: 'd6bfaeeb-deaf-494a-9604-6433ffbf2027',
      accountType: ExternalAccountTypes.SAVINGS,
      availableBalance: '3807.46',
      bankName: 'Synchrony Bank',
      external: false,
      externalId: '13445028',
      name: 'Savings ●●7114',
      nickname: '',
      product: {
        productDisplayType: 'HYS',
        productId: '100',
        productName: 'HYS PERSONAL',
        productType: AllAccountsConstants.High_Yield_Savings,
      },
      transferFromEnabled: true,
      transferToEnabled: true,
    },
    {
      accountDisplayId: '●●5786',
      accountId: '21b5c8d0-3858-4ebd-91e7-d12e0e44b1e5',
      accountType: ExternalAccountTypes.SAVINGS,
      availableBalance: '1000.00',
      bankName: 'Synchrony Bank',
      external: false,
      externalId: '14486540',
      name: 'Savings ●●5786',
      nickname: '',
      product: {
        productDisplayType: 'HYS',
        productId: '100',
        productName: 'HYS PERSONAL',
        productType: AllAccountsConstants.High_Yield_Savings,
      },
      transferFromEnabled: true,
      transferToEnabled: true,
    },
  ],
};

export default mockAccounts;
