import type { AccountDetails, Transaction } from 'Domain/Account/AccountDetails';
import type { Account, ServiceError } from '../../utilities/types';
import type { AccountDashboardState } from '../accountDashboard/accountDashboard.reducer';
import { accountDetailHelpers } from '../routes/routes.constants';
import AccountDetailsTypes from '../../domain/Account/AccountDetails';

export const ACTION_CLEAR_ACCOUNT_ACTIVITY = 'ACTION_CLEAR_ACCOUNT_ACTIVITY';
export const ACTION_CLEAR_ACCOUNT_ACTIVITY_ERRORS = 'ACTION_CLEAR_ACCOUNT_ACTIVITY_ERRORS';
export const ACTION_FETCH_ACCOUNT_DETAILS_REQUEST = 'ACTION_FETCH_ACCOUNT_DETAILS_REQUEST';
export const ACTION_FETCH_ACCOUNT_DETAILS_SUCCESS = 'ACTION_FETCH_ACCOUNT_DETAILS_SUCCESS';
export const ACTION_FETCH_ACCOUNT_DETAILS_FAILURE = 'ACTION_FETCH_ACCOUNT_DETAILS_FAILURE';
export const ACTION_NICKNAME_MODAL_RESET = 'ACTION_NICKNAME_MODAL_RESET';
export const ACTION_SAVE_ACCOUNT_NICKNAME_REQUEST = 'ACTION_SAVE_ACCOUNT_NICKNAME_REQUEST';
export const ACTION_SAVE_ACCOUNT_NICKNAME_RESP_FAILURE =
  'ACTION_SAVE_ACCOUNT_NICKNAME_RESP_FAILURE';
export const ACCOUNT_NICKNAME_MODAL_TOGGLE = 'ACCOUNT_NICKNAME_MODAL_TOGGLE';
export const ACTION_FETCH_ACCOUNT_TRANSACTIONS_REQUEST =
  'ACTION_FETCH_ACCOUNT_TRANSACTIONS_REQUEST';
export const ACTION_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS =
  'ACTION_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS';
export const ACTION_FETCH_ACCOUNT_TRANSACTIONS_FAILURE =
  'ACTION_FETCH_ACCOUNT_TRANSACTIONS_FAILURE';
export const ACTION_FETCH_MORE_TRANSACTIONS_REQUEST = 'ACTION_FETCH_MORE_TRANSACTIONS_REQUEST';
export const ACTION_FETCH_MORE_TRANSACTIONS_FAILURE = 'ACTION_FETCH_MORE_TRANSACTIONS_FAILURE';
export const ACTION_FETCH_ACCOUNT_ID_REQUEST = 'ACTION_FETCH_ACCOUNT_ID_REQUEST';
export const ACTION_FETCH_ACCOUNT_ID_SUCCESS = 'ACTION_FETCH_ACCOUNT_ID_SUCCESS';
export const ACTION_FETCH_ACCOUNT_ID_FAILURE = 'ACTION_FETCH_ACCOUNT_ID_FAILURE';

export const emptyAccountDetails: AccountDetails = {
  accountBeneficiaries: [],
  accountId: '',
  accountIdDisplay: '',
  accountOwners: [],
  currentBalance: '',
  holdBalance: '',
  inGracePeriod: false,
  interestDistribution: { method: 'Capitalize' },
  interest: {
    apy: '',
    paidYTD: '',
    rate: '',
  },
  iraPlanType: '',
  maturityDate: '',
  openDate: new Date(),
  routingNumber: '',
  term: '',
  withdrawal: {
    allowed: '',
    count: '',
  },
  primaryOwner: false,
  product: {
    detail: {
      displayText: '',
      url: '',
    },
    interest: {
      accrualMethod: '',
      paymentFrequency: '',
      paymentPeriod: '',
      yearBase: '',
    },
    interestRateId: '',
    marketApy: 0,
    marketRate: 0,
    maxOpenAmount: 0,
    minOpenAmount: 0,
    productDefinitionType: '',
    productDisplayType: 'NONE',
    productId: '',
    productName: '',
    productType: '',
    productTypeId: '',
    productTypeName: 'NONE',
    promotion: {
      promotionBonusAmount: 0,
      promotionBonusDays: 0,
      promotionCode: '',
      promotionName: '',
      promotionDescription: '',
      promotionDisclosure: '',
      promotionMinBalance: 0,
      promotionMaxBalance: 0,
      promotionEndDate: '',
      promotionFundByDate: '',
      promotionNewMoneyRequired: false,
      promotionStartDate: '',
      promotionType: '',
    },
    rateMatrices: [],
    term: 0,
    termUnits: '',
    tiers: [{ amount: '', rate: '' }],
  },
  nickname: '',
  type: AccountDetailsTypes.CHECKING,
};

export type FetchAccountTransactionsPayload = {
  transactions: Array<Transaction>;
  cursor: string | null | undefined;
  lastPage: boolean;
};

export type FetchCheckImagesPayload = {
  front: string;
  back: string;
};

export type AccountActivityState = {
  accountDetails: AccountDetails;
  isAccountIdLoading: boolean;
  isAccountIdError: boolean;
  accountsError?: ServiceError;
  cursor: string | null | undefined;
  hasLoadMoreTransactionsError: boolean;
  isAccountLoading: boolean;
  isFirstTransactionLoading: boolean;
  isMoreTransactionLoading: boolean;
  editNicknamePending: boolean;
  lastPage: boolean;
  transactions: Array<Transaction>;
  transactionsError?: ServiceError;
  decryptedAccountId: string | null | undefined;
  checkImages?: FetchCheckImagesPayload;
  isCheckImagesError?: boolean;
  nickNameRespError?: boolean;
  isNicknameModalOpen: boolean;
};

function getInitialState(): AccountActivityState {
  return {
    accountDetails: emptyAccountDetails,
    transactions: [],
    hasLoadMoreTransactionsError: false,
    isAccountLoading: false,
    isAccountIdLoading: false,
    isAccountIdError: false,
    isFirstTransactionLoading: false,
    isMoreTransactionLoading: false,
    lastPage: false,
    cursor: null,
    editNicknamePending: false,
    decryptedAccountId: null,
    isNicknameModalOpen: false,
  };
}

export type AccountActivityAction =
  | {
      type: typeof ACTION_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS;
      payload: FetchAccountTransactionsPayload;
    }
  | {
      type: typeof ACTION_FETCH_ACCOUNT_TRANSACTIONS_FAILURE;
      payload: ServiceError;
    }
  | {
      type: typeof ACTION_FETCH_MORE_TRANSACTIONS_FAILURE;
    }
  | {
      type: typeof ACTION_FETCH_ACCOUNT_TRANSACTIONS_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_MORE_TRANSACTIONS_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_ACCOUNT_DETAILS_SUCCESS;
      payload: AccountDetails;
    }
  | {
      type: 'ACTION_FETCH_ACCOUNT_DETAILS_FAILURE';
      payload: ServiceError;
    }
  | {
      type: 'ACTION_FETCH_ACCOUNT_DETAILS_REQUEST';
    }
  | {
      type: typeof ACTION_FETCH_ACCOUNT_ID_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_ACCOUNT_ID_SUCCESS;
      payload: string;
    }
  | {
      type: typeof ACTION_FETCH_ACCOUNT_ID_FAILURE;
    }
  | {
      type: 'ACTION_CLEAR_ACCOUNT_ID';
    }
  | {
      type: 'ACTION_DISPLAY_ACCOUNT_ACTIVITY';
      payload: AccountDetails;
    }
  | {
      type: typeof ACTION_CLEAR_ACCOUNT_ACTIVITY;
    }
  | {
      type: typeof ACTION_CLEAR_ACCOUNT_ACTIVITY_ERRORS;
    }
  | {
      type: 'ACTION_FETCH_CHECK_IMAGE_REQUEST';
    }
  | {
      type: 'ACTION_FETCH_CHECK_IMAGE_SUCCESS';
      payload: FetchCheckImagesPayload;
    }
  | {
      type: 'ACTION_FETCH_CHECK_IMAGE_FAILURE';
      payload: ServiceError;
    }
  | {
      type: typeof ACTION_SAVE_ACCOUNT_NICKNAME_REQUEST;
    }
  | {
      type: typeof ACTION_NICKNAME_MODAL_RESET;
    }
  | {
      type: typeof ACTION_SAVE_ACCOUNT_NICKNAME_RESP_FAILURE;
    }
  | {
      type: 'ACCOUNT_NICKNAME_MODAL_TOGGLE';
    };

const reduceFetchAccountTransactionsSuccess = (
  payload: FetchAccountTransactionsPayload,
  state
): AccountActivityState => {
  const { transactions: existingTransactions = [] } = state;
  const { transactions: newTransactions, lastPage, cursor = null } = payload;

  return {
    ...state,
    transactions: existingTransactions.concat(newTransactions),
    lastPage,
    cursor,
    isFirstTransactionLoading: false,
    isMoreTransactionLoading: false,
  };
};

export function buildEmptyAccount(): Account {
  return {
    accountApy: '',
    accountId: '',
    accountIdDisplay: '',
    accountStatus: '',
    accountType: 'NONE',
    accountTypeDisplayName: '',
    availableBalance: '',
    funded: false,
    eDelivery: false,
    nickname: '',
    owner: false,
    primaryOwner: false,
    productId: '',
    productDisplayType: 'NONE',
    rate: '',
    relationshipType: '',
    totalBalance: '',
    transfersFromEnabled: false,
    transfersToEnabled: false,
    type: AccountDetailsTypes.CHECKING,
  };
}

// note: if the page is hard reloaded the account info is not immediately available.
// Due to the complexity of the call this is an acceptable solution for now.
export const getSelectedAccount = (state: {
  accounts: AccountDashboardState;
}): Account | typeof undefined => {
  const { accounts } = state;
  return (
    accounts.data &&
    accounts.data.find(
      (account) => account.accountId === accountDetailHelpers.getAccountIdFromUrl()
    )
  );
};

export default (
  state: AccountActivityState | null | undefined = getInitialState(),
  action: AccountActivityAction = undefined
): AccountActivityState => {
  // For clearing errors only, declared up here because of ESLint rule
  const { transactionsError, accountsError, ...otherState } = state;

  switch (action.type) {
    case ACTION_SAVE_ACCOUNT_NICKNAME_REQUEST:
      return {
        ...state,
        editNicknamePending: true,
        nickNameRespError: false,
      };
    case ACTION_NICKNAME_MODAL_RESET:
      return {
        ...state,
        editNicknamePending: false,
        nickNameRespError: false,
      };
    case ACTION_SAVE_ACCOUNT_NICKNAME_RESP_FAILURE:
      return {
        ...state,
        nickNameRespError: true,
      };
    case ACCOUNT_NICKNAME_MODAL_TOGGLE:
      return {
        ...state,
        isNicknameModalOpen: !state.isNicknameModalOpen,
      };
    case 'ACTION_FETCH_CHECK_IMAGE_REQUEST':
      return {
        ...state,
        checkImages: undefined,
        isCheckImagesError: false,
      };
    case 'ACTION_FETCH_CHECK_IMAGE_SUCCESS':
      return {
        ...state,
        checkImages: {
          front: action.payload.front,
          back: action.payload.back,
        },
      };
    case 'ACTION_FETCH_CHECK_IMAGE_FAILURE':
      return {
        ...state,
        isCheckImagesError: true,
      };
    case ACTION_FETCH_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        transactions: [],
        cursor: null,
        lastPage: true,
        isAccountLoading: true,
      };
    case ACTION_FETCH_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        accountDetails: action.payload,
        isAccountLoading: false,
      };
    }
    case ACTION_FETCH_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isAccountLoading: false,
        isFirstTransactionLoading: false,
        accountsError: action.payload,
      };
    case ACTION_FETCH_ACCOUNT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        lastPage: false,
        hasLoadMoreTransactionsError: false,
        isFirstTransactionLoading: true,
      };
    case ACTION_FETCH_MORE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        lastPage: false,
        hasLoadMoreTransactionsError: false,
        isMoreTransactionLoading: true,
      };
    case ACTION_FETCH_ACCOUNT_TRANSACTIONS_SUCCESS:
      return reduceFetchAccountTransactionsSuccess(action.payload, state);
    case ACTION_FETCH_ACCOUNT_TRANSACTIONS_FAILURE:
      return { ...state, isFirstTransactionLoading: false, transactionsError: action.payload };
    case ACTION_FETCH_MORE_TRANSACTIONS_FAILURE:
      return { ...state, isMoreTransactionLoading: false, hasLoadMoreTransactionsError: true };
    case 'ACTION_DISPLAY_ACCOUNT_ACTIVITY':
      return { ...state, accountDetails: action.payload };
    case ACTION_CLEAR_ACCOUNT_ACTIVITY:
      return getInitialState();
    case ACTION_CLEAR_ACCOUNT_ACTIVITY_ERRORS:
      return { ...otherState };
    case ACTION_FETCH_ACCOUNT_ID_REQUEST:
      return { ...state, isAccountIdLoading: true };
    case ACTION_FETCH_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        decryptedAccountId: action.payload,
        isAccountIdLoading: false,
        isAccountIdError: false,
      };
    case ACTION_FETCH_ACCOUNT_ID_FAILURE:
      return { ...state, isAccountIdLoading: false, isAccountIdError: true };
    case 'ACTION_CLEAR_ACCOUNT_ID': {
      return { ...state, decryptedAccountId: null, isAccountIdLoading: false };
    }
    default:
      return state;
  }
};
