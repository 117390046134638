import {
  ANALYTICS_ACTION_STATEMENTS_SUCCESS,
  ANALYTICS_ACTION_STATEMENTS_FAILURE,
  ANALYTICS_ACTION_STATEMENTS_SUBMIT_FAILURE,
  ANALYTICS_ACTION_STATEMENTS_SUBMIT_SUCCESS,
  ANALYTICS_ACTION_STATEMENTS_OPT_OUT_SUCCESS,
  ANALYTICS_ACTION_STATEMENTS_ALREADY_OPT_OUT_SUCCESS,
  ANALYTICS_ACTION_STATEMENTS_OPT_OUT_FAILURE,
} from '../actions';
import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';

const initView = () => {
  pageAnalytics.newPageView();
  pageAnalytics.addInfo({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.activity,
    pagefunction: pageTrack.pagefunction.statements,
  });
};

const viewFailure = () => {
  initView();
  pageAnalytics.addInfo({ pagename: pageTrack.pagename.view_error });
  pageAnalytics.record();
};

const viewSuccess = () => {
  initView();
  pageAnalytics.addInfo({ pagename: pageTrack.pagename.view });
  pageAnalytics.record();
};

const viewSubmitSuccess = () => {
  initView();
  pageAnalytics.addInfo({ pagename: pageTrack.pagename.complete });
  pageAnalytics.record();
};

const viewSubmitFailure = () => {
  initView();
  pageAnalytics.addInfo({ pagename: pageTrack.pagename.submit_server_error });
  pageAnalytics.record();
};

const viewOptoutSuccess = () => {
  initView();
  pageAnalytics.addInfo({
    pagename: pageTrack.pagename.view_opt_out,
    pagefunction: pageTrack.pagefunction.combined_statements,
  });
  pageAnalytics.record();
};

const viewAlreadyOptoutSuccess = () => {
  initView();
  pageAnalytics.addInfo({
    pagename: pageTrack.pagename.view_already_opt_out,
    pagefunction: pageTrack.pagefunction.combined_statements,
  });
  pageAnalytics.record();
};

const viewOptoutFailure = () => {
  initView();
  pageAnalytics.addInfo({
    pagename: pageTrack.pagename.view_server_error,
    pagefunction: pageTrack.pagefunction.combined_statements,
  });
  pageAnalytics.record();
};

export const statementsPageEvents = {
  [ANALYTICS_ACTION_STATEMENTS_FAILURE]: viewFailure,
  [ANALYTICS_ACTION_STATEMENTS_SUCCESS]: viewSuccess,
  [ANALYTICS_ACTION_STATEMENTS_SUBMIT_SUCCESS]: viewSubmitSuccess,
  [ANALYTICS_ACTION_STATEMENTS_SUBMIT_FAILURE]: viewSubmitFailure,
  [ANALYTICS_ACTION_STATEMENTS_OPT_OUT_SUCCESS]: viewOptoutSuccess,
  [ANALYTICS_ACTION_STATEMENTS_ALREADY_OPT_OUT_SUCCESS]: viewAlreadyOptoutSuccess,
  [ANALYTICS_ACTION_STATEMENTS_OPT_OUT_FAILURE]: viewOptoutFailure,
} as const;

export default statementsPageEvents;
