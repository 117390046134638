/* eslint-disable no-underscore-dangle */
import { HTMLAttributes } from 'react';
import { clickTrackType, DataTrackTypes } from './clickTracking.constants';
// eslint-disable-next-line import/no-cycle
import pageAnalytics from './pageAnalytics';

const globalWindow = window || global;

type ClickType = {
  pagefunction?: string;
  pagename?: string;
  type?: string;
  title?: string;
};

export const recordClickEvent = (dataObject: ClickType) => {
  globalWindow._SFDDL.click.push({
    pagefunction: pageAnalytics.getPageInfo('pagefunction'),
    pagename: pageAnalytics.getPageInfo('pagename'),
    type: clickTrackType.BLANK,
    title: '',
    ...dataObject,
  });

  const eventName = 'customClickEvent';
  let clickTrackingEvent: Event;
  if (typeof Event === 'function') {
    clickTrackingEvent = new Event(eventName);
  } else {
    clickTrackingEvent = document.createEvent('Event');
    clickTrackingEvent.initEvent(eventName, false, false);
  }

  if (!globalWindow._SFDDL.debugging && document.dispatchEvent) {
    document.dispatchEvent(clickTrackingEvent);
  }
};

const getFirstElementWithAttribute = (
  event: MouseEvent,
  attribute: keyof HTMLAttributes<HTMLElement> | DataTrackTypes
) => {
  // gets all bubbled elements in the DOM tree from the clicked element
  // finds the first instance of attribute and returns the element.
  let currentElem = <HTMLElement>event.target;
  while (currentElem) {
    if (currentElem.attributes && currentElem.attributes.getNamedItem(attribute)) {
      return currentElem;
    }
    currentElem = currentElem.parentElement;
  }
  return null;
};

const typeOfNode = (element: Node) => {
  const role = element instanceof HTMLElement ? element.getAttribute('role') : undefined;

  switch (role) {
    case 'link':
      return clickTrackType.LINK;
    case 'button':
      return clickTrackType.BUTTON;
    default:
    // continue to check nodeName
  }

  switch (element.nodeName) {
    case 'A':
      return clickTrackType.LINK;
    case 'BUTTON':
      return clickTrackType.BUTTON;
    default:
      return clickTrackType.BLANK;
  }
};

export const eventMonitor = (event: MouseEvent) => {
  // scan the elements in the clicked stack to see if there are any to process
  const element = getFirstElementWithAttribute(event, 'data-track-title');
  if (!element) {
    return;
  }

  const title = element.attributes.getNamedItem('data-track-title').value;
  const typeAttr = element.attributes.getNamedItem('data-track-type');
  const type = typeAttr ? typeAttr.value : typeOfNode(element);
  const pagenameAttr = element.attributes.getNamedItem('data-track-page');
  const pagename = pagenameAttr ? pagenameAttr.value : pageAnalytics.getPageInfo('pagename');
  const pagefunctionAttr = element.attributes.getNamedItem('data-track-pagefunction');
  const pagefunction = pagefunctionAttr
    ? pagefunctionAttr.value
    : pageAnalytics.getPageInfo('pagefunction');

  recordClickEvent({ pagename, title, type, pagefunction });
};

export const startClickListener = () => {
  if (!globalWindow._SFDDL) {
    globalWindow._SFDDL = {};
  }
  globalWindow._SFDDL.click = [];
  globalWindow.removeEventListener('click', eventMonitor);
  globalWindow.addEventListener('click', eventMonitor, true);
};
