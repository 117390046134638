import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import type { StyleProps } from '../../utilities/types';
import { BodyText } from '../typography/typography';
import styles from './loadingIndicator.styles';

type Props = StyleProps & {
  className?: string;
  isLoading?: boolean;
};

const renderLoadingAnimation = (classes, isLoading) => (
  <figure className={classes.loadingIndicator} data-test="loading-indicator">
    {isLoading && <div className={classnames(classes.loadingBar, classes.loadingBar1)} />}
    <div className={classnames(classes.loadingBar, classes.loadingBar2)} />
    {isLoading && <div className={classnames(classes.loadingBar, classes.loadingBar3)} />}
  </figure>
);

const LoadingIndicatorWrapper = (props: Props) => {
  const { className, classes = {}, isLoading = true, ...rest } = props;
  return (
    <div className={classnames(className, classes.loadingIndicatorContainer)}>
      {renderLoadingAnimation(classes, isLoading)}
      {isLoading && (
        <BodyText
          role="status"
          component="div"
          className={classes.loadingIndicatorText}
          fontWeight="bold"
          textTransform="upperCase"
          {...rest}
        >
          Loading
        </BodyText>
      )}
    </div>
  );
};

const LoadingIndicator = withStyles(styles)(LoadingIndicatorWrapper);

LoadingIndicator.displayName = 'LoadingIndicator';
export default LoadingIndicator;
