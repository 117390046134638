import { WITHHOLDING_AFFIX } from '../components/agreement/termsSection.constants';

const DEFAULT_PREFIX = 'terms-';

// https://redux-form.com/7.4.2/docs/api/field.md/
// Numeric field names, e.g. name="42" or name="foo.5.email", are not supported,
// as they can be confused for array indexes.

const parseValue = (value: string | boolean) => {
  // used to get "values" from agreements/eTin radio options
  // in etin response the values are booleans
  // but the "values" for radio options are strings,
  // and they are also the data-test attribute so here we are
  return typeof value === 'string' ? value === `${WITHHOLDING_AFFIX}-true` : value;
};

function parseFieldName(value: string, prefix: string) {
  return value.slice(prefix.length);
}

export function parseFieldNameValues(
  value:
    | Record<string, boolean>
    | {
        [key: string]: boolean;
        ['terms-withholding']: boolean;
      },
  prefix: string = DEFAULT_PREFIX
) {
  return Object.entries(value).reduce<Record<string, boolean>>(
    (output, [key, val]: [string, string | boolean]) => {
      /* eslint no-param-reassign: "error" */
      output[parseFieldName(key, prefix)] = parseValue(val);
      return output;
    },
    {}
  );
}

export function formatFieldName(value = '', prefix: string = DEFAULT_PREFIX) {
  return `${prefix}${value}`;
}
