import React from 'react';

const RatesAndTerms = Object.freeze({
  AFFINITY_RATES_HEADER: 'Special Member Rates:',
  DEFAULT_RATES_HEADER: <>Here are the rates for the products you&apos;ve chosen:</>,
  APY_DISCLOSURE: (
    <>
      The annual percentage yield (APY) is the effective annual rate of return taking into account
      the effect of compounding interest.
    </>
  ),
  NEW_APY_DISCLOSURE: 'Your actual rates will be set upon renewal date.',
  GRAND_FATHERED_TERM_DISCLOSURE:
    'Your current term is not available to select online. If you take no further action, your CD will renew at the current term on the date of maturity. If you wish to keep the current term for your CD but add or withdraw funds at maturity, you will need to call a Banker at 1-844-345-5790.',
  DETAILED_APY_DISCLOSURE: (
    <>
      APYs are subject to change at any time without notice. Offers apply to personal accounts only.
      Fees may reduce earnings. For Money Market and High Yield Savings Accounts, the rate may
      change after the account is opened. A penalty may be imposed for early withdrawals on a CD.
      After maturity, if you choose to roll over your CD, you will earn the base rate of interest in
      effect at that time. The maximum APY shown for CDs and IRA CDs is for a 60-month CD with a
      balance of at least $25,000. See all CD rates and terms offered
    </>
  ),
});

export default RatesAndTerms;
