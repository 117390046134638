import React, { useState } from 'react';
import { Grid, Divider } from '@material-ui/core';
import {
  rewardsLevels,
  RewardsText,
  RewardTierTitle,
} from '../../containers/rewards/rewards.constants';
import type { ServiceLevel } from '../../containers/rewards/rewards.constants';
import useRewardsTierTileStyles from './rewardsTierTile.styles';
import getRewardTierInfo from '../../containers/rewards/rewards.utilities';
import Select from '../inputs/select';
import { StandardText, BodyTextSmall, StandardTextEmphasis } from '../typography/typography';

export const RewardsTierTile = ({ currentServiceLevel }) => {
  const classes = useRewardsTierTileStyles();
  const [selectedTier, setSelectedTier] = useState(currentServiceLevel);
  const handleChange = (event) => {
    setSelectedTier(event.target.value);
  };

  const { balance, icon, minimumTenure, perks, title } = getRewardTierInfo(
    selectedTier as ServiceLevel
  );

  return (
    <>
      <Grid item xs={12}>
        <Select value={selectedTier} onChange={handleChange} label={RewardsText.VIEW_REWARD_TIER}>
          {rewardsLevels.map((rewardsLevel) => (
            <option key={rewardsLevel.title} value={rewardsLevel.title}>
              {rewardsLevel.title}
            </option>
          ))}
        </Select>
      </Grid>

      <Grid container item className={classes.tile}>
        <Grid item xs={12} className={classes.rewardLevel}>
          {icon}
          <StandardTextEmphasis textTransform="upperCase" className={classes.tierTitle}>
            {title}
          </StandardTextEmphasis>
        </Grid>
        <Grid container item xs={12} component="dl" className={classes.tierRequirements}>
          <Grid item xs={5}>
            <StandardText fontWeight="medium" component="dd">
              {RewardsText.BALANCE}
            </StandardText>
            <BodyTextSmall>{balance}</BodyTextSmall>
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="center"
            className={classes.tinyText}
          >
            {title === RewardTierTitle.BASIC ? 'And' : 'Or'}
          </Grid>
          <Grid item xs={5}>
            <StandardText fontWeight="medium" component="dd">
              {RewardsText.MINIMUM_TENURE}
            </StandardText>
            <BodyTextSmall>{minimumTenure}</BodyTextSmall>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container item xs={12} justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <StandardText fontWeight="medium" data-test="tile-perks-header" component="h4">
              {RewardsText.AVAILABLE_PERKS}
            </StandardText>
          </Grid>
          <Grid container item xs={12} spacing={1} component="ul">
            {perks.map((perk) => (
              <Grid
                item
                xs={12}
                component={(props) => (
                  <BodyTextSmall {...props} className={classes.alignLeft} component="li" />
                )}
                key={perk}
              >
                {perk}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RewardsTierTile;
