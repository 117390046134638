import { Grid } from '@material-ui/core';
import React from 'react';
import Logo from '../logo/logo';
import FooterWide from '../footer/footer';
import useStyles from './deadPage.styles';

const NoRoute = () => {
  const classes = useStyles();
  const NotFoundText = "we're really sorry about this, but something went wrong";

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap" spacing={0}>
      <Grid item className={classes.header}>
        <Logo />
      </Grid>
      <div className={classes.content}>
        <h1>Error 404</h1>
        <p>{NotFoundText}</p>
        <p className={classes.sad}>&#9785;</p>
      </div>
      <Grid item>
        <FooterWide />
      </Grid>
    </Grid>
  );
};

export default NoRoute;
