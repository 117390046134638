import React from 'react';
import { Grid } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StandardText } from '../../components/typography/typography';
import { IRA_FORM_LINK, BeneficiariesPageTexts } from './beneficiaries.constants';
import IconLink from '../../components/links/iconLink';
import { Account } from '../../utilities/types';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';

interface BeneficiariesIraModuleProps {
  beneficiaryAccounts: Account[];
  alignmentClasses: { allPadding: string; horizontalPadding: string };
  classes: ClassNameMap;
}

const BeneficiariesIraModule = ({
  beneficiaryAccounts,
  alignmentClasses,
  classes,
}: BeneficiariesIraModuleProps) => {
  const IraModuleTextStyle = () => {
    if (beneficiaryAccounts.length) {
      return alignmentClasses.allPadding;
    }
    return alignmentClasses.horizontalPadding;
  };

  return (
    <Grid
      container
      spacing={2}
      className={IraModuleTextStyle()}
      data-test="beneficiaries-ira-container"
    >
      <Grid item xs={12}>
        <StandardText>{BeneficiariesPageTexts.IRA_DESCRIPTION}</StandardText>
      </Grid>
      <Grid item>
        <IconLink
          to={IRA_FORM_LINK}
          icon={
            <SVGImage
              imageName={ImagesFileNames.iconDownloadSvg}
              className={classes.downloadSvg}
              ariaHidden="true"
            />
          }
          linkText={BeneficiariesPageTexts.IRA_BENEFICIARY_CHANGE_FORM}
          external
          data-test="beneficiaries-ira-form-link"
        />
      </Grid>
    </Grid>
  );
};

export default BeneficiariesIraModule;
