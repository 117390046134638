import axios from '../../utilities/axios';
import {
  EDIT_USERNAME_URL,
  MFA_CHALLENGE_URL,
  SECURITY_ELIGIBILITY_URL,
  MFA_VALIDATE_URL,
  EDIT_PASSWORD_URL,
  MFA_CANCEL_URL,
} from '../../utilities/route-mappings';
import { getBlackBox } from '../authenticate/blackBox.service';
import type { InitiateOTPFieldName, ValidateOTPFieldName } from '../otp/otp.constants';
import type {
  ApplicantName,
  ApplicantPrimaryAddress,
} from '../newAccountOpening/applications.contracts.types';

export const SecurityEligibilityTypes = Object.freeze({
  CHANGE_USERNAME: 'CHANGE_USERNAME',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  NEW_ACCOUNT_OPENING: 'NEW_ACCOUNT_OPENING',
});

export type SecurityEligibilityType =
  typeof SecurityEligibilityTypes[keyof typeof SecurityEligibilityTypes];

export const SecurityEligibilityFlows = Object.freeze({
  USERNAME_OTP_REQUIRED: 'CHANGE_USERNAME_WITH_OTP',
  USERNAME_NO_OTP: 'CHANGE_USERNAME_NO_OTP',
  PASSWORD_OTP_REQUIRED: 'CHANGE_PASSWORD_WITH_OTP',
  PASSWORD_NO_OTP: 'CHANGE_PASSWORD_NO_OTP',
  FULL_ACCESS_TOKEN_EXCHANGE: 'FULL_ACCESS_TOKEN_EXCHANGE',
  NEW_ACCOUNT_OPENING_WITH_OTP: 'NEW_ACCOUNT_OPENING_WITH_OTP',
  NEW_ACCOUNT_OPENING_NO_OTP: 'NEW_ACCOUNT_OPENING_NO_OTP',
});

export type SecurityEligibilityFlow =
  typeof SecurityEligibilityFlows[keyof typeof SecurityEligibilityFlows];

export type OTPPhone = {
  id: string;
  display: string;
};

export type TrustScoreUIType = {
  action: string | null;
  messageStatus: string | null;
  handlePhoneNumberUpdate: (digits: number) => void;
  payload: {
    header: string;
    headerSubtitle: string;
    errorMessage: {
      phoneNumberrequired: string;
    };
    continue: string;
    donothaveanothernumber: string;
    cancel: string;
    footer: string;
  };
  config: {
    custom: {
      showCAlUi: boolean;
    };
  };
  handleAuthenticate: (isPhoneValue: string, digitsValid: boolean) => void;
  handleCancel: () => void;
  handleAlternativeFlow: () => void;
};

export type SendOtpType = {
  showErrorContainer: boolean;
  showErrorMessage: boolean;
  analyticsConstants: {
    dontSeeYourNumber: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    continue: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    cancel: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    didNotReceiveYourCode: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    verifyCode: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    class: string;
  };
  isSendAsVoiceEnabled: boolean;
  targets: string;
  localizedContent: {
    sendCodeScreen: {
      headerSubtitle: {
        oneTimePasscode: string;
        intro: string;
        singlePhoneNumber: string;
        multiplePhoneNumbers: string;
        onlySendAsSmsTextOptionAvailable: string;
        notYourNumber: string;
      };
      header: string;
      sendCodeTo: {
        dontSeeNumber: string;
      };
      continue: string;
      cancel: string;
      footer: string;
    };
  };
  handleSendOtp: () => void;
  handleCancel: () => void;
  handleDontSeeNumber: () => void;
  handleSendCodeAs: (option: string) => void;
};

export type VerifyOtpType = {
  selectedTarget: string;
  localizedContent: {
    verifyCodeScreen: {
      header: string;
      headerSubtitle: string;
      didNotReceiveCode: string;
      verifyCode: string;
      cancel: string;
    };
    phone_security_response?: null | boolean;
  };
  showErrorContainer: boolean;
  error: string;
  requiredErrorMsg: string;
  isRetryAuthenticatorEnabled: boolean;
  analyticsConstants: {
    dontSeeYourNumber: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    continue: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    cancel: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    didNotReceiveYourCode: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    verifyCode: {
      title: string;
      dataType: string;
      dataObject: string;
      dataReason: string;
    };
    class: string;
  };
  handleCancel: () => void;
  handleDidntReceiveCode: () => void;
  handleOtpChange: (otp: string) => void;
  handleVerifyOtp: () => void;
  otpAttempts: number;
};

const needsOtpMap = {
  [SecurityEligibilityTypes.CHANGE_USERNAME]: SecurityEligibilityFlows.USERNAME_OTP_REQUIRED,
  [SecurityEligibilityTypes.CHANGE_PASSWORD]: SecurityEligibilityFlows.PASSWORD_OTP_REQUIRED,
  [SecurityEligibilityTypes.NEW_ACCOUNT_OPENING]:
    SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP,
} as const;

export const needsOtp = (type: SecurityEligibilityType, flow: SecurityEligibilityFlow) =>
  needsOtpMap[type] === flow;

export type SecurityEligibilityRequest = {
  type: SecurityEligibilityType;
  name?: Partial<ApplicantName>;
  primaryAddress?: Partial<ApplicantPrimaryAddress>;
  email?: string;
};

export type SecurityEligibilityResponse = {
  flow: SecurityEligibilityFlow;
  phone_numbers?: OTPPhone[];
  expires_at?: string;
  state_token?: string;
};

export type OTPChallengeResponse = {
  flow: SecurityEligibilityFlow;
  max_initiations_reached: boolean;
};

export type SecurityEligibilityService = (
  arg1: SecurityEligibilityRequest
) => Promise<SecurityEligibilityResponse>;

export const securityEligibilityService: SecurityEligibilityService = async (
  request: SecurityEligibilityRequest
) => {
  const blackbox = getBlackBox();
  const response = await axios.post(SECURITY_ELIGIBILITY_URL(), {
    ...request,
    client_id: window.__config__.CLIENT_ID,
    blackbox,
  });
  return response.data;
};
export type OTPChallengeRequest = Partial<Record<InitiateOTPFieldName, string>>;

export type OTPChallengeService = (arg1: OTPChallengeRequest) => Promise<OTPChallengeResponse>;

export const challengeOTPService: OTPChallengeService = async (request: OTPChallengeRequest) => {
  let headers;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    headers = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }
  const response = await axios.post(MFA_CHALLENGE_URL(), request, { headers });
  return response.data;
};

export type OTPValidateRequest = Partial<Record<ValidateOTPFieldName, string>>;

export type OTPValidateResponse = {
  flow: SecurityEligibilityFlow;
  email: string;
};

export type OTPValidateService = (arg1: OTPValidateRequest) => Promise<OTPValidateResponse>;

export const validateOTPService: OTPValidateService = async (request: OTPValidateRequest) => {
  const response = await axios.post(MFA_VALIDATE_URL(), request);
  return response.data;
};

export type EditUsernameService = (arg1: string) => Promise<SecurityEligibilityResponse>;
export type EditPasswordService = (arg1: string) => Promise<SecurityEligibilityResponse>;

export const editUsernameService: EditUsernameService = async (username: string) => {
  const response = await axios.put(EDIT_USERNAME_URL(), { username });
  return response.data;
};

export const editPasswordService: EditPasswordService = async (password: string) => {
  const response = await axios.put(EDIT_PASSWORD_URL(), { password });
  return response.data;
};

export const cancelOTPService: () => Promise<Record<string, string>> = () =>
  axios.post(MFA_CANCEL_URL());
