import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import { ApplicationStatuses } from '../../containers/newAccountOpening/newAccountOpening.constants';
import type { ProcessingApplicationAnalyticsAction } from '../actions';
import { ANALYTICS_PROCESSING_APPLICATION } from '../actions';

const viewPage = (action: ProcessingApplicationAnalyticsAction) => {
  const appStatus = action.payload?.status || ApplicationStatuses.PENDING;
  let pageName;

  if (appStatus === ApplicationStatuses.CREATED && action.isNewAccountOpening) {
    pageName = pageTrack.pagename.complete_no_otp;
  } else if (
    appStatus === ApplicationStatuses.PENDING ||
    appStatus === ApplicationStatuses.REVIEW
  ) {
    pageName = pageTrack.pagename.pending;
  } else if (appStatus === ApplicationStatuses.DENIED && action.isNewAccountOpening) {
    pageName = pageTrack.pagename.denied;
  } else {
    pageName = pageTrack.pagename.unknown;
  }

  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: pageTrack.pagefunction.review,
      pagesubfunction: action.isNewAccountOpening
        ? pageTrack.pagesubfunction.NAO
        : pageTrack.pagesubfunction.EAO,
      pagename: pageName,
      attr: pageTrack.attr.blank,
    })
    .record();
};

export const processingApplicationPageEvents = {
  [ANALYTICS_PROCESSING_APPLICATION]: viewPage,
} as const;

export default processingApplicationPageEvents;
