import type { ReduxState } from '../../../reducers';
import { getExternalFundingType, getPageSubfunction } from '../fundNewAccount.analytics';
import type { AccountFundingFlow } from '../../../utilities/accountFundingFlowType';
import type { Action } from '../../../utilities/types';
import pageAnalytics from '../../pageAnalytics';
import pageTrack from '../../pageAnalytics.constants';

const getReviewPageAnalytics = (fundingFlow: AccountFundingFlow, state: ReduxState) => {
  return {
    account_type: 'generic',
    pagekind: 'account_opening',
    pagefunction: 'funding',
    pagesubfunction: getPageSubfunction(fundingFlow),
    attr: getExternalFundingType(state),
  };
};

export const viewSuccess =
  (fundingFlow: AccountFundingFlow) => (action: Action, state: ReduxState) => {
    pageAnalytics
      .newPageView({
        ...getReviewPageAnalytics(fundingFlow, state),
        pagename: pageTrack.pagename.review_amount,
      })
      .record();
  };

export const viewFailure =
  (fundingFlow: AccountFundingFlow) => (action: Action, state: ReduxState) =>
    pageAnalytics
      .newPageView({
        ...getReviewPageAnalytics(fundingFlow, state),
        pagename: pageTrack.pagename.amount_violation,
      })
      .record();

export default viewSuccess;
