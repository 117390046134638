import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { SecurityAnalyticsSubmitAction } from '../actions';
import {
  ANALYTICS_INITIATE_OTP_NO_PHONE,
  ANALYTICS_INITIATE_OTP_SERVER_ERROR,
  ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR,
  ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR,
  ANALYTICS_INITIATE_OTP_START_SUCCESS,
} from '../actions';
import type { ReduxState } from '../../reducers';
import { getPageFunctionTypeForOTP, isNAOOTPFlow } from '../utilities';

const viewInit = (state: ReduxState) => {
  const pageKindType = isNAOOTPFlow(state)
    ? pageTrack.pagekind.account_opening
    : pageTrack.pagekind.account_settings;

  return pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageKindType,
    pagefunction: getPageFunctionTypeForOTP(state),
  });
};

const viewSuccess = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  const pageSubFunction = isNAOOTPFlow(state)
    ? pageTrack.pagesubfunction.NAO
    : pageTrack.pagesubfunction.OTP;

  const pageName = isNAOOTPFlow(state) ? pageTrack.pagename.otp_start : pageTrack.pagename.start;

  viewInit(state).addInfo({ pagename: pageName, pagesubfunction: pageSubFunction }).record();
};

const viewNoPhoneSuccess = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state)
    .addInfo({
      pagename: pageTrack.pagename.start_no_phone,
      pagesubfunction: pageTrack.pagesubfunction.OTP,
    })
    .record();
};

const otpServerError = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state)
    .addInfo({
      pagename: pageTrack.pagename.start_server_error,
      pagesubfunction: isNAOOTPFlow(state)
        ? pageTrack.pagesubfunction.NAO
        : pageTrack.pagesubfunction.OTP,
    })
    .record();
};

const otpUnconfirmedPhoneError = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state)
    .addInfo({
      pagename: pageTrack.pagename.start_unconfirmed_phone,
      pagesubfunction: isNAOOTPFlow(state)
        ? pageTrack.pagesubfunction.NAO
        : pageTrack.pagesubfunction.OTP,
    })
    .record();
};

const otpConfirmationAttemptsExceededError = (
  action: SecurityAnalyticsSubmitAction,
  state: ReduxState
) => {
  viewInit(state)
    .addInfo({
      pagename: pageTrack.pagename.start_max_unconfirmed_attempts,
      pagesubfunction: isNAOOTPFlow(state)
        ? pageTrack.pagesubfunction.NAO
        : pageTrack.pagesubfunction.OTP,
    })
    .record();
};

export const initiateOtpPageEvents = {
  [ANALYTICS_INITIATE_OTP_START_SUCCESS]: viewSuccess,
  [ANALYTICS_INITIATE_OTP_NO_PHONE]: viewNoPhoneSuccess,
  [ANALYTICS_INITIATE_OTP_SERVER_ERROR]: otpServerError,
  [ANALYTICS_INITIATE_OTP_UNCONFIRMED_PHONE_ERROR]: otpUnconfirmedPhoneError,
  [ANALYTICS_INITIATE_OTP_CONFIRMATION_ATTEMPTS_EXCEEDED_ERROR]:
    otpConfirmationAttemptsExceededError,
} as const;

export default initiateOtpPageEvents;
