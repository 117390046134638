import { isEqual } from 'lodash';
import type { AllAccountsAction } from '../../containers/allAccounts/allAccounts.reducer';
import {
  ACTION_FETCH_ALL_ACCOUNTS_FAILURE,
  ACTION_FETCH_ALL_ACCOUNTS_SUCCESS,
} from '../../containers/allAccounts/allAccounts.reducer';
import type { TransferAction } from '../../containers/transfers/transfers.reducer';
import {
  ACTION_GET_TRANSFER_ACTIVITY_FAILURE,
  ACTION_GET_TRANSFER_ACTIVITY_REQUEST,
  ACTION_GET_TRANSFER_ACTIVITY_SUCCESS,
} from '../../containers/transfers/transfers.reducer';
import pageAnalytics from '../pageAnalytics';

let dispatchedActionsCount = 0;

const checkForBothDispatchedActions = (count = dispatchedActionsCount) => {
  // need to wait until both async requests complete before finishing the page
  dispatchedActionsCount = count + 1;
  if (dispatchedActionsCount === 2) pageAnalytics.record();
};

export const initTransferDispatchedActionCount = () => {
  dispatchedActionsCount = 0;
};

const viewInit = () => {
  initTransferDispatchedActionCount();
  pageAnalytics.newPageView({
    account_type: 'generic',
    pagekind: 'transfers',
    pagefunction: 'transfers_dashboard',
  });
};

const viewSuccess = (action: TransferAction) => {
  // Error priority - 4 (lowest)
  if (dispatchedActionsCount > 1) return;
  if (
    pageAnalytics.getPageInfo('pagename') === '' ||
    pageAnalytics.getPageInfo('pagename') === 'landing_page'
  ) {
    if ('payload' in action && isEqual(action.payload, []))
      pageAnalytics.addInfo({ pagename: 'landing_page_no_transfers' });
    else pageAnalytics.addInfo({ pagename: 'landing_page' });
  }
  checkForBothDispatchedActions();
};

const viewFailure = () => {
  // Error priority - 1 (highest)
  pageAnalytics.addInfo({ pagename: 'landing_page_transfers_failed_to_load' });
  checkForBothDispatchedActions(1);
};

const fetchAccountsSuccess = (action: AllAccountsAction) => {
  // Error priority - 3
  if (dispatchedActionsCount > 1) return;
  let inlen = 0; // a nasty flow work around, unsure if an array.length will return a number ¯\_(ツ)_/¯
  let exlen = 0;
  if (
    'payload' in action &&
    'internalAccounts' in action.payload &&
    action.payload.internalAccounts.length
  )
    inlen = action.payload.internalAccounts.length;
  if (
    'payload' in action &&
    'externalAccounts' in action.payload &&
    action.payload.externalAccounts.length
  )
    exlen = action.payload.externalAccounts.length;
  if (Number(inlen) + Number(exlen) > 1 && pageAnalytics.getPageInfo('pagename') === '') {
    pageAnalytics.addInfo({ pagename: 'landing_page' });
  } else {
    pageAnalytics.addInfo({ pagename: 'landing_page_not_enough_accounts' });
  }
  checkForBothDispatchedActions();
};

const fetchAccountsFailure = () => {
  // Error priority - 2
  if (dispatchedActionsCount > 1) return;
  pageAnalytics.addInfo({ pagename: 'landing_page_external_accounts_failed_to_load' });
  checkForBothDispatchedActions();
};

export const transfersPageEvents = {
  [ACTION_GET_TRANSFER_ACTIVITY_REQUEST]: viewInit,
  [ACTION_GET_TRANSFER_ACTIVITY_SUCCESS]: viewSuccess,
  [ACTION_GET_TRANSFER_ACTIVITY_FAILURE]: viewFailure,
  [ACTION_FETCH_ALL_ACCOUNTS_SUCCESS]: fetchAccountsSuccess,
  [ACTION_FETCH_ALL_ACCOUNTS_FAILURE]: fetchAccountsFailure,
} as const;

export default transfersPageEvents;
