import { withStyles } from '@material-ui/core';
import React from 'react';
import type { StyleProps } from '../../utilities/types';

export const styles = {
  root: {
    minWidth: '100%',
    minHeight: '100%',
    width: '100vh',
    height: '100vh',
    overflow: 'hidden',
  },
} as const;

type Props = {
  data: any;
};

const PdfWrapper = ({ data, classes }: Props & StyleProps) => (
  <div className={classes.root}>
    <object width="100%" height="100%" type="application/pdf" data={data}>
      <embed src={data} type="application/pdf" />
    </object>
  </div>
);

export default withStyles(styles)(PdfWrapper);
