let joseImport;

const fetchJose = () => {
  if (typeof joseImport === 'undefined') {
    return import(/* webpackChunkName: "crypto" */ 'node-jose').then((m) => {
      joseImport = m.default;
      return joseImport;
    });
    // eslint-disable-next-line no-else-return
  } else {
    return Promise.resolve(joseImport);
  }
};

export default fetchJose;
