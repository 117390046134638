import type { ProductDisplayType } from 'Domain/Account/AccountDetails';
import type { AllAccount, AllAccountTypeName } from 'Domain/Account/AllAccount';
import type { Account, NAOAccountSummary } from '../../utilities/types';
import type { ExternalAccountType } from '../addExternalAccount/addExternalAccount.actions';
import axios from '../../utilities/axios';
import { ACCOUNTS_TRANSFERABLE_URL } from '../../utilities/route-mappings';
import type { AllAccountsType } from './allAccounts.reducer';
import { isCD } from '../../utilities/accountTypes';

type TransferableAccount = {
  accountDisplayId: string;
  accountId: string | null | undefined;
  accountType: ExternalAccountType;
  availableBalance: string | null | undefined;
  bankName: string;
  external: boolean;
  externalId: string;
  name: string;
  nickname: string | null | undefined;
  product:
    | {
        productDisplayType: ProductDisplayType;
        productType: AllAccountTypeName;
      }
    | null
    | undefined;
  transferFromEnabled: boolean;
  transferToEnabled: boolean;
};

const convertTransferrableAccountsToAllAccounts = (
  transferableAccounts: TransferableAccount[]
): AllAccount[] =>
  transferableAccounts.map((transferableAccount) => {
    const {
      accountDisplayId,
      accountId,
      accountType,
      availableBalance,
      bankName,
      external,
      externalId,
      name,
      nickname,
      product,
      transferFromEnabled,
      transferToEnabled,
    } = transferableAccount;

    return {
      id: accountId || externalId,
      accountIdDisplay: accountDisplayId,
      bankName,
      externalId,
      name,
      external,
      accountType,
      productDisplayType: product ? product.productDisplayType : 'NONE',
      typeName: product ? product.productType : '',
      transfersFromEnabled: transferFromEnabled,
      transfersToEnabled: transferToEnabled,
      availableBalance: availableBalance || '0',
      nickname: nickname || '',
    };
  });

export const convertAccountSummaryToAllAccount = (
  accountSummary: NAOAccountSummary
): AllAccount => {
  const {
    accountId,
    accountIdDisplay,
    accountType,
    availableBalance,
    nickname,
    product,
    transfersFromEnabled,
    transfersToEnabled,
  } = accountSummary;
  return {
    accountType,
    availableBalance,
    external: false,
    id: accountId,
    accountIdDisplay,
    nickname,
    productType: product.productTypeName,
    productDisplayType: product.productDisplayType,
    transfersFromEnabled,
    transfersToEnabled,
    typeName: isCD(product.productDisplayType) ? product.productName : product.productTypeName,
  };
};

export const convertAccountToAllAccount = (account: Account): AllAccount => {
  const {
    accountId,
    accountIdDisplay,
    accountType,
    accountTypeDisplayName,
    availableBalance,
    nickname,
    productType,
    productDisplayType,
    transfersFromEnabled,
    transfersToEnabled,
  } = account;

  return {
    accountType,
    availableBalance,
    external: false,
    id: accountId,
    accountIdDisplay,
    nickname,
    productType,
    productDisplayType,
    transfersFromEnabled,
    transfersToEnabled,
    typeName: accountTypeDisplayName,
  };
};

const filterInternalExternal = (allAccounts: AllAccount[]): AllAccountsType => ({
  internalAccounts: allAccounts.filter((account) => !account.external),
  externalAccounts: allAccounts.filter((account) => account.external),
});

export const convertAndFilter = (transferableAccounts: TransferableAccount[]): AllAccountsType =>
  filterInternalExternal(convertTransferrableAccountsToAllAccounts(transferableAccounts));

const fetchAllAccounts = (): Promise<AllAccountsType> =>
  axios.get(ACCOUNTS_TRANSFERABLE_URL()).then((res) => convertAndFilter(res.data.data));

export const fetchAccountsTransferable = (): Promise<TransferableAccount[]> =>
  axios.get(ACCOUNTS_TRANSFERABLE_URL()).then((res) => res.data.data);

export default fetchAllAccounts;
