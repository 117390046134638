import { useEffect, useState } from 'react';

// Hook to run useEffect which returns the page loaded state.

export default () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  const onPageLoad = () => {
    setPageLoaded(true);
  };

  useEffect(() => {
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);

      return () => window.removeEventListener('load', onPageLoad);
    }

    return null;
  }, []);

  return pageLoaded;
};
