import type { FieldState } from 'final-form';
import type { FinalForms } from './types';
import { normalizeDollarStringToNumber } from '../formatters/currency';
import regexp from './regexConsts';
import { EditNicknameFieldNames } from '../form.constants';
import { PersonalInformationInlineErrors } from '../components/cms/personalInformation.constants';
import AddExternalAccounts from '../containers/addExternalAccount/addExternalAccount.constants';
import type { AllAccount } from '../domain/Account/AllAccount';
import type { ExternalAccount } from '../domain/Account/ExternalAccount';

export type FinalFormValidator<V extends FinalForms> = (
  val: unknown | null | undefined,
  allValues?: V,
  meta?: FieldState<string> | null | undefined
) => unknown | null | undefined;

export function composeValidators<V extends FinalForms>(
  ...validators: FinalFormValidator<V>[]
): FinalFormValidator<V> {
  return (val: unknown | null | undefined, allValues?: V, meta?: FieldState<string> | null) => {
    let validatorResponse;
    for (const validator of validators) {
      validatorResponse = validator(val, allValues, meta);
      if (validatorResponse) {
        return validatorResponse;
      }
    }
    return null;
  };
}

export const requiredField =
  (errorMessage = 'This field is required') =>
  (value?: string | null) =>
    !value ? errorMessage : undefined;

export const validateAmount = (errorMessage: string) => (amount?: string | null) => {
  const normalizedAmount = normalizeDollarStringToNumber(amount);
  const normalizedAmountNumber = Number(normalizedAmount);
  return Number.isNaN(normalizedAmountNumber) || normalizedAmountNumber === 0
    ? errorMessage
    : undefined;
};

export const checkValidPhoneNumber = (value?: string | null) => {
  return !regexp.phoneNumber.test(value)
    ? PersonalInformationInlineErrors.INVALID_FORMAT_PHONE
    : undefined;
};

export const checkEmptyTextField = (fieldName: string) => (value?: string | null) =>
  !value ? `Please enter ${fieldName}.` : undefined;

export const charsAndNumbers = (fieldName: string) => (value?: string | null) =>
  !regexp.charsAndNumbers.test(value)
    ? `Sorry, ${fieldName} can only include letters and numbers.`
    : undefined;

export const charsNumbersAndSpaces = (fieldName: string) => (value?: string | null) =>
  !regexp.charsNumbersAndSpaces.test(value)
    ? `Sorry, ${fieldName} can only include letters, numbers, and spaces.`
    : undefined;

export const checkValidNickname = charsNumbersAndSpaces(EditNicknameFieldNames.NICKNAME);

export const maxActiveExternalAccountsReached = (accounts: AllAccount[]) =>
  accounts?.length >= AddExternalAccounts.NUM_OF_MAX_ACCOUNTS;

export const maxExternalAccountsReached = (accounts: ExternalAccount[]) =>
  accounts?.length >= AddExternalAccounts.NUM_OF_MAX_ACCOUNTS;
