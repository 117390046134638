import { FormApi } from 'final-form';
import { Beneficiary } from 'Containers/beneficiaries/beneficiaries.constants';
import { Account, BeneficiaryForm, CustomerAddress, CustomerInfo } from '../../utilities/types';
import { AddressFieldNames, BeneficiaryFieldNames } from '../../form.constants';
import { normalizeDashes } from '../../utilities/reduxForm';

export interface AddressChangeProps {
  value: boolean;
  primaryOwnerInfo: CustomerInfo;
  form: FormApi<BeneficiaryForm>;
  isAddMode: boolean;
  beneficiary: Beneficiary | null | undefined;
}

export interface AccountChangeProps {
  value: boolean;
  accounts: Account[];
  values: BeneficiaryForm;
  account: Account;
  form: FormApi<BeneficiaryForm>;
}

const updateFormwithPrimaryAddress = (
  form: FormApi<BeneficiaryForm>,
  primaryOwnerAddress: CustomerAddress
) => {
  form.batch(() => {
    form.change(AddressFieldNames.STREET_ADDRESS_1, primaryOwnerAddress.line1);
    form.change(AddressFieldNames.STREET_ADDRESS_2, primaryOwnerAddress.aptNumber);
    form.change(AddressFieldNames.CITY, primaryOwnerAddress.city);
    form.change(AddressFieldNames.STATE, primaryOwnerAddress.state);
    form.change(AddressFieldNames.ZIP_CODE, normalizeDashes(primaryOwnerAddress.zipCode));
  });
};

const updateFormWithBeneficiaryAddress = (
  form: FormApi<BeneficiaryForm>,
  isAddMode: boolean,
  beneficiary: Beneficiary | null | undefined
) => {
  form.batch(() => {
    form.change(
      AddressFieldNames.STREET_ADDRESS_1,
      isAddMode ? '' : beneficiary?.addresses[0]?.line1 || ''
    );
    form.change(
      AddressFieldNames.STREET_ADDRESS_2,
      isAddMode ? '' : beneficiary?.addresses[0]?.line2 || ''
    );
    form.change(AddressFieldNames.CITY, isAddMode ? '' : beneficiary?.addresses[0]?.city || '');
    form.change(AddressFieldNames.STATE, isAddMode ? '' : beneficiary?.addresses[0]?.state || '');
    form.change(
      AddressFieldNames.ZIP_CODE,
      isAddMode ? '' : normalizeDashes(beneficiary?.addresses[0]?.postalCode || '')
    );
  });
};

export const handleAddressChange = ({
  value,
  primaryOwnerInfo,
  form,
  isAddMode,
  beneficiary,
}: AddressChangeProps) => {
  if (value && primaryOwnerInfo) {
    const primaryOwnerAddress = primaryOwnerInfo.addresses[0];
    updateFormwithPrimaryAddress(form, primaryOwnerAddress);
  } else {
    updateFormWithBeneficiaryAddress(form, isAddMode, beneficiary);
  }
};

// If we are selecting an account, check if every other account was already selected and check if *every* account was already selected
const shouldChangeAllAccounts = (
  value: boolean,
  unselectedAccounts: Account[],
  accountId: string
) => {
  if (value) {
    return unselectedAccounts.length === 0;
  }
  return unselectedAccounts.length === 1 && unselectedAccounts[0].accountId === accountId;
};

export const handleAccountChange = ({
  value,
  accounts,
  values,
  account,
  form,
}: AccountChangeProps) => {
  const unselectedAccounts = accounts.filter(({ accountId }) => !values[accountId]);

  // Using undefined to differentiate between this and clicking All Accounts
  if (shouldChangeAllAccounts(value, unselectedAccounts, account.accountId)) {
    form.change(BeneficiaryFieldNames.ALL_ACCOUNTS, value || undefined);
  }
};
