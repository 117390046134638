import { StyleRules, withStyles } from '@material-ui/core';
import * as React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import pxToRem from '../../utilities/pxToRem';
import { InformationalSectionHeaderBold, StandardText } from '../typography/typography';
import { SynchronyInfo } from '../cms/blockText.constants';
import useOWCSContent from '../../containers/owcs/useOWCSContent';
import {
  OWCSPageNames,
  OWCSPageSectionsNames,
  OWCSPageNamesPOBox,
} from '../../containers/owcs/owcs.constants';
import { getContentDetailsFromOWCS, getSectionsFromPage } from '../../containers/owcs/owcs.utils';
import type { StyleProps } from '../../utilities/types';
import { fullAddressOWCS } from '../print/transactionContentUtilities';

type AddressProps = {
  template?: string;
  classProps?: ClassNameMap;
};
export const styles = (): StyleRules => ({
  fundByCheckAddress: {
    marginBottom: pxToRem(70),
  },
});

type ComponentProps = AddressProps & StyleProps;
const SynchronyAddress = React.memo(({ template, classProps, classes }: ComponentProps) => {
  const owcsRes = useOWCSContent(OWCSPageNames.DASHBOARD_POBOX);
  const poOWCS = getContentDetailsFromOWCS(
    getSectionsFromPage(OWCSPageSectionsNames.POBOX_SECTION, owcsRes.sections)
  )[0];

  const hasConfirmation = template === OWCSPageNamesPOBox.PAGE_CONFRIMATION;
  const hasPrint = template === OWCSPageNamesPOBox.PAGE_PRINT;
  if (poOWCS) {
    return (
      <>
        {hasConfirmation && (
          // Uses RDFa metadata
          // https://schema.org/PostalAddress
          <address className={classes.fundByCheckAddress} data-test="funding-mailing-address">
            <InformationalSectionHeaderBold
              component="p"
              translate="no"
              typeof="schema:PostalAddress"
            >
              <span property="schema:name">{SynchronyInfo.BANKNAME}</span>
              <br />
              <span property="schema:streetAddress">{poOWCS.poboxnumber}</span>
              <br />
              <span property="schema:addressLocality">{poOWCS.city}</span>,{' '}
              <abbr property="schema:addressRegion" title={poOWCS.state}>
                {poOWCS.statecode}
              </abbr>{' '}
              <span property="schema:postalCode">{poOWCS.zip}</span>
            </InformationalSectionHeaderBold>
          </address>
        )}

        {hasPrint &&
          (classProps?.accountAddress ? (
            <div className={classProps.accountAddress}>{fullAddressOWCS(poOWCS)}</div>
          ) : (
            <StandardText component="div" data-test="transaction-full-address">
              {fullAddressOWCS(poOWCS)}
            </StandardText>
          ))}
      </>
    );
  }
  return null;
});

export default withStyles(styles)(SynchronyAddress);
