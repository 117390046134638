import pageAnalytics from '../pageAnalytics';
import pageAnalyticsConstants from '../pageAnalytics.constants';
import type { CDRenewalStepsType } from '../../containers/cdRenewal/cdRenewal.types';
import type {
  CDRenewalNextStepAnalyticsAction,
  CDRenewalAnalyticsActionPayload,
  CDRenewalAnalyticsData,
  CDRenewalStartFailureAnalyticsAction,
  CDRenewalStartSuccessAnalyticsAction,
} from '../actions';
import {
  ANALYTICS_CD_RENEWAL_NEXT_STEP,
  ANALYTICS_CD_RENEWAL_START_SUCCESS,
  ANALYTICS_CD_RENEWAL_START_FAILURE,
  ANALYTICS_CD_RENEWAL_CONFIRMATION,
  ANALYTICS_CD_RENEWAL_INFORMATION,
} from '../actions';
import { CDRenewalSteps } from '../../containers/routes/routes.constants';
import { AnalyticsPageInfo } from '../../containers/cdRenewal/cdRenewal.constants';

const initData = () => {
  return {
    account_type: AnalyticsPageInfo.DEFAULT_ACCOUNT_TYPE,
    pagekind: AnalyticsPageInfo.DEFAULT_PAGE_KIND,
    pagefunction: AnalyticsPageInfo.DEFAULT_PAGE_FUNCTION,
  };
};

const overviewSuccess = (action: CDRenewalStartSuccessAnalyticsAction) => {
  pageAnalytics
    .newPageView(initData())
    .addInfo(
      action.payload.isGracePeriod
        ? {
            pagename: AnalyticsPageInfo.START,
            pagekind: AnalyticsPageInfo.ACTIVITY,
            pagefunction: AnalyticsPageInfo.CD_RENEWAL,
          }
        : {
            pagename: AnalyticsPageInfo.START,
          }
    )
    .record();
};

const overviewFailure = (action: CDRenewalStartFailureAnalyticsAction) => {
  pageAnalytics
    .newPageView(initData())
    .addInfo(
      action.payload.isGracePeriod
        ? {
            pagename: AnalyticsPageInfo.START_ERROR,
            pagekind: AnalyticsPageInfo.ACTIVITY,
            pagefunction: AnalyticsPageInfo.CD_RENEWAL,
          }
        : {
            pagename: AnalyticsPageInfo.START_ERROR,
          }
    )
    .record();
};

const viewTermsAdjustment = () => {
  pageAnalytics
    .newPageView(initData())
    .addInfo({ pagename: AnalyticsPageInfo.TERMS_OPTIONS })
    .record();
};

const viewFundsAdjustment = () => {
  pageAnalytics.newPageView(initData()).addInfo({ pagename: 'funds_options' }).record();
};

const viewChooseAccount = (pagesubfunction: string) => {
  pageAnalytics
    .newPageView(initData())
    .addInfo({ pagename: AnalyticsPageInfo.SELECT_ACCOUNT, pagesubfunction })
    .record();
};

const viewYodlee = (payload: CDRenewalAnalyticsActionPayload) => {
  const { pagename } = payload;
  pageAnalytics
    .newPageView(initData())
    .addInfo({
      pagefunction: pageAnalyticsConstants.pagefunction.add_external_bank,
      pagename,
    })
    .record();
};

const viewAccountToAddTo = () => viewChooseAccount(AnalyticsPageInfo.ADD_FUNDS);

const viewAccountToWithdrawFrom = () => viewChooseAccount(AnalyticsPageInfo.WITHDRAW_FUNDS);

const viewAmountEntry = (payload: CDRenewalAnalyticsActionPayload) => {
  const { pagename = '', attr = '' } = payload;
  pageAnalytics.newPageView(initData()).addInfo({ pagename, attr }).record();
};

const viewVerify = () => {
  pageAnalytics.newPageView(initData()).addInfo({ pagename: AnalyticsPageInfo.VERIFY }).record();
};

const viewConfirmation = ({ payload }: { payload: CDRenewalAnalyticsData }) => {
  const { pagesubfunction = '', attr = '' } = payload;
  pageAnalytics
    .newPageView(initData())
    .addInfo({ pagename: AnalyticsPageInfo.CONFIRMATION, pagesubfunction, attr })
    .record();
};

const viewInformation = () => {
  pageAnalytics
    .newPageView(initData())
    .addInfo({ pagename: AnalyticsPageInfo.RENEWAL_INFORMATION })
    .record();
};

const stepViews: Partial<
  Record<CDRenewalStepsType, (arg1: CDRenewalAnalyticsActionPayload) => void>
> = {
  [CDRenewalSteps.TERMS_ADJUSTMENT]: viewTermsAdjustment,
  [CDRenewalSteps.FUNDS_ADJUSTMENT]: viewFundsAdjustment,
  [CDRenewalSteps.ADD_FUNDS]: viewAccountToAddTo,
  [CDRenewalSteps.WITHDRAW_FUNDS]: viewAccountToWithdrawFrom,
  [CDRenewalSteps.ADD_AMOUNT]: viewAmountEntry,
  [CDRenewalSteps.WITHDRAW_AMOUNT]: viewAmountEntry,
  [CDRenewalSteps.YODLEE]: viewYodlee,
  [CDRenewalSteps.VERIFY]: viewVerify,
};

const viewStep = (action: CDRenewalNextStepAnalyticsAction) => {
  const { payload } = action;
  const { step } = payload;
  if (stepViews[step]) {
    stepViews[step](payload);
  }
};

export const cdRenewalFormEvents = {
  [ANALYTICS_CD_RENEWAL_NEXT_STEP]: viewStep,
  [ANALYTICS_CD_RENEWAL_START_FAILURE]: overviewFailure,
  [ANALYTICS_CD_RENEWAL_START_SUCCESS]: overviewSuccess,
  [ANALYTICS_CD_RENEWAL_CONFIRMATION]: viewConfirmation,
  [ANALYTICS_CD_RENEWAL_INFORMATION]: viewInformation,
} as const;

export default cdRenewalFormEvents;
