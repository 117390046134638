/* eslint-disable */
import loadScript from '../../src/utilities/scriptLoader';

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', 'AW-986526954');

loadScript('https://www.googletagmanager.com/gtag/js?id=AW-986526954', { async: true });
