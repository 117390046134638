import { withStyles } from '@material-ui/core';
import { ClassNameMap, StyleRules } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { PrimaryButton } from '../buttons/buttons';
import type { ButtonProps } from '../buttons/buttons';

export type ButtonLinkProps = {
  to: string;
  external?: boolean;
  newTab?: boolean;
  className?: string;
  classes?: ClassNameMap;
  component?: React.ComponentType<ButtonProps & { to?: string }>;
  children: React.ReactNode;
  onClick?: (arg1: React.ChangeEvent<any>) => void;
  ['data-test']?: string;
  ['data-track-title']?: string;
  id?: string;
  title?: string;
};

const styles: StyleRules = {
  buttonLink: {
    // Need element margin to take up full width without resizing button.
    // Would use 'width: fit-content', but doesn't work in IE.
    // https://stackoverflow.com/a/49095568
    width: 'fit-content',
    flexGrow: 0,
    margin: 0,
    '&:hover': {
      // override MUI 'Link' component styles
      textDecoration: 'none',
    },
  },
};

const ButtonLink = withStyles(styles)(
  ({
    to = '',
    external = false,
    newTab = false,
    classes = {},
    component: ButtonComponent = PrimaryButton,
    className,
    children,
    ...rest
  }: ButtonLinkProps) => {
    const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
    const linkClassName = classNames(className, classes.buttonLink);

    return external || newTab ? (
      <ButtonComponent
        component={Link}
        className={linkClassName}
        href={to}
        role="link"
        {...rest}
        {...newTabProps}
      >
        {children}
      </ButtonComponent>
    ) : (
      <ButtonComponent
        component={RouterLink}
        className={linkClassName}
        to={to}
        role="link"
        {...rest}
      >
        {children}
      </ButtonComponent>
    );
  }
);

export default ButtonLink;
