import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export const HEADER_PORTAL_ID = 'headerportal';

type Props = {
  children: React.ReactNode;
};

const getHeaderPortal = () => document.getElementById(HEADER_PORTAL_ID);

// Component which renders children into the bottom of the header
const HeaderPortal = ({ children }: Props) => {
  const [portalRef, setPortalRef] = useState(getHeaderPortal());

  // Set portal ref after initial page render
  useEffect(() => {
    const headerPortal = getHeaderPortal();
    if (portalRef !== headerPortal) {
      setPortalRef(headerPortal);
    }
  }, [portalRef]);

  return portalRef instanceof HTMLElement ? ReactDOM.createPortal(children, portalRef) : null;
};

export default HeaderPortal;
