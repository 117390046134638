import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { MarketingAlertsAnalyticsAction } from '../actions';
import {
  ANALYTICS_MARKETING_ALERTS_START_SUCCESS,
  ANALYTICS_MARKETING_ALERTS_START_FAILURE,
  ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS,
  ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE,
} from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
    pagefunction: pageTrack.pagefunction.marketing_alerts,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start_server_error }).record();
};

const submitSuccess = (action: MarketingAlertsAnalyticsAction) => {
  viewInit().addInfo({ attr: action.payload, pagename: pageTrack.pagename.complete }).record();
};

const submitFailure = (action: MarketingAlertsAnalyticsAction) => {
  viewInit()
    .addInfo({ attr: action.payload, pagename: pageTrack.pagename.submit_server_error })
    .record();
};

export const marketingAlertsPageEvents = {
  [ANALYTICS_MARKETING_ALERTS_START_SUCCESS]: viewSuccess,
  [ANALYTICS_MARKETING_ALERTS_START_FAILURE]: viewFailure,
  [ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS]: submitSuccess,
  [ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE]: submitFailure,
} as const;

export default marketingAlertsPageEvents;
