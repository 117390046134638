// This is much more understandable as a named export
// eslint-disable-next-line import/prefer-default-export
export const CmsFlashMessageContent = Object.freeze({
  CALL_TO_ACTION: 'callToAction',
  CALL_TO_ACTION_URI: 'callToActionUri',
  MESSAGE: 'message',
});

export const CmsTileMessageContent = Object.freeze({
  ...CmsFlashMessageContent,
  TITLE: 'title',
});

export const CmsImageContent = Object.freeze({
  ...CmsFlashMessageContent,
  IMAGE: 'image',
});

export const CustomerRelationshipTypes = Object.freeze({
  CUSTODIAN: 'Custodian',
  SOLE_OWNER: 'Sole Owner',
  JOINT_PRIMARY_OWNER: 'Joint Primary Owner',
  GRANTOR_TRUSTEE: 'Grantor Trustee',
  GUARDIAN: 'Guardian',
});

export const displayMode = Object.freeze({
  desktopView: 'Desktop',
  mobileView: 'Mobile',
});

export const GoPaperlessMessageContent = Object.freeze({
  TEXT: 'Statement and Notice Delivery Preferences',
  MESSAGE: 'Receive statements and notices about your accounts via email.',
});

export const NONOTIFICATION = Object.freeze({
  MESSAGE: 'You’re all caught up! You do not have any notifications right now.',
});

export const AccountTiles = Object.freeze({
  MATURITY_DATE: 'Maturity Date',
});
