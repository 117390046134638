import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { START_TIMER, STOP_TIMER } from 'redux-timer-middleware';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  isTokenAuthenticated,
  setAccessTokens,
  TokenMap,
} from '../../utilities/authentication';
import { setSessionID } from '../../utilities/sessionID';
import type { Dispatch, ServiceFunction } from '../../utilities/types';
import {
  ACTION_SET_TICK_STATUS,
  ACTION_SET_USER_LOGGED_IN,
  ACTION_SET_USER_LOGGED_OUT,
  ACTION_REFRESH_ACCESS_TOKEN,
} from './authenticate.reducer';
import type { TokenResponse } from './authenticate.service';
import { exchangeAuthCodeService } from './authenticate.service';

export const startTimer = () => (dispatch: Dispatch) => {
  dispatch({
    type: START_TIMER,
    payload: {
      actionName: ACTION_SET_TICK_STATUS,
      timerName: 'actionTimer',
      timerInterval: 1000 * 60, // 1 min in ms
    },
  });
};

export const stopTimer = (dispatch: Dispatch) => {
  dispatch({
    type: STOP_TIMER,
    payload: {
      timerName: 'actionTimer',
    },
  });
};

export const fetchLoggedInState = () => (dispatch: Dispatch) => {
  if (isTokenAuthenticated(ACCESS_TOKEN_STORAGE_KEY)) {
    dispatch({ type: ACTION_SET_USER_LOGGED_IN });
  } else {
    dispatch({ type: ACTION_SET_USER_LOGGED_OUT });
  }
};

export const exchangeAuthCode =
  (authCode: string, service: ServiceFunction<string, TokenResponse> = exchangeAuthCodeService) =>
  async (dispatch: ThunkDispatch<null, null, AnyAction>) => {
    const tokenResponse: TokenResponse = await service(authCode);
    const tokenMap: TokenMap = new Map([[ACCESS_TOKEN_STORAGE_KEY, tokenResponse.access_token]]);

    setAccessTokens(tokenMap);
    if (tokenResponse.analytics_id) setSessionID(tokenResponse.analytics_id);

    dispatch(fetchLoggedInState());
  };

export const listenForRefreshCode = () => (dispatch: ThunkDispatch<null, null, AnyAction>) => {
  window.addEventListener('message', async (event: MessageEvent) => {
    if (
      event.origin === global.window.location.origin &&
      event.data &&
      typeof event.data.refreshCode === 'string'
    ) {
      await dispatch(exchangeAuthCode(event.data.refreshCode));
      dispatch({ type: ACTION_REFRESH_ACCESS_TOKEN });
    }
  });
};
