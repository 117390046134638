import decode from 'jwt-decode';
import type { PendingApplication } from './types';
import Routes from '../containers/routes/routes.constants';
import { getSessionID } from './sessionID';
import { encodeBase64, decodeBase64 } from './encodeOrDecode';

export const ACCESS_TOKEN_STORAGE_KEY = 'access_id';
export const BRIDGE_TOKEN_STORAGE_KEY = 'bridge_id';
export const AUTH_STATE_TOKEN_STORAGE_KEY = 'state_token';
export const MODIFY_PROFILE_TOKEN_STORAGE_KEY = 'profile_modify_token';

type TokenKey =
  | typeof ACCESS_TOKEN_STORAGE_KEY
  | typeof BRIDGE_TOKEN_STORAGE_KEY
  | typeof AUTH_STATE_TOKEN_STORAGE_KEY
  | typeof MODIFY_PROFILE_TOKEN_STORAGE_KEY;

type Token = {
  exp: number;
  given_name: string;
  username: string;
};

type AccessToken = {
  scope: [];
  sub: string;
};

export type TokenMap = Map<TokenKey, string>;

export type AuthenticationPersistedState = {
  iframeRequest?: boolean;
  pendingApplication?: PendingApplication;
  url?: string;
  // Url provided in case auth responds with an error of "otp_cancel" or "otp_failed". In that case, we will
  // immediately redirect to this url if provided.
  otpErrorUrl?: string;
};

const getTokenExpirationDate = (encodedToken: string): Date | null | undefined => {
  const token: Token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
};

export const getTokenGivenName = (encodedToken: string): string => {
  const token: Token = decode(encodedToken);

  return token.given_name;
};

export const getTokenGivenUsername = (encodedToken: string): string => {
  const token: Token = decode(encodedToken);

  return token.username;
};

const isTimerExpired = (token: string): boolean => {
  const expirationDate = getTokenExpirationDate(token);

  if (expirationDate == null) {
    return true;
  }

  return expirationDate < new Date();
};

const queryize = (params: Record<string, string>) => {
  let query = '';

  Object.keys(params).forEach((param) => {
    if (params[param]) {
      query += `${param}=${encodeURIComponent(params[param])}&`;
    }
  });

  return query.slice(0, -1);
};

export const setAccessTokens = (tokenMap: TokenMap): void =>
  tokenMap.forEach((value, key) => {
    window.__tokens__[key] = value;
  });

const updateToken = (key: TokenKey, token?: string) => {
  if (token) {
    const tokenMap = new Map([[key, token]]);
    setAccessTokens(tokenMap);
  }
};

export const setUpdatedAccessToken = (accessToken?: string) =>
  updateToken(ACCESS_TOKEN_STORAGE_KEY, accessToken);

export const setUpdatedAuthStateToken = (stateToken?: string) =>
  updateToken(AUTH_STATE_TOKEN_STORAGE_KEY, stateToken);

export const setUpdatedModifyProfileToken = (profileModifyToken?: string) =>
  updateToken(MODIFY_PROFILE_TOKEN_STORAGE_KEY, profileModifyToken);

export const getAccessToken = (key: string) => window.__tokens__[key];

export const clearAuthStateToken = () => delete window.__tokens__[AUTH_STATE_TOKEN_STORAGE_KEY];

export const clearModifyProfileToken = () =>
  delete window.__tokens__[MODIFY_PROFILE_TOKEN_STORAGE_KEY];

export const clearAccessTokens = () => {
  window.__tokens__ = {};
};

export const isTokenValid = (key: string): boolean => {
  const accessToken = getAccessToken(key);
  return accessToken != null && !isTimerExpired(accessToken);
};

export const hasReadWriteScope = (key: string): boolean => {
  try {
    const accessToken: AccessToken = decode(getAccessToken(key));
    const scopes = accessToken && accessToken.scope ? accessToken.scope : [];
    return scopes.some((scope) => scope === 'read') && scopes.some((scope) => scope === 'write');
  } catch (e) {
    return false;
  }
};

export const isTokenAuthenticated = (key: string): boolean =>
  isTokenValid(key) && hasReadWriteScope(key);

export const getCustomerCIF = (): string | null | undefined => {
  if (!isTokenAuthenticated(ACCESS_TOKEN_STORAGE_KEY)) return undefined;
  const accessToken: AccessToken = decode(getAccessToken(ACCESS_TOKEN_STORAGE_KEY));
  return accessToken.sub;
};

export const buildCallbackUrl = (): string => `${global.window.location.origin}/callback`;

export const buildLogoutUrl = (reason?: string | null): string => {
  const logoutReason = reason ? `?reason=${reason}` : '';
  return `${window.__config__.AUTH_BASE_URL}/account/logout${logoutReason}`;
};

const buildParams = (redirectPath?: string) => ({
  client_id: window.__config__.CLIENT_ID,
  grant_type: 'authorization_code',
  prompt: null,
  response_type: 'code',
  scope: 'read write profile:register',
  redirect_uri: buildCallbackUrl(),
  analytics_id: getSessionID(),
  state: redirectPath
    ? encodeBase64(JSON.stringify({ url: redirectPath } as AuthenticationPersistedState))
    : undefined,
});

export const buildLoginUrl = (redirectPath?: string) =>
  `${window.__config__.AUTH_BASE_URL}/oauth/authorize?${queryize(buildParams(redirectPath))}`;

export const buildRefreshTokenUrl = (oauthState: AuthenticationPersistedState): string => {
  const authorizationUrl = `${window.__config__.AUTH_BASE_URL}/oauth/authorize`;
  const params = {
    client_id: window.__config__.CLIENT_ID,
    grant_type: null,
    prompt: 'none',
    response_type: 'code',
    scope: 'read write profile:register',
    redirect_uri: buildCallbackUrl(),
    state: encodeBase64(JSON.stringify(oauthState)),
  } as const;

  return `${authorizationUrl}?${queryize(params)}`;
};

export const buildRefreshSessionUrl = () =>
  `${window.__config__.AUTH_BASE_URL}/account/refresh-session`;

export const buildOauthTokenUrl = (): string => `${window.__config__.AUTH_BASE_URL}/oauth/token`;

export const buildRegisterNewUserUrl = (redirectPath?: string) => {
  const registrationUrl = `${window.__config__.AUTH_BASE_URL}/account/register`;
  const params = redirectPath
    ? {
        state: encodeBase64(JSON.stringify({ url: redirectPath } as AuthenticationPersistedState)),
      }
    : {};
  return `${registrationUrl}?${queryize(params)}`;
};

const naoRegistrationUrl = () => `${window.__config__.AUTH_BASE_URL}/account/user-registration`;
const createProfileUrl = () => `${window.__config__.AUTH_BASE_URL}/account/create-profile`;
const createProfileStateObj = {
  url: Routes.NAO_NEW_ACCOUNT_FUNDING,
} as const;

type AuthParams = {
  client_id: string;
  redirect_uri: string;
  analytics_id?: string;
  applicant_id: string;
  application_id: string;
  state: string;
};

const buildRegistrationRedirectParams = (
  applicantId: string,
  applicationId: string,
  oauthState: AuthenticationPersistedState
): AuthParams => ({
  client_id: window.__config__.CLIENT_ID,
  redirect_uri: buildCallbackUrl(),
  analytics_id: getSessionID(),
  applicant_id: applicantId,
  application_id: applicationId,
  state: encodeBase64(JSON.stringify(oauthState)),
});

export const buildCreateProfileRedirectUrl = (applicantId: string, applicationId: string): string =>
  `${createProfileUrl()}?${queryize(
    buildRegistrationRedirectParams(applicantId, applicationId, createProfileStateObj)
  )}`;

/**
 * Constructs the auth URL to send the user to registration. This URL contains a base64 encoded
 * state object, which contains another URL that our app will redirect to when the user comes
 * back from auth. This second URL has its own state object to redirect them to funding. This
 * extra step can be removed once auth implements the flow that takes the user straight from
 * OTP to create profile without coming back to Ignite first.
 * @param {string} applicantId
 * @param {string} applicationId
 */
export const buildRegistrationRedirectUrl = (
  applicantId: string,
  applicationId: string
): string => {
  const createProfileParams = {
    state: encodeBase64(JSON.stringify(createProfileStateObj)),
  } as const;
  const registrationStateObj = {
    url: `${createProfileUrl()}?${queryize(createProfileParams)}`,
    otpErrorUrl: Routes.REGISTRATION_FAILED,
  } as const;

  return `${naoRegistrationUrl()}?${queryize(
    buildRegistrationRedirectParams(applicantId, applicationId, registrationStateObj)
  )}`;
};

export const parseStateParam = (state: string) => {
  try {
    return JSON.parse(decodeBase64(state)) as AuthenticationPersistedState;
  } catch (err) {
    console.info('Invalid state parameter.'); // eslint-disable-line
    return {};
  }
};
