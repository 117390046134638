import type { AccountDetailsType } from 'Domain/Account/AccountDetails';
import type { AllAccountTypeName } from 'Domain/Account/AllAccount';
import type { ProductTypesName } from '../products/products.reducer';

const AccountAlertsTitle = 'Manage Account Alerts';

const AccountAlertsDescription =
  'Sign up to receive email alerts for changes in your balance, withdrawal limits, or other account actions.';

const AlertsPageTexts = Object.freeze({
  TITLE: AccountAlertsTitle,
  DESCRIPTION: AccountAlertsDescription,
});

export type Alert = {
  alertType: string;
  amount: string | null | undefined;
  code: string;
  description: string;
  requiresAmount: boolean;
};

export type AlertsEligibleAccount = {
  accountId: string;
  accountType: AccountDetailsType;
  accountIdDisplay: string;
  alerts: Alert[];
  product: {
    productType: ProductTypesName;
    productDisplayType: AllAccountTypeName;
    productId: string;
    productName: string;
  };
  nickname: string;
  editId: string;
};

export type AccountAlertsPreferencesInfo = {
  accountsAlertsPreferences: {
    eligibleAccounts: AlertsEligibleAccount[];
  };
};

export type UpdateAlerts = Alert & {
  action: string;
  status: string;
};

export type UpdateAlertsEligibleAccount = AlertsEligibleAccount & {
  alerts: UpdateAlerts[];
};

export type UpdateAccountAlertsPreferencesResponse = {
  accountsAlertsPreferences: {
    eligibleAccounts: UpdateAlertsEligibleAccount[];
  };
};

export default AlertsPageTexts;
