const globalWindow = window || global;

/* eslint-disable */
(function (a, b, c, d) {
  a = globalWindow.__config__.CDP_URL;
  b = document;
  c = 'script';
  d = b.createElement(c);
  d.src = a;
  d.async = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode.insertBefore(d, a);
})();
