import React from 'react';
import { FormControl, FormControlLabel, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import pxToRem from '../../utilities/pxToRem';
import { Checkbox, useCheckboxStyles } from '../finalForm/finalFormInputs.styles';
import SVGImage from '../svgImage';
import { CheckboxCheckedIconSVG } from '../svg/svg';

const styles = () => ({
  checkboxLabel: {
    fontSize: pxToRem(16),
  },
});

type Props = {
  label: string;
  classes: ClassNameMap;
  id: string;
  checked: boolean;
  labelId: string;
  'data-test-label': string;
  input: any;
  meta: any;
};

export default withStyles(styles)(
  ({
    input,
    classes,
    id,
    label,
    labelId,
    checked,
    'data-test-label': dataTest,
    meta,
    ...rest
  }: Props) => {
    const { onChange, ...restInput } = input;
    const checkBoxClasses = useCheckboxStyles();
    return (
      <FormControl component="fieldset">
        <FormControlLabel
          id={labelId}
          control={
            <Checkbox
              disableRipple
              {...rest}
              checkedIcon={
                <SVGImage
                  iconComponent={
                    <CheckboxCheckedIconSVG
                      className={checkBoxClasses.checkedIconSVG}
                      aria-hidden="true"
                      role="img"
                    />
                  }
                />
              }
              id={id}
              onChange={onChange}
              {...restInput}
              checked={checked}
              color="primary"
              inputProps={{ 'aria-labelledby': labelId }}
            />
          }
          label={label}
          classes={{ label: classes.checkboxLabel }}
          data-test={dataTest}
        />
      </FormControl>
    );
  }
);
