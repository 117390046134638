import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import {
  ANALYTICS_BENEFICIARIES_VIEW_SUCCESS,
  ANALYTICS_BENEFICIARIES_VIEW_FAILURE,
  ANALYTICS_BENEFICIARIES_START_SUCCESS,
  ANALYTICS_BENEFICIARIES_START_FAILURE,
  ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS,
  ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE,
  ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS,
  ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE,
} from '../actions';
import type { BeneficiariesAnalyticsAction } from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_settings,
    pagefunction: pageTrack.pagefunction.beneficiaries,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

const startSuccess = (action: BeneficiariesAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.start, pagesubfunction: action.payload })
    .record();
};

const startFailure = (action: BeneficiariesAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.start_server_error, pagesubfunction: action.payload })
    .record();
};

const submitSuccess = (action: BeneficiariesAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.complete, pagesubfunction: action.payload })
    .record();
};

const submitFailure = (action: BeneficiariesAnalyticsAction) => {
  viewInit()
    .addInfo({ pagename: pageTrack.pagename.submit_server_error, pagesubfunction: action.payload })
    .record();
};

const removeSuccess = () => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.complete,
      pagesubfunction: pageTrack.pagesubfunction.REMOVE,
    })
    .record();
};

const removeFailure = () => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.submit_server_error,
      pagesubfunction: pageTrack.pagesubfunction.REMOVE,
    })
    .record();
};

export const beneficiariesPageEvents = {
  [ANALYTICS_BENEFICIARIES_VIEW_SUCCESS]: viewSuccess,
  [ANALYTICS_BENEFICIARIES_VIEW_FAILURE]: viewFailure,
  [ANALYTICS_BENEFICIARIES_START_SUCCESS]: startSuccess,
  [ANALYTICS_BENEFICIARIES_START_FAILURE]: startFailure,
  [ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_SUCCESS]: submitSuccess,
  [ANALYTICS_BENEFICIARIES_MODAL_SUBMIT_FAILURE]: submitFailure,
  [ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS]: removeSuccess,
  [ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE]: removeFailure,
} as const;

export default beneficiariesPageEvents;
