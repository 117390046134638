import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { History } from 'history';
import { IVAChatStatusProvider } from 'cb-iva-react-web-ui';
import { startClickListener } from './analytics/clickTracking';
import createMuiTheme from './styles/createTheme';
import { useEffectOnce } from './utilities/reactHooks';
import type { Store } from './utilities/types';
import ErrorBoundary from './components/error/errorBoundary';
import { SynchronyInfo } from './components/cms/blockText.constants';
import startUnloadListener from './utilities/unloadListener';
import LoadingOverlay from './components/loadingOverlay/loadingOverlay';
import Routes from './containers/routes/routes';
import initNewRelic from './vendor/newrelic/config';
import RefreshToken from './containers/refreshToken/refreshToken';
import ActivityTimer from './containers/activityTimer/activityTimer';
import RefreshSession from './containers/refreshToken/refreshSession';
import OWCSWrapper from './containers/owcs/owcs.container';
import SydneyIVAChat from './containers/sydneyIVA/sydneyIVAChat';
import PixelWhitelisting from './containers/pixelWhitelisting/pixelWhitelisting';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BioCatch from './components/bioCatch/bioCatch';
import ControlPanel from './components/controlPanel/ControlPanel';
import fetchFeatureFlags from './utilities/fetchFeatureFlags';

type AppProps = {
  history: History;
  store: Store;
};

export default ({ history, store }: AppProps) => {
  const theme = createMuiTheme();
  const fetchSmartBanner = () => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
    });
  };

  useEffectOnce(() => {
    startClickListener();
    startUnloadListener(store);
    initNewRelic(history);
    fetchSmartBanner();
    fetchFeatureFlags();
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Suspense fallback={<LoadingOverlay isLoading />}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <IVAChatStatusProvider>
              <ErrorBoundary>
                <Helmet>
                  <title data-test="bank-name">{SynchronyInfo.BANKNAME}</title>
                  <meta name="apple-itunes-app" content="app-id=1357695194" />
                  <link rel="manifest" href="/manifest.json" />
                </Helmet>
                {window.__config__.ENVIRONMENT !== 'production' && <ControlPanel />}
                {window.__config__.IVA_CHAT_ENABLED === 'true' && <SydneyIVAChat />}
                <OWCSWrapper>
                  <Routes />
                </OWCSWrapper>
                <RefreshToken />
                <RefreshSession />
                <ActivityTimer />
                <PixelWhitelisting />
                {window.__config__.BIOCATCH_ENABLED === 'true' && <BioCatch />}
              </ErrorBoundary>
            </IVAChatStatusProvider>
          </ConnectedRouter>
        </Provider>
      </Suspense>
    </MuiThemeProvider>
  );
};
