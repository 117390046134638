import { http, HttpResponse } from 'msw';
import mockAccounts from '../../utilities/tests/mock-accounts';
import { ALL_ACCOUNTS_DASHBOARD_URL } from '../../utilities/route-mappings';

const getDepositAccounts = http.get(ALL_ACCOUNTS_DASHBOARD_URL(), () => {
  return HttpResponse.json({
    data: {
      accounts: mockAccounts,
      customerTotalBalance: '54180.08',
      asOfDate: null,
      nextPageAvailable: false,
    },
  });
});

export default [getDepositAccounts];
