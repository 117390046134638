import type {
  OverdraftAccount,
  OverdraftDetails,
  ProtectorAccountList,
  ProtectorAccount,
} from './overdraftProtection.service';

export const ACTION_OVERDRAFT_SET_LOADING = 'ACTION_OVERDRAFT_SET_LOADING';
export const ACTION_FETCH_OVERDRAFT_DETAILS_REQUEST = 'ACTION_FETCH_OVERDRAFT_DETAILS_REQUEST';
export const ACTION_FETCH_OVERDRAFT_DETAILS_SUCCESS = 'ACTION_FETCH_OVERDRAFT_DETAILS_SUCCESS';
export const ACTION_FETCH_OVERDRAFT_DETAILS_FAILURE = 'ACTION_FETCH_OVERDRAFT_DETAILS_FAILURE';
export const ACTION_FETCH_PROTECTOR_ACCOUNTS_REQUEST = 'ACTION_FETCH_PROTECTOR_ACCOUNTS_REQUEST';
export const ACTION_FETCH_PROTECTOR_ACCOUNTS_SUCCESS = 'ACTION_FETCH_PROTECTOR_ACCOUNTS_SUCCESS';
export const ACTION_FETCH_PROTECTOR_ACCOUNTS_FAILURE = 'ACTION_FETCH_PROTECTOR_ACCOUNTS_FAILURE';
export const ACTION_LINK_OVERDRAFT_PROTECTOR_ACCOUNT_SUCCESS =
  'ACTION_LINK_OVERDRAFT_PROTECTOR_ACCOUNT_SUCCESS';
export const ACTION_OVERDRAFT_ACTION_REQUEST = 'ACTION_OVERDRAFT_ACTION_REQUEST';
export const ACTION_OVERDRAFT_ACTION_FAILURE = 'ACTION_OVERDRAFT_ACTION_FAILURE';

export type OverdraftState = {
  isOverdraftActionLoading: boolean;
  isLoading: boolean;
  isOverdraftError: boolean;
  message?: string;
  protectedAccount: OverdraftAccount | null | undefined;
  protectorAccount: OverdraftAccount | null | undefined;
  protectorAccountList: ProtectorAccount[] | null | undefined;
};

export function getInitialState(): OverdraftState {
  return {
    isOverdraftActionLoading: false,
    isLoading: false,
    isOverdraftError: false,
    protectedAccount: null,
    protectorAccount: null,
    protectorAccountList: null,
  };
}

export type OverdraftAction =
  | {
      type: typeof ACTION_OVERDRAFT_SET_LOADING;
      payload: boolean;
    }
  | {
      type: typeof ACTION_FETCH_PROTECTOR_ACCOUNTS_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_PROTECTOR_ACCOUNTS_SUCCESS;
      payload: ProtectorAccountList;
    }
  | {
      type: typeof ACTION_FETCH_PROTECTOR_ACCOUNTS_FAILURE;
    }
  | {
      type: typeof ACTION_FETCH_OVERDRAFT_DETAILS_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_OVERDRAFT_DETAILS_SUCCESS;
      payload: OverdraftDetails;
    }
  | {
      type: typeof ACTION_FETCH_OVERDRAFT_DETAILS_FAILURE;
    }
  | {
      type: typeof ACTION_OVERDRAFT_ACTION_REQUEST;
    }
  | {
      type: typeof ACTION_LINK_OVERDRAFT_PROTECTOR_ACCOUNT_SUCCESS;
      payload: OverdraftDetails;
    }
  | {
      type: typeof ACTION_OVERDRAFT_ACTION_FAILURE;
    };

export default (
  state: OverdraftState | null | undefined = getInitialState(),
  action: OverdraftAction = undefined
): OverdraftState => {
  switch (action.type) {
    case ACTION_OVERDRAFT_SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTION_FETCH_OVERDRAFT_DETAILS_REQUEST:
      return {
        ...state,
        protectedAccount: null,
        protectorAccount: null,
      };
    case ACTION_FETCH_OVERDRAFT_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isOverdraftError: false,
        isOverdraftActionLoading: false,
      };
    case ACTION_FETCH_PROTECTOR_ACCOUNTS_REQUEST:
      return {
        ...state,
        protectorAccountList: null,
      };
    case ACTION_FETCH_PROTECTOR_ACCOUNTS_SUCCESS:
      return {
        ...state,
        protectorAccountList: action.payload.protectorAccountList,
        isOverdraftError: false,
      };
    case ACTION_FETCH_OVERDRAFT_DETAILS_FAILURE:
    case ACTION_FETCH_PROTECTOR_ACCOUNTS_FAILURE:
      return {
        ...state,
        isOverdraftError: true,
      };
    case ACTION_OVERDRAFT_ACTION_REQUEST:
      return {
        ...state,
        isOverdraftActionLoading: true,
      };
    case ACTION_LINK_OVERDRAFT_PROTECTOR_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isOverdraftActionLoading: false,
      };
    case ACTION_OVERDRAFT_ACTION_FAILURE:
      return {
        ...state,
        isOverdraftActionLoading: false,
      };
    default:
      return state;
  }
};
