import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { Theme } from '../../utilities/types';
import { PrimaryButton, SecondaryButton, GhostButton } from '../buttons/buttons';
import colors from '../colors/colors';

const renderVariant = (variant) => {
  switch (variant) {
    case 'primary':
      return PrimaryButton;
    case 'secondary':
      return SecondaryButton;
    case 'flat':
      return GhostButton;
    default:
      return Button;
  }
};

const styles = (theme: Theme) => ({
  child: {
    backgroundColor: colors.optimumBlue,
  },
  rippleVisible: {
    opacity: 0.2,
    transform: 'scale(1.25)',
    animation: `$enter 550ms ${theme.transitions.easing.easeInOut}`,
  },
  childPulsate: {
    animation: 'none',
  },
  '@keyframes enter': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.1,
    },
    '100%': {
      transform: 'scale(1.25)',
      opacity: 0.2,
    },
  },
});

const GridButton = ({ classes, children, variant, gridItemClass, ...props }: any) => {
  const ButtonVariant = renderVariant(variant);
  const { button: buttonClass, ...rippleClasses } = classes;
  return (
    <Grid className={gridItemClass} item>
      <ButtonVariant
        TouchRippleProps={{ classes: rippleClasses }}
        className={buttonClass}
        {...props}
      >
        {children}
      </ButtonVariant>
    </Grid>
  );
};

export default withStyles(styles)(GridButton);
