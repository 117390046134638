import type { Dispatch } from '../../utilities/types';
import type { FetchOWCSPageServiceResponse, FetchOWCSPageService } from './owcs.type';
import { ACTION_ADD_OWCS_PAGE_LIST, ACTION_SET_OWCS_LOADING_STATE } from './owcs.reducer';
import { fetchOWCSPageListService } from './owcs.service';

export const getOWCSPageList =
  (service: FetchOWCSPageService = fetchOWCSPageListService) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ACTION_SET_OWCS_LOADING_STATE, payload: true });
      const data: FetchOWCSPageServiceResponse = await service();
      dispatch({ type: ACTION_ADD_OWCS_PAGE_LIST, payload: data.Pages });
    } catch (err) {
      dispatch({ type: ACTION_SET_OWCS_LOADING_STATE, payload: false });
    }
  };

export default getOWCSPageList;
