import { makeStyles } from '@material-ui/core/styles';
import Colors from '../colors/colors';
import pxToRem from '../../utilities/pxToRem';

export default makeStyles(() => ({
  addAttachmentButton: {
    marginLeft: -8,
    justifyContent: 'left',
  },
  disabledButton: {
    '&:focus': {
      color: Colors.disabledState,
    },
  },
  addAttachmentList: {
    marginBottom: pxToRem(20),
  },
}));
