const host = window.__config__.CONTROL_CENTER_URL;
const disclosuresFile = '/disclosures/disclosures.json';

interface DisclosureResponse {
  apyCds: string;
  apyHysMoneyMarket: string;
  apyNonIraCds: string;
}

// eslint-disable-next-line consistent-return, import/prefer-default-export
export const fetchDisclosures = async (): Promise<DisclosureResponse> => {
  try {
    const data = await fetch(`${host}${disclosuresFile}`);
    return await data.json();
  } catch (err) {
    console.error('Error: ', err);
  }
};
