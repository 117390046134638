import { StyleRules, makeStyles } from '@material-ui/core';
import pxToRem from '../../../utilities/pxToRem';

export const loadingIndicatorStyles: StyleRules = {
  root: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: pxToRem(8),
    marginTop: pxToRem(-10),
  },
};

export default makeStyles({
  raisedDefault: {
    marginRight: pxToRem(18),
    paddingLeft: pxToRem(32),
    paddingRight: pxToRem(32),
  },
  raisedPrimary: {
    paddingLeft: pxToRem(32),
    paddingRight: pxToRem(32),
  },
});
