import { LOCATION_CHANGE } from 'connected-react-router';
import type { FlashMessageVariantType } from './flashMessage.constants';

export const ACTION_SET_FLASH_MESSAGE = 'ACTION_SET_FLASH_MESSAGE';
export const ACTION_CLEAR_FLASH_MESSAGE = 'ACTION_CLEAR_FLASH_MESSAGE';
export const ACTION_SET_PERSISTENT_FLASH_MESSAGE = 'ACTION_SET_PERSISTENT_FLASH_MESSAGE';
export const ACTION_SET_MODAL_FLASH_MESSAGE = 'ACTION_SET_MODAL_FLASH_MESSAGE';
export const ACTION_CLEAR_MODAL_FLASH_MESSAGE = 'ACTION_CLEAR_MODAL_FLASH_MESSAGE';

export type FlashMessageProperties = {
  messageType: FlashMessageVariantType | null | undefined;
  messageText: string | null | undefined;
  ['aria-live']?: React.AriaAttributes['aria-live'];
  linkText?: string;
  onLinkClick?: () => void;
};

export type FlashMessageState = {
  pageFlashMessage: FlashMessageProperties;
  modalFlashMessage: FlashMessageProperties;
  persists: boolean;
};

export const setFlashMessage = (message: FlashMessageProperties) => {
  return {
    type: ACTION_SET_FLASH_MESSAGE,
    payload: message,
  };
};

export const setPersistentFlashMessage = (message: FlashMessageProperties) => {
  return {
    type: ACTION_SET_PERSISTENT_FLASH_MESSAGE,
    payload: message,
  };
};

export const setModalFlashMessage = (message: FlashMessageProperties) => {
  return {
    type: ACTION_SET_MODAL_FLASH_MESSAGE,
    payload: message,
  };
};

export const clearFlashMessage = () => ({
  type: ACTION_CLEAR_FLASH_MESSAGE,
});

export const clearModalFlashMessage = () => ({
  type: ACTION_CLEAR_MODAL_FLASH_MESSAGE,
});

export const emptyFlashMessage = { messageType: null, messageText: null } as const;

export const getInitialState = (): FlashMessageState => ({
  pageFlashMessage: { ...emptyFlashMessage },
  modalFlashMessage: { ...emptyFlashMessage },
  persists: false,
});

export type FlashMessageAction =
  | {
      type: typeof ACTION_SET_FLASH_MESSAGE;
      payload: FlashMessageProperties;
    }
  | {
      type: typeof ACTION_SET_PERSISTENT_FLASH_MESSAGE;
      payload: FlashMessageProperties;
    }
  | {
      type: typeof ACTION_SET_MODAL_FLASH_MESSAGE;
      payload: FlashMessageProperties;
    }
  | {
      type: typeof ACTION_CLEAR_FLASH_MESSAGE;
    }
  | {
      type: typeof ACTION_CLEAR_MODAL_FLASH_MESSAGE;
    }
  | {
      type: typeof LOCATION_CHANGE;
    };

export default (
  state: FlashMessageState | null | undefined = getInitialState(),
  action: FlashMessageAction | never = undefined
): FlashMessageState => {
  switch (action.type) {
    case ACTION_SET_FLASH_MESSAGE:
      return {
        ...state,
        pageFlashMessage: action.payload,
        persists: false,
      };
    case ACTION_SET_MODAL_FLASH_MESSAGE:
      return {
        ...state,
        modalFlashMessage: action.payload,
        persists: false,
      };
    case ACTION_SET_PERSISTENT_FLASH_MESSAGE:
      return {
        ...state,
        pageFlashMessage: action.payload,
        persists: true,
      };
    case ACTION_CLEAR_FLASH_MESSAGE:
      return {
        ...state,
        pageFlashMessage: { ...emptyFlashMessage },
        persists: false,
      };
    case ACTION_CLEAR_MODAL_FLASH_MESSAGE:
      return {
        ...state,
        modalFlashMessage: { ...emptyFlashMessage },
        persists: false,
      };
    case LOCATION_CHANGE:
      return state.persists ? state : getInitialState();
    default:
      return state;
  }
};
