import { ACTION_CHOOSE_EXTERNAL_ACCOUNT_OPTION_LOADED } from '../../containers/addExternalAccount/addExternalAccount.reducers';
import pageAnalytics from '../pageAnalytics';

const initData = {
  account_type: 'generic',
  pagekind: 'transfers',
  pagefunction: 'add_external_bank',
  pagesubfunction: 'existing',
  pagename: 'choose_method',
  attr: '',
} as const;

const viewSuccess = () => {
  pageAnalytics.newPageView(initData).record();
};

export const chooseExternalAccountOptionPageEvents = {
  [ACTION_CHOOSE_EXTERNAL_ACCOUNT_OPTION_LOADED]: viewSuccess,
} as const;

export default chooseExternalAccountOptionPageEvents;
