import React from 'react';
import { Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import shouldFdicLogoRender from '../../utilities/shouldFdicLogoRender';
import styles from './header.styles';
import pxToRem from '../../utilities/pxToRem';
import { LargestHeader } from '../typography/typography';
import { HEADER_PORTAL_ID } from './headerPortal';
import FDICLogo from '../fdicLogo/fdicLogo';

type HeaderCollapseProps = {
  classes: any;
  children: React.ReactNode;
  active: boolean;
  transitionState?: string;
  isDark: boolean;
  onEnter: any;
  onEntered: any;
  onExit: any;
  onExited: any;
  onFocus: any;
  onBlur: any;
  onMouseLeave: any;
  collapseRef: any;
  itemsRef: any;
  onMouseEnterTrue: any;
  onMouseEnterFalse: any;
  usingKeyboard?: boolean;
  title?: string;
  isScrolledDown?: boolean;
};

const HeaderCollapse = withStyles(styles)((headerCollapseProps: HeaderCollapseProps) => {
  const {
    classes = {},
    children,
    active,
    itemsRef,
    transitionState,
    isDark,
    onEnter,
    onEntered,
    onExit,
    onExited,
    onFocus,
    onBlur,
    onMouseLeave,
    collapseRef,
    onMouseEnterTrue,
    onMouseEnterFalse,
    usingKeyboard,
    title,
    isScrolledDown,
  } = headerCollapseProps;
  const isFdicLogoVisible = shouldFdicLogoRender();
  const MouseLeave = () => {
    if (transitionState !== 'opening') {
      onMouseLeave();
    }
  };
  let y = 0;

  const unFreezeScrolling = () => {
    collapseRef.current.onscroll = () => {
      collapseRef.current.scrollTop = y;
    };
  };

  const freezeScrolling = () => {
    if (collapseRef.current !== null) {
      collapseRef.current.onscroll = () => {};
    }
  };

  const handleFocus = () => {
    if (collapseRef.current !== null) {
      requestAnimationFrame(() => {
        freezeScrolling();
        document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
    onFocus();
  };

  const handleBlur = () => {
    y = collapseRef.current.scrollTop;
    unFreezeScrolling();
    onBlur();
    requestAnimationFrame(() => {
      freezeScrolling();
    });
  };

  return (
    <>
      <Collapse
        collapsedSize={isDark ? '0px' : pxToRem(100)}
        in={active}
        timeout={{ enter: 400, exit: 200 }}
        className={classNames(
          classes.collapseSection,
          active ? classes.headerMenuOpen : '',
          transitionState === 'opened' ? classes.headerMenuOpened : ''
        )}
        onEnter={onEnter}
        onEntered={onEntered}
        onExit={onExit}
        onExited={onExited}
        // @ts-ignore
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseLeave={MouseLeave}
        ref={collapseRef}
        data-test="header-menu-collapse"
      >
        {transitionState === 'closed' && (
          <div
            className={classNames(classes.mobileHidden, classes.menuHit)}
            onMouseEnter={onMouseEnterTrue}
            aria-hidden
            data-test="transition-closed"
          />
        )}

        {/* Hidden div positioned at the bottom of the fully expanded Collapse. Moving the mouse into it
            closes the header. Allows for a larger mouse area without preventing hover events on the links. */}
        {active && !usingKeyboard && (
          <div
            className={classNames(classes.mobileHidden, classes.menuExit)}
            onMouseEnter={onMouseEnterFalse}
            style={{
              height: itemsRef.current
                ? `calc(100% - ${pxToRem(itemsRef.current.scrollHeight)})`
                : 0,
              top: itemsRef.current ? itemsRef.current.scrollHeight : 0,
            }}
            aria-hidden
            data-test="closing-header-mouse-hover"
          />
        )}
        {children}
      </Collapse>

      <div
        className={classNames(
          isFdicLogoVisible ? classes.headerPageContainerWithLogo : classes.headerPageContainer,
          isScrolledDown && !isFdicLogoVisible ? classes.headerPageContainerCollapsed : '',
          isScrolledDown && isFdicLogoVisible ? classes.headerPageContainerWithLogoCollapsed : ''
        )}
      >
        {isFdicLogoVisible && <FDICLogo isDark />}
        {title && (
          <LargestHeader
            id="page-title"
            className={classNames(classes.headerPageTitle)}
            tabIndex={-1}
            data-test="header-page-title"
          >
            {title}
          </LargestHeader>
        )}
      </div>

      <div
        id={HEADER_PORTAL_ID}
        className={classNames(classes.headerPortal, {
          [classes.headerPortalCollapsed]: isScrolledDown,
        })}
        data-test="header-portal"
      />
    </>
  );
});
export default HeaderCollapse;
