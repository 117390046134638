import { Location } from 'history';

export const MessageFieldNames = Object.freeze({
  ATTACHMENTS: 'attachments',
  ATTACHMENT_FILES: 'attachmentFiles',
  TOPIC: 'topic',
  MESSAGE: 'message',
});

export type Attachment = {
  attachmentName: string;
};

export type Message = {
  messageId: string;
  from: string;
  to: string;
  text: string;
  date: string;
  attachments: Attachment[];
};

export type MessageDetails = {
  threadId: string;
  subject: string;
  messages: Message[];
};

export type Topic = {
  name: string;
  value: string;
};

export type GetTopics = {
  topics: Topic[];
};

export type AttachmentDownloadLinkResponse = {
  attachmentLink: string;
};

export type InboxLocationShape = Location & {
  state?: {
    successfullySent: boolean;
  };
};
