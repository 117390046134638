import React, { Ref, forwardRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import type { FormApi } from 'final-form';
import type { MessageForm } from '../../utilities/types';
import { MessageFieldNames } from '../../containers/messages/messages.constants';
import IconButton from '../buttons/iconButton';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

type FileAttachmentIconProps = {
  isLoading: boolean;
  classes: {
    [key: string]: string;
  };
  handleRemove: () => void;
  file: File;
};

type CurrentFileAttachmentProps = {
  form: FormApi<MessageForm>;
  response: { attachmentId: string };
};

type AttachedFileDisplayProps = {
  attachmentId: string;
  values: MessageForm;
  handleRemoveFocus: () => void;
  form: FormApi<MessageForm>;
  file: File;
};

type ErrorMessageProps = {
  errorMessage: React.ReactNode;
  classes: {
    [key: string]: string;
  };
};

export const handleCurrentAttachment = ({ form, response }: CurrentFileAttachmentProps) => {
  // Calling getState because it's possible one of the other AttachmentTiles reached this point
  // between the most recent render and now
  const updatedValues = form.getState().values;
  if (updatedValues) {
    const currentAttachments = updatedValues.attachments || [];
    form.change(MessageFieldNames.ATTACHMENTS, [...currentAttachments, response.attachmentId]);
  }
};

export const handleAttachedFiles = ({
  attachmentId,
  values,
  handleRemoveFocus,
  form,
  file,
}: AttachedFileDisplayProps) => {
  if (attachmentId) {
    const attachmentIds = new Set(values.attachments);
    attachmentIds.delete(attachmentId);
    form.change(MessageFieldNames.ATTACHMENTS, [...attachmentIds]);
  }
  const updatedFiles = new Set(values.attachmentFiles);
  updatedFiles.delete(file);
  handleRemoveFocus();
  form.change(MessageFieldNames.ATTACHMENT_FILES, updatedFiles);
};

export const RenderAttachmentIcon = forwardRef(
  (props: FileAttachmentIconProps, ref: Ref<HTMLButtonElement>) => {
    const { isLoading, classes, handleRemove, file } = props;
    return isLoading ? (
      <span className={classes.loadingIndicatorContainer}>
        <CircularProgress size={18} />
      </span>
    ) : (
      <IconButton autoFocus ref={ref} className={classes.removeButton} onClick={handleRemove}>
        <SVGImage
          imageName={ImagesFileNames.iconRemoveSvg}
          ariaLabel={`Remove attachment ${file.name}`}
          role="img"
        />
      </IconButton>
    );
  }
);

export const RenderErrorMessage = ({ errorMessage, classes }: ErrorMessageProps) => {
  return errorMessage ? (
    <div data-test="attachment-error" className={classes.errorMessage}>
      {errorMessage}
    </div>
  ) : null;
};
