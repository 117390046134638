import { Grid, withStyles } from '@material-ui/core';
import type { ReactNode } from 'react';
import React from 'react';
import Colors from '../colors/colors';
import { Alignment } from '../../styles/layout/alignment.styles';
import { Z_INDEX_LEFT_COLUMN } from '../../styles/zIndex';
import pxToRem from '../../utilities/pxToRem';
import { InformationalSectionHeader, StandardText } from '../typography/typography';
import type { StyleProps, Theme } from '../../utilities/types';

export const LEFT_PANEL_BOX_SHADOW = '8px -2px 5px 0 rgba(0, 0, 0, 0.03)';

const styles = (theme: Theme) => ({
  headerPadding: {
    ...Alignment.pageContentPadding,
    width: '100%',
    height: '100%',
  },
  leftPanelContainer: {
    ...Alignment.headerPadding,
    zIndex: Z_INDEX_LEFT_COLUMN,
    [theme.breakpoints.up('md')]: {
      ...Alignment.allPadding,
      flexGrow: 1,
      minHeight: '500px',
      backgroundColor: Colors.white,
      boxShadow: LEFT_PANEL_BOX_SHADOW,
    },
  },
  headerText: {
    marginTop: pxToRem(10),
    marginBottom: pxToRem(22),
  },
});
type LeftPanelGridProps = {
  children: ReactNode;
  ['data-test']?: string;
} & StyleProps;

const LeftPanelGridItem = ({ children, classes, 'data-test': dataTest }: LeftPanelGridProps) => (
  <Grid
    item
    xs={12}
    md={4}
    component="aside"
    className={classes.leftPanelContainer}
    data-test={dataTest}
  >
    <div className={classes.headerPadding}>{children}</div>
  </Grid>
);

const Styles = withStyles(styles);
const styled = Styles(LeftPanelGridItem);
styled.displayName = 'LeftPanelGridItem';
export default styled;

type LeftPanelHeaderProps = {
  title: string;
  text?: string;
} & StyleProps;

const LeftPanelHeaderInner = ({ classes, title, text }: LeftPanelHeaderProps) => (
  <>
    <InformationalSectionHeader>{title}</InformationalSectionHeader>
    <div className={classes.headerText}>
      <StandardText>{text}</StandardText>
    </div>
  </>
);

export const LeftPanelHeader = Styles(LeftPanelHeaderInner);
