import depositAccounts from './depositAccounts';
import products from './products';
import depositAccountDetails from './depositAccountDetails';
import { HandlersConfig } from '../typings';

export const defaultHandlersConfig: HandlersConfig[] = [
  { name: 'depositAccounts', enabled: false },
  { name: 'products', enabled: false },
  { name: 'depositAccountDetails', enabled: false },
];

export const handlerMapping = {
  depositAccounts,
  products,
  depositAccountDetails,
};
