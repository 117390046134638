import React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import { Field as ReduxFormField } from 'redux-form';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FinalFormComponents } from '../finalForm/finalFormInputs';
import { ReduxFormComponents } from '../reduxForms/inputs';
import { AddressFieldNames } from '../../form.constants';
import Aria from '../cms/aria.constants';
import {
  checkEmptyStreetAddress,
  checkMinCharsStreetAddress,
  checkValidStreetAddress,
  checkEmptyCity,
  checkMinCharsCity,
  checkValidCity,
  checkValidZipCode,
  checkEmptyZip,
  checkValidUnitNumber,
  checkEmptyState,
  checkEmptyEmail,
  checkValidEmailAddress,
  checkValidPhoneNumber,
  checkEmptyPhone,
} from '../../containers/newAccountOpening/newUser/openAccount.validators';
import type { List } from '../../reducers/lists.reducer';
import { SelectField } from '../cms/inputText.constants';
import { normalizeDashes } from '../../utilities/reduxForm';
import { requiredField, composeValidators } from '../../utilities/validators';
import Labels from '../cms/labels.constants';

type AddressFieldProps = {
  classes?: ClassNameMap;
  dataTest?: string;
  disabled?: boolean;
  initialValue?: string;
  useFinalForm?: boolean;
};

const emailValidator = composeValidators(checkEmptyEmail, checkValidEmailAddress);
const streetAddress1Validator = composeValidators(
  checkEmptyStreetAddress,
  checkMinCharsStreetAddress,
  checkValidStreetAddress
);
const cityValidator = composeValidators(checkEmptyCity, checkMinCharsCity, checkValidCity);
const zipCodeValidator = composeValidators(checkEmptyZip, checkValidZipCode);
const phoneNumberValidator = composeValidators(checkEmptyPhone, checkValidPhoneNumber);

export const Email = (props: AddressFieldProps) => {
  const { classes = {}, disabled, dataTest, initialValue, useFinalForm } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderEmailField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: AddressFieldNames.EMAIL,
    id: AddressFieldNames.EMAIL,
    type: 'email',
    label: 'Email Address',
    labelId: 'emailLabelId',
    'data-test': dataTest,
    validate: emailValidator,
    component: RenderEmailField,
    autoComplete: 'email',
    maxLength: 54,
    disabled,
    ariaDescribed: Aria.ADA_DISCLOSURE_DESC_BY_ID,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

type StreetAddress1Props = {
  helperText?: string;
  classNameInstruction?: string;
};

export const StreetAddress1 = (props: AddressFieldProps & StreetAddress1Props) => {
  const {
    classes = {},
    disabled,
    dataTest,
    helperText,
    initialValue,
    useFinalForm,
    classNameInstruction,
  } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderTextField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    id: 'streetAddress1',
    name: AddressFieldNames.STREET_ADDRESS_1,
    type: 'text',
    label: 'Street Address',
    labelId: 'streetAddressLabelId',
    'data-test': dataTest,
    validate: streetAddress1Validator,
    autoComplete: 'address-line1',
    component: RenderTextField,
    disabled,
    maxLength: 50,
    ariaRequired: true,
    classes,
    ariaDescribed: Aria.ADA_DISCLOSURE_DESC_BY_ID,
    ...finalFormOnlyProps,
  };

  return (
    <>
      {useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />}{' '}
      <span id={Aria.ADA_STREET_ADDRESS} className={classNameInstruction}>
        {helperText}
      </span>
    </>
  );
};

export const StreetAddress2 = (props: AddressFieldProps) => {
  const { classes = {}, disabled, dataTest, initialValue, useFinalForm } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderTextField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    id: 'streetAddress2',
    name: AddressFieldNames.STREET_ADDRESS_2,
    type: 'text',
    label: 'Unit # (Optional)',
    labelId: 'unitnoAddressLabelId',
    'data-test': dataTest,
    validate: checkValidUnitNumber,
    autoComplete: 'address-line2',
    component: RenderTextField,
    disabled,
    maxLength: 15,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

export const City = (props: AddressFieldProps) => {
  const { classes = {}, disabled, dataTest, initialValue, useFinalForm } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderTextField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: AddressFieldNames.CITY,
    type: 'text',
    autoComplete: 'address-level2',
    label: 'City',
    labelId: 'cityAddressLabelId',
    'data-test': dataTest,
    validate: cityValidator,
    component: RenderTextField,
    disabled,
    maxLength: 24,
    ariaRequired: true,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

type StateProps = {
  states: List;
};

export const State = (props: AddressFieldProps & StateProps) => {
  const { classes = {}, disabled, dataTest, initialValue, states, useFinalForm } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderSelectField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: AddressFieldNames.STATE,
    id: 'state',
    label: 'State',
    labelId: 'stateAddressLabelId',
    'data-test': dataTest,
    validate: checkEmptyState,
    placeholder: SelectField.STATE,
    options: states,
    component: RenderSelectField,
    disabled,
    autoComplete: 'address-level1',
    ariaRequired: true,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

export const ZipCode = (props: AddressFieldProps) => {
  const { classes = {}, disabled, dataTest, initialValue, useFinalForm } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderZipcodeField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;

  const fieldProps = {
    name: AddressFieldNames.ZIP_CODE,
    id: AddressFieldNames.ZIP_CODE,
    type: 'text',
    label: 'Zip Code',
    labelId: 'zipAddressLabelId',
    'data-test': dataTest,
    validate: zipCodeValidator,
    component: RenderZipcodeField,
    autoComplete: 'postal-code',
    parse: useFinalForm ? normalizeDashes : undefined,
    normalize: useFinalForm ? undefined : normalizeDashes,
    disabled,
    ariaRequired: true,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

type PhoneNumberProps = {
  noPhoneNumber: boolean;
  label?: string;
  name?: string;
};

export const PhoneNumber = (props: AddressFieldProps & PhoneNumberProps) => {
  const {
    classes = {},
    disabled,
    dataTest,
    initialValue,
    label = Labels.PHONE_NUMBER,
    name = AddressFieldNames.PHONE_NUMBER,
    noPhoneNumber,
    useFinalForm,
  } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderTelephoneField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;
  const reduxFormValidator = noPhoneNumber ? undefined : phoneNumberValidator;
  const finalFormValidator = (value, allValues) =>
    // $FlowShutUp Doesn't play nicely with final form / redux form ambiguity
    allValues.noPhoneNumber ? undefined : phoneNumberValidator(value);
  const validator = useFinalForm ? finalFormValidator : reduxFormValidator;

  const fieldProps = {
    'data-test': dataTest,
    name,
    id: name,
    label,
    labelId: 'phonenumLabelId',
    validate: validator,
    autoComplete: 'tel-national',
    type: 'tel',
    component: RenderTelephoneField,
    parse: useFinalForm ? normalizeDashes : undefined,
    normalize: useFinalForm ? undefined : normalizeDashes,
    disabled: disabled || noPhoneNumber,
    ariaRequired: disabled || !noPhoneNumber,
    ariaDescribed: Aria.ADA_DISCLOSURE_DESC_BY_ID,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

export const PhoneType = (props: AddressFieldProps & PhoneNumberProps) => {
  const {
    classes = {},
    disabled,
    dataTest,
    initialValue,
    label = Labels.PHONE_TYPE,
    name = AddressFieldNames.PHONE_TYPE,
    noPhoneNumber,
    useFinalForm,
  } = props;
  const finalFormOnlyProps = { initialValue };
  if (!useFinalForm) {
    // Need to use delete and not just setting undefined, see https://github.com/facebook/react/issues/7266
    delete finalFormOnlyProps.initialValue;
  }
  const { RenderPhoneTypeField } = useFinalForm ? FinalFormComponents : ReduxFormComponents;
  const reduxFormValidator = noPhoneNumber ? undefined : requiredField();
  const finalFormValidator = (value, allValues) =>
    allValues.noPhoneNumber ? undefined : requiredField()(value);
  const validator = useFinalForm ? finalFormValidator : reduxFormValidator;

  const fieldProps = {
    'data-test': dataTest,
    name,
    label,
    validate: validator,
    component: RenderPhoneTypeField,
    disabled: disabled || noPhoneNumber,
    ariaRequired: disabled || !noPhoneNumber,
    ariaDescribed: Aria.ADA_DISCLOSURE_DESC_BY_ID,
    classes,
    ...finalFormOnlyProps,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};

type NoPhoneCheckProps = {
  clearPhoneNumber: () => void;
};

export const NoPhoneCheck = (props: AddressFieldProps & PhoneNumberProps & NoPhoneCheckProps) => {
  const { classes = {}, clearPhoneNumber, disabled, dataTest, noPhoneNumber, useFinalForm } = props;
  const { NoPhoneCheck: renderNoPhoneCheck } = useFinalForm
    ? FinalFormComponents
    : ReduxFormComponents;

  const fieldProps = {
    onChange: (event) => {
      const { checked } = event.target;
      if (checked && clearPhoneNumber) {
        clearPhoneNumber();
      }
    },
    name: AddressFieldNames.NO_PHONE_NUMBER,
    type: useFinalForm ? 'checkbox' : undefined,
    'data-test': dataTest,
    label: 'No phone number due to a hearing or speech disability',
    labelId: 'nophoneLabelId',
    checked: noPhoneNumber,
    component: renderNoPhoneCheck,
    format: (value) => (value ? 'true' : 'false'),
    disabled,
    ariaRequired: !disabled && noPhoneNumber,
    classes,
  };

  return useFinalForm ? <FinalFormField {...fieldProps} /> : <ReduxFormField {...fieldProps} />;
};
