const cleanValue = (v) => {
  if (Array.isArray(v)) {
    return v.map((item) => cleanValue(item)).filter((item) => typeof item !== 'undefined');
  }
  if (typeof v === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return cleanObject(v);
  }
  return v;
};

/**
 * Deeply cleans object to
 * - Remove all properties with undefined values
 * - Remove all properties with empty objects as values
 * - Clean objects within nested arrays
 * - Remove undefined array entries
 */
export function cleanObject(obj: Record<string, unknown>): Record<string, unknown> | undefined {
  const newObj: Record<string, unknown> = {};
  const propNames = Object.getOwnPropertyNames(obj);
  let numUndefinedValues = 0;
  for (const propName of propNames) {
    const value = obj[propName];
    const newValue = cleanValue(value);
    if (typeof newValue === 'object' && Object.keys(newValue).length === 0) {
      numUndefinedValues += 1;
    } else if (typeof newValue !== 'undefined') {
      newObj[propName] = newValue;
    } else {
      numUndefinedValues += 1;
    }
  }
  return propNames.length === numUndefinedValues ? undefined : newObj;
}

export default cleanObject;
