import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { StandardTextEmphasis, SubtitleText } from '../typography/typography';
import { NO_EMAIL_ON_FILE } from '../cms/blockText.constants';
import Routes from '../../containers/routes/routes.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import { AccountActivityBtn } from '../cms/buttonText.constants';
import pxToRem from '../../utilities/pxToRem';
import type { StyleProps } from '../../utilities/types';
import Arrow, { ArrowPosition } from '../links/arrow';
import { boldFontWeight } from '../typography/typography.styles';
import colors from '../colors/colors';

const styles = {
  whiteSpaceContainer: {
    paddingRight: pxToRem(5),
  },
  linkText: {
    fontSize: pxToRem(14),
    fontWeight: boldFontWeight,
    lineHeight: 1.29,
    letterSpacing: pxToRem(1.2),
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: colors.optimumBlue,
    '&:hover': {
      color: colors.hoverState,
      textDecoration: 'underline',
    },
  },
} as const;

type Props = {
  dataTest: string;
  hasNoLabel?: boolean;
  emailAddress?: string;
} & StyleProps;

const renderNoEmail = (classes: { [key: string]: string }) => {
  return (
    <>
      <SubtitleText className={classes.whiteSpaceContainer} data-test="no-email-on-file-text">
        {NO_EMAIL_ON_FILE}{' '}
      </SubtitleText>
      <Link
        to={{ pathname: Routes.PROFILE, state: { forceEditingContactInfo: true } }}
        className={classes.linkText}
        data-test="quick-links-add-email-address"
        data-track-title={clickTrack.profile.add_email_address}
        data-track-type={clickTrackType.LINK}
      >
        {AccountActivityBtn.ADD_EMAIL_ADDRESS}
        <Arrow position={ArrowPosition.RIGHT} direction={ArrowPosition.RIGHT} />
      </Link>
    </>
  );
};

const RenderEmailOrNoEmail = ({ dataTest, hasNoLabel, emailAddress, classes }: Props) => {
  return (
    <p data-test={dataTest}>
      {!hasNoLabel && <StandardTextEmphasis>Email: </StandardTextEmphasis>}
      {emailAddress ? <SubtitleText> {emailAddress} </SubtitleText> : renderNoEmail(classes)}
    </p>
  );
};
export default withStyles(styles)(RenderEmailOrNoEmail);
