import { makeStyles } from '@material-ui/styles';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';

export const useBeneficiariesStyles = makeStyles((theme: Theme) => ({
  addBeneficiaryButton: {
    [theme.breakpoints.up('lg')]: {
      marginRight: pxToRem(56),
    },
  },
  addBeneficiaryButtonMobile: {
    // Accounting for button padding to keep text aligned with page
    marginLeft: -8,
    marginTop: pxToRem(30),
    [theme.breakpoints.up('sm')]: {
      marginBottom: pxToRem(14),
      marginTop: pxToRem(18),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: pxToRem(30),
      marginTop: pxToRem(16),
    },
  },
  beneficiaryAddress: {
    marginTop: pxToRem(8),
    marginBottom: pxToRem(24),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  buttonSvg: {
    marginRight: pxToRem(8),
  },
  column: {
    margin: 'auto',
  },
  editButton: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      marginRight: pxToRem(64),
    },

    // Same as passing classes={{ label: <classname> }} to the button but MUI threw an error on that
    '& > span': {
      display: 'flex',
    },
  },
  editRemoveCell: {
    textAlign: 'left',
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  fullHeightLayout: {
    padding: 0,
    overflow: 'hidden',
  },
  itemContainer: {
    paddingTop: pxToRem(16),
    paddingBottom: pxToRem(24),
  },
  removeButton: {
    [theme.breakpoints.up('lg')]: {
      marginRight: pxToRem(56),
    },
  },
  flashMessage: {
    width: '100%',
    marginBottom: 0,
  },
  downloadSvg: {
    marginRight: pxToRem(8),
    display: 'inline',
  },
  editIconSvg: {
    width: pxToRem(18),
    height: pxToRem(18),
  },
  beneficiaryItemContainer: {
    paddingBottom: pxToRem(24),
  },
  manageHeader: {
    paddingBottom: pxToRem(12),
  },
}));

export default useBeneficiariesStyles;
