import type { RecurringInterval } from '../transfers.types';
import type { ServiceError } from '../../../utilities/types';
import type { TransferActivity } from '../transfers.reducer';

export type EditTransferInfo = {
  successMessage: string;
};

export type EditTransferState = {
  transfer?: TransferActivity;
  editTransferError?: ServiceError;
  editTransferInfo: EditTransferInfo;
  isLoading: boolean;
  isPending: boolean;
  frequencies: RecurringInterval[];
};

export const getInitialState = (): EditTransferState => ({
  editTransferInfo: { successMessage: '' },
  isLoading: true,
  isPending: false,
  frequencies: [],
});

export const ACTION_REDIRECT_CONFIRM_EDIT_TRANSFER = 'ACTION_REDIRECT_CONFIRM_EDIT_TRANSFER';
export const ACTION_CREATE_EDIT_TRANSFER_REQUEST = 'ACTION_CREATE_EDIT_TRANSFER_REQUEST';
export const ACTION_CREATE_EDIT_TRANSFER_FAILURE = 'ACTION_CREATE_EDIT_TRANSFER_FAILURE';
export const ACTION_CREATE_EDIT_TRANSFER_SUCCESS = 'ACTION_CREATE_EDIT_TRANSFER_SUCCESS';
export const ACTION_CLEAR_EDIT_TRANSFER = 'ACTION_CLEAR_EDIT_TRANSFER';

export type EditTransferAction =
  | {
      type: 'ACTION_CREATE_EDIT_TRANSFER_FAILURE';
    }
  | {
      type: 'ACTION_CREATE_EDIT_TRANSFER_REQUEST';
    }
  | {
      type: 'ACTION_REDIRECT_CONFIRM_EDIT_TRANSFER';
      payload: TransferActivity;
    }
  | {
      type: 'ACTION_CREATE_EDIT_TRANSFER_SUCCESS';
      payload: EditTransferInfo;
    }
  | {
      type: 'ACTION_CLEAR_EDIT_TRANSFER';
    };

export default (
  state: EditTransferState | null | undefined = getInitialState(),
  action: EditTransferAction = undefined
): EditTransferState => {
  switch (action.type) {
    case ACTION_CREATE_EDIT_TRANSFER_FAILURE:
      return { ...state, isPending: false };
    case ACTION_CREATE_EDIT_TRANSFER_REQUEST:
      return { ...state, isPending: true };
    case ACTION_CREATE_EDIT_TRANSFER_SUCCESS:
      return {
        ...state,
        editTransferInfo: action.payload,
        isPending: false,
      };
    case ACTION_REDIRECT_CONFIRM_EDIT_TRANSFER:
      return {
        ...state,
        transfer: action.payload,
        isLoading: false,
      };
    case ACTION_CLEAR_EDIT_TRANSFER:
      return { ...state, transfer: undefined };
    default:
      return { ...state };
  }
};
