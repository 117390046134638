import type { FetchSuccessType } from '../../containers/externalAccountDashboard/externalAccountDashboard.reducer';
import {
  ACTION_FETCH_EXTERNAL_ACCOUNTS_FAILURE,
  ACTION_FETCH_EXTERNAL_ACCOUNTS_REQUEST,
  ACTION_FETCH_EXTERNAL_ACCOUNTS_SUCCESS,
} from '../../containers/externalAccountDashboard/externalAccountDashboard.reducer';
import pageAnalytics from '../pageAnalytics';

const viewInit = () => {
  pageAnalytics.newPageView({
    account_type: 'generic',
    pagekind: 'transfers',
    pagefunction: 'external_banks_dashboard',
  });
};

const viewSuccess = (action: FetchSuccessType) => {
  const accounts = action.payload;

  if (!Array.isArray(accounts) || !accounts.length) {
    pageAnalytics.addInfo({ pagename: 'landing_page_empty' });
    pageAnalytics.record();
    return;
  }

  if (
    accounts.some(
      (account) =>
        account.verificationStatus !== 'VERIFIED' && account.trialDeposits.message != null
    )
  ) {
    pageAnalytics.addInfo({ pagename: 'landing_page_trial_deposits_message' });
    pageAnalytics.record();
    return;
  }

  if (accounts.length > 9) {
    pageAnalytics.addInfo({ pagename: 'landing_page_max_accounts' });
    pageAnalytics.record();
    return;
  }

  pageAnalytics.addInfo({ pagename: 'landing_page' });
  pageAnalytics.record();
};
const viewFailure = () => {
  pageAnalytics.addInfo({ pagename: 'landing_page_failed_to_load' });
  pageAnalytics.record();
};

export const externalAccountDashboardPageEvents = {
  [ACTION_FETCH_EXTERNAL_ACCOUNTS_REQUEST]: viewInit,
  [ACTION_FETCH_EXTERNAL_ACCOUNTS_SUCCESS]: viewSuccess,
  [ACTION_FETCH_EXTERNAL_ACCOUNTS_FAILURE]: viewFailure,
} as const;

export default externalAccountDashboardPageEvents;
