import type { AllAccount } from 'Domain/Account/AllAccount';
import { ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS } from '../externalAccountDashboard/externalAccountDashboard.reducer';

export const ACTION_FETCH_ALL_ACCOUNTS_REQUEST = 'ACTION_FETCH_ALL_ACCOUNTS_REQUEST';
export const ACTION_FETCH_ALL_ACCOUNTS_SUCCESS = 'ACTION_FETCH_ALL_ACCOUNTS_SUCCESS';
export const ACTION_FETCH_ALL_ACCOUNTS_FAILURE = 'ACTION_FETCH_ALL_ACCOUNTS_FAILURE';
export const ACTION_CLEAR_ALL_ACCOUNTS = 'ACTION_CLEAR_ALL_ACCOUNTS';
export const ACTION_ADD_YODLEE_ALL_ACCOUNT = 'ACTION_ADD_YODLEE_ALL_ACCOUNT';

export type AllAccountsType = {
  internalAccounts: Array<AllAccount>;
  externalAccounts: Array<AllAccount>;
};

export type AllAccountsState = AllAccountsType & {
  allAccountsLoading: boolean;
  error: boolean;
};

export type AllAccountsAction =
  | {
      type: typeof ACTION_FETCH_ALL_ACCOUNTS_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_ALL_ACCOUNTS_SUCCESS;
      payload: AllAccountsType;
    }
  | {
      type: typeof ACTION_FETCH_ALL_ACCOUNTS_FAILURE;
    }
  | {
      type: typeof ACTION_ADD_YODLEE_ALL_ACCOUNT;
      payload: AllAccount;
    }
  | {
      type: 'ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS';
      payload: {
        accountId: string;
      };
    }
  | {
      type: typeof ACTION_CLEAR_ALL_ACCOUNTS;
    };

function getInitialState(): AllAccountsState {
  return {
    internalAccounts: [],
    externalAccounts: [],
    allAccountsLoading: false,
    error: false,
  };
}

export default (
  state: AllAccountsState | null | undefined = getInitialState(),
  action: AllAccountsAction = undefined
): AllAccountsState => {
  switch (action.type) {
    case ACTION_DELETE_EXTERNAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        externalAccounts: state.externalAccounts.filter(
          (account) => account.id !== action.payload.accountId
        ),
      };
    case ACTION_FETCH_ALL_ACCOUNTS_REQUEST:
      return { ...state, allAccountsLoading: true, error: false };
    case ACTION_FETCH_ALL_ACCOUNTS_FAILURE:
      return { ...state, allAccountsLoading: false, error: true };
    case ACTION_FETCH_ALL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        allAccountsLoading: false,
        error: false,
      };
    case ACTION_ADD_YODLEE_ALL_ACCOUNT:
      if (
        state.externalAccounts.length === 0 ||
        !state.externalAccounts.map((acc) => acc.externalId).includes(action.payload.externalId)
      ) {
        return { ...state, externalAccounts: [...state.externalAccounts, action.payload] };
      }
      return { ...state };
    case ACTION_CLEAR_ALL_ACCOUNTS: {
      return getInitialState();
    }
    default:
      return state;
  }
};
