import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Hidden } from '@material-ui/core';
import classnames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { uniqueId } from 'lodash';
import AccountManagementPage from '../../components/accountManagementPage/accountManagementPage';
import {
  Header2,
  ScreenReaderText,
  SectionHeader,
  StandardText,
} from '../../components/typography/typography';
import type {
  Alert,
  AlertsEligibleAccount,
  UpdateAccountAlertsPreferencesResponse,
} from './accountAlerts.constants';
import AlertsPageTexts from './accountAlerts.constants';
import { AccountAlertsType } from '../../form.constants';
import type { CustomerInfo } from '../../utilities/types';
import type { ReduxState } from '../../reducers';
import RenderEmailOrNoEmail from '../../components/email/renderEmailOrNoEmail';
import { useEffectOnce } from '../../utilities/reactHooks';
import { fetchAccountAlertsPreferences } from './accountAlerts.service';
import Error from '../../components/error/error';
import { FlashMessageText } from '../../components/flashMessage/flashMessage.constants';
import TileListSubtitles from '../../components/tileList/tileListSubtitles';
import TileList from '../../components/tileList/tileList';
import { useAccountAlertsStyles } from './accountAlerts.styles';
import { useActivityTileStyles } from '../../components/activityTile/activityTile.styles';
import { buildAccountNameWithProduct } from '../../formatters/buildAccountName';
import { IconGhostButton } from '../../components/buttons/buttons';
import currencyFormatter from '../../formatters/currency';
import { fetchCustomerInfo as fetchCustomerInfoAction } from '../newAccountOpening/applications.actions';
import { useAlignmentStyles } from '../../styles/layout/alignment.styles';
import EditAccountAlerts from './editAccountAlerts';
import {
  ANALYTICS_ACCOUNT_ALERTS_START_FAILURE,
  ANALYTICS_ACCOUNT_ALERTS_START_SUCCESS,
} from '../../analytics/actions';
import { AccountAlertsBtn } from '../../components/cms/buttonText.constants';
import { formatAccountNameForScreenReader } from '../../utilities/a11y';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';

type StateProps = {
  customerInfo?: CustomerInfo;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  customerInfo: state.applications.customerInfo,
});

type DispatchProps = {
  fetchCustomerInfo: () => Promise<void>;
  recordAnalyticsPageView: () => void;
  recordAnalyticsErrorPageView: () => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchCustomerInfo: async () => {
    dispatch(fetchCustomerInfoAction());
  },
  recordAnalyticsPageView: () => {
    dispatch({ type: ANALYTICS_ACCOUNT_ALERTS_START_SUCCESS });
  },
  recordAnalyticsErrorPageView: () => {
    dispatch({ type: ANALYTICS_ACCOUNT_ALERTS_START_FAILURE });
  },
});

type AllProps = StateProps & DispatchProps;

const setFocusOnEditAlerts = (editAccount) => {
  if (editAccount && editAccount?.editId) {
    setTimeout(() => {
      const EditAlertElement: HTMLElement = document.querySelector(`[id=${editAccount.editId}]`);
      if (EditAlertElement) {
        EditAlertElement.focus();
      }
    }, 100);
  }
};

export const Alerts = ({
  customerInfo,
  fetchCustomerInfo,
  recordAnalyticsPageView,
  recordAnalyticsErrorPageView,
}: AllProps) => {
  const classes = useAccountAlertsStyles();
  const activityTileClasses = useActivityTileStyles();
  const alignmentClasses = useAlignmentStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [customerErrorMessage, setCustomerErrorMessage] = useState<string | null | undefined>();
  const [eligibleAccounts, setEligibleAccounts] = useState<
    AlertsEligibleAccount[] | null | undefined
  >();
  const [editAccount, setEditAccount] = useState<AlertsEligibleAccount | null | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  const emailOnFile =
    customerInfo &&
    customerInfo.emailAddresses &&
    customerInfo.emailAddresses[0] &&
    customerInfo.emailAddresses[0].email;

  const getAccountAlertsPreferences = async () => {
    if (eligibleAccounts) {
      return;
    }
    let response;
    setIsLoading(true);
    setErrorMessage(undefined);
    try {
      response = await fetchAccountAlertsPreferences();
    } catch (err) {
      recordAnalyticsErrorPageView();
      setErrorMessage((err && err.data && err.data.message) || FlashMessageText.GENERIC_ERROR);
      return;
    } finally {
      setIsLoading(false);
    }
    if (response && response.accountsAlertsPreferences) {
      recordAnalyticsPageView();
      const eligibleAccountsList = response.accountsAlertsPreferences.eligibleAccounts.map(
        (accountList) => {
          return {
            ...accountList,
            editId: uniqueId('editid-'),
          };
        }
      );
      setEligibleAccounts(eligibleAccountsList);
    }
  };

  const refetchCustomerInfo = async () => {
    if (customerInfo) {
      return;
    }
    setIsCustomerLoading(true);
    setCustomerErrorMessage(undefined);
    try {
      await fetchCustomerInfo();
    } catch (err) {
      setCustomerErrorMessage(
        (err && err.data && err.data.message) || FlashMessageText.GENERIC_ERROR
      );
      return;
    } finally {
      setIsCustomerLoading(false);
    }
  };

  const getInitialData = async () =>
    Promise.all([getAccountAlertsPreferences(), refetchCustomerInfo()]);

  useEffectOnce(() => {
    getInitialData();
  });

  const onCancelEditing = () => {
    setFocusOnEditAlerts(editAccount);
    setEditAccount(undefined);
    setIsEditing(false);
  };

  const renderTileListHeader = () => (
    <>
      <Hidden mdUp xsDown>
        <TileListSubtitles
          hideLeftSmDown={false}
          leftColumn="Account"
          middleColumn="Alerts"
          rightColumn="Manage Alerts"
          columnAlign="left"
          columnSizes={{ left: 4, middle: 5, right: 3 }}
          titleType="account-alerts"
          data-test="account-alerts-list-subtitles"
        />
      </Hidden>
      <Hidden smDown>
        <TileListSubtitles
          leftColumn="Account"
          middleColumn="Alerts"
          rightColumn="Manage Alerts"
          columnAlign="left"
          columnSizes={{ left: 3, middle: 6, right: 3 }}
          titleType="account-alerts"
          data-test="account-alerts-list-subtitles"
        />
      </Hidden>
    </>
  );

  const onEditClick = (account: AlertsEligibleAccount) => () => {
    setEditAccount(account);
    setIsEditing(true);
  };

  const onCompleteEditing = (updateResponse: UpdateAccountAlertsPreferencesResponse) => {
    setEditAccount(undefined);
    setIsEditing(false);

    // Should never happen but Flow thinks it can
    if (!eligibleAccounts) return;
    setFocusOnEditAlerts(editAccount);
    // We can only ever edit one account at a time
    const responseAccount = updateResponse.accountsAlertsPreferences.eligibleAccounts[0];

    // Using the response data to build a new eligibleAccounts object with the updated values
    const updatedEligibleAccounts = eligibleAccounts.map((account) => {
      if (account.accountId === responseAccount.accountId) {
        const responseAlerts = responseAccount.alerts.map((alert) => {
          // These fields are in the update response but we don't need or expect them in state
          const { ...rest } = alert;
          return rest;
        });

        // Need to keep the same original ordering of alerts here, so iterating through and replacing
        // outdated alerts as needed based on the response
        const updatedAlerts = account.alerts.map((alert) => {
          const matchingResponseAlert = responseAlerts.find(({ code }) => code === alert.code);
          return matchingResponseAlert || alert;
        });

        return { ...account, alerts: updatedAlerts };
      }

      return account;
    });

    setEligibleAccounts(updatedEligibleAccounts);
  };

  const renderAlertsList = (alerts: Alert[], fullWidth?: boolean) => (
    <Grid data-test="without-none-type-alert-cell" item xs={12} md={fullWidth ? undefined : 6}>
      {alerts.map((alert) => (
        <Fragment key={alert.code}>
          <SectionHeader component="dt" className={classes.alertDescription}>
            {alert.description}
          </SectionHeader>
          <StandardText component="dd" fontWeight="medium" className={classes.alertAmount}>
            {currencyFormatter(alert.amount, false)}
          </StandardText>
        </Fragment>
      ))}
    </Grid>
  );

  const renderAlertAccount = (account: AlertsEligibleAccount) => {
    // Don't render the account currently being edited, if there is one
    if (editAccount && account.accountId === editAccount.accountId) {
      return null;
    }

    const enrolledAlerts = account.alerts.filter(
      (alert) => alert.alertType !== AccountAlertsType.NONE && alert.amount !== null
    );
    // The design has 2 per column which *should* be fine, but allowing for more than 2 here in case
    // more alerts are added in the future since 3 columns wouldn't fit
    const firstColumnAlerts = enrolledAlerts.slice(
      0,
      Math.max(2, Math.ceil(enrolledAlerts.length / 2))
    );
    const secondColumnAlerts = enrolledAlerts.slice(firstColumnAlerts.length);
    return (
      <Grid
        container
        item
        xs={12}
        className={activityTileClasses.itemContainer}
        alignItems="baseline"
        component="tr"
        role="row"
        key={account.accountId}
        data-test="account-alert-row"
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          component="td"
          role="cell"
          className={classnames(activityTileClasses.item, classes.accountName)}
        >
          <span aria-hidden>{buildAccountNameWithProduct(account)}</span>
          <ScreenReaderText>{formatAccountNameForScreenReader(account)}</ScreenReaderText>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} component="td" role="cell">
            <IconGhostButton
              id={account.editId}
              className={classes.mobileEditButton}
              onClick={onEditClick(account)}
              disabled={isEditing}
              data-test="edit-alerts-button"
              icon={
                <SVGImage
                  imageName={ImagesFileNames.iconPencilSvg}
                  className={classes.editIconSvg}
                  ariaHidden="true"
                />
              }
            >
              {AccountAlertsBtn.EDIT_ALERTS}
            </IconGhostButton>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={5}
          md={6}
          component="td"
          role="cell"
          className={activityTileClasses.item}
        >
          {enrolledAlerts.length ? (
            <Grid container item xs={12} component="dl" className={classes.alertsList}>
              {renderAlertsList(firstColumnAlerts, !secondColumnAlerts.length)}
              {!!secondColumnAlerts.length && renderAlertsList(secondColumnAlerts)}
            </Grid>
          ) : (
            <StandardText>Off</StandardText>
          )}
        </Grid>
        <Hidden xsDown>
          <Grid
            item
            sm={3}
            className={classnames(activityTileClasses.item, classes.buttonContainer)}
            component="td"
            role="cell"
          >
            <IconGhostButton
              id={account.editId}
              className={classes.editButton}
              data-test="edit-alerts-button"
              onClick={onEditClick(account)}
              disabled={isEditing}
              icon={
                <SVGImage
                  imageName={ImagesFileNames.iconPencilSvg}
                  className={classes.editIconSvg}
                  ariaHidden="true"
                />
              }
            >
              {AccountAlertsBtn.EDIT_ALERTS}
            </IconGhostButton>
          </Grid>
        </Hidden>
      </Grid>
    );
  };
  // showAccountAlertList condition validate the length of account alerts for edit and nonedit both cases.
  const showAccountAlertList =
    !!eligibleAccounts?.length && !(editAccount && eligibleAccounts?.length === 1);

  const error = errorMessage || customerErrorMessage;
  const renderEmailOrNoEmailDataTest = emailOnFile
    ? 'email-accountAlerts'
    : 'no-email-account-alerts';
  return (
    <AccountManagementPage
      pageHeading="Account Alerts"
      loading={isLoading || isCustomerLoading}
      fullHeightLayoutClassName={classes.fullHeightLayout}
    >
      {error ? (
        <Error data-test="account-alert-error" message={error} onClick={getInitialData} center />
      ) : (
        <>
          <Grid container spacing={3} className={alignmentClasses.allPadding}>
            <Grid item xs={12}>
              <Header2>{AlertsPageTexts.TITLE}</Header2>
            </Grid>
            <Grid item lg={9}>
              <p>{AlertsPageTexts.DESCRIPTION}</p>
            </Grid>
            <Grid item xs={12}>
              <RenderEmailOrNoEmail
                dataTest={renderEmailOrNoEmailDataTest}
                emailAddress={emailOnFile}
              />
            </Grid>
          </Grid>
          {editAccount && (
            <EditAccountAlerts
              account={editAccount}
              onCompleteEditing={onCompleteEditing}
              onCancelEditing={onCancelEditing}
              hideFocusAccountAlerts={showAccountAlertList}
            />
          )}

          <Grid item xs={12}>
            {showAccountAlertList && (
              <TileList
                header={renderTileListHeader()}
                data-test="account-alerts-list"
                list={eligibleAccounts}
                listItemRenderProp={renderAlertAccount}
              />
            )}
          </Grid>
        </>
      )}
    </AccountManagementPage>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
