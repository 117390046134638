import { StyleRules, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import type { ReactNode } from 'react';
import React from 'react';
import classnames from 'classnames';
import pxToRem from '../../utilities/pxToRem';
import type { StyleProps } from '../../utilities/types';
import Colors from '../colors/colors';
import { LinkText, LinkTextOpenAccount } from '../typography/typography';

type LinkProps = {
  icon: ReactNode;
  linkText: string;
  children?: ReactNode;
  to: string;
  ['data-test']?: string;
  ['data-track-title']?: string;
  external?: boolean;
  showIconRight?: boolean;
};

const IconLinkStyles: StyleRules = {
  buttonContainer: {
    color: Colors.optimumBlue,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    textDecoration: 'none',
    '&:hover': {
      color: Colors.hoverState,
    },
    '&:hover $text': {
      textDecoration: 'underline',
      color: 'inherit',
    },
    '& > *:first-child': {
      flexShrink: 0,
    },
  },
  text: {
    whiteSpace: 'nowrap',
  },
  textBrand: {
    whiteSpace: 'nowrap',
    fontSize: `${pxToRem(24)} !important`,
    fontWeight: 'bold',
    color: '#3b3c43 !important',
  },
  externalLinkText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  myOpenAccountBrand: {
    flexDirection: 'row-reverse',
    fontSize: pxToRem(30),
    justifyContent: 'left',
  },
} as const;

const IconLinkWrapper = ({
  children,
  classes,
  'data-test': dataTest,
  'data-track-title': dataTrackTitle,
  external,
  icon,
  linkText,
  to,
  showIconRight,
}: LinkProps & StyleProps) => {
  const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  const showIconRightClassName = showIconRight ? classes.myOpenAccountBrand : '';
  const renderLink = () => {
    const LinkTextClassName = showIconRight ? classes.textBrand : classes.text;
    if (showIconRight) {
      return (
        <LinkTextOpenAccount className={LinkTextClassName}>
          {children || linkText}
        </LinkTextOpenAccount>
      );
    }
    return <LinkText className={LinkTextClassName}>{children || linkText}</LinkText>;
  };

  return external ? (
    <a
      href={to}
      className={classes.buttonContainer}
      data-test={dataTest}
      data-track-title={dataTrackTitle}
      {...externalProps}
    >
      {icon}
      <p className={classes.externalLinkText}>{children || linkText}</p>
    </a>
  ) : (
    <Link
      to={to}
      className={classnames(classes.buttonContainer, showIconRightClassName)}
      data-test={dataTest}
      data-track-title={dataTrackTitle}
    >
      {icon}
      {renderLink()}
    </Link>
  );
};

const iconLink = withStyles(IconLinkStyles)(IconLinkWrapper);
iconLink.displayName = 'IconLink';

export default iconLink;
