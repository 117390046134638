import type { TermsAgreement } from 'Domain/TrialDeposits/VerificationStatus';
import type {
  ExternalAccount,
  VerificationStatus,
  ExternalAccountTrialDeposits,
} from 'Domain/Account/ExternalAccount';
import type { AddExternalAccountResponse } from '../addExternalAccount/externalAccount.domains';

export const ACTION_ADD_EXTERNAL_ACCOUNT_INSTANT_VERIFICATION_SUCCESS =
  'ACTION_ADD_EXTERNAL_ACCOUNT_INSTANT_VERIFICATION_SUCCESS';
export const ACTION_FAIL_VERIFY_TRIAL_DEPOSITS = 'ACTION_FAIL_VERIFY_TRIAL_DEPOSITS';
export const ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS = 'ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS';

export type VerifyTrialDepositsState = {
  accountIdDisplay?: string;
  accountType?: string;
  bankName?: string;
  externalId?: string;
  id?: string;
  isLoading: boolean;
  isVerifySuccessful: boolean;
  termsAgreement?: TermsAgreement;
  trialDeposits?: ExternalAccountTrialDeposits;
  verificationStatus?: VerificationStatus;
};

export type VerifyTrialDepositAction =
  | {
      type: 'ACTION_DISPLAY_VERIFY_TRIAL_DEPOSITS';
      payload: ExternalAccount;
    }
  | {
      type: 'ACTION_SUBMIT_VERIFY_TRIAL_DEPOSITS';
    }
  | {
      type: typeof ACTION_FAIL_VERIFY_TRIAL_DEPOSITS;
    }
  | {
      type: typeof ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS;
    }
  | {
      type: 'ACTION_FETCH_VERIFY_EXTERNAL_ACCOUNT_TERMS';
      payload: TermsAgreement;
    }
  | {
      type: 'ACTION_RESET_TRIAL_DEPOSIT_VERIFY_STATUS';
    }
  | {
      type: 'ACTION_INITIATE_VERIFY_TRIAL_DEPOSIT_STATE';
    }
  | {
      type: typeof ACTION_ADD_EXTERNAL_ACCOUNT_INSTANT_VERIFICATION_SUCCESS;
      payload: AddExternalAccountResponse;
    }
  | {
      type: 'ACTION_ADD_YODLEE_ACCOUNT_SUCCESS';
    };

function getInitialState(): VerifyTrialDepositsState {
  return {
    isLoading: false,
    isVerifySuccessful: false,
  };
}

export default (
  state: VerifyTrialDepositsState | null | undefined = getInitialState(),
  action: VerifyTrialDepositAction = undefined
): VerifyTrialDepositsState => {
  switch (action.type) {
    case 'ACTION_DISPLAY_VERIFY_TRIAL_DEPOSITS':
      return { ...state, ...action.payload };
    case 'ACTION_SUBMIT_VERIFY_TRIAL_DEPOSITS':
      return { ...state, isLoading: true };
    case ACTION_FAIL_VERIFY_TRIAL_DEPOSITS:
      return { ...state, isLoading: false };
    case ACTION_SUCCESS_VERIFY_TRIAL_DEPOSITS:
      return {
        ...state,
        isLoading: false,
        isVerifySuccessful: true,
      };
    case 'ACTION_FETCH_VERIFY_EXTERNAL_ACCOUNT_TERMS':
      return { ...state, termsAgreement: action.payload };
    case 'ACTION_RESET_TRIAL_DEPOSIT_VERIFY_STATUS':
      return {
        ...state,
        isVerifySuccessful: false,
      };
    case ACTION_ADD_EXTERNAL_ACCOUNT_INSTANT_VERIFICATION_SUCCESS:
      return { ...state, ...action.payload, isVerifySuccessful: true };
    case 'ACTION_ADD_YODLEE_ACCOUNT_SUCCESS':
      return { ...state, isVerifySuccessful: true };
    default:
      return state;
  }
};
