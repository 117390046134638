import {
  Button as MuiButton,
  withStyles,
  CircularProgress,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';
import React, { forwardRef } from 'react';
import type { ReactNode, Ref } from 'react';
import { makeStyles, StyleRules } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { YodleeAggregatorsResponseObject } from 'Containers/newAccountOpening/funding/fundNewAccount.service';
import type { FinalFormSubmit } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import colors from '../colors/colors';
import { ScreenReaderText } from '../typography/typography';

export const primaryButtonMargin = `${pxToRem(22)} ${pxToRem(16)} 0 0`;
const errorButtonMargin = `${pxToRem(22)} 0 0`;

/**
 * This should be importing from TypographyStyles.primaryButton.buttonText
 * but it is not detecting that property.
 * So we are hard-coding the value instead.
 */
const buttonTextFontSize = 16;

export const primaryButtonStyles = {
  backgroundColor: colors.blue700,
  fontSize: buttonTextFontSize,
  margin: primaryButtonMargin,
  // !important used to override MUI button style precedence in production
  // possibly caused by https://github.com/mui-org/material-ui/issues/16609
  color: `${colors.white} !important`,
  border: `solid 2px ${colors.blue700}`,
  boxShadow: 'none',
  '&:hover, &:focus': {
    backgroundColor: colors.hoverState,
    border: `solid 2px ${colors.hoverState}`,
  },
  '&:disabled': {
    backgroundColor: colors.buttonDisabledState,
    border: 'none',
    color: `${colors.buttonTextDisabled} !important`,
    '&:hover, &:focus': {
      backgroundColor: colors.buttonDisabledState,
    },
  },
  '&$focusVisible': {
    outlineColor: colors.blue800,
  },
};
const errorButton: StyleRules = {
  error: {
    ...primaryButtonStyles,
    backgroundColor: colors.brick,
    borderColor: colors.brick,
    margin: errorButtonMargin,
    '&:hover, &:focus': {
      backgroundColor: colors.brick,
      borderColor: colors.brick,
      opacity: 0.9,
    },
  },
  primary: {
    ...primaryButtonStyles,
    margin: errorButtonMargin,
  },
};

const useGreenButtonStyle = makeStyles((theme) => ({
  button: {
    background: colors.green,
    color: theme.palette.getContrastText(colors.green),
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.green,
    },
  },
}));

export type ButtonEvent =
  | ((
      event: React.MouseEvent<HTMLButtonElement>
    ) => void | Promise<void | YodleeAggregatorsResponseObject>)
  | FinalFormSubmit;

export interface ButtonProps extends MuiButtonProps {
  children: ReactNode;
  disabled?: boolean;
  id?: string;
  ['data-test']?: string;
  ['data-track-title']?: string;
  classes?: ClassNameMap;
  onClick?: ButtonEvent;
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';
  className?: string;
  component?: any;
  role?: string;
  style?: { [key: string]: string };
  href?: string;
}

type ErrorButtonProps = {
  isPrimary?: boolean;
};

type IconButtonProps = {
  startIcon?: ReactNode;
};

const loadingIndicatorStyles: StyleRules = {
  root: {
    color: colors.white,
    marginLeft: pxToRem(-12),
    marginRight: pxToRem(8),
  },
};

export const LoadingIndicator = withStyles(loadingIndicatorStyles)(
  (props: { classes: ClassNameMap }) => <CircularProgress classes={props.classes} size={20} />
);

// Blue button with white text
const PrimaryButton = ({ children, loading, ...otherProps }: ButtonProps) => (
  <>
    <MuiButton variant="contained" color="primary" disableRipple {...otherProps}>
      {loading && <LoadingIndicator />}
      {children}
    </MuiButton>
    {loading && (
      <ScreenReaderText aria-live="polite" role="status">
        Loading
      </ScreenReaderText>
    )}
  </>
);
PrimaryButton.displayName = 'PrimaryButton';

/** White button with blue text */
const ReversePrimaryButton = ({ children, loading, ...otherProps }: ButtonProps) => (
  <>
    <MuiButton variant="contained" color="default" disableRipple {...otherProps}>
      {loading && <LoadingIndicator />}
      {children}
    </MuiButton>
    {loading && (
      <ScreenReaderText aria-live="polite" role="status">
        Loading
      </ScreenReaderText>
    )}
  </>
);

const ErrorButton = withStyles(errorButton)(
  ({ isPrimary, classes = {}, ...otherProps }: ButtonProps & ErrorButtonProps) => (
    <PrimaryButton
      {...otherProps}
      classes={isPrimary ? { root: classes.primary } : { root: classes.error }}
    />
  )
);
ErrorButton.displayName = 'ErrorButton';

export const secondaryButtonStyles = {
  backgroundColor: 'transparent',
  color: colors.blue800,
  fontSize: pxToRem(16),
  border: `solid 2px ${colors.optimumBlue}`,
  margin: `${pxToRem(22)} ${pxToRem(16)} 0 0`,
  '&:hover': {
    color: colors.marineBlue,
    backgroundColor: 'transparent',
    border: `solid 2px ${colors.marineBlue}`,
  },
  '&:disabled': {
    color: colors.disabledState,
    backgroundColor: 'transparent',
    border: `solid 2px ${colors.disabledState}`,
  },
  '&$focusVisible': {
    border: 0,
    backgroundColor: colors.blue800,
    color: 'white',
  },
};

interface SecondaryButtonProps extends MuiButtonProps {
  children: ReactNode;
  onClick?: ButtonEvent;
  classes?: ClassNameMap;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  tabIndex?: number;
}

const loadingIndicatorDarkStyles: StyleRules = {
  root: {
    color: 'inherit',
    marginLeft: pxToRem(-12),
    marginRight: pxToRem(8),
  },
};

export const LoadingIndicatorDark = withStyles(loadingIndicatorDarkStyles)(
  ({ classes }: { classes: ClassNameMap }) => <CircularProgress classes={classes} size={20} />
);

/** transparent button with blue outline and text */
const SecondaryButton = forwardRef((props: SecondaryButtonProps, ref: Ref<HTMLButtonElement>) => {
  const { children, loading, ...otherProps } = props;
  return (
    <>
      <MuiButton variant="outlined" color="primary" disableRipple {...otherProps} ref={ref}>
        {loading && <LoadingIndicatorDark />}
        {children}
      </MuiButton>
      {loading && (
        <ScreenReaderText aria-live="polite" role="status">
          Loading
        </ScreenReaderText>
      )}
    </>
  );
});

export const ghostButtonStyles: StyleRules = {
  root: {
    fontSize: buttonTextFontSize,
    backgroundColor: 'transparent',
    color: colors.optimumBlue,
    borderRadius: '0px',
    border: 'none',
    '&:hover': {
      color: colors.hoverState,
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: `solid ${pxToRem(2)} ${colors.optimumBlue}`,
    },
    fontWeight: 'bold',
  },
};

const GhostButton = withStyles(ghostButtonStyles)(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { children, loading, ...otherProps } = props;
    return (
      <>
        <MuiButton {...otherProps} disableFocusRipple ref={ref}>
          {loading && <LoadingIndicatorDark />}
          {children}
        </MuiButton>
        {loading && (
          <ScreenReaderText aria-live="polite" role="status">
            Loading
          </ScreenReaderText>
        )}
      </>
    );
  })
);

const iconGhostButtonStyles: StyleRules = {
  root: ghostButtonStyles.root,
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: pxToRem(8),
  },
};

type IconGhostButtonProps = ButtonProps & {
  icon: ReactNode;
  className?: string;
  component?: keyof HTMLElementTagNameMap;
  htmlFor?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  tabIndex?: number;
};

const IconGhostButton = withStyles(iconGhostButtonStyles)(
  forwardRef((props: IconGhostButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { children, icon, loading, classes = {}, ...otherProps } = props;
    const spanIconClassName = icon ? classes.iconContainer : '';
    return (
      <>
        <MuiButton classes={{ root: classes.root }} {...otherProps} disableRipple ref={ref}>
          {loading ? <LoadingIndicatorDark /> : <span className={spanIconClassName}>{icon}</span>}
          {children}
        </MuiButton>
        {loading && (
          <ScreenReaderText aria-live="polite" role="status">
            Loading
          </ScreenReaderText>
        )}
      </>
    );
  })
);

const textButtonStyles: StyleRules = {
  root: {
    ...ghostButtonStyles.root,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

const TextButton = withStyles(textButtonStyles)((props: ButtonProps) => (
  <MuiButton disableRipple {...props} />
));

const GreenButton = ({ children, ...props }: ButtonProps & IconButtonProps) => {
  const styles = useGreenButtonStyle();
  return (
    <MuiButton className={styles.button} {...props}>
      {children}
    </MuiButton>
  );
};
export {
  PrimaryButton,
  ReversePrimaryButton,
  ErrorButton,
  SecondaryButton,
  GhostButton,
  TextButton,
  IconGhostButton,
  GreenButton,
};
