import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import type { ReactNode } from 'react';
import React from 'react';
import type { StyleProps } from '../../utilities/types';
import { NewAccountHeaderLinkText } from '../typography/typography';
import styles from '../header/headerIconLink.styles';

type LinkProps = {
  icon: ReactNode;
  linkText: string;
  to: string;
  ['data-test']?: string;
  ['data-track-title']?: string;
  external?: boolean;
  style?: {
    opacity?: number;
    textDecoration?: string;
  };
  isActive?: boolean;
  isExpanded: boolean;
  isHamburgerVisible: boolean;
};

const HeaderIconLinkWrapper = ({
  classes,
  'data-test': dataTest,
  'data-track-title': dataTrackTitle,
  external,
  icon,
  linkText,
  isActive = false,
  to,
  isExpanded,
  isHamburgerVisible,
  style = {},
}: LinkProps & StyleProps) => {
  const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <Link
      to={to}
      className={classes.openAccountHeaderLink}
      data-test={dataTest}
      data-track-title={dataTrackTitle}
      tabIndex={isHamburgerVisible && !isExpanded ? -1 : 0}
      style={style}
      {...externalProps}
    >
      {icon}
      <NewAccountHeaderLinkText isActive={isActive}>{linkText}</NewAccountHeaderLinkText>
    </Link>
  );
};

const headerIconLink = withStyles(styles)(HeaderIconLinkWrapper);
headerIconLink.displayName = 'IconLink';

export default headerIconLink;
