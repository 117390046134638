/* eslint-disable prefer-destructuring */
import * as ReduxFormConstants from '../form.constants';

export const AOFlow = Object.freeze({
  NAO: 'NAO',
  EAO: 'EAO',
});

export type AccountOpeningFlow = typeof AOFlow[keyof typeof AOFlow];

// TODO: Refactor imports in favor of using form.constants
export const NAO_FORM_ID = ReduxFormConstants.NAO_FORM_ID;
export const EAO_FORM_ID = ReduxFormConstants.EAO_FORM_ID;

export type AccountOpeningFormId = typeof NAO_FORM_ID | typeof EAO_FORM_ID | string;

export const isNAOFlow = (formId: AccountOpeningFormId): boolean => formId === NAO_FORM_ID;
export const isEAOFlow = (formId: AccountOpeningFormId): boolean => formId === EAO_FORM_ID;
