import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, CardContent } from '@material-ui/core';
import type { FlashMessageProperties } from '../flashMessage/flashMessage.reducer';
import type { ReduxState } from '../../reducers/index';
import { Header2 } from '../typography/typography';
import IgniteFlashMessage from '../flashMessage/IgniteFlashMessage';
import pxToRem from '../../utilities/pxToRem';
import Page from './page';
import Logo from '../logo/logo';
import {
  Alignment,
  defaultPadding,
  mediumPadding,
  largePadding,
} from '../../styles/layout/alignment.styles';
import type { Theme } from '../../utilities/types';
import Colors from '../colors/colors';

const useStyles = (cardWidth = 480) =>
  makeStyles((theme: Theme) => ({
    cardContainer: {
      height: '100%',
      padding: `${pxToRem(20)} 0`,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
      },
    },
    cardContentContainer: {
      boxShadow: 'none',
      backgroundColor: Colors.backgroundGrey,
      width: pxToRem(cardWidth),
      [theme.breakpoints.up('sm')]: {
        boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fafafa',
      },
    },
    cardContent: {
      padding: defaultPadding,
      [theme.breakpoints.up('sm')]: {
        padding: `${largePadding} ${mediumPadding}`,
      },
    },
    headerContainer: {
      marginBottom: pxToRem(24),
    },
    descriptionContainer: {
      marginBottom: pxToRem(16),
    },
    flashMessage: {
      marginBottom: '1rem',
      width: pxToRem(cardWidth),
    },
    logoContainer: {
      ...Alignment.headerPadding,
    },
    pageContent: {
      flexDirection: 'row',
    },
  }));

type StateProps = {
  flashMessage: FlashMessageProperties;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  flashMessage: state.flashMessage.pageFlashMessage,
});

type Props = {
  children?: React.ReactNode;
  loading?: boolean;
  cardWidthPx?: number;
  description?: string;
  headerText?: string;
  title?: string;
};

type AllProps = StateProps & Props;

const PageWithCard = ({
  cardWidthPx,
  children,
  description,
  flashMessage,
  headerText,
  loading,
  title,
}: AllProps) => {
  const classes = useStyles(cardWidthPx)();

  return (
    <Page
      hideHeader
      footerIsLight
      loading={loading}
      title={title || headerText}
      contentClassName={classes.pageContent}
    >
      <div className={classes.logoContainer}>
        <Logo isDark />
      </div>

      <Grid container alignContent="center" className={classes.cardContainer}>
        {flashMessage.messageText && (
          <IgniteFlashMessage
            variant={flashMessage.messageType}
            resetScroll
            className={classes.flashMessage}
          >
            {flashMessage.messageText}
          </IgniteFlashMessage>
        )}
        <Card square className={classes.cardContentContainer}>
          <CardContent className={classes.cardContent}>
            <>
              {headerText && (
                <Header2 component="h1" className={classes.headerContainer}>
                  {headerText}
                </Header2>
              )}
              {description && <p className={classes.descriptionContainer}>{description}</p>}
              {children}
            </>
          </CardContent>
        </Card>
      </Grid>
    </Page>
  );
};

export default connect(mapStateToProps)(PageWithCard);
