import {
  ACTION_FETCH_PROFILE_FAILURE,
  ACTION_FETCH_PROFILE_SUCCESS,
} from '../../containers/accountDashboard/profile.reducer';
import pageAnalytics from '../pageAnalytics';

const defaultProfileData = {
  account_type: 'generic',
  pagekind: 'account_settings',
  pagefunction: 'profile',
} as const;

const viewSuccess = () => {
  pageAnalytics
    .newPageView(defaultProfileData)
    .addInfo({
      pagename: 'view',
    })
    .record();
};

const viewFailure = () => {
  pageAnalytics
    .newPageView(defaultProfileData)
    .addInfo({
      pagename: 'view_error',
    })
    .record();
};

export const profilePageEvents = {
  [ACTION_FETCH_PROFILE_SUCCESS]: viewSuccess,
  [ACTION_FETCH_PROFILE_FAILURE]: viewFailure,
} as const;

export default profilePageEvents;
