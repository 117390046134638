import type { TransmitState } from '../../utilities/types';

export const UPDATE_TRANSMIT_STATUS = 'UPDATE_TRANSMIT_STATUS';
function getInitialState(): TransmitState {
  return {
    applicantId: [],
    applicationId: '',
    decision: '',
    iovationStatus: '',
    mfaStatus: '',
    status: {
      responseCode: '',
      responseDesc: '',
      transId: '',
    },
    token: undefined,
  };
}

const initialState = getInitialState();

export type ApplicationsTransmitAction = {
  type: typeof UPDATE_TRANSMIT_STATUS;
  payload: Record<string, string>;
};

export default (
  state: TransmitState | null | undefined = initialState,
  action: ApplicationsTransmitAction = undefined
): TransmitState => {
  if (action.type === UPDATE_TRANSMIT_STATUS) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};
