import { makeStyles } from '@material-ui/core/styles';
import Colors from '../colors/colors';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';

export default makeStyles((theme: Theme) => ({
  andOrCell: {
    fontWeight: 100,
    textTransform: 'uppercase',
  },
  circleSvg: {
    verticalAlign: 'middle',
    width: pxToRem(16),
    height: pxToRem(16),
    margin: 'auto',
  },
  column: {
    width: `calc(100% / 6)`,
    textAlign: 'left',
    paddingTop: pxToRem(25),
  },
  columnAwards: {
    width: `calc(100% / 6)`,
    textAlign: 'center',
    lineHeight: 2,
  },
  spacer: {
    height: pxToRem(24),
  },
  spacerLine: {
    position: 'relative',
    padding: pxToRem(8),
    '&:after': {
      content: '""',
      backgroundColor: Colors.lightBlue,
      position: 'absolute',
      width: '93%',
      left: 30,
      height: 1,
      bottom: 0,
    },
  },
  tableIcons: {
    '& *': {
      border: 'none',
    },
  },
  perksTableRows: {
    '& td': {
      textAlign: 'center',
    },
    '& td:not(:first-child)': {
      position: 'relative',
      '&:after': {
        content: '""',
        backgroundColor: Colors.lightBlue,
        position: 'absolute',
        width: '100%',
        left: 0,
        height: 1,
        bottom: 0,
      },
      // Need borders that don't go all the way to the edge, without adding non-table elements
      '&:first-child:after': {
        left: pxToRem(12),
        width: `calc(100% - ${pxToRem(12)})`,
        [theme.breakpoints.up('lg')]: {
          left: pxToRem(16),
          width: `calc(100% - ${pxToRem(16)})`,
        },
      },
      '&:last-child:after': {
        width: `calc(100% - ${pxToRem(12)})`,
        [theme.breakpoints.up('lg')]: {
          width: `calc(100% - ${pxToRem(16)})`,
        },
      },
    },
  },
  tableBody: {
    backgroundColor: Colors.white,
    boxShadow: `0 ${pxToRem(2)} ${pxToRem(20)} 0 rgba(0, 0, 0, 0.1)`,
    '& tr:last-child td': {
      paddingBottom: pxToRem(40),
      '&:after': {
        content: 'none',
      },
    },
    '& th:not(:first-child):not(:last-child), td:not(:first-child):not(:last-child)': {
      [theme.breakpoints.down('md')]: {
        paddingLeft: pxToRem(8),
        paddingRight: pxToRem(8),
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& tr:first-child > td': {
        paddingTop: pxToRem(24),
      },
      '& tr:last-child > td': {
        paddingBottom: pxToRem(24),
      },
      '& td:first-child': {
        paddingLeft: pxToRem(24),
      },
      '& td:last-child': {
        paddingRight: pxToRem(24),
      },
    },
  },
  tableHeader: {
    fontSize: `${pxToRem(20)} !important`,
    fontWeight: 'bold',
  },
  columnText: {
    fontSize: `${pxToRem(14)} !important`,
  },
  perkDescriptionCell: {
    [theme.breakpoints.only('sm')]: {
      hyphens: 'auto',
    },
  },
  rewardsTitle: {
    display: 'block',
  },
}));
