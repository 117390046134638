/* eslint-disable */
/* Surfly init script. Adapted from https://docs.surfly.com/tutorials/basic-integration */

const scriptSrc = `${window.__config__.SURFLY_URL}/surfly.js`;
const widget_key = window.__config__.SURFLY_WIDGET_KEY;

(function (s, u, r, f, l, y) {
  s[f] = s[f] || {
    init: function () {
      s[f].q = arguments;
    },
  };
  l = u.createElement(r);
  y = u.getElementsByTagName(r)[0];
  l.async = 1;
  l.src = scriptSrc;
  y.parentNode.insertBefore(l, y);
})(window, document, 'script', 'Surfly');

Surfly.init({ widget_key }, function (initResult) {
  if (!initResult.success) {
    console.warn('Surfly was unable to initialize properly.');
  }
});
