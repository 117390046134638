import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  getAccessToken,
  getTokenGivenName,
} from '../../utilities/authentication';
import type {
  AddressForm,
  ContactForm,
  Dispatch,
  GetState,
  OccupationForm,
  PartialCustomerInfo,
} from '../../utilities/types';
import {
  ACTION_FETCH_PROFILE_FAILURE,
  ACTION_FETCH_PROFILE_SUCCESS,
  ACTION_SET_PROFILE_LOADING_FALSE,
  ACTION_SET_PROFILE_LOADING_TRUE,
  ACTION_FORM_DIRTY_PREVENT_UNLOAD,
} from '../accountDashboard/profile.reducer';
import { fetchCustomerInfo } from '../newAccountOpening/applications.actions';
import type { PatchCustomerService } from '../newAccountOpening/customer.service';
import { patchCustomerService } from '../newAccountOpening/customer.service';
import { ACTION_FETCH_CUSTOMER_INFO_SUCCESS } from '../newAccountOpening/applications.reducer';
import {
  setFlashMessage,
  clearFlashMessage,
} from '../../components/flashMessage/flashMessage.reducer';
import {
  FlashMessageVariant,
  FlashMessageText,
} from '../../components/flashMessage/flashMessage.constants';
import { getStateList } from '../../actions/lists.actions';
import trimSuperfluousSpaces from '../../formatters/trimSuperfluousSpaces';
import { AddressFieldNames, OccupationFieldNames } from '../../form.constants';

const PRIMARY_PHONE_TYPE = 'EvePhone';
const SECONDARY_PHONE_TYPE = 'DayPhone';

const fetchProfileSuccess = () => ({
  type: ACTION_FETCH_PROFILE_SUCCESS,
  payload: { firstName: getTokenGivenName(getAccessToken(ACCESS_TOKEN_STORAGE_KEY)) },
});

const fetchProfileFailure = () => ({
  type: ACTION_FETCH_PROFILE_FAILURE,
  payload: {},
});

const setProfileLoadingTrue = () => ({
  type: ACTION_SET_PROFILE_LOADING_TRUE,
});

const setProfileLoadingFalse = () => ({
  type: ACTION_SET_PROFILE_LOADING_FALSE,
});

const setSuccessfulEditFlashMessage = () => (dispatch: Dispatch) =>
  dispatch(
    setFlashMessage({
      messageType: FlashMessageVariant.SUCCESS,
      messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
    })
  );

const setErrorEditFlashMessage =
  (messageText: string = FlashMessageText.EDIT_PROFILE_ERROR) =>
  (dispatch: Dispatch) =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText,
      })
    );

export const fetchProfileData =
  () =>
  async (dispatch: ThunkDispatch<null, null, AnyAction>): Promise<void> => {
    Promise.all([dispatch(setProfileLoadingTrue()), dispatch(fetchCustomerInfo())])
      .then(() => [dispatch(getStateList()), dispatch(fetchProfileSuccess())])
      .finally(() => dispatch(setProfileLoadingFalse()))
      .catch(() => dispatch(fetchProfileFailure()));
  };
export const editProfileData =
  (partialCustomerInfo: PartialCustomerInfo, service: PatchCustomerService) =>
  async (dispatch: Dispatch) => {
    const payload = await service(partialCustomerInfo);
    dispatch({
      type: ACTION_FETCH_CUSTOMER_INFO_SUCCESS,
      payload: { customerInfo: payload },
    });
    return payload;
  };

export const editProfileAddress =
  (address: AddressForm, service: PatchCustomerService = patchCustomerService) =>
  async (dispatch: ThunkDispatch<null, null, AnyAction>) => {
    const { streetAddress1, streetAddress2, city, state, zipCode } = address;
    const partialCustomerInfo = {
      addresses: [
        {
          // Necessary to pass in empty string instead of undefined for the PATCH request
          aptNumber: trimSuperfluousSpaces(streetAddress2) || '',
          city: trimSuperfluousSpaces(city),
          line1: trimSuperfluousSpaces(streetAddress1),
          state: trimSuperfluousSpaces(state),
          type: 'Primary',
          zipCode: trimSuperfluousSpaces(zipCode),
        },
      ],
    };

    return dispatch(editProfileData(partialCustomerInfo, service));
  };

export const editProfileContactInformation =
  (contactInformation: ContactForm, service: PatchCustomerService = patchCustomerService) =>
  async (dispatch: ThunkDispatch<null, null, AnyAction>) => {
    const {
      email,
      emailType,
      phoneNumber,
      phoneType,
      secondaryPhoneNumber,
      secondaryPhoneType,
      noPhoneNumber,
    } = contactInformation;
    const phones = [];
    if (phoneNumber) {
      phones.push({
        number: trimSuperfluousSpaces(phoneNumber),
        displayType: phoneType,
        type: PRIMARY_PHONE_TYPE,
      });
    }
    if (secondaryPhoneNumber) {
      phones.push({
        number: trimSuperfluousSpaces(secondaryPhoneNumber),
        displayType: secondaryPhoneType,
        type: SECONDARY_PHONE_TYPE,
      });
    }
    const partialCustomerInfo = {
      emailAddresses: [{ email: trimSuperfluousSpaces(email), type: emailType }],
      phones,
      noPhoneADA: noPhoneNumber,
    };

    return dispatch(editProfileData(partialCustomerInfo, service));
  };

export const editProfileOccupation =
  (occupationForm: OccupationForm, service: PatchCustomerService = patchCustomerService) =>
  async (dispatch: ThunkDispatch<null, null, AnyAction>) => {
    const { occupation, employerName } = occupationForm;
    const partialCustomerInfo = {
      employments: [
        { employerName: trimSuperfluousSpaces(employerName), occupation: { value: occupation } },
      ],
    };

    return dispatch(editProfileData(partialCustomerInfo, service));
  };

// Mapping of the BSL validation response field to the corresponding field names
// in the form.
const errorToField = Object.freeze({
  'addresses[0].unitNumber': AddressFieldNames.STREET_ADDRESS_2,
  'addresses[0].city': AddressFieldNames.CITY,
  'addresses[0].line1': AddressFieldNames.STREET_ADDRESS_1,
  'addresses[0].state': AddressFieldNames.STATE,
  'addresses[0].zipCode': AddressFieldNames.ZIP_CODE,
  'employments[0].employer': OccupationFieldNames.EMPLOYER_NAME,
  'employments[0].occupation.value': OccupationFieldNames.OCCUPATION,
  'phones[0].number': AddressFieldNames.PHONE_NUMBER,
  'phones[0].displayType': AddressFieldNames.PHONE_TYPE,
  'phones[1].number': AddressFieldNames.SECONDARY_PHONE_NUMBER,
  'phones[1].displayType': AddressFieldNames.SECONDARY_PHONE_TYPE,
  'emailAddresses[0].email': AddressFieldNames.EMAIL,
});

type ValidationErrorField = keyof typeof errorToField;
type ValidationError = {
  field: ValidationErrorField;
  value: string;
};

type EditProfileField = typeof errorToField[keyof typeof errorToField];
type EditProfileValidationError = Partial<Record<EditProfileField, string>>;

const parseFieldErrors = (errors: ValidationError[]): EditProfileValidationError =>
  errors.reduce<Record<string, string>>((parsedErrors, error) => {
    const field = errorToField[error.field];
    if (field) {
      return { ...parsedErrors, [field]: error.value };
    }
    return parsedErrors;
  }, {});

export const editProfile =
  (editProfileAction: () => Promise<PartialCustomerInfo>, onCompleteEditing: () => void) =>
  async (dispatch: ThunkDispatch<null, null, AnyAction>) => {
    dispatch(clearFlashMessage());
    try {
      await editProfileAction();
    } catch (err) {
      const { data = {} } = err || {};
      // Field Validation Errors
      if (data.details && data.details.errors && Object.keys(data.details.errors).length) {
        return parseFieldErrors(data.details.errors);
      }
      dispatch(setErrorEditFlashMessage(data.message));
      return {};
    }
    dispatch(setSuccessfulEditFlashMessage());
    onCompleteEditing();
    return {};
  };

export const updateFormStatus =
  (isDirty: boolean) => async (dispatch: Dispatch, getState: GetState) => {
    if (isDirty !== getState().profile.isFormDirtyPreventUnload) {
      dispatch({ type: ACTION_FORM_DIRTY_PREVENT_UNLOAD, payload: isDirty });
    }
  };
