import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  fetchLoggedInState,
  startTimer,
  listenForRefreshCode,
} from './containers/authenticate/authenticate.actions';
import { isSessionIDValid, setSessionID } from './utilities/sessionID';
import { fetchDowntime } from './containers/accountDashboard/accountDashboard.actions';
import pageAnalytics from './analytics/pageAnalytics';

const bootstrap = () => (dispatch: ThunkDispatch<null, null, AnyAction>) => {
  if (!isSessionIDValid()) setSessionID();
  pageAnalytics.initSFDDL();
  dispatch(startTimer());
  dispatch(listenForRefreshCode());
  dispatch(fetchDowntime());
  dispatch(fetchLoggedInState());

  if (
    window.__config__.WIREMOCK_ENABLED === 'true' &&
    (window.location.search.includes('wiremock') || Boolean(sessionStorage.getItem('wiremock')))
  ) {
    sessionStorage.setItem('wiremock', '1'); // persist wiremock for session
    window.__config__.API_BASE_URL = 'https://dev-ignite.synchronycredit.com/wiremock';
    window.__config__.AUTH_BASE_URL = 'https://dev-ignite.synchronycredit.com/wiremock';
  }

  if (window.__config__.EDIT_CONFIG_ENABLED !== 'true') {
    Object.freeze(window.__config__);
  }

  // start new relic and ensure versioning works
  if (
    window.newrelic &&
    window.__config__.WEB_VERSION &&
    window.__config__.WEB_VERSION !== 'LOCAL' &&
    !window.__config__.WEB_VERSION.startsWith('CF_')
  ) {
    window.newrelic.addRelease('SYB-Ignite-Web', window.__config__.WEB_VERSION);
  }
};

export default bootstrap;
