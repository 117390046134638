import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import type { StyleProps } from '../../utilities/types';
import { ArrowLinkCommonStyles } from '../buttons/arrowLinkButton';
import Colors from '../colors/colors';
import type { ArrowDirectionValue, ArrowPositionValue } from './arrow';
import Arrow, { ArrowDirection, ArrowPosition } from './arrow';
import { boldFontWeight } from '../typography/typography.styles';
import pxToRem from '../../utilities/pxToRem';

const useStyles = makeStyles({
  ...ArrowLinkCommonStyles,
  linkContainer: {
    '&:hover': {
      textDecoration: 'underline',
      color: Colors.hoverState,
    },
  },
  // This is duplicated from typography linkText
  linkText: {
    fontSize: pxToRem(14),
    fontWeight: boldFontWeight,
    lineHeight: 1.29,
    letterSpacing: pxToRem(1.2),
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: Colors.optimumBlue,
    '&:hover': {
      color: Colors.hoverState,
    },
  },
});

export type ArrowLinkProps = {
  to: string | Location;
  children: string;
  className?: string;
  onClick?: MouseEventHandler;
  textColor?: string;
  position?: ArrowPositionValue;
  arrowDirection?: ArrowDirectionValue;
  role?: string;
  tabIndex?: number;
  target?: string;
  rel?: string;
};

const isInternalLink = (to: string | Location): boolean => {
  if (typeof to === 'string') {
    return to.startsWith('/');
  }
  return !!to.pathname && to.pathname.startsWith('/');
};

const ArrowLink = ({
  children,
  classes,
  className,
  onClick,
  position = ArrowPosition.RIGHT,
  arrowDirection = ArrowDirection.RIGHT,
  to,
  ...otherProps
}: ArrowLinkProps & StyleProps) => {
  const styles = useStyles();
  const arrow = <Arrow position={position} direction={arrowDirection} />;

  if (isInternalLink(to)) {
    return (
      <Link
        to={to}
        className={classnames(
          styles.linkContainer,
          styles.linkText,
          classes?.arrowLinkText,
          className
        )}
        {...otherProps}
      >
        {position === ArrowPosition.LEFT ? arrow : null}
        <span>{children}</span>
        {position === ArrowPosition.RIGHT ? arrow : null}
      </Link>
    );
  }
  return (
    <a
      href={typeof to === 'string' ? to : to.pathname}
      onClick={onClick}
      className={classnames(
        styles.linkContainer,
        styles.linkText,
        classes?.arrowLinkText,
        className
      )}
      rel="noreferrer"
      target="_blank"
      {...otherProps}
    >
      {position === ArrowPosition.LEFT ? arrow : null}
      <span>{children}</span>
      {position === ArrowPosition.RIGHT ? arrow : null}
    </a>
  );
};

export default ArrowLink;
