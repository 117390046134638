import pxToRem from '../../utilities/pxToRem';

export default () =>
  ({
    '@keyframes loading-bar1-grow': {
      '0%, 10%': {
        transform: 'scaleY(0)',
      },
      '20%, 70%': {
        transform: 'scaleY(1)',
      },
      '80%, 100%': {
        transform: 'scaleY(0)',
      },
    },
    '@keyframes loading-bar2-grow': {
      '0%, 18%': {
        transform: 'scaleY(0)',
      },
      '30%, 78%': {
        transform: 'scaleY(1)',
      },
      '90%, 100%': {
        transform: 'scaleY(0)',
      },
    },
    '@keyframes loading-bar3-grow': {
      '0%, 28%': {
        transform: 'scaleY(0)',
      },
      '40%, 88%': {
        transform: 'scaleY(1)',
      },
      '100%': {
        transform: 'scaleY(0)',
      },
    },
    loadingIndicatorContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    loadingIndicator: {
      display: 'flex',
      justifyContent: 'center',
    },
    loadingBar: {
      backgroundColor: '#fbc600',
      marginRight: pxToRem(3),
      width: pxToRem(10),
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
      animationDuration: '1720ms',
    },
    loadingBar1: {
      height: pxToRem(26),
      animationName: '$loading-bar1-grow',
      alignSelf: 'flex-end',
    },
    loadingBar2: {
      animationName: '$loading-bar2-grow',
      height: pxToRem(54),
    },
    loadingBar3: {
      animationName: '$loading-bar3-grow',
      height: pxToRem(26),
      marginRight: 0,
    },
    loadingIndicatorText: {
      textAlign: 'center',
    },
  } as const);
