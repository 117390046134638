import React from 'react';
import Button from 'bank-component-library/ui/atoms/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
  PreferencesTableHeaders,
  ETaxDocumentPreferencesFieldNames,
  EDeliveryStatus,
  ETaxDocumentPreferencesConstant,
} from '../../containers/ePreferences/ePreferences.constants';
import { ModalBtnText } from '../cms/buttonText.constants';
import IgniteModal, { IgniteModalActions, IgniteModalContent } from './igniteModal';
import { RadioButtonTable, renderElectronicDeliveryCheck } from '../finalForm/finalFormInputs';
import { StandardText, StandardTextEmphasis } from '../typography/typography';
import pxToRem from '../../utilities/pxToRem';
import { isChecked } from '../../containers/newAccountOpening/validators';
import Colors from '../colors/colors';
import type {
  ETaxDocumentPreferences,
  ETaxDocumentPreferencesFormData,
} from '../../containers/ePreferences/ePreferences.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';

type Props = {
  closeModal: () => void;
  visible: boolean;
  taxDocumentPreferences: ETaxDocumentPreferences;
  onSubmit: (formData: ETaxDocumentPreferencesFormData) => Promise<void>;
  error?: string;
};

const useStyle = makeStyles({
  optionTable: {
    marginTop: pxToRem(40),
    marginBottom: pxToRem(40),
    '& td, th': {
      border: 'none',
      padding: pxToRem(0),
    },
    // Center everything except the Account column
    '& td:not(:first-child),th:not(:first-child)': {
      textAlign: 'center',
    },
  },
  checkboxContainer: {
    fontSize: pxToRem(12),
  },
  errorText: {
    color: Colors.inlineError,
    fontSize: pxToRem(12),
    marginBottom: pxToRem(20),
  },
});

export const ManageTaxDocumentModal = ({
  closeModal,
  visible,
  taxDocumentPreferences,
  onSubmit,
  error,
}: Props) => {
  const classes = useStyle();

  const allAccountsFieldOptions = [
    {
      value: EDeliveryStatus.NOT_ENROLLED,
      ariaLabel: `${ETaxDocumentPreferencesConstant.FIELD_LABEL} - ${PreferencesTableHeaders.MAIL_AND_ONLINE}`,
    },
    {
      value: EDeliveryStatus.ENROLLED,
      ariaLabel: `${ETaxDocumentPreferencesConstant.FIELD_LABEL} - ${PreferencesTableHeaders.ONLINE_ONLY}`,
    },
  ];

  const buildInitialValues: ETaxDocumentPreferencesFormData = {
    [ETaxDocumentPreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED]:
      taxDocumentPreferences.areTermsAccepted,
    [ETaxDocumentPreferencesFieldNames.E_DELIVERY_STATUS]: taxDocumentPreferences.edeliveryStatus,
  };

  return (
    <IgniteModal
      title={ETaxDocumentPreferencesConstant.MODAL_TITLE}
      visible={visible}
      onBackdropClick={closeModal}
      data-test="tax-preference-modal"
      onEscapeKeyDown={closeModal}
    >
      {error && (
        <StandardText data-test="tax-preference-error" className={classes.errorText}>
          {error}
        </StandardText>
      )}
      <IgniteModalContent variant="text">
        {ETaxDocumentPreferencesConstant.MODAL_SUB_TITLE}
      </IgniteModalContent>
      <IgniteModalContent>
        <Form
          data-test="tax-preference-form"
          onSubmit={onSubmit}
          initialValues={buildInitialValues}
        >
          {({ invalid, handleSubmit, submitting, values = {}, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Table className={classes.optionTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        <StandardTextEmphasis>
                          {PreferencesTableHeaders.MAIL_AND_ONLINE}
                        </StandardTextEmphasis>
                      </TableCell>
                      <TableCell>
                        <StandardTextEmphasis>
                          {PreferencesTableHeaders.ONLINE_ONLY}
                        </StandardTextEmphasis>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      <Field
                        type="radio"
                        label={ETaxDocumentPreferencesConstant.FIELD_LABEL}
                        name={ETaxDocumentPreferencesFieldNames.E_DELIVERY_STATUS}
                        options={allAccountsFieldOptions}
                        component={RadioButtonTable}
                        ariaLabelledBy="funds-adjustment-header"
                        selectedValue={values[ETaxDocumentPreferencesFieldNames.E_DELIVERY_STATUS]}
                        isHeaderRequired
                      />
                      <OnChange name={ETaxDocumentPreferencesFieldNames.E_DELIVERY_STATUS}>
                        {(value) => {
                          form.change(
                            ETaxDocumentPreferencesFieldNames.E_DELIVERY_STATUS as keyof ETaxDocumentPreferencesFormData,
                            value
                          );
                        }}
                      </OnChange>
                    </>
                  </TableBody>
                </Table>
                <Grid item>
                  <Field
                    className={classes.checkboxContainer}
                    labelId={`${ETaxDocumentPreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED}-ModalLabel`}
                    id={ETaxDocumentPreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED}
                    name={ETaxDocumentPreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED}
                    component={renderElectronicDeliveryCheck}
                    type="checkbox"
                    validate={isChecked}
                    required
                  />
                </Grid>
                <IgniteModalActions isCL>
                  <Button
                    variant="primary-outlined"
                    data-test="confirmation-modal-cancel-button"
                    onClick={closeModal}
                    data-track-page={clickTrack.ePreferences.view}
                    data-track-title={clickTrack.ePreferences.documents_cancel}
                    data-track-type={clickTrackType.BUTTON}
                  >
                    {ModalBtnText.CANCEL_GENERIC}
                  </Button>
                  <Button
                    data-test="confirmation-modal-primary-button"
                    isLoading={submitting}
                    disabled={invalid || submitting}
                    onClick={handleSubmit}
                    data-track-page={clickTrack.ePreferences.view}
                    data-track-title={clickTrack.ePreferences.documents_save}
                    data-track-type={clickTrackType.BUTTON}
                  >
                    {ModalBtnText.SAVE}
                  </Button>
                </IgniteModalActions>
              </form>
            );
          }}
        </Form>
      </IgniteModalContent>
    </IgniteModal>
  );
};

export default ManageTaxDocumentModal;
