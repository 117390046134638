import React from 'react';
import shouldPixelRender from '../../../containers/pixelWhitelisting/pixel.utils';
import { ArtisaiConst, pixelAllowedRoutes } from './artisaiEvent.constants';

const ArtisaiEvent = ({ currentLocation }) => {
  const { ARTISAI_SRC } = ArtisaiConst;
  return shouldPixelRender(pixelAllowedRoutes, currentLocation) ? (
    <img
      src={ARTISAI_SRC}
      width="1"
      height="1"
      alt="artisai_event"
      style={{ visibility: 'hidden' }}
    />
  ) : null;
};

export default ArtisaiEvent;
