import { StyleRules } from '@material-ui/core';
import breakpoint from '../../styles/breakpoints';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';

export default {
  rootPrint: {
    backgroundColor: Colors.white,
    borderTop: `${pxToRem(1)} solid ${Colors.lightGray}`,
    borderLeft: `${pxToRem(1)} solid ${Colors.lightGray}`,
    borderRight: `${pxToRem(1)} solid ${Colors.lightGray}`,
    '&:last-child': {
      borderBottom: `${pxToRem(1)} solid ${Colors.lightGray}`,
    },
  },
  '@media print': {
    rootPrint: {
      '&:first-child': {
        borderTop: 'none',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      position: 'relative',
      display: 'block',
    },
    tileListContainer: {
      display: 'block !important',
    },
  },
  root: {
    position: 'relative',
    marginBottom: pxToRem(20),
    '&:last-child': {
      marginBottom: pxToRem(0),
    },
    [breakpoint.down('md')]: {
      marginBottom: pxToRem(12),
      padding: `0 ${pxToRem(32)} 0`,
    },
    [breakpoint.down('sm')]: {
      padding: `0 ${pxToRem(16)} 0`,
    },
  },
  tileListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tileListHeader: {
    display: 'block',
  },
} as StyleRules;
