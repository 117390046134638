import React from 'react';
import NarrowLeftPanelGridItem from '../pageLayout/NarrowLeftPanelGridItem';
import SidebarLink from '../links/sidebarLink';

import type { NavProps } from './nav.types';

const NavDesktop = ({ links, selected }: NavProps) => {
  const renderLink = (link) => {
    const active = link === selected;

    const child = (
      <SidebarLink
        key={link.to}
        to={link.to}
        data-test={link.dataTest}
        active={active}
        data-track-type={link.dataTrackType}
        data-track-title={link.dataTrackTitle}
        aria-current={active ? 'page' : undefined}
      >
        {link.label}
      </SidebarLink>
    );

    return <li key={link.dataTest}>{child}</li>;
  };

  return (
    <NarrowLeftPanelGridItem>
      <ul>{links.map(renderLink)}</ul>
    </NarrowLeftPanelGridItem>
  );
};

export default NavDesktop;
