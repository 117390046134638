import axios from '../../utilities/axios';
import { RENEWAL_INSTRUCTIONS_URL } from '../../utilities/route-mappings';

export type ReInvestmentProductRequest = {
  productId: string;
};

export type ReInvestmentProductResponse = {
  productType: string;
  productDisplayType: string;
  productId: string;
  productName: string;
  minOpenAmount: number;
  maxOpenAmount: number;
};

type InternalRenewalProductResponse = {
  productType: string;
  productDisplayType: string;
  productId: string;
  productName: string;
};

export type ExternalTransferAccountRequest = {
  externalId: string;
  accountId: string;
  accountType: string;
};

export type ExternalTransferAccountResponse = {
  accountId?: string;
  externalId?: string;
  accountType: string;
  routingNumber: string;
  accountDisplayId: string;
  bankName: string;
  nickname: string;
};

export type InternalTransferAccountRequest = {
  accountId: string;
  accountType: string;
};

export type InternalTransferAccountResponse = ExternalTransferAccountResponse & {
  product: InternalRenewalProductResponse;
};

export const AdjustmentType = Object.freeze({
  UNCHANGED: 'No Changes to Funds',
  ADD: 'Add Funds',
  WITHDRAW: 'Withdraw Funds',
});

export type AdjustmentTypeValue = typeof AdjustmentType[keyof typeof AdjustmentType];

export const TransferType = Object.freeze({
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
  PAY_BY_CHECK: 'Pay by check',
});

export type TransferTypeValue = typeof TransferType[keyof typeof TransferType];

type AdjustmentRequest = {
  adjustmentType: AdjustmentTypeValue;
  amount?: string;
  transferType?: TransferTypeValue;
  transferAccount?: InternalTransferAccountRequest | ExternalTransferAccountRequest;
};

export type AdjustmentResponse = {
  adjustmentType: AdjustmentTypeValue;
  amount?: string;
  transferType?: TransferTypeValue;
  transferAccount?: ExternalTransferAccountResponse | InternalTransferAccountResponse;
};

export type AddRenewalInstructionsRequest = {
  reinvestmentProduct: ReInvestmentProductRequest;
  fundsAdjustment: AdjustmentRequest;
};

export type RenewalInstructionsResponse = {
  reinvestmentProduct?: ReInvestmentProductResponse;
  fundsAdjustment?: AdjustmentResponse;
  tentativeMaturityDate?: string;
};

export const getRenewalInstructionsService = (
  type: string,
  accountId: string
): Promise<RenewalInstructionsResponse> =>
  axios.get(RENEWAL_INSTRUCTIONS_URL(type, accountId)).then((res) => res.data);

export const addRenewalInstructionsService = (
  type: string,
  accountId: string,
  renewalInfo: AddRenewalInstructionsRequest
): Promise<RenewalInstructionsResponse> =>
  axios.post(RENEWAL_INSTRUCTIONS_URL(type, accountId), renewalInfo).then((res) => res.data);

export const updateRenewalInstructionsService = (
  type: string,
  accountId: string,
  renewalInfo: AddRenewalInstructionsRequest
): Promise<RenewalInstructionsResponse> =>
  axios.put(RENEWAL_INSTRUCTIONS_URL(type, accountId), renewalInfo).then((res) => res.data);

export const removeRenewalInstructionsService = (
  type: string,
  accountId: string
): Promise<RenewalInstructionsResponse> =>
  axios.delete(RENEWAL_INSTRUCTIONS_URL(type, accountId)).then((res) => res.data);
