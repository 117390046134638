import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ModalText } from '../cms/blockText.constants';
import IgniteModal, { IgniteModalContent, IgniteModalActions } from './igniteModal';
import { SecondaryButton, primaryButtonMargin } from '../buttons/buttons';
import { ModalBtnText } from '../cms/buttonText.constants';
import ButtonLink from '../links/buttonLink';
import { clickTrackType } from '../../analytics/clickTracking.constants';

const useButtonLinkStyles = makeStyles(() => ({
  buttonLink: {
    margin: primaryButtonMargin,
  },
}));

export type LeavingSiteConfirmationModalProps = {
  onExit: () => void;
  to: string;
  visible: boolean;
  confirmText?: string;
  ['data-track-title']?: string;
  ['data-track-page']?: string;
  modalBody?: string;
};

export const LeavingSiteConfirmationModal = (props: LeavingSiteConfirmationModalProps) => {
  const buttonLinkStyles = useButtonLinkStyles();
  return (
    <IgniteModal
      visible={props.visible}
      title={ModalText.LEAVING_SITE_WARNING_MODAL_TITLE}
      data-test="confirmation-modal"
      onEscapeKeyDown={props.onExit}
    >
      <IgniteModalContent variant="text">
        {props.modalBody || ModalText.LEAVING_SITE_WARNING_MODAL_BODY}
      </IgniteModalContent>
      <IgniteModalActions>
        <SecondaryButton data-test="confirmation-modal-cancel-button" onClick={props.onExit}>
          {ModalBtnText.CANCEL_GENERIC}
        </SecondaryButton>
        <ButtonLink
          classes={buttonLinkStyles}
          data-track-title={props['data-track-title']}
          data-track-type={clickTrackType.BUTTON}
          data-track-page={props['data-track-page']}
          to={props.to}
          external
          newTab
          onClick={props.onExit}
        >
          {props.confirmText || ModalBtnText.OK_BTN}
        </ButtonLink>
      </IgniteModalActions>
    </IgniteModal>
  );
};

export default LeavingSiteConfirmationModal;
