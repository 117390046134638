import { SynchronyInfo } from '../cms/blockText.constants';

export type POBOX = {
  poboxnumber: string;
  city: string;
  state: string;
  stateCode: string;
  zip: string;
};

export const fullAddress = `${SynchronyInfo.ADDRESS_POBOX}, ${SynchronyInfo.ADDRESS_CITY}, ${SynchronyInfo.ADDRESS_STATE} ${SynchronyInfo.ADDRESS_ZIP}`;

export const capitalAccountTypeDisplayName = (accountTypeDisplayName) =>
  accountTypeDisplayName?.toUpperCase();

export const encryptedAccountId = (accountIdDisplay) =>
  accountIdDisplay ? accountIdDisplay.replaceAll('●', 'XXXX') : '';

export const fullAddressOWCS = (poOWCS) =>
  `${poOWCS?.poboxnumber}, ${poOWCS?.city}, ${poOWCS?.statecode} ${poOWCS?.zip}`;
