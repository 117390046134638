export const clickTrack = Object.freeze({
  header: {
    icon: 'synchrony_icon',
    inbox: 'inbox_ss',
    inboxMenu: 'inbox',
    cancelCard: 'cancel_card',
    documents: 'documents',
    editProfile: 'edit_profile',
    statements: 'statements',
    alertsOffers: 'account_alerts_ss',
    rewards: 'rewards_ss',
    security: 'security',
    alerts: 'account_alerts',
    beneficiaries: 'beneficiaries',
    message_center_inbox: 'message_center_inbox',
    go_paperless: 'go_paperless',
    atm_locator: 'atm_locator',
    compose_new_message_from_header: 'compose_new_message_from_header',
  },

  footer: {
    terms: 'online_terms',
    privacy: 'privacy',
    privacy_policy: 'privacy_policy',
    disclosures: 'disclosures',
    coBrowse: 'co-browse',
    contact: 'contact_us',
    faq: 'faq',
    fdic: 'member_fdic',
  },

  general: {
    continue: 'continue',
    next: 'next_step',
    done: 'done',
    doneEditing: 'done_editing',
    back: 'go_back',
    cancel: 'cancel',
    submit: 'submit',
    landing_page: 'landing_page',
  },

  accountActivity: {
    alert_pencil: 'alert_pencil',
    banner_bump: 'banner_bump',
    beneficiaries: 'manage_beneficiaries_ss',
    checks_proceed: 'checks_proceed',
    deposit_slips_proceed: 'deposit_slips_proceed',
    edit_nick_name: 'edit_nick_name',
    interest_payments: 'interest_payments',
    make_a_transfer: 'make_a_transfer',
    manage_overdraft_protection: 'manage_overdraft_protection',
    order_checks: 'order_checks',
    order_deposit_slips: 'order_deposit_slips',
    order_modal: 'order_modal',
    reveal_account_number: 'reveal_account_number',
    statements: 'statements',
    stop_check_payment: 'stop_check_payment_ss',
    view_check_image: 'view_check_image',
    update_options: 'update_options',
    print_transactions: 'print_transactions',
    download_transactions: 'download_transactions',
  },

  account: {
    open_new_account: 'open_new_account',
    // account-tile has a dynamic title
  },

  accountDashboard: {
    view_dashboard: 'view_dashboard',
    cms_image_tile_link_title: 'tile_image',
    cms_image_tile_link: 'flash-cms-alert-image-link',
  },

  accountManagement: {
    my_profile: 'my_profile',
    security: 'security',
    account_alerts_ss: 'account_alerts_ss',
    beneficiaries: 'beneficiaries',
    go_paperless: 'go_paperless',
    atm_cards: 'atm_cards',
    account_alerts: 'account_alerts',
    offers: 'offers',
    combined_statements: 'combined_statements',
  },

  accountOpening: {
    add_new_joint_owner: 'add_new_joint_owner',
    create_account: 'create_account',
    cancel: 'cancel_exit_application',
    continue: 'continue_application',
    doneEdit: 'done_editing',
    edit_joint_owner: 'edit_joint_owner',
    edit_primary_owner: 'edit_primary_owner',
    edit_product_selection: 'edit_product_selection',
  },

  addExternalAccount: {
    agreement: 'authorization_agreement_ach',
    add_account: 'add_account',
  },
  chooseFunding: {
    next_step_it: 'next_step_it',
    next_step_exist: 'next_step_exist',
    next_step_et_yodlee: 'next_step_et_yodlee',
    next_step_et_ews: 'next_step_et_ews',
    next_step_check: 'next_step_check',
  },
  addExternalAccountError: {
    sign_in_with_yodlee: 'sign_in_with_yodlee',
    try_new_account_ews: 'try_new_account_ews',
    cancel_go_to_dashboard: 'cancel_go_to_dashboard',
    mail_a_check: 'mail_a_check',
  },
  cdBumpUp: {
    bump: 'bump',
    no_bump: 'no_bump',
  },

  fundAmount: {
    funds_availability: 'funds_availability_and_cut_off',
  },

  reviewFunding: {
    submit_funding: 'submit_funding',
  },

  ePreferences: {
    revert_changes: 'revert_changes',
    view: 'view',
    save_changes: 'save_changes',
    tax_documents: 'tax_documents',
    documents_save: 'documents_save',
    documents_cancel: 'documents_cancel',
  },

  rewards: {
    terms_and_conditions: 'terms_and_conditions',
    travel: 'travel',
  },

  accountAlerts: {
    cancel_changes: 'cancel_changes',
    view: 'view',
  },

  beneficiaries: {
    cancel_changes: 'cancel_changes',
    view: 'view',
    nav_to_beneficiaries: 'nav_to_beneficiaries',
    landing_page: 'landing_page,',
  },

  externalAccountDashboard: {
    add_new_bank: 'add_new_bank',
    edit: 'edit',
    edit_cancel: 'edit_cancel',
    edit_save: 'edit_save',
    reinitiate_trial_deposits: 'reinitiate_trial_deposits',
    remove_external_accounts: 'remove_external_accounts',
    remove_pending_verification: 'remove_pending_verification',
    view_transfers: 'view_transfers',
    verify_trial_deposits: 'verify_trial_deposits',
  },

  chooseExternalAccountOption: {
    next_step_ews: 'next_step_ews',
    next_step_yodlee: 'next_step_yodlee',
  },

  cdInterestPayments: {
    cancel_changes: 'cancel_changes',
    view: 'view',
    remove_interest_account: 'remove_interest_account',
    start: 'start',
  },

  cdRenewal: {
    cancel: 'cancel',
    new: 'new',
    no_changes: 'no_changes',
    remove_instructions: 'remove_instructions',
  },

  cdGracePeriod: {
    grace_period_alert: 'banner_gp',
    update_instruction_gp: 'update_instructions_gp',
    start: 'start',
    cancel: 'cancel',
  },

  documents: {
    load_more_documents: 'load_more_documents',
    view_document: 'view_document',
    view: 'view',
  },

  overdraftProtection: {
    open_new_account: 'open_new_account',
  },

  otp: {
    resend_now: 'resend_now',
  },

  messages: {
    pencil: 'pencil_cms',
    image_cms: 'tile_image',
    text_cms: 'tile_message',
    edit_email_prompt: 'edit_email_prompt',
    go_paperless_prompt: 'go_paperless_prompt',
    landing_page: 'landing_page',
  },

  newTransfer: {
    add_new_external_bank: 'add_new_external_bank',
    funds_availability: 'funds_availability_and_cut_off',
  },

  products: {
    explore_products: 'explore_products',
  },

  profile: {
    add_email_address: 'add_email_address',
    add_address: 'add_address',
    edit_address: 'edit_address',
    edit_contact: 'edit_contact',
    edit_professional: 'edit_professional',
    save_address: 'save_address',
    save_contact: 'save_contact',
    save_professional: 'save_professional',
  },
  security: {
    change_username_cancel: 'change_username_cancel',
    change_password_cancel: 'change_password_cancel',
    change_username_edit: 'change_username_edit',
    change_password_edit: 'change_password_edit',
  },
  selectAProduct: {
    rates: 'rates_terms',
  },
  ratesAndTerms: {
    accountOpening: 'account_opening',
    exploreProducts: 'savings_accounts',
    rates: 'rates_terms_page',
    view: 'view',
  },
  statements: {
    view_statement: 'view_statement',
    account_alerts_ss: 'account_alerts_ss',
    go_paperless: 'go_paperless',
    offers: 'offers',
    account_alerts: 'account_alerts',
    opt_out_combined: 'opt_out_combined',
    view: 'view',
    submit: 'submit',
    cancel: 'cancel',
  },

  transfers: {
    new_transfer: 'new_transfer',
    manage_external_accounts: 'manage_external_accounts',
    add_external_accounts: 'add_external_accounts',
    cancel_recurring: 'remove_transfer',
    edit_transfer_start: 'edit_transfer_start',
    cancel: 'cancel',
    confirm: 'confirm',
    start: 'start',
    edit_transfer: 'edit_transfer',
  },

  transfersConfirmation: {},

  verifyTransfer: {
    complete: 'complete_transfer',
    cancel: 'cancel',
  },

  verifyTrialDeposits: {
    verify_account: 'verify_account',
    // using general cancel title
    done: 'done',
  },
  depositInstructions: {
    view_dashboard: 'view_dashboard',
  },

  viewInbox: {
    compose_new_message: 'compose_new_message',
    compose_reply: 'compose_reply',
    landing_page: 'landing_page',
  },
  newMessage: {
    cancel_new_message: 'cancel_new_message',
    submit_new_message: 'submit_new_message',
  },
  replyMessage: {
    cancel_reply: 'cancel_reply',
    submit_reply: 'submit_reply',
    view_attachment: 'view_attachment',
    add_attachment: 'add_attachment',
  },
});

export const clickTrackType = Object.freeze({
  BUTTON: 'button',
  RADIO_BUTTON: 'radio-button',
  LINK: 'link',
  ICON: 'icon',
  BLANK: '',
});

export type DataTrackTypes =
  | 'data-track-title'
  | 'data-track-type'
  | 'data-track-pagefunction'
  | 'data-track-page';

/*
  paste into component to track:

  data-track-title={clickTrack.verifyTransfer.cancel}
  data-track-type={clickTrackType.LINK}

*/
