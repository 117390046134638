import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';
import styles from './header.styles';
import { HeaderLinksLeft, HeaderLinksRight } from './headerLinks';

type HeaderNavbarProps = {
  classes: any;
  name?: string;
  pathname: string;
  accounts: any;
  accountIsLoading: boolean;
  nextPageAvailable: boolean;
  itemsRef: any;
  isHamburgerVisible: boolean;
  isDark: boolean;
  active: boolean;
  isDashboardCaching: boolean;
  transitionState: string;
  unreadMessages: number;
  onSignOff: any;
};

const HeaderNavbar = withStyles(styles)((headerNavbarProps: HeaderNavbarProps) => {
  const {
    classes = {},
    name,
    isHamburgerVisible,
    isDark,
    accountIsLoading,
    accounts,
    pathname,
    active,
    itemsRef,
    isDashboardCaching,
    transitionState,
    unreadMessages,
    nextPageAvailable,
    onSignOff,
  } = headerNavbarProps;

  const isDarkTheme = isDark && !active;

  return (
    <nav
      className={
        transitionState === 'opened' || transitionState === 'opening'
          ? classes.navOpen
          : classes.navClosed
      }
      data-test="nav-holder"
    >
      <ul
        className={classNames(classes.items, !isDashboardCaching ? classes.itemsNotCached : '')}
        ref={itemsRef}
        aria-hidden={isHamburgerVisible && !active}
      >
        {!isDashboardCaching && (
          <HeaderLinksLeft
            showLightText={isDarkTheme}
            isExpanded={active}
            pathname={pathname}
            accounts={accounts}
            nextPageAvailable={nextPageAvailable}
            accountIsLoading={accountIsLoading}
            isHamburgerVisible={isHamburgerVisible}
          />
        )}

        <HeaderLinksRight
          name={name}
          showLightText={isDarkTheme}
          isExpanded={active}
          pathname={pathname}
          isHamburgerVisible={isHamburgerVisible}
          unreadMessages={unreadMessages}
          accounts={accounts}
          isDashboardCaching={isDashboardCaching}
          onSignOff={onSignOff}
        />
      </ul>
    </nav>
  );
});

export default HeaderNavbar;
