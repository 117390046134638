import { SynchronyInfo } from './blockText.constants';

export const PersonalInformationInlineErrors = Object.freeze({
  // errors for empty form field
  EMPTY_FIRST_NAME: 'Please enter First Name.',
  EMPTY_LAST_NAME: 'Please enter Last Name.',
  EMPTY_STREET_ADDRESS_1: 'Please enter Street Address.',
  EMPTY_CITY: 'Please enter City.',
  EMPTY_STATE: 'Please select State.',
  EMPTY_ZIP: 'Please enter Zip Code.',
  EMPTY_PHONE_NUMBER: 'Please enter Phone Number.',
  EMPTY_EMAIL: 'Please enter Email Address.',
  EMPTY_SSN: 'Please enter Social Security Number.',
  EMPTY_DOB: 'Please enter Date of Birth.',

  // errors for invalid characters
  INVALID_CHARS_FIRST_NAME: 'Sorry, First Name can only include letters and spaces.',
  INVALID_CHARS_MIDDLE_NAME: 'Sorry, Middle Name can only include letters and spaces.',
  INVALID_CHARS_LAST_NAME: 'Sorry, Last Name can only include letters and spaces.',
  INVALID_CHARS_STREET_ADDRESS_1:
    'Sorry, Street Address can only include letters, numbers, and spaces, and must include at least one space.',
  INVALID_CHARS_STREET_ADDRESS_2: 'Sorry, Unit # can only include letters, numbers, and spaces.',
  INVALID_CHARS_CITY: 'Sorry, City can only include letters and spaces.',

  // errors for too few characters
  MINCHARS_STREET_ADDRESS_1: 'Sorry, Street Address should contain a minimum of 3 characters.',
  MINCHARS_CITY: 'Sorry, City should contain a minimum of 3 characters.',

  // errors for invalid format
  INVALID_FORMAT_ZIP: 'Please enter a valid 5 or 9 digit Zip Code.',
  INVALID_FORMAT_PHONE: 'Please enter a valid 10 digit Phone Number.',
  INVALID_FORMAT_EMAIL: 'Please enter a valid Email Address.',
  INVALID_FORMAT_SSN: 'Please enter a valid 9 digit Social Security Number.',
  INVALID_FORMAT_DOB: 'Please enter the date in the MM/DD/YYYY format.',

  // special cases
  DOB_TOO_YOUNG: 'All applicants must be at least 18 years old.',
  DOB_OUT_OF_DATE_RANGE: 'Please enter a valid Date of Birth.',
});

export const PersonalInformationFormLabels = Object.freeze({
  FIRST_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',
  STREET_ADDRESS_1: 'Street Address',
  STREET_ADDRESS_2: 'Unit #',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'Zip Code',
  PHONE_NUMBER: 'Phone Number',
  EMAIL: 'Email Address',
  EMAIL_TYPE: 'Email Type',
  SOCIAL_SECURITY_NUMBER: 'Social Security Number',
  DATE_OF_BIRTH: 'Date of Birth',
});

export const SecurityFormLabels = Object.freeze({
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_USERNAME: 'Change Username',
});

export const PersonalInformationHelperText = Object.freeze({
  APPLICATION_STREET_ADDRESS_1: `If you would like to use a P.O. Box or other mail forwarding service to receive mail from us, please complete the application using your street address and then call us at ${SynchronyInfo.PHONE_TEXT}.`,
  PROFILE_STREET_ADDRESS_1: `If you would like to use a P.O. Box or other mail forwarding service to receive mail from us, please call us at ${SynchronyInfo.PHONE_TEXT}.`,
});

export const SecurityInfoHelperText = Object.freeze({
  SECURITY_TITLE: 'Security Information',
  USERNAME: 'Between 4-20 characters with letters and numbers only',
  USERNAME_ARIA: 'Between 4 and 20 characters with letters and numbers only',
});

export const NO_PHONE_COPY = 'No phone number due to hearing or speech disability';
