import pageAnalytics from '../../pageAnalytics';
import type { AccountFundingFlow } from '../../../utilities/accountFundingFlowType';
import pageTrack from '../../pageAnalytics.constants';
import { getPageSubfunction } from '../fundNewAccount.analytics';

const initData = (fundingFlow: AccountFundingFlow) => {
  return {
    account_type: pageTrack.account_type.generic,
    pagefunction: pageTrack.pagefunction.add_external_bank,
    pagekind: pageTrack.pagekind.account_opening,
    pagesubfunction: getPageSubfunction(fundingFlow),
    attr: pageTrack.attr.ews,
  };
};

export const viewAddExtBankSuccess = (fundingFlow: AccountFundingFlow) => () => {
  pageAnalytics
    .newPageView(initData(fundingFlow))
    .addInfo({ pagename: pageTrack.pagename.start })
    .record();
};

export const viewAddExtBankSubmissionFailure = (fundingFlow: AccountFundingFlow) => () => {
  pageAnalytics
    .newPageView(initData(fundingFlow))
    .addInfo({ pagename: pageTrack.pagename.start_submit_error })
    .record();
};
