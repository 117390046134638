import { makeStyles } from '@material-ui/core/styles';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';
import { Typography as TypographyStyles } from '../typography/typography.styles';

export default makeStyles({
  container: {
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.optimumBlue}`,
    color: Colors.optimumBlue,
    padding: `${pxToRem(10)} ${pxToRem(8)}`,
  },
  errorContainer: {
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.inlineError}`,
    padding: `${pxToRem(10)} ${pxToRem(8)}`,
    color: Colors.inlineError,
  },
  errorMessage: {
    marginLeft: pxToRem(26),
    marginTop: pxToRem(4),
    fontSize: pxToRem(12),
  },
  fileNameContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  fileName: {
    fontSize: pxToRem(14),
    fontWeight: 'bold',
    marginLeft: pxToRem(8),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  loadingIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    padding: pxToRem(5),
    margin: pxToRem(-5),
  },
  tryAgainButton: {
    ...TypographyStyles.inlineLinkText,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    color: Colors.inlineError,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: Colors.inlineError,
    },
  },
});
