const regexp = {
  zipCode: /^\d{5}(\d{4})?$/,
  phoneNumber: /^\d{10}$/,
  formattedPhoneNumber: /\d \w(?= )|\d{10}|\(\d{3}\)[-]?\d{3}[-]?\d{4}/,
  otp: /^\d{5}$/,
  dateOfBirth: /^.{10}$/,
  ssn: /^\d{9}$/,
  // eslint-disable-next-line no-useless-escape
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  charsAndNumbers: /^[A-Z0-9]*$/i,
  charsAndSpaces: /^[A-Z ]*$/i,
  charsNumbersAndSpaces: /^[A-Z 0-9]*$/i,
  charsNumbersAndAtLeastOneSpace: /^[A-Z 0-9]+ [A-Z 0-9]+$/i,
  atLeastTwoChars: /^.{2,}$/,
  atLeastThreeChars: /^.{3,}$/,
  endsWithLinkText: /link_text$/,
  endsWithLinkUri: /link_uri$/,
  endsWithMessage: /message$/,
  endsWithTitle: /title$/,
  endsWithImage: /image$/,
  flashN: /^flash_\d+$/,
  msgTileN: /^msg_tile_\d+$/,
  imgMessageTileN: /^img_tile_\d+$/,
  imgTileN: /^img_tile_\d+_image$/,
};

export const maskExternalAccountNumber = (accountNumber = '') => {
  return `●●${accountNumber.replace(/.(?=.{4,}$)/g, '')}`;
};

export default regexp;
