import Routes, {
  accountDetailHelpers,
  accountFundingHelpers,
  statementsHelpers,
  isExistingAccountFlow,
  isNewAccountFlow,
  overdraftProtectionHelpers,
  cdRenewalHelpers,
  cdInterestPaymentsHelpers,
  messageCenterHelpers,
  editTransferHelpers,
  cdBumpUpHelpers,
} from '../containers/routes/routes.constants';
import type { ReduxState } from '../reducers/index';
import { AFFlow } from '../utilities/accountFundingFlowType';
import { accountActivityPageEvents } from './paths/accountActivity.analytics';
import { accountDashboardPageEvents } from './paths/accounts.analytics';
import { newExternalBankAccPageEvents } from './paths/addExternalAccount.analytics';
import { applicationFailLoadPageEvents } from './paths/applicationFailLoad.analytics';
import { cancelApplicationPageEvents } from './paths/cancelApplication.analytics';
import { combinedStatementsPageEvents } from './paths/combinedStatements.analytics';
import { chooseExternalAccountOptionPageEvents } from './paths/chooseExternalAccountOption.analytics';
import { yodleeResponsePageEvents } from './paths/yodleeResponse.analytics';
import { externalAccountDashboardPageEvents } from './paths/externalAccountDashboard.analytics';
import { fundingVerificationEvents } from './paths/formPaths/fundingVerification.analytics';
import { fundingPageEvents } from './paths/fundNewAccount.analytics';
import { marketingAlertsPageEvents } from './paths/marketingAlerts.analytics';
import { newTransferPageEvents } from './paths/newTransfer.analytics';
import { openAccountFormFlowEvents } from './paths/openAccount.analytics';
import { overdraftProtectionPageEvents } from './paths/overdraftProtection.analytics';
import { pendingApplicationPageEvents } from './paths/pendingApplication.analytics';
import { personalInformationPageEvents } from './paths/personalInformation.analytics';
import { profilePageEvents } from './paths/profile.analytics';
import { ePreferencesPageEvents } from './paths/ePreferences.analytics';
import { successConfirmationPageEvents } from './paths/successConfirmation.analytics';
import { confirmTransferPageEvents } from './paths/transferConfirmation.analytics';
import { transfersPageEvents } from './paths/transfers.analytics';
import { verifyTransferPageEvents } from './paths/verifyTransfer.analytics';
import { verifyTrialDepositsPageEvents } from './paths/verifyTrialDeposits.analytics';
import { welcomePageEvents } from './paths/welcome.analytics';
import { statementsPageEvents } from './paths/statements.analytics';
import cdRenewalFormEvents from './paths/cdRenewal.analytics';
import securityPageEvents from './paths/security.analytics';
import { initiateOtpPageEvents } from './paths/initiateOtp.analytics';
import { initiateOtpTransmitPageEvents } from './paths/initiateOtpTransmit.analytics';
import { validateOtpPageEvents } from './paths/validateOtp.analytics';
import { rewardsPageEvents } from './paths/rewards.analytics';
import { cancelCardPageEvents } from './paths/cancelCard.analytics';
import { accountAlertsPageEvents } from './paths/accountAlerts.analytics';
import beneficiariesPageEvents from './paths/beneficiaries.analytics';
import { cdInterestPaymentsPageEvents } from './paths/cdInterestPayments.analytics';
import { documentsPageEvents } from './paths/documents.analytics';
import { viewInboxPageEvents } from './paths/viewInbox.analytics';
import { addExternalYodleeAccountPageEvents } from './paths/yodleeAccount.analytics';
import { newMessagePageEvents } from './paths/newMessage.analytics';
import { replyMessagePageEvents } from './paths/replyMessage.analytics';
import { editTransferPageEvents } from './paths/editTransfer.analytics';
import { processingApplicationPageEvents } from './paths/processingApplication.analytics';
import { processingApplicationTransmitPageEvents } from './paths/processingApplicationTransmit.analytics';
import exploreProductsPageEvents from './paths/exploreProducts.analytics';
import rateAndTermsPageEvents from './paths/rateAndTerms.analytics';
import cdBumpUpEvents from './paths/cdBumpUp.analytics';

type PageEvents = {
  [key: string]: (action: any, state: ReduxState) => void;
};

export const pathsExact: {
  [key: string]: PageEvents;
} = {
  [Routes.ADD_EXTERNAL_ACCOUNT_NUM]: newExternalBankAccPageEvents,
  [Routes.ADD_EXTERNAL_ACCOUNT_OPTIONS]: chooseExternalAccountOptionPageEvents,
  [Routes.ADD_EXTERNAL_YODLEE]: addExternalYodleeAccountPageEvents,
  [Routes.ACCOUNT_VERIFICATION_FAILED]: fundingVerificationEvents,
  [Routes.ADD_YODLEE_ACCOUNTS_SUCCESS]: yodleeResponsePageEvents,
  [Routes.ALERTS]: accountAlertsPageEvents,
  [Routes.APPLICATION_INIT_FAIL]: applicationFailLoadPageEvents,
  [Routes.BENEFICIARIES]: beneficiariesPageEvents,
  [Routes.CANCEL_APPLICATION]: cancelApplicationPageEvents,
  [Routes.CONTACT_US_APPLICATION]: cancelApplicationPageEvents,
  [Routes.CANCEL_CARD]: cancelCardPageEvents,
  [Routes.CONFIRM_TRANSFER]: confirmTransferPageEvents,
  [Routes.COMBINED_STATEMENTS]: combinedStatementsPageEvents,
  [Routes.DASHBOARD]: accountDashboardPageEvents,
  [Routes.DOCUMENTS]: documentsPageEvents,
  [Routes.PREFERENCES]: ePreferencesPageEvents,
  [Routes.EAO_ACCOUNT_CONFIRMATION]: successConfirmationPageEvents,
  [Routes.EAO_VERIFY_TRIAL_DEPOSITS]: verifyTrialDepositsPageEvents(AFFlow.EAO),
  [Routes.EAO]: openAccountFormFlowEvents,
  [Routes.EXPLORE_PRODUCTS]: exploreProductsPageEvents,
  [Routes.RATE_AND_TERMS]: rateAndTermsPageEvents,
  [Routes.EXTERNAL_ACCOUNTS]: externalAccountDashboardPageEvents,
  [Routes.NAO_ACCOUNT_CONFIRMATION]: successConfirmationPageEvents,
  [Routes.NAO_VERIFY_TRIAL_DEPOSITS]: verifyTrialDepositsPageEvents(AFFlow.NAO),
  [Routes.NAO]: personalInformationPageEvents,
  [Routes.NEW_MESSAGE]: newMessagePageEvents,
  [Routes.NEW_TRANSFER]: newTransferPageEvents,
  [Routes.OFFERS]: marketingAlertsPageEvents,
  [Routes.OTP_INITIATE]: initiateOtpPageEvents,
  [Routes.OTP_INITIATE_TRANSMIT]: initiateOtpTransmitPageEvents,
  [Routes.OTP_INITIATE_TRANSMIT_EAO]: initiateOtpTransmitPageEvents,
  [Routes.OTP_VALIDATE]: validateOtpPageEvents,
  [Routes.PENDING_APPLICATION]: pendingApplicationPageEvents,
  [Routes.PROCESSING_APPLICATION]: processingApplicationPageEvents,
  [Routes.PROCESSING_APPLICATION_TRANSMIT]: processingApplicationTransmitPageEvents,
  [Routes.PROFILE]: profilePageEvents,
  [Routes.REWARDS]: rewardsPageEvents,
  [Routes.SECURITY]: securityPageEvents,
  [Routes.TRANSFERS_DASHBOARD]: transfersPageEvents,
  [Routes.TRIAL_DEPOSIT_INSTRUCTIONS]: fundingVerificationEvents,
  [Routes.TRIAL_DEPOSITS]: fundingVerificationEvents,
  [Routes.VERIFY_TRANSFER]: verifyTransferPageEvents,
  [Routes.VERIFY_TRIAL_DEPOSITS]: verifyTrialDepositsPageEvents(AFFlow.DIRECT),
  [Routes.VIEW_INBOX]: viewInboxPageEvents,
  [Routes.WELCOME]: welcomePageEvents,
  [Routes.MAIL_CHECK]: fundingVerificationEvents,
};

export const pathsDynamic: Array<{
  validator: (arg1: string) => boolean;
  callbacks: PageEvents;
}> = [
  { validator: accountDetailHelpers.isAccountDetailsUrl, callbacks: accountActivityPageEvents },
  { validator: statementsHelpers.isStatementsUrl, callbacks: statementsPageEvents },
  {
    validator: overdraftProtectionHelpers.isOverdraftProtectionUrl,
    callbacks: overdraftProtectionPageEvents,
  },
  { validator: isNewAccountFlow, callbacks: openAccountFormFlowEvents },
  { validator: isExistingAccountFlow, callbacks: openAccountFormFlowEvents },
  {
    validator: accountFundingHelpers.isDirectFunding,
    callbacks: fundingPageEvents(AFFlow.DIRECT),
  },
  {
    validator: accountFundingHelpers.isEAOFunding,
    callbacks: fundingPageEvents(AFFlow.EAO),
  },
  {
    validator: accountFundingHelpers.isNAOFunding,
    callbacks: fundingPageEvents(AFFlow.NAO),
  },
  { validator: cdRenewalHelpers.isCdRenewalFlow, callbacks: cdRenewalFormEvents },
  {
    validator: cdInterestPaymentsHelpers.isCdInterestPaymentsUrl,
    callbacks: cdInterestPaymentsPageEvents,
  },
  {
    validator: messageCenterHelpers.isReplyMessageUrl,
    callbacks: replyMessagePageEvents,
  },
  {
    validator: editTransferHelpers.isEditTransfersUrl,
    callbacks: editTransferPageEvents,
  },
  {
    validator: cdBumpUpHelpers.isCDBumpUpFlow,
    callbacks: cdBumpUpEvents,
  },
];
