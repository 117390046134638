import { formValueSelector } from 'redux-form';
import { FlashMessageVariant } from '../../components/flashMessage/flashMessage.constants';
import { CHOOSE_FUNDING_SECTION_ID } from '../../components/openNewAccountFormFlow/chooseFunding.constants';
import { ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED } from '../../containers/addExternalAccount/addExternalAccount.reducers';
import {
  ACTION_FETCH_ALL_ACCOUNTS_FAILURE,
  ACTION_FETCH_ALL_ACCOUNTS_SUCCESS,
} from '../../containers/allAccounts/allAccounts.reducer';
import {
  ACTION_SET_FLASH_MESSAGE,
  FlashMessageAction,
} from '../../components/flashMessage/flashMessage.reducer';
import {
  ANALYTICS_ACCOUNT_VERIFIED,
  ANALYTICS_ADD_EXTERNAL_BANK_START,
  ANALYTICS_FUND_AMOUNT,
  ANALYTICS_FUND_REVIEW,
  ANALYTICS_FUND_CONFIRMATION,
  ANALYTICS_CHOOSE_FUNDING,
  ANALYTICS_YODLEE_START_SUCCESS,
  ANALYTICS_YODLEE_START_FAILURE,
  ANALYTICS_ROUTING_NUMBER_FAILED,
  ANALYTICS_YODLEE_FAILED,
} from '../actions';
import { ACTION_FETCH_PRODUCT_TYPES_FAILURE } from '../../containers/products/products.reducer';
import { FundingSteps } from '../../containers/routes/routes.constants';
import { FUNDING_FORM_ID } from '../../form.constants';
import type { ReduxState } from '../../reducers/index';
import type { AccountFundingFlow } from '../../utilities/accountFundingFlowType';
import {
  AFFlow,
  FundingMethod,
  NewExternalFundingMethod,
} from '../../utilities/accountFundingFlowType';
import pageTrack from '../pageAnalytics.constants';
import {
  viewFailure as viewChooseFundingFailure,
  viewSuccess as viewChooseFundingSuccess,
  virtualViewPostRegistration,
} from './formPaths/chooseFunding.analytics';
import { viewFundAmount } from './formPaths/fundAmount.analytics';
import {
  viewAddExtBankSuccess,
  viewAddExtBankSubmissionFailure,
} from './formPaths/fundingAddExternalAccount.analytics';
import {
  viewFailure as viewReviewFailure,
  viewSuccess as viewReviewSuccess,
} from './formPaths/fundingReview.analytics';
import {
  fundingAccountVerified,
  fundingSuccess,
  fundingAccountEWSFailed,
  fundingAccountYodleeFailed,
} from './formPaths/fundingVerification.analytics';
import { yodleeStartSuccess, yodleeFailure } from './addYodleeAccount.analytics';

export const isOnFormStep = (state: ReduxState, formStep: string) =>
  state.router.location.pathname.endsWith(`/${formStep}`);

export const getFundingType = (state: ReduxState) => {
  return formValueSelector(FUNDING_FORM_ID)(
    state,
    `${CHOOSE_FUNDING_SECTION_ID}.accountFundingType`
  );
};

export const getPageSubfunction = (fundingFlow?: AccountFundingFlow): string => {
  switch (fundingFlow) {
    case AFFlow.EAO:
      return pageTrack.pagesubfunction.EAO;
    case AFFlow.NAO:
      return pageTrack.pagesubfunction.NAO;
    case AFFlow.DIRECT:
    default:
      return pageTrack.pagesubfunction.servicing;
  }
};

export const isInternalFunding = (state: ReduxState) => {
  return getFundingType(state) === FundingMethod.INTERNAL;
};

export const getExternalFundingType = (state: ReduxState, requiresMicroDeposits?: boolean) => {
  const fundType = getFundingType(state);

  switch (fundType) {
    case FundingMethod.EXTERNAL:
      return pageTrack.attr.exist; // external account was already linked

    case FundingMethod.CHECK:
      return pageTrack.attr.check;

    case FundingMethod.ADD_EXTERNAL_ACCOUNT: {
      const source = formValueSelector(FUNDING_FORM_ID)(
        state,
        `${CHOOSE_FUNDING_SECTION_ID}.selectedNewExternalFundSource`
      );
      const newExternal = requiresMicroDeposits ? pageTrack.attr.md : pageTrack.attr.ews;
      return source === NewExternalFundingMethod.YODLEE ? pageTrack.attr.yodlee : newExternal;
    }
    case FundingMethod.INTERNAL:
      return pageTrack.attr.internal;
    default:
      return '';
  }
};

const isOnReviewFundingPage = (state: ReduxState) =>
  state.router.location.pathname.endsWith(`/${FundingSteps.REVIEW}`);

export const fundingPageEvents = (fundingFlow: AccountFundingFlow) => ({
  [ANALYTICS_CHOOSE_FUNDING]: virtualViewPostRegistration(fundingFlow),
  [ANALYTICS_YODLEE_START_SUCCESS]: yodleeStartSuccess(fundingFlow),
  [ANALYTICS_YODLEE_START_FAILURE]: yodleeFailure(fundingFlow),
  [ANALYTICS_ACCOUNT_VERIFIED]: fundingAccountVerified(fundingFlow),
  [ANALYTICS_ROUTING_NUMBER_FAILED]: fundingAccountEWSFailed(fundingFlow),
  [ANALYTICS_YODLEE_FAILED]: fundingAccountYodleeFailed(fundingFlow),

  [ANALYTICS_ADD_EXTERNAL_BANK_START]: viewAddExtBankSuccess(fundingFlow),
  [ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED]: viewAddExtBankSubmissionFailure(fundingFlow),

  [ANALYTICS_FUND_AMOUNT]: viewFundAmount(fundingFlow),

  [ANALYTICS_FUND_REVIEW]: viewReviewSuccess(fundingFlow),
  [ACTION_SET_FLASH_MESSAGE]: (action: FlashMessageAction, state: ReduxState) => {
    if (
      'payload' in action &&
      action.payload.messageType === FlashMessageVariant.ERROR &&
      isOnReviewFundingPage(state)
    )
      viewReviewFailure(fundingFlow)(action, state);
  },

  [ACTION_FETCH_ALL_ACCOUNTS_SUCCESS]: viewChooseFundingSuccess(fundingFlow),
  [ACTION_FETCH_ALL_ACCOUNTS_FAILURE]: viewChooseFundingFailure(fundingFlow),
  [ACTION_FETCH_PRODUCT_TYPES_FAILURE]: viewChooseFundingFailure(fundingFlow),

  [ANALYTICS_FUND_CONFIRMATION]: fundingSuccess(fundingFlow),
});

export default fundingPageEvents;
