import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { MouseEventHandler, ReactNode } from 'react';
import type { StyleProps } from '../../utilities/types';
import { ErrorButton } from '../buttons/buttons';
import { StandardText, InformationalSectionHeaderBold } from '../typography/typography';
import styles from './error.styles';
import { FlashMessageText } from '../flashMessage/flashMessage.constants';

// consts for keynames to resolve sonar error "duplicate string"
const attrButtonDataTest = 'btn-data-test';
const attrTitleDataTest = 'title-data-test';
const attrMessageDataTest = 'message-data-test';

export const defaults = {
  title: 'Something went wrong',
  message: FlashMessageText.GENERIC_ERROR,
  action: 'Try again',
  onClick: () => {
    window.location.reload();
  },
  isPrimary: false,
  'title-data-test': 'error-title',
  'message-data-test': 'error-message',
  'btn-data-test': 'error-btn',
};

export type ErrorProps = {
  action?: string;
  center?: boolean;
  className?: string;
  icon?: ReactNode;
  isPrimary?: boolean;
  message?: ReactNode;
  onClick?: MouseEventHandler;
  title?: string;
  [attrButtonDataTest]?: string;
  [attrTitleDataTest]?: string;
  [attrMessageDataTest]?: string;
};

export const Error = ({
  title = defaults.title,
  center,
  icon,
  message = defaults.message,
  action = defaults.action,
  onClick = () => {
    window.location.reload();
  },
  isPrimary = defaults.isPrimary,
  classes,
  className,
  ...props
}: ErrorProps & StyleProps) => (
  <div
    className={classNames(classes?.container, className, {
      [classes?.center]: center,
    })}
  >
    {icon && <div className={classes?.icon}>{icon}</div>}
    <InformationalSectionHeaderBold data-test={props[attrTitleDataTest] || 'error-title'}>
      {title}
    </InformationalSectionHeaderBold>
    <div className={classes?.message} aria-live="assertive">
      <StandardText data-test={props[attrMessageDataTest] || 'error-message'}>
        {message}
      </StandardText>
    </div>
    <div className={classes?.button}>
      <ErrorButton
        onClick={onClick}
        isPrimary={isPrimary}
        data-test={props[attrButtonDataTest] || 'error-btn'}
      >
        {action}
      </ErrorButton>
    </div>
  </div>
);

const styled = withStyles(styles)(Error);
styled.displayName = 'Error';
export default styled;
