import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import type { ReduxState } from '../../reducers';
import Logo from '../logo/logo';
import FooterWide from '../footer/footer';
import useStyles from './deadPage.styles';
import { ANALYTICS_WELCOME_PAGE_MAINTENANCE } from '../../analytics/actions';
import Routes from '../../containers/routes/routes.constants';

type StateProps = {
  downtimeMessage?: string;
};

type DispatchProps = {
  setAnalyticsWelcomeMaintenancePageView: () => void;
};

const mapStateToProps = (state: ReduxState): StateProps => {
  const { downtime } = state.accounts;
  return {
    downtimeMessage: downtime && downtime.downtime_message,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setAnalyticsWelcomeMaintenancePageView: () => {
    dispatch({ type: ANALYTICS_WELCOME_PAGE_MAINTENANCE });
  },
});

const Maintenance = ({
  downtimeMessage,
  setAnalyticsWelcomeMaintenancePageView,
}: StateProps & DispatchProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (window.location.pathname === Routes.WELCOME) {
      setAnalyticsWelcomeMaintenancePageView();
    }
  }, [setAnalyticsWelcomeMaintenancePageView]);

  return (
    <Grid className={classes.root} container direction="column" spacing={0}>
      <Grid item className={classes.header}>
        <Logo />
      </Grid>
      <div className={classes.content}>
        <h1>{downtimeMessage}</h1>
      </div>
      <Grid item>
        <FooterWide />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
