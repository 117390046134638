import { makeStyles } from '@material-ui/styles';
import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import { Alignment } from '../../styles/layout/alignment.styles';
import breakpoint from '../../styles/breakpoints';
import Colors from '../../components/colors/colors';
import { fontFamily } from '../../styles/createTheme';
import { mediumFontWeight } from '../../components/typography/typography.styles';

export const useDocumentsContainerStyles = makeStyles(
  (): StyleRules => ({
    loadMoreButtonContainer: {
      marginTop: pxToRem(24),
      marginBottom: pxToRem(24),
      ...Alignment.horizontalPadding,
    },
    quickLinksContainer: {
      marginTop: pxToRem(24),
    },
    quickLinksHeader: {
      paddingBottom: pxToRem(12),
    },
    quickLinksActionLink: {
      paddingBottom: pxToRem(8),
    },
    menuContainers: {
      ...Alignment.allPadding,
    },
    menuContainersWithFlashMssg: {
      ...Alignment.footerPadding,
    },
    errorMessageFont: {
      paddingRight: pxToRem(8),
      color: Colors.inlineError,
      fontSize: pxToRem(12),
    },
    documentRowContainer: {
      alignItems: 'baseline',
    },
    rightColumn: {
      paddingRight: pxToRem(0),
      textAlign: 'right',
    },
    taxDocButton: {
      marginTop: pxToRem(8),
    },
    selectDisabledStyle: {
      opacity: 0.5,
      backgroundColor: '#dfdfdf !important',
      pointerEvents: 'none',
    },
    documentYearSelect: {
      '& .MuiFormControl-root': {
        marginTop: 0,
      },
      '& .MuiInput-root': {
        backgroundColor: Colors.white,
        padding: `${pxToRem(7)} ${pxToRem(8)} ${pxToRem(7)} ${pxToRem(8)}`,
        fontFamily,
        color: Colors.charcoalGrey,
        fontSize: pxToRem(16),
        fontWeight: mediumFontWeight,
      },
    },
    documentSelectIcon: {
      marginRight: pxToRem(1),
      '&.MuiSelect-icon': {
        top: 'unset',
      },
    },
    documentSelectLabel: {
      fontSize: pxToRem(14),
      fontWeight: 400,
      marginLeft: pxToRem(8),
      marginBottom: pxToRem(6),
    },
    documentMenuItem: {
      marginTop: pxToRem(4),
      '& ul': {
        padding: 0,
      },
      '& li': {
        padding: `${pxToRem(8)} ${pxToRem(20)}`,
        fontSize: pxToRem(16),
        fontWeight: 'normal',
        letterSpacing: pxToRem(1.17),
        textTransform: 'uppercase',
      },
    },
    viewDocumentLink: {
      marginInline: 'auto',
      [breakpoint.up('md')]: {
        marginInlineEnd: 'unset',
        paddingRight: 0,
      },
    },
    arrowIconWrapperClass: {
      position: 'absolute',
      pointerEvents: 'none',
      right: pxToRem(8),
    },
  })
);

export default useDocumentsContainerStyles;
