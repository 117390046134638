import React, { useState, useEffect } from 'react';
import Button from 'bank-component-library/ui/atoms/Button';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { RenderTextField } from '../finalForm/finalFormInputs';
import LoadingIndicatorStyled from '../loadingIndicator/loadingIndicator';
import { useEffectOnce } from '../../utilities/reactHooks';
import useOtpStyles from './transmitOtp.styles';
import type { TrustScoreUIType } from '../../containers/security/security.service';
import { composeValidators } from '../../utilities/validators';
import IgniteFlashMessage from '../flashMessage/IgniteFlashMessage';
import { FlashMessageVariant } from '../flashMessage/flashMessage.constants';
import ConfirmationModal from '../modal/ConfirmationModal';
import i18n from '../../strings/i18n';
import { checkEmptyPhoneNumber, checkPhoneValidation } from './transmit.validators';
import { formatPhoneNumber, removeExtraCharacters, phoneValidation } from './transmit.utilities';
import { SynchronyInfo } from '../cms/blockText.constants';
import { FormHeaderProps } from '../formWithImage/typings';
import pageTrack from '../../analytics/pageAnalytics.constants';
import pageAnalytics from '../../analytics/pageAnalytics';

type AllProps = TrustScoreUIType & FormHeaderProps;

function TrustScoreUI(props: AllProps) {
  const {
    action,
    handlePhoneNumberUpdate,
    payload,
    handleAuthenticate,
    handleCancel,
    handleAlternativeFlow,
  } = props;
  const [isPhoneValue, setPhoneVal] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageStatus, setMessageStatus] = useState(props.messageStatus);
  let digitsValid = false;
  const [showModal, setShowModal] = useState(false);
  const classes = useOtpStyles(props);
  const modalTitle = i18n({ OTPValidateText: 'OTP_VALIDATE_HEADER_CANCEL' }).toLowerCase();
  const modalBody = i18n({ OTPValidateText: 'OTP_VALIDATE_BODY_CANCEL' }).toLowerCase();

  const phoneNumberValidator = composeValidators(checkEmptyPhoneNumber, checkPhoneValidation);

  const updatePhoneNumber = (value) => {
    setShowError(false);
    const spaceFormatting = value.replace(/\s/g, '');
    const digits = removeExtraCharacters(spaceFormatting);
    const formattedNumber = formatPhoneNumber(spaceFormatting);
    handlePhoneNumberUpdate(digits);
    setPhoneVal(digits);
    digitsValid = phoneValidation(digits);
    return formattedNumber;
  };

  const normalizeDashes = (value?: string | null) => (value ? updatePhoneNumber(value) : '');

  const onSubmit = (e: Event | React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
  };

  const handleAlternateFlow = (e: Event | React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    handleAlternativeFlow();
  };

  useEffectOnce(() => {
    setIsLoading(false);
    if (messageStatus === null) {
      return pageAnalytics
        .newPageView({
          account_type: pageTrack.account_type.generic,
          pagekind: pageTrack.pagekind.account_opening,
          pagename: pageTrack.pagename.otp_start,
          pagefunction: pageTrack.pagefunction.ao_otp,
          pagesubfunction: pageTrack.pagesubfunction.NAO,
        })
        .record();
    }

    return null;
  });

  useEffect(() => {
    if (messageStatus === i18n({ TransmitAnalytics: 'ALTERNATE_NUMBER' })) {
      return pageAnalytics
        .newPageView({
          account_type: pageTrack.account_type.generic,
          pagekind: pageTrack.pagekind.account_opening,
          pagename: pageTrack.pagename.start_unconfirmed_phone,
          pagefunction: pageTrack.pagefunction.ao_otp,
          pagesubfunction: pageTrack.pagesubfunction.NAO,
        })
        .record();
    }
    return null;
  }, [messageStatus]);

  function handleButtonClick(e: Event | React.SyntheticEvent<Element, Event>) {
    handleAuthenticate(isPhoneValue, digitsValid);
    if (isPhoneValue === '' || isPhoneValue === null) {
      setMessageStatus(null);
      setShowError(true);
    }
    e.preventDefault();
  }

  return (
    <div className={classes.trustScoreContainer}>
      {isLoading && <LoadingIndicatorStyled />}
      {showError === true && (
        <IgniteFlashMessage data-test="phone-number-error" variant={FlashMessageVariant.ERROR}>
          {payload.errorMessage.phoneNumberrequired}
        </IgniteFlashMessage>
      )}

      {messageStatus !== null ? (
        <IgniteFlashMessage variant={FlashMessageVariant.ERROR}>{messageStatus}</IgniteFlashMessage>
      ) : null}

      <ConfirmationModal
        confirmText={i18n({ ModalBtnText: 'EXIT_BTN' })}
        exitText={i18n({ ModalBtnText: 'CANCEL_CONTINUE_BTN' })}
        contentText={i18n({ ModalText: 'CANCEL_TRANSMIT_BODY' }, { modalBody })}
        data-test="cancel-modal"
        onExit={() => {
          setShowModal(false);
        }}
        onConfirm={handleCancel}
        titleText={i18n({ ModalText: 'CANCEL_TRANSMIT_TITLE' }, { modalTitle })}
        visible={showModal}
      />

      <header data-test="enter-phone-number-holder">
        <Grid
          item
          data-test="enter-phone-number-header"
          component="div"
          xs={12}
          className={classes.headerTitle}
        >
          {i18n({ OTPText: 'OTP_TRUSTSCORE_HEADER' })}
        </Grid>

        <Grid
          item
          data-test="enter-phone-number-description"
          component="div"
          xs={12}
          className={classes.headerDescription}
        >
          {i18n({ OTPText: 'OTP_TRUSTSCORE_DESCRIPTION' })}
        </Grid>
      </header>
      {action === 'generate' ? (
        <Grid item component="div" xs={12} className={classes.otpLinksHolder}>
          <Link
            id="alternateFlow"
            data-test="alternateFlow"
            className={classes.otpLinks}
            onClick={handleAlternateFlow}
            to="/"
          >
            {i18n({ OTPValidateText: 'DONOT_HAVE_ANOTHER_NUMBER' })}
          </Link>
        </Grid>
      ) : null}

      <Grid
        item
        data-test="enter-phone-number-header"
        component="div"
        xs={12}
        className={classes.headerSubDescription}
      >
        {i18n({ OTPText: 'OTP_TRUSTSCORE_NO_PHONE_TEXT' })}
        {SynchronyInfo.OTP_PHONE_LINK}.
      </Grid>

      <Grid item component="div" xs={12} className={classes.otpInputNumber}>
        <Form data-test="enter-phone-number-form" onSubmit={onSubmit}>
          {() => {
            return (
              <Field
                id="phoneNumber"
                className={classes.phoneNumber}
                name={i18n({ TransmitFormLabels: 'ENTER_PHONE_NUMBER' })}
                label={i18n({ OTPText: 'MOBILE_NUMBER' })}
                maxLength={14}
                placeholder="(___)-___-____"
                validate={phoneNumberValidator}
                component={RenderTextField}
                parse={normalizeDashes}
                data-test="phone-number-field"
              />
            );
          }}
        </Form>
      </Grid>
      <Grid container spacing={2} className={classes.buttonHolderCL}>
        <Grid item xs={5}>
          <Button
            id="confirmBtn"
            variant="primary-outlined"
            data-test="cancel-phone"
            className={classes.authenticateBtn}
            onClick={() => setShowModal(true)}
          >
            {payload.cancel}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleButtonClick}
            id="continueBtn"
            data-test="continue-phone"
            className={classes.authenticateBtn}
          >
            {payload.continue}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default TrustScoreUI;
