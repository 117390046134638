import type { AxiosPromise } from 'axios';
import axios from '../../utilities/axios';
import {
  MESSAGE_COUNTS_URL,
  MESSAGE_DETAILS_URL,
  MESSAGES_INFO_URL,
  MESSAGES_TOPICS_URL,
  MESSAGES_URL,
  SUBMIT_NEW_MESSAGE_URL,
  SUBMIT_REPLY_MESSAGE_URL,
  MESSAGE_ATTACHMENTS_URL,
  MESSAGE_ATTACHMENT_DOWNLOAD_URL,
} from '../../utilities/route-mappings';
import type {
  AttachmentDownloadLinkResponse,
  GetTopics,
  MessageDetails,
} from './messages.constants';
import type { MessagesInfo } from '../viewInbox/viewInbox.constants';

type MessageResponseType = {
  topic: string;
  message: string;
};

export const sendMessageService = (
  topic: string,
  message: string
): AxiosPromise<MessageResponseType> =>
  axios.post(MESSAGES_URL(), {
    topic,
    message,
  });

export type MessageCounts = {
  read: string;
  sent: string;
  unread: string;
};

type MessageCountsService = () => Promise<MessageCounts>;

export const getMessageCountsService: MessageCountsService = async () => {
  const response = await axios.get(MESSAGE_COUNTS_URL());
  return response.data;
};

export type GetMessageTopicsService = () => Promise<GetTopics>;

export const fetchMessageTopics: GetMessageTopicsService = async () => {
  const response = await axios.get(MESSAGES_TOPICS_URL());
  return response.data;
};

export type GetInboxMessagesService = () => Promise<MessagesInfo>;

export const fetchInboxMessages: GetInboxMessagesService = async () => {
  const response = await axios.get(MESSAGES_INFO_URL());
  return response.data;
};

export type GetMessageDetailService = (threadId: string) => Promise<MessageDetails>;

export const fetchMessageDetails: GetMessageDetailService = async (threadId: string) => {
  const response = await axios.get(MESSAGE_DETAILS_URL(threadId));
  return response.data;
};

export type SubmitNewMessageService = (
  topic: string,
  message: string,
  attachments?: string[]
) => Promise<string>;

export const submitNewMessage: SubmitNewMessageService = async (
  topic: string,
  message: string,
  attachments?: string[]
) => {
  const response = await axios.post(SUBMIT_NEW_MESSAGE_URL(), {
    topic,
    text: message,
    attachments,
  });
  return response.data;
};

export type SubmitReplyMessageService = (
  threadId: string,
  message: string,
  attachments?: string[]
) => Promise<string>;

export const submitReplyMessage: SubmitReplyMessageService = async (
  threadId: string,
  message: string,
  attachments?: string[]
) => {
  const response = await axios.post(SUBMIT_REPLY_MESSAGE_URL(threadId), {
    text: message,
    attachments,
  });
  return response.data;
};

type UploadMessageAttachmentResponse = {
  attachmentId: string;
};
export type UploadMessageAttachmentService = (
  fileName: string,
  fileType: string,
  content: string
) => Promise<UploadMessageAttachmentResponse>;

export const uploadAttachmentService: UploadMessageAttachmentService = async (
  fileName,
  fileType,
  content
) => {
  let telemetryHeaders;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    telemetryHeaders = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }

  const headers = {
    ...telemetryHeaders,
    /**
     * ! MacOS default screenshot name uses a special character on one of the white spaces
     * which throws an error since it's an invalid HTTP Header value
     */
    'X-SYF-FileName': fileName.replace(/\s/g, ' '),
    'X-SYF-FileType': fileType,
  } as const;
  const response = await axios.post(MESSAGE_ATTACHMENTS_URL(), { content }, { headers });
  return response.data;
};

export type GetAttachmentDownloadLinkService = (
  threadId: string,
  messageId: string,
  attachmentName: string
) => Promise<AttachmentDownloadLinkResponse>;

export const fetchViewAttachmentLink: GetAttachmentDownloadLinkService = async (
  threadId,
  messageId,
  attachmentName
) => {
  const response = await axios.get(
    MESSAGE_ATTACHMENT_DOWNLOAD_URL(threadId, messageId, attachmentName)
  );
  return response.data;
};

export default fetchInboxMessages;
