type Attributes = {
  [key: string]: string | undefined;
};

export default (url: string, attributes: Attributes = {}) => {
  return new Promise<void>((resolve, reject) => {
    const newScript = document.createElement('script');

    Object.keys(attributes).forEach((key) => {
      const value = attributes[key];
      if (value) newScript.setAttribute(key, value);
    });

    newScript.onerror = () => {
      newScript.remove();
      reject();
    };

    newScript.onload = () => resolve();

    if (document.head) {
      document.head.appendChild(newScript);
    } else {
      reject();
      return;
    }

    newScript.src = url;
  });
};
