import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { Button } from 'bank-component-library/ui/atoms/Button';
import useStyles from './ControlPanel.styles';
import { getMswConfig, resetMswConfig, setMswConfigFlag, toggleHandlerFlag } from '../../mocks';

const MockServiceWorkerPanel = () => {
  const classes = useStyles();
  const [mswConfig, setMswConfigState] = useState(getMswConfig());
  const { HANDLER_CONFIG: handlerConfig } = mswConfig;

  const updateMswConfigState = () => setMswConfigState(getMswConfig());

  const updateMswConfigFlag = (flagName: string, value: string) => {
    setMswConfigFlag(flagName, !value);
    updateMswConfigState();
  };

  const updateHandlerConfigFlag = (handlerName: string) => {
    toggleHandlerFlag(handlerName);
    updateMswConfigState();
  };

  const resetConfig = () => {
    resetMswConfig();
    updateMswConfigState();
  };

  return (
    <>
      <h1 className="text-2xl px-3">Core</h1>
      {Object.entries(mswConfig).map(([key, value]) => {
        if (typeof value !== 'boolean') return null;
        return (
          <FormControlLabel
            className={classes.flagLabel}
            key={key}
            control={
              <Switch
                checked={mswConfig[key] === true}
                onChange={() => updateMswConfigFlag(key, mswConfig[key])}
                name={key}
              />
            }
            label={key}
          />
        );
      })}
      <h1 className="text-2xl px-3">Handlers</h1>
      {handlerConfig.map(({ name, enabled }) => {
        return (
          <FormControlLabel
            className={classes.flagLabel}
            key={`handler-${name}`}
            control={
              <Switch
                checked={enabled}
                name={name}
                onChange={() => {
                  updateHandlerConfigFlag(name);
                }}
              />
            }
            label={name}
            disabled={!mswConfig.MSW_ENABLED}
          />
        );
      })}
      <br />
      <div className="flex flex-col items-center">
        <Button onClick={resetConfig} className="w-3/5">
          Reset MSW Config
        </Button>
      </div>
    </>
  );
};

export default MockServiceWorkerPanel;
