import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { EditTransferAnalyticsAction } from '../actions';
import {
  ANALYTICS_CONFIRM_EDIT_TRANSFER_SUCCESS,
  ANALYTICS_EDIT_TRANSFER_SUBMIT_FAILURE,
  ANALYTICS_EDIT_TRANSFER_SUBMIT_SUCCESS,
  ANALYTICS_START_EDIT_TRANSFER_FAILURE,
  ANALYTICS_START_EDIT_TRANSFER_SUCCESS,
} from '../actions';
import type { TransferActivity } from '../../containers/transfers/transfers.reducer';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.transfers,
    pagefunction: pageTrack.pagefunction.edit_transfer,
  });

const pageSubFunction = (transferActivity?: TransferActivity) => {
  let value = 'invalid';
  const fromType = transferActivity && transferActivity.fromAccount.syfAccount ? 'In' : 'Ex';
  const toType = transferActivity && transferActivity.toAccount.syfAccount ? 'toIn' : 'toEx';

  switch (fromType + toType) {
    case 'ExtoIn':
      value = 'inward';
      break;

    case 'IntoIn':
      value = 'internal';
      break;

    case 'IntoEx':
      value = 'outward';
      break;

    case 'ExtoEx':
    default:
      break;
  }
  return value;
};

const startSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start }).record();
};

const startFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.start_server_error }).record();
};

const confirmSuccess = (action: EditTransferAnalyticsAction) => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.confirm,
      pagesubfunction: pageSubFunction(action.payload),
      attr:
        (action.payload && action.payload.recurrenceType.value) === 'ONCE' ? 'once' : 'recurring',
    })
    .record();
};

const submitSuccess = (action: EditTransferAnalyticsAction) => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.complete,
      pagesubfunction: pageSubFunction(action.payload),
      attr:
        (action.payload && action.payload.recurrenceType.value) === 'ONCE' ? 'once' : 'recurring',
    })
    .record();
};

const submitFailure = (action: EditTransferAnalyticsAction) => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.submit_server_error,
      pagesubfunction: pageSubFunction(action.payload),
      attr: action.errorMessage,
    })
    .record();
};

export const editTransferPageEvents = {
  [ANALYTICS_START_EDIT_TRANSFER_SUCCESS]: startSuccess,
  [ANALYTICS_START_EDIT_TRANSFER_FAILURE]: startFailure,
  [ANALYTICS_CONFIRM_EDIT_TRANSFER_SUCCESS]: confirmSuccess,
  [ANALYTICS_EDIT_TRANSFER_SUBMIT_SUCCESS]: submitSuccess,
  [ANALYTICS_EDIT_TRANSFER_SUBMIT_FAILURE]: submitFailure,
} as const;

export default editTransferPageEvents;
