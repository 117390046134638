import { Grid, Hidden, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import type { ReduxState } from '../../reducers';
import type { CustomerInfo } from '../../utilities/types';
import { useRewardsContainerStyles } from './rewards.styles';
import {
  Header2,
  StandardTextEmphasis,
  SubtitleText,
} from '../../components/typography/typography';
import pxToRem from '../../utilities/pxToRem';
import { useEffectOnce } from '../../utilities/reactHooks';
import Page from '../../components/page/page';
import LeftPanelGridItem, { LeftPanelHeader } from '../../components/pageLayout/LeftPanelGridItem';
import FullHeightLayout from '../../components/pageLayout/FullHeightLayout';
import Content from '../../components/pageLayout/ContentGridContainerAndItem';
import ArrowLinkButton from '../../components/buttons/arrowLinkButton';
import ArrowLink from '../../components/links/arrowLink';
import { AccountActivityBtn } from '../../components/cms/buttonText.constants';
import {
  RewardsText,
  RewardLevelsMapping,
  RewardServiceLevels,
  RewardsLinks,
} from './rewards.constants';
import type { ServiceLevel } from './rewards.constants';

import LeavingSiteConfirmationModal from '../../components/modal/LeavingSiteConfirmationModal';
import RewardsTierChart from '../../components/rewardsTierChart/rewardsTierChart';
import Error from '../../components/error/error';
import RewardsTierTile from '../../components/rewardsTierTile/rewardsTierTile';
import getRewardTierInfo from './rewards.utilities';
import {
  ANALYTICS_REWARDS_VIEW_SERVER_ERROR,
  ANALYTICS_REWARDS_VIEW_SUCCESS,
} from '../../analytics/actions';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import { fetchCustomerInfo as fetchCustomerInfoAction } from '../newAccountOpening/applications.actions';
import i18n from '../../strings/i18n';

type StateProps = {
  serviceLevel?: ServiceLevel;
  customerInfo?: CustomerInfo;
};

type DispatchProps = {
  fetchCustomerInfo: () => Promise<void>;
  recordAnalyticsPageView: () => void;
  recordAnalyticsProfileErrorPageView: () => void;
};

type ProfileProps = StateProps & DispatchProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  customerInfo: state.applications.customerInfo,
  serviceLevel:
    state.applications.customerInfo &&
    (RewardLevelsMapping[state.applications.customerInfo.serviceLevel] ||
      RewardServiceLevels.BASIC),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchCustomerInfo: async () => {
    dispatch(fetchCustomerInfoAction());
  },
  recordAnalyticsPageView: () => dispatch({ type: ANALYTICS_REWARDS_VIEW_SUCCESS }),
  recordAnalyticsProfileErrorPageView: () =>
    dispatch({ type: ANALYTICS_REWARDS_VIEW_SERVER_ERROR }),
});

export const Rewards = (props: ProfileProps) => {
  const {
    customerInfo,
    fetchCustomerInfo,
    serviceLevel,
    recordAnalyticsPageView,
    recordAnalyticsProfileErrorPageView,
  } = props;
  const classes = useRewardsContainerStyles();
  const [showLeavingSiteModal, setShowLeavingSiteModal] = useState(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [hasCustomerError, setHasCustomerError] = useState<boolean>(false);

  const refetchCustomerInfo = async () => {
    if (customerInfo) {
      return true;
    }
    setIsCustomerLoading(true);
    setHasCustomerError(false);
    try {
      await fetchCustomerInfo();
    } catch (err) {
      setHasCustomerError(true);
      return false;
    } finally {
      setIsCustomerLoading(false);
    }
    return true;
  };

  const getInitialData = async () => {
    const [successfulCustomerRequest] = await Promise.all([refetchCustomerInfo()]);
    if (successfulCustomerRequest) {
      recordAnalyticsPageView();
    } else {
      recordAnalyticsProfileErrorPageView();
    }
  };

  useEffectOnce(() => {
    // Necessary because react doesn't like useEffect returning a Promise
    getInitialData();
  });

  const { icon, title, description, perks, travelBenefitsLink } = getRewardTierInfo(serviceLevel);

  const showTravelBenefitsLink = () => !!travelBenefitsLink;

  const renderTravelBenefits = () => (
    <ArrowLinkButton
      className={classes.rewardLink}
      buttonText={AccountActivityBtn.VIEW_TRAVEL_BENEFITS}
      onClick={() => setShowLeavingSiteModal(true)}
      data-test="travel-benefits-link"
      data-track-title={clickTrack.rewards.travel}
      data-track-type={clickTrackType.LINK}
    />
  );

  const renderLeftPanelLinks = () => {
    return (
      <>
        {showTravelBenefitsLink() && renderTravelBenefits()}
        <ArrowLink
          className={classes.rewardLink}
          to={RewardsLinks.PERKS_TERMS_AND_CONDITIONS}
          data-test="perks-terms-and-conditions-link"
          data-track-title={clickTrack.rewards.terms_and_conditions}
          data-track-type={clickTrackType.LINK}
        >
          {AccountActivityBtn.VIEW_PERKS_TERMS_AND_CONDITIONS}
        </ArrowLink>
      </>
    );
  };

  const renderLeavingSiteConfirmationModal = () => {
    return (
      travelBenefitsLink && (
        <LeavingSiteConfirmationModal
          to={travelBenefitsLink}
          onExit={() => setShowLeavingSiteModal(false)}
          visible={showLeavingSiteModal}
        />
      )
    );
  };

  return (
    <Page title="My Rewards" loading={isCustomerLoading}>
      <FullHeightLayout>
        {hasCustomerError ? (
          <Error center onClick={getInitialData} />
        ) : (
          <>
            <LeftPanelGridItem data-test="rewards-left-column">
              <LeftPanelHeader title="Current Reward Tier" text="" />
              <Grid container className={classes.currentRewardTierContainer}>
                <div className={classes.currentRewardTierIconContainer}>
                  <Grid item className={classes.currentRewardTierIcon} data-test="reward-icon">
                    {icon}
                  </Grid>
                  <Grid item>
                    <StandardTextEmphasis data-test="reward-icon-text">
                      {title}
                    </StandardTextEmphasis>
                  </Grid>
                </div>
                <Grid
                  item
                  component="div"
                  className={classes.currentRewardTierDescription}
                  lg={10}
                  sm={8}
                >
                  <SubtitleText data-test="total-balance-text">{description}</SubtitleText>
                </Grid>
              </Grid>
              <StandardTextEmphasis data-test="perks-header">
                {RewardsText.AVAILABLE_PERKS}
              </StandardTextEmphasis>
              <Grid item className={classes.rewardTierList} data-test="perks-list">
                <ul>
                  {perks.map((perk) => (
                    <li key={perk}>
                      <SubtitleText>{perk}</SubtitleText>
                    </li>
                  ))}
                </ul>
              </Grid>
              {renderLeftPanelLinks()}
              <Grid className={classes.divider}>
                <Hidden mdUp>
                  <Divider />
                </Hidden>
              </Grid>
            </LeftPanelGridItem>
            <Content>
              <Grid item className={classes.container} data-test="rewards-right-column">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header2 paddingBottom={pxToRem(8)} data-test="ro-customerFullName">
                      {i18n({ REWARDS_OVERVIEW_TEXT: 'HEADING' })}
                    </Header2>
                  </Grid>
                  <Grid item xs={12} data-test="rewards-overview-text">
                    <p>{i18n({ REWARDS_OVERVIEW_TEXT: 'SECTION1' })}</p>
                    <br />
                    <p>{i18n({ REWARDS_OVERVIEW_TEXT: 'SECTION2' })}</p>
                    <br />
                    <p>{i18n({ REWARDS_OVERVIEW_TEXT: 'SECTION3' })}</p>
                  </Grid>
                  <Grid container item xs={12}>
                    <Hidden xsDown>
                      <RewardsTierChart />
                    </Hidden>
                    <Hidden smUp>
                      <RewardsTierTile currentServiceLevel={serviceLevel} />
                    </Hidden>
                  </Grid>
                  <Grid item xs={12}>
                    <SubtitleText>{i18n({ REWARDS_OVERVIEW_TEXT: 'NOTE' })}</SubtitleText>
                  </Grid>
                </Grid>
              </Grid>
            </Content>
          </>
        )}
      </FullHeightLayout>
      {renderLeavingSiteConfirmationModal()}
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
