import { Grid } from '@material-ui/core';
import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ArrowLink from '../../components/links/arrowLink';
import Routes from '../routes/routes.constants';
import { Header2, StandardTextEmphasis } from '../../components/typography/typography';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';

type TaxDocumentPreferenceType = {
  TaxDocumentPreferenceTitle: string;
  ETaxDocumentPreferencesConstant: Record<string, string>;
  classes: ClassNameMap;
  EPreferencesBtn: Record<string, string>;
};

const TaxDocumentPreference = ({
  TaxDocumentPreferenceTitle,
  ETaxDocumentPreferencesConstant,
  classes,
  EPreferencesBtn,
}: TaxDocumentPreferenceType) => {
  return (
    <Grid className={classes.containerNewDesign}>
      <Grid container className={classes.tileItem} spacing={3}>
        <Grid item xs={12}>
          <Header2 data-test="ePreferences-header">{TaxDocumentPreferenceTitle}</Header2>
        </Grid>
        <Grid item xs={12}>
          <p data-test="ePreferences-tax-documents">
            <StandardTextEmphasis>
              {ETaxDocumentPreferencesConstant.TAX_PREFERENCE_TITLE}
            </StandardTextEmphasis>
            {ETaxDocumentPreferencesConstant.TAX_PREFERENCE_SUB_TITLE}
          </p>
        </Grid>
        <Grid item xs={12}>
          <ArrowLink
            to={Routes.DOCUMENTS}
            data-test="quick-links-tax-documents"
            className={classes.quickLinksActionLink}
            role="link"
            data-track-page={clickTrack.ePreferences.tax_documents}
            data-track-title={clickTrack.ePreferences.tax_documents}
            data-track-type={clickTrackType.LINK}
          >
            {EPreferencesBtn.MANAGE_TAX_DOCUMENT}
          </ArrowLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaxDocumentPreference;
