import _ from 'lodash';
import axios from '../../utilities/axios';
import type { Dispatch } from '../../utilities/types';
import type { ValidateRoutingNumberResponse } from '../addExternalAccount/addExternalAccount.container';
import { ACTION_SET_BANK_NAME } from '../addExternalAccount/addExternalAccount.reducers';
import { VALIDATE_EXTERNAL_ACCOUNT_URL } from '../../utilities/route-mappings';

type ValidateRoutingNumberPromise = Promise<ValidateRoutingNumberResponse>;

export const validateRoutingNumberService = (
  routingNumber: string
): ValidateRoutingNumberPromise => {
  if (routingNumber === '') {
    return Promise.resolve({ valid: true, bank: '' });
  }

  return axios.get(VALIDATE_EXTERNAL_ACCOUNT_URL(routingNumber)).then((res) => res.data.data);
};

export const asyncValidateRoutingNumber = (
  routingNumber: string,
  dispatch?: Dispatch | null,
  fieldName = 'routingNumber'
) =>
  validateRoutingNumberService(routingNumber).then((res: ValidateRoutingNumberResponse) => {
    if (dispatch != null && res.valid) {
      dispatch({ type: ACTION_SET_BANK_NAME, payload: res.bank });
    }
    if (!res.valid) {
      const err: Record<string, string> = {};
      _.set(err, fieldName.split('.'), 'Must be a valid routing number');
      throw new Error();
    }
  });

export default validateRoutingNumberService;
