import React from 'react';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import type { StyleProps } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

const ArrowStyles = {
  right: {
    transform: 'rotate(0deg)',
    top: '.2em',
  },
  up: {
    transform: 'rotate(-90deg)',
    top: '-.1em',
    left: '-.1em',
  },
  down: {
    transform: 'rotate(90deg)',
    top: '.4em',
    left: '-.3em',
  },
  left: {
    transform: 'rotate(180deg)',
    top: '.2em',
  },
  iconWrapper: {
    display: 'inline-block',
    position: 'relative',
    top: pxToRem(1),
  },
  'position-left': {
    paddingRight: '0.4rem',
  },
  'position-right': {
    paddingLeft: '0.4rem',
  },
} as const;

export const ArrowDirection = Object.freeze({
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
});
export type ArrowDirectionValue = typeof ArrowDirection[keyof typeof ArrowDirection];

export const ArrowPosition = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
});
export type ArrowPositionValue = typeof ArrowPosition[keyof typeof ArrowPosition];

export type ArrowProps = {
  direction: ArrowDirectionValue;
  position: ArrowPositionValue;
};

type AllProps = ArrowProps & StyleProps;

const Arrow = ({ direction, position, classes }: AllProps) => (
  <span className={classnames(classes.iconWrapper, classes[`position-${position}`])}>
    <SVGImage imageName={ImagesFileNames.arrowSvg} className={classnames(classes[direction])} />
  </span>
);

export default withStyles(ArrowStyles)(Arrow);
