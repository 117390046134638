import { LOCATION_CHANGE } from 'connected-react-router';
import pageAnalytics from '../pageAnalytics';

const viewPage = () => {
  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: 'account_opening',
      pagesubfunction: 'new_customer',
      pagename: 'cancelled_application',
      attr: '',
    })
    .record();
};

export const cancelApplicationPageEvents = {
  [LOCATION_CHANGE]: viewPage,
} as const;

export default cancelApplicationPageEvents;
