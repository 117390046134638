import { makeStyles } from '@material-ui/styles';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../../components/colors/colors';
import { Alignment } from '../../styles/layout/alignment.styles';
import breakpoint from '../../styles/breakpoints';

export const useMarketingAlertsContainerStyles = makeStyles(() => ({
  container: {
    ...Alignment.allPadding,
    maxWidth: pxToRem(1280),
  },
  contentContainer: {
    marginLeft: pxToRem(32),
    paddingRight: pxToRem(32),
    wordWrap: 'break-word',
  },
  addressTier: {
    paddingBottom: pxToRem(30),
  },
  whiteSpaceContainer: {
    paddingLeft: pxToRem(5),
  },
  electronicDelAgreementContainer: {
    paddingTop: pxToRem(10),
    marginLeft: pxToRem(30),
  },
  ePrefContainer: {
    paddingTop: pxToRem(2),
  },
  tileItem: {
    backgroundColor: Colors.white,
    wordWrap: 'break-word',
    boxShadow: `0 ${pxToRem(10)} ${pxToRem(40)} 0 rgba(0, 0, 0, 0.1)`,
    padding: pxToRem(24),
  },
  marketingErrorContainer: {
    width: '100%',
  },
  loadingIndicatorDisplay: {
    display: 'block',
  },
  marketingPreferenceGroup: {
    display: 'grid',
    [breakpoint.up('sm')]: {
      display: 'flex',
    },
    padding: `${pxToRem(12)} ${pxToRem(12)} ${pxToRem(0)}`,
  },
  marketingFormContainer: {
    display: 'contents',
  },
  marketingFormButton: {
    margin: `${pxToRem(24)} 0 ${pxToRem(12)} ${pxToRem(12)}`,
  },
}));

export default useMarketingAlertsContainerStyles;
