import axios from '../../utilities/axios';
import { CUSTOMER_INFO_URL } from '../../utilities/route-mappings';
import type { CustomerInfo, PartialCustomerInfo } from '../../utilities/types';

export type PatchCustomerResponse = CustomerInfo;

export type PatchCustomerService = (arg1: PartialCustomerInfo) => Promise<PatchCustomerResponse>;

export const patchCustomerService: PatchCustomerService = (patchCustomerData) =>
  axios.patch(CUSTOMER_INFO_URL(), patchCustomerData).then((resp) => resp.data.data);

export default patchCustomerService;
