import { Grid, withStyles, StyleRules, GridJustification, GridWrap } from '@material-ui/core';
import React, { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';
import {
  cancelBtnDataTrackTitle,
  cancelBtnDataTrackType,
  cancelBtnText,
  getBackGridButtonDataTrackTitle,
  getEditStage,
  previousBtnDataTest,
  previousButtonText,
  primaryBtnDataTest,
  shouldCancelBtnDisable,
  shouldPreviousBtnDisable,
  shouldPreviousButtonRender,
  shouldPrimaryBtnDisable,
} from '../../utilities/backNextAndCancelUtils';
import type { StyleProps, Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import GridButton from '../layout/gridButton';
import { defaultPadding } from '../../styles/layout/alignment.styles';
import { clickTrack } from '../../analytics/clickTracking.constants';
import { BackNextAndCancelBtn } from '../cms/buttonText.constants';

type Props = {
  backText?: string;
  cancelText?: string;
  cancelType?: string;
  canGoBackWhileEditing?: boolean;
  children?: ReactNode;
  className?: string;
  disableCancel?: boolean;
  disableCancelClickTrack?: boolean;
  disableGoBackClickTrack?: boolean;
  disablePrimary?: boolean;
  finalStep?: boolean;
  isEditing?: boolean;
  justify?: GridJustification;
  loading?: boolean;
  loadingSecondary?: boolean;
  nextText?: string;
  onBack?: MouseEventHandler;
  onCancel?: MouseEventHandler;
  onFinishEdit?: () => void;
  onNext?: () => void;
  wrap?: GridWrap;
  hidePrimary?: boolean;
  ePreferencesTableSection?: boolean;
  ['data-track-back']?: string;
  ['data-track-next']?: string;
  ['data-track-done']?: string;
  ['data-track-cancel']?: string;
  ['data-track-cancel-page']?: string;
  ['data-track-back-page']?: string;
  ['data-track-next-page']?: string;
  ['data-track-type']?: string;
  ['data-test-next']?: string;
  ['data-test-back']?: string;
  ['data-test-cancel']?: string;
};

const flexStart = 'flex-start';

const styles = (theme: Theme): StyleRules => ({
  backNextContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridWrapper: {
    width: '100%',
    marginTop: pxToRem(22),
  },
  rightColumn: {
    justifySelf: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      paddingTop: defaultPadding,
      justifyContent: flexStart,
      justifySelf: flexStart,
    },
  },
  cancelWrapper: {
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: { marginRight: 'auto' },
    [theme.breakpoints.down('xs')]: { flexBasis: 'auto' },
  },
  nextSubmitWrapper: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: { flexBasis: '100%' },
  },
  raisedDefault: {
    marginTop: 0,
    marginBottom: pxToRem(8),
    paddingLeft: pxToRem(30),
    paddingRight: pxToRem(30),
    [theme.breakpoints.up('sm')]: {
      marginRight: pxToRem(18),
    },
  },
  raisedPrimary: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: pxToRem(8),
    paddingLeft: pxToRem(30),
    paddingRight: pxToRem(30),
  },
  cancelDefault: {
    padding: `${pxToRem(6)} 0`,
    margin: `${pxToRem(8)} 0`,
    [theme.breakpoints.up('sm')]: {
      padding: `${pxToRem(6)} ${pxToRem(0)}`,
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${pxToRem(6)} ${pxToRem(16)}`,
      marginTop: 0,
    },
  },
  ePreferencesCancelButton: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${0} !important`,
    },
  },
  ePreferencesCancelWrapper: {
    [theme.breakpoints.up('sm')]: { marginRight: `${pxToRem(32)} !important` },
  },
});

const primaryBtnDataTrackTitle = (isEditing, finalStep, dataTrackDone, dataTrackNext) =>
  isEditing && finalStep
    ? dataTrackDone || clickTrack.general.doneEditing
    : dataTrackNext || clickTrack.general.next;
const BackNextAndCancel = ({
  backText,
  cancelText,
  cancelType,
  canGoBackWhileEditing,
  children,
  classes,
  className,
  disableCancel,
  disableCancelClickTrack,
  disableGoBackClickTrack,
  disablePrimary,
  finalStep,
  isEditing,
  justify = flexStart,
  loading,
  loadingSecondary,
  nextText,
  onBack,
  onCancel,
  onFinishEdit,
  onNext,
  wrap = 'wrap-reverse',
  hidePrimary,
  ePreferencesTableSection,
  'data-track-back': dataTrackBack,
  'data-track-next': dataTrackNext,
  'data-track-done': dataTrackDone,
  'data-track-cancel': dataTrackCancel,
  'data-track-cancel-page': dataTrackCancelPage,
  'data-track-back-page': dataTrackBackPage,
  'data-track-next-page': dataTrackNextPage,
  'data-track-type': dataTrackType,
  'data-test-next': dataTestNext,
  'data-test-back': dataTestBack,
  'data-test-cancel': dataTestCancel,
}: Props & StyleProps) => {
  const handlePrimaryClick = (e: MouseEvent) => {
    e.preventDefault();
    const isEditingFinalStepFinishEdit = getEditStage(isEditing, finalStep, onFinishEdit);
    if (isEditingFinalStepFinishEdit) {
      onFinishEdit();
    } else {
      onNext();
    }
  };

  const onBackGridButtonDataTrackTitle = getBackGridButtonDataTrackTitle(
    disableGoBackClickTrack,
    dataTrackBack
  );

  return (
    <Grid
      container
      spacing={0}
      justifyContent={justify}
      alignItems="center"
      direction="row"
      className={classnames(classes.gridWrapper, className)}
      wrap={wrap}
    >
      {onCancel && (
        <GridButton
          disableRipple
          variant="flat"
          className={classnames(classes.cancelDefault, {
            [classes.ePreferencesCancelButton]: ePreferencesTableSection,
          })}
          gridItemClass={classnames(classes.cancelWrapper, {
            [classes.ePreferencesCancelWrapper]: ePreferencesTableSection,
          })}
          onClick={onCancel}
          type={cancelType}
          disabled={shouldCancelBtnDisable(disableCancel, loading, loadingSecondary)}
          data-cancel
          data-test={dataTestCancel || 'cancel-button'}
          data-track-title={cancelBtnDataTrackTitle(disableCancelClickTrack, dataTrackCancel)}
          data-track-page={dataTrackCancelPage}
          data-track-type={cancelBtnDataTrackType(dataTrackType)}
        >
          {cancelBtnText(cancelText)}
        </GridButton>
      )}

      <div className={classes.backNextContainer}>
        {shouldPreviousButtonRender(onBack, isEditing, canGoBackWhileEditing) ? (
          <GridButton
            variant="secondary"
            onClick={onBack}
            disabled={shouldPreviousBtnDisable(loading, loadingSecondary)}
            className={classes.raisedDefault}
            data-back
            data-test={previousBtnDataTest(dataTestBack)}
            data-track-page={dataTrackBackPage}
            data-track-title={onBackGridButtonDataTrackTitle}
            loading={loadingSecondary}
          >
            {previousButtonText(backText)}
          </GridButton>
        ) : null}
        {!hidePrimary && (
          <GridButton
            variant="primary"
            color="primary"
            disabled={shouldPrimaryBtnDisable(disablePrimary, loading, loadingSecondary)}
            className={classes.raisedPrimary}
            onClick={handlePrimaryClick}
            type="submit"
            data-next
            data-test={primaryBtnDataTest(isEditing, dataTestNext)}
            data-track-page={dataTrackNextPage}
            data-track-title={primaryBtnDataTrackTitle(
              isEditing,
              finalStep,
              dataTrackDone,
              dataTrackNext
            )}
            loading={loading}
          >
            {isEditing && finalStep
              ? BackNextAndCancelBtn.DONE_EDITING
              : nextText || BackNextAndCancelBtn.NEXT_STEP}
          </GridButton>
        )}
      </div>

      {children}
    </Grid>
  );
};

export default withStyles(styles)(BackNextAndCancel);
