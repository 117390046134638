import { makeStyles } from '@material-ui/core/styles';

export const svgImageWrapperStyles = makeStyles({
  outerDivContainer: {
    '& > div': {
      display: 'contents',
    },
    display: 'contents',
  },
});

export default svgImageWrapperStyles;
