import React, { useState } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import classNames from 'classnames';
import Button from 'bank-component-library/ui/atoms/Button';
import { useMarketingAlertsContainerStyles } from './marketingAlerts.styles';
import {
  Header2,
  StandardTextPreferences,
  StandardTextEmphasis,
  InlineLinkText,
  SubtitleText,
} from '../../components/typography/typography';

import type { Dispatch } from '../../utilities/types';
import { setFlashMessage } from '../../components/flashMessage/flashMessage.reducer';
import {
  OffersPageFieldNames,
  EPreferenceTexts,
  FormValues,
  MarketingPreferenceContentProps,
} from './marketingAlerts.constants';
import type { MarketingAlertsInfo } from './marketingAlerts.constants';
import { fetchMarketingAlerts, updateMarketingAlerts } from './marketingAlerts.service';
import useEffectOnce from '../../utilities/reactHooks';
import { RenderCheck } from '../../components/finalForm/finalFormInputs';
import Error from '../../components/error/error';
import ArrowLink from '../../components/links/arrowLink';
import Routes from '../routes/routes.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import {
  FlashMessageVariant,
  FlashMessageText,
} from '../../components/flashMessage/flashMessage.constants';
import { AccountActivityBtn, ModalBtnText } from '../../components/cms/buttonText.constants';
import type { MarketingAlertsAnalyticsSubmitAttr } from '../../analytics/actions';
import {
  ANALYTICS_MARKETING_ALERTS_START_SUCCESS,
  ANALYTICS_MARKETING_ALERTS_START_FAILURE,
  ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS,
  ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE,
} from '../../analytics/actions';
import RenderEmailOrNoEmail from '../../components/email/renderEmailOrNoEmail';
import pageTrack from '../../analytics/pageAnalytics.constants';
import { ePreferencesFeatureFlag } from '../ePreferences/ePreferences.constants';
import LoadingIndicator from '../../components/loadingIndicator/loadingIndicator';

const pageViewActions = Object.freeze({
  startSuccess: ANALYTICS_MARKETING_ALERTS_START_SUCCESS,
  startFailure: ANALYTICS_MARKETING_ALERTS_START_FAILURE,
  submitSuccess: ANALYTICS_MARKETING_ALERTS_SUBMIT_SUCCESS,
  submitFailure: ANALYTICS_MARKETING_ALERTS_SUBMIT_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type DispatchProps = {
  recordAnalyticsPageView: (
    pageViewAction: PageViewAction,
    payload?: MarketingAlertsAnalyticsSubmitAttr
  ) => void;
  setErrorFlashMessage: (arg1?: string | null | undefined) => void;
  setSuccessFlashMessage: () => void;
};

type MarketingAlertsType = MarketingAlertsInfo | null | undefined;

type MarketingAlertsProps = {
  ePreferenceStyle: ClassNameMap;
};

type MarketingAlertsInfoType = MarketingAlertsInfo | null;

export const lgGridContainer = ePreferencesFeatureFlag ? 12 : 10;
export const LgEmailGridItem = ePreferencesFeatureFlag ? 8 : 6;
export const LgMailGridItem = ePreferencesFeatureFlag ? 4 : 6;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  recordAnalyticsPageView: (
    pageViewAction: PageViewAction,
    payload?: MarketingAlertsAnalyticsSubmitAttr
  ) => dispatch({ type: pageViewAction, payload }),
  setErrorFlashMessage: (message) =>
    dispatch(
      setFlashMessage({
        messageText: message || FlashMessageText.GENERIC_ERROR,
        messageType: FlashMessageVariant.ERROR,
      })
    ),
  setSuccessFlashMessage: () =>
    dispatch(
      setFlashMessage({
        messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
        messageType: FlashMessageVariant.SUCCESS,
      })
    ),
});

const buildInitialValues = (marketingAlertsInfo?: MarketingAlertsInfoType) => {
  if (!marketingAlertsInfo) {
    return null;
  }
  return {
    [OffersPageFieldNames.EMAIL]:
      marketingAlertsInfo.marketingPreferences.email &&
      marketingAlertsInfo.marketingPreferences.email.enrolled,
    [OffersPageFieldNames.MAIL]:
      marketingAlertsInfo.marketingPreferences.mail &&
      marketingAlertsInfo.marketingPreferences.mail.enrolled,
  };
};

const renderEmailAddress = (marketingAlerts: MarketingAlertsType, classes: ClassNameMap) => {
  const emailAddress =
    marketingAlerts &&
    marketingAlerts.marketingPreferences &&
    marketingAlerts.marketingPreferences.email &&
    marketingAlerts.marketingPreferences.email.emailAddress;

  return (
    <Grid container className={classes.contentContainer}>
      <Grid item xs={12}>
        <RenderEmailOrNoEmail
          dataTest={emailAddress ? 'email-marketingPref' : 'no-email-marketingPref'}
          hasNoLabel
          emailAddress={emailAddress}
        />
      </Grid>
    </Grid>
  );
};

const renderMailingAddress = (marketingAlerts: MarketingAlertsType, classes: ClassNameMap) => {
  const mailingAddressLine1 =
    marketingAlerts &&
    marketingAlerts.marketingPreferences &&
    marketingAlerts.marketingPreferences.mail &&
    marketingAlerts.marketingPreferences.mail.mailingAddress &&
    marketingAlerts.marketingPreferences.mail.mailingAddress.line2;
  const line3 =
    marketingAlerts &&
    marketingAlerts.marketingPreferences &&
    marketingAlerts.marketingPreferences.mail &&
    marketingAlerts.marketingPreferences.mail.mailingAddress &&
    marketingAlerts.marketingPreferences.mail.mailingAddress.line3;
  const zipCode =
    marketingAlerts &&
    marketingAlerts.marketingPreferences &&
    marketingAlerts.marketingPreferences.mail &&
    marketingAlerts.marketingPreferences.mail.mailingAddress &&
    marketingAlerts.marketingPreferences.mail.mailingAddress.zipCode;

  const mailingAddressLine2 = line3 && zipCode && `${line3} ${zipCode}`;

  return (
    <Grid container className={classes.contentContainer}>
      {mailingAddressLine1 ? (
        <Grid item>
          <Grid item>
            <SubtitleText className={classes.contentSpace}>{mailingAddressLine1}</SubtitleText>
          </Grid>
          <Grid item>
            <SubtitleText>{mailingAddressLine2}</SubtitleText>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={10}>
          <p data-test="no-address-marketingPref">
            <SubtitleText>{EPreferenceTexts.NO_MAIL_ON_FILE}</SubtitleText>
            <ArrowLink
              to={Routes.PROFILE}
              data-test="quick-links-add-mailing-address"
              data-track-title={clickTrack.profile.add_address}
              data-track-type={clickTrackType.LINK}
              role="link"
              className={classes.whiteSpaceContainer}
            >
              {AccountActivityBtn.ADD_ADDRESS}
            </ArrowLink>
          </p>
        </Grid>
      )}
    </Grid>
  );
};

const renderAgreementLink = (marketingAlerts: MarketingAlertsType, classes: ClassNameMap) => {
  const emailAddress =
    marketingAlerts &&
    marketingAlerts.marketingPreferences &&
    marketingAlerts.marketingPreferences.email &&
    marketingAlerts.marketingPreferences.email.emailAddress;

  return emailAddress ? (
    <Grid item xs={12} className={classes.electronicDelAgreementContainer}>
      <InlineLinkText
        component="a"
        rel="noopener noreferrer"
        data-test="electronic-delivery-link"
        href="https://www.synchronybank.com/sites/syfbank/documents/electronic-delivery-terms-conditions.pdf"
        target="_blank"
      >
        <StandardTextEmphasis>{EPreferenceTexts.ELECTRONIC_AGREEMENT}</StandardTextEmphasis>
      </InlineLinkText>
    </Grid>
  ) : null;
};

const verifyAnalyticsAttr = (
  analyticsAttr: MarketingAlertsAnalyticsSubmitAttr,
  formValues: FormValues
) => {
  let analyticsAttrFinal;
  if (formValues[OffersPageFieldNames.EMAIL] && formValues[OffersPageFieldNames.MAIL]) {
    analyticsAttrFinal = pageTrack.attr.both;
  } else if (formValues[OffersPageFieldNames.EMAIL]) {
    analyticsAttrFinal = pageTrack.attr.email;
  } else if (formValues[OffersPageFieldNames.MAIL]) {
    analyticsAttrFinal = pageTrack.attr.mail;
  } else {
    analyticsAttrFinal = pageTrack.attr.none;
  }
  return analyticsAttrFinal;
};

const MarketingPreferenceContent = (props: MarketingPreferenceContentProps) => {
  const { classes, isLoading, marketingAlerts, onSubmit } = props;
  const marketingHeader = ePreferencesFeatureFlag
    ? EPreferenceTexts.TITLE
    : EPreferenceTexts.PREFERENCE_TITLE;
  return (
    <Grid
      container
      className={classNames(
        { [classes.tileItem]: ePreferencesFeatureFlag },
        { [classes.container]: !ePreferencesFeatureFlag },
        { [classes.loadingIndicatorDisplay]: isLoading },
        classes.ePrefContainer
      )}
      spacing={3}
    >
      {isLoading ? (
        <LoadingIndicator aria-live="assertive" />
      ) : (
        <>
          <Grid item xs={12}>
            <Header2 data-test="offers-header">{marketingHeader}</Header2>
          </Grid>
          <Grid item container>
            <p>{EPreferenceTexts.SELECT_STATEMENT}</p>
          </Grid>
          <Form onSubmit={onSubmit} initialValues={buildInitialValues(marketingAlerts)}>
            {({ handleSubmit, form, submitting, pristine }) => (
              <Grid
                container
                component="form"
                onSubmit={handleSubmit}
                className={classes.marketingFormContainer}
              >
                <Grid
                  item
                  role="group"
                  aria-labelledby="email mail"
                  xs={12}
                  className={classes.marketingPreferenceGroup}
                >
                  <Grid item xs={12} sm={8} md={6} lg={LgEmailGridItem}>
                    <Field
                      data-test="email-checkbox"
                      type="checkbox"
                      id={OffersPageFieldNames.EMAIL}
                      name={OffersPageFieldNames.EMAIL}
                      label={<StandardTextPreferences>Email</StandardTextPreferences>}
                      labelId={`${OffersPageFieldNames.EMAIL}LabelId`}
                      component={RenderCheck}
                    />
                    {renderEmailAddress(marketingAlerts, classes)}

                    {renderAgreementLink(marketingAlerts, classes)}
                  </Grid>
                  <Grid item xs={12} sm={4} md={6} lg={LgMailGridItem}>
                    <Field
                      data-test="mail-checkbox"
                      type="checkbox"
                      id={OffersPageFieldNames.MAIL}
                      name={OffersPageFieldNames.MAIL}
                      label={<StandardTextPreferences>Mail</StandardTextPreferences>}
                      labelId={`${OffersPageFieldNames.MAIL}LabelId`}
                      component={RenderCheck}
                    />
                    {renderMailingAddress(marketingAlerts, classes)}
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.marketingFormButton}>
                  <Button
                    data-test="save-changes-button"
                    disabled={
                      submitting ||
                      (pristine &&
                        !(
                          pristine[OffersPageFieldNames.EMAIL] ||
                          pristine[OffersPageFieldNames.MAIL]
                        ))
                    }
                    isLoading={submitting}
                    onClick={async (event) => {
                      let newMarketingAlertsInfo;
                      try {
                        newMarketingAlertsInfo = await handleSubmit(event);
                      } catch {
                        // do not reset on error;
                        return;
                      }
                      if (newMarketingAlertsInfo) {
                        form.reset(buildInitialValues(newMarketingAlertsInfo ?? undefined));
                      }
                    }}
                    type="submit"
                  >
                    {ModalBtnText.SAVE_CHANGES}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        </>
      )}
    </Grid>
  );
};

export const MarketingPreference = ({
  recordAnalyticsPageView,
  setErrorFlashMessage,
  setSuccessFlashMessage,
  ePreferenceStyle,
}: DispatchProps & MarketingAlertsProps) => {
  const classes = useMarketingAlertsContainerStyles();
  const [marketingAlerts, setMarketingAlerts] = useState<MarketingAlertsType>();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getMarketingAlerts = async () => {
    let marketingAlertsInfo;
    setHasError(false);
    try {
      marketingAlertsInfo = await fetchMarketingAlerts();
    } catch {
      setHasError(true);
      recordAnalyticsPageView(pageViewActions.startFailure);
      return;
    }
    setIsLoading(false);
    setMarketingAlerts(marketingAlertsInfo);
    recordAnalyticsPageView(pageViewActions.startSuccess);
  };

  useEffectOnce(() => {
    setIsLoading(true);
    getMarketingAlerts();
  });
  const onSubmit = async (formValues: FormValues) => {
    let updatedMarketingAlerts;
    const request = {
      marketingPreferences: {
        email: {
          enrolled: formValues[OffersPageFieldNames.EMAIL],
        },
        mail: {
          enrolled: formValues[OffersPageFieldNames.MAIL],
        },
      },
    } as const;

    let analyticsAttrtext: MarketingAlertsAnalyticsSubmitAttr;
    const analyticsAttr = verifyAnalyticsAttr(analyticsAttrtext, formValues);

    try {
      updatedMarketingAlerts = await updateMarketingAlerts(request);
    } catch (err) {
      setErrorFlashMessage(err && err.data && err.data.message);
      recordAnalyticsPageView(pageViewActions.submitFailure, analyticsAttr);
      // Rethrow error to inform the form not to reset.
      throw err;
    }
    setSuccessFlashMessage();
    recordAnalyticsPageView(pageViewActions.submitSuccess, analyticsAttr);
    if (updatedMarketingAlerts) {
      setMarketingAlerts(updatedMarketingAlerts);
    }
    return updatedMarketingAlerts;
  };

  return (
    <div
      data-test="marketing-epreferences"
      className={classNames({
        [ePreferenceStyle.containerNewDesign]: ePreferencesFeatureFlag,
        [classes.marketingErrorContainer]: ePreferencesFeatureFlag,
      })}
    >
      {hasError ? (
        <Grid
          container
          spacing={3}
          className={classNames({ [classes.tileItem]: ePreferencesFeatureFlag })}
        >
          <Error
            onClick={() => {
              getMarketingAlerts();
            }}
            center
          />
        </Grid>
      ) : (
        <MarketingPreferenceContent
          classes={classes}
          isLoading={isLoading}
          marketingAlerts={marketingAlerts}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(MarketingPreference);
