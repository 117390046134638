import Routes from '../containers/routes/routes.constants';

/**
 *
 * @returns boolean indicating whether the current route should display the fdicLogo
 */
const shouldFdicLogoRender = (): boolean => {
  const fdicDisplayRoutes = [
    `${Routes.ACCOUNT_DETAILS_PREFIX}/Certificate`,
    Routes.CD_INTEREST_PAYMENTS_PREFIX,
    Routes.CD_RENEWAL_CONFIRMATION_PREFIX,
    Routes.CD_RENEWAL_INSTRUCTIONS_PREFIX,
    Routes.CD_RENEWAL_PREFIX,
    Routes.DIRECT_NEW_ACCOUNT_FUNDING_PREFIX,
    Routes.EAO_NEW_ACCOUNT_FUNDING_PREFIX,
    Routes.EDIT_TRANSFER_PREFIX,
    Routes.NAO_NEW_ACCOUNT_FUNDING,
    Routes.NEW_TRANSFER,
    Routes.TRANSFERS_DASHBOARD,
    Routes.EXTERNAL_ACCOUNTS,
    Routes.ADD_EXTERNAL_ACCOUNT_OPTIONS,
  ];

  return !!fdicDisplayRoutes.filter((route) => window.location.pathname.includes(route)).length;
};

export default shouldFdicLogoRender;
