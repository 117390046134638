import React from 'react';
import type { ReactNode } from 'react';
import DOMPurify from 'dompurify';
import { Modal, withStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import type { StyleProps } from '../../utilities/types';
import {
  InformationalSectionHeaderBold,
  StandardText,
  LargestHeader,
} from '../typography/typography';
import styles from './igniteModal.styles';
import { theme } from '../../styles/createTheme';
import pxToRem from '../../utilities/pxToRem';
import IconButton from '../buttons/iconButton';
import SVGImage from '../svgImage';
import { CloseIconSVG } from '../svg/svg';

type TitleComponentProps = 'LargestHeader' | '';

type IgniteModalTitleProps = {
  titleText: string;
  className?: string;
  id?: string;
  component?: TitleComponentProps;
};

type IgniteModalContentProps = {
  variant?: string;
  children?: React.ReactNode;
};

type IgniteModalProps = {
  visible: boolean;
  ['data-test']?: string;
  children: ReactNode;
  isWide?: boolean;
  title?: string;
  titleComponent?: TitleComponentProps;
  showCloseX?: boolean;
  onClose?: any;
  onBackdropClick?: any;
  onRendered?: any;
  className?: string;
  onEscapeKeyDown?: () => void;
  disableBackdropClick?: boolean;
  ['aria-label']?: string;
  ['aria-labelledby']?: string;
};

type IgniteModalActionsProps = {
  children?: React.ReactNode;
  isCL?: boolean;
};

export const IgniteModalTitle = (props: IgniteModalTitleProps) => {
  if (props.component === 'LargestHeader') {
    return (
      <LargestHeader data-test="modal-title" id={props.id} className={props.className}>
        {props.titleText}
      </LargestHeader>
    );
  }
  return (
    <InformationalSectionHeaderBold
      component="h1"
      data-test="modal-title"
      id={props.id}
      className={props.className}
    >
      {props.titleText}
    </InformationalSectionHeaderBold>
  );
};

const useIgniteModalActionsStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    // PrimaryButton has a built-in right margin but in this case we need it to be right-aligned
    '& > button:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  /** BCL buttons do not have any built in margin */
  rootBCL: { marginTop: pxToRem(22), '& > button': { marginRight: pxToRem(16) } },
  overlayImageDy: {
    width: '100%',
  },
  overlayImageHolder: {
    margin: `${pxToRem(2)} ${pxToRem(2)} ${pxToRem(20)} 0`,
  },
});

export const IgniteModalActions = (props: IgniteModalActionsProps) => {
  const { isCL } = props;
  const classes = useIgniteModalActionsStyles();
  const { root, rootBCL } = classes;
  const className = `${root} ${isCL ? rootBCL : ''}`;
  return (
    <Grid container className={className} data-test="modal-actions">
      {props.children}
    </Grid>
  );
};

export const IgniteModalContent = (props: IgniteModalContentProps) => {
  let modalContent;
  let overlayImage;
  let overlayDescription;

  if (props.variant === 'image') {
    overlayImage = props?.children;
  } else {
    overlayDescription = props?.children;
  }

  const classes = useIgniteModalActionsStyles();
  if (props.variant === 'text' && overlayDescription) {
    modalContent = (
      <StandardText data-test="modal-text">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(overlayDescription, { ADD_ATTR: ['target'] }),
          }}
        />
      </StandardText>
    );
  } else if (props.variant === 'image' && overlayImage) {
    modalContent = (
      <div data-test="modal-image" className={classes.overlayImageHolder}>
        <img className={classes.overlayImageDy} src={overlayImage} alt="overlay" />
      </div>
    );
  } else {
    modalContent = <> {overlayDescription} </>;
  }
  return modalContent;
};

const IgniteModal = (props: IgniteModalProps & StyleProps) => {
  const { classes } = props;

  const ariaAttributes: Record<string, any> = {};
  if (props.title || props['aria-labelledby']) {
    ariaAttributes['aria-labelledby'] = props['aria-labelledby'] || 'modal-title';
  } else {
    ariaAttributes['aria-label'] = props['aria-label'] || 'Modal';
  }

  return (
    <Modal
      data-test={props['data-test']}
      open={props.visible}
      role="dialog"
      tabIndex={-1}
      aria-modal="true"
      {...ariaAttributes}
      className={classnames(classes.modal, props.className)}
      ref={props.onRendered}
      onClose={props.onBackdropClick || props.onEscapeKeyDown}
    >
      <div
        data-test="modal-wrapper"
        className={classnames(classes.paper, classes.paperBackground, classes.themedOutline, {
          [classes.wide]: props.isWide,
        })}
      >
        {props.showCloseX && (
          <div className={classes.closeContainer} data-test="modal-close-container">
            <IconButton onClick={props.onClose} aria-label="Close">
              <SVGImage
                iconComponent={
                  <CloseIconSVG className={classes.closeIcon} aria-hidden="true" role="img" />
                }
              />
            </IconButton>
          </div>
        )}
        <div className={classes.modalContentWrapper}>
          <div className={classes.modalContent}>
            {props.title && (
              <IgniteModalTitle
                component={props.titleComponent}
                id="modal-title"
                className={classes.modalTitle}
                titleText={props.title}
              />
            )}
            {props.children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const defaultExport = withStyles(styles)(IgniteModal);
defaultExport.displayName = 'IgniteModal';
export default defaultExport;
