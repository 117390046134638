import React from 'react';
import type { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Grid, StyleRules, withStyles } from '@material-ui/core';
import { History } from 'history';
import type { Theme, StyleProps } from '../../utilities/types';
import Error from './error';
import FooterWide from '../footer/footer';
import Logo from '../logo/logo';
import { Alignment } from '../../styles/layout/alignment.styles';
import { setErrorNotice } from '../../vendor/newrelic/config';
import pxToRem from '../../utilities/pxToRem';
import shouldFdicLogoRender from '../../utilities/shouldFdicLogoRender';
import FDICLogo from '../fdicLogo/fdicLogo';

const styles = (theme: Theme): StyleRules => ({
  root: {
    minHeight: '100vh',
  },
  header: {
    ...Alignment.horizontalPadding,
    marginTop: pxToRem(48),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
});

type Props = {
  children: ReactNode;
  history: History;
};

type State = {
  hasError: boolean;
};

type ErrorBoundaryProps = Props & StyleProps & RouteComponentProps;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    this.unListen = this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    // if new relic, then report
    if (error) {
      setErrorNotice(error);
    }

    this.setState({ hasError: true });
  }

  componentWillUnmount() {
    // Its good to unlisten as per docs https://www.npmjs.com/package/history
    this.unListen();
  }

  unListen = () => {
    /* This is intentional to avoid eslint issue i.e sonarqube critcal issue */
  };

  render() {
    const { children, classes } = this.props;

    if (!this.state.hasError) {
      return children;
    }

    return (
      <Grid className={classes.root} container direction="column" wrap="nowrap" spacing={0}>
        <Grid item className={classes.header}>
          <Logo isDark isWithFdicLogo />
          {shouldFdicLogoRender() && <FDICLogo />}
        </Grid>
        <Error center />
        <Grid item>
          <FooterWide />
        </Grid>
      </Grid>
    );
  }
}

const styled = withStyles(styles)(ErrorBoundary);
styled.displayName = 'ErrorBoundaryStyled';
export default withRouter(styled);
