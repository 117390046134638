import type { NavLink } from './nav.types';
import type { Account } from '../../utilities/types';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import Routes, { showEpreferencesLink } from '../../containers/routes/routes.constants';
import labelsConstants from '../cms/labels.constants';

export const getNavLinks = (accounts: Account[], showCombinedStatements: boolean): NavLink[] => {
  const links: NavLink[] = [
    {
      label: labelsConstants.PROFILE,
      value: 'profile',
      to: Routes.PROFILE,
      dataTest: 'my-profile-link',
      dataTrackType: clickTrackType.LINK,
      dataTrackTitle: clickTrack.accountManagement.my_profile,
    },
    {
      label: labelsConstants.SECURITY,
      value: 'security',
      to: Routes.SECURITY,
      dataTest: 'security-link',
      dataTrackType: clickTrackType.LINK,
      dataTrackTitle: clickTrack.accountManagement.security,
    },
  ];
  if (window.__config__.IGNITE_OFFERS_ENABLED !== 'true') {
    links.push({
      label: labelsConstants.OFFERS,
      dataTest: 'offers-link',
      to: '',
      dataTrackType: clickTrackType.LINK,
      dataTrackTitle: clickTrack.accountManagement.offers,
    });
  }
  if (window.__config__.IGNITE_BENEFICIARIES_ENABLED === 'true') {
    links.push({
      label: labelsConstants.BENEFICIARIES,
      value: 'Beneficiaries',
      to: Routes.BENEFICIARIES,
      dataTest: 'beneficiaries-link',
      dataTrackTitle: clickTrack.accountManagement.beneficiaries,
    });
  }
  if (showEpreferencesLink(accounts)) {
    links.push({
      label: labelsConstants.E_PREFERENCES_ACCOUNTS,
      value: 'ePreferences',
      to: Routes.PREFERENCES,
      dataTest: 'preferences-link',
      dataTrackTitle: clickTrack.accountManagement.go_paperless,
    });
  }

  links.push({
    label: labelsConstants.CANCEL_CARD_SIDEBAR,
    value: 'cancelCard',
    to: Routes.CANCEL_CARD,
    dataTest: 'cancel-card-link',
    dataTrackTitle: clickTrack.accountManagement.atm_cards,
  });

  links.push({
    label: labelsConstants.ALERTS,
    value: 'Alerts',
    to: Routes.ALERTS,
    dataTest: 'account-alerts-link',
    dataTrackTitle: clickTrack.accountManagement.account_alerts,
  });

  if (showCombinedStatements && window.__config__.COMBINED_STATEMENTS_ENABLED === 'true') {
    links.push({
      label: labelsConstants.COMBINED_STATEMENTS,
      value: 'combinedStatements',
      to: Routes.COMBINED_STATEMENTS,
      dataTest: 'combined-statements-link',
      dataTrackTitle: clickTrack.accountManagement.combined_statements,
    });
  }

  return links;
};

export default getNavLinks;
