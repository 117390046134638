import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import { ANALYTICS_YODLEE_START_FAILURE, ANALYTICS_YODLEE_START_SUCCESS } from '../actions';

const initData = {
  account_type: 'generic',
  pagekind: 'transfers',
  pagefunction: 'add_external_bank',
  pagesubfunction: 'servicing',
  attr: pageTrack.attr.yodlee,
} as const;

const viewSuccess = () => {
  pageAnalytics
    .newPageView(initData)
    .addInfo({
      pagename: 'start',
    })
    .record();
};

const viewFailure = () => {
  pageAnalytics
    .newPageView(initData)
    .addInfo({
      pagename: 'start_submit_error',
    })
    .record();
};

export const addExternalYodleeAccountPageEvents = {
  [ANALYTICS_YODLEE_START_SUCCESS]: viewSuccess,
  [ANALYTICS_YODLEE_START_FAILURE]: viewFailure,
} as const;

export default addExternalYodleeAccountPageEvents;
