import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { OpenAccountAction } from '../../containers/newAccountOpening/newUser/openAccount.reducer';
import type { SubmitApplicationTransmitAnalyticsAction } from '../actions';
import {
  ANALYTICS_INITIATE_OTP_TRANSMIT,
  ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE,
  ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR,
} from '../actions';
import type { ReduxState } from '../../reducers';
import { getPageFunctionTypeForOTP, isNAOTransmitFlow } from '../utilities';
import { TRANSMIT_ANALYTICS_RESPONSE } from '../../containers/otp/otp.constants';

const viewTransmitInit = (state: ReduxState) => {
  return pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.account_opening,
    pagefunction: getPageFunctionTypeForOTP(state),
  });
};

const viewTransmitOtp = (action: OpenAccountAction, state: ReduxState) => {
  viewTransmitInit(state)
    .addInfo({
      pagename: pageTrack.pagename.interstitial,
      pagesubfunction: isNAOTransmitFlow(state),
      pagefunction: pageTrack.pagefunction.transmit,
    })
    .record();
};

const completeTransmitOtp = (action: OpenAccountAction, state: ReduxState) => {
  viewTransmitInit(state)
    .addInfo({
      pagename: pageTrack.pagename.complete,
      pagefunction: state.authenticate.isLoggedIn
        ? pageTrack.pagefunction.review
        : pageTrack.pagefunction.ao_otp,
      pagesubfunction: isNAOTransmitFlow(state),
      transmitStatus: TRANSMIT_ANALYTICS_RESPONSE.STATUS_SUCCESS,
    })
    .record();
};

const submitApplicationTransmitError = (
  action: SubmitApplicationTransmitAnalyticsAction,
  state: ReduxState
) => {
  let pageName;
  if (parseInt(action.payload, 10) >= 500) {
    pageName = pageTrack.pagename.server_error;
  } else if (parseInt(action.payload, 10) >= 400) {
    pageName = pageTrack.pagename.application_failed_server_error;
  } else {
    pageName = '';
  }

  if (pageName) {
    viewTransmitInit(state)
      .addInfo({
        pagename: pageName,
        pagesubfunction: isNAOTransmitFlow(state),
        pagefunction: pageTrack.pagefunction.review,
      })
      .record();
  }
};

export const initiateOtpTransmitPageEvents = {
  [ANALYTICS_INITIATE_OTP_TRANSMIT]: viewTransmitOtp,
  [ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE]: completeTransmitOtp,
  [ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR]: submitApplicationTransmitError,
} as const;

export default initiateOtpTransmitPageEvents;
