export const CHOOSE_FUNDING_SECTION_ID = 'chooseFunding';

export const FUNDING_TYPE = 'accountFundingType';

export const EXTERNAL_FUND_SOURCE = 'selectedExternalFundSource';
export const NEW_EXTERNAL_FUND_SOURCE = 'selectedNewExternalFundSource';
export const INTERNAL_FUND_SOURCE = 'selectedInternalFundSource';
export const AND_MORE_TEXT = '... and more!';
export const FAST_LINK_HEADER_TEXT = 'Instantly Sign In and Link Your Account';
export const ENTER_ROUTING_NUMBER = 'Enter Routing and Account Number';
export const ENTER_ROUTING_NUMBER_LABEL =
  'Can take up to 2–3 business days to verify your account.';
