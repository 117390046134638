import { makeStyles } from '@material-ui/styles';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../../components/colors/colors';
import breakpoint from '../../styles/breakpoints';
import { Alignment } from '../../styles/layout/alignment.styles';

export const useAccountAlertsStyles = makeStyles((theme: Theme) => ({
  alertsList: {
    maxWidth: pxToRem(650),
  },
  accountName: {
    fontSize: pxToRem(24),
    marginTop: pxToRem(8),
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(16),
    },
  },
  editAccountSubText: {
    marginTop: pxToRem(8),
  },
  alertAmount: {
    marginBottom: pxToRem(18),
  },
  alertDescription: {
    marginBottom: pxToRem(8),
  },
  balanceAlertsHeader: {
    borderBottom: `1px solid ${Colors.divider}`,
    paddingBottom: pxToRem(8),
    marginTop: pxToRem(24),
    [theme.breakpoints.up('md')]: {
      marginRight: `calc(50% + ${pxToRem(48)})`,
    },
  },
  buttonContainer: {
    textAlign: 'right',
  },
  editAlertsContainer: {
    ...Alignment.horizontalPadding,
    // fix IE width
    display: 'block',
    paddingTop: pxToRem(24),
    paddingBottom: pxToRem(24),
    backgroundColor: Colors.white,
    marginBottom: pxToRem(40),
    border: `1px solid ${Colors.divider}`,

    '&:focus': {
      outline: `1px solid ${Colors.optimumBlue}`,
      boxShadow: `0 6px 12px 0 rgba(0, 104, 153, 0.3)`,
    },
  },
  editAlertsNoFocus: {
    '&:focus': {
      outline: `none`,
      boxShadow: `none`,
    },
  },
  editAlertsFieldGroup: {
    padding: `${pxToRem(4)} ${pxToRem(16)} ${pxToRem(16)}`,
    borderBottom: `1px solid ${Colors.divider}`,
    [theme.breakpoints.up('md')]: {
      padding: `${pxToRem(4)} ${pxToRem(32)} ${pxToRem(16)}`,
    },
  },
  editAlertsListContainer: {
    [theme.breakpoints.up('md')]: {
      paddingRight: pxToRem(48),
    },
  },
  fullHeightLayout: {
    padding: 0,
    overflow: 'hidden',
  },
  mobileEditButton: {
    margin: `${pxToRem(8)} 0`,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formButtonContainer: {
    marginTop: pxToRem(32),
  },
  formCheckBox: {
    margin: 'auto',

    // Offsetting the Grid padding to center the checkbox
    transform: 'translate(0, 6px)',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-8px, 6px)',
    },
  },
  formBalance: {
    fontWeight: 500,
  },
  formMobileCancelButton: {
    // To match Save button
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  editIconSvg: {
    width: pxToRem(18),
    height: pxToRem(18),
  },
  editButton: {
    [breakpoint.up('sm')]: {
      paddingLeft: 0,
    },
  },
}));

export default useAccountAlertsStyles;
