import React from 'react';
import { BodyTextSmall } from '../typography/typography';
import ArrowLinkButton from './arrowLinkButton';
import { FlashMessageText } from '../flashMessage/flashMessage.constants';
import Colors from '../colors/colors';

type Props = {
  'data-test': string;
  disabledText: string;
  hasError: boolean;
  hasMore: boolean;
  isLoading: boolean;
  loadingText: string;
  loadMoreText: string;
  onClick: () => void;
  ['data-track-title']?: string;
};

export const LoadMoreButton = ({
  'data-test': dataTest,
  disabledText,
  hasError,
  hasMore,
  isLoading,
  loadingText,
  loadMoreText,
  onClick,
  'data-track-title': dataTrackTitle,
}: Props) => {
  let buttonText;
  if (isLoading) {
    buttonText = loadingText;
  } else if (hasMore) {
    buttonText = loadMoreText;
  } else {
    buttonText = disabledText;
  }

  const loadMoreLinkCondition = !hasMore || isLoading;

  return (
    <>
      <ArrowLinkButton
        data-test={dataTest}
        onClick={onClick}
        arrowDirection="down"
        isLoading={isLoading}
        disabled={loadMoreLinkCondition}
        aria-hidden={loadMoreLinkCondition ? true : undefined}
        buttonText={buttonText}
        data-track-title={dataTrackTitle}
      />
      {hasError && (
        <div>
          <BodyTextSmall textColor={Colors.inlineError}>
            {FlashMessageText.GENERIC_ERROR}
          </BodyTextSmall>
        </div>
      )}
    </>
  );
};

export default LoadMoreButton;
