import type {
  AdjustmentResponse,
  RenewalInstructionsResponse,
  ReInvestmentProductResponse,
} from './cdRenewal.service';

export type CDRenewalState = {
  reinvestmentProduct?: ReInvestmentProductResponse;
  fundsAdjustment?: AdjustmentResponse;
  tentativeMaturityDate?: string;
  isLoading: boolean;
  hasError: boolean;
  isRemoveInstructions?: boolean;
  isRemoveInstructionsFlow?: boolean;
};

export const ACTION_GET_CD_MATURITY_DETAILS_REQUEST = 'ACTION_GET_CD_MATURITY_DETAILS_REQUEST';
export const ACTION_GET_CD_MATURITY_DETAILS_SUCCESS = 'ACTION_GET_CD_MATURITY_DETAILS_SUCCESS';
export const ACTION_GET_CD_MATURITY_DETAILS_ERROR = 'ACTION_GET_CD_MATURITY_DETAILS_ERROR';
export const ACTION_CLEAR_CD_MATURITY_DETAILS = 'ACTION_CLEAR_CD_MATURITY_DETAILS';
export const ACTION_REMOVE_CD_INSTRUCTIONS_DETAILS = 'ACTION_REMOVE_CD_INSTRUCTIONS_DETAILS';
export const ACTION_ADDED_CD_INSTRUCTIONS_DETAILS = 'ACTION_ADDED_CD_INSTRUCTIONS_DETAILS';
export const ACTION_REMOVE_CD_INSTRUCTIONS_FLOW = 'ACTION_REMOVE_CD_INSTRUCTIONS_FLOW';
export const ACTION_ADDED_CD_INSTRUCTIONS_FLOW = 'ACTION_ADDED_CD_INSTRUCTIONS_FLOW';

export type CDRenewalAction =
  | {
      type: typeof ACTION_GET_CD_MATURITY_DETAILS_REQUEST;
    }
  | {
      type: typeof ACTION_GET_CD_MATURITY_DETAILS_SUCCESS;
      payload: RenewalInstructionsResponse;
    }
  | {
      type: typeof ACTION_GET_CD_MATURITY_DETAILS_ERROR;
    }
  | {
      type: typeof ACTION_CLEAR_CD_MATURITY_DETAILS;
    }
  | {
      type: typeof ACTION_REMOVE_CD_INSTRUCTIONS_DETAILS;
    }
  | {
      type: typeof ACTION_ADDED_CD_INSTRUCTIONS_DETAILS;
    }
  | {
      type: typeof ACTION_REMOVE_CD_INSTRUCTIONS_FLOW;
    }
  | {
      type: typeof ACTION_ADDED_CD_INSTRUCTIONS_FLOW;
    };

export const getInitialState = (): CDRenewalState => ({ isLoading: false, hasError: false });

export default (
  state: CDRenewalState | null | undefined = getInitialState(),
  action: CDRenewalAction = undefined
): CDRenewalState => {
  switch (action.type) {
    case ACTION_GET_CD_MATURITY_DETAILS_REQUEST: {
      return { ...state, isLoading: true };
    }
    case ACTION_GET_CD_MATURITY_DETAILS_SUCCESS: {
      return { ...state, ...action.payload, hasError: false, isLoading: false };
    }
    case ACTION_GET_CD_MATURITY_DETAILS_ERROR: {
      return { ...state, hasError: true, isLoading: false };
    }
    case ACTION_CLEAR_CD_MATURITY_DETAILS: {
      return {
        ...state,
        reinvestmentProduct: undefined,
        fundsAdjustment: undefined,
        tentativeMaturityDate: undefined,
      };
    }
    case ACTION_REMOVE_CD_INSTRUCTIONS_DETAILS: {
      return {
        ...state,
        isRemoveInstructions: true,
      };
    }
    case ACTION_ADDED_CD_INSTRUCTIONS_DETAILS: {
      return {
        ...state,
        isRemoveInstructions: false,
      };
    }

    case ACTION_REMOVE_CD_INSTRUCTIONS_FLOW: {
      return {
        ...state,
        isRemoveInstructionsFlow: false,
      };
    }
    case ACTION_ADDED_CD_INSTRUCTIONS_FLOW: {
      return {
        ...state,
        isRemoveInstructionsFlow: true,
      };
    }
    default:
      return state;
  }
};
