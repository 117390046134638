import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/core';
import type { StyleProps } from '../../utilities/types';
import { Typography } from '../typography/typography.styles';
import { Z_INDEX_SKIP_LINK } from '../../styles/zIndex';

type LinkProps = {
  href: string;
  children: React.ReactNode;
  className?: string;
};

const IconLinkStyles = {
  container: {
    ...Typography.screenReaderText,
    textDecoration: 'none',
    '&:focus': {
      width: 'auto',
      height: 'auto',
      clip: 'auto',
      clipPath: 'none',
      zIndex: Z_INDEX_SKIP_LINK,
    },
  },
} as const;

const SkipLinkComponent = ({
  children,
  classes,
  className,
  href,
  ...rest
}: LinkProps & StyleProps) => {
  return (
    <a href={href} className={cx(className, classes.container)} {...rest}>
      {children}
    </a>
  );
};

export const SkipLink = withStyles(IconLinkStyles)(SkipLinkComponent);
SkipLink.displayName = 'SkipLink';

export default SkipLink;
