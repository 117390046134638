import i18n from '../../strings/i18n';
import { CustomerInfo } from '../../utilities/types';
import { NewJointOwner } from '../newAccountOpening/jointOwners/typings';
import { SelectedJointOwnerValue, TransmitExistingAccountCustomerInfo } from './otp.constants';

export const buildTransmitPrimaryApplicationInfo = (
  personalInformationFields: Record<string, string>
): Record<string, string> => {
  return {
    first_name: personalInformationFields.firstName,
    middle_name: personalInformationFields.middleName ? personalInformationFields.middleName : '',
    last_name: personalInformationFields.lastName,
    suffix: '',
    address_line1: personalInformationFields.streetAddress1,
    address_line2: personalInformationFields.streetAddress2
      ? personalInformationFields.streetAddress2
      : '',
    city: personalInformationFields.city,
    state: personalInformationFields.state,
    zip5: personalInformationFields.zipCode,
    dob: personalInformationFields.dateOfBirth,
    ssn: personalInformationFields.socialSecurityNumber,
    phone_number: personalInformationFields.phoneNumber
      ? personalInformationFields.phoneNumber
      : '',
    phone_type: personalInformationFields.noPhoneNumber ? '' : personalInformationFields.phoneType,
    email_id: personalInformationFields.email,
    customer_type_flag: i18n({ TransmitCustomerType: 'NEW' }),
    customer_id: '',
    no_phone: personalInformationFields.noPhoneNumber,
  };
};

const buildTransmitJointOwnerInfo = (jointOwnerInfo): Record<string, string | boolean> => {
  return {
    first_name: jointOwnerInfo.jointDetails.firstName,
    middle_name: '',
    last_name: jointOwnerInfo.jointDetails.lastName,
    suffix: '',
    address_line1: jointOwnerInfo.jointAddress.streetAddress1,
    address_line2: jointOwnerInfo.jointAddress.streetAddress2
      ? jointOwnerInfo.jointAddress.streetAddress2
      : '',
    city: jointOwnerInfo.jointAddress.city,
    state: jointOwnerInfo.jointAddress.state,
    zip5: jointOwnerInfo.jointAddress.zipCode,
    dob: jointOwnerInfo.jointDetails.dateOfBirth,
    ssn: jointOwnerInfo.jointDetails.socialSecurityNumber,
    phone_number: jointOwnerInfo.jointAddress.phoneNumber,
    phone_type: jointOwnerInfo.jointAddress.phoneType,
    email_id: jointOwnerInfo.jointAddress.email,
    customer_type_flag: i18n({ TransmitCustomerType: 'NEW' }),
    customer_id: '',
    no_phone: false,
  };
};

export const buildTransmitJointOwner = (jointOwnersList) => {
  const jointOwnerArray = [];
  if (jointOwnersList.newJointOwners) {
    jointOwnerArray.push(
      jointOwnersList.newJointOwners.map((newJointOwner) => {
        return buildTransmitJointOwnerInfo(newJointOwner);
      })
    );
  }

  return jointOwnerArray;
};

export const buildTransmitPrimaryCustomerInfo = (
  personalInformationFields: CustomerInfo
): TransmitExistingAccountCustomerInfo => {
  const phoneDetails = personalInformationFields?.phones.find((phoneInfo) => phoneInfo);
  const addressDetails = personalInformationFields?.addresses.find((addressInfo) => addressInfo);
  const emailDetails = personalInformationFields?.emailAddresses.find((emailInfo) => emailInfo);
  return {
    first_name: personalInformationFields?.name?.firstName,
    middle_name: personalInformationFields?.name?.middleName
      ? personalInformationFields?.name?.middleName
      : '',
    last_name: personalInformationFields?.name?.lastName,
    suffix: personalInformationFields?.name?.suffix,
    address_line1: addressDetails?.line1,
    address_line2: addressDetails?.aptNumber ? addressDetails?.aptNumber : '',
    city: addressDetails?.city,
    state: addressDetails?.state,
    zip5: addressDetails?.zipCode,
    dob: personalInformationFields?.dateOfBirth,
    ssn: personalInformationFields?.taxId,
    phone_number: phoneDetails?.number ? phoneDetails?.number.replace(/[-\s)(]/g, '') : '',
    phone_type: personalInformationFields?.noPhoneADA ? '' : phoneDetails?.displayType,
    email_id: emailDetails?.email,
    customer_type_flag: i18n({ TransmitCustomerType: 'EXISTING' }),
    customer_id: personalInformationFields?.customerId,
    no_phone: personalInformationFields?.noPhoneADA,
  };
};

export const buildTransmitNewJointOwnerInfo = (
  jointOwnerInfo: NewJointOwner,
  noPhoneNumber: boolean | undefined
): TransmitExistingAccountCustomerInfo => {
  return {
    first_name: jointOwnerInfo?.jointDetails?.firstName
      ? jointOwnerInfo?.jointDetails?.firstName
      : '',
    middle_name: jointOwnerInfo?.jointDetails?.middleName
      ? jointOwnerInfo?.jointDetails?.middleName
      : '',
    last_name: jointOwnerInfo?.jointDetails?.lastName ? jointOwnerInfo?.jointDetails?.lastName : '',
    suffix: '',
    address_line1: jointOwnerInfo?.jointAddress?.streetAddress1
      ? jointOwnerInfo?.jointAddress?.streetAddress1
      : '',
    address_line2: jointOwnerInfo?.jointAddress?.streetAddress2
      ? jointOwnerInfo?.jointAddress?.streetAddress2
      : '',
    city: jointOwnerInfo?.jointAddress?.city ? jointOwnerInfo?.jointAddress?.city : '',
    state: jointOwnerInfo?.jointAddress?.state ? jointOwnerInfo?.jointAddress?.state : '',
    zip5: jointOwnerInfo?.jointAddress?.zipCode ? jointOwnerInfo?.jointAddress?.zipCode : '',
    dob: jointOwnerInfo?.jointDetails?.dateOfBirth ? jointOwnerInfo?.jointDetails?.dateOfBirth : '',
    ssn: jointOwnerInfo?.jointDetails?.socialSecurityNumber
      ? jointOwnerInfo?.jointDetails?.socialSecurityNumber
      : '',
    phone_number: jointOwnerInfo?.jointAddress?.phoneNumber
      ? jointOwnerInfo?.jointAddress?.phoneNumber
      : '',
    phone_type: jointOwnerInfo?.jointAddress?.phoneType
      ? jointOwnerInfo?.jointAddress?.phoneType
      : '',
    email_id: jointOwnerInfo?.jointAddress?.email ? jointOwnerInfo?.jointAddress?.email : '',
    customer_type_flag: i18n({ TransmitCustomerType: 'NEW' }),
    customer_id: '',
    no_phone: typeof noPhoneNumber !== 'undefined' ? noPhoneNumber : false,
  };
};

export const buildTransmitSelectedJointOwnerInfo = (
  jointOwnerInfo: SelectedJointOwnerValue
): TransmitExistingAccountCustomerInfo => {
  const phoneDetails = jointOwnerInfo?.phones?.find((phoneInfo) => phoneInfo);
  const extractValue = (value) => value || '';
  return {
    first_name: extractValue(jointOwnerInfo?.name?.['cipher.firstName']),
    middle_name: extractValue(jointOwnerInfo?.name?.['cipher.middleName']),
    last_name: extractValue(jointOwnerInfo?.name?.['cipher.lastName']),
    suffix: extractValue(jointOwnerInfo?.name?.suffix),
    address_line1: extractValue(jointOwnerInfo?.address?.['cipher.line1']),
    address_line2: extractValue(jointOwnerInfo?.address?.['cipher.aptNumber']),
    city: extractValue(jointOwnerInfo?.address?.['cipher.city']),
    state: extractValue(jointOwnerInfo?.address?.['cipher.state']),
    zip5: extractValue(jointOwnerInfo?.address?.['cipher.zipCode']),
    dob: extractValue(jointOwnerInfo?.['cipher.dateOfBirth']),
    ssn: extractValue(jointOwnerInfo?.['cipher.taxId']),
    phone_number: extractValue(phoneDetails?.number),
    phone_type: extractValue(phoneDetails?.displayType),
    email_id: extractValue(jointOwnerInfo?.email),
    customer_type_flag: i18n({ TransmitCustomerType: 'EXISTING' }),
    customer_id: extractValue(jointOwnerInfo?.customerId),
    no_phone: jointOwnerInfo?.noPhoneIndicator || false,
  };
};
