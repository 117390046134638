import classnames from 'classnames';
import { withStyles, CircularProgress, StyleRules } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Colors from '../colors/colors';
import { LinkText } from '../typography/typography';
import pxToRem from '../../utilities/pxToRem';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

type ArrowDirection = 'up' | 'right' | 'down' | 'left';

type LinkButtonProps = {
  buttonText: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
  classes?: ClassNameMap;
  disabled?: boolean;
  isLoading?: boolean;
  arrowDirection?: ArrowDirection;
  type?: 'button' | 'submit';
  textColor?: string;
  role?: string;
  tabIndex?: number;
  cdDetailsClass?: string;
};

export const ArrowLinkCommonStyles: StyleRules = {
  right: {
    position: 'relative',
    transform: 'rotate(0deg)',
    top: `${pxToRem(1)}`,
  },
  up: {
    position: 'relative',
    transform: 'rotate(-90deg)',
    top: `${pxToRem(1)}`,
    left: `${pxToRem(1)}`,
  },
  down: {
    position: 'relative',
    transform: 'rotate(90deg)',
    top: `${pxToRem(2)}`,
  },
  left: {
    position: 'relative',
    transform: 'rotate(180deg)',
    top: `${pxToRem(1)}`,
  },
};

const ArrowLinkButtonStyles: StyleRules = {
  ...ArrowLinkCommonStyles,
  iconWrapper: {
    display: 'inline-block',
    paddingLeft: '.4em',
    marginRight: '-1.4em',
  },
  buttonContainer: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    textAlign: 'left',
    textDecoration: 'none',
    '&:hover $buttonText, &:focus $buttonText': {
      textDecoration: 'underline',
      color: Colors.hoverState,
    },
    '&:disabled': {
      cursor: 'default',
    },
    '&:disabled $buttonText': {
      textDecoration: 'none',
      color: Colors.disabledState,
    },
  },
  buttonText: {
    paddingRight: '1.4em',
    paddingTop: pxToRem(2),
    paddingBottom: pxToRem(2),
  },
};

const arrowLinkLoadingIndicatorStyles: StyleRules = {
  root: {
    marginLeft: pxToRem(3),
    top: pxToRem(1),
    position: 'relative',
  },
};

const LoadingIndicator = withStyles(arrowLinkLoadingIndicatorStyles)(CircularProgress);

export const ArrowLinkButton = ({
  onClick = () => {
    /* This is intentional to avoid eslint issue i.e sonarqube critcal issue */
  },
  classes = {},
  arrowDirection = 'right',
  disabled = false,
  isLoading = false,
  buttonText = '',
  type = 'button',
  className = '',
  cdDetailsClass = '',
  textColor = Colors.optimumBlue,
  ...rest
}: LinkButtonProps) => {
  const direction = arrowDirection ? classes[arrowDirection] : '';
  const textFillColor = disabled ? Colors.steelGrey : textColor;

  return (
    <button
      onClick={onClick}
      className={classnames(classes.buttonContainer, className)}
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      disabled={disabled}
      {...rest}
    >
      <LinkText className={classes.buttonText} textColor={textFillColor}>
        {buttonText}
        {!disabled && !isLoading && (
          <span className={classnames(classes.iconWrapper, cdDetailsClass)}>
            <SVGImage imageName={ImagesFileNames.arrowSvg} className={direction} />
          </span>
        )}
        {isLoading && <LoadingIndicator size={pxToRem(12)} />}
      </LinkText>
    </button>
  );
};

const styled = withStyles(ArrowLinkButtonStyles)(ArrowLinkButton);
styled.displayName = 'ArrowLinkButton';

export default styled;
