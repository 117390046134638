import branch from 'branch-sdk';
import pageAnalytics from '../pageAnalytics';
import {
  ACTION_CUSTOMER_ERROR,
  ACTION_CUSTOMER_EXISTS,
} from '../../containers/newAccountOpening/newUser/openAccount.reducer';
import { ANALYTICS_PERSONAL_INFORMATION, ANALYTICS_START_INTENT } from '../actions';
import pageTrack from '../pageAnalytics.constants';

const initPageData = {
  account_type: pageTrack.account_type.generic,
  pagekind: pageTrack.pagekind.account_opening,
  pagefunction: pageTrack.pagefunction.about_you,
  pagesubfunction: pageTrack.pagesubfunction.NAO,
} as const;

const viewSuccess = () => {
  pageAnalytics.newPageView(initPageData).addInfo({ pagename: pageTrack.pagename.start }).record();
};

const customerExists = () => {
  pageAnalytics
    .newPageView(initPageData)
    .addInfo({ pagename: pageTrack.pagename.start_existing })
    .record();
};

const customerError = () => {
  pageAnalytics
    .newPageView(initPageData)
    .addInfo({ pagename: pageTrack.pagename.start_contactus })
    .record();
};

const startIntent = () => {
  pageAnalytics
    .newPageView(initPageData)
    .addInfo({ pagename: pageTrack.pagename.start_intent })
    .record();
};

export const personalInformationPageEvents = {
  [ANALYTICS_PERSONAL_INFORMATION]: viewSuccess,
  [ACTION_CUSTOMER_EXISTS]: customerExists,
  [ACTION_CUSTOMER_ERROR]: customerError,
  [ANALYTICS_START_INTENT]: startIntent,
} as const;

export default personalInformationPageEvents;
