export default Object.freeze({
  ACCOUNT_PRIVACY_POLICY: 'Account Privacy Policy',
  ACCOUNT_PRIVACY_POLICY_URL: 'https://www.synchronybank.com/documents/privacy_policy_retail.pdf',
  TERMS: 'Online Terms',
  TERMS_URL: 'https://www.synchronybank.com/terms/',
  ONLINE_PRIVACY_POLICY: 'Online Privacy Policy',
  ONLINE_PRIVACY_POLICY_URL: 'https://www.synchronybank.com/privacy/',
  DISCLOSURES: 'Disclosures',
  DISCLOSURES_URL: 'https://www.synchronybank.com/account-disclosures/',
  COBROWSE: 'Co-Browse',
  CONTACT_US: 'Contact Us',
  CONTACT_US_URL: 'https://www.synchronybank.com/contact-us/',
  FAQ: 'FAQ',
  FAQ_URL: 'https://www.synchronybank.com/banking-resources/faq/',
  FDIC_URL: 'https://www.synchronybank.com/fdic/',
});
