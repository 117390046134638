import type { Dispatch, GetState, ApplicationsState } from '../../utilities/types';
import {
  fetchProductTypesSuccessAction,
  fetchProductTypesFailureAction,
  clearProductTypesErrorAction,
  fetchProductTypesRequestAction,
} from './products.reducer';
import { fetchProductTypes as fetchProductTypesService } from '../../services/products.service';
import type { ProductTypesService } from '../../services/products.service';
import { getSearchParam } from '../../utilities/url';
import type { ProductTerm } from './products.reducer';
import { QueryParams } from '../routes/routes.constants';
import { AFFlow } from '../../utilities/accountFundingFlowType';
import type { AccountActivityState } from '../accountActivity/accountActivity.reducer';

export const validateProductTypes = (productTypes: ProductTerm) =>
  productTypes.products &&
  productTypes.products.length > 0 &&
  productTypes.terms &&
  productTypes.terms.length > 0;

export const getAccountDetails = (
  accountActivity: AccountActivityState,
  applications: ApplicationsState
) => {
  let accountId;
  let accountType;
  const AOFlow = applications.newAccount.id ? AFFlow.NAO : AFFlow.EAO;
  if (AOFlow === 'NAO') {
    accountId = applications.newAccount.id;
    accountType = applications.newAccount.accountType;
  } else if (AOFlow === 'EAO') {
    accountId = accountActivity.accountDetails.accountId;
    accountType = accountActivity.accountDetails.type;
  }
  return { accountId, accountType };
};

export const fetchAndValidateProductTypes =
  (service: ProductTypesService = fetchProductTypesService) =>
  async (dispatch: Dispatch, getState: GetState): Promise<boolean> => {
    const { products, router, accountActivity, applications } = getState();
    const { productTypes } = products;
    const promoCode = getSearchParam(router.location, QueryParams.promoCode);
    const UISCode = getSearchParam(router.location, QueryParams.affinityCode);
    const hasProductTypes = validateProductTypes(productTypes);
    const { accountId, accountType } = getAccountDetails(accountActivity, applications);

    const params = {
      affinityCompanyCode: UISCode || undefined,
      includePromotionCode: promoCode || undefined,
      accountType,
      accountId,
    } as const;

    dispatch(clearProductTypesErrorAction());

    // retrieve new product types
    let productsResponse;
    if (!hasProductTypes) {
      dispatch(fetchProductTypesRequestAction());
      try {
        productsResponse = await service(params);
      } catch (e) {
        dispatch(fetchProductTypesFailureAction());
        return false;
      }

      if (productsResponse) {
        const shouldShowInvalidAffinityCodeModal =
          window.__config__.INVALID_AFFINITY_MODAL_ENABLED === 'true' &&
          !!UISCode &&
          !productsResponse.validAffinityCompanyCode;

        const newProductResponse = {
          affinityCompanyCode: UISCode,
          shouldShowInvalidAffinityCodeModal,
          ...productsResponse,
        } as const;
        if (validateProductTypes(productsResponse.productTypes)) {
          dispatch(fetchProductTypesSuccessAction(newProductResponse));
          return true;
        }
        return false;
      }
    }
    return true;
  };

export default fetchAndValidateProductTypes;
