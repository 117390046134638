import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import {
  ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE,
  ANALYTICS_VIEW_DOCUMENTS_FAILURE,
  ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE,
  ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS,
  ANALYTICS_VIEW_DOCUMENTS_SUCCESS,
  ANALYTICS_EPREFERENCES_SAVED,
  ANALYTICS_EPREFERENCES_NOT_SAVED,
} from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.message_center,
    pagefunction: pageTrack.pagefunction.documents,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

const viewMoreSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.more }).record();
};

const viewMoreFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.more_server_error }).record();
};

const viewNonceFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.document_server_error }).record();
};
const preferenceSaved = () => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.ePreferences_saved,
      pagekind: pageTrack.pagekind.account_settings,
    })
    .record();
};

const preferenceNotSaved = () => {
  viewInit()
    .addInfo({
      pagename: pageTrack.pagename.ePreferences_not_saved,
      pagekind: pageTrack.pagekind.account_settings,
    })
    .record();
};

export const documentsPageEvents = {
  [ANALYTICS_VIEW_DOCUMENTS_SUCCESS]: viewSuccess,
  [ANALYTICS_VIEW_DOCUMENTS_FAILURE]: viewFailure,
  [ANALYTICS_VIEW_DOCUMENTS_MORE_SUCCESS]: viewMoreSuccess,
  [ANALYTICS_VIEW_DOCUMENTS_MORE_FAILURE]: viewMoreFailure,
  [ANALYTICS_VIEW_DOCUMENT_NONCE_FAILURE]: viewNonceFailure,
  [ANALYTICS_EPREFERENCES_SAVED]: preferenceSaved,
  [ANALYTICS_EPREFERENCES_NOT_SAVED]: preferenceNotSaved,
} as const;

export default documentsPageEvents;
