const host = window.__config__.CONTROL_CENTER_URL;
const featureFlagFile = '/feature-flags/featureFlags.json';

export type FeatureFlagJson = {
  ['KEY']: boolean | string;
};

const fetchFeatureFlags = async () => {
  try {
    const data = await fetch(`${host}${featureFlagFile}`);
    const json: FeatureFlagJson = await data.json();
    for (const [key, value] of Object.entries(json)) {
      window.__config__[key] = String(value);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export default fetchFeatureFlags;
