import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import {
  ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE,
  ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS,
} from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.explore_products,
    pagefunction: pageTrack.pagefunction.savings_accounts,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

export const exploreProductsPageEvents = {
  [ANALYTICS_EXPLORE_PRODUCTS_VIEW_SUCCESS]: viewSuccess,
  [ANALYTICS_EXPLORE_PRODUCTS_VIEW_FAILURE]: viewFailure,
} as const;

export default exploreProductsPageEvents;
