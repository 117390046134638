import axios from '../../utilities/axios';
import type {
  EPreferencesInfo,
  PatchEPreferences,
  ETaxDocumentPreferences,
  ETaxDocumentPreferencesResponse,
} from './ePreferences.constants';
import { TAX_DOCUMENT_PREFERENCES_URL, PREFERENCES_INFO_URL } from '../../utilities/route-mappings';

export type GetEPreferencesService = () => Promise<EPreferencesInfo>;

export const fetchEPreferences: GetEPreferencesService = async () => {
  const res = await axios.get(PREFERENCES_INFO_URL());
  return res.data;
};

export type SetEPreferencesService = (arg1: PatchEPreferences) => Promise<EPreferencesInfo>;

export const setEPreferences: SetEPreferencesService = async (ePreferences) => {
  const res = await axios.patch(PREFERENCES_INFO_URL(), ePreferences);
  return res.data;
};

export type GetTaxDocumentPreferencesService = () => Promise<ETaxDocumentPreferences>;

export const fetchTaxDocumentPreferences: GetTaxDocumentPreferencesService = async () => {
  const res = await axios.get(TAX_DOCUMENT_PREFERENCES_URL());
  return res.data.taxDocumentsPreferences;
};

export type UpdateTaxDocumentPreferencesService = (
  requestData: ETaxDocumentPreferencesResponse
) => Promise<ETaxDocumentPreferences>;

export const updateTaxDocumentPreferencesService: UpdateTaxDocumentPreferencesService = async (
  requestData
) => {
  const res = await axios.put(TAX_DOCUMENT_PREFERENCES_URL(), requestData);
  return res.data.taxDocumentsPreferences;
};
