import type { AllAccount } from 'Domain/Account/AllAccount';
import type { AccountDetails, CDAccountDetails } from 'Domain/Account/AccountDetails';
import React from 'react';
import i18n from '../strings/i18n';
import { ScreenReaderText } from '../components/typography/typography';
import type { Account } from '../utilities/types';
import type { EligibleAccount } from '../containers/ePreferences/ePreferences.constants';
import type { ProtectorAccount } from '../containers/overdraftProtection/overdraftProtection.service';
import type { TransferAccount } from '../containers/transfers/transfers.types';
import type { AlertsEligibleAccount } from '../containers/accountAlerts/accountAlerts.constants';

export const formatAccountName = (
  nickname: string | null | undefined,
  accountType: string,
  accountIdDisplay: string
) => {
  return `${nickname || accountType} ${accountIdDisplay}`;
};

/**
 * Formats a last four account number to return a component displaying '●●1234', with proper screen reading for the dots.
 * Can pass in a custom screen reader text, or defaults to 'account ending in'.
 */
export const formatAccountNumberLastFour = (accountId: string, customScreenReaderText = '') => {
  const accountIdLastFourNoDots = accountId.replace(/\D/g, '').slice(-4);
  return (
    <>
      <span aria-hidden="true">●●</span>
      <ScreenReaderText>
        {customScreenReaderText || i18n({ accounts: 'accountEndingIn' })}
      </ScreenReaderText>
      {accountIdLastFourNoDots}
    </>
  );
};

/** Format account name, with ●● dots having proper screen reader text */
export const formatAccountNameDots = (
  nickname: string | null | undefined,
  accountType: string,
  accountIdDisplay: string
) => {
  return (
    <>
      {`${nickname || accountType}`} {formatAccountNumberLastFour(accountIdDisplay)}
    </>
  );
};

export const buildAccountNameForTransferAccount = (account: TransferAccount) => {
  const { accountIdDisplay, nickname, accountType } = account;
  return formatAccountName(nickname, accountType, accountIdDisplay);
};

export const buildAccountNameForAccount = (account: Account) => {
  const { nickname, productDisplayType, accountIdDisplay } = account;
  return formatAccountName(nickname, productDisplayType, accountIdDisplay);
};

export const buildAccountNameForAccountDots = (account: Account) => {
  const { nickname, productDisplayType, accountIdDisplay } = account;
  return formatAccountNameDots(nickname, productDisplayType, accountIdDisplay);
};

type AccountTypes =
  | AccountDetails
  | CDAccountDetails
  | Account
  | EligibleAccount
  | AlertsEligibleAccount;

const buildAccountNameWithProductBase = (account: AccountTypes) => {
  let productDisplayType;
  if ('productDisplayType' in account) {
    productDisplayType = account.productDisplayType;
  } else {
    productDisplayType = account.product.productDisplayType;
  }
  const { nickname, accountIdDisplay } = account;
  return { nickname, productDisplayType, accountIdDisplay };
};

export const buildAccountNameWithProduct = (account: AccountTypes) => {
  const { nickname, productDisplayType, accountIdDisplay } =
    buildAccountNameWithProductBase(account);
  return formatAccountName(nickname, productDisplayType, accountIdDisplay);
};

export const buildAccountNameWithProductDots = (account: AccountTypes) => {
  const { nickname, productDisplayType, accountIdDisplay } =
    buildAccountNameWithProductBase(account);
  return formatAccountNameDots(nickname, productDisplayType, accountIdDisplay);
};

export const buildAccountNameForAllAccount = (account: AllAccount) => {
  const { accountIdDisplay, nickname, accountType, productDisplayType = '', external } = account;
  const displayName = external ? accountType : productDisplayType;
  return formatAccountName(nickname, displayName, accountIdDisplay);
};

export const buildAccountNameForProtectorAccount = (account: ProtectorAccount) => {
  const { accountIdDisplay, nickname, accountType } = account;
  return formatAccountName(nickname, accountType, accountIdDisplay);
};
