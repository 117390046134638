import { makeStyles } from '@material-ui/core/styles';
import Colors from '../colors/colors';
import type { Theme } from '../../utilities/types';
import pxToRem from '../../utilities/pxToRem';

export default makeStyles((theme: Theme) => ({
  editButton: {
    wordWrap: 'normal',
  },
  editButtonMobile: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: pxToRem(4),
  },
  infoContainer: {
    [theme.breakpoints.down('xs')]: {
      // Remove negative margins from Grid spacing to line up with expansion panel
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  infoLabel: {
    marginBottom: pxToRem(8),
  },
  infoLabelEditMode: {
    marginBottom: pxToRem(0),
  },
  infoLabelParentEditMode: {
    margin: pxToRem(0),
    [theme.breakpoints.down('md')]: {
      width: `${pxToRem(100)}`,
    },
  },
  root: {
    backgroundColor: Colors.white,
    boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('xs')]: {
      padding: `${pxToRem(12)} ${pxToRem(16)} ${pxToRem(24)} ${pxToRem(12)}`,
    },
    [theme.breakpoints.up('sm')]: {
      padding: `${pxToRem(24)} ${pxToRem(24)} ${pxToRem(32)} ${pxToRem(32)}`,
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: `0 ${pxToRem(10)} ${pxToRem(40)} 0 rgba(0, 0, 0, 0.1)`,
      padding: `${pxToRem(16)} ${pxToRem(32)}`,
    },
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${pxToRem(12)} ${pxToRem(24)} ${pxToRem(12)} ${pxToRem(12)} `,
    [theme.breakpoints.up('sm')]: {
      padding: 'unset',
      marginBottom: `${pxToRem(9)}`,
    },
  },
  titleRowEditMode: {
    marginBottom: `${pxToRem(24)}`,
  },
  editIconSvg: {
    width: pxToRem(18),
    height: pxToRem(18),
  },
}));

export const useHelperTextStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: pxToRem(12),
    paddingLeft: pxToRem(24),
  },
  greenCheckmark: {
    marginRight: pxToRem(4),
  },
  noCheckMark: {
    marginRight: pxToRem(18),
  },
  note: {
    textTransform: 'uppercase',
  },
  invalid: {
    visibility: 'hidden',
  },
  textContainer: {
    marginBottom: pxToRem(10),
  },
  textFieldPosition: {
    marginLeft: pxToRem(-15),
  },
  confirmPasswordPosition: {
    marginLeft: pxToRem(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: pxToRem(-15),
    },
  },
  submitErrorMessage: {
    fontSize: pxToRem(12),
    paddingLeft: pxToRem(15),
    color: Colors.inlineError,
  },
  passwordFieldPosition: {
    marginLeft: pxToRem(-8),
    paddingTop: 0,
    fontSize: pxToRem(16),
  },
  errorTextField: {
    color: Colors.inlineError,
  },
  noteForRequired: {
    margin: `0 0 ${pxToRem(10)}`,
  },
  saveButton: {
    padding: `${pxToRem(28)} ${pxToRem(16)} 0 ${pxToRem(16)}`,
  },
}));
