import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import type { FormRenderProps } from 'react-final-form';
import type { FormApi } from 'final-form';
import { Field, Form } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import { OnChange } from 'react-final-form-listeners';
import {
  renderElectronicDeliveryCheck,
  RadioButtonTable,
} from '../../components/finalForm/finalFormInputs';
import { StandardTextEmphasis, SubtitleText } from '../../components/typography/typography';
import {
  PreferencesFieldNames,
  PreferencesOptions,
  getSelectPreferenceFieldName,
  PreferencesFormLabels,
  PreferencesTableHeaders,
  TableHeader,
  ePreferencesFeatureFlag,
} from './ePreferences.constants';
import type {
  AccountIdPreferenceProps,
  EPreferencesInfo,
  EPreferenceProps,
  EligibleAccount,
  PreferencesOption,
  TableBodyProps,
  TableHeadProps,
} from './ePreferences.constants';
import { clickTrack } from '../../analytics/clickTracking.constants';
import { ProfileBtn, ModalBtnText } from '../../components/cms/buttonText.constants';
import type { EpreferencesForm } from '../../utilities/types';
import { setEPreferences as setEPreferencesService } from './ePreferences.service';
import { buildAccountNameWithProduct } from '../../formatters/buildAccountName';
import { isChecked } from '../newAccountOpening/validators';
import BackNextAndCancel from '../../components/buttons/backNextAndCancel';
import { ModalText } from '../../components/cms/blockText.constants';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import {
  ANALYTICS_EPREFERENCES_START_SUCCESS,
  ANALYTICS_EPREFERENCES_START_FAILURE,
  ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS,
  ANALYTICS_EPREFERENCES_SUBMIT_FAILURE,
} from '../../analytics/actions';
import { formatAccountNameYearForScreenReader } from '../../utilities/a11y';
import pageTrack from '../../analytics/pageAnalytics.constants';

const pageViewActions = Object.freeze({
  startSuccess: ANALYTICS_EPREFERENCES_START_SUCCESS,
  startFailure: ANALYTICS_EPREFERENCES_START_FAILURE,
  submitSuccess: ANALYTICS_EPREFERENCES_SUBMIT_SUCCESS,
  submitFailure: ANALYTICS_EPREFERENCES_SUBMIT_FAILURE,
});

const areAnyFields = (values: AccountIdPreferenceProps, value: string) =>
  Object.keys(values).some((fieldName) => values[fieldName] === value);

const compareAccountsPreferences = (
  eligibleAccounts: Record<string, string>,
  updatedAccounts: Record<string, string>
) => {
  const keys = Object.keys(eligibleAccounts);
  for (const id of keys) {
    if (eligibleAccounts[id] !== updatedAccounts[id]) {
      return false;
    }
  }
  return true;
};

const checkInitialPreference = (eligibleAccounts: EligibleAccount[]) => {
  const initialValues: Record<string, string> = {};
  eligibleAccounts.forEach((account) => {
    initialValues[getSelectPreferenceFieldName(account.accountId)] = account.edelivery
      ? PreferencesOptions.ONLINE_ONLY
      : PreferencesOptions.MAIL_AND_ONLINE;
  });
  return initialValues;
};

const buildInitialValues = (eligibleAccounts: EligibleAccount[]) => {
  let initialValues;
  if (eligibleAccounts) {
    initialValues = checkInitialPreference(eligibleAccounts);
    const isOnlineOnlyChecked = areAnyFields(initialValues, PreferencesOptions.ONLINE_ONLY);
    const isMailChecked = areAnyFields(initialValues, PreferencesOptions.MAIL_AND_ONLINE);
    if (isOnlineOnlyChecked && !isMailChecked) {
      initialValues[PreferencesFieldNames.ALL_ACCOUNTS] = PreferencesOptions.ONLINE_ONLY;
    } else if (!isOnlineOnlyChecked && isMailChecked) {
      initialValues[PreferencesFieldNames.ALL_ACCOUNTS] = PreferencesOptions.MAIL_AND_ONLINE;
    }
  }
  return initialValues;
};

const buildFieldOptions = (account: EligibleAccount) => {
  const accountScreenReaderText = formatAccountNameYearForScreenReader(account);
  return [
    {
      value: PreferencesOptions.MAIL_AND_ONLINE,
      ariaLabel: `${accountScreenReaderText} - ${PreferencesTableHeaders.MAIL_AND_ONLINE}`,
    },
    {
      value: PreferencesOptions.ONLINE_ONLY,
      ariaLabel: `${accountScreenReaderText} - ${PreferencesTableHeaders.ONLINE_ONLY}`,
    },
  ];
};
const getDisablePrimary = (
  invalid: boolean,
  eligibleAccounts: EligibleAccount[],
  disableSaveChanges: boolean
) => {
  return invalid || !eligibleAccounts || !eligibleAccounts.length || disableSaveChanges;
};

const updateFormChange = (
  isOnlineOnlyChecked: boolean,
  isMailChecked: boolean,
  form: FormApi<EpreferencesForm>
) => {
  if (isOnlineOnlyChecked && !isMailChecked) {
    form.change(PreferencesFieldNames.ALL_ACCOUNTS, PreferencesOptions.ONLINE_ONLY);
  } else if (!isOnlineOnlyChecked && isMailChecked) {
    form.change(PreferencesFieldNames.ALL_ACCOUNTS, PreferencesOptions.MAIL_AND_ONLINE);
  }
};

const updateAllAccountsRadio = (
  value: PreferencesOption,
  form: FormApi<EpreferencesForm>,
  eligibleAccounts: EligibleAccount[]
) => {
  form.batch(() => {
    eligibleAccounts.forEach((account) => {
      form.change(getSelectPreferenceFieldName(account.accountId), value);
    });
  });
};

const DisplayTableHead = (props: TableHeadProps) => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.accountHeaderCell}>
          <StandardTextEmphasis>{PreferencesTableHeaders.ACCOUNT}</StandardTextEmphasis>
        </TableCell>
        <TableCell className={classes.mailAndOnlineHeader}>
          <StandardTextEmphasis>{PreferencesTableHeaders.MAIL_AND_ONLINE}</StandardTextEmphasis>
        </TableCell>
        <TableCell className={classes.mailAndOnlineHeader}>
          <StandardTextEmphasis>{PreferencesTableHeaders.PRIOR_ONLINE_ONLY}</StandardTextEmphasis>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const DisplayTableBody = (props: TableBodyProps) => {
  const {
    classes,
    eligibleAccounts,
    allAccountsFieldOptions,
    values,
    form,
    handleAllAccountSelection,
    handleSingleAccountSelection,
  } = props;

  return (
    <TableBody className={classes.accountTableBody}>
      {!!eligibleAccounts && (
        <>
          {eligibleAccounts.length > 1 && (
            <>
              <Field
                type="radio"
                label={PreferencesFormLabels.ALL_ACCOUNTS}
                name={PreferencesFieldNames.ALL_ACCOUNTS}
                component={RadioButtonTable}
                isHeaderRequired
                options={allAccountsFieldOptions}
                selectedValue={values[PreferencesFieldNames.ALL_ACCOUNTS]}
              />
              <OnChange name={PreferencesFieldNames.ALL_ACCOUNTS}>
                {(value?: PreferencesOption) => {
                  if (value) {
                    handleAllAccountSelection(values[PreferencesFieldNames.ALL_ACCOUNTS]);
                    updateAllAccountsRadio(value, form, eligibleAccounts);
                  }
                }}
              </OnChange>
            </>
          )}
          {window.__config__.EPREFERENCES_ALL_ACCOUNT_ENABLED !== 'true' &&
            eligibleAccounts.map((account) => {
              const fieldName = getSelectPreferenceFieldName(account.accountId);
              return (
                <React.Fragment key={account.accountId}>
                  <Field
                    type="radio"
                    label={buildAccountNameWithProduct(account)}
                    screenReaderLabel={formatAccountNameYearForScreenReader(account)}
                    name={fieldName}
                    component={RadioButtonTable}
                    isHeaderRequired
                    options={buildFieldOptions(account)}
                    selectedValue={values[fieldName]}
                  />
                  <OnChange name={fieldName}>
                    {(value?: PreferencesOption) => {
                      handleSingleAccountSelection(value, values);
                      if (
                        values[PreferencesFieldNames.ALL_ACCOUNTS] &&
                        value !== values[PreferencesFieldNames.ALL_ACCOUNTS]
                      ) {
                        form.change(PreferencesFieldNames.ALL_ACCOUNTS, undefined);
                      }
                    }}
                  </OnChange>
                </React.Fragment>
              );
            })}
        </>
      )}
    </TableBody>
  );
};

const DisplayRadioBody = (props: TableBodyProps) => {
  const {
    classes,
    eligibleAccounts,
    allAccountsFieldOptions,
    values,
    form,
    handleAllAccountSelection,
  } = props;
  return (
    <Grid>
      {!!eligibleAccounts && eligibleAccounts.length >= 1 && (
        <>
          <Field
            type="radio"
            isHeaderRequired={false}
            name={PreferencesFieldNames.ALL_ACCOUNTS}
            tableRowClasses={classes.tableRowStyle}
            tableCellClasses={classes.tableCellStyleOnlyRadio}
            component={RadioButtonTable}
            options={allAccountsFieldOptions}
            selectedValue={values[PreferencesFieldNames.ALL_ACCOUNTS]}
          />
          <OnChange name={PreferencesFieldNames.ALL_ACCOUNTS}>
            {(value?: PreferencesOption) => {
              if (value) {
                updateAllAccountsRadio(value, form, eligibleAccounts);
                handleAllAccountSelection(values[PreferencesFieldNames.ALL_ACCOUNTS]);
              }
            }}
          </OnChange>
        </>
      )}
    </Grid>
  );
};

const EPreferencesTable = (ePreferenceProps: EPreferenceProps) => {
  const [disableSaveChanges, setDisableSaveChanges] = useState<boolean>(true);
  const [allAccountSelectedOption, setAllAccountOption] = useState<string>('');
  const [accountsInitialValue, setAccountsInitialValue] = useState<Record<string, string>>();
  const {
    eligibleAccounts,
    classes,
    updateFormDirtyStatus,
    showConfirmationModal,
    hasSessionExpired,
    clearFlashMessage,
    setSuccessFlashMessage,
    recordAnalyticsPageView,
    ePreferences,
    setEPreferences,
    setShowConfirmationModal,
    setErrorFlashMessage,
  } = ePreferenceProps;

  useEffect(() => {
    if (eligibleAccounts) {
      const getInitialValue = checkInitialPreference(eligibleAccounts);
      setAccountsInitialValue(getInitialValue);
      const isOnlineOnlyChecked = areAnyFields(getInitialValue, PreferencesOptions.ONLINE_ONLY);
      const isMailChecked = areAnyFields(getInitialValue, PreferencesOptions.MAIL_AND_ONLINE);
      if (isOnlineOnlyChecked && !isMailChecked) {
        setAllAccountOption(PreferencesOptions.ONLINE_ONLY);
      } else if (!isOnlineOnlyChecked && isMailChecked) {
        setAllAccountOption(PreferencesOptions.MAIL_AND_ONLINE);
      } else {
        setAllAccountOption('');
      }
    }
  }, [eligibleAccounts]);

  const onSubmit = async (values: EpreferencesForm) => {
    clearFlashMessage();
    if (!eligibleAccounts) {
      return;
    }
    const request = {
      statementPreferences: {
        eligibleAccounts: eligibleAccounts.map((account) => ({
          accountId: account.accountId,
          accountType: account.accountType,
          edelivery:
            values[getSelectPreferenceFieldName(account.accountId)] ===
            PreferencesOptions.ONLINE_ONLY,
        })),
        [PreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED]:
          !!values[PreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED],
      },
    } as const;
    let response: EPreferencesInfo;
    let submitAnalyticAttr = pageTrack.attr.mixed;
    if (values[PreferencesFieldNames.ALL_ACCOUNTS]) {
      submitAnalyticAttr =
        values[PreferencesFieldNames.ALL_ACCOUNTS] === PreferencesOptions.ONLINE_ONLY
          ? pageTrack.attr.online_only
          : pageTrack.attr.mail_only;
    }
    try {
      response = await setEPreferencesService(request);
    } catch (err) {
      setErrorFlashMessage(err && err.data && err.data.message);
      recordAnalyticsPageView(pageViewActions.submitFailure, submitAnalyticAttr);
      return;
    }

    setSuccessFlashMessage();
    setDisableSaveChanges(true);
    recordAnalyticsPageView(pageViewActions.submitSuccess, submitAnalyticAttr);
    if (response && response.statementPreferences.eligibleAccounts && ePreferences) {
      const responseEDeliveryMap = response.statementPreferences.eligibleAccounts.reduce<
        Record<string, boolean>
      >((map, account) => ({ ...map, [account.accountId]: account.edelivery }), {});
      const ePreferencesData = {
        ...ePreferences,
        statementPreferences: {
          ...ePreferences.statementPreferences,
          eligibleAccounts: ePreferences.statementPreferences.eligibleAccounts.map((account) => ({
            ...account,
            edelivery: responseEDeliveryMap[account.accountId],
          })),
        },
      } as const;
      setEPreferences(ePreferencesData);
    }
  };

  const handleAllAccountSelection = (currentvalue: string) => {
    if (currentvalue !== allAccountSelectedOption) {
      setDisableSaveChanges(false);
    } else {
      setDisableSaveChanges(true);
    }
  };

  const handleSingleAccountSelection = (currentValue: string, values: Record<string, string>) => {
    if (currentValue !== allAccountSelectedOption) {
      if (!compareAccountsPreferences(accountsInitialValue, values)) {
        setDisableSaveChanges(false);
      } else {
        setDisableSaveChanges(true);
      }
    }
  };

  return (
    <Grid item xs={12} sm={11} lg={!ePreferencesFeatureFlag ? 9 : 12}>
      {!ePreferencesFeatureFlag && (
        <Grid className={classes.tableHeader}>
          <SubtitleText>{TableHeader}</SubtitleText>
        </Grid>
      )}
      <Form onSubmit={onSubmit} initialValues={buildInitialValues(eligibleAccounts)}>
        {({
          dirty,
          handleSubmit,
          invalid,
          submitting,
          values = {},
          form,
        }: FormRenderProps<EpreferencesForm>) => {
          updateFormDirtyStatus(dirty);
          const isOnlineOnlyChecked = areAnyFields(values, PreferencesOptions.ONLINE_ONLY);
          const isMailChecked = areAnyFields(values, PreferencesOptions.MAIL_AND_ONLINE);
          const isTermsAndConditionsShown = isOnlineOnlyChecked || isMailChecked;
          updateFormChange(isOnlineOnlyChecked, isMailChecked, form);
          // If user checked edelivery T&C checkbox, then selected all mail
          // then went back to online-only, we want to force them to check
          // the T&C checkbox again
          const allAccountsFieldOptions = [
            {
              value: PreferencesOptions.MAIL_AND_ONLINE,
              ariaLabel: `${PreferencesFormLabels.ALL_ACCOUNTS} - ${PreferencesTableHeaders.MAIL_AND_ONLINE}`,
              label: PreferencesTableHeaders.MAIL_AND_ONLINE,
            },
            {
              value: PreferencesOptions.ONLINE_ONLY,
              ariaLabel: `${PreferencesFormLabels.ALL_ACCOUNTS} - ${PreferencesTableHeaders.ONLINE_ONLY}`,
              label: PreferencesTableHeaders.ONLINE_ONLY,
            },
          ];

          return (
            <form onSubmit={handleSubmit}>
              {!ePreferencesFeatureFlag ? (
                <Table className={classes.accountTable}>
                  <DisplayTableHead classes={classes} />
                  <DisplayTableBody
                    classes={classes}
                    eligibleAccounts={eligibleAccounts}
                    allAccountsFieldOptions={allAccountsFieldOptions}
                    values={values}
                    form={form}
                    handleAllAccountSelection={handleAllAccountSelection}
                    handleSingleAccountSelection={handleSingleAccountSelection}
                  />
                </Table>
              ) : (
                <>
                  <Grid className={classes.preferencesMainLable}>
                    <StandardTextEmphasis>
                      {PreferencesFormLabels.ALL_ACCOUNTS}
                    </StandardTextEmphasis>
                  </Grid>
                  <Grid>
                    <DisplayRadioBody
                      classes={classes}
                      eligibleAccounts={eligibleAccounts}
                      allAccountsFieldOptions={allAccountsFieldOptions}
                      values={values}
                      form={form}
                      handleAllAccountSelection={handleAllAccountSelection}
                      handleSingleAccountSelection={handleSingleAccountSelection}
                    />
                  </Grid>
                </>
              )}

              {isTermsAndConditionsShown && (
                <Grid item className={classes.electronicDeliveryCheckboxContainer}>
                  <Field
                    className={classes.checkboxContainer}
                    labelId={`${PreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED}Label`}
                    id={PreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED}
                    name={PreferencesFieldNames.ELECTRONIC_DELIVERY_TERMS_ACCEPTED}
                    component={renderElectronicDeliveryCheck}
                    type="checkbox"
                    validate={isChecked}
                    required
                  />
                </Grid>
              )}
              <Grid item className={classes.navigationControls}>
                <BackNextAndCancel
                  cancelText={ProfileBtn.REVERT_CHANGES}
                  ePreferencesTableSection={ePreferencesFeatureFlag}
                  disablePrimary={getDisablePrimary(invalid, eligibleAccounts, disableSaveChanges)}
                  loading={submitting}
                  nextText={ProfileBtn.SAVE_CHANGES}
                  data-track-next={clickTrack.ePreferences.save_changes}
                  data-track-next-page={clickTrack.ePreferences.view}
                  onCancel={
                    dirty &&
                    (() => {
                      clearFlashMessage();
                      setShowConfirmationModal(true);
                    })
                  }
                  onNext={handleSubmit}
                  wrap="wrap"
                />
              </Grid>

              <Prompt when={dirty && !hasSessionExpired} message={ModalText.EXIT_MODAL_CONTENT} />
              <ConfirmationModal
                titleText={ModalText.REVERT_CHANGES_MODAL_TITLE}
                contentText={ModalText.REVERT_CHANGES_MODAL_BODY}
                confirmText={ModalBtnText.REVERT_CHANGES_BTN}
                data-track-title={clickTrack.ePreferences.revert_changes}
                data-track-page={clickTrack.ePreferences.view}
                exitText={ModalBtnText.CANCEL_NO_CONTINUE_BTN}
                onConfirm={() => {
                  form.reset();
                  setShowConfirmationModal(false);
                }}
                onExit={() => setShowConfirmationModal(false)}
                visible={showConfirmationModal}
              />
            </form>
          );
        }}
      </Form>
    </Grid>
  );
};

export default EPreferencesTable;
