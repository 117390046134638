import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { RewardTierTitle, PerksTable } from '../../containers/rewards/rewards.constants';
import { StandardText, BodyTextSmall, ScreenReaderText } from '../typography/typography';
import useStyles from './rewardsTierChart.styles';
import i18n from '../../strings/i18n';

const PerksRewardChart = () => {
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell colSpan={6} className={classes.column} data-test="perks-level-heading">
          <StandardText className={classes.tableHeader}>
            {i18n({ REWARDS_TABLE: 'HEADER2' })}
          </StandardText>
        </TableCell>
      </TableRow>

      {Object.values(PerksTable).map((perk: Record<string, string>) => (
        <TableRow key={perk.TITLE} className={classes.perksTableRows}>
          <TableCell className={classes.perkDescriptionCell}>
            <BodyTextSmall data-test="reward-description">{perk.TITLE}</BodyTextSmall>
          </TableCell>
          <TableCell className={classes.perkDescriptionCell}>
            {perk.BASIC ? (
              <BodyTextSmall
                data-test={`reward-description-${RewardTierTitle.BASIC.toLowerCase()}`}
                className={classes.columnText}
              >
                {perk.BASIC}
              </BodyTextSmall>
            ) : (
              <ScreenReaderText>
                {RewardTierTitle.BASIC} reward level does not qualify for {perk.TITLE}
              </ScreenReaderText>
            )}
          </TableCell>
          <TableCell className={classes.perkDescriptionCell}>
            {perk.SILVER ? (
              <BodyTextSmall
                data-test={`reward-description-${RewardTierTitle.SILVER.toLowerCase()}`}
                className={classes.columnText}
              >
                {perk.SILVER}
              </BodyTextSmall>
            ) : (
              <ScreenReaderText>
                {RewardTierTitle.SILVER} reward level does not qualify for {perk.TITLE}
              </ScreenReaderText>
            )}
          </TableCell>
          <TableCell className={classes.perkDescriptionCell}>
            {perk.GOLD ? (
              <BodyTextSmall
                data-test={`reward-description-${RewardTierTitle.GOLD.toLowerCase()}`}
                className={classes.columnText}
              >
                {perk.GOLD}
              </BodyTextSmall>
            ) : (
              <ScreenReaderText>
                {RewardTierTitle.GOLD} reward level does not qualify for {perk.TITLE}
              </ScreenReaderText>
            )}
          </TableCell>
          <TableCell className={classes.perkDescriptionCell}>
            {perk.PLATINUM ? (
              <BodyTextSmall
                data-test={`reward-description-${RewardTierTitle.PLATINUM.toLowerCase()}`}
                className={classes.columnText}
              >
                {perk.PLATINUM}
              </BodyTextSmall>
            ) : (
              <ScreenReaderText>
                {RewardTierTitle.PLATINUM} reward level does not qualify for {perk.TITLE}
              </ScreenReaderText>
            )}
          </TableCell>
          <TableCell className={classes.perkDescriptionCell}>
            {perk.DIAMOND ? (
              <BodyTextSmall
                data-test={`reward-description-${RewardTierTitle.DIAMOND.toLowerCase()}`}
                className={classes.columnText}
              >
                {perk.DIAMOND}
              </BodyTextSmall>
            ) : (
              <ScreenReaderText>
                {RewardTierTitle.DIAMOND} reward level does not qualify for {perk.TITLE}
              </ScreenReaderText>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default PerksRewardChart;
