import React from 'react';
import { connect } from 'react-redux';
import type { ReduxState } from '../../reducers';
import { buildRefreshTokenUrl } from '../../utilities/authentication';

type StateProps = {
  isLoggedIn: boolean;
  shouldTryRefresh: boolean;
};

const mapStateToProps = (state: ReduxState) => ({
  isLoggedIn: state.authenticate.isLoggedIn,
  // In case the first refreshToken attempt fails, try again every 2 minutes. We need the mod logic
  // in order to re-render the RefreshToken when shouldTryRefresh = false if tokenTicks = 10 and then
  // shouldTryRefresh = true again if tokenTicks = 11.
  shouldTryRefresh: state.authenticate.tokenTicks >= 9 && state.authenticate.tokenTicks % 2 === 1,
});

type DispatchProps = Record<string, string>;

type AllProps = StateProps & DispatchProps;

export const RefreshToken = (props: AllProps) => {
  const { isLoggedIn, shouldTryRefresh } = props;

  if (shouldTryRefresh && isLoggedIn) {
    return (
      <iframe
        title="refreshToken"
        width="0"
        height="0"
        // The refreshTokenUrl will redirect to /callback with the new code.
        // Authenticate container sends a message with the code back to us.
        src={buildRefreshTokenUrl({ iframeRequest: true })}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          border: 0,
          display: 'none',
        }}
        // The iframe needs to execute scripts to post the message back to the parent container
        // and it needs to allow same origin to have access to the session cookie in order to
        // get the refresh token
        sandbox="allow-scripts allow-same-origin"
      />
    );
  }
  return null;
};

export default connect(mapStateToProps)(RefreshToken);
