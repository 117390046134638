import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { SecurityAnalyticsSubmitAction } from '../actions';
import {
  ANALYTICS_INVALID_OTP_ERROR,
  ANALYTICS_MAX_OTP_ATTEMPTS_ERROR,
  ANALYTICS_VERIFY_OTP_BLOCKED_ERROR,
  ANALYTICS_VERIFY_OTP_SERVER_ERROR,
  ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS,
  ANALYTICS_VERIFY_OTP_VIEW_SUCCESS,
} from '../actions';
import type { ReduxState } from '../../reducers';
import { getPageFunctionTypeForOTP, isNAOOTPFlow } from '../utilities';

const viewInit = (state: ReduxState) => {
  const pageKindType = isNAOOTPFlow(state)
    ? pageTrack.pagekind.account_opening
    : pageTrack.pagekind.account_settings;
  const pageSubFunction = isNAOOTPFlow(state)
    ? pageTrack.pagesubfunction.NAO
    : pageTrack.pagesubfunction.OTP;

  return pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageKindType,
    pagesubfunction: pageSubFunction,
    pagefunction: getPageFunctionTypeForOTP(state),
  });
};

const viewSuccess = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  const pageNameType = isNAOOTPFlow(state)
    ? pageTrack.pagename.otp_step2
    : pageTrack.pagename.step2;
  const selectedPhoneType = state.profile.isSelectedNewPhoneNumber
    ? pageTrack.attr.new_phone_number
    : pageTrack.attr.existing_phone_number;
  viewInit(state).addInfo({ pagename: pageNameType, attr: selectedPhoneType }).record();
};

const verifyOtpSuccess = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state).addInfo({ pagename: pageTrack.pagename.complete }).record();
};

const invalidOtpError = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state).addInfo({ pagename: pageTrack.pagename.step2_error }).record();
};

const verifyOtpBlockedError = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state).addInfo({ pagename: pageTrack.pagename.step2_blocked }).record();
};

const maxOtpAttemptsError = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state).addInfo({ pagename: pageTrack.pagename.step2_code_failed }).record();
};

const verifyOtpServerError = (action: SecurityAnalyticsSubmitAction, state: ReduxState) => {
  viewInit(state).addInfo({ pagename: pageTrack.pagename.step2_server_error }).record();
};

export const validateOtpPageEvents = {
  [ANALYTICS_VERIFY_OTP_VIEW_SUCCESS]: viewSuccess,
  [ANALYTICS_VERIFY_OTP_SUBMIT_SUCCESS]: verifyOtpSuccess,
  [ANALYTICS_INVALID_OTP_ERROR]: invalidOtpError,
  [ANALYTICS_MAX_OTP_ATTEMPTS_ERROR]: maxOtpAttemptsError,
  [ANALYTICS_VERIFY_OTP_SERVER_ERROR]: verifyOtpServerError,
  [ANALYTICS_VERIFY_OTP_BLOCKED_ERROR]: verifyOtpBlockedError,
} as const;
export default validateOtpPageEvents;
