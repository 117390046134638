import type { Dispatch, GetState, ServiceThunk } from '../utilities/types';
import type { List } from '../reducers/lists.reducer';
import {
  fetchStateListService,
  fetchOccupationListService,
  fetchNationsListService,
} from '../services/lists.service';
import {
  ACTION_UTILITIES_STATE_LIST_SUCCESS,
  ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS,
  ACTION_UTILITIES_NATIONS_LIST_SUCCESS,
} from '../reducers/lists.reducer';

export const getStateList =
  (service: ServiceThunk<List> = fetchStateListService) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { lists } = getState();
    const hasStateList = lists.states && lists.states.length;
    if (!hasStateList) {
      const payload = await service();
      dispatch({ type: ACTION_UTILITIES_STATE_LIST_SUCCESS, payload });
    }
  };

export const getOccupationList =
  (service: ServiceThunk<List> = fetchOccupationListService) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { lists } = getState();
    const hasOccupationList = lists.occupations && lists.occupations.length;
    if (!hasOccupationList) {
      const payload = await service();
      dispatch({ type: ACTION_UTILITIES_OCCUPATION_LIST_SUCCESS, payload });
    }
  };

export const getNationList =
  (service: ServiceThunk<List> = fetchNationsListService) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { lists } = getState();
    const hasNationList = lists.nations && lists.nations.length;
    if (!hasNationList) {
      const payload = await service();
      dispatch({ type: ACTION_UTILITIES_NATIONS_LIST_SUCCESS, payload });
    }
  };
