import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import { ANALYTICS_VIEW_INBOX_FAILURE, ANALYTICS_VIEW_INBOX_SUCCESS } from '../actions';

const viewInit = () =>
  pageAnalytics.newPageView({
    account_type: pageTrack.account_type.generic,
    pagekind: pageTrack.pagekind.message_center,
    pagefunction: pageTrack.pagefunction.inbox,
  });

const viewSuccess = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view }).record();
};

const viewFailure = () => {
  viewInit().addInfo({ pagename: pageTrack.pagename.view_server_error }).record();
};

export const viewInboxPageEvents = {
  [ANALYTICS_VIEW_INBOX_SUCCESS]: viewSuccess,
  [ANALYTICS_VIEW_INBOX_FAILURE]: viewFailure,
} as const;

export default viewInboxPageEvents;
