import React from 'react';
import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import pxToRem from '../../../utilities/pxToRem';
import GridButton from '../../layout/gridButton';
import { clickTrack } from '../../../analytics/clickTracking.constants';
import { BackAndNextProps, LoadingIndicatorProps } from './typings';
import useStyles, { loadingIndicatorStyles } from './styles';

const LoadingIndicator = withStyles(loadingIndicatorStyles)((props: LoadingIndicatorProps) => (
  <CircularProgress classes={props.classes} size={pxToRem(20)} />
));

const BackAndNext = ({
  backText,
  children,
  disableNext,
  isEditing,
  justify,
  loading,
  nextText,
  nextType,
  onBack,
  onNext,
  primaryOnLeft,
  'data-test-back': dataTestBack,
  'data-track-back': dataTrackBack,
  'data-track-next': dataTrackNext,
}: BackAndNextProps) => {
  const classes = useStyles();
  const backButton = onBack && !isEditing && (
    <GridButton
      variant="secondary"
      onClick={onBack}
      disabled={loading}
      className={classes.raisedDefault}
      data-back
      data-test={dataTestBack || 'cancel-button'}
      data-track-title={dataTrackBack || clickTrack.general.back}
    >
      {backText || 'Go Back'}
    </GridButton>
  );
  const primaryButton = (
    <GridButton
      variant="primary"
      color="primary"
      disabled={disableNext || loading}
      className={classes.raisedPrimary}
      onClick={onNext}
      type={nextType}
      data-next
      data-test="next-button"
      data-track-title={dataTrackNext || clickTrack.general.next}
    >
      {loading && <LoadingIndicator />}
      {children || nextText || 'Next Step'}
    </GridButton>
  );

  return (
    <Grid container spacing={0} alignItems="center" justifyContent={justify}>
      {primaryOnLeft ? (
        <>
          {primaryButton}
          {backButton}
        </>
      ) : (
        <>
          {backButton}
          {primaryButton}
        </>
      )}
    </Grid>
  );
};

export default BackAndNext;
