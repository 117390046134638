import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import type { StyleProps } from '../../utilities/types';
import { StandaloneSidebarLinkText } from '../typography/typography';
import { boldFontWeight } from '../typography/typography.styles';
import Colors from '../colors/colors';
import pxToRem from '../../utilities/pxToRem';

export const SidebarLinkStyles = {
  link: {
    display: 'block',
    padding: '1rem 1rem 1rem 3rem',
    '&:hover': {
      color: `${Colors.optimumBlue} !important`, // override typography styles
      transition: 'background-color 80ms ease-out',
    },
  },
  active: {
    color: `${Colors.optimumBlue} !important`, // override typography styles
    fontWeight: boldFontWeight,
    boxShadow: `inset ${pxToRem(5)} 0 0 0 ${Colors.dimOptimumBlue}`,
    backgroundColor: Colors.blackTransparent5,
  },
} as const;

export type SidebarLinkProps = {
  to: string;
  className?: string;
  children: string;
  active?: boolean;
  ['data-track-type']?: string;
  ['data-track-title']?: string;
};

const SidebarLink = ({
  children,
  classes,
  className,
  to,
  active,
  'data-track-type': dataTrackType,
  'data-track-title': dataTrackTitle,
  ...rest
}: SidebarLinkProps & StyleProps) => {
  return (
    <StandaloneSidebarLinkText
      // @ts-ignore: can't infer prop types of Link component
      to={to}
      component={Link}
      data-track-type={dataTrackType}
      data-track-title={dataTrackTitle}
      {...rest}
      className={classnames(classes.link, className, { [classes.active]: active })}
    >
      {children}
    </StandaloneSidebarLinkText>
  );
};

const styled = withStyles(SidebarLinkStyles)(SidebarLink);
styled.displayName = 'SidebarLink';
export default styled;
