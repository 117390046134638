import type { ProductTypesResponse } from '../../services/products.service';

export const ProductTypes = {
  Savings: 'Savings',
  MoneyMarket: 'Money Market',
  CertificateOfDeposit: 'Certificate Of Deposit',
  IRAMoneyMarket: 'IRA Money Market',
  IRACertificateOfDeposit: 'IRA Certificate Of Deposit',
  Checking: 'Checking',
  None: 'NONE',
} as const;

export type ProductTypesName = typeof ProductTypes[keyof typeof ProductTypes];
export type ProductTypesCode = keyof typeof ProductTypes;

export const toProductTypesCode = (code: string): ProductTypesCode | null | undefined => {
  switch (code) {
    case 'Savings':
    case 'MoneyMarket':
    case 'CertificateOfDeposit':
    case 'IRAMoneyMarket':
    case 'IRACertificateOfDeposit':
    case 'Checking':
      return code;
    default:
      return null;
  }
};

export type RateMatrix = {
  minBalance: number;
  maxBalance: number;
  rate: string;
  apy: string;
  balanceRange: string;
};
export type Term = {
  id: string;
  productId: string;
  description: string;
  term?: string;
  termUnits?: string;
  renewalMinBalance?: number;
  rateMatrices: RateMatrix[];
};

export type ProductCode = 'CD' | 'MMA' | 'HYS';

export type Promotion = {
  promotionBonusAmount: number;
  promotionBonusDays: number;
  promotionCode: string;
  promotionName: string;
  promotionDescription: string;
  promotionDisclosure: string;
  promotionMinBalance: number;
  promotionMaxBalance: number;
  promotionEndDate: string;
  promotionFundByDate: string;
  promotionNewMoneyRequired: boolean;
  promotionStartDate: string;
  promotionType: string;
  promotionRateVariance?: number;
  promotionRateVarianceDays?: number | null;
};
export type Product = {
  displayCode: ProductCode;
  displayName: ProductTypesName;
  hasChecks?: boolean;
  hasAtmCards?: boolean;
  hasDebitCards?: boolean;
  terms: number[];
  promotion: Promotion | null | undefined;
  maxRate: string;
  maxAPY: string;
};

export type ProductTerm = {
  products: Product[];
  terms: Term[];
};

export type ProductState = {
  activePromotion: Promotion | null | undefined;
  affinity: boolean;
  affinityCompanyCode: string;
  affinityVerbiage: string;
  error: boolean;
  isLoading: boolean;
  productTypes: ProductTerm;
  validAffinityCompanyCode: boolean;
  shouldShowInvalidAffinityCodeModal: boolean;
  validPromotionCode?: boolean;
  rateMatrices: Array<RateMatrix>;
};

export const getInitialState = (): ProductState => ({
  activePromotion: null,
  affinity: false,
  affinityCompanyCode: '',
  affinityVerbiage: '',
  error: false,
  isLoading: false,
  productTypes: {
    products: [],
    terms: [],
  },
  validAffinityCompanyCode: false,
  shouldShowInvalidAffinityCodeModal: false,
  rateMatrices: [],
});

export const ACTION_CLEAR_PRODUCTS = 'ACTION_CLEAR_PRODUCTS';
export const ACTION_FETCH_PRODUCT_TYPES_REQUEST = 'ACTION_FETCH_PRODUCT_TYPES_REQUEST';
export const ACTION_FETCH_PRODUCT_TYPES_SUCCESS = 'ACTION_FETCH_PRODUCT_TYPES_SUCCESS';
export const ACTION_FETCH_PRODUCT_TYPES_FAILURE = 'ACTION_FETCH_PRODUCT_TYPES_FAILURE';
export const ACTION_CLEAR_PRODUCT_TYPES_ERROR = 'ACTION_CLEAR_PRODUCT_TYPES_ERROR';

export const ACTION_CLEAR_AFFINITY_MODAL = 'ACTION_CLEAR_AFFINITY_MODAL';

export type ProductTypesAction =
  | {
      type: typeof ACTION_CLEAR_PRODUCTS;
    }
  | {
      type: typeof ACTION_FETCH_PRODUCT_TYPES_REQUEST;
    }
  | {
      type: typeof ACTION_FETCH_PRODUCT_TYPES_SUCCESS;
      payload: ProductTypesResponse;
    }
  | {
      type: typeof ACTION_FETCH_PRODUCT_TYPES_FAILURE;
    }
  | {
      type: typeof ACTION_CLEAR_PRODUCT_TYPES_ERROR;
    }
  | {
      type: typeof ACTION_CLEAR_AFFINITY_MODAL;
    };

export const clearProductsAction = () => ({ type: ACTION_CLEAR_PRODUCTS });
export const fetchProductTypesRequestAction = () => ({ type: ACTION_FETCH_PRODUCT_TYPES_REQUEST });
export const fetchProductTypesSuccessAction = (productTypes: ProductTypesResponse) => ({
  type: ACTION_FETCH_PRODUCT_TYPES_SUCCESS,
  payload: productTypes,
});

export const fetchProductTypesFailureAction = () => ({
  type: ACTION_FETCH_PRODUCT_TYPES_FAILURE,
});

export const clearProductTypesErrorAction = () => ({
  type: ACTION_CLEAR_PRODUCT_TYPES_ERROR,
});

export default (
  state: ProductState | null | undefined = getInitialState(),
  action: ProductTypesAction = undefined
) => {
  switch (action.type) {
    case ACTION_CLEAR_PRODUCTS: {
      return getInitialState();
    }
    case ACTION_FETCH_PRODUCT_TYPES_REQUEST:
      return { ...state, activePromotion: null, error: false, isLoading: true };
    case ACTION_FETCH_PRODUCT_TYPES_SUCCESS: {
      const promoProduct = action.payload.productTypes.products.find(
        (product: Product) => !!product.promotion || {}
      );
      const { promotion } = promoProduct;
      const { rateMatrices } = action.payload;
      return {
        ...state,
        ...action.payload,
        activePromotion: promotion,
        error: false,
        isLoading: false,
        rateMatrices,
      };
    }
    case ACTION_FETCH_PRODUCT_TYPES_FAILURE:
      return { ...state, error: true, isLoading: false };
    case ACTION_CLEAR_PRODUCT_TYPES_ERROR:
      return { ...state, error: false };
    case ACTION_CLEAR_AFFINITY_MODAL:
      return { ...state, shouldShowInvalidAffinityCodeModal: false };
    default:
      return state;
  }
};
